import { Alert, AlertProps, BackofficeEditProfilePage, ButtonProps, CheckboxProps, Condition, SwitchProps, DropDownProps, TextFieldProps } from '@hi-interactive-admin/simulador-kinto';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Profile, BaseProfileProp, ProfileCommercialConditionsProp, ProfileInsuranceProp, ProfileWebSubPageProp, ProfileWebFieldProp, FunctionalGroup } from '../../model/Profile';
import { CustomerService } from '../../services/CustomerServices';
import { Option } from '../../model/interfaces';
import { ProfileServices } from '../../services/ProfileServices';
import { convertNumber, formatDateWithTimeZone } from '../../Utilities/Utils';
import { VehicleService } from '../../services/VehicleService';
import { AppSettings, EmptyAppSettings } from "../../model/Settings";
import { SettingsService } from "../../services/SettingsService";
import { KintoRootProps } from '../../model/KintoComponentProps';
import { UserModel } from '../../model/User';
import { UserServices } from "../../services/UserServices";
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material';
import useNavbarHook from '../../customCallbacks/navbarHook';

type BackofficeEditProfileRouteParams = {
  profileId: string;
};

declare type CheckboxPropsWithValue = CheckboxProps & {
  value: string;
  label: string;
};

const customerService = new CustomerService();
const profileService = new ProfileServices();
const vehiclesService = new VehicleService();
const userService = new UserServices();

const BackofficeEditProfile : React.FC<KintoRootProps> = ({ onLogout }) =>{
  const [currentUser, setCurrentUser] = useState<UserModel>({
    userId: 0,
    username: "",
    friendlyName: "",
    avatarSrc: "",
    refreshToken: "",
    typeofIncome: "",
    typeofRental: "",
    commercialConditions: "",
    webPortalContactId: "",
    email: "",
    isAdministrator: false,
    profileId: undefined,
    profile: undefined,
    concessions: [],
    created: new Date(Date.now()),
    createdByUserId: 0,
    active: false,
  });

  const [settings, setSettings] = useState<AppSettings>(new EmptyAppSettings());

  const { 
    // navigate,
    handleCardClick, 
    // handleLogout,
    topBarPropsMemo,
    dashboardMenuButtonPropsMemo, 
    proposalMenuButtonPropsMemo, 
    clientsMenuButtonPropsMemo, 
    contractsMenuButtonPropsMemo,
    performanceMenuButtonPropsMemo, 
    backofficeMenuButtonPropsMemo
  } = useNavbarHook(onLogout);
      
  const [open, setOpen] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<Omit<AlertProps, "open" | "onClose">>({
    variant: "info",
    text: "",
    title: "",
    autoHideDuration: 3000,
  });
  const openAlert = (alertProps: Omit<AlertProps, "open" | "onClose">) => {
    setAlertProps(alertProps);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  const params = useParams<BackofficeEditProfileRouteParams>();
  

  const [webPages,setWebPages] =useState<{
    id: number,
    name: string,
    switchToggle: boolean,
    seeCheckbox: boolean,
    editCheckbox: boolean,
    validateCheckbox: boolean,
    required: boolean,
    editRequired: boolean
  }[]>([]);
  const [webSubPages,setWebSubPages] =useState<{
    id: number,
    profileWebPageId:number,
    name: string,
    switchToggle: boolean,
    seeCheckbox: boolean,
    editCheckbox: boolean,
    validateCheckbox: boolean,
    required: boolean,
    editRequired: boolean
  }[]>([]);
  const [webField,setWebFields] =useState<{
    id: number,
    profileWebSubPageId:number,
    name: string,
    switchToggle: boolean,
    seeCheckbox: boolean,
    editCheckbox: boolean,
    validateCheckbox: boolean,
    required: boolean,
    editRequired: boolean

    newConditionValue: TextFieldProps,
    newConditionValidationType: DropDownProps,
    conditionsList: Condition[]
  }[]>([]);

  
  const [valueChecked, setValueChecked] = useState<boolean>(false);


  const [pageLoading, setPageLoading] = useState(false);

  const [concessionOptions, setConcessionOptions] = useState<Option[]>([])
  const [profile, setProfile] = useState<Profile>({
    id: 0,
    name:"",
    concession:"",
    creator:"",
    createdByUserId:0,
    active:true,
    hasFullAccess: false,
    profileFunctionalGroups: [],
    profileAnnualKms: [],
    profileDeadlines: [],
    profileInsurances: [],
    profileCommercialConditions: [],
    profileIsolatedGlassBreaks: [],
    profileLiabilities: [],
    profileOccupiers: [],
    profilePaymentTerms: [],
    profileRentalTypes: [],
    profileRoadAssistances: [],
    profileVehicleDamages:[],
    profileWebPages: [],
  });
  const [createData, setCreateData] = useState<string>("");
  const [selectdConcessionOptions, setSelectedConcessionOptions] = useState<Option[]>([]);

  // CARACTERISTICAS - USESTATES
  const [paymentTerm, setPaymentTerm] = useState<string>("0");
  const [deadline, setDeadline] = useState<string>("0");
  const [annualKm, setAnnualKm] = useState<string>("0");

  const [deadlineValues, setDeadlineValues] = useState<CheckboxProps[]>([]);
  const [annualKmValues, setAnnualKmValues] = useState<CheckboxProps[]>([]);
  const [paymentTerms,setPaymentTerms] = useState<CheckboxProps[]>([]);


  // Functional Groups
  const selectAllValue = '';
  const updateOptionsWithSelectAll = (
    ev: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    updateFunction: React.Dispatch<React.SetStateAction<CheckboxProps[]>>
  ) => {
    let checkedFunctionalGroups: BaseProfileProp[]= [];

    if (ev.target.value === selectAllValue) {
      // Select All option
      updateFunction((prevState: CheckboxProps[]) => {
        const updatedState = prevState.map((item) => ({ ...item, checked: checked }));
        updatedState.forEach(x => {
        if (x.checked === true && x.label !== 'Selecionar todos'){
            checkedFunctionalGroups.push({
              id: 0,
              profileId: 0,
              value: String(x.value)
            });
          }
        })
        return updatedState;
      })
    } else {
      // Other options
      updateFunction((prevState: CheckboxProps[]) => {
        const updatedState = prevState.map((item) => {
          // If the current option is not checked, clear the "select all" option as well
          if (item.value === selectAllValue && !checked) {
            return { ...item, checked: false }
          }
  
          // Set checked for the option that changed
          if (item.value === ev.target.value) {
            return { ...item, checked: checked }
          }
          return item;
        });
  
        // If every other option besides the "select all" option was selected,
        // update the "select all" option to be checked as well.
        const isEveryOptionChecked =
          updatedState
            .filter((item) => item.value !== selectAllValue)
            .every((item) => item.checked);
  
        if (isEveryOptionChecked) {
          return updatedState.map((item) => ({ ...item, checked: true }))
        }
        updatedState.forEach(x => {
          if (x.checked === true && x.label !== 'Selecionar todos'){
            checkedFunctionalGroups.push({
              id: 0,
              profileId: 0,
              value: String(x.value)
            });
          }
        })
        return updatedState;
      });
    }

    setProfile(p => {
      let updatedProfile = {...p};
      checkedFunctionalGroups = checkedFunctionalGroups.map(selected => ({...selected, profileId: updatedProfile.id}));

      // procura os tipos de renda que estão no perfil e que estão selecionados retira os que não estão selecionados
      updatedProfile.profileFunctionalGroups = updatedProfile.profileFunctionalGroups.filter(x => checkedFunctionalGroups.some(y => y.value === x.value));
      // procura os tipos de renda que estão selecionados e que não estão no perfil e adiciona
      let rentalTypeValuesToAdd = checkedFunctionalGroups.filter(x => !updatedProfile.profileFunctionalGroups.some(y => y.value === x.value));
      updatedProfile.profileFunctionalGroups = [...updatedProfile.profileFunctionalGroups, ...rentalTypeValuesToAdd];

      return updatedProfile;
    });
    setValueChecked(true);

  }
  const handleFunctionalGroupsChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    // console.log("Functional Groups On Change:", checked);
    updateOptionsWithSelectAll(ev, checked, setFunctionalGroups);
  }, []);
  const [functionalGroups, setFunctionalGroups] = useState<CheckboxProps[]>([{ value: selectAllValue, label: 'Selecionar Todos', checked: false, onChange: handleFunctionalGroupsChange }]);
  // FIM Grupos Funcionais

  const [typeofRental, setTypeofRental] = useState<CheckboxPropsWithValue[]>([]);
  const [commercialConditions,setCommercialConditions] = useState<{
    rentalType:string,
    insurance_Filter:string,
    checkboxProps:CheckboxPropsWithValue
  }[]>([]);
  const [insuranceValues, setInsuranceValues] = useState<{
    commercialCondition: string,
    checkboxProps: CheckboxPropsWithValue//CheckboxProps
  }[]>([]);

  const [isolatedGlassBreakOptions,setIsolatedGlassBreakOptions] = useState<{
    insurancePackage:string,
    checkboxProps: CheckboxPropsWithValue
  }[]>([]);
  const [roadAssistanceOptions,setRoadAssistanceOptions] = useState<{
    insurancePackage:string,
    checkboxProps: CheckboxPropsWithValue
  }[]>([]);
  const [occupiersOptions,setOccupiersOptions] = useState<{
    insurancePackage:string,
    checkboxProps: CheckboxPropsWithValue
  }[]>([]);
  const [vehicleDamageOptions,setVehicleDamageOptions] = useState<{
    insurancePackage:string,
    checkboxProps: CheckboxPropsWithValue
  }[]>([]);
  const [liabilityOptions,setLiabilityOptions] = useState<{
    insurancePackage:string,
    checkboxProps: CheckboxPropsWithValue
  }[]>([]);

  const previousTypeofRentalState = useRef(typeofRental);
  const [previousCommercialConditionsState, setPreviousCommercialConditionsState] = useState<boolean>(false);
  const [previousInsuranceValuesState, setPreviousInsuranceValuesState ] = useState<boolean>(false);
  // const previousLiabilityOptionsState = useRef(liabilityOptions);
  // const previousVehicleDamageOptionsState = useRef(vehicleDamageOptions);
  // const previousOccupiersOptionsState = useRef(occupiersOptions);
  // const previousRoadAssistanceOptionsState = useRef(roadAssistanceOptions);
  // const previousIsolatedGlassBreakOptionsState = useRef(isolatedGlassBreakOptions);

  // const [commercialConditionInsurances, setCommercialConditionInsurances] = useState<Map<string, string[]>>(new Map());

 // USECALLBACKS GENERICAS DA PAGINA
  const handleProfileModalNameInput = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setProfile(x =>  {
      let updatedProfile = {...x, [e.target.name]: e.target.value};
      return updatedProfile;
    })
  }, []);

  const handleConcessionChange = useCallback((e: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => {
    setSelectedConcessionOptions(rows => {
      let updatedRows:Option[] = [];
      value.forEach((option: Option) => {
        if(!updatedRows.some(x => x.value === option.value)) {
          updatedRows.push({
            label: option.label,
            value: option.value,
          });
        }
          
      });
      // É NECESSÁRIO PERMITIR A GRAVAÇÃO DE VÁRIOS CONCESSIONÁRIOS
      // para isso é preciso que a hi-interactive corrija o interface
      // de criacção de perfis para permitir a selecção de vários concessionários
      setProfile(x => { 
        x.concession = "";
        if( updatedRows && updatedRows.length > 0){
          x.concession = updatedRows[0].value;
          // x.concession = updatedRows.map(x => x.value).join (",");
        }
        return {...x};
      });
      return updatedRows;
    });
  },[]);
  
  // CARACTERISTICAS - USECALLBACKS

  
  const handleLiabilityOptionsCheckedChange = useCallback( (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let selectedLiabilities: ProfileInsuranceProp[] = [];
    setLiabilityOptions((rows) => {
      rows.map((row )=> {
        if(row.checkboxProps.value === e.target.value){
          row.checkboxProps.checked = checked;
        }
        return row;
      })
      selectedLiabilities = rows.filter(x => x.checkboxProps.checked).map(selected => ({
        id: 0,
        profileId: 0,
        insurancePackage: selected.insurancePackage,
        value: selected.checkboxProps.value
      } as ProfileInsuranceProp )) ;
      return rows;
    })
    setProfile(x => {
      selectedLiabilities = selectedLiabilities.map(selected => ({...selected, profileId: x.id}));
      let updatedProfile = {...x};

      // procura os prazos que estão no perfil e que estão selecionados retira os que não estão selecionados
      updatedProfile.profileLiabilities = updatedProfile.profileLiabilities.filter(x => selectedLiabilities.some(y => y.value === x.value));
      // procura os prazos que estão selecionados e que não estão no perfil e adiciona
      let valuesToAdd = selectedLiabilities.filter(x => !updatedProfile.profileLiabilities.some(y => y.value === x.value));
      updatedProfile.profileLiabilities = [...updatedProfile.profileLiabilities, ...valuesToAdd];

      return updatedProfile;
    })
  },[]);

  const handleVehicleDamageOptionsCheckedChange = useCallback( (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let selectedVehicleDamages: ProfileInsuranceProp[] = [];
    setVehicleDamageOptions((rows) => {
      rows.map((row )=> {
        if(row.checkboxProps.value === e.target.value){
          row.checkboxProps.checked = checked;
        }
        return row;
      });
      selectedVehicleDamages = rows.filter(x => x.checkboxProps.checked).map(selected => ({
        id: 0,
        profileId: 0,
        insurancePackage: selected.insurancePackage,
        value: selected.checkboxProps.value
      } as ProfileInsuranceProp )) ;
      return rows
    })
    setProfile(x => {
      selectedVehicleDamages = selectedVehicleDamages.map(selected => ({...selected, profileId: x.id}));
      let updatedProfile = {...x};

      // procura os prazos que estão no perfil e que estão selecionados retira os que não estão selecionados
      updatedProfile.profileVehicleDamages = updatedProfile.profileVehicleDamages.filter(x => selectedVehicleDamages.some(y => y.value === x.value));
      // procura os prazos que estão selecionados e que não estão no perfil e adiciona
      let valuesToAdd = selectedVehicleDamages.filter(x => !updatedProfile.profileVehicleDamages.some(y => y.value === x.value));
      updatedProfile.profileVehicleDamages = [...updatedProfile.profileVehicleDamages, ...valuesToAdd];

      return updatedProfile;
    })
  },[]);

  const handleOccupiersOptionsCheckedChange = useCallback( (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let selectedOccupiers: ProfileInsuranceProp[] = [];
    setOccupiersOptions((rows)=> {
      rows.map((row )=> {
        if(row.checkboxProps.value === e.target.value){
          row.checkboxProps.checked=checked;
        }
        return row;
      })
      selectedOccupiers = rows.filter(x => x.checkboxProps.checked).map(selected => ({
        id: 0,
        profileId: 0,
        insurancePackage: selected.insurancePackage,
        value: selected.checkboxProps.value
      } as ProfileInsuranceProp )) ;
      return rows;
    })
    setProfile(x => {
      selectedOccupiers = selectedOccupiers.map(selected => ({...selected, profileId: x.id}));
      let updatedProfile = {...x};

      // procura os prazos que estão no perfil e que estão selecionados retira os que não estão selecionados
      updatedProfile.profileOccupiers = updatedProfile.profileOccupiers.filter(x => selectedOccupiers.some(y => y.value === x.value));
      // procura os prazos que estão selecionados e que não estão no perfil e adiciona
      let valuesToAdd = selectedOccupiers.filter(x => !updatedProfile.profileOccupiers.some(y => y.value === x.value));
      updatedProfile.profileOccupiers = [...updatedProfile.profileOccupiers, ...valuesToAdd];

      return updatedProfile;
    })
  },[]);

  const handleRoadAssistanceOptionsCheckedChange = useCallback( (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let selectedRoadAssistances: ProfileInsuranceProp[] = [];
    setRoadAssistanceOptions((rows) =>{
      rows.map((row )=> {
        if(row.checkboxProps.value === e.target.value){
          row.checkboxProps.checked=checked;
        }
        return row;
      })
      selectedRoadAssistances = rows.filter(x => x.checkboxProps.checked).map(selected => ({
        id: 0,
        profileId: 0,
        insurancePackage: selected.insurancePackage,
        value: selected.checkboxProps.value
      } as ProfileInsuranceProp )) ;
      return rows;
    })
    setProfile(x => {
      selectedRoadAssistances = selectedRoadAssistances.map(selected => ({...selected, profileId: x.id}));
      let updatedProfile = {...x};

      // procura os prazos que estão no perfil e que estão selecionados retira os que não estão selecionados
      updatedProfile.profileRoadAssistances = updatedProfile.profileRoadAssistances.filter(x => selectedRoadAssistances.some(y => y.value === x.value));
      // procura os prazos que estão selecionados e que não estão no perfil e adiciona
      let valuesToAdd = selectedRoadAssistances.filter(x => !updatedProfile.profileRoadAssistances.some(y => y.value === x.value));
      updatedProfile.profileRoadAssistances = [...updatedProfile.profileRoadAssistances, ...valuesToAdd];

      return updatedProfile;
    })
  },[]);

  const handleIsolatedGlassBreakOptionsCheckedChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let selectedIsolatedGlassBreaks: ProfileInsuranceProp[] = [];
    setIsolatedGlassBreakOptions((rows) =>{
      rows.map((row )=> {
        if(row.checkboxProps.value === e.target.value){
          row.checkboxProps.checked=checked;
        }
        return row;
      })
      selectedIsolatedGlassBreaks = rows.filter(x => x.checkboxProps.checked).map(selected => ({
        id: 0,
        profileId: 0,
        insurancePackage: selected.insurancePackage,
        value: selected.checkboxProps.value
      } as ProfileInsuranceProp )) ;
      return rows;
    })
    setProfile(x => {
      selectedIsolatedGlassBreaks = selectedIsolatedGlassBreaks.map(selected => ({...selected, profileId: x.id}));
      let updatedProfile = {...x};

      // procura os prazos que estão no perfil e que estão selecionados retira os que não estão selecionados
      updatedProfile.profileIsolatedGlassBreaks = updatedProfile.profileIsolatedGlassBreaks.filter(x => selectedIsolatedGlassBreaks.some(y => y.value === x.value));
      // procura os prazos que estão selecionados e que não estão no perfil e adiciona
      let valuesToAdd = selectedIsolatedGlassBreaks.filter(x => !updatedProfile.profileIsolatedGlassBreaks.some(y => y.value === x.value));
      updatedProfile.profileIsolatedGlassBreaks = [...updatedProfile.profileIsolatedGlassBreaks, ...valuesToAdd];

      return updatedProfile;
    })        
  },[]);
  
  // SEGURO
  const handleInsurancesCheckedChange = useCallback( (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let selectedInsurances: ProfileInsuranceProp[] = [];
    setInsuranceValues((rows)=> {
      let updatedInsuranceValuesRows = [...rows];
     
      updatedInsuranceValuesRows.map((ins) => {
        if(ins.checkboxProps.value === e.target.value){
          ins.checkboxProps.checked = checked;
          setPreviousInsuranceValuesState(true);         
        }
        return {...ins};
      })
      setProfile(x => {
        selectedInsurances = selectedInsurances.map(selected => ({...selected, profileId: x.id}));
        let updatedProfile = {...x};

        // procura os prazos que estão no perfil e que estão selecionados retira os que não estão selecionados
        updatedProfile.profileInsurances = updatedProfile.profileInsurances.filter(x => rows.some(y => y.checkboxProps.value === x.value && y.checkboxProps.checked));
        // procura os prazos que estão selecionados e que não estão no perfil e adiciona
        let valuesToAdd = rows.filter(x => x.checkboxProps.checked && !updatedProfile.profileInsurances.some(y => y.value === x.checkboxProps.value)).map(result => {
          return {
            id: 0,
            profileId: updatedProfile.id,
            value: result.checkboxProps.value
          } as BaseProfileProp;
        });
        updatedProfile.profileInsurances = [...updatedProfile.profileInsurances, ...valuesToAdd];

        return updatedProfile;
      })
      return updatedInsuranceValuesRows;
    })
    setValueChecked(true)
   }, []);

  // CONDIÇÕES COMERCIAIS
  const handleCommercialConditionsCheckedChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCommercialConditions((rows) => {
      rows.map(async (commercialCondition) => {
        if(commercialCondition.checkboxProps.value === e.target.value){
          // commercialCondition.checkboxProps.label = commercialCondition.title;
          commercialCondition.checkboxProps.checked = checked;
          setPreviousCommercialConditionsState(true)
        }

        return {...commercialCondition};
      })

      setProfile(x => {
        let updatedProfile = {...x};

        // procura os prazos que estão no perfil e que estão selecionados retira os que não estão selecionados
        updatedProfile.profileCommercialConditions = updatedProfile.profileCommercialConditions.filter(x => rows.some(y => y.checkboxProps.value === x.value && y.checkboxProps.checked));
        // procura os prazos que estão selecionados e que não estão no perfil e adiciona
        let valuesToAdd:ProfileCommercialConditionsProp[] = rows.filter(x => x.checkboxProps.checked && !updatedProfile.profileCommercialConditions.some(y => y.value === x.checkboxProps.value)).map(result => {
          return {
            id: 0,
            profileId: updatedProfile.id,
            rentalType: result.rentalType,
            value: result.checkboxProps.value
          } as ProfileCommercialConditionsProp;
        });
        updatedProfile.profileCommercialConditions = [...updatedProfile.profileCommercialConditions, ...valuesToAdd];
        return {...updatedProfile};
      })
      return rows;
    })
    setValueChecked(true);
  },[ ]);
  
  // TIPO DE ALUGUER
  const handleTypeofRentalCheckedChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let checkedTypesofRental: BaseProfileProp[]= [];

    setTypeofRental((rows) => {
      let updatedTypeofRentalRows = [...rows];

      updatedTypeofRentalRows.map((typeofRental)=> {
        if(e.target.value === typeofRental.value){
          typeofRental.checked = checked;
        }
        if(typeofRental.checked){
          // adiciona valor para mapear no perfil
          checkedTypesofRental.push({
            id: 0,
            profileId: 0,
            value: typeofRental.value
          });
        }
        return { ...typeofRental }; 
      })
     
      return updatedTypeofRentalRows;
    })
    setProfile(p => {
      let updatedProfile = {...p};
      checkedTypesofRental = checkedTypesofRental.map(selected => ({...selected, profileId: updatedProfile.id}));

      // procura os tipos de renda que estão no perfil e que estão selecionados retira os que não estão selecionados
      updatedProfile.profileRentalTypes = updatedProfile.profileRentalTypes.filter(x => checkedTypesofRental.some(y => y.value === x.value));
      // procura os tipos de renda que estão selecionados e que não estão no perfil e adiciona
      let rentalTypeValuesToAdd = checkedTypesofRental.filter(x => !updatedProfile.profileRentalTypes.some(y => y.value === x.value));
      updatedProfile.profileRentalTypes = [...updatedProfile.profileRentalTypes, ...rentalTypeValuesToAdd];

      // // atribui o id do perfil aos valores selecionados
      // checkedCommercialConditions = checkedCommercialConditions.map(selected => ({...selected, profileId: updatedProfile.id}));
      // // remove do perfil as condições comerciais que não estão selecionadas
      // updatedProfile.profileCommercialConditions = updatedProfile.profileCommercialConditions.filter(x => checkedCommercialConditions.some(y => y.value === x.value));
      // // procura as condições comerciais que estão selecionados e que não estão no perfil e adiciona
      // let commercialConditionValuesToAdd = checkedCommercialConditions.filter(x => !updatedProfile.profileCommercialConditions.some(y => y.value === x.value));
      // updatedProfile.profileCommercialConditions = [...updatedProfile.profileCommercialConditions, ...commercialConditionValuesToAdd];

      return updatedProfile;
    });
    setValueChecked(true);
  },[]); 




  // TIPO DE RENDA
  const handlePaymentTermsCheckedChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let checkedPaymentTerms: BaseProfileProp[] = [];
    setPaymentTerms((rows)=>
      rows.map((paymentTerms)=> {
        if(e.target.value === paymentTerms.value){
          paymentTerms.checked = checked;
        }
        if(paymentTerms.checked){
          checkedPaymentTerms.push({
            id: 0,
            profileId: 0,
            value: (paymentTerms.value as string),
          });
        }
        return paymentTerms;
      })
    )
    setProfile(x =>  {
      checkedPaymentTerms = checkedPaymentTerms.map(selected => ({...selected, profileId: x.id}));
      let updatedProfile = {...x};

      // procura os prazos que estão no perfil e que estão selecionados retira os que não estão selecionados
      updatedProfile.profilePaymentTerms = updatedProfile.profilePaymentTerms.filter(x => checkedPaymentTerms.some(y => y.value === x.value));
      // procura os prazos que estão selecionados e que não estão no perfil e adiciona
      let valuesToAdd = checkedPaymentTerms.filter(x => !updatedProfile.profilePaymentTerms.some(y => y.value === x.value));
      updatedProfile.profilePaymentTerms = [...updatedProfile.profilePaymentTerms, ...valuesToAdd];

      return updatedProfile;
    })
  },[]);

  // PRAZO
  const handleDeadLineCheckedChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let checkedDeadlines: BaseProfileProp[] = [];
    setDeadlineValues((rows)=>
      rows.map((deadline) => {
        if(e.target.value === String(deadline.value)){
          deadline.checked=checked;
        }
        if(deadline.checked){
          checkedDeadlines.push({
            id: 0,
            profileId: 0,
            value: String(deadline.value),
          });
        }
        return deadline;
      })
    )
    setProfile(x => {
      checkedDeadlines = checkedDeadlines.map(selected => ({...selected, profileId: x.id}));
      let updatedProfile = {...x};

      // procura os prazos que estão no perfil e que estão selecionados retira os que não estão selecionados
      updatedProfile.profileDeadlines = updatedProfile.profileDeadlines.filter(x => checkedDeadlines.some(y => y.value === x.value));
      // procura os prazos que estão selecionados e que não estão no perfil e adiciona
      let valuesToAdd = checkedDeadlines.filter(x => !updatedProfile.profileDeadlines.some(y => y.value === x.value));
      updatedProfile.profileDeadlines = [...updatedProfile.profileDeadlines, ...valuesToAdd];

      return updatedProfile;
    });
  },[]);
  
  // KILOMETRAGEM
  const handleAnnualKmCheckedChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let checkedAnnualKms: BaseProfileProp[] = [];
    setAnnualKmValues((rows)=>
      rows.map((annualKm) => {
        if(e.target.value  === String(annualKm.value)){
          annualKm.checked = checked;
        }

        if(annualKm.checked){
          checkedAnnualKms.push({
            id: 0,
            profileId: 0,
            value: String(annualKm.value),
          });
        }
        return annualKm;
      })
    )
    setProfile(x => {
      checkedAnnualKms = checkedAnnualKms.map(selected => ({...selected, profileId: x.id}));
      let updatedProfile = {...x};

      // procura os prazos que estão no perfil e que estão selecionados retira os que não estão selecionados
      updatedProfile.profileAnnualKms = updatedProfile.profileAnnualKms.filter(x => checkedAnnualKms.some(y => y.value === x.value));
      // procura os prazos que estão selecionados e que não estão no perfil e adiciona
      let valuesToAdd = checkedAnnualKms.filter(x => !updatedProfile.profileAnnualKms.some(y => y.value === x.value));
      updatedProfile.profileAnnualKms = [...updatedProfile.profileAnnualKms, ...valuesToAdd];

      return updatedProfile;
    });
  },[]);


  // CALLBACKS DE INSERIR E ADCIONAR OPÇÕES - Tipo de Renda
  const handlePaymentTermChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPaymentTerm(e.target.value)
  },[]);
  const handleAddPaymentTermKmClick: ButtonProps["onClick"] = useCallback((e: any) => {
    if(paymentTerms.findIndex(x => x.value === paymentTerm)<0){
      paymentTerms.push({
        value: paymentTerm, 
        onChange:handlePaymentTermsCheckedChange,
        checked:false,
        label: ''
      });
      setPaymentTerms(paymentTerms);
    } else {
      openAlert({
        variant: "error",
        text: "O valor "+ paymentTerm+" já existe na lista de termos de pagamento!",
        title: "Atenção",
      });
    }
    setPaymentTerm("0");
  },[
    handlePaymentTermsCheckedChange,
    paymentTerms,
    paymentTerm
  ]);
  
  // CALLBACKS DE INSERIR E ADCIONAR OPÇÕES - Prazo
  const handleDeadLineChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDeadline(Number(e.target.value).toString()) // remove zeros à esquerda
  },[]);
  const handleAddDeadlineKmClick: ButtonProps["onClick"] = useCallback((e: any) => {
    if(deadlineValues.findIndex(x => x.value === deadline) < 0){
      deadlineValues.push({
        value: deadline,
        onChange: handleDeadLineCheckedChange,
        checked: false,
        label: deadline
      });
      setDeadlineValues(deadlineValues);
    } else {
      openAlert({
        variant: "error",
        text: "O valor "+ deadline+" já existe na lista de pazos!",
        title: "Atenção",
      });
    }
    setDeadline("0");
  }, [
    handleDeadLineCheckedChange,
    deadlineValues,
    deadline
  ]);

  // CALLBACKS DE INSERIR E ADCIONAR OPÇÕES - Kilometragem
  const handleAnnualKmChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAnnualKm(Number(e.target.value).toString()) // remove zeros à esquerda
  },[]);
  const handleAddAnnualKmClick: ButtonProps["onClick"] = useCallback((e: any) => {
    if(annualKmValues.findIndex(x=>x.value===annualKm)<0){
      annualKmValues.push({
        value:annualKm,
        onChange: handleAnnualKmCheckedChange,
        checked:false,
        label: annualKm
      });
      setAnnualKmValues(annualKmValues);
    } else {
      openAlert({
        variant: "error",
        text: "O valor "+ annualKm+" já existe na lista de Kms!",
        title: "Atenção",
      });
    }
    setAnnualKm("0");
  },[
    handleAnnualKmCheckedChange,
    annualKmValues,
    annualKm
  ]);


  // SELECT ALL OPTIONS CALLBACKS

  const handleSelectAllDealinesOnChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setDeadlineValues(rows =>  {
      rows.map((row,index) => {
        row.checked = checked;
        if(index !== 0){
          let event = { target: { value: row.value } } as unknown as ChangeEvent<HTMLInputElement>;
          handleDeadLineCheckedChange(event, checked);
        }
        return {...row};
      });
      return [...rows];
    })
  },[handleDeadLineCheckedChange]); 

  const handleSelectAllAnnualKmsOnChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAnnualKmValues(rows =>  {
      rows.map((row,index) => {
        row.checked = checked;
        if(index !== 0){
          let event = { target: { value: row.value } } as unknown as ChangeEvent<HTMLInputElement>;
          handleAnnualKmCheckedChange(event, checked);
        }
        return {...row};
      });
      return [...rows];
    })
  },[handleAnnualKmCheckedChange]);
  
  const handleSelectAllPaymentTermsOnChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setPaymentTerms(rows =>  {
      rows.map((row,index) => {
        row.checked = checked;
        if(index !== 0){
          let event = { target: { value: row.value } } as unknown as ChangeEvent<HTMLInputElement>;
          handlePaymentTermsCheckedChange(event, checked);
        }
        return {...row};
      });
      return [...rows];
    })
  },[handlePaymentTermsCheckedChange]); 

  const handleSelectAllTypesofRentalOnChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setTypeofRental(rows =>  {
      rows.map((row) => {
        row.checked = checked;
        if(row.value !== '0'){
          let event = { target: { value: row.value } } as unknown as ChangeEvent<HTMLInputElement>;
          handleTypeofRentalCheckedChange(event, checked);
        } else {
          if(!checked){
            let event = { target: { value: row.value } } as unknown as ChangeEvent<HTMLInputElement>;
            handleCommercialConditionsCheckedChange(event, checked);
          }
        }
        return {...row};
      });
      return [...rows];
    })
  },[handleTypeofRentalCheckedChange, handleCommercialConditionsCheckedChange]); 

  const handleSelectAllCommercialConditionsOnChange = useCallback(async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCommercialConditions(rows =>  {   
      rows.map(async (row) => {       
        row.checkboxProps.checked = checked;
        setValueChecked(true);
        if(row.checkboxProps.value !== '0'){
          let event = { target: { value: row.checkboxProps.value } } as unknown as ChangeEvent<HTMLInputElement>;
          handleCommercialConditionsCheckedChange(event, checked);
        } else {
          if(!checked){
            let event = { target: { value: row.checkboxProps.value } } as unknown as ChangeEvent<HTMLInputElement>;
            handleInsurancesCheckedChange(event, checked);
          }
        }
        return {...row};
      });
      return [...rows];
    })
  },[handleCommercialConditionsCheckedChange, handleInsurancesCheckedChange]); 

  const handleSelectAllInsurancePackagesOnChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setInsuranceValues(rows =>  {
      rows.map((row) => {
        row.checkboxProps.checked = checked;
        if(row.checkboxProps.value !== '0'){
          let event = { target: { value: row.checkboxProps.value } } as unknown as ChangeEvent<HTMLInputElement>;
          handleInsurancesCheckedChange(event, checked);
        } else {
          if(!checked){
            let event = { target: { value: row.checkboxProps.value } } as unknown as ChangeEvent<HTMLInputElement>;
            handleLiabilityOptionsCheckedChange(event, checked);
            handleVehicleDamageOptionsCheckedChange(event, checked);
            handleOccupiersOptionsCheckedChange(event, checked);
            handleRoadAssistanceOptionsCheckedChange(event, checked);
            handleIsolatedGlassBreakOptionsCheckedChange(event, checked);
          }
        }
        
        return {...row};
      });
      return [...rows];
    })
  },[
    handleInsurancesCheckedChange,
    handleIsolatedGlassBreakOptionsCheckedChange,
    handleLiabilityOptionsCheckedChange,
    handleOccupiersOptionsCheckedChange,
    handleRoadAssistanceOptionsCheckedChange,
    handleVehicleDamageOptionsCheckedChange
  ]); 

  const handleSelectAllLiabilitiesOnChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setLiabilityOptions(rows =>  {
      rows.map((row) => {
        row.checkboxProps.checked = checked;
        if(row.checkboxProps.value !== '0'){
          let event = { target: { value: row.checkboxProps.value } } as unknown as ChangeEvent<HTMLInputElement>;
          handleLiabilityOptionsCheckedChange(event, checked);
        }
        return {...row};
      });
      return [...rows];
    })
  },[handleLiabilityOptionsCheckedChange]); 

  const handleSelectAllOwnDamagesOnChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setVehicleDamageOptions(rows =>  {
      rows.map((row) => {
        row.checkboxProps.checked = checked;
        if(row.checkboxProps.value !== '0'){
          let event = { target: { value: row.checkboxProps.value } } as unknown as ChangeEvent<HTMLInputElement>;
          handleVehicleDamageOptionsCheckedChange(event, checked);
        }
        return {...row};
      });
      return [...rows];
    })
  },[handleVehicleDamageOptionsCheckedChange]); 

  const handleSelectAllOccupiersOnChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setOccupiersOptions(rows =>  {
      rows.map((row) => {
        row.checkboxProps.checked = checked;
        if(row.checkboxProps.value !== '0'){
          let event = { target: { value: row.checkboxProps.value } } as unknown as ChangeEvent<HTMLInputElement>;
          handleOccupiersOptionsCheckedChange(event, checked);
        }
        return {...row};
      });
      return [...rows];
    })
  },[handleOccupiersOptionsCheckedChange]); 

  const handleSelectAllRoadAssistancesOnChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setRoadAssistanceOptions(rows =>  {
      rows.map((row) => {
        row.checkboxProps.checked = checked;
        if(row.checkboxProps.value !== '0'){
          let event = { target: { value: row.checkboxProps.value } } as unknown as ChangeEvent<HTMLInputElement>;
          handleRoadAssistanceOptionsCheckedChange(event, checked);
        }
        return {...row};
      });
      return [...rows];
    })
  },[handleRoadAssistanceOptionsCheckedChange]); 

  const handleSelectAllIsolagedGlassBreakagesOnChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsolatedGlassBreakOptions(rows =>  {
      rows.map((row) => {
        row.checkboxProps.checked = checked;
        if(row.checkboxProps.value !== '0'){
          let event = { target: { value: row.checkboxProps.value } } as unknown as ChangeEvent<HTMLInputElement>;
          handleIsolatedGlassBreakOptionsCheckedChange(event, checked);
        }
        return {...row};
      });
      return [...rows];
    })
  },[handleIsolatedGlassBreakOptionsCheckedChange]); 


  //FIELD CONDITIONS CALLBACKS 
  const handleRemoveConditionCallback = useCallback((webFieldID: number, index: number) => (e: any) => {
    setWebFields(fields => {
      let updatedFields = [...fields];
      let updatedField = updatedFields.find((field, fieldIndex) => field.id === webFieldID);
      if(updatedField){
        updatedField.conditionsList.splice(index, 1); // isto é o mesmo que o codigo debaixo
        // updatedField.conditionsList = updatedField.conditionsList.filter((condition, conditionIndex) => conditionIndex !== index);
        updatedField.conditionsList.map((condition, conditionIndex) => { 

          condition.removeButtonProps.onClick = handleRemoveConditionCallback(webFieldID, conditionIndex);
          // removeButtonProps: {
          //   onClick: handleRemoveConditionCallback(index, updatedField.conditionsList.length)
          // },
          // saveButtonProps: {
          //   onClick: () => alert('guardar condição'),//handleSaveConditionCallback(index, updatedField.conditionsList.length)
          // }
          return condition;
        });
      }
      return updatedFields;  
    })
  },[]);
  
  const handleConditionDropPropsChange = useCallback((e:  ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let indexes = e.target.name.split('+');
    setWebFields(fields => { 
      let updatedFields = [...fields];
      let updatedFieldIndex = updatedFields.findIndex((field) => field.id === Number(indexes[1]));
      if(updatedFieldIndex !== -1){
        let updatedField = {...updatedFields[updatedFieldIndex]};
        if(indexes.length > 2 && updatedField.conditionsList.length > 0){
          // updatedField.conditionsList[Number(indexes[2])].dropProps.value = e.target.value;
          let conditions = [...updatedField.conditionsList.map((condition, conditionIndex) => { 
            if(conditionIndex === Number(indexes[2])){
              return {
                ...condition, 
                dropProps: { ...condition.dropProps, value: e.target.value,}
              };
            }
            return condition;
          })];
          updatedField = {...updatedField, conditionsList: conditions}
          setDisplayConditionChanged(rows=> {
            return rows.map((row) => {
              if(row.id === updatedField?.id) {
                row.value = true;
              }
              return row;
            })
          })
        } else {
          updatedField.newConditionValidationType.value = Number(e.target.value); // atualiza a linha de nova condição
        }
        updatedFields[updatedFieldIndex] = {...updatedField}
      }
      return [...updatedFields]
    })
  },[]);

  const handleConditionTextFieldPropsChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let indexes = e.target.name.split('+');
    setWebFields(fields => { 
      let updatedFields = [...fields];
      let updatedFieldIndex = updatedFields.findIndex((field) => field.id === Number(indexes[1]));
      if(updatedFieldIndex !== -1){
        let updatedField = {...updatedFields[updatedFieldIndex]};
        if(indexes.length > 2 && updatedField.conditionsList.length > 0){
          // updatedField.conditionsList[Number(indexes[2])].textFieldProps.value = e.target.value;
          let conditions = [...updatedField.conditionsList.map((condition, conditionIndex) => { 
            if(conditionIndex === Number(indexes[2])){
              return {
                ...condition, 
                textFieldProps: { ...condition.textFieldProps, value: e.target.value,}
              };
            }
            return condition;
          })];
          updatedField = {...updatedField, conditionsList: conditions}
          setDisplayConditionChanged(rows=> {
            return rows.map((row) => {
              if(row.id === updatedField?.id) {
                row.value = true;
              }
              return row;
            })
          })
        } else {
          updatedField.newConditionValue.value = e.target.value; // atualiza a linha de nova condição
        }
        updatedFields[updatedFieldIndex] = {...updatedField}
      }
      return [...updatedFields]
    })
  },[]);


  // CALLBACK DE CARREGAMENTO DE OPÇÕES GUARDADAS DO PERFIL
  const loadPage = useCallback(async () => {
    let options:Option[] = [];
    await customerService.getCenterDealer().then(async (data) => {
      if(data && data.length > 0) {
        data.forEach((element:any) => {
          options.push({
            label: `${element.description} - ${element.id}`,
            value: element.id,
          })            
        })
        setConcessionOptions(options);

        // carrega perfil e opções de perfil
        if(params.profileId && options.length > 0){
          let id=convertNumber(params.profileId);
          await profileService.getProfileById(id,null).then(async (profileData) => {
            const timeZone:string = "pt-PT";
            let date = formatDateWithTimeZone(profileData['createdOn'] as string, timeZone);
            setCreateData(date);
    
            setProfile({
              id: profileData['id'],      
              name: profileData['name'],  
              active: profileData['active'],
              concession: profileData['concession'],
              hasFullAccess: profileData['hasFullAccess'],
              creator: profileData['creator'],
              createdByUserId:  profileData['createdByUserId'],
              profileFunctionalGroups: profileData['profileFunctionalGroups'] ?? [],
              profileAnnualKms: profileData['profileAnnualKms'] ?? [],
              profileDeadlines: profileData['profileDeadlines'] ?? [],
              profileInsurances: profileData['profileInsurances'] ?? [],
              profileCommercialConditions: profileData['profileCommercialConditions'] ?? [],
              profileIsolatedGlassBreaks: profileData['profileIsolatedGlassBreaks'] ?? [],
              profileLiabilities: profileData['profileLiabilities'] ?? [],
              profileOccupiers: profileData['profileOccupiers'] ?? [],
              profilePaymentTerms: profileData['profilePaymentTerms'] ?? [],
              profileRentalTypes: profileData['profileRentalTypes'] ?? [],
              profileRoadAssistances: profileData['profileRoadAssistances'] ?? [],
              profileVehicleDamages: profileData['profileVehicleDamages'] ?? [],
              profileWebPages: profileData['profileWebPages'] ?? [],
            });
            

            setFullAccessSwitch(profileData['hasFullAccess']);
          
            const concessionsValues: string[] = profileData['concession'].split(",")
            let profileConcession  = options.filter(x => concessionsValues.some(y => y === x.value ))
            setSelectedConcessionOptions(rows => {
              let updatedRows = [...rows];
              if (profileConcession) {
                // if(!updatedRows.some(x => x.value === profileConcession?.value)) {
                //   updatedRows.push(profileConcession)
                if(!updatedRows.some(x => profileConcession.some(y => y.value === x.value))) {
                  updatedRows = [...updatedRows, ...profileConcession]
                }
              } else {
                updatedRows = []
              }
              return updatedRows
            });
  
            // POPULATE CARACTERISTICAS
            // Preenche opções para selecção dos perfis mediante os valores do perfil
            // Se o perfil ainda não tiver selecções, preenche com as opções por defeito (tipo de aluguers, prazos, kilometragens e tipo de renda)

            let loadedProfile = profileData as Profile

            // carrega as selecções das checkboxes para o tipo de contrato / tipo de aluguer
            const rentalTypeData = await vehiclesService.getRentalTypes();
            const commercialConditionsData = await vehiclesService.getCommercialConditions();
            let commercialConditionRelatedInsurances: {
              commercialCondition: string,
              insurancePackages: string[]
            }[] = [];

            const insurancePackagesData = await vehiclesService.getAllInsurancePackage();
            const insurancePackageLineData = await vehiclesService.getInsurancePackageLine();

            //TODO: carregar grupos funcionais
            // criar uma api para obter grupos funcionais
            // e efetuar respectivo carregamento no lugar do setFunctionalGroups
            const functionalGroupsData = await profileService.getFunctionalGroups();
            let functionalGroupOptions: CheckboxProps[] = [];
            // [
            //   { value: selectAllValue, label: 'Selecionar Todos', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'HR', label: 'HR (HR - Clientes Particulares)', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'TRP', label: 'TRP', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'TRP ILHAS', label: 'TRP ILHAS', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'NISSAN', label: 'NISSAN', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'NISSAN MADEIRA', label: 'NISSAN MADEIRA', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'NISSAN AÇORES', label: 'NISSAN AÇORES', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'AOV GERAL', label: 'AOV GERAL', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'LR01', label: 'LR01', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'LR02', label: 'LR02', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'LR03', label: 'LR03', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'KSH', label: 'KSH', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'KSL', label: 'KSL', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'KFL', label: 'KFL', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'MAPFRE', label: 'MAPFRE (Mapfre Renting Particulares)', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'MYCARFLIX', label: 'MYCARFLIX', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'GF001', label: 'GF001', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'GF002', label: 'GF002', checked: false, onChange: handleFunctionalGroupsChange },
            //   { value: 'GF003', label: 'GF003', checked: false, onChange: handleFunctionalGroupsChange },
            // ];
            if(functionalGroupsData && functionalGroupsData.succeeded){
              functionalGroupsData.value.forEach((functionalGroup: FunctionalGroup) => {
                functionalGroupOptions.push({
                  value: functionalGroup.function_Group,
                  label: `${functionalGroup.function_Group} - ${functionalGroup.description}`,
                  checked: false,
                  // checked: loadedProfile.profileFunctionalGroups.some((x) => x.value === line.function_Group),
                  onChange: handleFunctionalGroupsChange
                });
              });
           
              functionalGroupOptions.forEach((line) => {
                setFunctionalGroups(rows => {
                  let updatedFunctionalGroupsRows = [...rows];

                  if(updatedFunctionalGroupsRows.length === 0){
                    updatedFunctionalGroupsRows.push({
                      value: '', 
                      label: 'Selecionar Todos', 
                      onChange: handleFunctionalGroupsChange,
                      checked: false
                    })
                  }

                  if( !updatedFunctionalGroupsRows.some( row =>  row.value === line.value )){
                    updatedFunctionalGroupsRows.push({ 
                      value: line.value, 
                      label: line.label, 
                      onChange: handleFunctionalGroupsChange,
                      checked: false
                    })
                  }

                  updatedFunctionalGroupsRows.map(updatedFunctionalGroupsRow => {
                    if(loadedProfile.profileFunctionalGroups.some((x) => x.value === updatedFunctionalGroupsRow.value)){
                      updatedFunctionalGroupsRow.checked = true;
                    }
                    return {...updatedFunctionalGroupsRow};
                  });
                  if( functionalGroupOptions
                    .filter(x=> x.label !== 'Selecionar Todos')
                    .every(y => loadedProfile.profileFunctionalGroups.some((z) => z.value === y.value))
                  ){
                    updatedFunctionalGroupsRows.map((x) => { 
                      if(x.label === 'Selecionar Todos'){
                        x.checked = true;
                      }
                      return {...x};
                    })
                  }

                  return updatedFunctionalGroupsRows;
                });
              });
            }

            if (rentalTypeData && rentalTypeData.succeeded === true) {
              // let selectedTypesofRental: { value: string; label: string; checkboxProps: CheckboxProps; }[] = [];
              rentalTypeData.value.forEach((line: Option) => {
                setTypeofRental(rows => {
                  let updatedTypeofRentalRows = [...rows];

                  if(updatedTypeofRentalRows.length === 0){
                    updatedTypeofRentalRows.push({
                      value: '0', 
                      label: 'Selecionar todos', 
                      onChange: handleSelectAllTypesofRentalOnChange,
                      checked: false
                    })
                  }

                  if( !updatedTypeofRentalRows.some( row =>  row.value === line.value )){
                    updatedTypeofRentalRows.push({ 
                      value: line.value, 
                      label: line.label, 
                      onChange: handleTypeofRentalCheckedChange,
                      checked: false
                    })
                  }
                  updatedTypeofRentalRows.map(updatedTypeofRentalRow => {
                    if(loadedProfile.profileRentalTypes.some((x) => x.value === updatedTypeofRentalRow.value)){
                      updatedTypeofRentalRow.checked = true;
                    }
                    return {...updatedTypeofRentalRow};
                  });
                  return updatedTypeofRentalRows;
                })
              });
            }
            
            // carrega as selecções das checkboxes para as condições comerciais
            if(loadedProfile.profileRentalTypes && loadedProfile.profileRentalTypes.length > 0){

              loadedProfile.profileRentalTypes.map((type) => {
                if (commercialConditionsData && commercialConditionsData.succeeded){
                  commercialConditionsData.value.filter((x: any) => x.code === type.value).forEach((line: any) => {
                    setCommercialConditions(rows => {
                      let updatedCommercialConditionsRows = [...rows];

                      if(updatedCommercialConditionsRows.length === 0){
                        updatedCommercialConditionsRows.push({
                          rentalType: '0',
                          insurance_Filter: '',
                          checkboxProps: {
                            value: '0', 
                            label: 'Selecionar todos', 
                            onChange: handleSelectAllCommercialConditionsOnChange,
                            checked: false
                          }
                        })
                      }

                      if( !updatedCommercialConditionsRows.some( row =>  row.checkboxProps.value === line.value)){
                        updatedCommercialConditionsRows.push({ 
                          rentalType: type.value,
                          insurance_Filter: line.insurance_Filter,
                          checkboxProps: {
                            value: line.value, 
                            label: `${line.label}`,
                            onChange:handleCommercialConditionsCheckedChange,
                            checked:  loadedProfile.profileCommercialConditions.some(x => x.value === line.value) ? true : false
                          }
                        })
                      }
                      return updatedCommercialConditionsRows.sort((a, b) => {
                        if (a.checkboxProps.label === 'Selecionar todos') return -1;
                        if (b.checkboxProps.label === 'Selecionar todos') return 1;
                        return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                      });
                    })

                    if(!commercialConditionRelatedInsurances.some(x => x.commercialCondition === line.value)){
                      let commercialCondition = commercialConditionsData.value.find((x: any) => x.value === line.value);
                      if(commercialCondition) {
                        commercialConditionRelatedInsurances.push({
                          commercialCondition: commercialCondition.value,
                          insurancePackages: commercialCondition.insurance_Filter.split('|').filter((x:string) => x !== "''")
                        })
                      }
                    }
                  });
                }
                
                return type;
              })
            }

            // carrega as selecções das checkboxes para os seguros (pacotes de seguros)
            let loadedInsuranceValues: {
              commercialCondition: string,
              checkboxProps: CheckboxPropsWithValue//CheckboxProps
            }[] = [];


            if(loadedProfile.profileCommercialConditions && loadedProfile.profileCommercialConditions.length > 0){
              setInsuranceValues(rows => {
                let updatedInsuranceRows = [...rows];

                if(updatedInsuranceRows.length === 0){
                  updatedInsuranceRows.push({
                    commercialCondition: '0',
                    checkboxProps: {
                      value: '0', 
                      label: 'Selecionar todos', 
                      onChange: handleSelectAllInsurancePackagesOnChange,
                      checked: false
                    }
                  })
                }

                loadedProfile.profileCommercialConditions.map((commercialCondition) => {
                  if (insurancePackagesData && insurancePackagesData.succeeded) {

                    let currentCommercialCondition = commercialConditionRelatedInsurances.find( x => x.commercialCondition === commercialCondition.value)
                    if(currentCommercialCondition){

                      currentCommercialCondition.insurancePackages.forEach((relatedInsurance) => {
                        
                        
                        if(!updatedInsuranceRows.some(updatedRow => 
                          updatedRow.checkboxProps.value === `${relatedInsurance}-${currentCommercialCondition!.commercialCondition}` &&
                          updatedRow.commercialCondition === currentCommercialCondition!.commercialCondition
                        )){
                          let insuranceData = insurancePackagesData.value.find((insurance: any) => insurance.value === relatedInsurance);
                          if(insuranceData){
                            updatedInsuranceRows.push({
                              commercialCondition: currentCommercialCondition!.commercialCondition,
                              checkboxProps: {
                                value: `${insuranceData.value}-${currentCommercialCondition!.commercialCondition}`,
                                label: `${commercialCondition.value} - ${insuranceData.label}`,
                                onChange: handleInsurancesCheckedChange,
                                checked:  loadedProfile.profileInsurances.some(x => x.value === `${insuranceData.value}-${currentCommercialCondition!.commercialCondition}`) ? true : false
                              }
                            });
                          }
                        }
                      })
                    }
                  }

                  return commercialCondition
                })
                loadedInsuranceValues = updatedInsuranceRows;

                return updatedInsuranceRows.sort((a, b) => {
                  if (a.checkboxProps.label === 'Selecionar todos') return -1;
                  if (b.checkboxProps.label === 'Selecionar todos') return 1;
                  return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                });
              })
            }

            // carrega as selecções das checkboxes para os seguros (opções de seguros)
            if(loadedProfile.profileInsurances && loadedProfile.profileInsurances.length > 0){
              loadedProfile.profileInsurances.map((ins) => {
                const insuranceRequestValue = ins.value.split("-")[0];

                insurancePackageLineData.filter((x: any) => x.insurance_Package === insuranceRequestValue)
                .map((item: { insurance: string, description: string, type: string, use: string}) => {
                
                  let use = settings.Data.Vehicles_UseProps.find(x => x.value === item.use)?.label
                  let insuranceLabel = '';


                  // insuranceLabel = CPAOV001 - Frota Propria
                  //Liability
                  if(item.type === "Liability"){
                    setLiabilityOptions(rows => {
                      let updatedRows = [...rows]

                      if(updatedRows.length === 0){
                        updatedRows.push({
                          insurancePackage: '0',
                          checkboxProps: {
                            value: '0', 
                            label: 'Selecionar todos', 
                            onChange: handleSelectAllLiabilitiesOnChange,
                            checked: false
                          }
                        })
                      }

                      if(loadedInsuranceValues && loadedInsuranceValues.length > 0) {
                        let insuranceChecboxProps =  loadedInsuranceValues.find( x => x.checkboxProps.value === ins.value)?.checkboxProps;
                        if(insuranceChecboxProps){
                          insuranceLabel = insuranceChecboxProps.label ?? '';
                        }
                      }
                      if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${ins.value}`) && insuranceLabel !== '') {
                        updatedRows.push({
                          insurancePackage: ins.value,
                          checkboxProps: {
                            label: `${insuranceLabel} - ${use} - ${item.insurance} - ${item.description}`,
                            value: `${item.insurance}-${ins.value}`,
                            onChange: handleLiabilityOptionsCheckedChange,
                            checked: loadedProfile.profileLiabilities.some(x => x.value === `${item.insurance}-${ins.value}`) ? true : false
                          }
                        })
                      }

                      return updatedRows.sort((a, b) => {
                        if (a.checkboxProps.label === 'Selecionar todos') return -1;
                        if (b.checkboxProps.label === 'Selecionar todos') return 1;
                        return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                      });
                    })
                  }
                  //Own Damage
                  if(item.type === "Own Damage"){
                    setVehicleDamageOptions(rows => {
                      let updatedRows = [...rows]

                      if(updatedRows.length === 0){
                        updatedRows.push({
                          insurancePackage: '0',
                          checkboxProps: {
                            value: '0', 
                            label: 'Selecionar todos', 
                            onChange: handleSelectAllOwnDamagesOnChange,
                            checked: false
                          }
                        })
                      }

                      if(loadedInsuranceValues && loadedInsuranceValues.length > 0) {
                        let insuranceChecboxProps =  loadedInsuranceValues.find( x => x.checkboxProps.value === ins.value)?.checkboxProps;
                        if(insuranceChecboxProps){
                          insuranceLabel = insuranceChecboxProps.label ?? '';
                        }
                      }
                      if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${ins.value}`) && insuranceLabel !== '') {
                        updatedRows.push({
                          insurancePackage: ins.value,
                          checkboxProps: {
                            value: `${item.insurance}-${ins.value}`,
                            label: `${insuranceLabel} - ${use} - ${item.insurance} - ${item.description}`,
                            onChange: handleVehicleDamageOptionsCheckedChange,
                            checked: loadedProfile.profileVehicleDamages.some(x => x.value === `${item.insurance}-${ins.value}`) ? true : false
                          }
                        });
                      }

                      return updatedRows.sort((a, b) => {
                        if (a.checkboxProps.label === 'Selecionar todos') return -1;
                        if (b.checkboxProps.label === 'Selecionar todos') return 1;
                        return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                      });
                    })
                  }
                  //Other Passenger
                  if(item.type === "Other Passenger"){
                    setOccupiersOptions(rows => {
                      let updatedRows = [...rows]

                      if(updatedRows.length === 0){
                        updatedRows.push({
                          insurancePackage: '0',
                          checkboxProps: {
                            value: '0', 
                            label: 'Selecionar todos', 
                            onChange: handleSelectAllOccupiersOnChange,
                            checked: false
                          }
                        })
                      }

                      if(loadedInsuranceValues && loadedInsuranceValues.length > 0) {
                        let insuranceChecboxProps =  loadedInsuranceValues.find( x => x.checkboxProps.value === ins.value)?.checkboxProps;
                        if(insuranceChecboxProps){
                          insuranceLabel = insuranceChecboxProps.label ?? '';
                        }
                      }
                      if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${ins.value}`) && insuranceLabel !== '') {
                        updatedRows.push({
                          insurancePackage: ins.value,        
                          checkboxProps: {
                            value: `${item.insurance}-${ins.value}`,
                            label: `${insuranceLabel} - ${use} - ${item.insurance} - ${item.description}`,

                            onChange: handleOccupiersOptionsCheckedChange,
                            checked: loadedProfile.profileOccupiers.some(x => x.value === `${item.insurance}-${ins.value}`) ? true : false
                          }  
                        });
                      }

                      return updatedRows.sort((a, b) => {
                        if (a.checkboxProps.label === 'Selecionar todos') return -1;
                        if (b.checkboxProps.label === 'Selecionar todos') return 1;
                        return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                      });
                    })
                  }
                  //Legal Defense
                  if(item.type === "Legal Defense"){
                    setRoadAssistanceOptions(rows => {
                      let updatedRows = [...rows]

                      if(updatedRows.length === 0){
                        updatedRows.push({
                          insurancePackage: '0',
                          checkboxProps: {
                            value: '0', 
                            label: 'Selecionar todos', 
                            onChange: handleSelectAllRoadAssistancesOnChange,
                            checked: false
                          }
                        })
                      }

                      if(loadedInsuranceValues && loadedInsuranceValues.length > 0) {
                        let insuranceChecboxProps =  loadedInsuranceValues.find( x => x.checkboxProps.value === ins.value)?.checkboxProps;
                        if(insuranceChecboxProps){
                          insuranceLabel = insuranceChecboxProps.label ?? '';
                        }
                      }
                      if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${ins.value}`) && insuranceLabel !== '') {
                        updatedRows.push({
                          insurancePackage: ins.value,
                          checkboxProps: {
                            // SOLUCAO TEMPORARIA ATE A HI-INTERACTIVE CORRIGIR O COMPONENTE
                            value: `${item.insurance}-${ins.value}`,
                            label: `${insuranceLabel} - ${use} - ${item.insurance} - ${item.description}`,

                            onChange: handleRoadAssistanceOptionsCheckedChange,
                            checked: loadedProfile.profileRoadAssistances.some(x => x.value === `${item.insurance}-${ins.value}`) ? true : false
                          }
                        });
                      }

                      return updatedRows.sort((a, b) => {
                        if (a.checkboxProps.label === 'Selecionar todos') return -1;
                        if (b.checkboxProps.label === 'Selecionar todos') return 1;
                        return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                      });
                    })
                  }
                  //Fire
                  if(item.type === "Fire "){
                    setIsolatedGlassBreakOptions(rows => {
                      let updatedRows = [...rows]

                      if(updatedRows.length === 0){
                        updatedRows.push({
                          insurancePackage: '0',
                          checkboxProps: {
                            value: '0', 
                            label: 'Selecionar todos', 
                            onChange: handleSelectAllIsolagedGlassBreakagesOnChange,
                            checked: false
                          }
                        })
                      }

                      if(loadedInsuranceValues && loadedInsuranceValues.length > 0) {
                        let insuranceChecboxProps =  loadedInsuranceValues.find( x => x.checkboxProps.value === ins.value)?.checkboxProps;
                        if(insuranceChecboxProps){
                          insuranceLabel = insuranceChecboxProps.label ?? '';
                        }
                      }
                      if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${ins.value}` ) && insuranceLabel !== '') {
                        updatedRows.push({
                          insurancePackage: ins.value,
                          checkboxProps: {
                            // SOLUCAO TEMPORARIA ATE A HI-INTERACTIVE CORRIGIR O COMPONENTE
                            value: `${item.insurance}-${ins.value}`,
                            label: `${insuranceLabel} - ${use} - ${item.insurance} - ${item.description}`,

                            onChange: handleIsolatedGlassBreakOptionsCheckedChange,
                            checked: loadedProfile.profileIsolatedGlassBreaks.some(x => x.value === `${item.insurance}-${ins.value}`) ? true : false
                          }
                        })
                      }

                      return updatedRows.sort((a, b) => {
                        if (a.checkboxProps.label === 'Selecionar todos') return -1;
                        if (b.checkboxProps.label === 'Selecionar todos') return 1;
                        return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                      });
                    })
                  }
                  return item;
                })

                return ins;
              })
            }

            // carrega as selecções das checkboxes para os tipos de renda
            if(loadedProfile.profilePaymentTerms && loadedProfile.profilePaymentTerms.length > 0){
              setPaymentTerms(rows => {
                rows.map(row => {
                  if(loadedProfile.profilePaymentTerms.some(x => x.value === row.value)){
                    row.checked = true;
                  }
                  return row;
                })
                return [...rows];
              })
            }

            // carrega as selecções das checkboxes para os prazos
            if(loadedProfile.profileDeadlines && loadedProfile.profileDeadlines.length > 0){
              setDeadlineValues(rows => {
                // coloca as selecções do perfil como checked
                rows.map(row => {
                  if(loadedProfile.profileDeadlines.some(x => x.value === String(row.value))){
                    row.checked = true;
                  }
                  return row;
                });

                // procura os valores que estão no perfil e que não existem no appsettings e adiciona
                let valuesToAdd = loadedProfile.profileDeadlines.filter(x => !rows.some(y => String(y.value) === x.value));
                if(valuesToAdd && valuesToAdd.length > 0){
                  valuesToAdd.forEach(value => {
                    rows.push({
                      value: value.value.toString(),
                      onChange: handleDeadLineCheckedChange,
                      checked: true
                    })
                  })
                }
                return [...rows];
              });
            }

            // carrega as selecções das checkboxes para as kilometragens
            if(loadedProfile.profileAnnualKms && loadedProfile.profileAnnualKms.length > 0){
              setAnnualKmValues(rows => {
                // coloca as selecções do perfil como checked
                rows.map(row => {
                  if(loadedProfile.profileAnnualKms.some(x => x.value === String(row.value))){
                    row.checked = true;
                  }
                  return row;
                });

                // procura os valores que estão no perfil e que não existem no appsettings e adiciona
                let valuesToAdd = loadedProfile.profileAnnualKms.filter(x => !rows.some(y => String(y.value) === x.value));
                if(valuesToAdd && valuesToAdd.length > 0){
                  valuesToAdd.forEach(value => {
                    rows.push({
                      value: value.value.toString(),
                      onChange: handleAnnualKmCheckedChange,
                      checked: true
                    })
                  })
                }
                return [...rows];
              });
            }

            

            // POPULATE DEFINIÇÕES DE ACESSO A PARTIR DUM PERFIL EXISTENTE
            // MENUS
            // MENUS
            if(loadedProfile.profileWebPages && loadedProfile.profileWebPages.length > 0){
              loadedProfile.profileWebPages.map(profileWebpage => { 
                setWebPages(webPageRows => {
                  webPageRows.map(webpage=> {
                    if(webpage.id === profileWebpage.webPageId){
                      webpage.switchToggle = profileWebpage.isSwitchToggled;
                      webpage.seeCheckbox = profileWebpage.isVisible;
                      webpage.editCheckbox = profileWebpage.isEditable;
                      webpage.validateCheckbox = profileWebpage.hasValidationRules;
                      webpage.required = profileWebpage.required;
                      webpage.editRequired = profileWebpage.editRequired;
                    }
                    return {...webpage};
                  })
                  return [...webPageRows];
                });

                setMenuSwitch(switchMenus => {
                  switchMenus.map(s => {
                    if(s.value === profileWebpage.webPageId){
                      s.checked = profileWebpage.isSwitchToggled;
                    }
                    return s;
                  })
                  return [...switchMenus]; 
                });
                setMenuSeeCheckbox(seeMenus => {
                  seeMenus.map(s => {
                    if(s.value === profileWebpage.webPageId){
                      s.checked = profileWebpage.isVisible;
                    }
                    return s;
                  })
                  return [...seeMenus]; 
                });
                setMenuEditCheckbox(editMenus => {
                  editMenus.map(s => {
                    if(s.value === profileWebpage.webPageId){
                      s.checked = profileWebpage.isEditable;
                    }
                    return s;
                  })
                  return [...editMenus]; 
                });
                setMenuValidateCheckbox(validateMenus => {
                  validateMenus.map(s => {
                    if(s.value === profileWebpage.webPageId){
                      s.checked = profileWebpage.hasValidationRules;
                    }
                    return s;
                  })
                  return [...validateMenus]; 
                });
                // SUBMENUS
                // SUBMENUS
                profileWebpage.profileWebSubPages.map(profileSubmenu => {
                  setWebSubPages(submenuRows => { 
                    submenuRows.map(submenu => {
                      if(submenu.id === profileSubmenu.webSubPageId){
                        submenu.switchToggle = profileSubmenu.isSwitchToggled;
                        submenu.seeCheckbox = profileSubmenu.isVisible;
                        submenu.editCheckbox = profileSubmenu.isEditable;
                        submenu.validateCheckbox = profileSubmenu.hasValidationRules;
                      }
                      return {...submenu};
                    })
                    return [...submenuRows];
                  });
                  setSubMenuSwitch(submenuSwitch => {
                    submenuSwitch.map(s => {
                      if(s.value === profileSubmenu.webSubPageId){
                        s.checked = profileSubmenu.isSwitchToggled;
                      }
                      return s;
                    })
                    return [...submenuSwitch]; 
                  });
                  setSubMenuSeeCheckbox(submenuSee => {
                    submenuSee.map(s => {
                      if(s.value === profileSubmenu.webSubPageId){
                        s.checked = profileSubmenu.isVisible;
                      }
                      return s;
                  })
                  return [...submenuSee]; 
                  });
                  setSubMenuEditCheckbox(submenuEdit => {
                    submenuEdit.map(s => {
                      if(s.value === profileSubmenu.webSubPageId){
                        s.checked = profileSubmenu.isEditable;
                      }
                      return s;
                  })
                  return [...submenuEdit]; 
                  });
                  setSubMenuValidateCheckbox(submenuValidate => {
                    submenuValidate.map(s => {
                      if(s.value === profileSubmenu.webSubPageId){
                        s.checked = profileSubmenu.hasValidationRules;
                      }
                      return s;
                  })
                  return [...submenuValidate]; 
                  });
                  // CAMPOS
                  // CAMPOS
                  profileSubmenu.profileWebFields.map((profileField,fieldIndex) => {
                    setWebFields(fieldRows => { 
                      fieldRows.map(field => {
                        if(field.id === profileField.webFieldId){
                          field.switchToggle = profileField.isSwitchToggled;
                          field.seeCheckbox = profileField.isVisible;
                          field.editCheckbox = profileField.isEditable;
                          field.validateCheckbox = profileField.hasValidationRules;

                          field.conditionsList = profileField.profileWebFieldConditions.map((x, conditionIndex) => {
                            // let isDisabled = !profileField.isSwitchToggled
                            let newConditionDropDownProps: DropDownProps = {
                              id: x.id.toString(),
                              name: `dropProps+${profileField.webFieldId}+${conditionIndex}`,
                              value: x.conditionSymbol,
                              // defaultValue: x.conditionSymbol,
                              onChange: handleConditionDropPropsChange,
                              // disabled: isDisabled
                            }
                            let newConditionTextFieldProps: TextFieldProps = {
                              id: x.id.toString(),
                              name: `textFieldProps+${profileField.webFieldId}+${conditionIndex}`,
                              value: x.value,
                              // defaultValue: x.value,
                              placeholder: 'Indicar condição',
                              onChange: handleConditionTextFieldPropsChange,
                              // disabled: isDisabled
                            }

                            let newCondition: Condition = {
                              dropProps: newConditionDropDownProps,
                              textFieldProps: newConditionTextFieldProps,
                            
                              // tem de ser convertido de evento para botão para poder desactivar
                              removeButtonProps: {
                                // disabled: isDisabled,
                                onClick: handleRemoveConditionCallback(profileField.webFieldId, conditionIndex)
                              },
                              saveButtonProps: {
                                // disabled: isDisabled,
                                hidden: true,
                                // onClick: () => alert('guardar condição'),//handleSaveConditionCallback(index, updatedField.conditionsList.length)
                              }
                            }
                            return newCondition;

                          })
                        }
                        return {...field};
                      })
                      return [...fieldRows];
                    });

                    setFieldSwitch(fieldSwitch => {
                      fieldSwitch.map(s => {
                        if(s.value === profileField.webFieldId){
                          s.checked = profileField.isSwitchToggled;
                        }
                        return s;
                      })
                      return [...fieldSwitch]; 
                    });
                    setFieldSeeCheckbox(fieldSee => {
                      fieldSee.map(s => {
                        if(s.value === profileField.webFieldId){
                          s.checked = profileField.isVisible;
                        }
                        return s;
                    })
                    return [...fieldSee]; 
                    });
                    setFieldEditCheckbox(fieldEdit => {
                      fieldEdit.map(s => {
                        if(s.value === profileField.webFieldId){
                          s.checked = profileField.isEditable;
                        }
                        return s;
                    })
                    return [...fieldEdit]; 
                    });
                    setFieldValidateCheckbox(fieldValidate => {
                      fieldValidate.map(s => {
                        if(s.checkboxProps.value === profileField.webFieldId){
                          s.checkboxProps.checked = profileField.hasValidationRules;
                          // s.canEditConditions = profileField.mustHaveValidationRules;
                        }
                        return s;
                    })
                    return [...fieldValidate]; 
                    });
                    return profileField;
                  })
                  return profileSubmenu;
                });
                return profileWebpage;
              });

              setHeadSwitch(true);
            }

          });
        }
      }
    });

  },[
    settings.Data.Vehicles_UseProps,
    // insuranceValues,

    handleAnnualKmCheckedChange,
    handleDeadLineCheckedChange,
    handleTypeofRentalCheckedChange, 
    handleCommercialConditionsCheckedChange, 
    handleInsurancesCheckedChange, 
    handleLiabilityOptionsCheckedChange, 
    handleVehicleDamageOptionsCheckedChange, 
    handleOccupiersOptionsCheckedChange, 
    handleRoadAssistanceOptionsCheckedChange, 
    handleIsolatedGlassBreakOptionsCheckedChange, 

    handleSelectAllTypesofRentalOnChange,
    handleSelectAllCommercialConditionsOnChange,
    handleSelectAllInsurancePackagesOnChange,
    handleSelectAllLiabilitiesOnChange,
    handleSelectAllOwnDamagesOnChange,
    handleSelectAllOccupiersOnChange,
    handleSelectAllRoadAssistancesOnChange,
    handleSelectAllIsolagedGlassBreakagesOnChange,
    params.profileId,
    handleConditionTextFieldPropsChange,
    handleConditionDropPropsChange,
    handleRemoveConditionCallback,
    handleFunctionalGroupsChange
  ])

  // DEFINIÇÕES DE ACESSO STATES
  const [fullAccessSwitch, setFullAccessSwitch] = useState<boolean>(false);

  const [headSwitch, setHeadSwitch] = useState<boolean>(false);
  const [menuSwitch, setMenuSwitch] = useState<SwitchProps[]>([])
  const [menuSeeCheckbox, setMenuSeeCheckbox] = useState<CheckboxProps[]>([])
  const [menuEditCheckbox, setMenuEditCheckbox] = useState<CheckboxProps[]>([])
  const [menuValidateCheckbox, setMenuValidateCheckbox] = useState<CheckboxProps[]>([])

  const [subMenuSwitch, setSubMenuSwitch] = useState<SwitchProps[]>([])
  const [submenuSeeCheckbox, setSubMenuSeeCheckbox] = useState<CheckboxProps[]>([])
  const [submenuEditCheckbox, setSubMenuEditCheckbox] = useState<CheckboxProps[]>([])
  const [submenuValidateCheckbox, setSubMenuValidateCheckbox] = useState<CheckboxProps[]>([])

  const [fieldSwitch, setFieldSwitch] = useState<SwitchProps[]>([])
  const [fieldSeeCheckbox, setFieldSeeCheckbox] = useState<CheckboxProps[]>([])
  const [fieldEditCheckbox, setFieldEditCheckbox] = useState<CheckboxProps[]>([])
  const [fieldValidateCheckbox, setFieldValidateCheckbox] = useState<{
    checkboxProps: CheckboxProps,
    canEditConditions:boolean
  }[]>([{
    checkboxProps: {
      name: '',
      checked: false,
    },
    canEditConditions: false
  }])

  // DEFINIÇÕES DE ACESSO CALLBACKS
  
  
  


  // Campos
  const fieldSwitchPropsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFieldSwitch(fields => {
      return fields.map(field => {
        if( field.value === Number(e.target.value)){
          return {...field, checked: checked};
        }
        return field;
      })
    });

    // // disable de condições existentes por campo

    // setWebFields(fields => {
    //   fields.map(field => {
    //     if(field.id.toString() === e.target.value){
    //       field.conditionsList.map(x => {
    //         let updatedCondition = {...x}
    //         updatedCondition.dropProps.disabled = !checked;
    //         updatedCondition.textFieldProps.disabled = !checked;
    //         updatedCondition.removeButtonProps.disabled = !checked;
    //         updatedCondition.saveButtonProps.disabled = !checked;
    //         return updatedCondition;
    //       })
    //     }
    //     return {...field}

    //   })
    //   return [...fields]
    // })
  },[])

  const fieldSeeCheckboxPropsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFieldSeeCheckbox(menus => {
      // let menusToUpdate = [...menus];
      if(menus && menus.length > 0){
        menus.map(menu => {
          if( menu.value === Number(e.target.value)){
            if(!menu.disabled){
              menu.checked = checked;
            }
          }
          return menu;
        })
        return [...menus];
      }
      return [];
    });
  },[])

  const fieldEditCheckboxPropsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFieldEditCheckbox(menus => {
      // let menusToUpdate = [...menus];
      if(menus && menus.length > 0){
        menus.map(menu => {
          if( menu.value === Number(e.target.value)){
            if(!menu.disabled){
              menu.checked = checked;
            }
          }
          return menu;
        })
        return [...menus];
      }
      return [];
    });
  },[])

  const fieldValidateCheckboxPropsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFieldValidateCheckbox(menus => {
      // let menusToUpdate = [...menus];
      if(menus && menus.length > 0){
        menus.map(menu => {
          if( menu.checkboxProps.value === Number(e.target.value)){
            menu.checkboxProps.checked = checked;
            // menu.canEditConditions = checked;
          }
          return menu;
        })
        return [...menus];
      }
      return [];
    });
  },[])


  //Submenus
  const subMenuSwitchPropsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSubMenuSwitch(submenus => {
      submenus.map(submenu => {
        if( submenu.value === Number(e.target.value)){
          submenu.checked = checked;

          let switchesToToggle = settings.Data.Profile_Web_Fields.filter(x => x.profileWebSubPageId === submenu.value);
          setFieldSwitch(fields => {
            fields.map(field => {
              if(switchesToToggle.some(x => x.id === field.value)){
                field.checked = checked;
              }
              return field;
            })
            return [...fields];
          });
        }
        return submenu;
      })
      return [...submenus];
    });
  },[settings.Data.Profile_Web_Fields]);

  const submenuSeeCheckboxPropsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSubMenuSeeCheckbox(menus => {
      // let menusToUpdate = [...menus];
      if(menus && menus.length > 0){
        menus.map(menu => {
          if( menu.value === Number(e.target.value)){
            menu.checked = checked;

            let checkboxesToToggle = settings.Data.Profile_Web_Fields.filter(x => x.profileWebSubPageId === menu.value);
            setFieldSeeCheckbox(fields => {
              fields.map(field => {
                if(checkboxesToToggle.some(x => x.id === field.value)){
                  if(!field.disabled){
                    field.checked = checked;
                  }
                }
                return field;
              })
              return [...fields];
            });

          }
          return menu;
        })
        return [...menus];
      }
      return [];
    });
  },[settings.Data.Profile_Web_Fields])

  const submenuEditCheckboxPropsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSubMenuEditCheckbox(menus => {
      // let menusToUpdate = [...menus];
      if(menus && menus.length > 0){
        menus?.map(menu => {
          if( menu.value === Number(e.target.value)){
            menu.checked = checked;

            let checkboxesToToggle = settings.Data.Profile_Web_Fields.filter(x => x.profileWebSubPageId === menu.value);
            setFieldEditCheckbox(fields => {
              fields.map(field => {
                if(checkboxesToToggle.some(x => x.id === field.value)){
                  if(!field.disabled){
                    field.checked = checked;
                  }
                }
                return field;
              })
              return [...fields];
            });
          }
          return menu;
        })
        return [...menus];
      }
      return [];
    });
  },[settings.Data.Profile_Web_Fields])

  const submenuValidateCheckboxPropsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSubMenuValidateCheckbox(menus => {
      // let menusToUpdate = [...menus];
      if(menus && menus.length > 0){
        menus?.map(menu => {
          if( menu.value === Number(e.target.value)){
            menu.checked = checked;

            let checkboxesToToggle = settings.Data.Profile_Web_Fields.filter(x => x.profileWebSubPageId === menu.value);
            setFieldValidateCheckbox(fields => {
              fields.map(field => {
                if(checkboxesToToggle.some(x => x.id === field.checkboxProps.value)){
                    field.checkboxProps.checked = checked;
                }
                return field;
              })
              return [...fields];
            });
          }
          return menu;
        })
        return [...menus];
      }
      return [];
    });
  },[settings.Data.Profile_Web_Fields])


  //Menus
  const menuSwitchPropsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setMenuSwitch(menus => {
      // let menusToUpdate = [...menus];
      menus.map(menu => {
        if( menu.value === Number(e.target.value)){
          menu.checked = checked;

          let switchesToToggle = settings.Data.Profile_WebSub_Pages.filter(x => x.profileWebPageId === menu.value);
          switchesToToggle.forEach(x => {
            //simula evento para dar trigger ao evento de mudança de estado
            let event = { target: { value: x.id } } as unknown as ChangeEvent<HTMLInputElement>;
            subMenuSwitchPropsOnChange(event, checked);
          })

        }
        return menu;
      })
      return [...menus];
    });
  },[settings.Data.Profile_WebSub_Pages, subMenuSwitchPropsOnChange])

  const menuSeeCheckboxPropsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setMenuSeeCheckbox(menus => {
      // let menusToUpdate = [...menus];
      if(menus && menus.length > 0){
        menus?.map(menu => {
          if( menu.value === Number(e.target.value)){
            menu.checked = checked;

            let switchesToToggle = settings.Data.Profile_WebSub_Pages.filter(x => x.profileWebPageId === menu.value);
            switchesToToggle.forEach(x => {
              //simula evento para dar trigger ao evento de mudança de estado
              let event = { target: { value: x.id } } as unknown as ChangeEvent<HTMLInputElement>;
              submenuSeeCheckboxPropsOnChange(event, checked);
            })

          }
          return menu;
        })
        return [...menus];
      }
      return [];
    });
  },[settings.Data.Profile_WebSub_Pages, submenuSeeCheckboxPropsOnChange])

  const menuEditCheckboxPropsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setMenuEditCheckbox(menus => {
      // let menusToUpdate = [...menus];
      if(menus && menus.length > 0){
        menus?.map(menu => {
          if( menu.value === Number(e.target.value)){
            menu.checked = checked;

            let switchesToToggle = settings.Data.Profile_WebSub_Pages.filter(x => x.profileWebPageId === menu.value);
            switchesToToggle.forEach(x => {
              //simula evento para dar trigger ao evento de mudança de estado
              let event = { target: { value: x.id } } as unknown as ChangeEvent<HTMLInputElement>;
              submenuEditCheckboxPropsOnChange(event, checked);
            })

          }
          return menu;
        })
        return [...menus];
      }
      return [];
    });
  },[settings.Data.Profile_WebSub_Pages, submenuEditCheckboxPropsOnChange])

  const menuValidateCheckboxPropsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setMenuValidateCheckbox(menus => {
      // let menusToUpdate = [...menus];
      if(menus && menus.length > 0){
        menus?.map(menu => {
          if( menu.value === Number(e.target.value)){
            menu.checked = checked;

            let switchesToToggle = settings.Data.Profile_WebSub_Pages.filter(x => x.profileWebPageId === menu.value);
            switchesToToggle.forEach(x => {
              //simula evento para dar trigger ao evento de mudança de estado
              let event = { target: { value: x.id } } as unknown as ChangeEvent<HTMLInputElement>;
              submenuValidateCheckboxPropsOnChange(event, checked);
            })
          }
          return menu;
        })
        return [...menus];
      }
      return [];
    });
  },[settings.Data.Profile_WebSub_Pages, submenuValidateCheckboxPropsOnChange])

  const checkAllMenus = useCallback((checked:boolean) => {
    // populate menus
    setMenuSwitch(rows => {
      rows.map(row => {
        row.checked = checked;
        return row;
      })
      return [...rows]
    });
    setMenuSeeCheckbox(rows => {
      if(rows && rows.length > 0) {
        rows.map(row => {
          if(!row.disabled){
            row.checked = checked;
          }
          return row;
        })
        return [...rows]
      }
      return [];
    });
    setMenuEditCheckbox(rows => {
      if(rows && rows.length > 0) {
        rows.map(row => {
          if(!row.disabled){
            row.checked = checked;
          }
          return row;
        })
        return [...rows]
      }
      return [];
    });
    setMenuValidateCheckbox(rows => {
      if(rows && rows.length > 0) {
        rows.map(row => {
          row.checked = checked;
          return row;
        })
        return [...rows]
      }
      return [];
    });
    // populate submenus
    setSubMenuSwitch(rows => {
      rows.map(row => {
        row.checked = checked;
        return row;
      })
      return [...rows]
    });
    setSubMenuSeeCheckbox(rows => {
      if(rows && rows.length > 0) {
        rows.map(row => {
          if(!row.disabled){
            row.checked = checked;
          }
          return row;
        })
        return [...rows]
      }
      return [];
    });
    setSubMenuEditCheckbox(rows => {
      if(rows && rows.length > 0) {
        rows.map(row => {
          if(!row.disabled){
            row.checked = checked;
          }
          return row;
        })
        return [...rows]
      }
      return [];
    });
    setSubMenuValidateCheckbox(rows => {
      if(rows && rows.length > 0) {
        rows.map(row => {
          row.checked = checked;
          return row;
        })
        return [...rows]
      }
      return [];
    });
    // populate fields
    setFieldSwitch(rows => {
      if(rows && rows.length > 0) {
        rows.map(row => {
          row.checked = checked;
          return row;
        })
        return [...rows]
      }
      return [];
    });
    setFieldSeeCheckbox(rows => {
      if(rows && rows.length > 0) {
        rows.map(row => {
          if(!row.disabled){
            row.checked = checked;
          }
          return row;
        })
        return [...rows]
      }
      return [];
    });
    setFieldEditCheckbox(rows => {
      if(rows && rows.length > 0) {
        rows.map(row => {
          if(!row.disabled){
            row.checked = checked;
          }
          return row;
        })
        return [...rows]
      }
      return [];
    });
    setFieldValidateCheckbox(rows => {
      if(rows && rows.length > 0) {
        rows.map(row => {
          row.checkboxProps.checked = checked;
          return row;
        })
        return [...rows]
      }
      return [];
    });
  },[])

  const headSwitchPropsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setHeadSwitch(checked);
    checkAllMenus(checked);
  },[checkAllMenus])

  const fullAccessSwitchPropsOnChange = useCallback(async (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFullAccessSwitch(checked);
    setValueChecked(false);
      
    // (DES)SELCCIONA TODAS AS DEFINIÇÕES DE ACESSO
    setHeadSwitch(checked);
    checkAllMenus(checked);

    // update profile hasFullAccess property with checked value using setProfile
    setProfile(prevProfile => ({...prevProfile, hasFullAccess: checked}));

    // (DES)SELECCIONA TODAS AS CARACTERISTICAS
    setAnnualKmValues(rows => {
      rows.map(row => {
        row.checked = checked;   
        return row;
      })

      setProfile(x => {
        let updatedProfile = {...x};

        rows.map((element) => {
          const annualKmsExists = updatedProfile.profileAnnualKms.some(y => y.value === element.value);
          if (element.checked && !annualKmsExists) {
            updatedProfile.profileAnnualKms.push({
              id: 0,
              profileId: updatedProfile.id,
              value: String(element.value),
            });
          }
          if (!element.checked && annualKmsExists) {
            updatedProfile.profileAnnualKms = updatedProfile.profileAnnualKms.filter(y => y.value !== element.value);
          }
          return element;
        });  
        return updatedProfile;
      })

      return [...rows];
    });

    setDeadlineValues(rows => {
      rows.map(row => {
        row.checked = checked;   
        return row;
      })

      setProfile(x => {
        let updatedProfile = {...x};

        rows.map((element) => {
          const deadlinesExists = updatedProfile.profileDeadlines.some(y => y.value === String(element.value));
          if (element.checked && !deadlinesExists) {
            updatedProfile.profileDeadlines.push({
              id: 0,
              profileId: updatedProfile.id,
              value: String(element.value),
            });
          }
          if (!element.checked && deadlinesExists) {
            updatedProfile.profileDeadlines = updatedProfile.profileDeadlines.filter(y => y.value !== String(element.value));
          }
          return element;
        });  
        return updatedProfile;
      })

      return [...rows];
    });

    setPaymentTerms(rows => {
      rows.map(row => {
        row.checked = checked;   
        return row;
      })
      
      setProfile(x => {
        let updatedProfile = {...x};

        rows.map((element) => {
          const paymentTermsExists = updatedProfile.profilePaymentTerms.some(y => y.value === String(element.value));
          if (element.checked && !paymentTermsExists) {
            updatedProfile.profilePaymentTerms.push({
              id: 0,
              profileId: updatedProfile.id,
              value: String(element.value),
            });
          }
          if (!element.checked && paymentTermsExists) {
            updatedProfile.profilePaymentTerms = updatedProfile.profilePaymentTerms.filter(y => y.value !== String(element.value));
          }
          return element;
        });  
        return updatedProfile;
      })

      return [...rows];
    });
      
    setTypeofRental(typeofRentalRows => {
      typeofRentalRows.map((typeofRentalRow,index) => {
        typeofRentalRow.checked = checked;
        return typeofRentalRow;
      })

      setProfile(x => {
        let updatedProfile = {...x};

        typeofRentalRows.map((typeofRentalRow) => {
          const rentalTypeExists = updatedProfile.profileRentalTypes.some(y => y.value === typeofRentalRow.value);
          if (typeofRentalRow.checked && !rentalTypeExists) {
            updatedProfile.profileRentalTypes.push({
              id: 0,
              profileId: updatedProfile.id,
              value: typeofRentalRow.value,
            });
          }
          if (!typeofRentalRow.checked && rentalTypeExists) {
            updatedProfile.profileRentalTypes = updatedProfile.profileRentalTypes.filter(y => y.value !== typeofRentalRow.value);
          }
          return typeofRentalRow;
        });  
        return updatedProfile;
      })

      return [...typeofRentalRows];
    });

    const commercialConditionsData = await vehiclesService.getCommercialConditions();
    const insurancePackagesData = await vehiclesService.getAllInsurancePackage();
    const insurancePackageLinesData = await vehiclesService.getInsurancePackageLine();

    if(checked){
      let commercialConditionRelatedInsurances: {
        commercialCondition: string,
        insurancePackages: string[]
      }[] = [];
      // // carrega as selecções das checkboxes para os seguros (pacotes de seguros)
      let loadedInsuranceValues: {
        commercialCondition: string,
        checkboxProps: CheckboxPropsWithValue//CheckboxProps
      }[] = [];

      if (commercialConditionsData && commercialConditionsData.succeeded){
        commercialConditionsData.value.forEach((ccline: any) => {
          // cria objecto com relações de pacotes de seguros por condição comercial
          if(!commercialConditionRelatedInsurances.some(x => x.commercialCondition === ccline.value)){
            let commercialCondition = commercialConditionsData.value.find((x: any) => x.value === ccline.value);
            if(commercialCondition) {
              commercialConditionRelatedInsurances.push({
                commercialCondition: commercialCondition.value,
                insurancePackages: commercialCondition.insurance_Filter.split('|').filter((x:string) => x !== "''")
              })
            }
          }
          // carrega condições comerciais
          setCommercialConditions(ccrows => {
            let updatedCommercialConditionsRows =  [...ccrows];
            if( !updatedCommercialConditionsRows.some( ccrow =>  ccrow.checkboxProps.value === ccline.value )){
              updatedCommercialConditionsRows.push({ 
                rentalType: ccline.code,
                insurance_Filter: ccline.insurance_Filter,
                checkboxProps: {
                  value: ccline.value,
                  label: `${ccline.label}`,
                  onChange:handleCommercialConditionsCheckedChange,
                  checked: checked
                }
              })
            }

            setProfile(x => {
              let updatedProfile = {...x};
      
              updatedCommercialConditionsRows.map((element) => {
                const commercialConditionsExists = updatedProfile.profileCommercialConditions.some(y => y.value === element.checkboxProps.value);
                if (element.checkboxProps.checked && !commercialConditionsExists) {
                  updatedProfile.profileCommercialConditions.push({
                    id: 0,
                    profileId: updatedProfile.id,
                    rentalType: element.rentalType,
                    value: element.checkboxProps.value,
                  });
                }
                if (!element.checkboxProps.checked && commercialConditionsExists) {
                  updatedProfile.profileCommercialConditions = updatedProfile.profileCommercialConditions.filter(y => y.value !== element.checkboxProps.value);
                }
                return element;
              });  
              return updatedProfile;
            })
          
            return updatedCommercialConditionsRows.sort((a, b) => {
              if (a.checkboxProps.label === 'Selecionar todos') return -1;
              if (b.checkboxProps.label === 'Selecionar todos') return 1;
              return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
            });
          })

        });
      }

      if (insurancePackagesData && insurancePackagesData.succeeded) {
        commercialConditionRelatedInsurances.forEach((commercialCondition, index) => { 
          commercialCondition.insurancePackages.forEach((relatedInsurance) => {
            if(!loadedInsuranceValues.some(x => x.checkboxProps.value === `${relatedInsurance}-${commercialCondition.commercialCondition}`
              && x.commercialCondition === commercialCondition.commercialCondition
            )){
              const currentInsurance = insurancePackagesData.value.find((x: any) => x.value === relatedInsurance);
              if ( currentInsurance){
                loadedInsuranceValues.push({
                  commercialCondition: commercialCondition.commercialCondition,
                  checkboxProps: {
                    value: `${currentInsurance.value}-${commercialCondition.commercialCondition}`,
                    label: `${commercialCondition.commercialCondition} - ${currentInsurance.label}`,
                    onChange: handleInsurancesCheckedChange,
                    checked: checked
                  }
                })
              }
            }
            
            setInsuranceValues(insrows => {
              let updatedInsuranceRows = [...insrows];

              if(!updatedInsuranceRows.some(x => 
                x.checkboxProps.value === `${relatedInsurance}-${commercialCondition.commercialCondition}` && 
                x.commercialCondition === commercialCondition.commercialCondition) ) {
                  const currentInsurance = insurancePackagesData.value.find((x: any) => x.value === relatedInsurance);
                  if ( currentInsurance){
                    updatedInsuranceRows.push({
                      commercialCondition: commercialCondition.commercialCondition,
                      checkboxProps: {
                        value: `${currentInsurance.value}-${commercialCondition.commercialCondition}`,
                        label: `${commercialCondition.commercialCondition} - ${currentInsurance.label}`,
                        onChange: handleInsurancesCheckedChange,
                        checked: checked
                      }
                    });  
                  }
                }
              setProfile(x => {
                let updatedProfile = {...x};
        
                updatedInsuranceRows.map((element) => {
                  const insurancesExists = updatedProfile.profileInsurances.some(y => y.value === element.checkboxProps.value);
                  if (element.checkboxProps.checked && !insurancesExists) {
                    updatedProfile.profileInsurances.push({
                      id: 0,
                      profileId: updatedProfile.id,
                      value: element.checkboxProps.value,
                    });
                  }
                  if (!element.checkboxProps.checked && insurancesExists) {
                    updatedProfile.profileInsurances = updatedProfile.profileInsurances.filter(y => y.value !== element.checkboxProps.value);
                  }
                  return element;
                });  
                return updatedProfile;
              });

              return updatedInsuranceRows.sort((a, b) => {
                if (a.checkboxProps.label === 'Selecionar todos') return -1;
                if (b.checkboxProps.label === 'Selecionar todos') return 1;
                return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
              });
            });
    
          })
        })
      }

      if(insurancePackageLinesData && insurancePackageLinesData.length > 0) {
        loadedInsuranceValues.forEach(currentInsurance => {
          const insuranceRequestValue = currentInsurance.checkboxProps.value.split("-")[0];


          insurancePackageLinesData.filter((x: any) => x.insurance_Package === insuranceRequestValue)
          .map((item: { insurance: string, description: string, type: string, use: string}) => {
          
            let use = settings.Data.Vehicles_UseProps.find(x => x.value === item.use)?.label
            let insuranceLabel = currentInsurance.checkboxProps.label;


            //Liability
            if(item.type === "Liability"){
              setLiabilityOptions(rows => {
                let updatedRows = [...rows]
                if (!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${currentInsurance.checkboxProps.value}`) && insuranceLabel !== '') {
                  updatedRows.push({
                    insurancePackage: currentInsurance.checkboxProps.value,
                    checkboxProps: {
                      label: `${insuranceLabel} - ${use} - ${item.insurance} - ${item.description}`,
                      value: `${item.insurance}-${currentInsurance.checkboxProps.value}`,
                      onChange: handleLiabilityOptionsCheckedChange,
                      checked: checked
                    }
                  })
                }

                setProfile(x => {
                  let updatedProfile = {...x};
          
                  updatedRows.map((element) => {
                    const liabilitiesExists = updatedProfile.profileLiabilities.some(y => y.value === element.checkboxProps.value);
                    if (element.checkboxProps.checked && !liabilitiesExists) {
                      updatedProfile.profileLiabilities.push({
                        id: 0,
                        profileId: updatedProfile.id,
                        insurancePackage: element.insurancePackage,
                        value: element.checkboxProps.value,
                      });
                    }
                    if (!element.checkboxProps.checked && liabilitiesExists) {
                      updatedProfile.profileLiabilities = updatedProfile.profileLiabilities.filter(y => y.value !== element.checkboxProps.value);
                    }
                    return element;
                  });  
                  return updatedProfile;
                })

                return updatedRows.sort((a, b) => {
                  if (a.checkboxProps.label === 'Selecionar todos') return -1;
                  if (b.checkboxProps.label === 'Selecionar todos') return 1;
                  return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                });
              })
            }
            // Own Damage
            if(item.type === "Own Damage"){
              setVehicleDamageOptions(rows => {
                let updatedRows = [...rows]
                if (!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${currentInsurance.checkboxProps.value}`) && insuranceLabel !== '') {
                  updatedRows.push({
                    insurancePackage: currentInsurance.checkboxProps.value,
                    checkboxProps: {
                      label: `${insuranceLabel} - ${use} - ${item.insurance} - ${item.description}`,
                      value: `${item.insurance}-${currentInsurance.checkboxProps.value}`,
                      onChange: handleVehicleDamageOptionsCheckedChange,
                      checked: checked
                    }
                  });
                }
                setProfile(x => {
                  let updatedProfile = {...x};
          
                  updatedRows.map((element) => {
                    const ownDamageExists = updatedProfile.profileVehicleDamages.some(y => y.value === element.checkboxProps.value);
                    if (element.checkboxProps.checked && !ownDamageExists) {
                      updatedProfile.profileVehicleDamages.push({
                        id: 0,
                        profileId: updatedProfile.id,
                        insurancePackage: element.insurancePackage,
                        value: element.checkboxProps.value,
                      });
                    }
                    if (!element.checkboxProps.checked && ownDamageExists) {
                      updatedProfile.profileVehicleDamages = updatedProfile.profileVehicleDamages.filter(y => y.value !== element.checkboxProps.value);
                    }
                    return element;
                  });  
                  return updatedProfile;
                })

                return updatedRows.sort((a, b) => {
                  if (a.checkboxProps.label === 'Selecionar todos') return -1;
                  if (b.checkboxProps.label === 'Selecionar todos') return 1;
                  return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                });
              })
            }
            //Other Passenger
            if(item.type === "Other Passenger"){
              setOccupiersOptions(rows => {
                let updatedRows = [...rows]
                if (!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${currentInsurance.checkboxProps.value}`) && insuranceLabel !== '') {
                  updatedRows.push({
                    insurancePackage: currentInsurance.checkboxProps.value,        
                    checkboxProps: {
                      label: `${insuranceLabel} - ${use} - ${item.insurance} - ${item.description}`,
                      value: `${item.insurance}-${currentInsurance.checkboxProps.value}`,
                      onChange: handleOccupiersOptionsCheckedChange,
                      checked: checked
                    }  
                  });
                }
                setProfile(x => {
                  let updatedProfile = {...x};
          
                  updatedRows.map((element) => {
                    const occupiersExists = updatedProfile.profileOccupiers.some(y => y.value === element.checkboxProps.value);
                    if (element.checkboxProps.checked && !occupiersExists) {
                      updatedProfile.profileOccupiers.push({
                        id: 0,
                        profileId: updatedProfile.id,
                        insurancePackage: element.insurancePackage,
                        value: element.checkboxProps.value,
                      });
                    }
                    if (!element.checkboxProps.checked && occupiersExists) {
                      updatedProfile.profileOccupiers = updatedProfile.profileOccupiers.filter(y => y.value !== element.checkboxProps.value);
                    }
                    return element;
                  });  
                  return updatedProfile;
                })

                return updatedRows.sort((a, b) => {
                  if (a.checkboxProps.label === 'Selecionar todos') return -1;
                  if (b.checkboxProps.label === 'Selecionar todos') return 1;
                  return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                });
              })
            }
            //Legal Defense
            if(item.type === "Legal Defense"){
              setRoadAssistanceOptions(rows => {
                let updatedRows = [...rows]
                if (!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${currentInsurance.checkboxProps.value}`) && insuranceLabel !== '') {
                  updatedRows.push({
                    insurancePackage: currentInsurance.checkboxProps.value,
                    checkboxProps: {
                      label: `${insuranceLabel} - ${use} - ${item.insurance} - ${item.description}`,
                      value: `${item.insurance}-${currentInsurance.checkboxProps.value}`,
                      onChange: handleRoadAssistanceOptionsCheckedChange,
                      checked: checked
                    }
                  });
                }
                setProfile(x => {
                  let updatedProfile = {...x};
          
                  updatedRows.map((element) => {
                    const profileRoadAssistancesExists = updatedProfile.profileRoadAssistances.some(y => y.value === element.checkboxProps.value);
                    if (element.checkboxProps.checked && !profileRoadAssistancesExists) {
                      updatedProfile.profileRoadAssistances.push({
                        id: 0,
                        profileId: updatedProfile.id,
                        insurancePackage: element.insurancePackage,
                        value: element.checkboxProps.value,
                      });
                    }
                    if (!element.checkboxProps.checked && profileRoadAssistancesExists) {
                      updatedProfile.profileRoadAssistances = updatedProfile.profileRoadAssistances.filter(y => y.value !== element.checkboxProps.value);
                    }
                    return element;
                  });  
                  return updatedProfile;
                })

                return updatedRows.sort((a, b) => {
                  if (a.checkboxProps.label === 'Selecionar todos') return -1;
                  if (b.checkboxProps.label === 'Selecionar todos') return 1;
                  return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                });
              })
            }
            //Fire
            if(item.type === "Fire "){
              setIsolatedGlassBreakOptions(rows => {
                let updatedRows = [...rows]
                if (!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${currentInsurance.checkboxProps.value}`) && insuranceLabel !== '') {
                  updatedRows.push({
                    insurancePackage: currentInsurance.checkboxProps.value,
                    checkboxProps: {
                      label: `${insuranceLabel} - ${use} - ${item.insurance} - ${item.description}`,
                      value: `${item.insurance}-${currentInsurance.checkboxProps.value}`,
                      onChange: handleIsolatedGlassBreakOptionsCheckedChange,
                      checked: checked
                    }
                  })
                }
                setProfile(x => {
                  let updatedProfile = {...x};
          
                  updatedRows.map((element) => {
                    const profileIsolatedGlassBreaksExists = updatedProfile.profileIsolatedGlassBreaks.some(y => y.value === element.checkboxProps.value);
                    if (element.checkboxProps.checked && !profileIsolatedGlassBreaksExists) {
                      updatedProfile.profileIsolatedGlassBreaks.push({
                        id: 0,
                        profileId: updatedProfile.id,
                        insurancePackage: element.insurancePackage,
                        value: element.checkboxProps.value,
                      });
                    }
                    if (!element.checkboxProps.checked && profileIsolatedGlassBreaksExists) {
                      updatedProfile.profileIsolatedGlassBreaks = updatedProfile.profileIsolatedGlassBreaks.filter(y => y.value !== element.checkboxProps.value);
                    }
                    return element;
                  });  
                  return updatedProfile;
                })

                return updatedRows.sort((a, b) => {
                  if (a.checkboxProps.label === 'Selecionar todos') return -1;
                  if (b.checkboxProps.label === 'Selecionar todos') return 1;
                  return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                });
              })
            }

            return item;
          })
        })
      }
    } else {
      setLiabilityOptions([]);
      setVehicleDamageOptions([]);
      setOccupiersOptions([]);
      setRoadAssistanceOptions([]);
      setIsolatedGlassBreakOptions([]);
      setCommercialConditions([]);
      setInsuranceValues([]);
      setProfile(x => ({
        ...x,
        profileCommercialConditions: [],
        profileInsurances: [],
        profileVehicleDamages: [],
        profileOccupiers: [],
        profileRoadAssistances: [],
        profileIsolatedGlassBreaks: [],
        profileLiabilities: []
      }));
    }  
  },[
    checkAllMenus, 
    handleCommercialConditionsCheckedChange, 
    handleInsurancesCheckedChange, 
    handleIsolatedGlassBreakOptionsCheckedChange, 
    handleLiabilityOptionsCheckedChange, 
    handleOccupiersOptionsCheckedChange, 
    handleRoadAssistanceOptionsCheckedChange, 
    handleVehicleDamageOptionsCheckedChange,
    settings.Data.Vehicles_UseProps,
  ])

  // LEITURA DAS PROPRIEDADES DO APPSETTINGS
  useEffect(() => {
    SettingsService.getInstance()
      .then(x => setSettings(x.settings));
  }, []);

  // RENDERIZAÇÃO DE UM PERFIL EXISTENTE
  useEffect(() => {

    if (!pageLoading) {
      loadPage();
      const idUser = localStorage.getItem("userId");
      if (idUser) {
        const id = parseInt(idUser);
        userService.getUserById(id,true).then((user) => {
          if(user.succeeded){
            setCurrentUser(user.value)
          }
        });
      }

      setCommercialConditions(rows => {
        let updatedCommercialConditionsRows = [...rows];

        if(updatedCommercialConditionsRows.length === 0){
          updatedCommercialConditionsRows.push({
            rentalType: '0',
            insurance_Filter: '',
            checkboxProps: {
              value: '0', 
              label: 'Selecionar todos', 
              onChange: handleSelectAllCommercialConditionsOnChange,
              checked: false
            }
          })
        }
        // setPreviousCommercialConditionsState(true);
         
        return updatedCommercialConditionsRows;
      });
      setInsuranceValues(insrows => {
        let updatedInsuranceRows = [...insrows];

        if(updatedInsuranceRows.length === 0){
          updatedInsuranceRows.push({
            commercialCondition: '0',
            checkboxProps: {
              value: '0', 
              label: 'Selecionar todos', 
              onChange: handleSelectAllInsurancePackagesOnChange,
              checked: false
            }
          })
        }
        // setPreviousInsuranceValuesState(true)

        return updatedInsuranceRows
      });
      setLiabilityOptions(rows => {
        let updatedRows = [...rows]

        if(updatedRows.length === 0){
          updatedRows.push({
            insurancePackage: '0',
            checkboxProps: {
              value: '0', 
              label: 'Selecionar todos', 
              onChange: handleSelectAllLiabilitiesOnChange,
              checked: false
            }
          })
        }
        return updatedRows;
      });
      setVehicleDamageOptions(rows => {
        let updatedRows = [...rows]

        if(updatedRows.length === 0){
          updatedRows.push({
            insurancePackage: '0',
            checkboxProps: {
              value: '0', 
              label: 'Selecionar todos', 
              onChange: handleSelectAllOwnDamagesOnChange,
              checked: false
            }
          })
        }
        return updatedRows;
      });
      setOccupiersOptions(rows => {
        let updatedRows = [...rows]

        if(updatedRows.length === 0){
          updatedRows.push({
            insurancePackage: '0',
            checkboxProps: {
              value: '0', 
              label: 'Selecionar todos', 
              onChange: handleSelectAllOccupiersOnChange,
              checked: false
            }
          })
        }
        return updatedRows;
      });

      setRoadAssistanceOptions(rows => {
        let updatedRows = [...rows]

        if(updatedRows.length === 0){
          updatedRows.push({
            insurancePackage: '0',
            checkboxProps: {
              value: '0', 
              label: 'Selecionar todos', 
              onChange: handleSelectAllRoadAssistancesOnChange,
              checked: false
            }
          })
        }
        return updatedRows;
      });
      setIsolatedGlassBreakOptions(rows => {
        let updatedRows = [...rows]

        if(updatedRows.length === 0){
          updatedRows.push({
            insurancePackage: '0',
            checkboxProps: {
              value: '0', 
              label: 'Selecionar todos', 
              onChange: handleSelectAllIsolagedGlassBreakagesOnChange,
              checked: false
            }
          })
        }
        return updatedRows;
      });
    }
    setPageLoading(true);
  }, [
    handleSelectAllCommercialConditionsOnChange, 
    handleSelectAllInsurancePackagesOnChange, 
    handleSelectAllIsolagedGlassBreakagesOnChange, 
    handleSelectAllLiabilitiesOnChange, 
    handleSelectAllOccupiersOnChange, 
    handleSelectAllOwnDamagesOnChange, 
    handleSelectAllRoadAssistancesOnChange, 
    loadPage, pageLoading
  ])

  // VALIDAÇÃO DE ATUALIAZAÇÃO DE ESTADOS DO PERFIL
  useEffect(() => {

    if (valueChecked) {
      // carregamento das checkboxes do tipo de aluguer
      let checkedTypesofRental: BaseProfileProp[]= [];
      let checkedCommercialConditions: ProfileCommercialConditionsProp[] = [];

      if(typeofRental && typeofRental.length > 0 && previousTypeofRentalState.current !== typeofRental) {
        previousTypeofRentalState.current = typeofRental;
        typeofRental.filter(x => x.value !== '0' ).map(async (selectedTypeofRental) => {
          if(selectedTypeofRental.checked){
            // adiciona valor para mapear no perfil
            checkedTypesofRental.push({
              id: 0,
              profileId: 0,
              value: selectedTypeofRental.value
            });
            // carrega valores das condições comerciais para o valor seleciconado
            await vehiclesService.getCommercialConditions(selectedTypeofRental.value).then((data) => {
              if (data.succeeded === true) {
                setCommercialConditions(rows => {
                  let updatedCommercialConditionsRows = [...rows];

                  if(updatedCommercialConditionsRows.length === 0){
                    updatedCommercialConditionsRows.push({
                      rentalType: '0',
                      insurance_Filter: '',
                      checkboxProps: {
                        value: '0', 
                        label: 'Selecionar todos', 
                        onChange: handleSelectAllCommercialConditionsOnChange,
                        checked: false
                      }
                    })
                  }

                  data.value.forEach((line: any) => {
                    if(!updatedCommercialConditionsRows.some(x => x.checkboxProps.value === line.value)){
                      updatedCommercialConditionsRows.push({ 
                        rentalType: selectedTypeofRental.value,
                        insurance_Filter: line.insurance_Filter,
                        checkboxProps: {
                          value: line.value,
                          name: line.value,
                          label: `${line.label}`,
                          onChange: handleCommercialConditionsCheckedChange,
                          checked: false
                        }
                      })
                    }
                  })
                  // updatedCommercialConditionsRows = updatedCommercialConditionsRows.sort((a, b) => a.rentalType.localeCompare(b.rentalType));
                  // return updatedCommercialConditionsRows;
                  return updatedCommercialConditionsRows.sort((a, b) => {
                    if (a.checkboxProps.label === 'Selecionar todos') return -1;
                    if (b.checkboxProps.label === 'Selecionar todos') return 1;
                    return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                  });
                });         
              } 
            });
          } else {
            setCommercialConditions(prevConditions => 
              prevConditions.filter(condition => {
                if(condition.rentalType !== selectedTypeofRental.value && condition.checkboxProps.checked){
                  checkedCommercialConditions.push({
                    id: 0,
                    profileId: 0,
                    rentalType: condition.rentalType,
                    value: condition.checkboxProps.value 
                  });
                }
                return condition.rentalType !== selectedTypeofRental.value ;
              })
            );
          }
        });
      }

      if(commercialConditions && commercialConditions.length > 0 && previousCommercialConditionsState) {
        setPreviousCommercialConditionsState(false)

        setCommercialConditions((rows) => {
          vehiclesService.getAllInsurancePackage().then(insurancePackagesData => {
            if (insurancePackagesData && insurancePackagesData.succeeded) {

              const selectAll = rows.find(x => x.checkboxProps.value === '0')
              if(selectAll && selectAll.checkboxProps.checked){
                rows.map(row => {
                  if(row.checkboxProps.value !== selectAll.checkboxProps.value){
                    const commercialInsurances = row.insurance_Filter.split('|').filter((x:string) => x !== "''");
                    const commercialConditionValue = row.checkboxProps.value;

                    commercialInsurances.forEach((relatedInsurance) => {
                      setInsuranceValues(insrows => {
                        let updatedInsuranceRows = [...insrows];

                        if(updatedInsuranceRows.length === 0){
                          updatedInsuranceRows.push({
                            commercialCondition: '0',
                            checkboxProps: {
                              value: '0', 
                              label: 'Selecionar todos', 
                              onChange: handleSelectAllInsurancePackagesOnChange,
                              checked: false
                            }
                          })
                        }

                        if(!updatedInsuranceRows.some(x => 
                          x.checkboxProps.value === `${relatedInsurance}-${commercialConditionValue}` && 
                          x.commercialCondition === commercialConditionValue) ) {
                            const currentInsurance = insurancePackagesData.value.find((x: any) => x.value === relatedInsurance);
                            if ( currentInsurance){
                              updatedInsuranceRows.push({
                                commercialCondition: commercialConditionValue,
                                checkboxProps: {
                                  value: `${currentInsurance.value}-${commercialConditionValue}`,
                                  label: `${commercialConditionValue} - ${currentInsurance.label}`,
                                  onChange: handleInsurancesCheckedChange,
                                  checked: false
                                }
                              });  
                            }
                          }

                          return updatedInsuranceRows.sort((a, b) => {
                            if (a.checkboxProps.label === 'Selecionar todos') return -1;
                            if (b.checkboxProps.label === 'Selecionar todos') return 1;
                            return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                          });
                      });
                    })
                  }
                  return row;
                })
              }
              if(selectAll && !selectAll.checkboxProps.checked){
                rows.map(row => {

                  if(row.checkboxProps.checked && row.checkboxProps.value !== '0'){
                    const commercialInsurances = row.insurance_Filter.split('|').filter((x:string) => x !== "''");
                    const commercialConditionValue = row.checkboxProps.value;

                    commercialInsurances.forEach((relatedInsurance) => {
                      setInsuranceValues(insrows => {
                        let updatedInsuranceRows = [...insrows];

                        if(updatedInsuranceRows.length === 0){
                          updatedInsuranceRows.push({
                            commercialCondition: '0',
                            checkboxProps: {
                              value: '0', 
                              label: 'Selecionar todos', 
                              onChange: handleSelectAllInsurancePackagesOnChange,
                              checked: false
                            }
                          })
                        }

                        if(!updatedInsuranceRows.some(x => 
                          x.checkboxProps.value === `${relatedInsurance}-${commercialConditionValue}` && 
                          x.commercialCondition === commercialConditionValue) 
                        ) {
                          const currentInsurance = insurancePackagesData.value.find((x: any) => x.value === relatedInsurance);
                          if (currentInsurance) {
                            updatedInsuranceRows.push({
                              commercialCondition: commercialConditionValue,
                              checkboxProps: {
                                value: `${currentInsurance.value}-${commercialConditionValue}`,
                                label: `${commercialConditionValue} - ${currentInsurance.label}`,
                                onChange: handleInsurancesCheckedChange,
                                checked: false
                              }
                            });  
                          }
                        }

                        return updatedInsuranceRows.sort((a, b) => {
                          if (a.checkboxProps.label === 'Selecionar todos') return -1;
                          if (b.checkboxProps.label === 'Selecionar todos') return 1;
                          return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                        });
                      });
              
                    })
                  } 
                  
                  else {
                    setInsuranceValues(prevInsurances => {
                      let result = prevInsurances.filter(x => {
                        if(x.checkboxProps.value === '0') { return true; }
                        // else { return x.commercialCondition !== row.checkboxProps.value}
                        else {
                          if(x.commercialCondition === row.checkboxProps.value && !row.checkboxProps.checked) {
                            return false;
                          } else {
                            return true;
                          }
                        }
                      })

                      if(result.length === 1) {
                        result = result.map(x => {
                          x.checkboxProps.checked = false;
                          return x;
                        })
                        setPreviousInsuranceValuesState(true);
                        setValueChecked(true)
                      }

                      return result; 
                    })

                  }
                  return row;
                })
              }
            }      
          })
          return rows
        })
      }

      if(insuranceValues && insuranceValues.length > 0 && previousInsuranceValuesState) {
        setPreviousInsuranceValuesState(false)

        setInsuranceValues(insurances => {
          if(insurances.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
            insurances.map(x => {
              if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                x.checkboxProps.checked = false
              } 
              return {...x};
            });
          }
          
          if(insurances.length > 1 && insurances.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
            insurances.map(x => {
              if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                x.checkboxProps.checked = true
              }
              return {...x};
            });
          } else {
            insurances.map(x => {
              if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                x.checkboxProps.checked = false
              }

              setVehicleDamageOptions((rows) => {
                return rows.filter(x => x.checkboxProps.value === '0' )
              });
              setLiabilityOptions((rows) => {
                return rows.filter(x => x.checkboxProps.value === '0' )
              });
              setOccupiersOptions(rows => {
                return rows.filter(x => x.checkboxProps.value === '0' )
              });
              setRoadAssistanceOptions(rows => {
                return rows.filter(x => x.checkboxProps.value === '0' )
              });
              setIsolatedGlassBreakOptions(rows => {
                return rows.filter(x => x.checkboxProps.value === '0' )
              });
              return {...x};
            });
          }


          vehiclesService.getInsurancePackageLine().then(insurancePackageLinesData => {
            if (insurancePackageLinesData && insurancePackageLinesData.length > 0) {

              const selectAll = insurances.find(x => x.checkboxProps.value === '0')
              if(selectAll && selectAll.checkboxProps.checked){

                insurances.map(insuranceValue => {
                  const insuranceRequestValue = insuranceValue.checkboxProps.value.split("-")[0];
                  if(insuranceRequestValue !== '0'){
                    const insurancePackageLineData = insurancePackageLinesData.filter((item:any) => item.insurance_Package === insuranceRequestValue)
                    insurancePackageLineData.map((item: { insurance: string; description:string; type:string, use: string }) => {
                      let use = settings.Data.Vehicles_UseProps.find(x => x.value === item.use)?.label
                      //Liability
                      if(item.type === "Liability"){
                        setLiabilityOptions(rows => {
                          let updatedRows = [...rows]
                          if(updatedRows.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                                x.checkboxProps.checked = false
                              } 
                              return {...x};
                            });
                          }
                          
                          if(updatedRows.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                                x.checkboxProps.checked = true
                              }
                              return {...x};
                            });
                          }

                          if(updatedRows.length === 0){
                            updatedRows.push({
                              insurancePackage: '0',
                              checkboxProps: {
                                value: '0', 
                                label: 'Selecionar todos', 
                                onChange: handleSelectAllLiabilitiesOnChange,
                                checked: false
                              }
                            })
                          }

                          if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${insuranceValue.checkboxProps.value}`)) {
                            updatedRows.push({
                              insurancePackage: insuranceValue.checkboxProps.value,
                              checkboxProps: {
                                value: `${item.insurance}-${insuranceValue.checkboxProps.value}`,
                                label: `${insuranceValue.checkboxProps.label} ${use} - ${item.description} - ${item.insurance}`,
                                onChange: handleLiabilityOptionsCheckedChange,
                                checked:false
                              }
                            })
                          }

                          const allChecked = updatedRows.every(x => x.checkboxProps.value === '0' || (x.checkboxProps.value !== '0' &&  x.checkboxProps.checked));
                          updatedRows.map(x => {
                            if(x.checkboxProps.value === '0') {
                              x.checkboxProps.checked = allChecked;
                            }
                            return x;
                          });


                          return updatedRows.sort((a, b) => {
                            if (a.checkboxProps.label === 'Selecionar todos') return -1;
                            if (b.checkboxProps.label === 'Selecionar todos') return 1;
                            return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                          });
                        })
                      }
                      //Own Damage
                      if(item.type === "Own Damage"){
                        setVehicleDamageOptions(rows => {
                          let updatedRows = [...rows]
                          if(updatedRows.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                                x.checkboxProps.checked = false
                              } 
                              return {...x};
                            });
                          }
                          
                          if(updatedRows.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                                x.checkboxProps.checked = true
                              }
                              return {...x};
                            });
                          }

                          if(updatedRows.length === 0){
                            updatedRows.push({
                              insurancePackage: '0',
                              checkboxProps: {
                                value: '0', 
                                label: 'Selecionar todos', 
                                onChange: handleSelectAllOwnDamagesOnChange,
                                checked: false
                              }
                            })
                          }

                          if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${insuranceValue.checkboxProps.value}`)) {
                            updatedRows.push({
                              insurancePackage: insuranceValue.checkboxProps.value,
                              checkboxProps: {
                                value: `${item.insurance}-${insuranceValue.checkboxProps.value}`,
                                label: `${insuranceValue.checkboxProps.label} ${use} - ${item.description} - ${item.insurance}`,
                                onChange: handleVehicleDamageOptionsCheckedChange,
                                checked:false
                              }
                            })
                          }

                          const allChecked = updatedRows.every(x => x.checkboxProps.value === '0' || (x.checkboxProps.value !== '0' &&  x.checkboxProps.checked));
                          updatedRows.map(x => {
                            if(x.checkboxProps.value === '0') {
                              x.checkboxProps.checked = allChecked;
                            }
                            return x;
                          });

                          return updatedRows.sort((a, b) => {
                            if (a.checkboxProps.label === 'Selecionar todos') return -1;
                            if (b.checkboxProps.label === 'Selecionar todos') return 1;
                            return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                          });
                        })
                      }
                      //Other Passenger
                      if(item.type === "Other Passenger"){
                        setOccupiersOptions(rows => {
                          let updatedRows = [...rows]
                          if(updatedRows.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                                x.checkboxProps.checked = false
                              } 
                              return {...x};
                            });
                          }
                          
                          if(updatedRows.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                                x.checkboxProps.checked = true
                              }
                              return {...x};
                            });
                          }

                          if(updatedRows.length === 0){
                            updatedRows.push({
                              insurancePackage: '0',
                              checkboxProps: {
                                value: '0', 
                                label: 'Selecionar todos', 
                                onChange: handleSelectAllOccupiersOnChange,
                                checked: false
                              }
                            })
                          }

                          if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${insuranceValue.checkboxProps.value}`)) {
                            updatedRows.push({
                              insurancePackage: insuranceValue.checkboxProps.value,
                              checkboxProps: {
                                value: `${item.insurance}-${insuranceValue.checkboxProps.value}`,
                                label: `${insuranceValue.checkboxProps.label} ${use} - ${item.description} - ${item.insurance}`,
                                onChange: handleOccupiersOptionsCheckedChange,
                                checked:false
                              }
                            })
                          }

                          const allChecked = updatedRows.every(x => x.checkboxProps.value === '0' || (x.checkboxProps.value !== '0' &&  x.checkboxProps.checked));
                          updatedRows.map(x => {
                            if(x.checkboxProps.value === '0') {
                              x.checkboxProps.checked = allChecked;
                            }
                            return x;
                          });

                          return updatedRows.sort((a, b) => {
                            if (a.checkboxProps.label === 'Selecionar todos') return -1;
                            if (b.checkboxProps.label === 'Selecionar todos') return 1;
                            return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                          });
                        })
                      }
                      //Legal Defense
                      if(item.type === "Legal Defense"){
                        setRoadAssistanceOptions(rows => {
                          let updatedRows = [...rows]
                          if(updatedRows.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                                x.checkboxProps.checked = false
                              } 
                              return {...x};
                            });
                          }
                          
                          if(updatedRows.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                                x.checkboxProps.checked = true
                              }
                              return {...x};
                            });
                          }

                          if(updatedRows.length === 0){
                            updatedRows.push({
                              insurancePackage: '0',
                              checkboxProps: {
                                value: '0', 
                                label: 'Selecionar todos', 
                                onChange: handleSelectAllRoadAssistancesOnChange,
                                checked: false
                              }
                            })
                          }

                          if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${insuranceValue.checkboxProps.value}`)) {
                            updatedRows.push({
                              insurancePackage: insuranceValue.checkboxProps.value,
                              checkboxProps: {
                                value: `${item.insurance}-${insuranceValue.checkboxProps.value}`,
                                label: `${insuranceValue.checkboxProps.label} ${use} - ${item.description} - ${item.insurance}`,
                                onChange: handleRoadAssistanceOptionsCheckedChange,
                                checked:false
                              }
                            })
                          }

                          const allChecked = updatedRows.every(x => x.checkboxProps.value === '0' || (x.checkboxProps.value !== '0' &&  x.checkboxProps.checked));
                          updatedRows.map(x => {
                            if(x.checkboxProps.value === '0') {
                              x.checkboxProps.checked = allChecked;
                            }
                            return x;
                          });

                          return updatedRows.sort((a, b) => {
                            if (a.checkboxProps.label === 'Selecionar todos') return -1;
                            if (b.checkboxProps.label === 'Selecionar todos') return 1;
                            return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                          });
                        })
                      }
                      //Fire
                      if(item.type === "Fire "){
                        setIsolatedGlassBreakOptions(rows => {
                          let updatedRows = [...rows]
                          if(updatedRows.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                                x.checkboxProps.checked = false
                              } 
                              return {...x};
                            });
                          }
                          
                          if(updatedRows.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                                x.checkboxProps.checked = true
                              }
                              return {...x};
                            });
                          }

                          if(updatedRows.length === 0){
                            updatedRows.push({
                              insurancePackage: '0',
                              checkboxProps: {
                                value: '0', 
                                label: 'Selecionar todos', 
                                onChange: handleSelectAllIsolagedGlassBreakagesOnChange,
                                checked: false
                              }
                            })
                          }

                          if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${insuranceValue.checkboxProps.value}`)) {
                            updatedRows.push({
                              insurancePackage: insuranceValue.checkboxProps.value,
                              checkboxProps: {
                                value: `${item.insurance}-${insuranceValue.checkboxProps.value}`,
                                label: `${insuranceValue.checkboxProps.label} ${use} - ${item.description} - ${item.insurance}`,
                                onChange: handleIsolatedGlassBreakOptionsCheckedChange,
                                checked:false
                              }
                            })
                          }

                          const allChecked = updatedRows.every(x => x.checkboxProps.value === '0' || (x.checkboxProps.value !== '0' &&  x.checkboxProps.checked));
                          updatedRows.map(x => {
                            if(x.checkboxProps.value === '0') {
                              x.checkboxProps.checked = allChecked;
                            }
                            return x;
                          });

                          return updatedRows.sort((a, b) => {
                            if (a.checkboxProps.label === 'Selecionar todos') return -1;
                            if (b.checkboxProps.label === 'Selecionar todos') return 1;
                            return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                          });
                        })
                      }
                      return item;
                    })
                  }
                  return insuranceValue;
                })
              }

              if(selectAll && !selectAll.checkboxProps.checked){
                insurances.map(insuranceValue => {
                  const insuranceRequestValue = insuranceValue.checkboxProps.value.split("-")[0];
                  if (insuranceRequestValue !== '0' && insuranceValue.checkboxProps.checked) {
                    const insurancePackageLineData = insurancePackageLinesData.filter((item: any) => item.insurance_Package === insuranceRequestValue)
                    insurancePackageLineData.map((item: { insurance: string; description:string; type:string, use: string }) => {
                      let use = settings.Data.Vehicles_UseProps.find(x => x.value === item.use)?.label
                      //Liability
                      if(item.type === "Liability"){
                        setLiabilityOptions(rows => {
                          let updatedRows = [...rows]
                          if(updatedRows.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                                x.checkboxProps.checked = false
                              } 
                              return {...x};
                            });
                          }
                          
                          if(updatedRows.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                                x.checkboxProps.checked = true
                              }
                              return {...x};
                            });
                          }
  
                          if(updatedRows.length === 0){
                            updatedRows.push({
                              insurancePackage: '0',
                              checkboxProps: {
                                value: '0', 
                                label: 'Selecionar todos', 
                                onChange: handleSelectAllLiabilitiesOnChange,
                                checked: false
                              }
                            })
                          }
  
                          if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${insuranceValue.checkboxProps.value}`)) {
                            updatedRows.push({
                              insurancePackage: insuranceValue.checkboxProps.value,
                              checkboxProps: {
                                value: `${item.insurance}-${insuranceValue.checkboxProps.value}`,
                                label: `${insuranceValue.checkboxProps.label} ${use} - ${item.description} - ${item.insurance}`,
                                onChange: handleLiabilityOptionsCheckedChange,
                                checked:false
                              }
                            })
                          }

                          const allChecked = updatedRows.every(x => x.checkboxProps.value === '0' || (x.checkboxProps.value !== '0' &&  x.checkboxProps.checked));
                          updatedRows.map(x => {
                            if(x.checkboxProps.value === '0') {
                              x.checkboxProps.checked = allChecked;
                            }
                            return x;
                          });

                          return updatedRows.sort((a, b) => {
                            if (a.checkboxProps.label === 'Selecionar todos') return -1;
                            if (b.checkboxProps.label === 'Selecionar todos') return 1;
                            return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                          });
                        })
                      }
                      //Own Damage
                      if(item.type === "Own Damage"){
                        setVehicleDamageOptions(rows => {
                          let updatedRows = [...rows]
                          if(updatedRows.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                                x.checkboxProps.checked = false
                              } 
                              return {...x};
                            });
                          }
                          
                          if(updatedRows.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                                x.checkboxProps.checked = true
                              }
                              return {...x};
                            });
                          }
  
                          if(updatedRows.length === 0){
                            updatedRows.push({
                              insurancePackage: '0',
                              checkboxProps: {
                                value: '0', 
                                label: 'Selecionar todos', 
                                onChange: handleSelectAllOwnDamagesOnChange,
                                checked: false
                              }
                            })
                          }
  
                          if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${insuranceValue.checkboxProps.value}`)) {
                            updatedRows.push({
                              insurancePackage: insuranceValue.checkboxProps.value,
                              checkboxProps: {
                                value: `${item.insurance}-${insuranceValue.checkboxProps.value}`,
                                label: `${insuranceValue.checkboxProps.label} ${use} - ${item.description} - ${item.insurance}`,
                                onChange: handleVehicleDamageOptionsCheckedChange,
                                checked:false
                              }
                            })
                          }
                          const allChecked = updatedRows.every(x => x.checkboxProps.value === '0' || (x.checkboxProps.value !== '0' &&  x.checkboxProps.checked));
                          updatedRows.map(x => {
                            if(x.checkboxProps.value === '0') {
                              x.checkboxProps.checked = allChecked;
                            }
                            return x;
                          });
                          return updatedRows.sort((a, b) => {
                            if (a.checkboxProps.label === 'Selecionar todos') return -1;
                            if (b.checkboxProps.label === 'Selecionar todos') return 1;
                            return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                          });
                        })
                      }
                      //Other Passenger
                      if(item.type === "Other Passenger"){
                        setOccupiersOptions(rows => {
                          let updatedRows = [...rows]
                          if(updatedRows.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                                x.checkboxProps.checked = false
                              } 
                              return {...x};
                            });
                          }
                          
                          if(updatedRows.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                                x.checkboxProps.checked = true
                              }
                              return {...x};
                            });
                          }
  
                          if(updatedRows.length === 0){
                            updatedRows.push({
                              insurancePackage: '0',
                              checkboxProps: {
                                value: '0', 
                                label: 'Selecionar todos', 
                                onChange: handleSelectAllOccupiersOnChange,
                                checked: false
                              }
                            })
                          }
  
                          if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${insuranceValue.checkboxProps.value}`)) {
                            updatedRows.push({
                              insurancePackage: insuranceValue.checkboxProps.value,
                              checkboxProps: {
                                value: `${item.insurance}-${insuranceValue.checkboxProps.value}`,
                                label: `${insuranceValue.checkboxProps.label} ${use} - ${item.description} - ${item.insurance}`,
                                onChange: handleOccupiersOptionsCheckedChange,
                                checked:false
                              }
                            })
                          }
                          
                          const allChecked = updatedRows.every(x => x.checkboxProps.value === '0' || (x.checkboxProps.value !== '0' &&  x.checkboxProps.checked));
                          updatedRows.map(x => {
                            if(x.checkboxProps.value === '0') {
                              x.checkboxProps.checked = allChecked;
                            }
                            return x;
                          });

                          return updatedRows.sort((a, b) => {
                            if (a.checkboxProps.label === 'Selecionar todos') return -1;
                            if (b.checkboxProps.label === 'Selecionar todos') return 1;
                            return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                          });
                        })
                      }
                      //Legal Defense
                      if(item.type === "Legal Defense"){
                        setRoadAssistanceOptions(rows => {
                          let updatedRows = [...rows]
                          if(updatedRows.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                                x.checkboxProps.checked = false
                              } 
                              return {...x};
                            });
                          }
                          
                          if(updatedRows.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                                x.checkboxProps.checked = true
                              }
                              return {...x};
                            });
                          }
  
                          if(updatedRows.length === 0){
                            updatedRows.push({
                              insurancePackage: '0',
                              checkboxProps: {
                                value: '0', 
                                label: 'Selecionar todos', 
                                onChange: handleSelectAllRoadAssistancesOnChange,
                                checked: false
                              }
                            })
                          }
  
                          if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${insuranceValue.checkboxProps.value}`)) {
                            updatedRows.push({
                              insurancePackage: insuranceValue.checkboxProps.value,
                              checkboxProps: {
                                value: `${item.insurance}-${insuranceValue.checkboxProps.value}`,
                                label: `${insuranceValue.checkboxProps.label} ${use} - ${item.description} - ${item.insurance}`,
                                onChange: handleRoadAssistanceOptionsCheckedChange,
                                checked:false
                              }
                            })
                          }

                          const allChecked = updatedRows.every(x => x.checkboxProps.value === '0' || (x.checkboxProps.value !== '0' &&  x.checkboxProps.checked));
                          updatedRows.map(x => {
                            if(x.checkboxProps.value === '0') {
                              x.checkboxProps.checked = allChecked;
                            }
                            return x;
                          });

                          return updatedRows.sort((a, b) => {
                            if (a.checkboxProps.label === 'Selecionar todos') return -1;
                            if (b.checkboxProps.label === 'Selecionar todos') return 1;
                            return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                          });
                        })
                      }
                      //Fire
                      if(item.type === "Fire "){
                        setIsolatedGlassBreakOptions(rows => {
                          let updatedRows = [...rows]
                          if(updatedRows.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                                x.checkboxProps.checked = false
                              } 
                              return {...x};
                            });
                          }
                          
                          if(updatedRows.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                            updatedRows.map(x => {
                              if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                                x.checkboxProps.checked = true
                              }
                              return {...x};
                            });
                          }
                          
                          if(updatedRows.length === 0){
                            updatedRows.push({
                              insurancePackage: '0',
                              checkboxProps: {
                                value: '0', 
                                label: 'Selecionar todos', 
                                onChange: handleSelectAllIsolagedGlassBreakagesOnChange,
                                checked: false
                              }
                            })
                          }
  
                          if(!updatedRows.some(x => x.checkboxProps.value === `${item.insurance}-${insuranceValue.checkboxProps.value}`)) {
                            updatedRows.push({
                              insurancePackage: insuranceValue.checkboxProps.value,
                              checkboxProps: {
                                value: `${item.insurance}-${insuranceValue.checkboxProps.value}`,
                                label: `${insuranceValue.checkboxProps.label} ${use} - ${item.description} - ${item.insurance}`,
                                onChange: handleIsolatedGlassBreakOptionsCheckedChange,
                                checked:false
                              }
                            })
                          }

                          const allChecked = updatedRows.every(x => x.checkboxProps.value === '0' || (x.checkboxProps.value !== '0' &&  x.checkboxProps.checked));
                          updatedRows.map(x => {
                            if(x.checkboxProps.value === '0') {
                              x.checkboxProps.checked = allChecked;
                            }
                            return x;
                          });
                          
                          return updatedRows.sort((a, b) => {
                            if (a.checkboxProps.label === 'Selecionar todos') return -1;
                            if (b.checkboxProps.label === 'Selecionar todos') return 1;
                            return a.checkboxProps.label.localeCompare(b.checkboxProps.label);
                          });
                        })
                      }
                      return item;
                    })
                  } else {
                    setLiabilityOptions(prevLiabilities =>  {
                      let checkedLiabilities = prevLiabilities.filter(liability => liability.checkboxProps.value !== '0' && liability.insurancePackage !== insuranceValue.checkboxProps.value && liability.checkboxProps.checked );
                      setProfile(x => {
                        let updatedProfile = {...x};
                        updatedProfile.profileLiabilities = updatedProfile.profileLiabilities.filter(x => checkedLiabilities.some(y => y.checkboxProps.value === x.value));

                        return updatedProfile;
                      })

                      let result = prevLiabilities.filter(liability => {
                        if(liability.checkboxProps.value === '0') { return true; }
                        else { return liability.insurancePackage !== insuranceValue.checkboxProps.value && liability.checkboxProps.checked }
                      });

                      if(result.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                        result.map(x => {
                          if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                            x.checkboxProps.checked = false
                          } 
                          return {...x};
                        });
                      }
                      if(result.length > 1 && result.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                        result.map(x => {
                          if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                            x.checkboxProps.checked = true
                          }
                          return {...x};
                        });
                      }
                      if(result.length === 1 ) {
                        result.map(x => {
                          if(x.checkboxProps.value === '0') {
                            x.checkboxProps.checked = false
                          }
                          return x;
                        })
                      }

                      return result;
                    });
                    setVehicleDamageOptions(prevDamages => {
                      let checkedVehicleDamges = prevDamages.filter(damage => damage.checkboxProps.value !== '0' && damage.insurancePackage !== insuranceValue.checkboxProps.value && damage.checkboxProps.checked);
                      setProfile(x => {
                        let updatedProfile = {...x};
                        updatedProfile.profileVehicleDamages = updatedProfile.profileVehicleDamages.filter(x => checkedVehicleDamges.some(y => y.checkboxProps.value === x.value));

                        return updatedProfile;
                      })

                      let result = prevDamages.filter(damage =>  {
                        if(damage.checkboxProps.value === '0') { return true; }
                        else {
                          if(damage.insurancePackage === insuranceValue.checkboxProps.value && !insuranceValue.checkboxProps.checked) {
                            return false;
                          } else {
                            return true;
                          }
                          // return damage.insurancePackage === insuranceValue.checkboxProps.value && !insuranceValue.checkboxProps.checked
                        }
                      });
                      
                      if(result.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                        result.map(x => {
                          if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                            x.checkboxProps.checked = false
                          } 
                          return {...x};
                        });
                      }
                      if(result.length > 1 && result.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                        result.map(x => {
                          if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                            x.checkboxProps.checked = true
                          }
                          return {...x};
                        });
                      }
                      if(result.length === 1 ) {
                        result.map(x => {
                          if(x.checkboxProps.value === '0') {
                            x.checkboxProps.checked = false
                          }
                          return x;
                        })
                      }

                      return result;
                    });
                    setOccupiersOptions(prevOccupiers => {
                      let checkedOccupiers = prevOccupiers.filter(occupier => occupier.checkboxProps.value !== '0' && occupier.insurancePackage !== insuranceValue.checkboxProps.value && occupier.checkboxProps.checked);
                      setProfile(x => {
                        let updatedProfile = {...x};
                        updatedProfile.profileOccupiers = updatedProfile.profileOccupiers.filter(x => checkedOccupiers.some(y => y.checkboxProps.value === x.value));

                        return updatedProfile;
                      });

                      let result = prevOccupiers.filter(occupier =>  {
                        if(occupier.checkboxProps.value === '0') { return true; }
                        else { return occupier.insurancePackage !== insuranceValue.checkboxProps.value && occupier.checkboxProps.checked }
                      });

                      if(result.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                        result.map(x => {
                          if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                            x.checkboxProps.checked = false
                          } 
                          return {...x};
                        });
                      }
                      if(result.length > 1 && result.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                        result.map(x => {
                          if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                            x.checkboxProps.checked = true
                          }
                          return {...x};
                        });
                      }
                      if(result.length === 1 ) {
                        result.map(x => {
                          if(x.checkboxProps.value === '0') {
                            x.checkboxProps.checked = false
                          }
                          return x;
                        })
                      }

                      return result;
                    });
                    setRoadAssistanceOptions(prevAssistances => {
                      let checkedAssistances = prevAssistances.filter(assistance => assistance.checkboxProps.value !== '0' && assistance.insurancePackage !== insuranceValue.checkboxProps.value && assistance.checkboxProps.checked);
                      setProfile(x => {
                        let updatedProfile = {...x};
                        updatedProfile.profileRoadAssistances = updatedProfile.profileRoadAssistances.filter(x => checkedAssistances.some(y => y.checkboxProps.value === x.value));

                        return updatedProfile;
                      });

                      let result = prevAssistances.filter(assistance =>  {
                        if(assistance.checkboxProps.value === '0') { return true; }
                        else { return assistance.insurancePackage !== insuranceValue.checkboxProps.value && assistance.checkboxProps.checked }
                      });

                      if(result.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                        result.map(x => {
                          if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                            x.checkboxProps.checked = false
                          } 
                          return {...x};
                        });
                      }
                      if(result.length > 1 && result.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                        result.map(x => {
                          if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                            x.checkboxProps.checked = true
                          }
                          return {...x};
                        });
                      }
                      if(result.length === 1 ) {
                        result.map(x => {
                          if(x.checkboxProps.value === '0') {
                            x.checkboxProps.checked = false
                          }
                          return x;
                        })
                      }

                      return result;
                    });
                    setIsolatedGlassBreakOptions(prevBreaks => {
                      let checkedBreaks = prevBreaks.filter(breaks => breaks.checkboxProps.value !== '0' && breaks.insurancePackage !== insuranceValue.checkboxProps.value && breaks.checkboxProps.checked);
                      setProfile(x => {
                        let updatedProfile = {...x};
                        updatedProfile.profileIsolatedGlassBreaks = updatedProfile.profileIsolatedGlassBreaks.filter(x => checkedBreaks.some(y => y.checkboxProps.value === x.value));

                        return updatedProfile;
                      });

                      let result = prevBreaks.filter(breaks =>  {
                        if(breaks.checkboxProps.value === '0') { return true; }
                        else { return breaks.insurancePackage !== insuranceValue.checkboxProps.value && breaks.checkboxProps.checked }
                      });

                      if(result.some(x => x.checkboxProps.value !== '0' && !x.checkboxProps.checked)) {
                        result.map(x => {
                          if (x.checkboxProps.value === '0' && x.checkboxProps.checked) {
                            x.checkboxProps.checked = false
                          } 
                          return {...x};
                        });
                      }
                      if(result.length > 1 && result.every(x => x.checkboxProps.value === '0' || x.checkboxProps.checked)) { 
                        result.map(x => {
                          if (x.checkboxProps.value === '0' && !x.checkboxProps.checked) {
                            x.checkboxProps.checked = true
                          }
                          return {...x};
                        });
                      }
                      if(result.length === 1 ) {
                        result.map(x => {
                          if(x.checkboxProps.value === '0') {
                            x.checkboxProps.checked = false
                          }
                          return x;
                        })
                      }

                      return result;
                    });
                  }

                  return insuranceValue;
                })
              }
            }
          })
          return insurances;
        })

      }
    }
    setValueChecked(false)

    // for each checked object in typeofRental retrieve data from the commercial conditions webservice and load them into commercialConditions
  }, [
    profile, 
    valueChecked, 
    typeofRental, 
    commercialConditions, 
    insuranceValues, 
    settings.Data.Vehicles_UseProps, 
    previousCommercialConditionsState,
    previousInsuranceValuesState,
    
    handleCommercialConditionsCheckedChange, 
    handleInsurancesCheckedChange, 
    handleLiabilityOptionsCheckedChange, 
    handleVehicleDamageOptionsCheckedChange, 
    handleOccupiersOptionsCheckedChange, 
    handleRoadAssistanceOptionsCheckedChange, 
    handleIsolatedGlassBreakOptionsCheckedChange,

    handleSelectAllCommercialConditionsOnChange,
    handleSelectAllInsurancePackagesOnChange,
    handleSelectAllLiabilitiesOnChange,
    handleSelectAllOwnDamagesOnChange,
    handleSelectAllOccupiersOnChange,
    handleSelectAllRoadAssistancesOnChange,
    handleSelectAllIsolagedGlassBreakagesOnChange
  
  ]);
  

  // RENDERIZAÇÃO DE UM PERFIL NOVO
  // POPULATE CARACTERISTICAS / DFINIÇÕES
  useEffect(() => {
    // CARACTERISTICAS
    
    //populate paymentTerms - Tipos de Renda
    let rents = settings.Data.Vehicles_TypeOfRental[0];

    // populate paymentTerms - Tipos de Renda
    rents.result.forEach((rent, index: number) => {
      setPaymentTerms(rows => {
        if( rows.length === 0) {
          rows.push({
            value: 'Selecionar todos',
            onChange: handleSelectAllPaymentTermsOnChange,
            checked:false
          })
        }

        if( !rows.some(row => row.value === rent.value) ){
          rows.push({
            value: rent.value,
            label: rent.label,
            onChange: handlePaymentTermsCheckedChange,
            checked:false
          })
        }
        return [...rows];
      
      })
    })

    //populate deadlines - Prazo
    let deadlines = settings.Data.DeadlineValues;
    deadlines.forEach(dl => {
      setDeadlineValues((rows) => {
        if( rows.length === 0) {
          rows.push({
            value: 'Selecionar todos',
            onChange: handleSelectAllDealinesOnChange,
            checked:false
          
          })
        }

        if( !rows.some(row => row.value === (dl.value as string) )){
          rows.push({
            value: dl.value as string,
            onChange: handleDeadLineCheckedChange,
            checked:false
          })
        }
        return [...rows];
      })
    })

    // populate annualkms - Kilometragem
    let mileage = settings.Data.AnnualKmValues;
    mileage.forEach(miles => {
      setAnnualKmValues((rows) => {
        if( rows.length === 0) {
          rows.push({
            value: 'Selecionar todos',
            onChange: handleSelectAllAnnualKmsOnChange,
            checked:false
          
          })
        }

        if( !rows.some(row => row.value === (miles.value as string) )){
          rows.push({
            value: (miles.value as string),
            onChange: handleAnnualKmCheckedChange,
            checked:false
          })
        }
        return [...rows];
      })
    })


    setWebFields(
      settings.Data.Profile_Web_Fields.map((field) => {
        setDisplayConditionChanged(rows => {
          if(rows.length < settings.Data.Profile_Web_Fields.length){
            const hasObject = rows.some(x => x.id === field.id);
            if(!hasObject){
              rows.push({id: field.id, value:false});
            }
          }
          return [...rows];          
        })
        return {...field, 
          newConditionValue: {
            // name: `textFieldProps+${index}+${field.conditionsList.length}`,
            name: `textFieldProps+${field.id}`,
            onChange: handleConditionTextFieldPropsChange
          },
          newConditionValidationType: {
            // name: `dropProps+${index}+${field.conditionsList.length}`,
            name: `dropProps+${field.id}`,
            onChange: handleConditionDropPropsChange
          },
          conditionsList: [] as Condition[]
        };
      })
    );

    // populate submenus list
    setWebSubPages(settings.Data.Profile_WebSub_Pages)
    // populate menus list
    setWebPages(settings.Data.Profile_Web_Pages)

    // populate menus
    let menuSwitchListInitialState: SwitchProps[] = [];
    let menuSeeCheckboxListInitialState: CheckboxProps[] = [];
    let menuEditCheckboxListInitialState: CheckboxProps[] = [];
    let menuValidateCheckboxListInitialState: CheckboxProps[] = [];

    let menus = settings.Data.Profile_Web_Pages;
    menus.forEach(menu => {
      menuSwitchListInitialState.push({
        name: menu.name,
        value: menu.id,
        checked: menu.switchToggle,
        onChange: menuSwitchPropsOnChange
        // onChange: menuSwitchPropsOnChange(menu.id)
      });
      menuSeeCheckboxListInitialState.push({
        name: menu.name,
        value: menu.id,
        checked: menu.seeCheckbox,
        disabled: menu.required,
        onChange: menuSeeCheckboxPropsOnChange
        // onChange: menuSeeCheckboxPropsOnChange(menu.id)
      });
      menuEditCheckboxListInitialState.push({
        name: menu.name,
        value: menu.id,
        checked: menu.editCheckbox,
        disabled: menu.editRequired,
        onChange: menuEditCheckboxPropsOnChange
        // onChange: menuEditCheckboxPropsOnChange(menu.id)
      });
      menuValidateCheckboxListInitialState.push({
        name: menu.name,
        value: menu.id,
        checked: menu.validateCheckbox,
        onChange: menuValidateCheckboxPropsOnChange
        // onChange: menuValidateCheckboxPropsOnChange(menu.id)
      });
    })
    setMenuSwitch(menuSwitchListInitialState);
    setMenuSeeCheckbox(menuSeeCheckboxListInitialState);
    setMenuEditCheckbox(menuEditCheckboxListInitialState);
    setMenuValidateCheckbox(menuValidateCheckboxListInitialState);

    // populate submenus
    let submenuSwitchListInitialState: SwitchProps[] = [];
    let submenuSeeCheckboxListInitialState: CheckboxProps[] = [];
    let submenuEditCheckboxListInitialState: CheckboxProps[] = [];
    let submenuValidateCheckboxListInitialState: CheckboxProps[] = [];

    let submenus = settings.Data.Profile_WebSub_Pages;
    submenus.forEach(submenu => {
      submenuSwitchListInitialState.push({
        name: submenu.name,
        value: submenu.id,
        checked: submenu.switchToggle,
        onChange: subMenuSwitchPropsOnChange
        // onChange: subMenuSwitchPropsOnChange(submenu.id)
      })
      submenuSeeCheckboxListInitialState.push({
        name: submenu.name,
        value: submenu.id,
        checked: submenu.seeCheckbox,
        disabled: submenu.required,
        onChange: submenuSeeCheckboxPropsOnChange
        // onChange: submenuSeeCheckboxPropsOnChange(submenu.id)
      });
      submenuEditCheckboxListInitialState.push({
        name: submenu.name,
        value: submenu.id,
        checked: submenu.editCheckbox,
        disabled: submenu.editRequired,
        onChange: submenuEditCheckboxPropsOnChange
        // onChange: submenuEditCheckboxPropsOnChange(submenu.id)
      });
      submenuValidateCheckboxListInitialState.push({
        name: submenu.name,
        value: submenu.id,
        checked: submenu.validateCheckbox,
        onChange: submenuValidateCheckboxPropsOnChange
        // onChange: submenuValidateCheckboxPropsOnChange(submenu.id)
      });
    })
    setSubMenuSwitch(submenuSwitchListInitialState)
    setSubMenuSeeCheckbox(submenuSeeCheckboxListInitialState);
    setSubMenuEditCheckbox(submenuEditCheckboxListInitialState);
    setSubMenuValidateCheckbox(submenuValidateCheckboxListInitialState);

    // populate fields
    let fieldsSwitchListInitialState: SwitchProps[] = [];
    let fieldSeeCheckboxListInitialState: CheckboxProps[] = [];
    let fieldEditCheckboxListInitialState: CheckboxProps[] = [];
    let fieldValidateCheckboxListInitialState: {
      checkboxProps: CheckboxProps,
      canEditConditions: boolean
    }[] = [];

    let loadFields = settings.Data.Profile_Web_Fields;
    loadFields.forEach(field => {
      fieldsSwitchListInitialState.push({
        name: field.name,
        checked: field.switchToggle,
        value: field.id,
        onChange: fieldSwitchPropsOnChange
        // onChange: fieldSwitchPropsOnChange(field.id)
      })
      fieldSeeCheckboxListInitialState.push({
        name: field.name,
        checked: field.seeCheckbox,
        disabled: field.required,
        value: field.id,
        onChange: fieldSeeCheckboxPropsOnChange
        // onChange: fieldSeeCheckboxPropsOnChange(field.id)
      });
      fieldEditCheckboxListInitialState.push({
        name: field.name,
        checked: field.editCheckbox,
        disabled: field.editRequired,
        value: field.id,
        onChange: fieldEditCheckboxPropsOnChange
        // onChange: fieldEditCheckboxPropsOnChange(field.id)
      });
      fieldValidateCheckboxListInitialState.push({
        checkboxProps: {
        name: field.name,
        checked: field.validateCheckbox,
        value: field.id,
        onChange: fieldValidateCheckboxPropsOnChange
        // onChange: fieldValidateCheckboxPropsOnChange(field.id)
        },
        canEditConditions: false
      });
    })
    setFieldSwitch(fieldsSwitchListInitialState);
    setFieldSeeCheckbox(fieldSeeCheckboxListInitialState);
    setFieldEditCheckbox(fieldEditCheckboxListInitialState);
    setFieldValidateCheckbox(fieldValidateCheckboxListInitialState);

    setProfile(x => {
      let initialWebPagesValues = {...x};
      if(initialWebPagesValues && initialWebPagesValues.profileWebPages){
        menus.forEach(menu => {
          let submenusToUpdate = submenus.filter(submenu => submenu.profileWebPageId === menu.id);
          let profileSubMenus: ProfileWebSubPageProp[] = [];

          submenusToUpdate.forEach(submenu => {

            let fieldsToUpdate = loadFields.filter(field => field.profileWebSubPageId === submenu.id);
            let profileWebFields: ProfileWebFieldProp[] = [];

            // mapeia os campos
            fieldsToUpdate.forEach(field => { 
              if(initialWebPagesValues.profileWebPages.some(prof => 
                prof.name === menu.name && 
                prof.profileWebSubPages.some(sub => 
                  sub.name === submenu.name && 
                  sub.profileWebFields.some(f => f.name === field.name)
                )
              )){
                // se o campo já existir, atualiza
                initialWebPagesValues.profileWebPages.map(prof => { 

                  if(prof.name === menu.name){
                  
                    prof.profileWebSubPages.map(sub => {
                  
                      if(sub.name === submenu.name){
                  
                        sub.profileWebFields.map(f => {
                  
                          if(f.name === field.name){
                            f.profileWebSubPageId = submenu.id;
                            f.webFieldId = field.id;
                            f.name = field.name;
                            f.isVisible = field.seeCheckbox;
                            f.isEditable = field.editCheckbox;
                            f.hasValidationRules = field.validateCheckbox;
                            f.required = field.required;
                            f.editRequired = field.editRequired;
                          }
                          return f;
                        })
                      }
                      return sub;
                    })
                  }
                  return prof;
                });
              } else {
                // se o campo não existir, adiciona
                profileWebFields.push({
                  id: 0,
                  profileId: x.id,
                  profileWebSubPageId: submenu.id,
                  webFieldId: field.id,
                  name: field.name,
                  isSwitchToggled: field.switchToggle, // falta definir campo
                  isVisible: field.seeCheckbox,
                  isEditable: field.editCheckbox,
                  hasValidationRules: field.validateCheckbox, // falta definir campo
                  required: field.required,
                  editRequired: field.editRequired,
                  profileWebFieldConditions: []
                });
              }
            });

            // mapeia os submenus
            if(initialWebPagesValues.profileWebPages.some(prof =>
              prof.name === menu.name &&
              prof.profileWebSubPages.some(sub => sub.name === submenu.name)
            )){
              initialWebPagesValues.profileWebPages.map(prof => {
                if(prof.name === menu.name){
                  prof.profileWebSubPages.map(sub => {
                    if(sub.name === submenu.name){
                      sub.profileWebPageId = submenu.profileWebPageId;
                      sub.name = submenu.name;
                      sub.webSubPageId = submenu.id;
                      sub.isSwitchToggled = submenu.switchToggle;
                      sub.isVisible = submenu.seeCheckbox;
                      sub.isEditable = submenu.editCheckbox;
                      sub.hasValidationRules = submenu.validateCheckbox;
                      sub.required = submenu.required;
                      sub.editRequired = submenu.editRequired;
                      sub.profileWebFields = profileWebFields;
                    }
                    return sub;
                  })
                }
                return prof;
              })
            } else {
              profileSubMenus.push({ 
                id: 0,
                profileId: x.id,
                profileWebPageId: menu.id,
                webSubPageId: submenu.id,
                name: submenu.name,
                isSwitchToggled: submenu.switchToggle, 
                isVisible: submenu.seeCheckbox,
                isEditable: submenu.editCheckbox,
                hasValidationRules: submenu.validateCheckbox,
                required: submenu.required,
                editRequired: submenu.editRequired,
                profileWebFields: profileWebFields,
              });
            }
          })
          // mapeia os menus
          if(initialWebPagesValues.profileWebPages.some(prof => prof.name === menu.name)){
            // se existir, atualiza
            initialWebPagesValues.profileWebPages.map(prof => {
              if(prof.name === menu.name){
                prof.webPageId = menu.id;
                prof.name = menu.name;
                prof.isVisible = menu.seeCheckbox;
                prof.isEditable = menu.editCheckbox;
                prof.required = menu.required; 
                prof.editRequired = menu.editRequired;
                prof.profileWebSubPages = profileSubMenus;
              }
              return prof;
            })
          } else {
            // se não existir, adiciona
            initialWebPagesValues.profileWebPages.push({
              id: 0,
              profileId: x.id,
              webPageId: menu.id,
              name: menu.name,
              isSwitchToggled: menu.switchToggle, 
              isVisible: menu.seeCheckbox,
              isEditable: menu.editCheckbox,
              hasValidationRules: menu.validateCheckbox,
              required: menu.required,
              editRequired: menu.editRequired,
              profileWebSubPages:profileSubMenus,
            });
          }

        });
      }
      return initialWebPagesValues;
    })

  }, [
    handlePaymentTermsCheckedChange, 
    handleDeadLineCheckedChange,
    handleAnnualKmCheckedChange,
    // CASO SEJA NECESSÁRIO INCLUIR TIPOS DE USO NO FILTRO
    // handleUseCheckedChange,
    settings.Data.Vehicles_TypeOfRental,
    settings.Data.DeadlineValues,
    settings.Data.AnnualKmValues,
    settings.Data.Profile_Web_Pages,
    settings.Data.Profile_WebSub_Pages,
    settings.Data.Profile_Web_Fields,
    settings.Data.Vehicles_UseProps,
    
    menuSwitchPropsOnChange,
    menuSeeCheckboxPropsOnChange,
    menuEditCheckboxPropsOnChange,
    menuValidateCheckboxPropsOnChange,
    subMenuSwitchPropsOnChange,
    submenuSeeCheckboxPropsOnChange,
    submenuEditCheckboxPropsOnChange,
    submenuValidateCheckboxPropsOnChange,
    fieldSwitchPropsOnChange,
    fieldSeeCheckboxPropsOnChange,
    fieldEditCheckboxPropsOnChange,
    fieldValidateCheckboxPropsOnChange,
    // profile,
    handleConditionDropPropsChange,
    handleConditionTextFieldPropsChange,
    handleSelectAllAnnualKmsOnChange,
    handleSelectAllDealinesOnChange,
    handleSelectAllPaymentTermsOnChange,
    handleSelectAllTypesofRentalOnChange,
  ]);

  // CARACTERISTICAS
  const characteristicsDataMemo = useMemo(() => {
    return [
      {
        haveDivider: true,
        haveTitle: true,
        title: 'Dados da Viatura',
        subTitle: 'Grupos Funcionais',
        checkboxValues: functionalGroups,
        haveAdditionValues: false,
      },
      {
        haveDivider: true,
        haveTitle: true,
        title: 'Tipo de contrato',
        subTitle: 'Tipo de aluguer',
        checkboxValues: typeofRental,
        haveAdditionValues: false,
      },
      {
        haveDivider: true,
        haveTitle: false,
        subTitle: 'Condições comerciais',
        checkboxValues: commercialConditions.map(x => x.checkboxProps),
        haveAdditionValues: false, 
      },
      {
        haveDivider: true,
        haveTitle: false,
        subTitle: 'Tipo de renda',
        checkboxValues: paymentTerms,
        haveAdditionValues: true,
      
        addButtonProps: {
          onClick:handleAddPaymentTermKmClick,
        },
        addButtonText: 'Adicionar',
        addTextFieldProps: {
          label: 'Adicionar outros valores',
          value:paymentTerm,
          onChange: handlePaymentTermChange,
        },
      },
      {
        haveTitle: true,
        title: 'Definições de contrato',
        subTitle: 'Prazo',
        checkboxValues: deadlineValues,
        haveAdditionValues: true,
        addButtonProps: {
          onClick: handleAddDeadlineKmClick,
        },
        addButtonText: 'Adicionar',
        addTextFieldProps: {
          label: 'Adicionar outros valores',
          value:deadline,
          onChange: handleDeadLineChange,
        },
      },

      {
        haveDivider: true,
        haveTitle: false,
        subTitle: 'Kilometros anuais',
        checkboxValues: annualKmValues,
        haveAdditionValues: true,
        addButtonProps: {
          onClick: handleAddAnnualKmClick,
        },
        addButtonText: 'Adicionar',
        addTextFieldProps: {
          label: 'Adicionar outros valores',
          value:annualKm,
          onChange: handleAnnualKmChange,
        },
      },


      {
        haveDivider: true,
        haveTitle: false,
        subTitle: 'Seguro',
        checkboxValues: insuranceValues.map(x => x.checkboxProps),
        haveAdditionValues: false,
      },
      {
        haveTitle: false,
        subTitle: 'Danos próprios',
        checkboxValues: vehicleDamageOptions.map(x => x.checkboxProps),
        haveAdditionValues: false,
      },

      {
        haveTitle: false,
        subTitle: 'Ocupantes',
        checkboxValues:occupiersOptions.map(x => x.checkboxProps),
        haveAdditionValues: false,
      },
      {
        haveTitle: false,
        subTitle: 'Assistência em viagem',
        checkboxValues: roadAssistanceOptions.map(x => x.checkboxProps),
        haveAdditionValues: false,
      },

      {
        haveTitle: false,
        subTitle: 'Responsabilidade civil ',
        checkboxValues: liabilityOptions.map(x => x.checkboxProps),
        haveAdditionValues: false,
      },

      {
        haveTitle: false,
        subTitle: 'Quebra isolada de vidros',
        checkboxValues: isolatedGlassBreakOptions.map(x => x.checkboxProps),
        haveAdditionValues: false,
      },
    ]
  },[
    // useValues,

    annualKm, annualKmValues,  deadline, deadlineValues, 
    paymentTerm, paymentTerms,
    handleAddAnnualKmClick, handleAddDeadlineKmClick,
    handleAddPaymentTermKmClick, handleAnnualKmChange, handleDeadLineChange, 
    handlePaymentTermChange, 
    typeofRental,
    commercialConditions,
    insuranceValues, 
    isolatedGlassBreakOptions, liabilityOptions, occupiersOptions,  
    roadAssistanceOptions,  vehicleDamageOptions,
    functionalGroups
  ])


  const handleAddConditionCallback = useCallback((e: any) => {      
    let fieldId = Number(e.currentTarget.name);

    setWebFields(fields => {
      let updatedFields = [...fields];
      let updatedField = updatedFields.find((field) => field.id === fieldId);
      // let index = updatedFields.findIndex((field) => field.id === fieldId);
      if(updatedField){
        // let isDisabled = !updatedField.switchToggle
        let newConditionDropDownProps: DropDownProps = {
          id: '0',
          name: `dropProps+${updatedField.id}+${updatedField.conditionsList.length}`,
          value: updatedField.newConditionValidationType.value ?? 0,
          // defaultValue: updatedField.newConditionValidationType.value,
          onChange: handleConditionDropPropsChange,
          // disabled: isDisabled
        }
        let newConditionTextFieldProps: TextFieldProps = {
          id: '0',
          name: `textFieldProps+${updatedField.id}+${updatedField.conditionsList.length}`,
          value: updatedField.newConditionValue.value ?? '',
          // defaultValue: updatedField.newConditionValue.value,
          placeholder: 'Indicar condição',
          onChange: handleConditionTextFieldPropsChange,
          // disabled: isDisabled
        }
        let newCondition: Condition = {
          dropProps: {...newConditionDropDownProps, id: updatedField.conditionsList.length.toString()},
          textFieldProps: {...newConditionTextFieldProps, id: updatedField.conditionsList.length.toString()},
         
          // tem de ser convertido de evento para botão para poder desactivar
          removeButtonProps: {
            // disabled: isDisabled,
            onClick: handleRemoveConditionCallback(updatedField.id, updatedField.conditionsList.length)
          },
          saveButtonProps: {
            // disabled: isDisabled,
            hidden: true,
            // onClick: () => alert('guardar condição'),//handleSaveConditionCallback(index, updatedField.conditionsList.length)
          }
        }
        updatedField.conditionsList.push(newCondition);
        updatedField.newConditionValidationType.value = 0;
        updatedField.newConditionValue.value = '';
      }
      return updatedFields;
    
    })

    // setConditionsList(rows => {
    //   let updatedRows = [...rows];
    //   let updatedCondition: Condition = {
    //     dropProps: condition.dropProps,
    //     textFieldProps: condition.textFieldProps,
    //     removeButtonProps: handleRemoveConditionCallback(rows.length)
    //   }
    //   updatedRows.push(updatedCondition);
    //   updatedCondition = {
    //     dropProps: conditionDropProps,
    //     textFieldProps: conditionTextFieldProps,
    //     removeButtonProps: handleRemoveConditionCallback(rows.length)
    //   }

    //   updatedRows.push(updatedCondition)
    //   return updatedRows;
    // })
  },
  [
    handleConditionDropPropsChange, 
    handleConditionTextFieldPropsChange, 
    handleRemoveConditionCallback
  ]);

  const [displayConditionChanged, setDisplayConditionChanged] = useState<{id:number, value:boolean}[]>([]);

  // DEFINIÇÕES DE ACESSOS
  const accessListMemo = useMemo(() => {
    return webPages.map((page) => {
      // MENUS DA NAVBAR
      return {
        id: page.id,
        menu: page.name,
        // valida se consegue encontrar o switch correspondente ao menu, caso contrário instancia uma opção com base nas páginas para atualizar o estado
        switchProps:  menuSwitch.find(x => x.value === page.id) ?? {
          checked: page.switchToggle,
          value: page.id,
          name: page.name,
          onChange: menuSwitchPropsOnChange
        },
        seeCheckboxProps: menuSeeCheckbox.find(x=> x.value === page.id) ?? {
          checked: page.seeCheckbox,
          disabled: page.required,
          value: page.id,
          name: page.name,
          onChange: menuSeeCheckboxPropsOnChange
        },
        editCheckboxProps: menuEditCheckbox.find(x=> x.value   === page.id) ?? {
          checked: page.editCheckbox,
          value: page.id,
          name: page.name,
          disabled: page.editRequired,
          onChange: menuEditCheckboxPropsOnChange
        },
        validateCheckboxProps: menuValidateCheckbox.find(x=> x.value === page.id) ?? {
          checked: page.validateCheckbox,
          value: page.id,
          name: page.name,
          onChange: menuValidateCheckboxPropsOnChange
        },
        validateDropDownProps: { value: 0 }, // 0 - = / -1 = < / 1 - >
        open: true,
        level: 0,
        textFieldProps: { placeholder: 'Indicar condição' },
        canEditConditions: false,

        addButtonProps: {},
        addButtonText: 'Adicionar',
        // STEPS DAS FUNCIONALIDADES
        subMenus: webSubPages.filter(x => x.profileWebPageId === page.id).map((subpage) => {
          return {
            id: subpage.id,
            menu: subpage.name,
            switchProps: subMenuSwitch.find(x => x.value === subpage.id) ?? {
              checked: subpage.switchToggle,
              value: subpage.id,
              name: subpage.name,
              onChange: subMenuSwitchPropsOnChange
            },
            seeCheckboxProps: submenuSeeCheckbox.find(x => x.value === subpage.id) ?? {
              checked: subpage.seeCheckbox,
              value: subpage.id,
              name: subpage.name,
              disabled: subpage.required,
              onChange: submenuSeeCheckboxPropsOnChange
            },
            editCheckboxProps: submenuEditCheckbox.find(x => x.value === subpage.id) ?? {
              checked: subpage.editCheckbox,
              value: subpage.id,
              name: subpage.name,
              disabled: subpage.editRequired,
              onChange: submenuEditCheckboxPropsOnChange
            },
            validateCheckboxProps: submenuValidateCheckbox.find(x => x.value === subpage.id) ?? {
              checked: subpage.validateCheckbox,
              value: subpage.id,
              name: subpage.name,
              onChange: submenuValidateCheckboxPropsOnChange
            },
            validateDropDownProps: { value: 0 },
            textFieldProps: { placeholder: 'Indicar condição' },
            addButtonProps: {},
            addButtonText: 'Adicionar',
            open: subMenuSwitch.find(x => x.value === subpage.id)?.checked ?? subpage.switchToggle,
            level: 1,
            canEditConditions: false,
            
            // CAMPOS
            subMenus: webField.filter(x => x.profileWebSubPageId === subpage.id).map((field)=>{
              return {
                id: field.id,
                menu: field.name,
                switchProps: fieldSwitch.find(x => x.value === field.id) ?? {
                  checked: field.switchToggle,
                  value: field.id,
                  name: field.name,
                  onChange: fieldSwitchPropsOnChange
                },
                seeCheckboxProps: fieldSeeCheckbox.find(x => x.value === field.id) ?? {
                  checked: field.seeCheckbox,
                  disabled: field.required,
                  value: field.id,
                  name: field.name,
                  onChange: fieldSeeCheckboxPropsOnChange
                },
                editCheckboxProps: fieldEditCheckbox.find(x => x.value === field.id) ?? {
                  checked: field.editCheckbox,
                  disabled: field.editRequired,
                  value: field.id,
                  name: field.name,
                  onChange: fieldEditCheckboxPropsOnChange
                },
                validateCheckboxProps: fieldValidateCheckbox.find(x => x.checkboxProps.value === field.id)?.checkboxProps ?? {
                  checked: field.validateCheckbox,
                  value: field.id,
                  name: field.name,
                  onChange: fieldValidateCheckboxPropsOnChange
                },
                validateDropDownProps: {
                  name: `dropProps+${field.id}`,
                  value: field.newConditionValidationType.value ?? 0,
                  // defaultValue: field.newConditionValidationType.value ?? 0,
                  onChange: handleConditionDropPropsChange
                },
                textFieldProps: { 
                  name: `textFieldProps+${field.id}`,
                  value: field.newConditionValue.value ?? '',
                  // defaultValue: field.newConditionValue.value ?? '',
                  placeholder: 'Indicar condição',
                  onChange: handleConditionTextFieldPropsChange,
                },
                addButtonProps: {
                  name: field.id.toString(),
                  onClick: handleAddConditionCallback,
                },
                addButtonText: 'Adicionar',
                // open: true,
                level: 2,
                // ESTA PROPRIEDADE É GERIDA PELA PROPRIEDADE SWITCHPROPS
                // ESTA PROPRIEDADE CONTROLA A EDITABILIDADE DAS PROPRIEDADES validateDropDownProps, textFieldProps, addButtonText, addButtonProps
                // TENDO EM ISSO EM CONTA O VALOR DA PROP canEditConditions É SEMPRE TRUE PARA PERMITIR A EDITABILIDADE MEDIANTE A SELECEÇÃO DO SWITCH 
                canEditConditions: true , 
                conditions: field.conditionsList ?? [],
                isModified: displayConditionChanged.find(x => x.id === field.id)?.value ?? false
              }
            })
          }
        })
      }
    });
  },[
    displayConditionChanged,
    fieldSwitch, 
    menuSwitch, 
    subMenuSwitch, 
    fieldSwitchPropsOnChange, 
    menuSwitchPropsOnChange, 
    subMenuSwitchPropsOnChange, 
    menuSeeCheckbox,
    menuEditCheckbox,
    menuValidateCheckbox,
    menuSeeCheckboxPropsOnChange,
    menuEditCheckboxPropsOnChange,
    menuValidateCheckboxPropsOnChange,
    submenuSeeCheckbox,
    submenuEditCheckbox,
    submenuValidateCheckbox,
    submenuSeeCheckboxPropsOnChange,
    submenuEditCheckboxPropsOnChange,
    submenuValidateCheckboxPropsOnChange,
    fieldSeeCheckbox,
    fieldEditCheckbox,
    fieldValidateCheckbox,
    fieldSeeCheckboxPropsOnChange,
    fieldEditCheckboxPropsOnChange,
    fieldValidateCheckboxPropsOnChange,
    webField, 
    webPages, 
    webSubPages,

    // conditionsList,
    // condition,
    // handleRemoveConditionCallback,
    // conditionDropProps,
    handleConditionDropPropsChange,
    // conditionTextFieldProps,
    handleConditionTextFieldPropsChange,
    // handleCondtionPropsChange,
    handleAddConditionCallback
    
  ])

  // // disable de condições existentes por campo
  // // useEffect para teste
  // useEffect(() => {
  //   setWebFields(fields => {
  //     fields.map( field => {
  //       let fs = fieldSwitch.find( x => x.name === field.name)
  //       if( fs){
  //         field.conditionsList.map(x => {
  //           x.dropProps.disabled = !fs!.checked;
  //           x.textFieldProps.disabled = !fs!.checked
  //           return {...x}
  //         });
  //       }
  //       return {...field}

  //     })
  //     return [...fields]
  //   })
  // },[fieldSwitch])


   // CALLBACKS DE ATUALIZAÇÃO DE PERFIL
   const handleChangedProfileClick = useCallback((e: any)  => {
    if(!profile.name || profile.name === "") {
      setInvalidUserError(true);
      setInvalidUserText("O nome do perfil é obrigatório!");
      openAlert({
        variant: "error",
        text: "Por favor preencha os campos obrigatórios!",
        title: "Atenção",
      });
    }
    else{
      setInvalidUserError(false);
      setInvalidUserText("");

      if(profile) {
        profileService.add(profile).then(profileData =>{
          openAlert({
            variant: "success",
            text: "Guardado com sucesso!",
            title: "Sucesso",
            autoHideDuration: 5000
          });
        });
      }  
    }
  }, [profile]);

  const handleChangedProfilePermissionsClick = useCallback((e: any)  => {
    if(!profile.name || profile.name === "") {
      setInvalidUserError(true);
      setInvalidUserText("O nome do perfil é obrigatório!");
      openAlert({
        variant: "error",
        text: "Por favor preencha os campos obrigatórios!",
        title: "Atenção",
      });
    }
    else {
      setInvalidUserError(false);
      setInvalidUserText("");

      let updatedProfile = {...profile}
      accessListMemo.forEach((webPage) => {
        // se o perfil já tiver a configuração da página, atualiza
        if (updatedProfile.profileWebPages.some(profilePage => profilePage.name === webPage.menu && profilePage.id !== 0)) {
          // configuração da página ex: step 2 viaturas
          const profilePage = updatedProfile.profileWebPages.find(profilePage => profilePage.name === webPage.menu);
          if (profilePage) { // apesar de não ser necessário, o typescript obriga a fazer esta verificação para garantir que o objeto existe
            profilePage.isSwitchToggled = webPage.switchProps.checked ?? false;
            profilePage.isEditable = webPage.editCheckboxProps.checked ?? false;
            profilePage.isVisible = webPage.seeCheckboxProps.checked ?? false;
            profilePage.hasValidationRules = webPage.validateCheckboxProps.checked ?? false;

            // configuração do subcomponente ex: detalhes da viatura
            profilePage.profileWebSubPages.map((subMenu) => {
              const subMenuPage = webPage.subMenus.find(subMenuPage => subMenuPage.menu === subMenu.name && subMenuPage.id !== 0);
              if (subMenuPage) {// apesar de não ser necessário, o typescript obriga a fazer esta verificação para garantir que o objeto existe
                subMenu.isSwitchToggled = subMenuPage.switchProps.checked ?? false;
                subMenu.isEditable = subMenuPage.editCheckboxProps.checked ?? false;
                subMenu.isVisible = subMenuPage.seeCheckboxProps.checked ?? false;
                subMenu.hasValidationRules = subMenuPage.validateCheckboxProps.checked ?? false;

                // configuração dos campos ex: matricula, combustivel, cilindrada
                subMenu.profileWebFields.map((field) => {
                  const fieldPage = subMenuPage.subMenus.find(fieldPage => fieldPage.menu === field.name && fieldPage.id !== 0);
                  if (fieldPage) {// apesar de não ser necessário, o typescript obriga a fazer esta verificação para garantir que o objeto existe
                    field.isSwitchToggled = fieldPage.switchProps.checked ?? false;
                    field.isEditable = fieldPage.editCheckboxProps.checked ?? false;
                    field.isVisible = fieldPage.seeCheckboxProps.checked ?? false;
                    field.hasValidationRules = fieldPage.validateCheckboxProps.checked ?? false;
                    field.required = fieldPage.seeCheckboxProps.disabled ?? false;
                    field.profileWebFieldConditions = fieldPage.conditions.map((condition) => {
                      return {
                        id: Number(condition.dropProps.id),
                        profileWebFieldId: fieldPage.id,
                        value: String(condition.textFieldProps.value),
                        conditionSymbol: Number(condition.dropProps.value),
                      }
                    });
                  }
                  return field;
                });
                // Find and add missing fields
                subMenuPage.subMenus.forEach((fieldPage) => {
                  const isFieldIncluded = subMenu.profileWebFields.some(
                    (field) => field.name === fieldPage.menu && field.id !== 0
                  );
                  if (!isFieldIncluded) {
                    subMenu.profileWebFields.push({
                      name: fieldPage.menu,
                      isSwitchToggled: fieldPage.switchProps.checked ?? false,
                      isEditable: fieldPage.editCheckboxProps.checked ?? false,
                      isVisible: fieldPage.seeCheckboxProps.checked ?? false,
                      hasValidationRules: fieldPage.validateCheckboxProps.checked ?? false,
                      required: fieldPage.seeCheckboxProps.disabled ?? false,
                      editRequired: fieldPage.editCheckboxProps.disabled ?? false,
                      profileId: updatedProfile.id,
                      id: 0,
                      webFieldId: fieldPage.id,
                      profileWebSubPageId: subMenu.id,
                      profileWebFieldConditions: fieldPage.conditions.map((condition) => {
                        return {
                          id: Number(condition.dropProps.id),
                          profileWebFieldId: fieldPage.id,
                          value: String(condition.textFieldProps.value),
                          conditionSymbol: Number(condition.dropProps.value),
                        };
                      }),
                    });
                  }
                });
              }
              return subMenu;
            });
          }
        // senão adiciona
        } else {
          // configuração da página ex: step 2 viaturas
          if(!updatedProfile.profileWebPages.some(prof => prof.name === webPage.menu)){
            updatedProfile.profileWebPages.push({
              name: webPage.menu,
              isSwitchToggled: webPage.switchProps.checked ?? false,
              isEditable: webPage.editCheckboxProps.checked ?? false,
              isVisible: webPage.seeCheckboxProps.checked ?? false,
              hasValidationRules: webPage.validateCheckboxProps.checked ?? false,
              required: webPage.seeCheckboxProps.disabled ?? false,
              editRequired: webPage.editCheckboxProps.disabled ?? false,
              profileId: updatedProfile.id,
              id: 0,                  // id do objecto na bd
              webPageId: webPage.id,  // id/index do objecto no array
              // configuração do subcomponente ex: detalhes da viatura
              profileWebSubPages: webPage.subMenus.map((subMenu) => {
                return {
                  name: subMenu.menu,
                  isSwitchToggled: subMenu.switchProps.checked ?? false,
                  isVisible: subMenu.seeCheckboxProps.checked ?? false,
                  isEditable: subMenu.editCheckboxProps.checked ?? false,
                  hasValidationRules: subMenu.validateCheckboxProps.checked ?? false,
                  required: subMenu.seeCheckboxProps.disabled ?? false,
                  editRequired: subMenu.editCheckboxProps.disabled ?? false,
                  profileId: updatedProfile.id,
                  id: 0,                         // id do objecto na bd
                  webSubPageId: subMenu.id,      // id/index do objecto no array
                  profileWebPageId: 0, //webPage.id,  // id/index do objecto pai no array
                  // configuração dos campos ex: matricula, combustivel, cilindrada
                  profileWebFields: subMenu.subMenus.map((field) => {
                    return {
                      name: field.menu,
                      isSwitchToggled: field.switchProps.checked ?? false,  
                      isEditable: field.editCheckboxProps.checked ?? false,
                      isVisible: field.seeCheckboxProps.checked ?? false,
                      hasValidationRules: field.validateCheckboxProps.checked ?? false,
                      required: field.seeCheckboxProps.disabled ?? false,
                      editRequired: field.editCheckboxProps.disabled ?? false,
                      profileId: updatedProfile.id,
                      id: 0,                            // id do objecto na bd
                      webFieldId: field.id,             // id/index do objecto no array
                      profileWebSubPageId: 0, //subMenu.id,  // id/index do objecto pai no array
                      profileWebFieldConditions: field.conditions.map((condition) => {
                        return {
                          id: Number(condition.dropProps.id),
                          profileWebFieldId: field.id,
                          value: String(condition.textFieldProps.value),
                          conditionSymbol: Number(condition.dropProps.value),
                        }
                      }),
                    }
                  }),
                }
              })
            });
          }
        }

      });

      if(updatedProfile) {
        profileService.add(updatedProfile).then(profileData =>{
          if(profileData && profileData.id > 0){
            setDisplayConditionChanged(rows => rows.map(x => ({ ...x, value: false })));
            openAlert({
              variant: "success",
              text: "Guardado com sucesso!",
              title: "Sucesso",
              autoHideDuration: 5000
            });
            // FAZ SENTIDO UM UTILIZADOR PODER ATUALIZAR O PRÓPRIO PERFIL?
            // SE SIM, É NECESSÁRIO ATUALIZAR AS PERMISSÕES ATUAIS DO UTILIZADOR 
            if(currentUser.profileId === updatedProfile.id){
              userService.getUserById(currentUser.userId,null).then(userData => {
                if(userData.succeeded){
                  setCurrentUser(userData.value as UserModel);
                  // setProfile(userData.value.profile);
                }
              });
            }
            // loadPage();
          } else {
            openAlert({
              variant: "error",
              text: "Ocorreu um erro ao atualizar o perfil. Por favor contacte um administrador.!",
              title: "Atenção",
            });
          }
        });
      }
      // setProfile(updatedProfile);
    }
    
  }, [profile,accessListMemo,currentUser,setCurrentUser]);//, loadPage]);

  const [invalidUserText, setInvalidUserText] = useState<string>("")
  const [invalidUserError, setInvalidUserError] = useState<boolean>(false)
  return (
    <>
      <BackofficeEditProfilePage
        topBarProps={topBarPropsMemo}
        dashboardMenuButtonProps={dashboardMenuButtonPropsMemo}
        backofficeMenuButtonProps={backofficeMenuButtonPropsMemo}
        clientsMenuButtonProps={clientsMenuButtonPropsMemo}
        contractsMenuButtonProps={contractsMenuButtonPropsMemo}
        performanceMenuButtonProps={performanceMenuButtonPropsMemo}
        proposalMenuButtonProps={proposalMenuButtonPropsMemo}
        
        breadCrumbsLinks={[
          {
            label: 'Backoffice',
            onClick:  handleCardClick('backoffice'),
          },
          { 
            label: 'Gestão de Perfis',
            onClick: handleCardClick('profiles'),
          },
          { label: profile ? profile.name : '' },
        ]}
        // cabeçalho da página
        pageTitle={`Perfil`}
        nameTextFieldProps={{ 
          label: 'Nome do perfil',
          value: profile.name,
          required:true,
          helperText: invalidUserText,
          error: invalidUserError,
          name:'name',
          onChange: handleProfileModalNameInput
        }}
        createdBy={{ label: 'Criado por', value: profile ? profile.creator : '' }}
        creationDate={{ label: 'Criado em', value: createData ? createData : '' }}
        saveLinkText="Guardar"
        saveLinkProps={{
          onClick: handleChangedProfileClick,
        }}
        concessionSearchResultProps={{
          label: 'Concessão',
          placeholder: 'Pesquisar',
          options: concessionOptions,
          value: selectdConcessionOptions,
          onChange: handleConcessionChange,
          multiple: true
        }}
        cancelButtonText="CANCELAR"
        cancelButtonProps={{
           onClick: handleCardClick('profiles'),
        }}
        confirmButtonText="GUARDAR"
        confirmButtonProps={{
          onClick: handleChangedProfilePermissionsClick,
        }}

        // secção das opções (CARACTERISTICAS)
        characteristicsData={characteristicsDataMemo}
        
        // secção das permissções (DEFINIÇÕES DE ACESSO)
        actionsAccessSwitchText="Este perfil tem acesso a todas as opções e permissão para realizar todas as ações"
        actionsAccessSwitchProps={{
          checked: fullAccessSwitch,
          onChange: fullAccessSwitchPropsOnChange
        }}
        tabsText={['Caracteristicas', 'Definições de acesso']}
        headSwitchProps={{
          checked: headSwitch,
          onChange: headSwitchPropsOnChange
        }}
        headAccessList={[
          'Menus',
          'Ver',
          'Editar',
          'Validação',
          'Condições de validação',
          'Expandir tudo',
        ]}
        accessList={accessListMemo}
      />
      <Alert open={open} onClose={handleClose} {...alertProps} />
    </>
  );
};

export default BackofficeEditProfile;