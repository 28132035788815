import axios, { CancelToken, CancelTokenSource } from 'axios';
import axiosInstance from "../Utilities/axiosInstance";
import { vehicleDetails } from "../app/proposal/vehicleDetails";
//import settings from "../appSettings.json";
import { APIResponse } from "../model/APIResponse";
import {
  AdvancedFilterVersionsOptions,
  AdvancedFilterVehiclesOptions,
  AdvancedFilterAccessoryOptions,
} from "../model/Enums";
import { IVehicle } from "../model/Vehicle";
import { IAccessory } from "../model/Accessory";
import { IExtraStandard } from "../model/ExtraStandard";
import { IMostFrequent } from "../model/MostFrequent";
import { Proposal } from "../model/Proposal";
import { Simulation } from "../model/Simulation";
import { IFrequentSimulation } from "../model/FrequentSimulation";
import { SettingsService } from './SettingsService';
import { IFrequentHeaderSimulation } from '../model/FrequentHeaderSimulation';
import { IPreConfiguredVehicle } from '../model/PreConfiguredVehicle';

export class VehicleService {
  private settingsService: any;//.getInstance();
 
  ensureSettingsService =  async () => {
    if(this.settingsService === null || this.settingsService === undefined){
      this.settingsService = await SettingsService.getInstance();
    }
  };
  
  getProposalVehicles = async (proposalId: number): Promise<any[]> => {
    await this.ensureSettingsService();
    try {
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.ModelVersions_RelativeURL;
      const { data } = await axiosInstance.post(
        url,
        [
          {
            type: AdvancedFilterVersionsOptions.ProposalId,
            value: proposalId.toString(),
          },
        ],
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const items: IVehicle[] = data.value.map(
        (v: { model: { makeId: any; id: any } }) => {
          let item = {
            makeId: v.model.makeId,
            modelId: v.model.id,
            pvp: "Não definido",
            extras: "Não definido",
            discount: "Não definido",
            proformaPrice: "Não definido",
            businessPrice: "Não definido",
            details: vehicleDetails,
          };
          item.details.registrationTextFieldProps.value = "AA-BB-CC";
          //TODO: Implementar opções de tipos de combustivel
          item.details.fuelType.value = "e";
          //TODO: Implementar opções de cilindrada
          item.details.cylinderDisplacement.value = "";
          //TODO: Implementar opções de potência
          item.details.potency.value = "";
          return item;
        }
      );
      return items;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  cancelRequest = (message = "Pedido excedeu o tempo limite.", cancelToken: CancelTokenSource) => {
    // Set the timeout limit here (in milliseconds)
    const timeoutLimit = 10000;
  
    // Create a delay before calling the cancel function
    const timeoutTime = setTimeout(() => {
      cancelToken.cancel(message);
    }, timeoutLimit);
  
    // Return a function that can be used to clear the timeout and prevent the cancel function from being called
    return () => clearTimeout(timeoutTime);
  };
  //getVersions = async (filters:{proposalId: number,modelIds:string[]}): Promise<any[]> => { 
  getVersions = async (
    filters: { type: AdvancedFilterVersionsOptions; value: string }[],
    cancelToken?: CancelTokenSource,
    timeoutLimit?:number,
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.ModelVersions_RelativeURL +
        "/bc";
      const { data } = await axiosInstance.post(url, filters, {
        headers: {
          "Content-Type": "application/json",
        },
        cancelToken: cancelToken?.token,
        //TODO validar isto
        // timeout:timeoutLimit // se tiver ativo cancela automaticamente o pedido senão não valida sequer o timeout
      })
      return data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled by user");
        return {
          succeeded: false,
          hasErrors:true,
          message: error.message,
          value: {},
          errors: [],
        };
      } else {
        console.log(error.response);
        return {
          succeeded: false,
          hasErrors:true,
          message: 'Ocorreu um erro. Por favor tente novamente.',
          value: {},
          errors: [],
        };
      }
    } finally {
      if (cancelToken)
        cancelToken.cancel = () => {};
    }
  
  };

  getVersionsToCombo = async (
    filters: { type: AdvancedFilterVersionsOptions; value: string }[]
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.ModelVersionsCombo_RelativeURL;
      const { data } = await axiosInstance.post(url, filters, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getAllInsurancePackage = async (): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/AllInsurancePackage"
      const { data } = await axiosInstance.get(url);
      return data;
    }  catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getInsurancePackage = async (CodeCommercial: string): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.InsurancePackage_RelativeURL +
        "/" +
        CodeCommercial;
      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  getInsurancePackageLine = async (
    InsurancePackage?: string | undefined,
    Use?: string | undefined
  ): Promise<any> => {
    try {
      await this.ensureSettingsService();
      let url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.InsurancePackageLine_RelativeURL;
      if(InsurancePackage && !Use){
        url += "/" + InsurancePackage 
      }
      if(InsurancePackage && Use){
        url += "/" + InsurancePackage + "/" + Use
      }
      if(!InsurancePackage && Use){
        url += "//" + Use;
      }

      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  getVehiclePriceGroup = async (): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.VehiclePriceGroup_RelativeURL;
      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  getReplacTransType = async (): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.ReplacTransType_RelativeURL;
      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

   getReplacementVehicleGroups = async (): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/ReplacementVehicleGroups";
      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error) {
      console.log(error);
    }
    return [];
  };
  
  GetReplacVehicleAssignSetup = async (
    code: string
  ): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/ReplacVehicleAssignSetup" +
        "/" +
        code;
        
      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  GetSegmentOrder = async (
    code: string
  ): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/SegmentOrder" +
        "/" +
        code;
      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  getBrands = async (): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url = this.settingsService.settings.APIBaseURL + this.settingsService.settings.Models_RelativeURL;
      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  getModels = async (brandId: string): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL + this.settingsService.settings.Models_RelativeURL + "/" + brandId;
      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  getTypeStatusOptions = async (): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.TypeStatusOptions_RelativeURL;
      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  getFuelTypes = async (): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.FuelTypes_RelativeURL;
      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error) {
      console.log(error);
    }
    return [];
  };
  
  getSegments = async (): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.Segments_RelativeURL;
      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  getOptionsType = async (
    typeId: string,
    isAcessory: boolean | null
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      let url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.OptionsType_RelativeURL +
        "/" +
        typeId +
        "/";

      if (isAcessory !== null) url += isAcessory;

      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getCampaignOptionsType = async (
    typeId: string,
    isAcessory: boolean | null
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      let url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.CampaignOptionsType_RelativeURL +
        "/" +
        typeId +
        "/";

      if (isAcessory !== null) url += isAcessory;

      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  get = async (vehicleId: number): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url = this.settingsService.settings.APIBaseURL + this.settingsService.settings.Vehicle_RelativeURL + "/";
      const { data } = await axiosInstance.get(url + vehicleId);
      return data; //.value;
    } catch (error) {
      console.log(error);
    }
  };

  add = async (vehicles: IVehicle[]) => {
    try {
      await this.ensureSettingsService();
      const url = this.settingsService.settings.APIBaseURL + this.settingsService.settings.Vehicle_RelativeURL;
      const { data } = await axiosInstance.post(url, vehicles);
      return data;
    } catch (error) {
      console.log(error);
      return {
        succeeded: false,
        message: error,
        value: {},
        errors: [],
      };
    }
  };

  delete = async (vehicleId: number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL + this.settingsService.settings.Vehicle_RelativeURL + "/" + vehicleId;
      const { data } = await axiosInstance.delete(url);
      return data;
    } catch (error) {
      console.log(error);
      return {
        succeeded: false,
        message: error,
        value: {},
        errors: [],
      };
    }
  };

  deleteAccessory = async (accessoryId: number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.Accessories_RelativeURL +
        "/" +
        accessoryId;
      const { data } = await axiosInstance.delete(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  deleteAccessories = async (accessories: IAccessory[]) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.Accessories_RelativeURL;
      const { data } = await axiosInstance.post(url, accessories);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  deleteExtrasStandard = async (estrasStandard: IExtraStandard[]) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.Accessories_RelativeURL;
      const { data } = await axiosInstance.post(url, estrasStandard);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  deleteSimulation = async (simulationId: number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.Simulations_RelativeURL +
        "/" +
        simulationId;
      const { data } = await axiosInstance.delete(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  addExtraStandard = async (vehicles: IVehicle) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        this.settingsService.settings.ExtraStandard_RelativeURL;
      // const extraStandard = vehicles.details.extraStandard.filter(
      //   (x) => x.checkboxProps.checked == true
      // );
      const { data } = await axiosInstance.post(url, null);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  addMostFrequent = async (vehicle: IVehicle, title: string, visibility: number,userId: number) => {
    try {
      await this.ensureSettingsService();
      let mostFrequent: IMostFrequent = {
        id: 0,
        userId: userId,

        segmentId: vehicle.euroTaxInternationalSegmt,
        makeId: vehicle.makeId,
        modelId: vehicle.modelId,
        versionId: vehicle.versionId,
        fuelId: vehicle.fuelId,
        fuelDescription: vehicle.fuelDescription,
        secondaryFuelType: "", //vehicle.secondaryFuelType,
        powerHP: vehicle.powerHP,
        vat: vehicle.vat,
        engineCc: vehicle.engineCc,
        versionDescription: vehicle.versionDescription,

        caracteristics: vehicle.caracteristics,
        licensePlate: vehicle.licensePlate,

        euroTaxInternationalSegmt: vehicle.euroTaxInternationalSegmt,
        lotId: vehicle.lotId,
        technMaxQuantityKm: vehicle.technMaxQuantityKm,
        //configurações do negócio

        priceexclVAT: vehicle.priceexclVAT,
        consumersPrice: vehicle.consumersPrice,

        totalExtraWithoutTax: vehicle.totalExtraWithoutTax,
        totalExtraWithTax: vehicle.totalExtraWithTax,

        totalAccessoriesWithout: vehicle.totalAccessoriesWithout,
        totalAccessoriesWith: vehicle.totalAccessoriesWith,

        discountWithTax: vehicle.discountWithTax,
        discountWithoutTax: vehicle.discountWithoutTax,
        discountWithTaxPercentage: vehicle.discountWithTaxPercentage,
        discountWithTaxTotal: vehicle.discountWithTaxTotal,

        proformaInvoicePriceWithoutTax: vehicle.proformaInvoicePriceWithoutTax,
        proformaInvoicePriceWithTax: vehicle.proformaInvoicePriceWithTax,

        campaignWithoutTax: vehicle.campaignWithoutTax,
        campaignWithTax: vehicle.campaignWithTax,
        campaignWithTaxPercentage: vehicle.campaignWithTaxPercentage,
        campaignWithTaxTotal: vehicle.campaignWithTaxTotal,

        rappelWithoutTax: vehicle.rappelWithoutTax,
        rappelWithTax: vehicle.rappelWithTax,
        rappelWithTaxPercentage: vehicle.rappelWithTaxPercentage,
        rappelWithTaxTotal: vehicle.rappelWithTaxTotal,

        extraRappel: vehicle.extraRappel,
        extraOptionsSearch: vehicle.extraOptionsSearch,
        extraOptionSelect: vehicle.extraOptionSelect,

        recycling_charge: vehicle.recycling_charge,
        luxury_tax: vehicle.luxury_tax,
        pvp: vehicle.pvp,
        transport_x0026_immatriculation_cost:
          vehicle.transport_x0026_immatriculation_cost,
        //ACESSÓRIOS
        accessories: vehicle.accessories,
        //extras
        extraStandard: vehicle.extraStandard,
        //simulations
        // simulations:vehicle.simulations,
        //caracteristicas tecnicas
        modelYear: "", // vehicle.modelYear,//TODO implementar modelYear
        numberofDoors: vehicle.numberofDoors,
        number_of_wheels:vehicle.number_of_wheels,

        recordDate: vehicle.recordDate
          ? vehicle.recordDate
          : "0001-01-01T00:00:00",
        status: vehicle.status,

        mileage: vehicle.mileage,
        use: vehicle.use,

        //espaço e dimensões
        quantity_of_Seats: vehicle.quantity_of_Seats,
        lengthmm: vehicle.lengthmm,
        widthmm: vehicle.widthmm,
        heightmm: vehicle.heightmm,
        trunkVolumel: vehicle.trunkVolumel,

        wheelBasemm: vehicle.wheelBasemm,
        trainFrontWheelsmm: vehicle.trainFrontWheelsmm,
        trainRearWheelsmm: vehicle.trainRearWheelsmm,
        //especificações do motor
        compressionratio: vehicle.compressionratio,
        bore: vehicle.bore, //???
        torqueNm: vehicle.torqueNm,
        powerkW: vehicle.powerkW,

        fuelType: vehicle.fuelType,
        fuelTankCapacityl: vehicle.fuelTankCapacityl,
        cO2DischargeDecF: vehicle.cO2DischargeDecF,

        maxspeedkmh: vehicle.maxspeedkmh,
        acceleration0100kmh: vehicle.acceleration0100kmh,

        //outras informações/servios
        bPM: vehicle.bPM,
        oilChange: vehicle.oilChange,
        //usage
        businessValueWithoutTax: vehicle.businessValueWithoutTax,
        businessValueWithTax: vehicle.businessValueWithTax,

        typeofRental: vehicle.typeofRental,
        commercialConditions: vehicle.commercialConditions,
        typeofIncome: vehicle.typeofIncome,
        quantity: vehicle.quantity,
        renew: vehicle.renew,
        driverId: vehicle.driverId,
        driverName: vehicle.driverName, //usado na listagem das propostas

        title: title,
        visibility: visibility,
        headerSimulation:    
        {
            id:0,
            userId:0,
            //frequentVehicleId:0,
            deadline:"",
            miles:"",
            tire:"",
            vehicleReplacementOptions:"",
            maintenance:"",
            insurance:"",
            deductible:"",
            title:"",
            mostUsed:0,
            visibility:0,
            typeofRental:"",
            commercialConditions:"",
            typeofIncome:"",
            use: "",
            frequentSimulations:[]
        }
      };
      const url =
        this.settingsService.settings.APIBaseURL + this.settingsService.settings.Vehicle_RelativeURL + "/mostFrequent/";

      const { data } = await axiosInstance.post(url, mostFrequent);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  getMostFrequent = async (userId: number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/mostFrequent/" +
        userId;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

   deleteMostFrequent= async (frequentId: number,userId:number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
       "/mostFrequentVehicle/" +
        frequentId+"/"+userId;
      const { data } = await axiosInstance.delete(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  getMostFrequentByValue = async (value: string): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();

      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/mostFrequentValue/" +
        value;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };
  searchMostFrequentVehicle = async (userId: number, options: string): Promise<APIResponse>  => {
    try {
      await this.ensureSettingsService();

      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/searchFrequentVehicle/" +
        userId;
      const { data } = await axiosInstance.post(url, options, { headers: {
        'Content-Type': 'application/json'
      }});
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao obter as viaturas frequentes.",
        value: {},
        errors: [],
      };
    }
  };

  getMostFrequentAccessories = async (id: number): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();

      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/mostFrequentAccessories/" +
        id;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };
  
  getMostFrequentTotal = async (userId: number): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();

      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/mostFrequentTotal/" +
        userId;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getCampaignSummariesList = async (
    filters: { type: AdvancedFilterVehiclesOptions; value: string }[]
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/campaignSummaries/";
      const { data } = await axiosInstance.post(url, filters, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  searchCampaignSummariesList = async (
    filters: { type: AdvancedFilterVehiclesOptions; value: string }[],searchParam:string
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/searchCampaignSummaries/";
      const { data } = await axiosInstance.post(url, ({filters,searchParam}), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getVehiclesBc = async (
    filters: { type: AdvancedFilterVehiclesOptions; value: string }[]
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/vehicles" +
        "/bc";
      const { data } = await axiosInstance.post(url, filters, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getRentalTypes = async (): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL + this.settingsService.settings.Vehicle_RelativeURL + "/RentalTypes";

      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getFuelPrice = async (CodeCommercial: string): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/FuelPrice/" +
        CodeCommercial;

      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getViaVerde = async (CodeCommercial: string): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/ViaVerde/" +
        CodeCommercial;

      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getCommercialConditions = async (leaseCode?: string): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/CommercialConditions/" +
        leaseCode;

      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getCommercialConditionsByNo = async (no: string): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/CommercialConditionsByNo/" +
        no;

      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getPaymentTerms = async (): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL + this.settingsService.settings.Vehicle_RelativeURL + "/PaymentTerms";

      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  //TODO validar alterações ao ws
  getDriversByNameOrNo = async (searchField: string): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/DriversByNameOrNo/" +
        searchField;

      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getDrivers = async (companyNo: string): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/Drivers/" +
        companyNo;

      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getAllDrivers = async (): Promise<any> => {
    // getDrivers = async (): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL + this.settingsService.settings.Vehicle_RelativeURL + "/AllDrivers";

      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getAccessories = async (
    filters: { type: AdvancedFilterAccessoryOptions; value: string }[]
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL + this.settingsService.settings.Vehicle_RelativeURL + "/Accessories";
      const { data } = await axiosInstance.post(url, filters, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getVehicleAccessories = async (vehicleId: number): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL + this.settingsService.settings.Vehicle_RelativeURL + "/Accessories/";
      const { data } = await axiosInstance.get(url + vehicleId);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  publish = async (proposal: Proposal): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL + this.settingsService.settings.Proposals_RelativeURL + "publish";
      const { data } = await axiosInstance.post(url, proposal, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  //Frequent header simulation B
  addFrequentHeaderSimulation = async (
    simulations: Simulation[],
    frequentHeaderSimulation: IFrequentHeaderSimulation,
    title: string,
    visibility: number,
    userId: number,
    create: boolean
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      
      frequentHeaderSimulation.userId=userId;
      frequentHeaderSimulation.title= title;
      frequentHeaderSimulation.visibility= visibility;
      frequentHeaderSimulation.mostUsed = 1;

      simulations.forEach(simulation => {
        let frequentSimulation: IFrequentSimulation = {
          id: create === true  ? 0 : simulation.id,
          frequentHeaderSimulationId: create === true  ? 0 : frequentHeaderSimulation.id,
          no: simulation.no,
          mostUsed:simulation.mostUsed,
          deadline: Number(simulation.deadline),
          miles: simulation.miles,
          tires: simulation.tires,
          tiresQuantity: simulation.tiresQuantity,
          insurance: simulation.insurance,
          maintenance: simulation.maintenance,
          secureOptions: simulation.secureOptions,
          vehicleReplacementOptions: simulation.vehicleReplacementOptions,
          ownDamage: simulation.ownDamage,
          vehicleType: simulation.vehicleType,
          occupants: simulation.occupants,
          travelAssistance: simulation.travelAssistance,
          responsibility: simulation.responsibility,
          glassBreakage: simulation.glassBreakage,
          maintenanceDescription: simulation.maintenanceDescription,
          iuc: simulation.iuc,
          fuelCard: simulation.fuelCard,
          petrolPump: simulation.petrolPump,
          viaVerde: simulation.viaVerde,
          entityViaVerde: simulation.entityViaVerde,
          fuelDescriptiom: simulation.fuelDescriptiom,
          runFlaat: simulation.runFlaat,
          vsOverhaulVehicle: simulation.vsOverhaulVehicle,
          vsOverhaulVehicleType: simulation.vsOverhaulVehicleType,
          vsAssistanceVehicleType: simulation.vsAssistanceVehicleType,
          vsAssistanceVehicle: simulation.vsAssistanceVehicle,
          vsAccidentVehicleType: simulation.vsAccidentVehicleType,
          vsAccidentVehicle: simulation.vsAccidentVehicle,
          vsTheftVehicleType: simulation.vsTheftVehicleType,
          vsTheftVehicle: simulation.vsTheftVehicle,
          insurancePackageOptions: simulation.insurancePackageOptions,
          vehicleDamageOptions: simulation.vehicleDamageOptions,
          occupiersOptions: simulation.occupiersOptions,
          roadAssistanceOptions: simulation.roadAssistanceOptions,
          liabilityOptions: simulation.liabilityOptions,
          isolatedGlassBreakOptions: simulation.isolatedGlassBreakOptions,
          vsAssistanceVehicleOptions: simulation.vsAssistanceVehicleOptions,
          vsAssistanceVehicleTypeOptions:
            simulation.vsAssistanceVehicleTypeOptions,
          vsAccidentVehicleOptions: simulation.vsAccidentVehicleOptions,
          vsAccidentVehicleTypeOptions: simulation.vsAccidentVehicleTypeOptions,
          vsTheftVehicleOptions: simulation.vsTheftVehicleOptions,
          vsTheftVehicleTypeOptions: simulation.vsTheftVehicleTypeOptions,
          vsOverhaulVehicleOptions: simulation.vsOverhaulVehicleOptions,
          vsOverhaulVehicleTypeOptions: simulation.vsOverhaulVehicleTypeOptions,
          vehicleTypeOptions: simulation.vehicleTypeOptions,
          fuelOptions: simulation.fuelOptions,
          // VS: simulation.vsCost, //FIXME Descomentar depois de adicionar o campo ao objecto SIMULATION na API

          //campos adicionados para cotações
          //configurações de negócio
          priceexclVAT: simulation.priceexclVAT,
          consumersPrice: simulation.consumersPrice,

          totalExtraWithoutTax: Number(simulation.totalExtraWithoutTax),
          totalExtraWithTax: Number(simulation.totalExtraWithTax),

          totalAccessoriesWithout: Number(simulation.totalAccessoriesWithout),
          totalAccessoriesWith: Number(simulation.totalAccessoriesWith),

          discountWithoutTax: Number(simulation.discountWithoutTax),
          discountWithTax: Number(simulation.discountWithTax),
          discountWithTaxPercentage: Number(simulation.discountWithTaxPercentage),
          discountWithTaxTotal: Number(simulation.discountWithTaxTotal),

          proformaInvoicePriceWithoutTax:
            simulation.proformaInvoicePriceWithoutTax,
          proformaInvoicePriceWithTax: simulation.proformaInvoicePriceWithTax,

          campaignWithoutTax: Number(simulation.campaignWithoutTax),
          campaignWithTax: Number(simulation.campaignWithTax),
          campaignWithTaxPercentage: Number(simulation.campaignWithTaxPercentage),
          campaignWithTaxTotal: Number(simulation.campaignWithTaxTotal),

          rappelWithoutTax: Number(simulation.rappelWithoutTax),
          rappelWithTax: Number(simulation.rappelWithTax),
          rappelWithTaxPercentage: Number(simulation.rappelWithTaxPercentage),
          rappelWithTaxTotal: Number(simulation.rappelWithTaxTotal),

          extraRappel: Number(simulation.extraRappel),

          businessValueWithoutTax: simulation.businessValueWithoutTax,
          businessValueWithTax: simulation.businessValueWithTax,
          //tipo de contrato
          quantity: simulation.quantity,
          // quotation calculated fields
          incomeWithoutTax: simulation.incomeWithoutTax,
          incomeWithTax: simulation.incomeWithTax,
          increaseVR: simulation.increaseVR,
          margin: simulation.margin,
          realVR: simulation.realVR,
          vrEurotax: simulation.vrEurotax,
          vrTable: simulation.vrTable,

          amortization: simulation.amortization,
          interests: simulation.interests,
          //validar se estes 5 campos estão duplicados
          tyresCost: simulation.tyresCost,
          maintenanceCost: simulation.maintenanceCost,
          iucCost: simulation.iucCost,
          insuranceCost: simulation.insuranceCost,
          vsCost: simulation.vsCost,

          finalTax: simulation.finalTax,
          estimatedMargin: simulation.estimatedMargin,
          monthlyTCOWithTax: simulation.monthlyTCOWithTax,
          monthlyTCOWithoutTax: simulation.monthlyTCOWithoutTax,
          additionalKmsWithTax: simulation.additionalKmsWithTax,
          additionalKmsWithoutTax: simulation.additionalKmsWithoutTax,
          collateral: simulation.collateral,
          fee: simulation.fee,
          initialEntry: simulation.initialEntry,
          travelledKmsWithTax: simulation.travelledKmsWithTax,
          travelledKmsWithoutTax: simulation.travelledKmsWithoutTax,

          
        };
        frequentHeaderSimulation.frequentSimulations.push(frequentSimulation);
      });
     
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/frequentHeaderSimulation/";

      const { data } = await axiosInstance.post(url, frequentHeaderSimulation);
      return data;
    } catch (error: any) {
      console.log(error);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.message,
        value: {},
        errors: [],
      };
    }
  };

  getFrequentHeaderSimulations = async (userId: number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/frequentHeaderSimulation/" +
        userId;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  getFrequentHeaderSimulationsTopFive = async (userId: number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/frequentHeaderSimulationTopFive/"+
        userId;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
   deleteFrequentHeaderSimulation= async (userId:number,frequentId: number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
       "/frequentHeaderSimulation/" +
        frequentId+"/"+userId;
      const { data } = await axiosInstance.delete(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  searchFrequentHeaderSimulations = async (userId: number, params: string): Promise<APIResponse>  => {
    try {
      await this.ensureSettingsService();

      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        `/searchFrequentHeaderSimulation/` + userId + '/';
      const { data } = await axiosInstance.post(url, params,  {headers: {
        'Content-Type': 'application/json'
      }});
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao obter as simulações frequentes.",
        value: {},
        errors: [],
      };
    }
  };

  //Frequent header simulation E
  addFrequentSimulation = async (
    simulation: Simulation,
    title: string,
    visibility: number,
    userId: number,
    create: boolean
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      let frequentSimulation: IFrequentSimulation = {
        id: create === true  ? 0 : simulation.id,
        frequentHeaderSimulationId:0,
        no: simulation.no,
        mostUsed:simulation.mostUsed,
        deadline: Number(simulation.deadline),
        miles: simulation.miles,
        tires: simulation.tires,
        tiresQuantity: simulation.tiresQuantity,
        insurance: simulation.insurance,
        maintenance: simulation.maintenance,
        secureOptions: simulation.secureOptions,
        vehicleReplacementOptions: simulation.vehicleReplacementOptions,
        ownDamage: simulation.ownDamage,
        vehicleType: simulation.vehicleType,
        occupants: simulation.occupants,
        travelAssistance: simulation.travelAssistance,
        responsibility: simulation.responsibility,
        glassBreakage: simulation.glassBreakage,
        maintenanceDescription: simulation.maintenanceDescription,
        iuc: simulation.iuc,
        fuelCard: simulation.fuelCard,
        petrolPump: simulation.petrolPump,
        viaVerde: simulation.viaVerde,
        entityViaVerde: simulation.entityViaVerde,
        fuelDescriptiom: simulation.fuelDescriptiom,
        runFlaat: simulation.runFlaat,
        vsOverhaulVehicle: simulation.vsOverhaulVehicle,
        vsOverhaulVehicleType: simulation.vsOverhaulVehicleType,
        vsAssistanceVehicleType: simulation.vsAssistanceVehicleType,
        vsAssistanceVehicle: simulation.vsAssistanceVehicle,
        vsAccidentVehicleType: simulation.vsAccidentVehicleType,
        vsAccidentVehicle: simulation.vsAccidentVehicle,
        vsTheftVehicleType: simulation.vsTheftVehicleType,
        vsTheftVehicle: simulation.vsTheftVehicle,
        insurancePackageOptions: simulation.insurancePackageOptions,
        vehicleDamageOptions: simulation.vehicleDamageOptions,
        occupiersOptions: simulation.occupiersOptions,
        roadAssistanceOptions: simulation.roadAssistanceOptions,
        liabilityOptions: simulation.liabilityOptions,
        isolatedGlassBreakOptions: simulation.isolatedGlassBreakOptions,
        vsAssistanceVehicleOptions: simulation.vsAssistanceVehicleOptions,
        vsAssistanceVehicleTypeOptions:
          simulation.vsAssistanceVehicleTypeOptions,
        vsAccidentVehicleOptions: simulation.vsAccidentVehicleOptions,
        vsAccidentVehicleTypeOptions: simulation.vsAccidentVehicleTypeOptions,
        vsTheftVehicleOptions: simulation.vsTheftVehicleOptions,
        vsTheftVehicleTypeOptions: simulation.vsTheftVehicleTypeOptions,
        vsOverhaulVehicleOptions: simulation.vsOverhaulVehicleOptions,
        vsOverhaulVehicleTypeOptions: simulation.vsOverhaulVehicleTypeOptions,
        vehicleTypeOptions: simulation.vehicleTypeOptions,
        fuelOptions: simulation.fuelOptions,
        // VS: simulation.vsCost, //FIXME Descomentar depois de adicionar o campo ao objecto SIMULATION na API

        //campos adicionados para cotações
        //configurações de negócio
        priceexclVAT: simulation.priceexclVAT,
        consumersPrice: simulation.consumersPrice,

        totalExtraWithoutTax: simulation.totalExtraWithoutTax,
        totalExtraWithTax: simulation.totalExtraWithTax,

        totalAccessoriesWithout: simulation.totalAccessoriesWithout,
        totalAccessoriesWith: simulation.totalAccessoriesWith,

        discountWithoutTax: simulation.discountWithoutTax,
        discountWithTax: simulation.discountWithTax,
        discountWithTaxPercentage: simulation.discountWithTaxPercentage,
        discountWithTaxTotal: simulation.discountWithTaxTotal,

        proformaInvoicePriceWithoutTax:
          simulation.proformaInvoicePriceWithoutTax,
        proformaInvoicePriceWithTax: simulation.proformaInvoicePriceWithTax,

        campaignWithoutTax: simulation.campaignWithoutTax,
        campaignWithTax: simulation.campaignWithTax,
        campaignWithTaxPercentage: simulation.campaignWithTaxPercentage,
        campaignWithTaxTotal: simulation.campaignWithTaxTotal,

        rappelWithoutTax: simulation.rappelWithoutTax,
        rappelWithTax: simulation.rappelWithTax,
        rappelWithTaxPercentage: simulation.rappelWithTaxPercentage,
        rappelWithTaxTotal: simulation.rappelWithTaxTotal,

        extraRappel: simulation.extraRappel,

        businessValueWithoutTax: simulation.businessValueWithoutTax,
        businessValueWithTax: simulation.businessValueWithTax,
        //tipo de contrato
        quantity: simulation.quantity,
        // quotation calculated fields
        incomeWithoutTax: simulation.incomeWithoutTax,
        incomeWithTax: simulation.incomeWithTax,
        increaseVR: simulation.increaseVR,
        margin: simulation.margin,
        realVR: simulation.realVR,
        vrEurotax: simulation.vrEurotax,
        vrTable: simulation.vrTable,

        amortization: simulation.amortization,
        interests: simulation.interests,
        //validar se estes 5 campos estão duplicados
        tyresCost: simulation.tyresCost,
        maintenanceCost: simulation.maintenanceCost,
        iucCost: simulation.iucCost,
        insuranceCost: simulation.insuranceCost,
        vsCost: simulation.vsCost,

        finalTax: simulation.finalTax,
        estimatedMargin: simulation.estimatedMargin,
        monthlyTCOWithTax: simulation.monthlyTCOWithTax,
        monthlyTCOWithoutTax: simulation.monthlyTCOWithoutTax,
        additionalKmsWithTax: simulation.additionalKmsWithTax,
        additionalKmsWithoutTax: simulation.additionalKmsWithoutTax,
        collateral: simulation.collateral,
        fee: simulation.fee,
        initialEntry: simulation.initialEntry,
        travelledKmsWithTax: simulation.travelledKmsWithTax,
        travelledKmsWithoutTax: simulation.travelledKmsWithoutTax,

      };
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/frequentSimulation/";

      const { data } = await axiosInstance.post(url, frequentSimulation);
      return data;
    } catch (error: any) {
      console.log(error);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.message,
        value: {},
        errors: [],
      };
    }
  };

  getFrequentSimulations = async (userId: number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/frequentSimulation/" +
        userId;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  getFrequentSimulationsTopFive = async () => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/frequentSimulationTopFive/";
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  deleteFrequentSimulation= async (frequentId: number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
       "/frequentSimulation/" +
        frequentId;
      const { data } = await axiosInstance.delete(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  searchFrequentSimulations = async (userId: number, options: {}): Promise<APIResponse>  => {
    try {
      await this.ensureSettingsService();

      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/searchFrequentSimulation/" +
        userId;
      const { data } = await axiosInstance.post(url, options);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao obter as simulações frequentes.",
        value: {},
        errors: [],
      };
    }
  };

  addFrequentQuotation = async (vehicle: IMostFrequent, userId: number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/frequentQuotation/";

      const { data } = await axiosInstance.post(url, vehicle);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  getFrequentQuotations = async (userId: number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/frequentQuotation/" +
        userId;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  searchFrequentQuotations = async (userId: number, options: string): Promise<APIResponse>  => {
    try {
      await this.ensureSettingsService();

      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/searchFrequentQuotation/" +
        userId;
      const { data } = await axiosInstance.post(url, options, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao obter as cotações frequentes.",
        value: {},
        errors: [],
      };
    }
  };

  getFromProposal = async (proposalId: number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/fromProposal/" +
        proposalId;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  upsertVehicle = async (
    vehicle: IVehicle,
    leaseQuoteNo: string
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Proposals_RelativeURL +
        "upsertSimulations/" +
        leaseQuoteNo;
      // console.log(vehicle.recordDate);
      // vehicle.recordDate = "0001-01-01T00:00:00";
      const { data } = await axiosInstance.post(url, vehicle, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao sincronizar as simulações.",
        value: {},
        errors: [],
      };
    }
  };

  upsertVehicle1 = async (
    vehicle: IVehicle,
    leaseQuoteNo: string
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Proposals_RelativeURL +
        "upsertSimulations/" +
        leaseQuoteNo;

      const { data } = await axiosInstance.post(url, vehicle, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao sincronizar as simulações.",
        value: {},
        errors: [],
      };
    }
  };

  getLicensePlates = async (licensePlate?: string) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.Vehicle_RelativeURL +
        "/licensePlates/" +
        licensePlate;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  getVendors = async (): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url = this.settingsService.settings.APIBaseURL + this.settingsService.settings.Vehicle_RelativeURL + "/";
      const { data } = await axiosInstance.get(`${url}vendors`);
      return data;
    } catch (error: any) {
      console.log(error);
      return {
        succeeded: false,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao obter a lista de vendedores.",
        value: {},
        errors: [],
      };
    }
  };

  getIncompatibilityExtraPackageDescription = async (typeNo: string, codes:string[]): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url = this.settingsService.settings.APIBaseURL + this.settingsService.settings.Vehicle_RelativeURL + "/OptionsType/BC/OptionsDescriptions";
      // let jsonElements = {'typeNo':typeNo, 'codes':codes}
      let jsonElements = {typeNo, codes}
      const { data } = await axiosInstance.post(url, jsonElements);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
//#region PreConfiguredVehicles
  getPreConfiguredVehicles = async (ids:number[] | null): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.PreConfiguredVehicles_RelativeURL;
      let response: any;
      if(ids && ids.length > 0) {
        response = await axiosInstance.post(url + "/GetByIds", ids, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } 
      else{
        response = await axiosInstance.get(url);  
      }
      return response.data;
    } 
    catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao obter os veículos pré-configurados.",
        value: {},
        errors: [],
      };
    }
  };
  deletePreConfigured = async (vehicleId: number) => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL + this.settingsService.settings.PreConfiguredVehicles_RelativeURL + "/" + vehicleId;
      const { data } = await axiosInstance.delete(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  upsertPreConfiguredVehicle = async (
    vehicle: IPreConfiguredVehicle,
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url =
        this.settingsService.settings.APIBaseURL +
        this.settingsService.settings.PreConfiguredVehicles_RelativeURL;
      
      const { data } = await axiosInstance.post(url, vehicle, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao sincronizar as simulações.",
        value: {},
        errors: [],
      };
    }
  };
  //#endregion

  getComplementaryProducts = async (commercialCondition: string, version: string): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url = this.settingsService.settings.APIBaseURL + this.settingsService.settings.Vehicle_RelativeURL + "/ComplementaryProducts";
      const { data } = await axiosInstance.get(url, {
        params: {
          commercialCondition,
          version
        } 
      });
      return data;
    } catch (error: any) {
      console.log(error);
      return {
        succeeded: false,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao obter a lista de produtos complementares.",
        value: {},
        errors: [],
      };
    }
  };

  getCommissions = async (): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url = this.settingsService.settings.APIBaseURL + this.settingsService.settings.Vehicle_RelativeURL + "/Commissions";
      const { data } = await axiosInstance.get(`${url}`);
      return data;
    } catch (error: any) {
      console.log(error);
      return {
        succeeded: false,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao obter a lista de commissões.",
        value: {},
        errors: [],
      };
    }
  };

  getFunctionalGroupBrands = async (makes: string[], models: string[]): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url = this.settingsService.settings.APIBaseURL + this.settingsService.settings.Models_RelativeURL + '/functionalGroupModels/';
      // const { data } = await axiosInstance.get(url);
      const filter = {makes: makes, models: models};
      const { data } = await axiosInstance.post(url, filter);
      return data;
    }  catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };
}
