import {  Alert, AlertProps, BackofficeProfilesPage } from '@hi-interactive-admin/simulador-kinto';
import { useCallback, useEffect, useState } from 'react';
import { Profile } from '../../model/Profile';
import { ProfileServices } from '../../services/ProfileServices';
import { CustomerService } from '../../services/CustomerServices';
import { Option } from '../../model/interfaces';
import { KintoRootProps } from '../../model/KintoComponentProps';
import { UserModel } from '../../model/User';
import { UserServices } from "../../services/UserServices";
import useNavbarHook from '../../customCallbacks/navbarHook';
import { AutocompleteChangeReason } from '@mui/material';

const profileService = new ProfileServices();
const customerService = new CustomerService();
const userService = new UserServices();

const BackofficeProfile : React.FC<KintoRootProps>  = ({ onLogout }) => {
  const { 
    backofficeMenuButtonPropsMemo,
    clientsMenuButtonPropsMemo,
    contractsMenuButtonPropsMemo,
    dashboardMenuButtonPropsMemo,
    proposalMenuButtonPropsMemo,
    performanceMenuButtonPropsMemo,
    topBarPropsMemo,
    handleCardClick,
    navigate
  } = useNavbarHook(onLogout);

  const [disable, setDisable] = useState<boolean>(true);
  const [selectedOption, setSelectedOption] = useState<Option>({label:"", value:""});
  const [profileOptions, setProfileOptions] = useState<Option[]>([{label:"", value:""}]);
  const [profiles, setProfiles] = useState<Profile[]>([]);

  const [concessionOptions, setConcessionOptions] = useState<Option[]>([])
  const [selectedConcessions, setSelectedConcessions] = useState<Option[]>([]);
  const [profile, setProfile] = useState<Profile>({
    id: 0,
    name:"",
    concession:"",
    creator:"",
    createdByUserId:0,
    active:true,
    hasFullAccess: false,
    profileFunctionalGroups: [],
    profileAnnualKms: [],
    profileDeadlines: [],
    profileInsurances: [],
    profileCommercialConditions: [],
    profileIsolatedGlassBreaks: [],
    profileLiabilities: [],
    profileOccupiers: [],
    profilePaymentTerms: [],
    profileRentalTypes: [],
    profileRoadAssistances: [],
    profileVehicleDamages:[],
    profileWebPages: [],
  });
  const [statusOption, setStatusOption] = useState<string>('all');

  const [currentUser, setCurrentUser] = useState<UserModel>({
    userId: 0,
    username: "",
    friendlyName: "",
    avatarSrc: "",
    refreshToken: "",
    typeofIncome: "",
    typeofRental: "",
    commercialConditions: "",
    webPortalContactId: "",
    email: "",
    isAdministrator: false,
    profileId: undefined,
    profile: undefined,
    concessions: [],
    created: new Date(Date.now()),
    createdByUserId: 0,
    active: false,
  });

  const [open, setOpen] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<Omit<AlertProps, "open" | "onClose">>({
    variant: "info",
    text: "",
    title: "",
  });
  const openAlert = (alertProps: Omit<AlertProps, "open" | "onClose">) => {
    setAlertProps(alertProps);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
 

  const handleArrowClick = useCallback( (itemId: string) => {
    navigate(`/profiles/${itemId}`);
  },[navigate]);

  const loadPage = () => {
    profileService.get().then(data => {
      if(data.succeeded){
        setProfiles(data.value);
      }
    });
    customerService.getCenterDealer().then((data)=>{
      let options:Option[] = [];
      if(data && data.length > 0){
        data.forEach((element:any) => {
          options.push({
            label: `${element.id} - ${element.description}`,
            value: element.id,
          })            
        })
      }
      setConcessionOptions(options);
    });
  }

  useEffect(() => {
    loadPage();
     
    const idUser = localStorage.getItem("userId");
    if (idUser) {
      const id = parseInt(idUser);
      userService.getUserById(id,true).then((user) => {
        if(user.succeeded){
          setCurrentUser(user.value)
        }
      });
    }

  }, [])

  const handleConfirmProfileModalClick = useCallback((e: any)  => {
    if(profile) {
      profileService.add(profile).then(profileData => {
        // o codigo comentado copia o campo "profileData" 
        // que obtemos da api para o state "profiles" 
        // e cria um novo array "..." com base no state 
        // para forçar o react a detetar as alterações do state e 
        // re-renderizar a página sem ter atenção aos dados que vem da api
        // setProfiles([...profiles,profileData]) 

        // este codigo faz o mesmo que o comentado acima,
        // mas valida que os campos compostos por arrays
        // sao preenchidos como arrays vazios caso não tenham valores ( null ou undefined )

        if(profileData){
          setProfiles(profs => {
            // valida dados do perfil
            let updatedProfile: Profile = {
              id: profileData.id,
              name: profileData.name,
              concession: profileData.concession,
              creator: profileData.creator,
              createdByUserId: profileData.createdByUserId,
              active: profileData.active,
              hasFullAccess: profileData.hasFullAccess,
              profileFunctionalGroups: profileData.profileFunctionalGroups ?? [],
              profileAnnualKms: profileData.profileAnnualKms ?? [],
              profileDeadlines: profileData.profileDeadlines ?? [],
              profileInsurances: profileData.profileInsurances ?? [],
              profileCommercialConditions: profileData.profileCommercialConditions ?? [],
              profileIsolatedGlassBreaks: profileData.profileIsolatedGlassBreaks ?? [],
              profileLiabilities: profileData.profileLiabilities ?? [],
              profileOccupiers: profileData.profileOccupiers ?? [],
              profilePaymentTerms: profileData.profilePaymentTerms ?? [],
              profileRentalTypes: profileData.profileRentalTypes ?? [],
              profileRoadAssistances: profileData.profileRoadAssistances ?? [],
              profileVehicleDamages: profileData.profileVehicleDamages ?? [],
              profileWebPages: profileData.profileWebPages ?? [],
            }
            // cria novo array para forçar deteção da alteração por parte do React
            let updateProfilesList:Profile[] = [...profs]
            // atualiza array com o novo perfil
            updateProfilesList.push(updatedProfile);
            // atualiza estado para forçar re-renderização
            return updateProfilesList;
          })
          openAlert({
            variant: "success",
            text: "Perfil criado com sucesso.",
            title: "Sucesso",
          });
        } else {
          openAlert({
            variant: "error",
            text: "Erro ao criar perfil. Por favor tente novamente.",
            title: "Erro",
          });
        }
      });
    }

  }, [profile]);

  const handleProfileModalConcessionChange = useCallback((e: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason) => {
    setSelectedConcessions(rows => {
      let updatedRows: Option[] = [];
      value.forEach((option: Option) => {
        if(!updatedRows.some(x => x.value === option.value)) {
          updatedRows.push({
            label: option.label,
            value: option.value,
          });
        }
      });

      return updatedRows
    });
    setProfile((row)=>{
      row.concession = value.map((x: Option) => x.value).join(',');
      return row;
    })       
  }, []);
  
  const handleProfileModalNameChange = useCallback(( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newProfile = profile;
    if (newProfile){
      newProfile.name =e.target.value;
      setProfile(newProfile);
    }
  }, [profile]);

  const handleProfileModalCreatorChange = useCallback(( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newProfile = profile;
    if (newProfile){
      newProfile.creator =e.target.value;
      setProfile(newProfile);
    }
  }, [profile]);

  const handleConcessionDropDownChange = useCallback(( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
   setStatusOption(e.target.value)
  }, []); 

  const handleProfileSearchResultChange = useCallback((e: React.SyntheticEvent<Element, Event>,value:any,reason:any) => {
    if (profileOptions.length > 0) {
      let option = profileOptions.find(x => { return x.value === (e.target as any).value });
      if (option)
        setSelectedOption(option)
    }
  }, [profileOptions]);
 
  const handleProfileSearchResultInputChange = useCallback((e: React.SyntheticEvent<Element, Event>,value:any,reason:any) => {
    if (e) {
      if ((e.target as any).value.length> 3 ) {
        profileService.getProfileByNameOrNumber((e.target as any).value).then(data => {
          if (data.length > 0) {
            let options:Option[] = []
            data.forEach((profileData:any) => {
              options.push({ label: profileData['name'], value: profileData['profileId'] })
            });

            setProfileOptions(options);
            setProfile(data);
          }   
        });
      }
    }
  }, []);

  const handleTableSearchProfileClick = useCallback((e: any)  => {
    setDisable(false);
    let profileList:Profile[] = []

    let status:boolean | null | undefined;
    if (statusOption === 'at') {  
      status = true;
    } else if (statusOption === 'in') {  
      status = false;
    } else {
      status = null;
    }
    profileService.getProfileById(Number(selectedOption.value),status).then(profilesData => {
      if(profilesData) {
        profileList.push({
          id: profilesData['id'],      
          name: profilesData['name'],  
          concession: profilesData['concession'],
          creator: profilesData['creator'],
          createdByUserId:  profilesData['createdByUserId'],
          active: profilesData['active'],
          hasFullAccess: profilesData['hasFullAccess'],
          profileFunctionalGroups: profilesData['profileFunctionalGroups'],
          profileAnnualKms:profilesData['profileAnnualKms'],
          profileDeadlines: profilesData['profileDeadlines'],
          profileInsurances: profilesData['profileInsurances'],
          profileCommercialConditions: profilesData['profileCommercialConditions'],
          profileIsolatedGlassBreaks: profilesData['profileIsolatedGlassBreaks'],
          profileLiabilities: profilesData['profileLiabilities'],
          profileOccupiers: profilesData['profileOccupiers'],
          profilePaymentTerms: profilesData['profilePaymentTerms'],
          profileRentalTypes: profilesData['profileRentalTypes'],
          profileRoadAssistances: profilesData['profileRoadAssistances'],
          profileVehicleDamages: profilesData['profileVehicleDamages'],
          profileWebPages: profilesData['profileWebPages'],
        });

        setProfiles(profileList);
      } else {
        openAlert({
          variant: "info",
          text: "O resultado da pesquisa não foi encontrado. Por favor confirme os dados da pesquisa.",
          title: "Atenção",
        });
      }
    })

  }, [selectedOption,statusOption]);

  const handleEraseLinkClick  = useCallback((e: any) => {
    loadPage();
    setSelectedOption({label:"",value:""});
    setDisable(true);
    setStatusOption('all');
  },[]);
  
  return (
    <>
      <BackofficeProfilesPage
        // barra de navegação
        topBarProps={topBarPropsMemo}
        backofficeMenuButtonProps={backofficeMenuButtonPropsMemo}
        clientsMenuButtonProps={clientsMenuButtonPropsMemo}
        contractsMenuButtonProps={contractsMenuButtonPropsMemo}
        dashboardMenuButtonProps={dashboardMenuButtonPropsMemo}        
        performanceMenuButtonProps={performanceMenuButtonPropsMemo}
        proposalMenuButtonProps={proposalMenuButtonPropsMemo}
        // breadcrumb
        breadCrumbsLinks={[
        {
          label: 'Backoffice',
          onClick: handleCardClick('backoffice'),
        },
        { label: 'Gestão de Perfis',
          onClick: handleCardClick('profiles'),  
        },
        
        ]}
        pageTitle="Gestão de Perfis"
        addNewProfileButtonText="Criar novo perfil"
        addNewProfileButtonProps={{
          // Não temos acesso ao carregamento da modal 
          // para pré-preencher o campo do utilizador
          // que criou o perfil

          // onClickCapture: () =>{ 
          //   setProfile(x => {
          //     if(currentUser.userId !== (null || undefined)){
          //       x.creator = currentUser.userId.toString();
          //     }
          //     return x;
          //   })
          // },
          onClick: () =>{ 
            setProfile(x => {
              if (currentUser.userId) {
                x.creator = currentUser.userId.toString();
                x.createdByUserId = currentUser.userId;
              }
              return x;
            })
          }
        }}
        profileSearchResultProps={{
          label: 'Pesquisar por Nome do Perfil',
          placeholder: 'Pesquisar',
          options: profileOptions,
          value: selectedOption,
          onChange:handleProfileSearchResultChange,
          onInputChange:handleProfileSearchResultInputChange,
        }}
        filterIconProps={{}}
        eraseLinkText="LIMPAR"
        eraseLinkProps={{ 
          disabled: disable, 
          onClick:handleEraseLinkClick,
        }}
        profileTableProps={{
          onActionButtonClick: (_, index) =>
            handleArrowClick(`${profiles[index].id}`),
          rows: profiles?.map((cur) => {
            let concessionsValues = cur.concession.split(',');
            let concession = concessionOptions.filter(x => concessionsValues.some( concessionValue => concessionValue === x.value)).map(x => x.label).join(','); 
            if (concession === undefined) {
              concession=cur.concession;
            }
            return { cell: [cur.name, cur.creator,concession ] };
          }),
          headCells: [
            {
              id: 'profile',
              label: 'Perfil',
            },
            {
              id: 'created-by',
              label: 'Criado por',
            },
            {
              id: 'concession',
              label: 'Concessão ',
            },
          ],
        }}
        concessionDropDownProps={{
          label: 'Estado',
          defaultValue: 'all',
          value: statusOption,
          options: [
            { value: 'all', label: 'Todos' },
            { value: 'at', label: 'Ativo' },
            { value: 'in', label: 'Inativo' },
          ],
          onChange:handleConcessionDropDownChange,
        }}
        resultNumber={`${profiles.length < 10 ? '0' : ''}${profiles.length}`}
        resultText="resultados encontrados"
        searchProfileButtonText="Pesquisar"
        searchProfileButtonProps={{
          onClick:handleTableSearchProfileClick, 
        }}
        newProfileModalProps={{
          title: 'Criar novo perfil',
          handleClose:() => {},
          profileNameTextFieldProps: { 
            label: 'Nome do perfil',
            name:'name',
            onChange:handleProfileModalNameChange
          },
          createdByTextFieldProps: { 
            label: 'Criado por',
            name:'creator',
            disabled: true,
            value: profile.creator ? profile.creator : currentUser ? currentUser.username : '',
            // value: currentUser.userId.toString() === profile.creator ? currentUser.username : '',
            onChange: handleProfileModalCreatorChange,
          },
          concessionSearchResultProps: {
            label: 'Concessão',
            placeholder: 'Pesquisar',
            options: concessionOptions,
            value: selectedConcessions,
            onChange: handleProfileModalConcessionChange,
            multiple: true,
            // não temos como seleccionar múltiplas concessões
            // caso o Alexandre se queixe tem de pedir 
            // à hi interactive
          },
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Criar perfil',
          confirmButtonProps: {
            onClick: handleConfirmProfileModalClick,
          },
          onLoad: () => {
            let newProfile = profile;
            newProfile.creator = profile.creator ? profile.creator : currentUser ? currentUser.username : '';
            newProfile.createdByUserId = profile.createdByUserId ? profile.createdByUserId : currentUser ? currentUser.userId : 0;
            setProfile(newProfile);
          }
        }}
      />
      <Alert open={open} onClose={handleClose} {...alertProps} />
    </>
  );
};

export default BackofficeProfile;