import {
  AccountIcon,
  ButtonProps,
  CreateProposalPage,
  CreateProposalPageProps,
  HeadCellProps,
  RichEditorProps,
  SearchResultProps,
  SuccessIcon,
  TextFieldProps,
  VehicleDetailsProps,
  VehicleFrontIcon,
  WizardProps,
  AlertProps,
  CheckboxProps,
  ProgressBarProps,
  Alert,
  RadioGroupProps,
  // ContractSettingsCardProps,
  // PumpDieselIcon,
  // TechnicalCharacteristics,
} from "@hi-interactive-admin/simulador-kinto";
import React, {
  ChangeEvent,
  FormEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAsyncRequest } from './../../services/AsyncRequestContext';
//HYDRA B
import { VehicleService } from "../../services/VehicleService";
// import { UserModel } from "../../model/User";
import { ICommision, IModel, IVehicle, IVersion, newVehicle } from "../../model/Vehicle";
import { Proposal } from "../../model/Proposal";
import { CustomerService } from "../../services/CustomerServices";
import { ContactsService } from "../../services/ContactsService";
import { ProposalService } from "../../services/ProposalService";
import { CommentsService } from "../../services/CommentsService";
import { IAccessory } from "../../model/Accessory";
import { useParams } from "react-router-dom";

import {
  AdvancedFilterVersionsOptions,
  AdvancedFilterVehiclesOptions,
  AdvancedFilterAccessoryOptions,
  VehicleTypeStatus,
  VehicleTypeStatusHelper,
} from "../../model/Enums";
import {
  CampaignVehicleDetails,
  emptyInsuranceOption,
  ExtraAccessory,
  ILabelValue,
  InsuranceFrameworkContract,
  InsuranceOptions,
  Option,
  IPreConfiguredVehicleDetails,
  SalesPerson,
  UsedVehicleDetails,
} from "../../model/interfaces";
import {
  validateNIF,
  validateEmail,
  validatePhoneNumber,
  validaStringFormatDecimal,
  // validateStringFormatNumber,
  convertNumber,
} from "../../Utilities/Utils";
import { UpsertProposalRequest } from "../../model/UpsertProposalRequest";
import { APIResponse, GenericResponse } from "../../model/APIResponse";

// import { VehicleDataProps } from '../../../../../../libs/ui/src/pages/proposal/step-2/VehicleData';
// import { ContractTypeProps } from '../../../../../../libs/ui/src/pages/proposal/step-3/ContractType';
// import { ContractSettingsProps } from '../../../../../../libs/ui/src/pages/proposal/step-4/ContractSettings';
// import { QuotationProps } from '../../../../../../libs/ui/src/pages/proposal/step-5/Quotation';
import { emptyRequiredData, RequiredDataProps } from "./clients";
import { IMostFrequent } from "../../model/MostFrequent";

import { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteInputChangeReason, createFilterOptions } from "@mui/material";
import VehicleUtilities from '../../Utilities/VehicleUtilities';
import { vehicleDetails } from './vehicleDetails';
import { IExtraStandard } from '../../model/ExtraStandard';
import { ComplementaryProduct, Simulation } from "../../model/Simulation";
import { IFrequentSimulation } from "../../model/FrequentSimulation";
import { convertToComment } from "../../model/ProposalComment";
//HYDRA E
//import settings from "../../appSettings.json";
import { KintoRootProps } from '../../model/KintoComponentProps';
import { FinishModalProps } from '@hi-interactive-admin/simulador-kinto/pages/proposal/FinishModal';
import { GroupEditModalProps } from '@hi-interactive-admin/simulador-kinto/pages/proposal/modals/GroupEditModal';
import { AppSettings, EmptyAppSettings } from "../../model/Settings";
import { SettingsService } from "../../services/SettingsService";
import { IFrequentHeaderSimulation } from "../../model/FrequentHeaderSimulation";
import useNavbarHook from "../../customCallbacks/navbarHook";
import usePermissionsHook from "../../customCallbacks/permissions/permissionsHook";
import useComplementaryProductsHook from "../../customCallbacks/proposal/complementaryProductsHook";
import { ContractSettingsDetailsProps } from "@hi-interactive-admin/simulador-kinto/pages/proposal/step-4/ContractSettingsDetails";
import useAdvVehicleFilterHook from "../../customCallbacks/advVehicleFilterHook";
import { IPreConfiguredVehicle } from "../../model/PreConfiguredVehicle";
import useProposalStatesHook from "../../customCallbacks/proposal/proposalStatesHook";
import { ProfileServices } from "../../services/ProfileServices";
import { FunctionalGroupsVersionType, FunctionalGroupVehicle } from "../../model/Profile";

declare type VehicleTypeModalVariant = "used" | "usedCampaign" | "newCampaign";

const doNothing = () => {
  alert("Funcionalidade em desenvolvimento");
};

const historyDrawerProps = {
  headerTitle: "Histórico de ações",
  tableHeader: ["Data", "Ação", "Utilizador"],
  seeText: "Ver",
  tableData: [
    {
      date: "Agosto 25 2020 16:34",
      action: "Adicionados comentários",
      canSee: true,
      userIcon: AccountIcon,
      username: "Joel",
      seeAction: doNothing,
    },
    {
      date: "Agosto 25 2020 16:34",
      action: "Configuradas viaturas",
      canSee: false,
      userIcon: AccountIcon,
      username: "Joel",
      seeAction: doNothing,

      multipleItems: [
        {
          hour: "16:34",
          action: "Adicionados comentários",
          canSee: true,
          seeAction: doNothing,
        },
        {
          hour: "16:34",
          action: "Adicionadas viaturas de campanha",
          canSee: false,
        },
        {
          hour: "16:34",
          action: "Actualizados dados da fixa de cliente",
          canSee: false,
        },
      ],
    },

    {
      date: "Agosto 15 2020 16:34",
      action: "Adcionou comentários",
      canSee: false,
      userIcon: AccountIcon,
      username: "Paula",
    },
  ],
};

const vehicleDataTableHeadCells: HeadCellProps[] = [
  {
    id: "brand",
    label: "Marca",
    width: "179px ",
  },
  {
    id: 'model-desc2',
    label: 'Modelo',
    width: "179px ",
  },
  {
    id: "model-desc",
    label: "Descrição do modelo",
    width: "376px",
  },
  {
    id: "pvp",
    label: "PVP ",
    width: "121px",
  },
  {
    id: "extras",
    label: "Extras",
    width: "121px",
  },
  {
    id: "deduction",
    label: "Desconto",
    width: "139px",
  },
  {
    id: "proforma-price",
    label: "Preço proforma",
    width: "127px",
  },
  {
    id: "business price",
    label: "Preço de negócio",
    width: "140px",
  },
];

const proformaFilesProgressBarProps = [
  {
    progress: 55,
    title: "nome-ficheiro.pdf",
    label: "Label",
    deleteIcon: true,
    deleteTooltipText: "Apagar",
  },
  {
    progress: 70,
    title: "nome-ficheiro.pdf",
    label: "Label",
    deleteTooltipText: "Apagar",
  },
  {
    progress: 100,
    title: "nome-ficheiro.pdf",
    label: "Label",
    deleteIcon: true,
    deleteTooltipText: "Apagar",
  },
  {
    progress: 55,
    title: "nome-ficheiro.pdf",
    label: "12mB/28mB",
    size: "small",
    deleteIcon: true,
    deleteTooltipText: "Apagar",
  },
  {
    progress: 20,
    title: "nome-ficheiro.pdf",
    label: "Erro",
    size: "small",
    error: true,
    deleteIcon: true,
    deleteTooltipText: "Apagar",
  },
];

const vehiclesService =  new VehicleService();
const custService = new CustomerService();
const contactService = new ContactsService();
const commentsService = new CommentsService();
const proposalService = new ProposalService();
const profileService = new ProfileServices();

const CreateProposalNew: React.FC<KintoRootProps> = ({ onLogout }) => {
  //#region HYDRA
  const { asyncResponse, setAsyncResponse } = useAsyncRequest();

  //#region VARS
  const [settings, setSettings] = useState<AppSettings>(new EmptyAppSettings());

  // const settingsService = await SettingsService.getInstance();
  // const settings = settingsService.settings as AppSettings;

  const filterOptions = createFilterOptions({
    limit: 100,
  });

  const {
    currentUser,
    // setCurrentUser,
    propertyDisabled,
    propertyHidden,
    propertyValidation,
    allowedValues
  } = usePermissionsHook();

  const {
    // descomentar estas funcionalidades conforme forem necessárias utilizar
    // navigate,
    handleCardClick,
    // handleLogout,
    backofficeMenuButtonPropsMemo,
    clientsMenuButtonPropsMemo,
    contractsMenuButtonPropsMemo,
    dashboardMenuButtonPropsMemo,
    performanceMenuButtonPropsMemo,
    proposalMenuButtonPropsMemo,
    topBarPropsMemo
  } = useNavbarHook(onLogout);


  const breadcrumbList = useMemo(() => [
    {
      label: 'Propostas',
      onClick: handleCardClick('proposal'),
    },
    {
      label: 'Nova Proposta',
    },
  ], [handleCardClick]);


  const [testInfo, setTestInfo]= useState< ProgressBarProps[]>([]);

  const [options, setOptions]= useState<any>({
    onUploadProgress: (progressEvent: { loaded: any; total: any }) => {
      const { loaded, total } = progressEvent;
      let percentage = Math.floor((loaded * 100) / total);
      //console.log(loaded +'kb of '+total+'kb | '+percentage+'%');
      let items = [...commentsAttachProgress];
      //temp fix
      if (items.length === 0 || (items.length > 0 && items[0] !== testInfo[0])) {
        items = testInfo;

        Array.from(items).map((item) => {
          let itm = item;
          itm.label = niceBytes(loaded) + " / " + niceBytes(total);
          // itm.label = niceBytes(item.progress.toString()) + " / " + niceBytes(item.progress.toString());
          itm.progress = percentage;
          itm.deleteIcon = true;
          itm.deleteTooltipText = "Apagar";
          return itm;
        });
      }
      //loads previous uploaded files progress bars to items/testInfo array along with current file progress bar
      if (progressBar.length > 0)
        progressBar.map((bar) => {
          return items.push(bar);
        });
    },
  });

  const [infoProgressBarProposal,setInfoProgressBarProposal]= useState< ProgressBarProps[]>([]);

  const [optionsProposal,setOptionsProposal]= useState<any>({
      onUploadProgress: (progressEvent: { loaded: any; total: any }) => {
        const { loaded, total } = progressEvent;
        let percentage = Math.floor((loaded * 100) / total);
        //console.log(loaded +'kb of '+total+'kb | '+percentage+'%');
        let items = [...progressBarProposal];
        //temp fix
        if (items.length === 0 || (items.length > 0 && items[0] !== infoProgressBarProposal[0])) {
          items = infoProgressBarProposal;

          Array.from(items).map((item) => {
            let itm = item;
            itm.label = niceBytes(loaded) + " / " + niceBytes(total);
            // itm.label = niceBytes(item.progress.toString()) + " / " + niceBytes(item.progress.toString());
            itm.progress = percentage;
            itm.deleteIcon = true;
            itm.deleteTooltipText = "Apagar";
            return itm;
          });
        }
        //loads previous uploaded files progress bars to items/infoProgressBarProposal array along with current file progress bar
        if (progressBarProposal.length > 0)
          progressBarProposal.map((bar) => {
            return items.push(bar);
        });
    },
  });

  const [editNote, setEditNote] = useState<RichEditorProps["html"]>(``);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [attachmentsProposal, setAttachmentsProposal] = useState<any[]>([]);
  const [commentsAttachProgress, setCommentsAttachProgress] = useState<ProgressBarProps[]>([]);

  const [totalCommentsUnread, setTotalCommentsUnread] = useState<number>(0);
  const [commentsFromGetResponse, setResponseComments] = useState<any>([]);
  const [comments, setComments] = useState<{
    avatarSrc: string;
    name: string;
    text: string;
    date: string;
    showEditButton?: boolean;
    files?: {
      file: File;
      onClick?: () => void;
    }[];
    newNotesAttachmentsFilesProgressBarProps?: ProgressBarProps[];
  }[]>([]);

  const [requiredClientData, setRequiredClientData] = useState<RequiredDataProps>(emptyRequiredData);
  const [requiredClientInterlocutorData, setRequiredClientInterlocutorData] = useState<RequiredDataProps>(emptyRequiredData);
  const [salesPersons, setSalesPersons] = useState<Option[]>([]);
  const [changedProposal, setChangedProposal] = useState<boolean>(false);
  const [changedContact, setChangedContact] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [requestingChannelOptions, setRequestingChannelOptions] = useState<any>([]);
  const [showClientData, setShowClientData] = useState<boolean>(false);
  const [clientsUpdate, setClientsUpdate] = useState<RequiredDataProps[]>([]);
  const [nifValidate, setNifValidate] = useState<boolean>(false);
  const [nifMessage, setNifMessage] = useState<string>("");
  const [nifIcon, setNifIcon] = useState<string>("");

  const [emailValidate, setEmailValidate] = useState<boolean>(false);
  const [emailMessage, setEmailMessage] = useState<string>("");
  const [emailIcon, setEmailIcon] = useState<string>("");

  const [phoneValidate, setPhoneValidate] = useState<boolean>(false);
  const [phoneMessage, setPhoneMessage] = useState<string>("");
  const [phoneIcon, setPhoneIcon] = useState<string>("");
  const [mobileValidate, setMobileValidate] = useState<boolean>(false);
  const [mobileMessage, setMobileMessage] = useState<string>("");
  const [mobileIcon, setMobileIcon] = useState<string>("");
  const [dataCustomerCombo, setDataCustomerByCombo] = useState([]);
  const [isNewClient, setIsNewClient] = useState<boolean>(false);
  const [searchClient, setSearchClient] = useState<{
    value: string;
    label: string;
  }>({ value: "", label: "" });
  //FUNCTIONAL GROUPS VEHICLES DATA - START
  const [functionalGroupsMakes, setFunctionalGroupsMakes] = useState<string[]>([]);
  const [functionalGroupsModels, setFunctionalGroupsModels] = useState<string[]>([]);
  const [functionalGroupsVersionTypes, setFunctionalGroupsVersionTypes] = useState<FunctionalGroupsVersionType[]>([]);
  //FUNCTIONAL GROUPS VEHICLES DATA - END
  const [makesSearchOptions, setMakesSearchOptions] = useState<any[]>([]);
  const [vehicleSearchOptions, setVehicleSearchOptions] = useState<Option[]>(
    []
  );
  const [progressBar, setNewProgressBar] = useState<ProgressBarProps[]>([]);
  const [progressBarProposal, setNewProgressBarProposal] = useState<ProgressBarProps[]>([]);
  const [rentalTypes, setRentalTypes] = useState<Option[]>([]);
  const [rentalTypeSettings, setRentalTypeSettings] = useState<any[]>([]);
  const [commercialConditions, setCommercialConditions] = useState<Option[]>(
    []
  );
  const [paymentTerms, setPaymentTerms] = useState<any[]>(settings.Data.Vehicles_TypeOfRental);
  const [drivers, setDrivers] = useState<Option[]>([]);
  const [allDrivers, setAllDrivers] = useState<Option[]>([]);
  const [searchDriver, setSearchDriver] = useState<{
    value: string;
    label: string;
  }>({ value: "", label: "" });

  const handleSetBrand = (makesSearchOptionsTmp: any[]) => {
    return makesSearchOptionsTmp.map((item: { label: string }) => {
      return {
        label: item.label,
        value: item.label,
      };
    });
  };
  const brandOptions = useMemo(() => handleSetBrand(makesSearchOptions),[makesSearchOptions]);
  const [open, setOpen] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<Omit<AlertProps, "open" | "onClose">>({
    variant: "info",
    text: "",
    title: "",
    autoHideDuration: 3000,
  });

  const openAlert = (alertProps: Omit<AlertProps, "open" | "onClose">) => {
    setAlertProps(alertProps);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [versionsSearchOptions, setVersionsSearchOptions] = useState<IVersion[]>([{
    makeId: "",
    modelId:"",
    versions: [{ label: "", value: "", isInactive: false}],
  }]);

  const [modelSearchOptions, setModelsSearchOptions] = useState<IModel[]>([{
    makeId: "",
    models: [{ label: "", value: "" }],
  }]);

  const handleQuotationSimulations = (vehicle: IVehicle) => {
    let rows: any[] = [];
    vehicle?.headerSimulation?.frequentSimulations.forEach((simulationData:IFrequentSimulation) => {
      let row = {
        amount: {
          label: "Qtd.",
          value: vehicle.quantity,
        },
        term: {
          label: "Prazo (meses)",
          value:convertNumber(simulationData.deadline),
        },
        km: {
          label: "Km",
          value: simulationData.miles,
        },
        proformaPrice: {
          label: "P. Proforma",
          value: `${simulationData.proformaInvoicePriceWithTax} €`,
        },
        businessPrice: {
          label: "V. Negócio",
          value: `${simulationData.businessValueWithoutTax} €`,
        },
        realValue: {
          label: "VR Negócio C/IVA",
          value: `${simulationData.realVR} €`,
        },
        realValueIncrease: {
          label: "Aumento VR	",
          value: `${simulationData.increaseVR} %`,
        },
        realValueTable: {
          label: "VR Tabela C/IVA",
          value: `${simulationData.vrTable} €`,
        },
        eurotax: {
          label: "VR Eurotax Original C/IVA",
          value: `${simulationData.vrEurotax} €`,
        },
        rappel: {
          label: "Rappel",
          value: `${simulationData.rappelWithTaxTotal} €`,
        },
        margin: {
          label: "%Margem	",
          value: `${simulationData.margin} %`,
        },
        rentWithoutTax: {
          label: "Renda sem IVA",
          value: `${simulationData.incomeWithoutTax} €`,
        },
        rentWithTax: {
          label: "Renda com IVA",
          value:`${simulationData.incomeWithTax} €`
        },
      };
      rows.push(row);
    });
    return rows;
  };

  const [vehicleGroupEditModalValues, setVehicleGroupEditModalValues] =
    useState<{
      // step 5
      incomeWithoutTax: number; // renda target
      incomeWithTax: number;
      collateral: number; // caução
      margin: number; // % margem
      increaseVr: number; // % aumento vr
      initialEntry: number; // entrada inicial
      expenses: number; // despesas de contrato
      marginCheck: boolean;
      commercialEffortCheck: boolean; //disabled -  same as margin check
      vrCheck: boolean;

      // step 2
      discountWithTax: number;
      discountWithoutTax: number;
      discountWithTaxPercentage: number;
      campaignWithTax: number;
      campaignWithTaxPercentage: number;
      campaignWithoutTax: number;
      rappelWithTax: number;
      rappelWithTaxPercentage: number;
      rappelWithoutTax: number;
      extraRappel: number;
      lotId: string;
      extraAccessories: {
        extraAcessoryName: string;
        extraAcessoryPrice: number;
        extraAcessoryInstaller: string;
      }[];
    }>({
      incomeWithoutTax: 0,
      incomeWithTax: 0,
      collateral: 0,
      margin: 0,
      increaseVr:0,
      initialEntry: 0,
      expenses: 0,
      marginCheck: false,
      commercialEffortCheck: false,
      vrCheck: false,
      discountWithTax: 0,
      discountWithoutTax: 0,
      discountWithTaxPercentage: 0,
      campaignWithTax: 0,
      campaignWithTaxPercentage: 0,
      campaignWithoutTax: 0,
      rappelWithTax: 0,
      rappelWithTaxPercentage: 0,
      rappelWithoutTax: 0,
      extraRappel: 0,
      lotId: "",
      extraAccessories: [],
    });

  const { proposalId } = useParams();
  const [proposal, setProposal] = useState<Proposal>(
    {
      id: 0,
      description: "Proposta de aluguer",
      webPortalContactId: "",
      contactId: "",
      inputChannel: "0",
      requestingChannel: "0",
      totalFleet: 0,
      clientType: "",
      clientTypology: "",
      comments: [],
      vehicles: [],
      isClientApproved: false,
      sendToApprovedDate:"",
      sendToApprovedValidateDate:"",
      leaseQuoteNo: "", //"CO10156555",
      //listagem de propostas
      clientName: "",
      nif: "",
      salespersonCode: "",
      salesPersonName: "",
      state: "Open",
      printProposalRentalTypeId: "",
      printProposalAttachments: [],
      printProposalComments:"",
      printProposalDestinationEmailAddress:"",
      printProposalTitle:""
    }
  );

  const [vehiclesListingTotal, setVehiclesListingTotal] = useState<number>(0);
  const [vehicleDataTable, setVehicleDataTable] = useState<IVehicle[]>([]);



  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  //mostFrequentModal USESTATES
  const [selectedFrequentVehicles, setSelectedFrequentVehicles] = useState<
    {
      checked: boolean;
      mostFrequentId: number;
    }[]
  >([]);
  const [mostFrequent, setMostFrequent] = useState<IMostFrequent[]>([]);
  const [mostFrequentTotal, setMostFrequentTotal] = useState<number>(0);
  const [mostFrequentSearchValue, setMostFrequentSearchValue] = useState<string>('');
  const [allInsurances, setAllInsurances] = useState<any[]>([]);
  const [mostFrequentSearched, setMostFrequentSearched] = useState<
    IMostFrequent[]
  >([]);
  const [frequentSearchOptions, setFrequentSearchOptions] = useState<Option[]>(
    []
  );
  const [mostFrequentConfigurations, setMostFrequentConfigurations] = useState<
    any[]
  >([]);

  //frequentSimulation
  const [frequentHeaderSimulations, setFrequentHeaderSimulations] = useState<any[]>([]);
  const [frequentSimulationOptions, setFrequentSimulationOptions] = useState<
    any[]
  >([]);
  const [scenariosFrequentSimulation, setScenariosFrequentSimulation] = useState<
    any[]
  >([]);
  const [frequentSimulationSearchOptions, setFrequentSimulationSearchOptions] =
    useState<any[]>([]);
  const [selectedFrequentOption, setSelectedFrequentOption] = useState<any>();
  //frequentQuotation
  const [frequentQuotations, setFrequentQuotations] = useState<any[]>([]);
  const [
    frequentQuotationsConfigurations,
    setFrequentQuotationsConfigurations,
  ] = useState<any[]>([]);
  const [frequentQuotationOptions, setFrequentQuotationOptions] = useState<
    any[]
  >([]);

  const [brandInput, setBrandInput] = useState<Option>({
    value: "",
    label: "",
  });

  const [step, setStep] = useState<number>(0);
  const [newNote, setNewNote] = useState<RichEditorProps["html"]>(``);
  const [isDetailsStep2VehicleOpen, setIsDetailsStep2VehicleOpen] = useState<
    boolean[]
  >(vehicleDataTable.map((_) => false));

  const [campaignFilters, setCampaignFilters] = useState<{ type: AdvancedFilterVehiclesOptions; value: string }[]>([]);
  const [totalCampaignVehiclesAmount, setTotalCampaignVehiclesAmount] = useState<number>(0);
  const [selectedCampaignVehicles, setSelectedCampaignVehicles] = useState<
    CampaignVehicleDetails[]
  >([]);
  const [filterResults, setFilterResults] =
    useState<
      CreateProposalPageProps["vehicleDataProps"]["filterDrawerProps"]["result"]
    >();
  const [vehicleBC, setVehicleBC] = useState<IVehicle[]>([]);
  const [usedVehicleOption, setUsedVehicleOptions] = useState<
    UsedVehicleDetails[]
  >([]);
  const [campaignVehicleOptions, setCampaignVehicleOptions] = useState<
    CampaignVehicleDetails[]
  >([]);
  const [usedVehicleSearchOptions, setUsedVehicleSearchOptions] = useState<
    Option[]
  >([]);
  const [selectedOptionId, setSelectedOptionId] = useState<string>("");
  const [selectedVehicles, setSelectedVehicles] = useState<
    UsedVehicleDetails[]
  >([]);
  const [preConfiguredVehicles, setPreConfiguredVehicles] = useState<IPreConfiguredVehicleDetails[]>([]);
  const [preConfiguredVehiclesSearchOptions, setPreConfiguredVehiclesSearchOptions] = useState<Option[]>([]);
  const [preConfiguredVehiclesSearchValue, setPreConfiguredVehiclesSearchValue] = useState<string>("");

  const [simulationLoading, setSimulationLoading] = useState<boolean>(false);
  const [simulationLoadingText, setSimulationLoadingText] =
    useState<string>("");
  const [proposalPrintTemplates, setProposalPrintTemplates] = useState<
    Option[]
  >([]);
  const [contractSelectedRows, setContractSelectedRows] = useState<{
    vehicleIndex: number;
    simulationIndex: number
  }[]>([{
    vehicleIndex: -1,
    simulationIndex: -1
  }]);
  // FILTROS - CHECKBOX SEGMENTOS
  //const [advFilterSegmentsOptions, setAdvFilterSegmentsOptions] = useState<any>([]);
  // FILTROS - CHECKBOX ESTADO VIATURA
  //const [advFilterTypeStatusOptions, setAdvFilterTypeStatusOptions] = useState<CheckboxProps[]>([]);
  // FILTROS - CHECKBOX TTPO COMBUSTIVEL
  //const [advFilterFuelTypeOptions, setAdvFilterFuelTypeOptions] = useState<CheckboxProps[]>([]);
  // FILTROS - CHECKBOX LOTAÇÃO DA VIATURA
  //const [advFilterSeats, setAdvFilterSeats] = useState<CheckboxProps[]>([])

  // const [appliedFilters, setAppliedFilters] = useState<{
  //   label: string;
  //   id: string;
  // }[]>([]);


  // //ESPECIFICAÇÕES DA VIATURA
  // const [vehiclesMinGrossWeightFilter, setVehiclesMinGrossWeightFilter] =
  //   useState(settings.Data.Vehicles_MinGrossWeight);
  // const [vehiclesMaxGrossWeightFilter, setVehiclesMaxGrossWeightFilter] =
  //   useState(settings.Data.Vehicles_MaxGrossWeight);

  // const [vehiclesMinMaxSuitcaseVolumeFilter,setVehiclesMinMaxSuitcaseVolumeFilter] =
  //   useState(settings.Data.Vehicles_MinMaxSuitcaseVolume);
  // const [vehiclesMaxMaxSuitcaseVolumeFilter,setVehiclesMaxMaxSuitcaseVolumeFilter] =
  //   useState(settings.Data.Vehicles_MaxMaxSuitcaseVolume);


  // //ESPAÇO E DIMENSÕES
  // const [vehiclesMinLengthFilter, setVehiclesMinLengthFilter] =
  //  useState(settings.Data.Vehicle_MinLength);
  // const [vehiclesMaxLengthFilter, setVehiclesMaxLengthFilter] =
  //   useState(settings.Data.Vehicles_MaxLength);

  // const [vehiclesMinWidthFilter, setVehiclesMinWidthFilter] =
  //   useState( settings.Data.Vehicles_MinWidth);
  // const [vehiclesMaxWidthFilter, setVehiclesMaxWidthFilter] =
  //   useState(settings.Data.Vehicles_MaxWidth);

  // const [vehiclesMinHeightFilter, setVehiclesMinHeightFilter] =
  //   useState(settings.Data.Vehicles_MinHeight);
  // const [vehiclesMaxHeightFilter, setVehiclesMaxHeightFilter] =
  //   useState(settings.Data.Vehicles_MaxHeight);

  // const [vehiclesMinDistanceBetweenFrontAxlesFilter, setVehiclesMinDistanceBetweenFrontAxlesFilter] =
  //   useState(settings.Data.Vehicles_MinDistanceBetweenFrontAxles);
  // const [ vehiclesMaxDistanceBetweenFrontAxlesFilter, setVehiclesMaxDistanceBetweenFrontAxlesFilter] =
  //   useState(settings.Data.Vehicles_MaxDistanceBetweenFrontAxles);

  // const [vehiclesMinDistanceBetweenRearAxlesFilter,setVehiclesMinDistanceBetweenRearAxlesFilter] =
  //   useState(settings.Data.Vehicles_MinDistanceBetweenRearAxles);
  // const [vehiclesMaxDistanceBetweenRearAxlesFilter,setVehiclesMaxDistanceBetweenRearAxlesFilter] =
  //   useState(settings.Data.Vehicles_MaxDistanceBetweenRearAxles);


  // //ESPECIFICAÇÕES DO MOTOR
  // const [vehiclesMinPowerFilter, setVehiclesMinPowerFilter] =
  //   useState(settings.Data.Vehicles_MinPower);
  // const [vehiclesMaxPowerFilter, setVehiclesMaxPowerFilter] =
  //   useState(settings.Data.Vehicles_MaxPower);

  // const [vehiclesMinEngineCapacityFilter, setVehiclesMinEngineCapacityFilter] =
  //   useState(settings.Data.Vehicles_MinEngineCapacity);
  // const [vehiclesMaxEngineCapacityFilter, setVehiclesMaxEngineCapacityFilter] =
  //   useState(settings.Data.Vehicles_MaxEngineCapacity);

  // const [vehiclesMinMaxTorqueFilter, setVehiclesMinMaxTorqueFilter] =
  //   useState(settings.Data.Vehicles_MinMaxTorque);
  // const [vehiclesMaxMaxTorqueFilter, setVehiclesMaxMaxTorqueFilter] =
  //   useState(settings.Data.Vehicles_MaxMaxTorque);

  //   const [vehiclesMinCO2EmissionFilter, setVehiclesMinCO2EmissionFilter] =
  //   useState(settings.Data.Vehicles_MinCO2Emission);
  // const [vehiclesMaxCO2EmissionFilter, setVehiclesMaxCO2EmissionFilter] =
  //   useState(settings.Data.Vehicles_MaxCO2Emission);

  // //ESPECIFICAÇÕES DA VIATURA
  // const vehicleMinGrossWeightDefaultValue = settings.Data.Vehicles_MinGrossWeight;
  // const vehicleMaxGrossWeightDefaultValue =settings.Data.Vehicles_MaxGrossWeight;

  // const vehicleMinMaxSuitcaseVolumeDefaultValue = settings.Data.Vehicles_MinMaxSuitcaseVolume;
  // const vehicleMaxMaxSuitcaseVolumeDefaultValue = settings.Data.Vehicles_MaxMaxSuitcaseVolume;

  // //ESPAÇO E DIMENSÕES
  // const vehicleMinLengthDefaultValue = settings.Data.Vehicle_MinLength;
  // const vehicleMaxLengthDefaultValue = settings.Data.Vehicles_MaxLength;

  // const vehicleMinWidthDefaultValue = settings.Data.Vehicles_MinWidth;
  // const vehicleMaxWidthDefaultValue = settings.Data.Vehicles_MaxWidth;

  // const vehicleMinHeightDefaultValue = settings.Data.Vehicles_MinHeight;
  // const vehicleMaxHeightDefaultValue = settings.Data.Vehicles_MaxHeight;

  // const vehicleMinDistanceBetweenFrontAxlesDefaultValue = settings.Data.Vehicles_MinDistanceBetweenFrontAxles;
  // const vehicleMaxDistanceBetweenFrontAxlesDefaultValue =settings.Data.Vehicles_MaxDistanceBetweenFrontAxles;

  // const vehicleMinDistanceBetweenRearAxlesDefaultValue = settings.Data.Vehicles_MinDistanceBetweenRearAxles;
  // const vehicleMaxDistanceBetweenRearAxlesDefaultValue = settings.Data.Vehicles_MaxDistanceBetweenRearAxles;

  // //ESPECIFICAÇÕES DO MOTOR
  // const vehicleMinPowerDefaultValue = settings.Data.Vehicles_MinPower;
  // const vehicleMaxPowerDefaultValue = settings.Data.Vehicles_MaxPower;

  // const vehicleMinEngineCapacityDefaultValue = settings.Data.Vehicles_MinEngineCapacity;
  // const vehicleMaxEngineCapacityDefaultValue = settings.Data.Vehicles_MaxEngineCapacity;

  // const vehicleMinMaxTorqueDefaultValue = settings.Data.Vehicles_MinMaxTorque;
  // const vehicleMaxMaxTorqueDefaultValue = settings.Data.Vehicles_MaxMaxTorque;

  // const vehicleMinCO2EmissionDefaultValue = settings.Data.Vehicles_MinCO2Emission;
  // const vehicleMaxCO2EmissionDefaultValue = settings.Data.Vehicles_MaxCO2Emission;


  const [newVehicleFrequentIndex, setNewVehicleFrequentIndex] = useState<number>();
  const [newFrequent, setNewFrequent] = useState<any>();
  const [frequentTitle, setFrequentTitle] = useState<string>("");
  const [frequentVisibility, setFrequentVisibility] = useState<number>(0);

  const [isInsuranceConditionsOpen, setIsInsuranceConditionsOpen] =
    useState<boolean>(false);
  const [
    isReplacementVehicleConditionsOpen,
    setIsReplacementVehicleConditionsOpen,
  ] = useState<boolean>(false);

  const [typeofIncomeGroup, setTypeofIncomeGroup] =useState<string>("");
  const [commercialConditionGroup, setCommercialConditionGroup]= useState<string>("");
  const [typeOfRentalGroup, setTypeOfRentalGroup] = useState<string>("");

  const [leaseQuoteCreatedInBC, setLeaseQuoteCreatedInBC] = useState<boolean>(false);
  const [ contractSettingsCheckedVehicles, setContractSettingsCheckedVehicles ] = useState(new Set());

  const [insuranceFrameworkContractSet, setInsuranceFrameworkContractSet ] = useState(new Set<InsuranceFrameworkContract>()) // useState(new Set());


  const [insurances, setInsurances] = useState<boolean[]>([false,false]);
  const handleShowInsuranceConditions = useCallback(() => {
    let disabled = !insurances[1];
    let findCommercialConditions = vehicleDataTable[0].commercialConditions;
    let findUse = vehicleDataTable[0].use;
    let updatedCheckedList = new Set(contractSettingsCheckedVehicles);
    if(updatedCheckedList.size > 0 && vehicleDataTable.length > 0) {
      vehicleDataTable.forEach((vehicle) => {
        if(updatedCheckedList.has(vehicle.id.toString())) {
          if(findCommercialConditions !== vehicle.commercialConditions || findUse !== vehicle.use) {
            disabled = true;
          }
          findCommercialConditions = vehicle.commercialConditions;
          findUse = vehicle.use;
        }
      });
    }
    if(disabled){
      setIsInsuranceConditionsOpen((old) => true);
    } else {
      setIsInsuranceConditionsOpen((old) => !old);
    }
  }, [vehicleDataTable, contractSettingsCheckedVehicles, insurances]);


  const [replacementVehicles, setReplacementVehicles] =useState<boolean[]>([false,false]);
  const handleShowReplacementBehicleConditions = useCallback(() => {
    let disabled = !replacementVehicles[1];
    if(disabled) {
      setIsReplacementVehicleConditionsOpen((old) => true);
    } else {
      setIsReplacementVehicleConditionsOpen((old) => !old);
    }
  }, [replacementVehicles]);

  //RadioButtonGroups


  const [fuelNo, setFuelNo] = useState<boolean>(false);
  const [fuelYes, setFuelYes] = useState<boolean>(false);
  const [tiresNo, setTiresNo] = useState<boolean>(false);
  const [tiresYes, setTyresYes] = useState<boolean>(false);
  const [tiresLimitedNo, setTiresLimitedNo] = useState<boolean>(false);
  const [tiresLimitedYes, setTiresLimitedYes] = useState<boolean>(false);
  const [viaVerdeNo, setViaVerdeNo] = useState<boolean>(false);
  const [viaVerdeYes, setViaVerdeYes] = useState<boolean>(false);
  const [visibleRadioGroup, setVisibleRadioGroup] = useState<number>(1);
  const [firstLineTyresRadioGroup, setFirstLineTyresRadioGroup] = useState<boolean>(false);
  const [secondLineTyresRadioGroup, setSecondLineTyresRadioGroup] = useState<boolean>(false);
  const [thirdLineTyresRadioGroup, setThirdLineTyresRadioGroup] = useState<string>("false");
  const [useScenario, setUseScenario] = useState<string>("");
  const [simulationScenario, setSimulationScenario] =
    useState<Simulation>({
      id: 0,
      no: "",
      deadline: 0,
      miles: 0,
      tires: false,
      tiresQuantity: "",
      mostUsed:0,
      insurance: "",
      maintenance: false,
      secureOptions: false,
      vehicleReplacementOptions: false,
      ownDamage: "",
      vehicleType: "",
      occupants: "",
      travelAssistance: "",
      responsibility: "",
      glassBreakage: "",
      maintenanceDescription: "",
      iuc: 0,
      fuelCard: false,
      petrolPump: false,
      viaVerde: false,
      entityViaVerde: "",
      fuelDescriptiom: "",
      runFlaat: false,
      vsOverhaulVehicle: "",
      vsOverhaulVehicleType: "",
      vsAssistanceVehicleType: "",
      vsAssistanceVehicle: "",
      vsAccidentVehicleType: "",
      vsAccidentVehicle: "",
      vsTheftVehicleType: "",
      vsTheftVehicle: "",
      insurancePackageOptions: [],
      vehicleDamageOptions: [],
      occupiersOptions: [],
      roadAssistanceOptions: [],
      liabilityOptions: [],
      isolatedGlassBreakOptions: [],
      vsAssistanceVehicleOptions: [],
      vsAssistanceVehicleTypeOptions: [],
      vsAccidentVehicleOptions: [],
      vsAccidentVehicleTypeOptions: [],
      vsTheftVehicleOptions: [],
      vsTheftVehicleTypeOptions: [],
      vsOverhaulVehicleOptions: [],
      vsOverhaulVehicleTypeOptions: [],
      vehicleTypeOptions: [],
      fuelOptions: [],
      // VS:false, //FIXME Descomentar depois de adicionar o campo ao objecto SIMULATION na API

      //FIXME Alexandre: CORRIGIR ESTAS ALTERAÇÕES quando estiverem definidas as regras para as cotações
      //campos adicionados para cotações
      //configurações de negócio
      priceexclVAT: 0,
      consumersPrice: 0,

      totalExtraWithoutTax: 0,
      totalExtraWithTax: 0,

      totalAccessoriesWithout: 0,
      totalAccessoriesWith: 0,

      discountWithoutTax: 0,
      discountWithTax: 0,
      discountWithTaxPercentage: 0,
      discountWithTaxTotal: 0,

      proformaInvoicePriceWithoutTax: 0,
      proformaInvoicePriceWithTax: 0,

      campaignWithoutTax: 0,
      campaignWithTax: 0,
      campaignWithTaxPercentage: 0,
      campaignWithTaxTotal: 0,

      rappelWithoutTax: 0,
      rappelWithTax: 0,
      rappelWithTaxPercentage: 0,
      rappelWithTaxTotal: 0,

      extraRappel: 0,

      businessValueWithoutTax: 0,
      businessValueWithTax: 0,
      //tipo de contrato
      quantity: 0,
      // quotation calculated fields
      baseIncomeWithoutTax: 0,
      incomeWithoutTax: 0,
      incomeWithTax: 0,
      increaseVR: 0,
      margin: 0,
      realVR: 0,
      vrEurotax: 0,
      vrTable: 0,
      baseVRTable: 0,

      amortization: 0,
      interests: 0,
      //validar se estes 5 campos estão duplicados
      tyresCost: 0,
      maintenanceCost: 0,
      iucCost: 0,
      insuranceCost: 0,
      vsCost: 0,

      finalTax: 0,
      estimatedMargin: 0,
      monthlyTCOWithTax: 0,
      monthlyTCOWithoutTax: 0,
      additionalKmsWithTax: 0,
      additionalKmsWithoutTax: 0,
      collateral: 0,
      fee: 0,
      initialEntry: 0,
      travelledKmsWithTax: 0,
      travelledKmsWithoutTax: 0,
      vehicleId:0,
      viaVerdeOptions:[],
      proposeToCustomer:false,
      title: "",
      visibility: 0,
      complementaryProducts: [],
      maximum_Kilometers:0,
      contractExpenses: 0,
      commissionValue: 0,
      commissionCode: ""
    });

  const [contractSettingsGroupEditTiresNo, setContractSettingsGroupEditTiresNo] = useState<boolean>(true);
  const [contractSettingsGroupEditTiresYes, setContractSettingsGroupEditTiresYes] = useState<boolean>(false);
  const [contractSettingsGroupEditTiresLimited, setContractSettingsGroupEditTiresLimited] = useState<boolean>(false);
  const [commercialConditionsScenario, setCommercialConditionsScenario]= useState<string>("");
  const [thirdLineTire, setThirdLineTire] = useState<number>(0);
  const [annualKm, setAnnualKm] = useState<number>(0);
  const [deadline, setDeadline] = useState<number>(0);
  const [editGroupExtraAccessories, setEditGroupExtraAccessories] = useState<ExtraAccessory[]>([]);
  //  const [editGroupExtraAccessories, setEditGroupExtraAccessories] = useState<GroupEditModalProps['extraAccessories']>([]);
  const [contractSettingsCardCheckedDemo, setContractSettingsCardCheckedDemo] = useState<string[]>([]);

  const {
    complementaryProductsByVehicleMemo,
    modalComplementaryProductsMemo,
    setModalComplementaryProductsList,
    setModalFilteredComplementaryProducts,
    setModalSelectedSearchOptions,
    modalComplementaryProductsList,
    modalFilteredComplementaryProducts
  } = useComplementaryProductsHook(vehicleDataTable, setVehicleDataTable, setChangedProposal, commercialConditionsScenario);

  const [newMostFrequent, setNewMostFrequent] = useState<any>();


  //#endregion

  //#region FUNCTIONS

  const [contractSettingsGroupEdit_DeadlineValue, setContractSettingsGroupEdit_DeadlineValue] = useState<string>('');
  const [contractSettingsGroupEdit_AnnualKmValue, setContractSettingsGroupEdit_AnnualKmValue] = useState<string>('');
  const [contractSettingsGroupEdit_TiresValue, setContractSettingsGroupEdit_TiresValue] = useState<string>('');
  const [deadlineValues,setDeadlineValues] = useState<CheckboxProps[]>([]);
  const [annualKmValues,setAnnualKmValues] = useState<CheckboxProps[]>([]);

  const resetScenarioModal = useCallback(() => {
    setInsurances((rows)=>{
      return rows.map((row) => {
        return false;
      });
    });
    setReplacementVehicles((rows)=>{
      return rows.map((row) => {
        return false;
      });
    });
    setVisibleRadioGroup(1);
    setDeadlineValues(rows => {
      rows.map( row => row.checked = false)
      return [...rows];
    })
    setAnnualKmValues(rows => {
      rows.map( row => row.checked = false)
      return [...rows];
    })

    setTiresLimitedNo(false);
    setTiresLimitedYes(false);
    setTiresNo(false);
    setTyresYes(false);
    setThirdLineTiresValuesMultiScenario(rows => {
      rows.forEach( row => row.checked = false )
      return rows
    })

    let simulationDefault:Simulation={
      id: 0,
      no: "",
      deadline: 0,
      miles: 0,
      tires: false,
      tiresQuantity: "",
      mostUsed:0,
      insurance:"",
      maintenance: false,
      secureOptions: false,
      vehicleReplacementOptions: false,
      ownDamage: "",
      vehicleType: "",
      occupants: "",
      travelAssistance: "",
      responsibility: "",
      glassBreakage: "",
      maintenanceDescription: "",
      iuc: 0,
      fuelCard: false,
      petrolPump: false,
      viaVerde: false,
      entityViaVerde: "",
      fuelDescriptiom: "",
      runFlaat: false,
      vsOverhaulVehicle: "",
      vsOverhaulVehicleType: "",
      vsAssistanceVehicleType: "",
      vsAssistanceVehicle: "",
      vsAccidentVehicleType: "",
      vsAccidentVehicle: "",
      vsTheftVehicleType: "",
      vsTheftVehicle: "",
      insurancePackageOptions:[],
      vehicleDamageOptions: [],
      occupiersOptions: [],
      roadAssistanceOptions:[],
      liabilityOptions: [],
      isolatedGlassBreakOptions: [],
      vsAssistanceVehicleOptions: simulationScenario.vsAssistanceVehicleOptions,
      vsAssistanceVehicleTypeOptions: simulationScenario.vsAssistanceVehicleTypeOptions,
      vsAccidentVehicleOptions:simulationScenario.vsAccidentVehicleOptions,
      vsAccidentVehicleTypeOptions: simulationScenario.vsAccidentVehicleTypeOptions,
      vsTheftVehicleOptions: simulationScenario.vsTheftVehicleOptions,
      vsTheftVehicleTypeOptions: simulationScenario.vsTheftVehicleTypeOptions,
      vsOverhaulVehicleOptions:simulationScenario.vsOverhaulVehicleOptions,
      vsOverhaulVehicleTypeOptions: simulationScenario.vsOverhaulVehicleTypeOptions,
      vehicleTypeOptions: simulationScenario.vehicleTypeOptions,
      fuelOptions: simulationScenario.fuelOptions,
      // VS:false, //FIXME Descomentar depois de adicionar o campo ao objecto SIMULATION na API

      //FIXME Alexandre: CORRIGIR ESTAS ALTERAÇÕES quando estiverem definidas as regras para as cotações
      //campos adicionados para cotações
      //configurações de negócio
      priceexclVAT: 0,
      consumersPrice: 0,

      totalExtraWithoutTax: 0,
      totalExtraWithTax: 0,

      totalAccessoriesWithout: 0,
      totalAccessoriesWith: 0,

      discountWithoutTax: 0,
      discountWithTax: 0,
      discountWithTaxPercentage: 0,
      discountWithTaxTotal: 0,

      proformaInvoicePriceWithoutTax: 0,
      proformaInvoicePriceWithTax: 0,

      campaignWithoutTax: 0,
      campaignWithTax: 0,
      campaignWithTaxPercentage: 0,
      campaignWithTaxTotal: 0,

      rappelWithoutTax: 0,
      rappelWithTax: 0,
      rappelWithTaxPercentage: 0,
      rappelWithTaxTotal: 0,

      extraRappel: 0,

      businessValueWithoutTax: 0,
      businessValueWithTax: 0,
      //tipo de contrato
      quantity: 0,
      // quotation calculated fields
      baseIncomeWithoutTax: 0,
      incomeWithoutTax: 0,
      incomeWithTax: 0,
      increaseVR: 0,
      margin: 0,
      realVR: 0,
      vrEurotax: 0,
      vrTable: 0,
      baseVRTable: 0,

      amortization: 0,
      interests: 0,
      //validar se estes 5 campos estão duplicados
      tyresCost: 0,
      maintenanceCost: 0,
      iucCost: 0,
      insuranceCost: 0,
      vsCost: 0,

      finalTax: 0,
      estimatedMargin: 0,
      monthlyTCOWithTax: 0,
      monthlyTCOWithoutTax: 0,
      additionalKmsWithTax: 0,
      additionalKmsWithoutTax: 0,
      collateral: 0,
      fee: 0,
      initialEntry: 0,
      travelledKmsWithTax: 0,
      travelledKmsWithoutTax: 0,
      vehicleId:0,
      viaVerdeOptions:[],
      proposeToCustomer:false,
      title: "",
      visibility: 0,
      complementaryProducts: [],
      maximum_Kilometers:0,
      contractExpenses: 0,
      commissionValue: 0,
      commissionCode: ""
    };
    setFrequentTitle("");
    setFrequentVisibility(0);
    setSimulationScenario(simulationDefault);
  },[
    // annualKmValues, deadlineValues,
    simulationScenario.fuelOptions,

    // simulationScenario.insurance, simulationScenario.insurancePackageOptions,
    // simulationScenario.isolatedGlassBreakOptions, simulationScenario.liabilityOptions,
    // simulationScenario.occupiersOptions, simulationScenario.roadAssistanceOptions,
    // simulationScenario.vehicleDamageOptions,

    simulationScenario.vehicleTypeOptions,
    simulationScenario.vsAccidentVehicleOptions, simulationScenario.vsAccidentVehicleTypeOptions,
    simulationScenario.vsAssistanceVehicleOptions, simulationScenario.vsAssistanceVehicleTypeOptions,
    simulationScenario.vsOverhaulVehicleOptions, simulationScenario.vsOverhaulVehicleTypeOptions,
    simulationScenario.vsTheftVehicleOptions, simulationScenario.vsTheftVehicleTypeOptions
  ]);

  function niceBytes(a: string) {
    let b = 0,
      c = parseInt(a, 10) || 0;
    for (; 1024 <= c && ++b; ) c /= 1024;
    return (
      c.toFixed(10 > c && 0 < b ? 1 : 0) +
      " " +
      ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][b]
    );
  }

  const handleAttachProposalFiles =useCallback((e: ChangeEvent<HTMLInputElement>) => {
    Array.from(e.target.files!).map((file) => {
      let fileType: string = file.name.split(".").pop()!;
      if (file.size > 2097152) {
        openAlert({
          variant: "info",
          text: "O Tamanho do ficheiro é superior a 2MB. Por favor introduza um ficheiro dentro do limite de tamanho. ",
          title: "Atenção",
          autoHideDuration: 3000,
        });
      } else if (
        !".jpg, .jpeg, .png, .doc, .pdf, .gif, .zip, .rar, .txt, .xls, .docx, .xlsx".includes(
          fileType
        )
      ) {
        openAlert({
          variant: "info",
          text: "O tipo de ficheiro não é permitido. Por favor introduza um ficheiro com um tipo de ficheiro permitido. ",
          title: "Atenção",
          autoHideDuration: 3000,
        });
      } else {
        const progressBarProps: ProgressBarProps = {
          progress: 0,
          title: file.name,
          label: "",
          deleteIcon: true,
          deleteTooltipText: "Apagar",
        };
        if (file.size <= 2097152) {
          infoProgressBarProposal.push(progressBarProps);
        }
      }
      return infoProgressBarProposal;
    });
  },[infoProgressBarProposal]);

  const handleAttachFiles =useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let progressBarList:any=testInfo;
    Array.from(e.target.files!).map((file) => {
      let fileType: string = file.name.split(".").pop()!;
      if (file.size > 2097152) {
        openAlert({
          variant: "info",
          text: "O Tamanho do ficheiro é superior a 2MB. Por favor introduza um ficheiro dentro do limite de tamanho. ",
          title: "Atenção",
          autoHideDuration: 3000,
        });
      } else if (
        !".jpg, .jpeg, .png, .doc, .pdf, .gif, .zip, .rar, .txt, .xls, .docx, .xlsx".includes(
          fileType
        )
      ) {
        openAlert({
          variant: "info",
          text: "O tipo de ficheiro não é permitido. Por favor introduza um ficheiro com um tipo de ficheiro permitido. ",
          title: "Atenção",
          autoHideDuration: 3000,
        });
      } else {
        const progressBarProps: ProgressBarProps = {
          progress: 0,
          title: file.name,
          label: "",
          deleteIcon: true,
          deleteTooltipText: "Apagar",
        };
        if (file.size <= 2097152) {
          progressBarList.push(progressBarProps);
        }
      }
      return progressBarList;
    });
    setTestInfo(progressBarList);
  },[testInfo]);


  //viatura de substituição
  const initSimulationDetailsDropdowns = useCallback(async (insuranceOption: InsuranceOptions, commercialCondition: string) => {
    await vehiclesService.getReplacementVehicleGroups().then((data) => {
      if (data) {
        let channelOptions: Option[] = [];
        data.map((item: { code: string; description: string }) => {
          return channelOptions.push({
              label: item.code + ' - ' + item.description,
              value: item.code,
          });
        })
        insuranceOption.vehicleTypeOptions = channelOptions;
      }
    });

    const data = await vehiclesService.getVehiclePriceGroup();
    // Overhaul
    insuranceOption.vsOverhaulVehicleOptions = data;
    // Theft
    insuranceOption.vsTheftVehicleOptions = data;
    // Accident
    insuranceOption.vsAccidentVehicleOptions = data;
    // Assistance
    insuranceOption.vsAssistanceVehicleOptions = data;

    await vehiclesService.getReplacTransType().then((response) => {
      response.map((item: { replacementVehicle: string; description: string; concept: string }) => {
        // Overhaul
        if (item.concept === "Revisão")
          insuranceOption.vsOverhaulVehicleTypeOptions.push({
            label: item.description,
            value: item.replacementVehicle,
          });
        // Theft
        else if (item.concept === "Roubo")
          insuranceOption.vsTheftVehicleTypeOptions.push({
            label: item.description,
            value: item.replacementVehicle,
          });
        // Accident
        else if (item.concept === "Sinistro")
          insuranceOption.vsAccidentVehicleTypeOptions.push({
            label: item.description,
            value: item.replacementVehicle,
          });
        // Assistance
        else if (item.concept === "Avaria")
          insuranceOption.vsAssistanceVehicleTypeOptions.push({
            label: item.description,
            value: item.replacementVehicle,
          });
        return insuranceOption;
      });
    });

    // Fuel
    await vehiclesService.getFuelPrice(commercialCondition).then((data) => {
      if( data.succeeded ){
        data.value.map((item: { code: string; description: string }) => {
          return insuranceOption.fuelOptions.push({
            label: item.description,
            value: item.code,
          });
        });
      }
    })
    // Via Verde
    const viaVerdeResponse = await vehiclesService.getViaVerde(commercialCondition);
    if ( viaVerdeResponse.succeeded ){
      viaVerdeResponse.value.map((item: { code: string; description: string }) => {
        return insuranceOption.viaVerdeOptions.push({
          label: item.description,
          value: item.code,
        });
      });
    }
    return insuranceOption
  },[])

  const initVehicleReplacementOptionsDropDown = useCallback(async (insuranceFrameworkContractOptions: InsuranceFrameworkContract,insuranceOption: InsuranceOptions,defaultValue:boolean):Promise<InsuranceOptions> => {
    if(insuranceFrameworkContractOptions.insurancePackageOptions && insuranceFrameworkContractOptions.insurancePackageOptions.length>0){
      //VALIDAR A SELECÇÃO INICAL DE UMA DE VÁRIAS OPÇOES DE SEGURO
      if(defaultValue){
        if (insuranceOption.insurance && insuranceOption.insurance !== '') {
          if(insuranceFrameworkContractOptions.insurancePackageOptions.find(x => x.insurance === insuranceOption.insurance)) {
            insuranceOption.insurance = insuranceFrameworkContractOptions.insurancePackageOptions.find(x => x.insurance === insuranceOption.insurance)?.insurancePackageOption.value ?? '';
          }
          else {
            insuranceOption.insurance = insuranceFrameworkContractOptions.insurancePackageOptions[0].insurancePackageOption.value;
          }
        } else {
          insuranceOption.insurance = insuranceOption.insurancePackageOption.value
          // insuranceOption.insurance = insuranceFrameworkContractOptions.insurancePackageOptions[0].insurancePackageOption.value;
        }
        insuranceOption.secureOptions = insuranceOption.insurance === '' ? false : true;
      }
    }
    return insuranceOption;
  }, [  ]);

  const initSelectedInsuranceDropDown= useCallback(async(insuranceOption: InsuranceOptions,use:string,defaultValue:boolean):Promise<InsuranceOptions> =>  {
    // simulation.vehicleDamageOptions=[];
    // simulation.roadAssistanceOptions=[];
    // simulation.occupiersOptions=[];
    // simulation.liabilityOptions=[];
    // simulation.isolatedGlassBreakOptions=[];

    if (insuranceOption.insurance) {
      await vehiclesService.getInsurancePackageLine(insuranceOption.insurance,use).then((response) => {
        response.map((item: { insurance: string;description:string;type:string; default:boolean }) => {
          //Liability
          if(item.type === "Liability") {
            insuranceOption.liabilityOptions.push({
              label: item.description,
              value: item.insurance,
            });
            if (item.default && defaultValue) {
              insuranceOption.responsibility = item.insurance
            }
          }

          //Own Damage
          if(item.type === "Own Damage") {
            insuranceOption.vehicleDamageOptions.push({
              label: item.description,
              value: item.insurance,
            });
            if (item.default && defaultValue) {
              insuranceOption.ownDamage = item.insurance
            }
          }

          //Other Passenger
          if(item.type === "Other Passenger") {
            insuranceOption.occupiersOptions.push({
              label: item.description,
              value: item.insurance,
            });
            if (item.default && defaultValue) {
              insuranceOption.occupants = item.insurance
            }
          }

          //Legal Defense
          if(item.type === "Legal Defense") {
            insuranceOption.roadAssistanceOptions.push({
              label: item.description,
              value: item.insurance,
            });
            if (item.default && defaultValue) {
              insuranceOption.travelAssistance = item.insurance
            }
          }
            //Fire
          if(item.type === "Fire ") {
            insuranceOption.isolatedGlassBreakOptions.push({
              label: item.description,
              value: item.insurance,
            });
            if (item.default && defaultValue) {
              insuranceOption.glassBreakage = item.insurance
            }
          }
          return insuranceOption;
        });
      });
    }
    return insuranceOption;
  }, [  ]);

  const initDropDownToScenario = useCallback(() => {
  // async function initDropDownToScenario() {
    let typeofRental="";
    let commercialConditions="";
    let typeofIncome="";


    if(contractSettingsCheckedVehicles.size > 0 && vehicleDataTable.length > 0){
      vehicleDataTable.forEach((firstVehicle) => {
        if(contractSettingsCheckedVehicles.has(firstVehicle.id.toString())) {
          typeofRental = firstVehicle?.typeofRental ? firstVehicle?.typeofRental : "";
          commercialConditions = firstVehicle?.commercialConditions ? firstVehicle?.commercialConditions : "";
          typeofIncome = firstVehicle?.typeofIncome ? firstVehicle?.typeofIncome : "";
          // use = firstVehicle?.use ? firstVehicle?.use : "";
        }
      });
    }
    else {
      typeofRental = currentUser?.typeofRental ? currentUser?.typeofRental : "";
      commercialConditions = currentUser?.commercialConditions ? currentUser?.commercialConditions : "";
      typeofIncome = currentUser?.typeofIncome ? currentUser.typeofIncome : "";
      // use = firstVehicle?.use ? firstVehicle?.use : "";
    }

    if(vehicleDataTable.filter(x => contractSettingsCheckedVehicles.has(x.id.toString())).some(x => x.commercialConditions !== commercialConditions)) {
      openAlert({
        variant: "info",
        text: "Está a selecionar viaturas com condições comerciais diferentes. Poderá não ser possível aplicar as configurações a todas as viaturas seleccionadas.",
        title: "Atenção",
        autoHideDuration: 3000,
      });
    }

    let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
    let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.commercialConditions === commercialConditions)
    setCommercialConditionsScenario(commercialConditions);
    let vehicleTemp: IVehicle = {
      id: 0,
      proposalId: proposal.id,
      leaseQuoteNo:"",
      makeId: "",
      versionId: "",
      modelId: "",
      licensePlate: "",
      priceexclVAT: 0,
      consumersPrice: 0,
      pvp: 0,
      recycling_charge: 0,
      luxury_tax: 0,
      transport_x0026_immatriculation_cost: 0,
      status: "",
      use: "",
      numberofDoors: 0,
      fuelId: "",
      fuelType: "",
      euroTaxInternationalSegmt: "",
      quantity_of_Seats: 0,
      lengthmm: 0,
      widthmm: 0,
      heightmm: 0,
      trunkVolumel: 0,
      wheelBasemm: 0,
      trainFrontWheelsmm: 0,
      trainRearWheelsmm: 0,
      compressionratio: 0,
      bore: 0,
      powerHP: 0,
      powerkW: 0,
      cO2DischargeDecF: 0,
      engineCc: 0,
      torqueNm: 0,
      fuelTankCapacityl: 0,

      maxspeedkmh: 0,
      acceleration0100kmh: 0,
      mileage: 0,
      modelYear: "",
      recordDate: "",
      accessories: [],
      extraStandard: [],
      simulations: [],
      no: "",
      lotId: "",
      totalExtraWithoutTax: 0,
      totalAccessoriesWithout: 0,
      proformaInvoicePriceWithoutTax: 0,
      totalExtraWithTax: 0,
      totalAccessoriesWith: 0,
      proformaInvoicePriceWithTax: 0,
      discountWithTaxTotal: 0,
      rappelWithTaxTotal: 0,
      campaignWithTaxTotal: 0,
      businessValueWithoutTax: 0,
      businessValueWithTax: 0,
      discountWithTax: 0,
      discountWithoutTax: 0,
      discountWithTaxPercentage: 0,
      campaignWithTax: 0,
      campaignWithoutTax: 0,
      campaignWithTaxPercentage: 0,
      rappelWithTax: 0,
      rappelWithTaxPercentage: 0,
      rappelWithoutTax: 0,
      extraRappel: 0,
      extraOptionsSearch: [],
      extraOptionSelect: 0,
      versionDescription: "",
      caracteristics: "",
      quantity: 1,
      typeofRental: typeofRental,
      commercialConditions: commercialConditions,
      typeofIncome: typeofIncome,
      oilChange: 0,
      bPM: 0,
      luxury_tax_vat: 0,
      vat: 0,
      technMaxQuantityKm: 0,
      driverId: "", //FIXME susbtituir por nome do contato do user quando este estiver definido no user
      renew: false,
      hidden: false,
      commercialOptions: [],
      modelDescription: "",
      iuc:0,
      number_of_wheels:0,
      vehicleStatus: {
        color: "warning",
        label: "evaluating",
      },
      driverName: "",
      group_Price_Map:"",
    };

    vehicleTemp.simulations.push({
      id: 0,
      vehicleId: 0,
      no: "",
      deadline: 0,
      miles: 0,
      tires: false,
      mostUsed:0,
      tiresQuantity: "",
      maintenance: false,
      maintenanceDescription: "",
      iuc: 0,
      fuelCard: false,
      petrolPump: false,
      viaVerde: false,
      entityViaVerde: "",
      fuelDescriptiom: "",
      runFlaat: false,

      // opções de seguro
      secureOptions: false,
      insurance: '',
      insurancePackageOptions: insuranceFrameworkContractOption ? insuranceFrameworkContractOption.insurancePackageOptions.map(x => x.insurancePackageOption) : [],
      vehicleReplacementOptions: false,

      vehicleDamageOptions: [],
      occupiersOptions: [],
      roadAssistanceOptions: [],
      liabilityOptions: [],
      isolatedGlassBreakOptions: [],

      // pre carregamento das opções?
      // é necessário preé carregar?
      // se sim é preciso utilizar uma opção de seguro que ainda não foi pre seleccionada

      // secureOptions: insurancePackageOption ? insurancePackageOption?.secureOptions : false,
      // vehicleDamageOptions = insurancePackageOption ? insurancePackageOption?.vehicleDamageOptions : [];
      // occupiersOptions = insurancePackageOption ? insurancePackageOption?.occupiersOptions : [];
      // roadAssistanceOptions = insurancePackageOption ? insurancePackageOption?.roadAssistanceOptions : [];
      // liabilityOptions = insurancePackageOption ? insurancePackageOption?.liabilityOptions : [];
      // isolatedGlassBreakOptions = insurancePackageOption ? insurancePackageOption?.isolatedGlassBreakOptions: [];

      ownDamage: "",
      occupants: "",
      travelAssistance: "",
      responsibility: "",
      glassBreakage: "",

      //opções de veiculo de substituição
      vsAccidentVehicleType: "",
      vsAssistanceVehicleType: "",
      vsOverhaulVehicleType: "",
      vsTheftVehicleType: "",
      vehicleType: "",

      vsAssistanceVehicle: "",
      vsAccidentVehicle: "",
      vsOverhaulVehicle: "",
      vsTheftVehicle: "",

      vsAccidentVehicleTypeOptions: [],
      vsAssistanceVehicleTypeOptions: [],
      vsOverhaulVehicleTypeOptions: [],
      vsTheftVehicleTypeOptions: [],
      vehicleTypeOptions: [],

      vsAccidentVehicleOptions: [],
      vsAssistanceVehicleOptions: [],
      vsOverhaulVehicleOptions: [],
      vsTheftVehicleOptions: [],

      fuelOptions: [],
      viaVerdeOptions: [],

      // é necessário preé carregar?
      // se sim é preciso utilizar uma opção de seguro que ainda não foi pre seleccionada
      //  simulation.vsAccidentVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsAccidentVehicleTypeOptions : [];
      //  simulation.vsAssistanceVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsAssistanceVehicleTypeOptions : [];
      //  simulation.vsOverhaulVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsOverhaulVehicleTypeOptions : [];
      //  simulation.vsTheftVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsTheftVehicleTypeOptions : [];
      //  simulation.vehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vehicleTypeOptions : [];

      //  simulation.vsAccidentVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsAccidentVehicleOptions : [];
      //  simulation.vsAssistanceVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsAssistanceVehicleOptions : [];
      //  simulation.vsOverhaulVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsOverhaulVehicleOptions : [];
      //  simulation.vsTheftVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsTheftVehicleOptions : [];

      //  simulation.fuelOptions = insurancePackageOption ? insurancePackageOption?.fuelOptions : [];
      //  simulation.viaVerdeOptions = insurancePackageOption ? insurancePackageOption?.viaVerdeOptions : [];

      //campos das cotações
      priceexclVAT: vehicleTemp.priceexclVAT,
      consumersPrice: vehicleTemp.consumersPrice,
      totalExtraWithoutTax: vehicleTemp.totalExtraWithoutTax,
      totalExtraWithTax: vehicleTemp.totalExtraWithTax,
      totalAccessoriesWithout: vehicleTemp.totalAccessoriesWithout,
      totalAccessoriesWith: vehicleTemp.totalAccessoriesWith,
      discountWithoutTax: vehicleTemp.discountWithoutTax,
      discountWithTax: vehicleTemp.discountWithTax,
      discountWithTaxPercentage: vehicleTemp.discountWithTaxPercentage,
      discountWithTaxTotal: vehicleTemp.discountWithTaxTotal,
      proformaInvoicePriceWithoutTax: vehicleTemp.proformaInvoicePriceWithoutTax,
      proformaInvoicePriceWithTax: vehicleTemp.proformaInvoicePriceWithTax,
      campaignWithoutTax: vehicleTemp.campaignWithoutTax,
      campaignWithTax: vehicleTemp.campaignWithTax,
      campaignWithTaxPercentage: vehicleTemp.campaignWithTaxPercentage,
      campaignWithTaxTotal: vehicleTemp.campaignWithTaxTotal,
      rappelWithoutTax: vehicleTemp.rappelWithTaxTotal,
      rappelWithTax: vehicleTemp.rappelWithTax,
      rappelWithTaxPercentage: vehicleTemp.rappelWithTaxPercentage,
      rappelWithTaxTotal: vehicleTemp.rappelWithTaxTotal,
      extraRappel: vehicleTemp.extraRappel,
      businessValueWithoutTax: vehicleTemp.businessValueWithoutTax,
      businessValueWithTax: vehicleTemp.businessValueWithTax,
      //tipo de contrato
      quantity: vehicleTemp.quantity,
      // quotation calculated fields
      baseIncomeWithoutTax: 0,
      incomeWithoutTax: 0,
      incomeWithTax: 0,
      increaseVR: 0,
      margin: 0,
      realVR: 0,
      vrEurotax: 0,
      vrTable: 0,
      baseVRTable:0,

      amortization: 0,
      interests: 0,
      //validar se estes 4 campos estão duplicados
      tyresCost: 0,
      maintenanceCost: 0,
      iucCost:vehicleTemp.iuc,
      insuranceCost: 0,
      vsCost: 0,

      finalTax: 0,
      estimatedMargin: 0,
      monthlyTCOWithTax: 0,
      monthlyTCOWithoutTax: 0,
      additionalKmsWithTax: 0,
      additionalKmsWithoutTax: 0,
      collateral: 0,
      fee: 0,
      initialEntry: 0,
      travelledKmsWithTax: 0,
      travelledKmsWithoutTax: 0,
      proposeToCustomer: false,
      title: "",
      visibility: 0,
      complementaryProducts: [],
      maximum_Kilometers:0,
      contractExpenses: 0,
      commissionValue: 0,
      commissionCode: ""
    });
    // await initVehicleReplacementOptionsDropDown(vehicleTemp.simulations[vehicleTemp.simulations.length-1],commercialConditions,true);
    setSimulationScenario(vehicleTemp.simulations[0]);
  // }
  },[
    contractSettingsCheckedVehicles,
    currentUser?.commercialConditions, currentUser.typeofIncome, currentUser?.typeofRental,
    proposal.id, vehicleDataTable,
    insuranceFrameworkContractSet
  ])

  function updateValuesVehicle(vehicle: IVehicle,fieldName:string): IVehicle {
    if(fieldName === "consumersPrice"){
       vehicle.priceexclVAT = convertNumber((
        convertNumber(vehicle.consumersPrice) / (1 + vehicle.vat / 100)).toFixed(2)
      );
    }
    else {
        vehicle.consumersPrice = convertNumber((
          convertNumber(vehicle.priceexclVAT) * (1 + vehicle.vat / 100)
        ).toFixed(2)
      );
    }
    vehicle.discountWithTax = convertNumber((
        convertNumber(vehicle.discountWithoutTax) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.discountWithTaxTotal =
      // regra para envio de valor
      convertNumber((
        convertNumber(vehicle.discountWithoutTax) +
        ((convertNumber(vehicle.priceexclVAT) + convertNumber(vehicle.totalExtraWithoutTax) + convertNumber(vehicle.totalAccessoriesWithout)) * (Number(vehicle.discountWithTaxPercentage) / 100))
      ).toFixed(2)
      );

      // regra para envio de percentagem
      // convertNumber(
      //   ((convertNumber(vehicle.discountWithoutTax) / ( Number(vehicle.priceexclVAT) + vehicle.totalExtraWithoutTax ) )*100
      // ).toFixed(2));

    vehicle.campaignWithTax = convertNumber((
        Number(vehicle.campaignWithoutTax) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.campaignWithTaxTotal =convertNumber((
        convertNumber(vehicle.campaignWithoutTax) +
        convertNumber(vehicle.priceexclVAT) * (Number(vehicle.campaignWithTaxPercentage) / 100)
      ).toFixed(2)
    );
    vehicle.rappelWithTax =convertNumber((
        Number(vehicle.rappelWithoutTax) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    let rappelTotal =convertNumber((
        convertNumber(vehicle.rappelWithoutTax) +
        convertNumber(vehicle.priceexclVAT) * (Number(vehicle.rappelWithTaxPercentage) / 100)
      ).toFixed(2)
    );
    //atualiza rappel
    vehicle.rappelWithTaxTotal = rappelTotal;

    vehicle.totalExtraWithoutTax =convertNumber((
        Number(vehicle.totalExtraWithTax) / (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.totalAccessoriesWithout =convertNumber((
        Number(vehicle.totalAccessoriesWith) / (1 + vehicle.vat / 100)
      ).toFixed(2)
    );

    vehicle.proformaInvoicePriceWithoutTax = convertNumber((
        // convertNumber(vehicle.pvp) +
        // convertNumber(vehicle.totalAccessoriesWithout) +
        // convertNumber(vehicle.totalExtraWithoutTax) -
        // convertNumber(vehicle.discountWithTaxTotal) -
        // convertNumber(vehicle.campaignWithTaxTotal)

        convertNumber(vehicle.priceexclVAT)
        + convertNumber(vehicle.luxury_tax)
        + convertNumber(vehicle.transport_x0026_immatriculation_cost)
        + convertNumber(vehicle.recycling_charge)

        + convertNumber(vehicle.totalAccessoriesWithout)
        + convertNumber(vehicle.totalExtraWithoutTax)

        - convertNumber(vehicle.discountWithTaxTotal) //envio valor
        // - convertNumber(vehicle.discountWithoutTax) //envio percentagem
        - convertNumber(vehicle.campaignWithTaxTotal)
      ).toFixed(2)
    );
    vehicle.proformaInvoicePriceWithTax =convertNumber((
        (
          convertNumber(vehicle.priceexclVAT)
          + convertNumber(vehicle.luxury_tax)
          + convertNumber(vehicle.transport_x0026_immatriculation_cost)
          + convertNumber(vehicle.recycling_charge)

          + convertNumber(vehicle.totalAccessoriesWithout)
          + convertNumber(vehicle.totalExtraWithoutTax)

          - convertNumber(vehicle.discountWithTaxTotal) //envio valor
          // - convertNumber(vehicle.discountWithoutTax) //envio percentagem
          - convertNumber(vehicle.campaignWithTaxTotal)
        ) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.pvp = convertNumber((
        convertNumber(vehicle.priceexclVAT) +
        convertNumber(vehicle.totalAccessoriesWithout) +
        convertNumber(vehicle.totalExtraWithoutTax) +
        convertNumber(vehicle.luxury_tax) +
        convertNumber(vehicle.transport_x0026_immatriculation_cost) +
        convertNumber(vehicle.recycling_charge)
      ).toFixed(2)
    );

    vehicle.businessValueWithoutTax =convertNumber((
        convertNumber(vehicle.priceexclVAT) +

        convertNumber(vehicle.totalAccessoriesWithout) +
        convertNumber(vehicle.totalExtraWithoutTax) +

        convertNumber(vehicle.luxury_tax) +
        convertNumber(vehicle.transport_x0026_immatriculation_cost) +
        convertNumber(vehicle.recycling_charge) -

        convertNumber(vehicle.rappelWithTaxTotal) -
        convertNumber(vehicle.extraRappel) -

        convertNumber(vehicle.discountWithTaxTotal) -  //envio valor
        // convertNumber(vehicle.discountWithoutTax) -  //envio percentagem
        convertNumber(vehicle.campaignWithTaxTotal)
      ).toFixed(2)
    );
    vehicle.businessValueWithTax =convertNumber((
        (
          convertNumber(vehicle.priceexclVAT) +

          convertNumber(vehicle.totalAccessoriesWithout) +
          convertNumber(vehicle.totalExtraWithoutTax) +

          convertNumber(vehicle.luxury_tax) +
          convertNumber(vehicle.transport_x0026_immatriculation_cost) +
          convertNumber(vehicle.recycling_charge) -

          convertNumber(vehicle.rappelWithTaxTotal) -
          convertNumber(vehicle.extraRappel) -

          convertNumber(vehicle.discountWithTaxTotal) - //envio valor
          // convertNumber(vehicle.discountWithoutTax) - //envio percentagem
          convertNumber(vehicle.campaignWithTaxTotal)
        ) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.totalExtraWithTax=convertNumber(vehicle.totalExtraWithTax.toFixed(2));
    vehicle.simulations?.map((simula:Simulation) => {
      simula.discountWithTax = vehicle.discountWithTax;
      simula.discountWithTaxTotal = vehicle.discountWithTaxTotal;
      simula.campaignWithTax = vehicle.campaignWithTax;
      simula.campaignWithTaxTotal = vehicle.campaignWithTaxTotal;
      simula.rappelWithoutTax = rappelTotal;
      simula.extraRappel = vehicle.extraRappel;
      simula.proformaInvoicePriceWithoutTax = vehicle.proformaInvoicePriceWithoutTax;
      simula.proformaInvoicePriceWithTax = vehicle.proformaInvoicePriceWithTax;
      simula.businessValueWithoutTax = vehicle.businessValueWithoutTax;
      simula.businessValueWithTax = vehicle.businessValueWithTax;
      simula.totalAccessoriesWith = vehicle.totalAccessoriesWith;
      simula.totalAccessoriesWithout = vehicle.totalAccessoriesWithout;
      simula.totalExtraWithoutTax = vehicle.totalExtraWithoutTax;
      simula.totalExtraWithTax = vehicle.totalExtraWithTax;
      simula.priceexclVAT = vehicle.priceexclVAT;
      simula.consumersPrice = vehicle.consumersPrice;

      return simula
    })


    return vehicle;
  }



   const getTypeOfRentalInProposal = useCallback((vehicles:IVehicle[]) => {
    let typeOfRental:string="";
     vehicles.forEach((row)=>{
        if(!typeOfRental.includes(row.typeofRental +", "+ row.commercialConditions)){
          typeOfRental =typeOfRental +" | "+ row.typeofRental +", "+ row.commercialConditions;

        }
      })


    if(typeOfRental !== "")
      typeOfRental = typeOfRental.substring(1).substring(1);
    return typeOfRental;
  },[]);

  const getTotalVehiclesProposal = useCallback((vehicles:IVehicle[]) => {
    let quantity:number=0;
     vehicles.forEach((row)=>{
          quantity =quantity +row.quantity;
      })
    return quantity;
  },[]);

  const getVersion = useCallback((make: string, model:string,indexVehicle:number) => {
    const index = versionsSearchOptions.findIndex((e) =>
      e.makeId === make && e.modelId === model ? make : ""
    );

    if(versionsSearchOptions[index]?.versions[0].value===""){
     let filters: { type: AdvancedFilterVersionsOptions; value: string }[] = [];
      filters.push({
        type: AdvancedFilterVersionsOptions.Make,
        value: make,
      });
       filters.push({
        type: AdvancedFilterVersionsOptions.Model,
        value:model,
      });

      const filtersBystate = VehicleTypeStatusHelper.generateAdvancedFilterVersionsOptions(true);
      if(filtersBystate.length > 0){
        filters.push(...filtersBystate);
      }

      vehiclesService.getVersionsToCombo(filters).then((data) => {
        if (!data.succeeded) {
          openAlert({
            variant: "error",
            text: "Ocorreu um erro carregar as descrições das viaturas! Por favor tente novamente. Se o problema persistir, contate o administrador.",
            title: "Atenção",
          });
        } else {
          if (data.value.length > 0) {
            if (data.value !== undefined && data.value[0] !== undefined) {
              setVersionsSearchOptions((rows) =>
                rows.map((cur, index) => {
                  if (cur.makeId !== make || cur.modelId !== model)  {
                    return cur;
                  }

                  if (cur.versions.length === 1 && cur.versions[0].label==='') {
                    cur.versions = data.value;
                  } else {
                    data.value.forEach((item: { label: string; value: string; isInactive: boolean }) => {
                      if (cur.versions.find((ver) =>{ return ver.value === item.value; }) === (null || undefined)) {
                        cur.versions.push(item);
                      }
                    });
                  }
                  return cur;
                })
              );
              return data.value
            }
          } else {
            const currentMakeIndex = versionsSearchOptions.findIndex(
              (x) => x.makeId === make && x.modelId===model
            );
            let vehicle:IVehicle=vehicleDataTable[indexVehicle];
            const hasInactiveVersion = versionsSearchOptions[currentMakeIndex].versions.some(v => v.value === vehicle?.versionId);
            if(!hasInactiveVersion){
              if( versionsSearchOptions[currentMakeIndex].versions.filter(x => x.value === "").length===1){
                versionsSearchOptions[currentMakeIndex].versions=[{
                  label: `${vehicle?.modelDescription}; ${vehicle?.caracteristics}`,
                  value: vehicle.versionId,
                  isInactive: true
                }];
              }
              else{
                versionsSearchOptions[currentMakeIndex].versions.push({
                  label: `${vehicle?.modelDescription}; ${vehicle?.caracteristics}`,
                  value: vehicle.versionId,
                  isInactive: true
                });
              }
            }
            setVersionsSearchOptions([...versionsSearchOptions]);
            return  versionsSearchOptions[currentMakeIndex].versions;
          }
        }
      });
    }
    else {
      return versionsSearchOptions[index]?.versions;
    }
  },[versionsSearchOptions,vehicleDataTable]);

  const convertToExpandableMakesOptions = useCallback((items: any) => {
    let convertedItems = items.map((brand: any, index:number) => {
      let newItem = {
        label: brand.id,
        hidden: false,
        open: false,
        checkBoxProps: {
          checked: false,
        },
        models: brand.models.map(
          (model: { id: string; description: string }) => {
            return {
              id: model.id,
              label: model.description,
              checkBoxProps: {
                checked: false,
              },
            };
          }
        ),
      };
      return newItem;
    });
    return convertedItems;
  },[]);

  // const handleExtraStandardTextFieldChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  const handleExtraStandardTextFieldChange = useCallback((e: FormEvent<HTMLDivElement>) => {
    let target = e.target as HTMLInputElement;
    let splits = target.name.split("-");
    const name = splits[0];
    const nameVat= splits[1];
    const rowIndex =convertNumber(splits[2]);
    const indexExtr =convertNumber(splits[3]);

    setVehicleDataTable((rows) =>
      rows.map((row, index) => {
        if (index !== rowIndex) {
          return row;
        }
        let vehicle = row;
        let acce = vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr];

      let priceVat=convertNumber(validaStringFormatDecimal(target.value,acce['price_Excl_VAT']));
      if(isNaN(priceVat)){
          acce={...acce,[nameVat]: validaStringFormatDecimal(target.value,acce['price_Excl_VAT'])};
          acce={...acce,[nameVat]: validaStringFormatDecimal(target.value,acce['price'])};
          vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr].price_Excl_VAT =acce.price_Excl_VAT;
      } else {
        acce={...acce,[nameVat]: validaStringFormatDecimal(target.value,acce['price_Excl_VAT'])}

        // acce={...acce,[name]:Number(validaStringFormatDecimal(
        //   (Number(priceVat) * (1+row.vat/100)).toString(),
        //   acce['price']))}
        acce.price = Math.round(( Number(priceVat) * (1+row.vat/100)) * 100) / 100;// ( Number(priceVat) * (1+row.vat/100)).toFixed(2)

        vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr].price_Excl_VAT =acce.price_Excl_VAT;
        vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr].price =acce.price;
        vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr].valueTextFieldProps.value = acce.price_Excl_VAT;

        let totalextraStandard = 0;
        let totalextraStandardVat = 0;

        if (vehicle.extraStandard.length > 0) {
          vehicle.extraStandard.forEach((item) => {
            if (item.checkedExtra === true){
              totalextraStandard +=convertNumber(item.price_Excl_VAT);
            }
          });

          if (totalextraStandard !== 0)
            totalextraStandardVat =convertNumber(
              (totalextraStandard * (1 + vehicle.vat / 100)).toFixed(2)
            );
          } else {
            totalextraStandard = vehicle.totalExtraWithoutTax;
            totalextraStandardVat = vehicle.totalExtraWithTax;
          }
          vehicle.totalExtraWithoutTax = Number(totalextraStandard.toFixed(2));
          vehicle.totalExtraWithTax = Number(totalextraStandardVat.toFixed(2));
          updateValuesVehicle(vehicle,"");
        }
        return { ...row, vehicle };
      })
    );
  },[]);

  const initExtraStandardDetails = useCallback(async (vehicle: IVehicle): Promise<IVehicle> => {
    //Extra Standard
    let response = await vehiclesService.getOptionsType(vehicle.versionId,false);
    if (!response.succeeded) {
      openAlert({
        variant: "error",
        text: `Não foi possível carregar os extras da viatura ${vehicle.versionDescription}! Por favor tente novamente. Se o problema persistir, contate o administrador.`,
        title: "Atenção",
      });
    } else {
      let extrasStandardTemp = vehicle.extraStandard;
      if (extrasStandardTemp.length > 0){
        vehiclesService.deleteExtrasStandard(extrasStandardTemp);
      }
      let extrasSearch: Option[];
      extrasSearch = [];
      vehicle.totalExtraWithoutTax =0;
      vehicle.totalExtraWithTax = 0;

      let index = vehicleDataTable.findIndex(x => x.id === vehicle.id)
      if(index === -1){
        index = 0;
      }
      let disable = vehicle.licensePlate ? true :false;
      response.value.forEach((element:any,indexExtr:number) => {
        //lista de pesquisa de extras
        extrasSearch.push({
          label: `${element.description} - ${element.manufactor_Code} - ${element.optionTypeId}`,
          value: element.optionTypeId,
        });
        vehicle.extraStandard.forEach((frequentExtra) => {
          if (frequentExtra.optionTypeId === element.optionTypeId) {
            element.checkedExtra = frequentExtra.checkedExtra
            element.visible = true
            if(frequentExtra.checkedExtra === true){
              vehicle.totalExtraWithTax = vehicle.totalExtraWithTax + element.price
              vehicle.totalExtraWithoutTax = vehicle.totalExtraWithoutTax + element.price_Excl_VAT
            }
          }
        })
        //esturtura ExtraStandard do portal
        element.group = element.main_Group_Allocation ? element.main_Group_Allocation : ""
        element.groupLabel = element.descGroup ? element.descGroup :""
        element.code = element.optionTypeId
        element.label = element.description
        element.valueTextFieldProps = {
          disabled: disable,
          name: "price-price_Excl_VAT-" + index + "-" + indexExtr, //name: `price-${index}-${indexExtr}`,
          value: element.price_Excl_VAT,
          // onChange: handleExtraStandardTextFieldChange,
          onInput: handleExtraStandardTextFieldChange,
        }
        element.checkboxProps = {
          disabled: disable,
          checked: element.checkedExtra,
          name:element.optionTypeId
        }
        element.lazyload = true
        element.requiredCodes = [] // => obrigatórios
        element.incompatibilityCodes = [] // incompatibilidade de extras
        element.includes = [] // => packs de extras

        switch (element.inclusion_Type) {
          case 'Obligatory':
            element.requiredCodes = element.compatibleExtrasList
            break;
          case 'Inclusion':
            element.requiredCodes = element.compatibleExtrasList
            break;
          case 'Exclusion':
            // temp fix
            let incompatibleExtrasTmp:string[] = []
            element.incompatibleExtrasList.forEach((incompatible:any) => {
              incompatible.forEach((value:any) => {
                incompatibleExtrasTmp.push(value)
              });
            });
            element.incompatibilityCodes = incompatibleExtrasTmp
            // end temp fix
            // aux[aux.length-1].incompatibilityCodes = item.incompatibleExtrasList
            break;

          default:
            break;
        }
        // Package
        if(element.package === 'Yes') {
          let packageExtras:{
            label:string,
            code:string
          }[] = [];

          element.compatibleExtrasList.forEach((value:string) => {
            let compatibleExtra:IExtraStandard = response.value.find((x:IExtraStandard) => x.optionTypeId === value)
            packageExtras.push({
              label: compatibleExtra?.description ?? value ,
              code: compatibleExtra?.optionTypeId ?? value
            })
          })
          element.includes = packageExtras;
          element.requiredCodes = []
        } else {
          element.includes = []
        }
      });
      vehicle.extraStandard = response.value;
      vehicle.extraOptionsSearch = extrasSearch;
    }
    return vehicle;
  },[
    handleExtraStandardTextFieldChange,
    // vehicleDataTable // causa ciclo infinito
  ])

  const initAccessoriesDetails = useCallback(async (vehicle: IVehicle): Promise<IVehicle> => {
    //Adicionais
    let response = await vehiclesService.getOptionsType( vehicle.versionId, true);
    if (!response.succeeded) {
      openAlert({
        variant: "error",
        text: "Ocorreu um erro pesquisar adicionais de viaturas! Por favor tente novamente. Se o problema persistir, contate o administrador.",
        title: "Atenção",
      });
    } else {
      let accessoriesTemp = vehicle.accessories;
      if (accessoriesTemp.length > 0){
        vehiclesService.deleteAccessories(accessoriesTemp);
      }
      let accessories: IAccessory[];
      response.value.map((item: any) => {
        item.id = 0;
        item.vehicleId = 0;
        return item;
      });
      accessories = response.value;
      let totalAccessories = 0;
      let totalAccessoriesVat = 0;
      if (accessories.length > 0) {
        accessories.forEach((item) => {
          totalAccessories += item.price_Excl_VAT;
        });
      }
      if (totalAccessories > 0){
        totalAccessoriesVat = convertNumber(
          (totalAccessories * (1 + vehicle.vat / 100)).toFixed(2)
        );
      }
      vehicle.accessories = accessories;
      vehicle.totalAccessoriesWithout = totalAccessories;
      vehicle.totalAccessoriesWith = totalAccessoriesVat;
    }
    return vehicle;
  },[]);

  const initCampaignExtraStandardDetails = useCallback(async (vehicle: IVehicle): Promise<IVehicle> => {
    //Extra Standard
    let response = await vehiclesService.getCampaignOptionsType(vehicle.versionId, false);
    if (!response.succeeded) {
      openAlert({
        variant: "error",
        text: `Não foi possível carregar os extras da viatura ${vehicle.versionDescription}! Por favor tente novamente. Se o problema persistir, contate o administrador.`,
        title: "Atenção",
      });
    } else {
      let extrasStandardTemp = vehicle.extraStandard;
      if (extrasStandardTemp.length > 0) {
        vehiclesService.deleteExtrasStandard(extrasStandardTemp);
      }
      let extrasSearch: Option[];
      extrasSearch = [];
      vehicle.totalExtraWithoutTax =0;
      vehicle.totalExtraWithTax = 0;

      // let index = vehicleDataTable.findIndex(x => x.id === vehicle.id)
      let index = vehicleDataTable.length;
      let disable = vehicle.licensePlate ? true :false;

      response.value.forEach((element:any,indexExtr:number) => {
        extrasSearch.push({
          label: `${element.description} - ${element.manufactor_Code} - ${element.optionTypeId}`,
          value: element.optionTypeId,
        });
        vehicle.extraStandard.forEach(frequentExtra => {
          if (frequentExtra.optionTypeId === element.optionTypeId) {
            element.checkedExtra = frequentExtra.checkedExtra;
            element.visible = true;
            if(frequentExtra.checkedExtra === true) {
              vehicle.totalExtraWithTax = vehicle.totalExtraWithTax + element.price;
            }
          }
        })
        //esturtura ExtraStandard do portal
        element.group = element.main_Group_Allocation ? element.main_Group_Allocation : ""
        element.groupLabel = element.descGroup ? element.descGroup :""
        element.code = element.optionTypeId
        element.label = element.description
        element.valueTextFieldProps = {
          name: "price-price_Excl_VAT-" + index + "-" + indexExtr, //name: `price-${index}-${indexExtr}`,
          value: element.price_Excl_VAT,
          // onChange: handleExtraStandardTextFieldChange,
          onInput: handleExtraStandardTextFieldChange,
          disabled: propertyDisabled('Extras','Detalhes Viatura') || disable,
          hidden: propertyHidden('Extras','Detalhes Viatura'),
          warning: propertyValidation( element.price_Excl_VAT, 'Extras','Detalhes Viatura'),
        }
        element.checkboxProps = {
          disabled: propertyDisabled('Extras','Detalhes Viatura') || disable,
          hidden: propertyHidden('Extras','Detalhes Viatura'),
          checked: element.checkedExtra,
          name:element.optionTypeId
        }
        element.lazyload = true
        element.requiredCodes = [] // => obrigatórios
        element.incompatibilityCodes = [] // incompatibilidade de extras
        element.includes = [] // => packs de extras

        switch (element.inclusion_Type) {
          case 'Obligatory':
            element.requiredCodes = element.compatibleExtrasList
            break;
          case 'Inclusion':
            element.requiredCodes = element.compatibleExtrasList
            break;
          case 'Exclusion':
            // temp fix
            let incompatibleExtrasTmp:string[] = []
            element.incompatibleExtrasList.forEach((incompatible:any) => {
              incompatible.forEach((value:any) => {
                incompatibleExtrasTmp.push(value)
              });
            });
            element.incompatibilityCodes = incompatibleExtrasTmp
            // end temp fix
            // aux[aux.length-1].incompatibilityCodes = item.incompatibleExtrasList
            break;

          default:
            break;
        }
        // Package
        if(element.package === 'Yes') {
          let packageExtras:{
            label:string,
            code:string
          }[] = [];

          element.compatibleExtrasList.forEach((value:string) => {
            let compatibleExtra:IExtraStandard = response.value.find((x:IExtraStandard) => x.optionTypeId === value)
            packageExtras.push({
              label: compatibleExtra?.description ?? value ,
              code: compatibleExtra?.optionTypeId ?? value
            })
          })
          element.includes = packageExtras;
          element.requiredCodes = []
        } else {
          element.includes = []
        }
      });
      vehicle.extraStandard = response.value;
      vehicle.extraOptionsSearch = extrasSearch;
    }
    return vehicle;
  },[
    propertyDisabled, propertyHidden, propertyValidation,
    handleExtraStandardTextFieldChange,
    // vehicleDataTable // causa ciclo infinito
  ])

  const handleFrequentCheckboxPropsOnChange = useCallback(
    (mostFrequentSelectdIndex: number, mostFrequentId: number) =>
      (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        let selectedFrequentVehicleTmp = { checked, mostFrequentId };
        let selectedFrequentVehiclesTmp = selectedFrequentVehicles;
        if(selectedFrequentVehiclesTmp.length === 0){
          selectedFrequentVehiclesTmp.push(selectedFrequentVehicleTmp);
        }
        else{
          var foundIndex = selectedFrequentVehiclesTmp.findIndex(x => x.mostFrequentId === mostFrequentId);
          if (foundIndex !== -1) {
            selectedFrequentVehiclesTmp[foundIndex].checked = checked;
          }
          else{
            selectedFrequentVehiclesTmp.push(selectedFrequentVehicleTmp);
          }
        }
        setSelectedFrequentVehicles([...selectedFrequentVehiclesTmp]);
      },
    [selectedFrequentVehicles]
  );

  const handleMostFrequent = useCallback((mostFrequentResults: IMostFrequent[]) => {
    let configurations:{
      vehicleID:string,
      vehicleSummary: string,
      checkboxProps: CheckboxProps,
      rows: {
        pvp: ILabelValue;
        extras: ILabelValue;
        discount: ILabelValue;
        proformaPrice: ILabelValue;
        businessPrice: ILabelValue;
      }[];
      seeMoreConditionsLinkText: string;
      hideConditionsLinkText: string;
    }[] = [];

    let configuration: {
      vehicleID:string,
      vehicleSummary: string;
      checkboxProps: CheckboxProps;
      rows: {
        pvp: ILabelValue;
        extras: ILabelValue;
        discount: ILabelValue;
        proformaPrice: ILabelValue;
        businessPrice: ILabelValue;
      }[];
      seeMoreConditionsLinkText: string;
      hideConditionsLinkText: string;
    } = {
      vehicleID:"",
      vehicleSummary: "",
      checkboxProps: {},
      rows: [],
      seeMoreConditionsLinkText: "",
      hideConditionsLinkText: "",
    };

    let mostfrequentTmp: IMostFrequent[] = mostFrequentResults;
    mostfrequentTmp.forEach((cur, index) => {
      configuration = {
        vehicleSummary: cur.versionDescription ? cur.versionDescription : "",
        checkboxProps: {
          onChange: handleFrequentCheckboxPropsOnChange(index, cur.id),
          // onChange: (e,checked) => handleFrequentCheckboxPropsOnChange(index, cur.id, checked, selectedFrequentVehicles,e),
        },
        seeMoreConditionsLinkText: "Mostrar mais condições ",
        hideConditionsLinkText: "Ocultar condições ",
        // falta o campo
        vehicleID: cur.title,
        rows: [
          {
            pvp: {
              label: "PVP",
              value: `${cur.consumersPrice} €`, // '33.436 €',
            },
            extras: {
              label: "Extras",
              value: `${cur.totalExtraWithTax} €`, //'3.436 €',
            },
            discount: {
              label: "Desconto",
              value: `${cur.discountWithTaxTotal} €`, //'10%',
            },
            proformaPrice: {
              label: "Preço proforma	",
              value: `${cur.proformaInvoicePriceWithTax} €`, //'33.436 €',
            },
            businessPrice: {
              label: "Preço de negócio	",
              value: `${cur.businessValueWithoutTax} €`, //'33.436 €',
            },
          },
        ],
      };
      configurations.push(configuration);
    });
    setMostFrequentConfigurations((rows) => (rows = configurations));
    return configurations;
  },[handleFrequentCheckboxPropsOnChange])

  const handleDrivers = useCallback(
    (value: string) => {
      vehiclesService.getDrivers(value).then((data) => {
        if (data.succeeded) {
          setDrivers(data.value);
        }
      });
    },
    []
  );

  //#endregion



  //#region USECALLBACK
  // const handleAttachment  =useCallback((index: number) => {
  //   let atts=attachments;
  //   atts.filter((item, attachmentIndex) => attachmentIndex !== index);
  //   setAttachments(atts);
  // },[attachments]);
  const handleAttachment =useCallback((index: number) => {
    setAttachments((rows)=>{
      let atts=rows.filter((item, attachmentIndex) => attachmentIndex !== index);
      return atts;
    });
  },[]);

  const handleAttachmentProposal =useCallback( (index: number) => {
      setAttachmentsProposal((rows)=>{
      return rows.filter((item, attachmentIndex) => attachmentIndex !== index);
    });
  },[setAttachmentsProposal]);

  const getScenariosFrequentSimulations = useCallback(() => {
    vehiclesService.getFrequentHeaderSimulationsTopFive(currentUser.userId).then((data) => {
      setScenariosFrequentSimulation(data.value as IFrequentHeaderSimulation[]);
    });
  }, [
    // unnecessary dependencies
    // currentUser,
    currentUser.userId
    // scenariosFrequentSimulation,
  ]);

  const scenariosFrequent = useMemo(() =>
    scenariosFrequentSimulation.map((item:IFrequentHeaderSimulation, index: number) => {
      return {
        title: item.title,
        value: String(item.id),
        checked: contractSettingsCardCheckedDemo.includes(String(item.id)),
        months:  item.deadline+' mês',
        options: [`${[convertNumber(item.miles).toFixed(0)]} km/ano`,`${[item.tire.replace('no','Não').replace('yes','Sim')]} pneus` ],
        clientInfo: "",
        onChange: async (value: string, checked: any) => {
          if (!checked) {
            setContractSettingsCardCheckedDemo((prev) => prev.filter((c) => c !== value));
          } else {
            if (contractSettingsCheckedVehicles.size === 0) {
              openAlert({
                variant: "error",
                text: "Por favor selecione uma viatura para aplicar a configuração, clicando numa das checkboxes à esquerda de uma das descrições das viaturas propostas.",
                title: "Aviso",
              });
            } else {
              setChangedProposal(true);
              setContractSettingsCardCheckedDemo((prev) => [...prev, value]);
              let frequentHeaderSimulation: IFrequentHeaderSimulation = scenariosFrequentSimulation.find((x) => {
                return x.id === item.id;
              });
              frequentHeaderSimulation.mostUsed = frequentHeaderSimulation.mostUsed+1;
              let tempSimulationsList: Simulation [] = JSON.parse(JSON.stringify(frequentHeaderSimulation.frequentSimulations));


              // ALTERNATIVA AO USO DE PROMISES ENCADEADAS
              // for (const vehicle of vehicleDataTable) {
              //   if (contractSettingsCheckedVehicles.size > 0) {
              //     for (const selectedVehicleId of Array.from(contractSettingsCheckedVehicles)) {
              //       if (selectedVehicleId === vehicle.id.toString()) {
              //         if (vehicle.simulations.some(sim => sim.deadline === 0 || sim.miles === 0)) {
              //           for (const frequentSimulationTmp of tempSimulationsList) {
              //             // Your existing code for frequentSimulation here
              //             let simulationIndex = vehicle.simulations.findIndex(sim => sim.deadline === 0 || sim.miles === 0);
              //             if(simulationIndex === -1) {
              //               simulationIndex = vehicle.simulations.length;
              //             }
              //             let frequentSimulation = JSON.parse(JSON.stringify(frequentSimulationTmp));
              //             frequentSimulation.vehicleId = vehicle.id;
              //             frequentSimulation.id =vehicle.simulations[simulationIndex] === undefined ? 0: vehicle.simulations[simulationIndex].id;
              //             //Assume os valores da viatura atual em vez dos valores
              //             //associados ao modelo guardado no frequente
              //             frequentSimulation.priceexclVAT = vehicle.priceexclVAT;
              //             frequentSimulation.consumersPrice = vehicle.consumersPrice;
              //             frequentSimulation.totalExtraWithoutTax = vehicle.totalExtraWithoutTax;
              //             frequentSimulation.totalExtraWithTax = vehicle.totalExtraWithTax;
              //             frequentSimulation.totalAccessoriesWithout = vehicle.totalAccessoriesWithout;
              //             frequentSimulation.totalAccessoriesWith = vehicle.totalAccessoriesWith;
              //             frequentSimulation.discountWithoutTax = vehicle.discountWithoutTax;
              //             frequentSimulation.discountWithTax = vehicle.discountWithTax;
              //             frequentSimulation.discountWithTaxPercentage = vehicle.discountWithTaxPercentage;
              //             frequentSimulation.discountWithTaxTotal = vehicle.discountWithTaxTotal;
              //             frequentSimulation.proformaInvoicePriceWithoutTax = vehicle.proformaInvoicePriceWithoutTax;
              //             frequentSimulation.proformaInvoicePriceWithTax = vehicle.proformaInvoicePriceWithTax;
              //             frequentSimulation.campaignWithoutTax = vehicle.campaignWithoutTax;
              //             frequentSimulation.campaignWithTax = vehicle.campaignWithTax;
              //             frequentSimulation.campaignWithTaxPercentage = vehicle.campaignWithTaxPercentage;
              //             frequentSimulation.campaignWithTaxTotal = vehicle.campaignWithTaxTotal;
              //             frequentSimulation.rappelWithoutTax = vehicle.rappelWithoutTax;
              //             frequentSimulation.rappelWithTax = vehicle.rappelWithTax;
              //             frequentSimulation.rappelWithTaxPercentage = vehicle.rappelWithTaxPercentage;
              //             frequentSimulation.rappelWithTaxTotal = vehicle.rappelWithTaxTotal;
              //             frequentSimulation.extraRappel = vehicle.extraRappel;
              //             frequentSimulation.businessValueWithoutTax = vehicle.businessValueWithoutTax;
              //             frequentSimulation.businessValueWithTax = vehicle.businessValueWithTax;
              //             frequentSimulation.quantity = vehicle.quantity;
              //             //fim validações
              //             vehicle.simulations[simulationIndex] = frequentSimulation;
              //             let defaultValue=false;
              //             //Se as condições comerciais não forem iguais ou o tipo do veiculo
              //             if(
              //               (
              //                 vehicle.commercialConditions !== frequentHeaderSimulation.commercialConditions ||
              //                 vehicle.use !== frequentHeaderSimulation.use
              //               ) && frequentSimulation.secureOptions === true){
              //               defaultValue = true;
              //             }
              //             await initVehicleReplacementOptionsDropDown(vehicle.simulations[simulationIndex], vehicle.commercialConditions, defaultValue);
              //             await initSelectedInsuranceDropDown(vehicle.simulations[simulationIndex], vehicle.use, defaultValue);
              //           }
              //         }
              //         else {
              //           for (const frequentSimulation of tempSimulationsList) {
              //             frequentSimulation.vehicleId = vehicle.id;
              //             frequentSimulation.id = 0;
              //             //Assume os valores da viatura atual em vez dos valores
              //             //associados ao modelo guardado no frequente
              //             frequentSimulation.priceexclVAT = vehicle.priceexclVAT;
              //             frequentSimulation.consumersPrice = vehicle.consumersPrice;
              //             frequentSimulation.totalExtraWithoutTax = vehicle.totalExtraWithoutTax;
              //             frequentSimulation.totalExtraWithTax = vehicle.totalExtraWithTax;
              //             frequentSimulation.totalAccessoriesWithout = vehicle.totalAccessoriesWithout;
              //             frequentSimulation.totalAccessoriesWith = vehicle.totalAccessoriesWith;
              //             frequentSimulation.discountWithoutTax = vehicle.discountWithoutTax;
              //             frequentSimulation.discountWithTax = vehicle.discountWithTax;
              //             frequentSimulation.discountWithTaxPercentage = vehicle.discountWithTaxPercentage;
              //             frequentSimulation.discountWithTaxTotal = vehicle.discountWithTaxTotal;
              //             frequentSimulation.proformaInvoicePriceWithoutTax = vehicle.proformaInvoicePriceWithoutTax;
              //             frequentSimulation.proformaInvoicePriceWithTax = vehicle.proformaInvoicePriceWithTax;
              //             frequentSimulation.campaignWithoutTax = vehicle.campaignWithoutTax;
              //             frequentSimulation.campaignWithTax = vehicle.campaignWithTax;
              //             frequentSimulation.campaignWithTaxPercentage = vehicle.campaignWithTaxPercentage;
              //             frequentSimulation.campaignWithTaxTotal = vehicle.campaignWithTaxTotal;
              //             frequentSimulation.rappelWithoutTax = vehicle.rappelWithoutTax;
              //             frequentSimulation.rappelWithTax = vehicle.rappelWithTax;
              //             frequentSimulation.rappelWithTaxPercentage = vehicle.rappelWithTaxPercentage;
              //             frequentSimulation.rappelWithTaxTotal = vehicle.rappelWithTaxTotal;
              //             frequentSimulation.extraRappel = vehicle.extraRappel;
              //             frequentSimulation.businessValueWithoutTax = vehicle.businessValueWithoutTax;
              //             frequentSimulation.businessValueWithTax = vehicle.businessValueWithTax;
              //             frequentSimulation.quantity = vehicle.quantity;
              //             //fim validações
              //             vehicle.simulations.push(frequentSimulation);
              //             let defaultValue=false;
              //             //Se as condições comerciais não forem iguais ou o tipo do veiculo
              //             if(((vehicle.commercialConditions !== frequentHeaderSimulation.commercialConditions || vehicle.use !== frequentHeaderSimulation.use) && frequentSimulation.secureOptions === true)){
              //               defaultValue=true;
              //             }
              //             await initVehicleReplacementOptionsDropDown(
              //               vehicle.simulations[vehicle.simulations.length - 1],
              //               vehicle.commercialConditions,
              //               defaultValue
              //             );
              //             await initSelectedInsuranceDropDown(
              //               vehicle.simulations[vehicle.simulations.length - 1],
              //               vehicle.use,
              //               defaultValue
              //             );
              //           }
              //         }
              //       }
              //     }
              //   }
              // }

              vehiclesService.addFrequentHeaderSimulation([],frequentHeaderSimulation,frequentHeaderSimulation.title,frequentHeaderSimulation.visibility,frequentHeaderSimulation.userId,false).then(async () => {
                let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
                let insuranceFrameworkContractOptions: InsuranceFrameworkContract = {
                  typeofRental: '',
                  commercialConditions: '',
                  insurancePackageOptions: []
                }

                let insurancePackageOptions: Option[] = [];
                let counter = 0;
                let message = `A(s) viatura(s) seleccionada(s) contém tipo(s) de uso/condições comerciais diferentes da pré-configuração seleccionada.<br>Não foi possível aplicar o cenário à(s) viatura(s):`;
                await Promise.all( vehicleDataTable.map(async (vehicle) => {
                  if (contractSettingsCheckedVehicles.size > 0) {
                    await Promise.all( Array.from(contractSettingsCheckedVehicles).map(async(selectedVehicleId) => {
                      if (selectedVehicleId === vehicle.id.toString()) {
                        if((vehicle.commercialConditions !== frequentHeaderSimulation.commercialConditions || vehicle.use !== frequentHeaderSimulation.use) && frequentHeaderSimulation.frequentSimulations[0].secureOptions === true ){
                          message += `<br>${vehicle.versionDescription}: ${vehicle.typeofRental} - ${vehicle.commercialConditions}`;
                        } else {
                          // carrega as opções dos contratos de quadro
                          if(!Array.from(tmpInsuranceSet).some(item =>
                            item.typeofRental === vehicle.typeofRental &&
                            item.commercialConditions === vehicle.commercialConditions
                          )) {
                            insurancePackageOptions = await vehiclesService.getInsurancePackage(vehicle.commercialConditions);

                            for(const insurancePackageOption of insurancePackageOptions ) {
                              let insuranceOption: InsuranceOptions = {
                                insurancePackageOption: {
                                  value: '',
                                  label: ''
                                },

                                vsOverhaulVehicleOptions: [],
                                vsTheftVehicleOptions: [],
                                vsAccidentVehicleOptions: [],
                                vsAssistanceVehicleOptions: [],
                                vehicleTypeOptions: [],

                                vsOverhaulVehicleTypeOptions: [],
                                vsTheftVehicleTypeOptions: [],
                                vsAccidentVehicleTypeOptions: [],
                                vsAssistanceVehicleTypeOptions: [],

                                fuelOptions: [],
                                viaVerdeOptions: [],

                                insurance: '',
                                vehicleDamageOptions: [],
                                roadAssistanceOptions: [],
                                occupiersOptions: [],
                                liabilityOptions: [],
                                isolatedGlassBreakOptions: [],

                                responsibility: '',
                                ownDamage: '',
                                occupants: '',
                                travelAssistance: '',
                                glassBreakage: '',
                                secureOptions: false,
                              }
                              insuranceFrameworkContractOptions.typeofRental = vehicle.typeofRental;
                              insuranceFrameworkContractOptions.commercialConditions = vehicle.commercialConditions;

                              insuranceOption.insurancePackageOption = insurancePackageOption;
                              // insuranceOption.insurance = insurancePackageOption.value;
                              insuranceOption = await initSimulationDetailsDropdowns(insuranceOption, vehicle.commercialConditions);


                              if (!insuranceFrameworkContractOptions.insurancePackageOptions.some(element =>
                                element.insurancePackageOption.value === insurancePackageOption.value)
                              ) {
                                insuranceFrameworkContractOptions.insurancePackageOptions.push(insuranceOption)
                                insuranceOption = await initVehicleReplacementOptionsDropDown(insuranceFrameworkContractOptions, insuranceOption,true);
                                insuranceOption = await initSelectedInsuranceDropDown(insuranceOption, vehicle.use,true);
                              }
                            }
                            tmpInsuranceSet.add(insuranceFrameworkContractOptions)
                          } else {
                            let selectedInsuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(item =>
                              item.typeofRental === vehicle.typeofRental &&
                              item.commercialConditions === vehicle.commercialConditions
                            )
                            if(selectedInsuranceFrameworkContractOption){
                              insuranceFrameworkContractOptions = selectedInsuranceFrameworkContractOption
                              let options: InsuranceOptions[] = selectedInsuranceFrameworkContractOption.insurancePackageOptions;
                              options.forEach(element => {
                                insurancePackageOptions.push(element.insurancePackageOption)
                              })
                            } else {
                              insurancePackageOptions = []
                            }
                          }
                          setInsuranceFrameworkContractSet(tmpInsuranceSet)


                          if (vehicle.simulations.some((sim) => sim.deadline === 0 || sim.miles === 0 )) {
                            await Promise.all(tempSimulationsList.map(async (frequentSimulationTmp) => {
                              let simulationIndex = vehicle.simulations.findIndex((sim) => sim.deadline === 0 || sim.miles === 0);
                              if(simulationIndex === -1) {
                                simulationIndex = vehicle.simulations.length;
                              }
                              let frequentSimulation = JSON.parse(JSON.stringify(frequentSimulationTmp));
                              frequentSimulation.vehicleId = vehicle.id;
                              frequentSimulation.id =vehicle.simulations[simulationIndex] === undefined ? 0: vehicle.simulations[simulationIndex].id;
                              frequentSimulation.iucCost = vehicle.iuc;
                              //Assume os valores da viatura atual em vez dos valores
                              //associados ao modelo guardado no frequente
                              frequentSimulation.priceexclVAT = vehicle.priceexclVAT;
                              frequentSimulation.consumersPrice = vehicle.consumersPrice;

                              frequentSimulation.totalExtraWithoutTax = vehicle.totalExtraWithoutTax;
                              frequentSimulation.totalExtraWithTax = vehicle.totalExtraWithTax;

                              frequentSimulation.totalAccessoriesWithout = vehicle.totalAccessoriesWithout;
                              frequentSimulation.totalAccessoriesWith = vehicle.totalAccessoriesWith;

                              frequentSimulation.discountWithoutTax = vehicle.discountWithoutTax;
                              frequentSimulation.discountWithTax = vehicle.discountWithTax;
                              frequentSimulation.discountWithTaxPercentage = vehicle.discountWithTaxPercentage;
                              frequentSimulation.discountWithTaxTotal = vehicle.discountWithTaxTotal;

                              frequentSimulation.proformaInvoicePriceWithoutTax = vehicle.proformaInvoicePriceWithoutTax;
                              frequentSimulation.proformaInvoicePriceWithTax = vehicle.proformaInvoicePriceWithTax;

                              frequentSimulation.campaignWithoutTax = vehicle.campaignWithoutTax;
                              frequentSimulation.campaignWithTax = vehicle.campaignWithTax;
                              frequentSimulation.campaignWithTaxPercentage = vehicle.campaignWithTaxPercentage;
                              frequentSimulation.campaignWithTaxTotal = vehicle.campaignWithTaxTotal;

                              frequentSimulation.rappelWithoutTax = vehicle.rappelWithoutTax;
                              frequentSimulation.rappelWithTax = vehicle.rappelWithTax;
                              frequentSimulation.rappelWithTaxPercentage = vehicle.rappelWithTaxPercentage;
                              frequentSimulation.rappelWithTaxTotal = vehicle.rappelWithTaxTotal;

                              frequentSimulation.extraRappel = vehicle.extraRappel;

                              frequentSimulation.businessValueWithoutTax = vehicle.businessValueWithoutTax;
                              frequentSimulation.businessValueWithTax = vehicle.businessValueWithTax;
                              frequentSimulation.quantity = vehicle.quantity;
                              //fim validações

                              vehicle.simulations[simulationIndex] = frequentSimulation;

                              //filtra opções de seguro consoante a simulação frequente a aplicar
                              let frequentInsurancePackageOption:InsuranceOptions = insuranceFrameworkContractOptions.insurancePackageOptions.find( x => x.insurance === frequentSimulation.insurance) ?? insuranceFrameworkContractOptions.insurancePackageOptions[0];

                              vehicle.simulations[simulationIndex].vsAccidentVehicleOptions = frequentInsurancePackageOption.vsAccidentVehicleOptions;
                              vehicle.simulations[simulationIndex].vsAssistanceVehicleOptions = frequentInsurancePackageOption.vsAssistanceVehicleOptions;
                              vehicle.simulations[simulationIndex].vsOverhaulVehicleOptions = frequentInsurancePackageOption.vsOverhaulVehicleOptions;
                              vehicle.simulations[simulationIndex].vsTheftVehicleOptions = frequentInsurancePackageOption.vsTheftVehicleOptions;

                              vehicle.simulations[simulationIndex].vsAccidentVehicleTypeOptions = frequentInsurancePackageOption.vsAccidentVehicleTypeOptions;
                              vehicle.simulations[simulationIndex].vsAssistanceVehicleTypeOptions = frequentInsurancePackageOption.vsAssistanceVehicleTypeOptions;
                              vehicle.simulations[simulationIndex].vsOverhaulVehicleTypeOptions = frequentInsurancePackageOption.vsOverhaulVehicleTypeOptions;
                              vehicle.simulations[simulationIndex].vsTheftVehicleTypeOptions = frequentInsurancePackageOption.vsTheftVehicleTypeOptions;
                              vehicle.simulations[simulationIndex].vehicleTypeOptions = frequentInsurancePackageOption.vehicleTypeOptions;

                              vehicle.simulations[simulationIndex].fuelOptions = frequentInsurancePackageOption.fuelOptions;
                              vehicle.simulations[simulationIndex].viaVerdeOptions = frequentInsurancePackageOption.viaVerdeOptions;

                              vehicle.simulations[simulationIndex].vehicleDamageOptions = frequentInsurancePackageOption.vehicleDamageOptions;
                              vehicle.simulations[simulationIndex].roadAssistanceOptions = frequentInsurancePackageOption.roadAssistanceOptions;
                              vehicle.simulations[simulationIndex].occupiersOptions = frequentInsurancePackageOption.occupiersOptions;
                              vehicle.simulations[simulationIndex].liabilityOptions = frequentInsurancePackageOption.liabilityOptions;
                              vehicle.simulations[simulationIndex].isolatedGlassBreakOptions = frequentInsurancePackageOption.isolatedGlassBreakOptions;

                              vehicle.simulations[simulationIndex].responsibility = frequentInsurancePackageOption.responsibility;
                              vehicle.simulations[simulationIndex].ownDamage = frequentInsurancePackageOption.ownDamage;
                              vehicle.simulations[simulationIndex].occupants = frequentInsurancePackageOption.occupants;
                              vehicle.simulations[simulationIndex].travelAssistance = frequentInsurancePackageOption.travelAssistance;
                              vehicle.simulations[simulationIndex].glassBreakage = frequentInsurancePackageOption.glassBreakage;

                              vehicle.simulations[simulationIndex].insurance = frequentInsurancePackageOption.insurance;
                              vehicle.simulations[simulationIndex].secureOptions = frequentInsurancePackageOption.insurance ? true : false;
                              vehicle.simulations[simulationIndex].insurancePackageOptions = insurancePackageOptions;

                              return frequentSimulation;
                            }))
                          } else {
                            await Promise.all(tempSimulationsList.map(async (frequentSimulation) => {
                              frequentSimulation.vehicleId = vehicle.id;
                              frequentSimulation.id = 0;
                              frequentSimulation.iucCost = vehicle.iuc;
                              //Assume os valores da viatura atual em vez dos valores
                              //associados ao modelo guardado no frequente
                              frequentSimulation.priceexclVAT = vehicle.priceexclVAT;
                              frequentSimulation.consumersPrice = vehicle.consumersPrice;

                              frequentSimulation.totalExtraWithoutTax = vehicle.totalExtraWithoutTax;
                              frequentSimulation.totalExtraWithTax = vehicle.totalExtraWithTax;

                              frequentSimulation.totalAccessoriesWithout = vehicle.totalAccessoriesWithout;
                              frequentSimulation.totalAccessoriesWith = vehicle.totalAccessoriesWith;

                              frequentSimulation.discountWithoutTax = vehicle.discountWithoutTax;
                              frequentSimulation.discountWithTax = vehicle.discountWithTax;
                              frequentSimulation.discountWithTaxPercentage = vehicle.discountWithTaxPercentage;
                              frequentSimulation.discountWithTaxTotal = vehicle.discountWithTaxTotal;

                              frequentSimulation.proformaInvoicePriceWithoutTax = vehicle.proformaInvoicePriceWithoutTax;
                              frequentSimulation.proformaInvoicePriceWithTax = vehicle.proformaInvoicePriceWithTax;

                              frequentSimulation.campaignWithoutTax = vehicle.campaignWithoutTax;
                              frequentSimulation.campaignWithTax = vehicle.campaignWithTax;
                              frequentSimulation.campaignWithTaxPercentage = vehicle.campaignWithTaxPercentage;
                              frequentSimulation.campaignWithTaxTotal = vehicle.campaignWithTaxTotal;

                              frequentSimulation.rappelWithoutTax = vehicle.rappelWithoutTax;
                              frequentSimulation.rappelWithTax = vehicle.rappelWithTax;
                              frequentSimulation.rappelWithTaxPercentage = vehicle.rappelWithTaxPercentage;
                              frequentSimulation.rappelWithTaxTotal = vehicle.rappelWithTaxTotal;

                              frequentSimulation.extraRappel = vehicle.extraRappel;

                              frequentSimulation.businessValueWithoutTax = vehicle.businessValueWithoutTax;
                              frequentSimulation.businessValueWithTax = vehicle.businessValueWithTax;
                              frequentSimulation.quantity = vehicle.quantity;
                              //fim validações
                              vehicle.simulations.push(frequentSimulation);

                              vehicle.simulations[vehicle.simulations.length-1].vsAccidentVehicleOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsAccidentVehicleOptions;
                              vehicle.simulations[vehicle.simulations.length-1].vsAssistanceVehicleOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsAssistanceVehicleOptions;
                              vehicle.simulations[vehicle.simulations.length-1].vsOverhaulVehicleOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsOverhaulVehicleOptions;
                              vehicle.simulations[vehicle.simulations.length-1].vsTheftVehicleOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsTheftVehicleOptions;

                              vehicle.simulations[vehicle.simulations.length-1].vsAccidentVehicleTypeOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsAccidentVehicleTypeOptions;
                              vehicle.simulations[vehicle.simulations.length-1].vsAssistanceVehicleTypeOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsAssistanceVehicleTypeOptions;
                              vehicle.simulations[vehicle.simulations.length-1].vsOverhaulVehicleTypeOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsOverhaulVehicleTypeOptions;
                              vehicle.simulations[vehicle.simulations.length-1].vsTheftVehicleTypeOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsTheftVehicleTypeOptions;
                              vehicle.simulations[vehicle.simulations.length-1].vehicleTypeOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vehicleTypeOptions;

                              vehicle.simulations[vehicle.simulations.length-1].fuelOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].fuelOptions;
                              vehicle.simulations[vehicle.simulations.length-1].viaVerdeOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].viaVerdeOptions;

                              vehicle.simulations[vehicle.simulations.length-1].vehicleDamageOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vehicleDamageOptions;
                              vehicle.simulations[vehicle.simulations.length-1].roadAssistanceOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].roadAssistanceOptions;
                              vehicle.simulations[vehicle.simulations.length-1].occupiersOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].occupiersOptions;
                              vehicle.simulations[vehicle.simulations.length-1].liabilityOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].liabilityOptions;
                              vehicle.simulations[vehicle.simulations.length-1].isolatedGlassBreakOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].isolatedGlassBreakOptions;

                              vehicle.simulations[vehicle.simulations.length-1].responsibility = insuranceFrameworkContractOptions.insurancePackageOptions[0].responsibility;
                              vehicle.simulations[vehicle.simulations.length-1].ownDamage = insuranceFrameworkContractOptions.insurancePackageOptions[0].ownDamage;
                              vehicle.simulations[vehicle.simulations.length-1].occupants = insuranceFrameworkContractOptions.insurancePackageOptions[0].occupants;
                              vehicle.simulations[vehicle.simulations.length-1].travelAssistance = insuranceFrameworkContractOptions.insurancePackageOptions[0].travelAssistance;
                              vehicle.simulations[vehicle.simulations.length-1].glassBreakage = insuranceFrameworkContractOptions.insurancePackageOptions[0].glassBreakage;

                              vehicle.simulations[vehicle.simulations.length-1].insurance = insuranceFrameworkContractOptions.insurancePackageOptions[0].insurance;
                              vehicle.simulations[vehicle.simulations.length-1].secureOptions= insuranceFrameworkContractOptions.insurancePackageOptions[0].secureOptions;
                              vehicle.simulations[vehicle.simulations.length-1].insurancePackageOptions = insurancePackageOptions;

                              return frequentSimulation;
                            }));
                          }
                          counter++;
                        }
                      }
                    }));
                  }
                  return vehicle;
                })).then(() => {
                  if(counter < contractSettingsCheckedVehicles.size){
                    openAlert({
                      variant: "info",
                      text: message,
                      title: "Aviso",
                      autoHideDuration: 3000,
                    });
                  } else {
                    openAlert({
                      variant: "success",
                      text: "Cenário aplicado com sucesso!",
                      title: "Sucesso",
                      autoHideDuration: 3000,
                    });
                  }
                  resetScenarioModal();
                  getScenariosFrequentSimulations();
                  setContractSettingsCardCheckedDemo((prev) =>
                    prev.filter((c) => c !== value)
                  );
                  setContractSettingsCheckedVehicles(new Set());
                });
              });
            }

          }
        },

        hidden: false
      };
    }),
    [
      scenariosFrequentSimulation,
      vehicleDataTable, contractSettingsCardCheckedDemo,
      contractSettingsCheckedVehicles,
      initSelectedInsuranceDropDown, initVehicleReplacementOptionsDropDown,
      initSimulationDetailsDropdowns, insuranceFrameworkContractSet,
      resetScenarioModal,
      //unnecessary
      // visibleRadioGroup,

      // missing
      getScenariosFrequentSimulations

    ]
  );


  const handleDeadLineCheckedChange =((index: number, allowMultiSelection: boolean) => (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setDeadlineValues((rows)=>
      rows.map((deadline,indexR)=> {
        if(index === indexR) {
          deadline.checked=checked;
        } else //if(allowMultiSelection)
          deadline.checked = allowMultiSelection ? deadline.checked : false;
        return deadline;
      })
    )
  });

  const handleAnnualKmCheckedChange = ((index: number, allowMultiSelection: boolean) => (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAnnualKmValues((rows)=>
      rows.map((annualKm,indexR )=> {
        if(index === indexR){
          annualKm.checked=checked;
        } else {
          annualKm.checked = allowMultiSelection ? annualKm.checked : false;
        }
        return annualKm;
      })
    )
  });

  const [thirdLineTiresValues,setThirdLineTiresValues] = useState<{ value: string; label:string }[]>(settings.Data.Vehicles_TyresNumber);
  const [thirdLineTiresValuesMultiScenario,setThirdLineTiresValuesMultiScenario] = useState<{
    value: number,
    label:string,
    onChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void ,
    checked:boolean
  }[]>(settings.Data.Vehicles_TyresNumber_MultiScenario);

  const handleThirdLineTiresCheckedChange = useCallback((index: number, allowMultiSelection: boolean) => (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setThirdLineTiresValuesMultiScenario((rows) =>
      rows.map((thirdLineTiresValue, rowIndex) => {
        if(allowMultiSelection){
          if(thirdLineTiresValue.value === Number(e.target.value)){
            thirdLineTiresValue.checked = checked;
          }
        } else {
          if(index === rowIndex){
            thirdLineTiresValue.checked=checked;
          }
          else {
            thirdLineTiresValue.checked = allowMultiSelection ? thirdLineTiresValue.checked : false;
          }
        }
        return thirdLineTiresValue;
      })
    )
  },[])

  const handleCreateScenarioClick: ButtonProps["onClick"] = useCallback(async (e: any) => {
    if(contractSettingsCheckedVehicles.size === 0){
      openAlert({
        variant: "error",
        text: "Não tem nenhum veículo selecionado!",
        title: "Atenção",
      });
    }
    else if(deadlineValues.filter(x => x.checked === true).length === 0){
      openAlert({
        variant: "error",
        text: "Não tem nenhum prazo selecionado!",
        title: "Atenção",
      });
    }
    else if(annualKmValues.filter(x => x.checked === true).length === 0){
      openAlert({
        variant: "error",
        text: "Não tem nenhum km selecionado!",
        title: "Atenção",
      });
    }
    else {
      let multicenarios:Simulation[]=[];
      let hasBothTyresOptions = false;
      let hasBothViaVerdeOptions = false;
      let hasBothFuelOptions = false;
      if(tiresNo && tiresYes) {
        hasBothTyresOptions = true;
      }
      if(viaVerdeNo && viaVerdeYes) {
        hasBothViaVerdeOptions = true;
      }
      if(fuelNo && fuelYes) {
        hasBothFuelOptions = true;
      }

      deadlineValues.map((deadline) => {
        if(deadline.checked === true) {
          //ANNUAL KMS
          annualKmValues.map((annualKm) => {
            if(annualKm.checked === true) {

              for(let tyresYesNoSelectedOptions = 0; tyresYesNoSelectedOptions < (hasBothTyresOptions === true ? 2 : 1); tyresYesNoSelectedOptions++){
                for(let viaVerdeYesNoSelectedOptions = 0; viaVerdeYesNoSelectedOptions < (hasBothViaVerdeOptions === true ? 2 : 1); viaVerdeYesNoSelectedOptions++){
                  for(let fuelYesNoSelectedOptions = 0; fuelYesNoSelectedOptions < (hasBothFuelOptions === true ? 2 : 1); fuelYesNoSelectedOptions++){
                    let tempSimulationScenario: Simulation = JSON.parse(JSON.stringify(simulationScenario));
                    //INSURANCES
                    insurances.map((insuranceYesNo,indexInsurance) => {
                      if(insuranceYesNo || (insurances.indexOf(true) < 0 && indexInsurance === 0)){
                        if(indexInsurance === 0) {
                          tempSimulationScenario.ownDamage = "";
                          tempSimulationScenario.occupants = "";
                          tempSimulationScenario.travelAssistance = "";
                          tempSimulationScenario.responsibility = "";
                          tempSimulationScenario.glassBreakage = "";
                          tempSimulationScenario.insurance = "";
                          tempSimulationScenario.secureOptions = false;
                        }
                        else {
                          tempSimulationScenario.ownDamage = simulationScenario.ownDamage;
                          tempSimulationScenario.occupants = simulationScenario.occupants;
                          tempSimulationScenario.travelAssistance = simulationScenario.travelAssistance;
                          tempSimulationScenario.responsibility = simulationScenario.responsibility;
                          tempSimulationScenario.glassBreakage = simulationScenario.glassBreakage;
                          tempSimulationScenario.insurance = simulationScenario.insurance;
                          tempSimulationScenario.secureOptions = true;
                        }
                        //VS
                        replacementVehicles.map((replacementVehicle,indexReplacementVehicle) => {
                          if (replacementVehicle || (replacementVehicles.indexOf(true) < 0 && indexReplacementVehicle === 0)){
                            if (indexReplacementVehicle === 0) {
                              tempSimulationScenario.vsTheftVehicleType = "";
                              tempSimulationScenario.vsTheftVehicle = "";
                              tempSimulationScenario.vsAccidentVehicleType = "";
                              tempSimulationScenario.vsAccidentVehicle = "";
                              tempSimulationScenario.vsAssistanceVehicleType = "";
                              tempSimulationScenario.vsAssistanceVehicle = "";
                              tempSimulationScenario.vsOverhaulVehicleType = "";
                              tempSimulationScenario.vsOverhaulVehicle = "";
                              tempSimulationScenario.vehicleType = "";
                              tempSimulationScenario.vehicleReplacementOptions = false;
                            }
                            else {
                              tempSimulationScenario.vsTheftVehicleType = simulationScenario.vsTheftVehicleType;
                              tempSimulationScenario.vsTheftVehicle = simulationScenario.vsTheftVehicle;
                              tempSimulationScenario.vsAccidentVehicleType = simulationScenario.vsAccidentVehicleType;
                              tempSimulationScenario.vsAccidentVehicle = simulationScenario.vsAccidentVehicle;
                              tempSimulationScenario.vsAssistanceVehicleType = simulationScenario.vsAssistanceVehicleType;
                              tempSimulationScenario.vsAssistanceVehicle = simulationScenario.vsAssistanceVehicle;
                              tempSimulationScenario.vsOverhaulVehicleType = simulationScenario.vsOverhaulVehicleType;
                              tempSimulationScenario.vsOverhaulVehicle = simulationScenario.vsOverhaulVehicle;
                              tempSimulationScenario.vehicleType = simulationScenario.vehicleType;
                              tempSimulationScenario.vehicleReplacementOptions = true;
                            }

                            tempSimulationScenario.deadline = Number(deadline.value);
                            tempSimulationScenario.miles = (Number(annualKm.value) * Number(deadline.value))/12;

                            tempSimulationScenario.mostUsed = 1;

                            //FUEL
                            if (
                              (fuelYes && hasBothFuelOptions && fuelYesNoSelectedOptions === 1) ||
                              (fuelYes && !hasBothFuelOptions && fuelYesNoSelectedOptions === 0 )
                            ) {
                              tempSimulationScenario.fuelDescriptiom=simulationScenario.fuelDescriptiom;
                              tempSimulationScenario.fuelCard=true;
                            } else {
                              tempSimulationScenario.fuelDescriptiom="";
                              tempSimulationScenario.fuelCard=false;
                            }
                            //VIA VERDE
                            if (
                              (viaVerdeYes && hasBothViaVerdeOptions && viaVerdeYesNoSelectedOptions === 1) ||
                              (viaVerdeYes && !hasBothViaVerdeOptions && viaVerdeYesNoSelectedOptions === 0 )
                            ) {
                              tempSimulationScenario.entityViaVerde=simulationScenario.entityViaVerde;
                              tempSimulationScenario.viaVerde=true;
                            } else{
                              tempSimulationScenario.entityViaVerde="";
                              tempSimulationScenario.viaVerde=false;
                            }

                            // PRODUTOS COMPLEMENTARES
                            let complementaryProducts: ComplementaryProduct[] = [];
                            modalComplementaryProductsMemo.tableProps[0].rows.forEach(row => {
                              if(row.cells[0].checked){
                                complementaryProducts.push({
                                  id: 0,
                                  simulationId: tempSimulationScenario.id,
                                  no: row.cells[1],
                                  description: row.cells[2],
                                  registerCode: String(row.cells[3].value),
                                  additionalCostValue: String(row.cells[4][0].value),
                                  additionalCostTimePeriod: String(row.cells[4][1].value),
                                  insurance: String(row.cells[5].value),
                                  accessoryType: String(row.details.accessoryDropDownProps.value),
                                  basePriceWithoutTax: Number(row.details.basePrice.withoutTaxTextFieldProps.value),
                                  basePriceWithTax: Number(row.details.basePrice.withTaxTextFieldProps.value),
                                  residualValue: Number(row.details.residualValueTextFieldProps.value),
                                  supplier: String(row.details.supplierDropDownProps.value),
                                  lineNo: Number(row.cells[6]),
                                  headerNo: row.cells[7],

                                })
                              }
                            })
                            tempSimulationScenario.complementaryProducts = complementaryProducts;

                            //TIRES
                            if (
                              (tiresYes && hasBothTyresOptions && tyresYesNoSelectedOptions === 1) ||
                              (tiresYes && !hasBothTyresOptions && tyresYesNoSelectedOptions === 0 )
                            ) {
                              tempSimulationScenario.tires = true;
                              if ( tiresLimitedNo && tiresLimitedYes ) {
                                //tiresLimitedNo
                                tempSimulationScenario.tiresQuantity = '0';
                                let insertSimulation: Simulation = JSON.parse(JSON.stringify(tempSimulationScenario));
                                multicenarios.push(insertSimulation);
                                //tiresLimitedYes
                                thirdLineTiresValuesMultiScenario.forEach(x => {
                                  if(x.checked) {
                                    tempSimulationScenario = JSON.parse(JSON.stringify(tempSimulationScenario));
                                    tempSimulationScenario.tiresQuantity = x.value.toString();
                                    let insertSimulation: Simulation = JSON.parse(JSON.stringify(tempSimulationScenario));
                                    multicenarios.push(insertSimulation);
                                  }
                                })
                              }
                              if ( tiresLimitedNo && !tiresLimitedYes) {
                                tempSimulationScenario.tiresQuantity = '0';
                                let insertSimulation: Simulation = JSON.parse(JSON.stringify(tempSimulationScenario));
                                multicenarios.push(insertSimulation);
                              }

                              if ( !tiresLimitedNo && tiresLimitedYes) {
                                if(thirdLineTiresValuesMultiScenario.some(x => x.checked)) {
                                  thirdLineTiresValuesMultiScenario.forEach(x => {
                                    if(x.checked) {
                                      tempSimulationScenario.tiresQuantity = x.value.toString();
                                      let insertSimulation: Simulation = JSON.parse(JSON.stringify(tempSimulationScenario));
                                      multicenarios.push(insertSimulation);
                                    }
                                  })
                                }
                                else {
                                  openAlert({
                                    variant: "info",
                                    text: "Não tem nenhuma quantidade de pneus seleccionada",
                                    title: "Atenção",
                                  });
                                }
                              }
                            } else {
                              tempSimulationScenario.tires = false;
                              tempSimulationScenario.tiresQuantity = '0';
                              let insertSimulation: Simulation = JSON.parse(JSON.stringify(tempSimulationScenario));
                              multicenarios.push(insertSimulation);
                            }
                          }
                          return replacementVehicle;
                        });
                      }
                      return insuranceYesNo;
                    });
                  }
                }
              }
            }
            return annualKm;
          })
        }
        return deadline;
      })
      let counter = 0;
      let message = `A(s) viatura(s) seleccionada(s) contém tipo(s) de uso/condições comerciais diferentes da pré-configuração efectuada.<br>Não foi possível aplicar o cenário à(s) viatura(s):`;
      await Promise.all(
        vehicleDataTable.map(async (vehicle, rowIndex) => {
          Array.from(contractSettingsCheckedVehicles).map(async (selectedVehicleId) => {
            Array.from(multicenarios).map(async (tempSimulationScenario)=>{
              if (selectedVehicleId === vehicle.id.toString()) {
                if((vehicle.commercialConditions !== commercialConditionsScenario || vehicle.use !== useScenario) && tempSimulationScenario.secureOptions === true ){
                  message += `<br>${vehicle.versionDescription}: ${vehicle.typeofRental} - ${vehicle.commercialConditions}`;
                } else {
                  let simulationIndex = vehicle.simulations.findIndex(sim  => sim.deadline === 0 || sim.miles === 0);

                  if(vehicle.simulations.some(sim  => sim.deadline === 0 || sim.miles === 0 )) {
                    tempSimulationScenario.id =vehicle.simulations.length === 0 ? 0: vehicle.simulations[simulationIndex].id;
                  } else {
                    tempSimulationScenario.id = 0;
                  }
                  tempSimulationScenario.vehicleId = vehicle.vehicleId;


                  //Assume os valores da viatura atual em vez dos valores
                  //associados ao modelo guardado no frequente
                  tempSimulationScenario.priceexclVAT = vehicle.priceexclVAT;
                  tempSimulationScenario.consumersPrice = vehicle.consumersPrice;

                  tempSimulationScenario.totalExtraWithoutTax = vehicle.totalExtraWithoutTax;
                  tempSimulationScenario.totalExtraWithTax = vehicle.totalExtraWithTax;

                  tempSimulationScenario.totalAccessoriesWithout = vehicle.totalAccessoriesWithout;
                  tempSimulationScenario.totalAccessoriesWith = vehicle.totalAccessoriesWith;

                  tempSimulationScenario.discountWithoutTax = vehicle.discountWithoutTax;
                  tempSimulationScenario.discountWithTax = vehicle.discountWithTax;
                  tempSimulationScenario.discountWithTaxPercentage = vehicle.discountWithTaxPercentage;
                  tempSimulationScenario.discountWithTaxTotal = vehicle.discountWithTaxTotal;

                  tempSimulationScenario.proformaInvoicePriceWithoutTax = vehicle.proformaInvoicePriceWithoutTax;
                  tempSimulationScenario.proformaInvoicePriceWithTax = vehicle.proformaInvoicePriceWithTax;

                  tempSimulationScenario.campaignWithoutTax = vehicle.campaignWithoutTax;
                  tempSimulationScenario.campaignWithTax = vehicle.campaignWithTax;
                  tempSimulationScenario.campaignWithTaxPercentage = vehicle.campaignWithTaxPercentage;
                  tempSimulationScenario.campaignWithTaxTotal = vehicle.campaignWithTaxTotal;

                  tempSimulationScenario.rappelWithoutTax = vehicle.rappelWithoutTax;
                  tempSimulationScenario.rappelWithTax = vehicle.rappelWithTax;
                  tempSimulationScenario.rappelWithTaxPercentage = vehicle.rappelWithTaxPercentage;
                  tempSimulationScenario.rappelWithTaxTotal = vehicle.rappelWithTaxTotal;

                  tempSimulationScenario.extraRappel = vehicle.extraRappel;

                  tempSimulationScenario.businessValueWithoutTax = vehicle.businessValueWithoutTax;
                  tempSimulationScenario.businessValueWithTax = vehicle.businessValueWithTax;
                  tempSimulationScenario.quantity = vehicle.quantity;
                  tempSimulationScenario.iucCost = vehicle.iuc;
                  //fim validações
                  let rentalSettings = rentalTypeSettings.find((element) => { return element.value === vehicle.typeofRental});
                  tempSimulationScenario.maintenance = rentalSettings.maintenance;
                  vehicle.maintenance = rentalSettings.maintenance;
                  let insertSimulation: Simulation = JSON.parse(JSON.stringify(tempSimulationScenario));
                  if(vehicle.simulations.some(sim  => sim.deadline === 0 || sim.miles === 0 )) {
                    vehicle.simulations[simulationIndex]=insertSimulation;
                  } else {
                    vehicle.simulations.push(insertSimulation);
                  }

                  setVehicleDataTable((rows) =>
                    rows.map((row, index) => {
                      if (index === rowIndex){
                        row.simulations[vehicle.simulations.length - 1] = vehicle.simulations[vehicle.simulations.length - 1]
                        return row;
                      }
                      return row;
                    })
                  )
                  counter++;
                }
              }
            })
          })
        })
      ).then(() => {
        getScenariosFrequentSimulations();
        if(counter < contractSettingsCheckedVehicles.size){
          openAlert({
            variant: "info",
            text: message,
            title: "Aviso",
            autoHideDuration: 3000,
          });
        } else {
          openAlert({
            variant: "success",
            text: "Cenário aplicado com sucesso!",
            title: "Sucesso",
            autoHideDuration: 3000,
          });
        }
        resetScenarioModal();

      })
    }
  },[
    modalComplementaryProductsMemo,
    deadlineValues,
    annualKmValues,
    simulationScenario,
    vehicleDataTable,
    contractSettingsCheckedVehicles,
    commercialConditionsScenario,
    useScenario,
    fuelNo, fuelYes, viaVerdeNo, viaVerdeYes,
    getScenariosFrequentSimulations,
    rentalTypeSettings, resetScenarioModal,
    insurances, replacementVehicles, thirdLineTiresValuesMultiScenario, tiresLimitedNo, tiresLimitedYes, tiresNo, tiresYes
  ]);


  const handleCreateSaveScenarioClick: ButtonProps["onClick"] = useCallback(async (e: any) => {
    if(contractSettingsCheckedVehicles.size === 0){
      openAlert({
        variant: "error",
        text: "Não tem nenhum veículo selecionado!",
        title: "Atenção",
      });
    }
    else if(deadlineValues.filter(x => x.checked === true).length === 0){
      openAlert({
        variant: "error",
        text: "Não tem nenhum prazo selecionado!",
        title: "Atenção",
      });
    }
    else if(annualKmValues.filter(x => x.checked === true).length === 0){
      openAlert({
        variant: "error",
        text: "Não tem nenhum km selecionado!",
        title: "Atenção",
      });
    }
    else {
      let tempHeaderSimulationScenario: IFrequentHeaderSimulation = {
        id:0,
        userId:0,
        //frequentVehicleId:0,
        deadline:"",
        miles:"",
        tire:"",
        vehicleReplacementOptions:"",
        maintenance:"",
        insurance:"",
        deductible:"",
        title:"",
        mostUsed:0,
        visibility:0,
        typeofRental:"",
        commercialConditions:"",
        typeofIncome:"",
        use: "",
        frequentSimulations:[]
      };
      let tempSimulationsScenario: Simulation[]=[];
      let hasBothTyresOptions = false;
      let hasBothViaVerdeOptions = false;
      let hasBothFuelOptions = false;

      deadlineValues.map((deadline) => {
        if (!tempHeaderSimulationScenario.insurance && tempHeaderSimulationScenario.insurance === ""){
          if(insurances[0]){
            tempHeaderSimulationScenario.insurance += "|no";
          }
          if (insurances[1]){
            tempHeaderSimulationScenario.insurance += "|yes";
          }
        }
        //if (!tempHeaderSimulationScenario.tire.includes("no") && !tempHeaderSimulationScenario.tire.includes("yes")){
        if(tiresNo && tiresYes) {
          hasBothTyresOptions = true;
          tempHeaderSimulationScenario.tire = "|yes|no";
        } else if (tiresNo) {
          hasBothTyresOptions = false;
          tempHeaderSimulationScenario.tire = "|no";
          // considerar passar |no:true ou |no:false
        } else if (tiresYes) {
          hasBothTyresOptions = false;
          tempHeaderSimulationScenario.tire = "|yes";
          // considerar passar |yes:true ou |yes:false
        } else if(tempHeaderSimulationScenario.tire === ""){
          hasBothTyresOptions = false;
          tempHeaderSimulationScenario.tire = "|no";
        }
        //}
        if (deadline.checked === true) {

          if(!tempHeaderSimulationScenario.deadline.includes(deadline.value as string)) {
            tempHeaderSimulationScenario.deadline += "|" + deadline.value;
          }
          annualKmValues.map((annualKm) => {
            if (annualKm.checked === true) {
              if(!tempHeaderSimulationScenario.miles.includes(Number(annualKm.value).toString())) {
                tempHeaderSimulationScenario.miles += "|" + annualKm.value;
              }

              //thirdLineTireValues.map((tiresKm) => {
                //if (tiresKm.value !="") {//REVER
              for(let viaVerdeYesNoSelectedOptions = 0; viaVerdeYesNoSelectedOptions < (hasBothViaVerdeOptions ? 2 : 1); viaVerdeYesNoSelectedOptions++){
                for(let fuelYesNoSelectedOptions = 0; fuelYesNoSelectedOptions < (hasBothFuelOptions ? 2 : 1); fuelYesNoSelectedOptions++){
                  for(let tyresYesNoSelectedOptions = 0; tyresYesNoSelectedOptions < (hasBothTyresOptions === true ? 2 : 1); tyresYesNoSelectedOptions++){

                    let tempSimulationScenario: Simulation = JSON.parse(JSON.stringify(simulationScenario));
                    insurances.map((insuranceYesNo,indexInsurance) => {
                      if(insuranceYesNo || (insurances.indexOf(true) < 0 && indexInsurance === 0)){
                        if (indexInsurance === 0) {
                            tempSimulationScenario.ownDamage = "";
                            tempSimulationScenario.occupants = "";
                            tempSimulationScenario.travelAssistance = "";
                            tempSimulationScenario.responsibility = "";
                            tempSimulationScenario.glassBreakage = "";
                            tempSimulationScenario.insurance = "";
                            tempSimulationScenario.secureOptions = false;
                        }
                        else{
                            tempSimulationScenario.ownDamage = simulationScenario.ownDamage;
                            tempSimulationScenario.occupants = simulationScenario.occupants;
                            tempSimulationScenario.travelAssistance = simulationScenario.travelAssistance;
                            tempSimulationScenario.responsibility = simulationScenario.responsibility;
                            tempSimulationScenario.glassBreakage = simulationScenario.glassBreakage;
                            tempSimulationScenario.insurance = simulationScenario.insurance;
                            tempSimulationScenario.secureOptions = true;
                        }

                        replacementVehicles.map((replacementVehicle,indexReplacementVehicle) => {
                          if (replacementVehicle || (replacementVehicles.indexOf(true) < 0 && indexReplacementVehicle === 0)){
                            if (indexReplacementVehicle === 0) {
                              tempSimulationScenario.vsTheftVehicleType = "";
                              tempSimulationScenario.vsTheftVehicle = "";
                              tempSimulationScenario.vsAccidentVehicleType = "";
                              tempSimulationScenario.vsAccidentVehicle = "";
                              tempSimulationScenario.vsAssistanceVehicleType = "";
                              tempSimulationScenario.vsAssistanceVehicle = "";
                              tempSimulationScenario.vsOverhaulVehicleType = "";
                              tempSimulationScenario.vsOverhaulVehicle = "";
                              tempSimulationScenario.vehicleType = "";
                              tempSimulationScenario.vehicleReplacementOptions = false;
                            } else {
                              tempSimulationScenario.vsTheftVehicleType = simulationScenario.vsTheftVehicleType;
                              tempSimulationScenario.vsTheftVehicle = simulationScenario.vsTheftVehicle;
                              tempSimulationScenario.vsAccidentVehicleType = simulationScenario.vsAccidentVehicleType;
                              tempSimulationScenario.vsAccidentVehicle = simulationScenario.vsAccidentVehicle;
                              tempSimulationScenario.vsAssistanceVehicleType = simulationScenario.vsAssistanceVehicleType;
                              tempSimulationScenario.vsAssistanceVehicle = simulationScenario.vsAssistanceVehicle;
                              tempSimulationScenario.vsOverhaulVehicleType = simulationScenario.vsOverhaulVehicleType;
                              tempSimulationScenario.vsOverhaulVehicle = simulationScenario.vsOverhaulVehicle;
                              tempSimulationScenario.vehicleType = simulationScenario.vehicleType;
                              tempSimulationScenario.vehicleReplacementOptions = true;
                            }

                            tempSimulationScenario.deadline = Number(deadline.value);
                            tempSimulationScenario.miles = (Number(annualKm.value) * Number(deadline.value))/12;

                            tempSimulationScenario.mostUsed = 1;
                            //FUEL
                            if (
                              (fuelYes && hasBothFuelOptions && fuelYesNoSelectedOptions === 1) ||
                              (fuelYes && !hasBothFuelOptions && fuelYesNoSelectedOptions === 0 )
                            ) {
                                tempSimulationScenario.fuelDescriptiom=simulationScenario.fuelDescriptiom;
                                tempSimulationScenario.fuelCard=true;
                            } else {
                                tempSimulationScenario.fuelDescriptiom="";
                                tempSimulationScenario.fuelCard=false;
                            }
                            //VIA VERDE
                            if (
                              (viaVerdeYes && hasBothViaVerdeOptions && viaVerdeYesNoSelectedOptions === 1) ||
                              (viaVerdeYes && !hasBothViaVerdeOptions && viaVerdeYesNoSelectedOptions === 0 )
                            ) {
                                tempSimulationScenario.entityViaVerde=simulationScenario.entityViaVerde;
                                tempSimulationScenario.viaVerde=true;
                            } else {
                                tempSimulationScenario.entityViaVerde="";
                                tempSimulationScenario.viaVerde=false;
                            }

                            // PRODUTOS COMPLEMENTARES
                            let complementaryProducts: ComplementaryProduct[] = [];
                            modalComplementaryProductsMemo.tableProps[0].rows.forEach(row => {
                              if(row.cells[0].checked){
                                complementaryProducts.push({
                                  id: 0,
                                  simulationId: tempSimulationScenario.id,
                                  no: row.cells[1],
                                  description: row.cells[2],
                                  registerCode: String(row.cells[3].value),
                                  additionalCostValue: String(row.cells[4][0].value),
                                  additionalCostTimePeriod: String(row.cells[4][1].value),
                                  insurance: String(row.cells[5].value),
                                  accessoryType: String(row.details.accessoryDropDownProps.value),
                                  basePriceWithoutTax: Number(row.details.basePrice.withoutTaxTextFieldProps.value),
                                  basePriceWithTax: Number(row.details.basePrice.withTaxTextFieldProps.value),
                                  residualValue: Number(row.details.residualValueTextFieldProps.value),
                                  supplier: String(row.details.supplierDropDownProps.value),
                                  lineNo: Number(row.cells[6]),
                                  headerNo: row.cells[7],

                                })
                              }
                            })
                            tempSimulationScenario.complementaryProducts = complementaryProducts;
                            //TIRES
                            // if (
                            //   (tiresYes && hasBothTyresOptions && tyresYesNoSelectedOptions === 1) ||
                            //   (tiresYes && !hasBothTyresOptions && tyresYesNoSelectedOptions === 0 )
                            // ) {
                            if (
                              (tiresYes && tiresNo && tyresYesNoSelectedOptions === 1) ||
                              (tiresYes && !tiresNo && tyresYesNoSelectedOptions === 0 )
                            ) {
                              tempSimulationScenario.tires = true;
                              if ( tiresLimitedNo && tiresLimitedYes ) {
                                //tiresLimitedNo
                                tempSimulationScenario.tiresQuantity = '0';
                                let insertSimulation: Simulation = JSON.parse(JSON.stringify(tempSimulationScenario));
                                tempSimulationsScenario.push(insertSimulation);
                                //tiresLimitedYes
                                thirdLineTiresValuesMultiScenario.forEach(x => {
                                  if(x.checked) {
                                    tempSimulationScenario = JSON.parse(JSON.stringify(tempSimulationScenario));
                                    tempSimulationScenario.tiresQuantity = x.value.toString();
                                    let insertSimulation: Simulation = JSON.parse(JSON.stringify(tempSimulationScenario));
                                    tempSimulationsScenario.push(insertSimulation);
                                  }
                                })
                              }
                              if ( tiresLimitedNo && !tiresLimitedYes) {
                                tempSimulationScenario.tiresQuantity = '0';
                                let insertSimulation: Simulation = JSON.parse(JSON.stringify(tempSimulationScenario));
                                tempSimulationsScenario.push(insertSimulation);
                              }

                              if ( !tiresLimitedNo && tiresLimitedYes) {
                                if(thirdLineTiresValuesMultiScenario.some(x => x.checked)) {
                                  thirdLineTiresValuesMultiScenario.forEach(x => {
                                    if(x.checked) {
                                      tempSimulationScenario.tiresQuantity = x.value.toString();
                                      let insertSimulation: Simulation = JSON.parse(JSON.stringify(tempSimulationScenario));
                                      tempSimulationsScenario.push(insertSimulation);
                                    }
                                  })
                                }
                                else {
                                  openAlert({
                                    variant: "info",
                                    text: "Não tem nenhuma quantidade de pneus seleccionada",
                                    title: "Atenção",
                                  });
                                }
                              }
                            } else {
                                tempSimulationScenario.tires = false;
                                tempSimulationScenario.tiresQuantity = '0';
                                let insertSimulation: Simulation = JSON.parse(JSON.stringify(tempSimulationScenario));
                                tempSimulationsScenario.push(insertSimulation);
                            }

                          }
                          return replacementVehicle;
                        });
                      }

                      return insuranceYesNo;
                    })
                  //}
              //});
                    }
                }
              }
            }

            return annualKm;
          });
        }
        return deadline;
      })

      if(tempSimulationsScenario.length > 0) {
        //Remove first char "|"
        tempHeaderSimulationScenario.deadline =  tempHeaderSimulationScenario.deadline.substring(1);
        tempHeaderSimulationScenario.miles = tempHeaderSimulationScenario.miles.substring(1);
        tempHeaderSimulationScenario.tire =   tempHeaderSimulationScenario.tire.substring(1);
        tempHeaderSimulationScenario.commercialConditions = commercialConditionsScenario;
        tempHeaderSimulationScenario.use = useScenario;
        vehiclesService.addFrequentHeaderSimulation(
          tempSimulationsScenario, tempHeaderSimulationScenario,
          frequentTitle, frequentVisibility, currentUser.userId, true
        ).then(async () => {
          getScenariosFrequentSimulations();
        });

        let counter = 0;
        let message = `A(s) viatura(s) seleccionada(s) contém tipo(s) de uso/condições comerciais diferentes da pré-configuração efectuada.<br>Não foi possível aplicar o cenário à(s) viatura(s):`;
        await Promise.all(vehicleDataTable.map(async (vehicle, rowIndex) => {
          Array.from(contractSettingsCheckedVehicles).map(async (selectedVehicleId) => {
            if (selectedVehicleId === vehicle.id.toString()) {
              for (let simuScenario of tempSimulationsScenario) {

                if((vehicle.commercialConditions !== commercialConditionsScenario || vehicle.use !== useScenario) && simuScenario.secureOptions === true ){
                  message += `<br>${vehicle.versionDescription}: ${vehicle.typeofRental} - ${vehicle.commercialConditions}`;

                } else {
                  let simulationIndex = vehicle.simulations.findIndex(sim  => sim.deadline === 0 || sim.miles === 0);
                  if(vehicle.simulations.some(sim  => sim.deadline === 0 || sim.miles === 0 )) {
                    simuScenario.id = vehicle.simulations.length === 0 ? 0 : vehicle.simulations[simulationIndex].id;
                  } else {
                    simuScenario.id = 0;
                  }
                  simuScenario.vehicleId = vehicle.id;
                  simuScenario.id = 0;

                  //Assume os valores da viatura atual em vez dos valores
                  //associados ao modelo guardado no frequente
                  simuScenario.priceexclVAT = vehicle.priceexclVAT;
                  simuScenario.consumersPrice = vehicle.consumersPrice;

                  simuScenario.totalExtraWithoutTax = vehicle.totalExtraWithoutTax;
                  simuScenario.totalExtraWithTax = vehicle.totalExtraWithTax;

                  simuScenario.totalAccessoriesWithout = vehicle.totalAccessoriesWithout;
                  simuScenario.totalAccessoriesWith = vehicle.totalAccessoriesWith;

                  simuScenario.discountWithoutTax = vehicle.discountWithoutTax;
                  simuScenario.discountWithTax = vehicle.discountWithTax;
                  simuScenario.discountWithTaxPercentage = vehicle.discountWithTaxPercentage;
                  simuScenario.discountWithTaxTotal = vehicle.discountWithTaxTotal;

                  simuScenario.proformaInvoicePriceWithoutTax = vehicle.proformaInvoicePriceWithoutTax;
                  simuScenario.proformaInvoicePriceWithTax = vehicle.proformaInvoicePriceWithTax;

                  simuScenario.campaignWithoutTax = vehicle.campaignWithoutTax;
                  simuScenario.campaignWithTax = vehicle.campaignWithTax;
                  simuScenario.campaignWithTaxPercentage = vehicle.campaignWithTaxPercentage;
                  simuScenario.campaignWithTaxTotal = vehicle.campaignWithTaxTotal;

                  simuScenario.rappelWithoutTax = vehicle.rappelWithoutTax;
                  simuScenario.rappelWithTax = vehicle.rappelWithTax;
                  simuScenario.rappelWithTaxPercentage = vehicle.rappelWithTaxPercentage;
                  simuScenario.rappelWithTaxTotal = vehicle.rappelWithTaxTotal;

                  simuScenario.extraRappel = vehicle.extraRappel;

                  simuScenario.businessValueWithoutTax = vehicle.businessValueWithoutTax;
                  simuScenario.businessValueWithTax = vehicle.businessValueWithTax;
                  simuScenario.quantity = vehicle.quantity;
                  simuScenario.iucCost = vehicle.iuc;
                  //fim validações


                  // faz sentido aplicar a sugestão de pneus aqui uma vez que já temos a quantidade a vir da modal de multi cenarios????
                  // se for para aplicar a parte da sugestão no código este código deve substituir o alerta a dizer que não tem quantidades seleccionadas
                  // no lugar do openAlert a dizer que não tem quantidades seleccionadas

                  // if(simuScenario.tires === true && simuScenario.tiresQuantity !== '0'){
                  // let round = convertNumber((convertNumber(simuScenario.miles)/settings.Data.Vehicle_TyresValueCalc)*vehicle.number_of_wheels);
                  //   let tiresQuant=0;
                  //   if(Math.ceil(round)%2 === 0) {
                  //     tiresQuant=Math.ceil(round);
                  //   } else {
                  //     tiresQuant=Math.floor(round);
                  //   }
                  //   simuScenario.tiresQuantity = tiresQuant.toString();
                  // }

                  let rentalSettings = rentalTypeSettings.find((element) => { return element.value === vehicle.typeofRental });
                  //simuScenario.vehicleReplacementOptions = rentalSettings.vs;
                  simuScenario.maintenance = rentalSettings.maintenance;
                  // simuScenario.tires = rentalSettings.tyres;
                  //simuScenario.secureOptions = rentalSettings.insurance;
                  let insertSimulation: Simulation = JSON.parse(JSON.stringify(simuScenario));
                  if(vehicle.simulations.some(sim  => sim.deadline === 0 || sim.miles === 0 )) {
                    vehicle.simulations[simulationIndex]=insertSimulation;
                  } else {
                    vehicle.simulations.push(insertSimulation);
                  }


                  setVehicleDataTable((rows) =>
                    rows.map((row, index) => {
                      if (index === rowIndex){
                        row.simulations[vehicle.simulations.length-1] = vehicle.simulations[vehicle.simulations.length-1]
                        return row;
                      }
                      return row;
                    })
                  )
                }
              }

            }
          })
        })).then( () => {
          if(counter < contractSettingsCheckedVehicles.size){
            openAlert({
              variant: "info",
              text: message,
              title: "Aviso",
              autoHideDuration: 3000,
            });
          } else {
            openAlert({
              variant: "success",
              text: "Cenário aplicado com sucesso!",
              title: "Sucesso",
              autoHideDuration: 3000,
            });
          }
          resetScenarioModal();
        });
      }
    }
  }, [
    deadlineValues,
    annualKmValues,
    simulationScenario,
    vehicleDataTable,
    contractSettingsCheckedVehicles,
    currentUser.userId,
    frequentTitle,
    frequentVisibility,
    getScenariosFrequentSimulations,
    rentalTypeSettings,
    resetScenarioModal,
    fuelYes,  viaVerdeYes,commercialConditionsScenario,useScenario,
    insurances, replacementVehicles,
    thirdLineTiresValuesMultiScenario,
    tiresLimitedYes,tiresLimitedNo, tiresYes,tiresNo,
    modalComplementaryProductsMemo
  ]);

  const handleAddDeadlineKmClick: ButtonProps["onClick"] = useCallback((e: any) => {
    setDeadlineValues(currentValues => {
      let values = {...currentValues};
      if(values.find(x => x.value === deadline.toString())){
        openAlert({
          variant: "error",
          text: "O valor "+ deadline+" já existe na lista de pazos!",
          title: "Atenção",
        })
      } else {
        if(convertNumber(deadline.toString()) > 0) {
          values.push({
            value: deadline.toString(),
            onChange: handleDeadLineCheckedChange(values.length, true),
            checked: false
          });
        } else {
            openAlert({
            variant: "error",
            text: "O valor inserido deve ser um numero e maior que 0",
            title: "Atenção",
          });
        }
      }

      return values
    })
    setDeadline(0);
  }, [ deadline ]);

  const handleDeadLineChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDeadline(convertNumber(e.target.value))
  },[]);

  const handleContractSettingsGroupEditDeadLineChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setContractSettingsGroupEdit_DeadlineValue(e.target.value)
  },[]);

  const handleContractSettingsGroupEditAnnualKmChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setContractSettingsGroupEdit_AnnualKmValue(e.target.value)
  },[]);

  const handleContractSettingsGroupEditTiresQuantityChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setContractSettingsGroupEdit_TiresValue(e.target.value)
  },[]);

  const handleContractSettingsGroupEditCancelClick = useCallback(() => {
    setContractSettingsGroupEdit_DeadlineValue('');
    setDeadlineValues(prevState => prevState.map(item => ({ ...item, checked: false })));
    setContractSettingsGroupEdit_AnnualKmValue('');
    setAnnualKmValues(prevState => prevState.map(item => ({ ...item, checked: false })));
    setContractSettingsGroupEditTiresNo(true);
    setContractSettingsGroupEditTiresYes(false);
    setContractSettingsGroupEditTiresLimited(false);
    setThirdLineTiresValuesMultiScenario(prevState => prevState.map(item => ({ ...item, checked: false })));

    setCommercialConditionsScenario('');
  },[]);

  const handleAddAnnualKmClick: ButtonProps["onClick"] =useCallback((e: any) => {
    if(annualKmValues.findIndex(x=>x.value===annualKm)<0){
      if( convertNumber(annualKm)>0){
        annualKmValues.push({
          value: annualKm,
          onChange: handleAnnualKmCheckedChange(annualKmValues.length, true),
          checked: false
        });
        setAnnualKmValues(annualKmValues);
      } else {
        openAlert({
          variant: "error",
          text: "O valor inserido deve ser um numero e maior que 0",
          title: "Atenção",
        });
      }
    } else {
      openAlert({
        variant: "error",
        text: "O valor "+ annualKm+" já existe na lista de Kms!",
        title: "Atenção",
      });
    }
    setAnnualKm(0);
  },[ annualKmValues, annualKm ]);

  const handleAnnualKmChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAnnualKm(convertNumber(e.target.value))
  },[]);

  const handleAddThirdLineTireClick: ButtonProps["onClick"] = useCallback((e: any) => {
    if( thirdLineTiresValuesMultiScenario.findIndex(x => x.value === thirdLineTire) < 0) {
      if(convertNumber(thirdLineTire) > 0) {
        setThirdLineTiresValuesMultiScenario(rows => {
          rows.push({
            value:thirdLineTire,
            label:thirdLineTire.toString(),
            onChange: handleThirdLineTiresCheckedChange(rows.length, true),
            checked: true
          })
          return rows;
        })
        setThirdLineTiresValues(rows => {
          rows.push({
            value:thirdLineTire.toString(),
            label:thirdLineTire.toString(),
          })
          return rows;
        });
      } else {
        openAlert({
          variant: "error",
          text: "O valor inserido tem de ser maior que 0",
          title: "Atenção",
        });
      }
    } else {
      openAlert({
        variant: "error",
        text: "O valor "+ thirdLineTire+" já existe na lista de pneus!",
        title: "Atenção",
      });
    }
    setThirdLineTire(0);
  }, [
    thirdLineTiresValuesMultiScenario,
    thirdLineTire,
    handleThirdLineTiresCheckedChange,
   ]);

  const handleThirdLineTireChange = useCallback(( e: ChangeEvent<HTMLInputElement> ) => {
    setThirdLineTire(convertNumber(e.target.value))
  }, []);

  const initGroupEditionDropDowns = useCallback(() => {
    setDeadlineValues(
      [...deadlineValues.map((x, index: number) => (
        {...x, onChange: handleDeadLineCheckedChange(index, false)}
      ))]
    );
    setAnnualKmValues(
      [...annualKmValues.map((x, index: number) => (
        {...x, onChange: handleAnnualKmCheckedChange(index, false)}
      ))]
    );
    setThirdLineTiresValuesMultiScenario(
      [...thirdLineTiresValuesMultiScenario.map((x, index: number) => (
        {...x, onChange: handleThirdLineTiresCheckedChange(index, false)}
      ))]
    );

    setContractSettingsGroupEditTiresNo(true);
    setContractSettingsGroupEditTiresYes(false);
    setInsurances([true, false]);
    setIsInsuranceConditionsOpen(false);
    setReplacementVehicles([true, false]);
    setIsReplacementVehicleConditionsOpen(false);
    setFuelNo(true);
    setFuelYes(false);
    setViaVerdeNo(true);
    setViaVerdeYes(false);


    let typeofRental="";
    let commercialConditions="";
    let typeofIncome="";

    if(contractSelectedRows.length > 0 && vehicleDataTable.length > 0){
      const firstV = vehicleDataTable.find((_, index) => index === contractSelectedRows[0].vehicleIndex);
      typeofRental = firstV?.typeofRental ? firstV?.typeofRental : "";
      commercialConditions = firstV?.commercialConditions ? firstV?.commercialConditions : "";
      typeofIncome = firstV?.typeofIncome ? firstV?.typeofIncome : "";
      setUseScenario(firstV?.use ? firstV?.use : "")
    } else {
      typeofRental = currentUser?.typeofRental ? currentUser?.typeofRental : "";
      commercialConditions = currentUser?.commercialConditions ? currentUser?.commercialConditions : "";
      typeofIncome = currentUser?.typeofIncome ? currentUser.typeofIncome : "";
    }

    let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
    let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.commercialConditions === commercialConditions)
    setCommercialConditionsScenario(commercialConditions);
    let vehicleTemp: IVehicle = {
      id: 0,
      proposalId: proposal.id,
      leaseQuoteNo:"",
      makeId: "",
      versionId: "",
      modelId: "",
      licensePlate: "",
      priceexclVAT: 0,
      consumersPrice: 0,
      pvp: 0,
      recycling_charge: 0,
      luxury_tax: 0,
      transport_x0026_immatriculation_cost: 0,
      status: "",
      use: "",
      numberofDoors: 0,
      fuelId: "",
      fuelType: "",
      euroTaxInternationalSegmt: "",
      quantity_of_Seats: 0,
      lengthmm: 0,
      widthmm: 0,
      heightmm: 0,
      trunkVolumel: 0,
      wheelBasemm: 0,
      trainFrontWheelsmm: 0,
      trainRearWheelsmm: 0,
      compressionratio: 0,
      bore: 0,
      powerHP: 0,
      powerkW: 0,
      cO2DischargeDecF: 0,
      engineCc: 0,
      torqueNm: 0,
      fuelTankCapacityl: 0,

      maxspeedkmh: 0,
      acceleration0100kmh: 0,
      mileage: 0,
      modelYear: "",
      recordDate: "",
      accessories: [],
      extraStandard: [],
      simulations: [],
      no: "",
      lotId: "",
      totalExtraWithoutTax: 0,
      totalAccessoriesWithout: 0,
      proformaInvoicePriceWithoutTax: 0,
      totalExtraWithTax: 0,
      totalAccessoriesWith: 0,
      proformaInvoicePriceWithTax: 0,
      discountWithTaxTotal: 0,
      rappelWithTaxTotal: 0,
      campaignWithTaxTotal: 0,
      businessValueWithoutTax: 0,
      businessValueWithTax: 0,
      discountWithTax: 0,
      discountWithoutTax: 0,
      discountWithTaxPercentage: 0,
      campaignWithTax: 0,
      campaignWithoutTax: 0,
      campaignWithTaxPercentage: 0,
      rappelWithTax: 0,
      rappelWithTaxPercentage: 0,
      rappelWithoutTax: 0,
      extraRappel: 0,
      extraOptionsSearch: [],
      extraOptionSelect: 0,
      versionDescription: "",
      caracteristics: "",
      quantity: 1,
      typeofRental: typeofRental,
      commercialConditions: commercialConditions,
      typeofIncome: typeofIncome,
      oilChange: 0,
      bPM: 0,
      luxury_tax_vat: 0,
      vat: 0,
      technMaxQuantityKm: 0,
      driverId: "", //FIXME susbtituir por nome do contato do user quando este estiver definido no user
      renew: false,
      hidden: false,
      commercialOptions: [],
      modelDescription: "",
      iuc:0,
      number_of_wheels:0,
      vehicleStatus: {
        color: "warning",
        label: "evaluating",
      },
      driverName: "",
      group_Price_Map:"",
    };

    vehicleTemp.simulations.push({
      id: 0,
      vehicleId: 0,
      no: "",
      deadline: 0,
      miles: 0,
      tires: false,
      mostUsed:0,
      tiresQuantity: "",
      maintenance: false,
      maintenanceDescription: "",
      iuc: 0,
      fuelCard: false,
      petrolPump: false,
      viaVerde: false,
      entityViaVerde: "",
      fuelDescriptiom: "",
      runFlaat: false,

      // opções de seguro
      secureOptions: false,
      insurance: '',
      insurancePackageOptions: insuranceFrameworkContractOption ? insuranceFrameworkContractOption.insurancePackageOptions.map(x => x.insurancePackageOption) : [],
      vehicleReplacementOptions: false,

      vehicleDamageOptions: [],
      occupiersOptions: [],
      roadAssistanceOptions: [],
      liabilityOptions: [],
      isolatedGlassBreakOptions: [],

      // pre carregamento das opções?
      // é necessário preé carregar?
      // se sim é preciso utilizar uma opção de seguro que ainda não foi pre seleccionada

      // secureOptions: insurancePackageOption ? insurancePackageOption?.secureOptions : false,
      // vehicleDamageOptions = insurancePackageOption ? insurancePackageOption?.vehicleDamageOptions : [];
      // occupiersOptions = insurancePackageOption ? insurancePackageOption?.occupiersOptions : [];
      // roadAssistanceOptions = insurancePackageOption ? insurancePackageOption?.roadAssistanceOptions : [];
      // liabilityOptions = insurancePackageOption ? insurancePackageOption?.liabilityOptions : [];
      // isolatedGlassBreakOptions = insurancePackageOption ? insurancePackageOption?.isolatedGlassBreakOptions: [];

      ownDamage: "",
      occupants: "",
      travelAssistance: "",
      responsibility: "",
      glassBreakage: "",

      //opções de veiculo de substituição
      vsAccidentVehicleType: "",
      vsAssistanceVehicleType: "",
      vsOverhaulVehicleType: "",
      vsTheftVehicleType: "",
      vehicleType: "",

      vsAssistanceVehicle: "",
      vsAccidentVehicle: "",
      vsOverhaulVehicle: "",
      vsTheftVehicle: "",

      vsAccidentVehicleTypeOptions: [],
      vsAssistanceVehicleTypeOptions: [],
      vsOverhaulVehicleTypeOptions: [],
      vsTheftVehicleTypeOptions: [],
      vehicleTypeOptions: [],

      vsAccidentVehicleOptions: [],
      vsAssistanceVehicleOptions: [],
      vsOverhaulVehicleOptions: [],
      vsTheftVehicleOptions: [],

      fuelOptions: [],
      viaVerdeOptions: [],

      // é necessário preé carregar?
      // se sim é preciso utilizar uma opção de seguro que ainda não foi pre seleccionada
      //  simulation.vsAccidentVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsAccidentVehicleTypeOptions : [];
      //  simulation.vsAssistanceVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsAssistanceVehicleTypeOptions : [];
      //  simulation.vsOverhaulVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsOverhaulVehicleTypeOptions : [];
      //  simulation.vsTheftVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsTheftVehicleTypeOptions : [];
      //  simulation.vehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vehicleTypeOptions : [];

      //  simulation.vsAccidentVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsAccidentVehicleOptions : [];
      //  simulation.vsAssistanceVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsAssistanceVehicleOptions : [];
      //  simulation.vsOverhaulVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsOverhaulVehicleOptions : [];
      //  simulation.vsTheftVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsTheftVehicleOptions : [];

      //  simulation.fuelOptions = insurancePackageOption ? insurancePackageOption?.fuelOptions : [];
      //  simulation.viaVerdeOptions = insurancePackageOption ? insurancePackageOption?.viaVerdeOptions : [];

      //campos das cotações
      priceexclVAT: vehicleTemp.priceexclVAT,
      consumersPrice: vehicleTemp.consumersPrice,
      totalExtraWithoutTax: vehicleTemp.totalExtraWithoutTax,
      totalExtraWithTax: vehicleTemp.totalExtraWithTax,
      totalAccessoriesWithout: vehicleTemp.totalAccessoriesWithout,
      totalAccessoriesWith: vehicleTemp.totalAccessoriesWith,
      discountWithoutTax: vehicleTemp.discountWithoutTax,
      discountWithTax: vehicleTemp.discountWithTax,
      discountWithTaxPercentage: vehicleTemp.discountWithTaxPercentage,
      discountWithTaxTotal: vehicleTemp.discountWithTaxTotal,
      proformaInvoicePriceWithoutTax: vehicleTemp.proformaInvoicePriceWithoutTax,
      proformaInvoicePriceWithTax: vehicleTemp.proformaInvoicePriceWithTax,
      campaignWithoutTax: vehicleTemp.campaignWithoutTax,
      campaignWithTax: vehicleTemp.campaignWithTax,
      campaignWithTaxPercentage: vehicleTemp.campaignWithTaxPercentage,
      campaignWithTaxTotal: vehicleTemp.campaignWithTaxTotal,
      rappelWithoutTax: vehicleTemp.rappelWithTaxTotal,
      rappelWithTax: vehicleTemp.rappelWithTax,
      rappelWithTaxPercentage: vehicleTemp.rappelWithTaxPercentage,
      rappelWithTaxTotal: vehicleTemp.rappelWithTaxTotal,
      extraRappel: vehicleTemp.extraRappel,
      businessValueWithoutTax: vehicleTemp.businessValueWithoutTax,
      businessValueWithTax: vehicleTemp.businessValueWithTax,
      //tipo de contrato
      quantity: vehicleTemp.quantity,
      // quotation calculated fields
      baseIncomeWithoutTax: 0,
      incomeWithoutTax: 0,
      incomeWithTax: 0,
      increaseVR: 0,
      margin: 0,
      realVR: 0,
      vrEurotax: 0,
      vrTable: 0,
      baseVRTable:0,

      amortization: 0,
      interests: 0,
      //validar se estes 4 campos estão duplicados
      tyresCost: 0,
      maintenanceCost: 0,
      iucCost:vehicleTemp.iuc,
      insuranceCost: 0,
      vsCost: 0,

      finalTax: 0,
      estimatedMargin: 0,
      monthlyTCOWithTax: 0,
      monthlyTCOWithoutTax: 0,
      additionalKmsWithTax: 0,
      additionalKmsWithoutTax: 0,
      collateral: 0,
      fee: 0,
      initialEntry: 0,
      travelledKmsWithTax: 0,
      travelledKmsWithoutTax: 0,
      proposeToCustomer: false,
      title: "",
      visibility: 0,
      complementaryProducts: [],
      maximum_Kilometers:0,
      contractExpenses: 0,
      commissionValue: 0,
      commissionCode: ""
    });
    setSimulationScenario(vehicleTemp.simulations[0]);
  },[deadlineValues, annualKmValues, thirdLineTiresValuesMultiScenario, contractSelectedRows, vehicleDataTable, insuranceFrameworkContractSet, proposal.id, handleThirdLineTiresCheckedChange, currentUser?.typeofRental, currentUser?.commercialConditions, currentUser.typeofIncome])

  const handleInsuranceVehicleNoChange = useCallback((allowMultiSelection: boolean) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  // const handleInsuranceVehicleNoChange = useCallback((allowMultiSelection: boolean) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (checked) { // onChange
    // if ((e.target as any).checked) { // onClick
      let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
      let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.commercialConditions === commercialConditionsScenario)

      setSimulationScenario((simulation) => {
        simulation.secureOptions = false;

        let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions[0]
        simulation.insurancePackageOptions = insuranceFrameworkContractOption ? insuranceFrameworkContractOption.insurancePackageOptions.map(x => x.insurancePackageOption) : [];

        simulation.vehicleDamageOptions = insurancePackageOption ? insurancePackageOption?.vehicleDamageOptions : [];
        simulation.occupiersOptions = insurancePackageOption ? insurancePackageOption?.occupiersOptions : [];
        simulation.roadAssistanceOptions = insurancePackageOption ? insurancePackageOption?.roadAssistanceOptions : [];
        simulation.liabilityOptions = insurancePackageOption ? insurancePackageOption?.liabilityOptions : [];
        simulation.isolatedGlassBreakOptions = insurancePackageOption ? insurancePackageOption?.isolatedGlassBreakOptions: [];
        // validar se o yes está checkado
        if(!insurances[1]){
          simulation.insurance = "";
          simulation.ownDamage = "";
          simulation.occupants = "";
          simulation.travelAssistance = "";
          simulation.responsibility = "";
          simulation.glassBreakage = "";
        }

        return simulation;
      });
    }

    setInsurances((rows)=>{
      return rows.map((row,index) => {
        if(index === 0){
          return checked; // onChange
          // return (e.target as any).checked; // onClick
        } else {
          if(!allowMultiSelection){
            setIsInsuranceConditionsOpen(false);
            row = false;
          } else {
            setIsInsuranceConditionsOpen(value => row)
          }
          return row;
        }
      });
    });
  }, [
    commercialConditionsScenario,
    insuranceFrameworkContractSet,
    insurances
  ]);

  const handleInsuranceVehicleYesChange = useCallback((allowMultiSelection: boolean) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  // const handleInsuranceVehicleYesChange = useCallback((allowMultiSelection: boolean) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (checked === false ) { // onChange
    // if ((e.target as any).checked === false ) { // onClick
      setSimulationScenario((simulation) => {
        setUseScenario("");
        simulation.ownDamage="";
        simulation.occupants="";
        simulation.travelAssistance="";
        simulation.responsibility="";
        simulation.glassBreakage="";
        simulation.insurance="";
        return simulation;
      });
      setIsInsuranceConditionsOpen(false);
    } else {
      let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
      let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.commercialConditions === commercialConditionsScenario)

      setSimulationScenario((simulation) => {
        simulation.secureOptions = checked; // onChange
        // simulation.secureOptions = (e.target as any).checked; // onClick
          //if(checked) {
          //  colocar aqui a logica de carregamento do seguro default
          // }
        let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions[0]
        simulation.insurance = insurancePackageOption ? insurancePackageOption?.insurance : '';
        simulation.insurancePackageOptions = insuranceFrameworkContractOption ? insuranceFrameworkContractOption.insurancePackageOptions.map(x => x.insurancePackageOption) : [];
        simulation.vehicleReplacementOptions = false;

        simulation.vehicleDamageOptions = insurancePackageOption ? insurancePackageOption?.vehicleDamageOptions : [];
        simulation.occupiersOptions = insurancePackageOption ? insurancePackageOption?.occupiersOptions : [];
        simulation.roadAssistanceOptions = insurancePackageOption ? insurancePackageOption?.roadAssistanceOptions : [];
        simulation.liabilityOptions = insurancePackageOption ? insurancePackageOption?.liabilityOptions : [];
        simulation.isolatedGlassBreakOptions = insurancePackageOption ? insurancePackageOption?.isolatedGlassBreakOptions: [];

        simulation.ownDamage = insurancePackageOption ? insurancePackageOption?.ownDamage : "";
        simulation.occupants = insurancePackageOption ? insurancePackageOption?.occupants : "";
        simulation.travelAssistance = insurancePackageOption ? insurancePackageOption?.travelAssistance : "";
        simulation.responsibility = insurancePackageOption ? insurancePackageOption?.responsibility : "";
        simulation.glassBreakage = insurancePackageOption ? insurancePackageOption?.glassBreakage : "";

        // //opções de veiculo de substituição
        // simulation.vsAccidentVehicleType = "";
        // simulation.vsAssistanceVehicleType = "";
        // simulation.vsOverhaulVehicleType = "";
        // simulation.vsTheftVehicleType = "";
        // simulation.vehicleType = "";

        // simulation.vsAssistanceVehicle = insurancePackageOption ? insurancePackageOption?.insurance : "";
        // simulation.vsAccidentVehicle = insurancePackageOption ? insurancePackageOption?.insurance : "";
        // simulation.vsOverhaulVehicle = insurancePackageOption ? insurancePackageOption?.insurance : "";
        // simulation.vsTheftVehicle = insurancePackageOption ? insurancePackageOption?.insurance : "";

        // simulation.vsAccidentVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsAccidentVehicleTypeOptions : [];
        // simulation.vsAssistanceVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsAssistanceVehicleTypeOptions : [];
        // simulation.vsOverhaulVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsOverhaulVehicleTypeOptions : [];
        // simulation.vsTheftVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsTheftVehicleTypeOptions : [];
        // simulation.vehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vehicleTypeOptions : [];

        // simulation.vsAccidentVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsAccidentVehicleOptions : [];
        // simulation.vsAssistanceVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsAssistanceVehicleOptions : [];
        // simulation.vsOverhaulVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsOverhaulVehicleOptions : [];
        // simulation.vsTheftVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsTheftVehicleOptions : [];

        // simulation.fuelOptions = insurancePackageOption ? insurancePackageOption?.fuelOptions : [];
        // simulation.viaVerdeOptions = insurancePackageOption ? insurancePackageOption?.viaVerdeOptions : [];

        return simulation;
      });

      if(!allowMultiSelection){
        setIsInsuranceConditionsOpen(true);
      }
    }
    setInsurances((rows) => {
      return rows.map((row,index) => {
        if(index === 1){
          setIsInsuranceConditionsOpen(checked);
          return checked; // onChange
          // return (e.target as any).checked; // onClick
        } else {
          return allowMultiSelection ? row : false;
        }
      });
    });
  }, [commercialConditionsScenario, insuranceFrameworkContractSet]);

  const handleReplacementVehicleNoChange = useCallback((allowMultiSelection: boolean) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
      let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.commercialConditions === commercialConditionsScenario)

      setSimulationScenario((simulation) => {
        let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions[0]
        simulation.vehicleReplacementOptions = false;

        //validar se o yes está checkado
        if(!replacementVehicles[1]) {
          //opções de veiculo de substituição
          simulation.vsAccidentVehicleType = "";
          simulation.vsAssistanceVehicleType = "";
          simulation.vsOverhaulVehicleType = "";
          simulation.vsTheftVehicleType = "";
          simulation.vehicleType = "";

          simulation.vsAssistanceVehicle = "";
          simulation.vsAccidentVehicle = "";
          simulation.vsOverhaulVehicle = "";
          simulation.vsTheftVehicle = "";
        }

        // listas de opções
        simulation.vsAccidentVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsAccidentVehicleTypeOptions : [];
        simulation.vsAssistanceVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsAssistanceVehicleTypeOptions : [];
        simulation.vsOverhaulVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsOverhaulVehicleTypeOptions : [];
        simulation.vsTheftVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsTheftVehicleTypeOptions : [];
        simulation.vehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vehicleTypeOptions : [];

        simulation.vsAccidentVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsAccidentVehicleOptions : [];
        simulation.vsAssistanceVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsAssistanceVehicleOptions : [];
        simulation.vsOverhaulVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsOverhaulVehicleOptions : [];
        simulation.vsTheftVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsTheftVehicleOptions : [];

        simulation.fuelOptions = insurancePackageOption ? insurancePackageOption?.fuelOptions : [];
        simulation.viaVerdeOptions = insurancePackageOption ? insurancePackageOption?.viaVerdeOptions : [];

        return simulation;
      });

    }

    setReplacementVehicles((rows)=>{
      return rows.map((row,index) => {
        if(index === 0){
          return checked;
        } else {
          if(!allowMultiSelection){
            setIsReplacementVehicleConditionsOpen(false);
            row = false;
          } else {
            setIsReplacementVehicleConditionsOpen(value => row)
          }

          return row;
        }
      });
    });
  }, [commercialConditionsScenario, insuranceFrameworkContractSet,replacementVehicles]);

  const handleReplacementVehicleYesChange = useCallback((allowMultiSelection: boolean) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked === false ) {
      setSimulationScenario((simulation) => {
        simulation.vsTheftVehicleType= "";
        simulation.vsTheftVehicle= "";
        simulation.vsAccidentVehicleType= "";
        simulation.vsAccidentVehicle= "";
        simulation.vsAssistanceVehicleType= "";
        simulation.vsAssistanceVehicle= "";
        simulation.vsOverhaulVehicleType= "";
        simulation.vsOverhaulVehicle= "";
        simulation.vehicleType= "";
        simulation.vehicleReplacementOptions = checked;
        return simulation;
      });
    } else {
      let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
      let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.commercialConditions === commercialConditionsScenario)

      setSimulationScenario((simulation) => {
        let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions[0]
        simulation.vehicleReplacementOptions = checked;

        //opções de veiculo de substituição
        simulation.vsAccidentVehicleType = "";
        simulation.vsAssistanceVehicleType = "";
        simulation.vsOverhaulVehicleType = "";
        simulation.vsTheftVehicleType = "";
        simulation.vehicleType = "";

        simulation.vsAssistanceVehicle = "";
        simulation.vsAccidentVehicle = "";
        simulation.vsOverhaulVehicle = "";
        simulation.vsTheftVehicle = "";

        simulation.vsAccidentVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsAccidentVehicleTypeOptions : [];
        simulation.vsAssistanceVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsAssistanceVehicleTypeOptions : [];
        simulation.vsOverhaulVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsOverhaulVehicleTypeOptions : [];
        simulation.vsTheftVehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vsTheftVehicleTypeOptions : [];
        simulation.vehicleTypeOptions = insurancePackageOption ? insurancePackageOption?.vehicleTypeOptions : [];

        simulation.vsAccidentVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsAccidentVehicleOptions : [];
        simulation.vsAssistanceVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsAssistanceVehicleOptions : [];
        simulation.vsOverhaulVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsOverhaulVehicleOptions : [];
        simulation.vsTheftVehicleOptions = insurancePackageOption ? insurancePackageOption?.vsTheftVehicleOptions : [];

        simulation.fuelOptions = insurancePackageOption ? insurancePackageOption?.fuelOptions : [];
        simulation.viaVerdeOptions = insurancePackageOption ? insurancePackageOption?.viaVerdeOptions : [];

        return simulation;
      });
      if(!allowMultiSelection){
        setIsReplacementVehicleConditionsOpen(true);
      }
    }
    //if(allowMultiSelection) {
      setReplacementVehicles((rows)=>{
        return rows.map((row,index) => {
          if(index === 1){
            setIsReplacementVehicleConditionsOpen(checked);
            return checked;
          } else {
            return allowMultiSelection ? row : false;
          }
        });
      });
    // }
    // else {
    //   setReplacementVehicles([false, true]);
    // }
  }, [commercialConditionsScenario, insuranceFrameworkContractSet]);

  // selecção da checbox Não dos pneus
  const handleTiresNoChange: CheckboxProps["onChange"] = useCallback((e: any,checked:boolean) => {
    setTiresNo(checked);
  }, []);

  // selecção da checbox Não dos pneus
  const handleTiresYesChange: CheckboxProps["onChange"] = useCallback((e: any,checked:boolean) => {
    setTyresYes(checked);
  }, []);

  const handleContractSettingsGroupEditTiresChangeYes: CheckboxProps["onChange"] = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setContractSettingsGroupEditTiresYes(checked);
    setContractSettingsGroupEditTiresNo(!checked);
    setSimulationScenario({...simulationScenario, tires: checked })
    if(checked) {
      setContractSettingsGroupEditTiresLimited(false);
      setThirdLineTiresValuesMultiScenario(prevState => prevState.map(item => ({ ...item, checked: false })));
    }
  }, [simulationScenario]);

  const handleContractSettingsGroupEditTiresChangeNo: CheckboxProps["onChange"] = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setContractSettingsGroupEditTiresNo(checked);
    setContractSettingsGroupEditTiresYes(!checked);
    setSimulationScenario({...simulationScenario, tires: checked })
  }, [simulationScenario]);

  const handleContractSettingsGroupEditTiresLimitedChange: CheckboxProps["onChange"] = useCallback((e: any,checked:boolean) => {
    if(e.target.value === 'non-limited') {
      setContractSettingsGroupEditTiresLimited(false);
      setThirdLineTiresValuesMultiScenario(prevState => prevState.map(item => ({ ...item, checked: false })));
      setSimulationScenario({...simulationScenario, tiresQuantity: '0' })
    } else if(e.target.value === 'limited') {
      setContractSettingsGroupEditTiresLimited(true);
    } else {
      console.log("Opção não encontrada");
    }
  }, [simulationScenario]);

  // selecção da checbox Não do número limite de pneus
  const handleTiresLimitedNoChange: CheckboxProps["onChange"] = useCallback((e: any,checked:boolean) => {
    setTiresLimitedNo(checked);
  }, []);

  // selecção da checbox Sim do número limite de pneus
  const handleTiresLimitedYesChange: CheckboxProps["onChange"] = useCallback((e: any,checked:boolean) => {
    setTiresLimitedYes(checked);

    // // funcionalidade de sugestão de pneus desativada até obter confirmação do cliente que
    // // aqui tambem é para ativar a funcionalidade
    // // na eventualidade dereativar a funcionalidade falta atualizar as simulações e as simulações frequentes
    // // com as respetivas quantidades de pneus

    // if(checked) {
    //   if(contractSelectedRows.length === 0)
    //   {
    //     openAlert({
    //       variant: "info",
    //       text: "Não tem nenhuma viatura seleccionada para efetuar a sugestão de pneus! Seleccione uma viatura antes de continuar",
    //       title: "Atenção",
    //       autoHideDuration: 5000
    //     });
    //   }
    //   let selectedKms: {value: number;checkProps: CheckboxProps; }[] = annualKmValues.filter( value => value.checkProps.checked === true )
    //   let selectedDeadlines: {value: number;checkProps: CheckboxProps; }[] = deadlineValues.filter( value => value.checkProps.checked === true )
    //   if(selectedDeadlines.length > 0 && selectedKms.length > 0){
    //     selectedDeadlines.forEach(deadline => {
    //       selectedKms.forEach(kms => {
    //         let totalKms = kms.value * (deadline.value/12)
    //         if (contractSelectedRows.length > 0) {
    //           contractSelectedRows.forEach(selected => {
    //             let wheels = vehicleDataTable[selected.vehicleIndex].number_of_wheels;
    //             let round = convertNumber((convertNumber(totalKms)/settings.Data.Vehicle_TyresValueCalc)*wheels);
    //             let tiresQuant=0;
    //             if(Math.ceil(round)%2 === 0) {
    //               tiresQuant = Math.ceil(round);
    //             } else {
    //               tiresQuant = Math.floor(round);
    //             }
    //             if ( thirdLineTiresValuesMultiScenario.find(x => x.value === Number(tiresQuant)) ){
    //               setThirdLineTiresValuesMultiScenario((rows) => {
    //                 rows.forEach(row => {
    //                   if (row.value === Number(tiresQuant)) {
    //                     row.checked = true
    //                   }
    //                   return row;
    //                 })
    //                 return rows
    //               })
    //             }
    //           })
    //         }
    //       })
    //     });
    //   }
    // }
  }, [
    // // dependencias desativadas até confirmar que é para reativar a funcionalidade
    // annualKmValues, contractSelectedRows,
    // deadlineValues, settings.Data.Vehicle_TyresValueCalc,
    // thirdLineTiresValuesMultiScenario,
    // vehicleDataTable
  ]);


  // selecção da checbox Não do combustivel
  const handleFuelNoChange = useCallback((allowMultiSelection: boolean) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFuelNo(checked);
    if(!allowMultiSelection) {
      setFuelYes(false);
    }
  }, []);

  // selecção da checkbox Sim do combustível
  const handleFuelYesChange = useCallback((allowMultiSelection: boolean) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked === false ) {
      setSimulationScenario((simulation)=> {
        simulation.fuelDescriptiom="";
        return simulation;
      });
    } else {
      setSimulationScenario((x) => {
        let simulation = {...x}
        let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
        let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.commercialConditions === commercialConditionsScenario)

        // carrega as opções default
        // let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions[0]

        // carrega as opções correspondentes ao seguro. se não encontrar as opções não preenche as opções
        let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions.find(opt => opt.insurance === simulation.insurance )
        if(insurancePackageOption) {
          simulation.fuelOptions = insurancePackageOption.fuelOptions;
        }
        return simulation
      })
    }
    setFuelYes(checked);
    if(!allowMultiSelection) {
      setFuelNo(false);
    }
  }, [commercialConditionsScenario, insuranceFrameworkContractSet]);

  // selecção da checkbox Não da via verde
  const handleViaVerdeNoChange = useCallback((allowMultiSelection: boolean) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setViaVerdeNo(checked);
    if(!allowMultiSelection) {
      setViaVerdeYes(false);
    }
  }, []);

  const handleViaVerdeYesChange = useCallback((allowMultiSelection: boolean) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked === false ) {
      setSimulationScenario((simulation) => {
          simulation.entityViaVerde="";
          return simulation;
      });
    } else {
      setSimulationScenario((x) => {
        let simulation = {...x}
        let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
        let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.commercialConditions === commercialConditionsScenario)

        // carrega as opções default
        // let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions[0]

        // carrega as opções correspondentes ao seguro. se não encontrar as opções não preenche as opções
        let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions.find(opt => opt.insurance === simulation.insurance )
        if(insurancePackageOption) {
          simulation.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;
        }
        return simulation
      })
    }
    setViaVerdeYes(checked);
    if(!allowMultiSelection) {
      setViaVerdeNo(false);
    }
  }, [commercialConditionsScenario, insuranceFrameworkContractSet]);

  const handleVisibleChange: RadioGroupProps["onChange"] = useCallback((e: any) => {
    setVisibleRadioGroup(convertNumber(e.target.value));
  }, []);


  const handleSimulationsCheckboxChange = useCallback(
    (vehicleIndex: number, simulationIndex: number, isCheckedTrue: boolean) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setChangedProposal(true);
      setVehicleDataTable((rows) =>
        rows.map((row, index) => {
          if (index !== vehicleIndex) {
            return row;
          }
          let vehicle = row;
          let simulationSelect = vehicle.simulations[simulationIndex];
          let checked = false;

          if (isCheckedTrue === false && Boolean(e.target.value) === true)
            checked = false;
          else if (
            isCheckedTrue === true &&
            Boolean(e.target.value) === true
          ) {
            checked = true;
          } else checked = Boolean(e.target.value);

          if (
            checked === true &&
            e.target.name === "secureOptions" &&
            vehicle.simulations[simulationIndex].insurance === ""
          ) {
            openAlert({
              variant: "error",
              text: "É necessário selecionar um tipo de seguro!",
              title: "Alerta",
            });
              vehicle.simulations[simulationIndex] = {
              ...simulationSelect,
              [e.target.name]: false,
            };
          }

          else if (checked === false && e.target.name === "secureOptions") {
            vehicle.simulations[simulationIndex] = {
              ...simulationSelect,
              ownDamage: "",
              occupants: "",
              travelAssistance: "",
              responsibility: "",
              glassBreakage: "",
              [e.target.name]: checked,
              insurance: checked? row.insurance:"",
            };
          } else if (
            checked === false &&
            e.target.name === "vehicleReplacementOptions"
          ) {
            vehicle.simulations[simulationIndex] = {
              ...simulationSelect,
              vsTheftVehicleType: "",
              vsTheftVehicle: "",
              vsAccidentVehicleType: "",
              vsAccidentVehicle: "",
              vsAssistanceVehicleType: "",
              vsAssistanceVehicle: "",
              vsOverhaulVehicleType: "",
              vsOverhaulVehicle: "",
              vehicleType: "",
              [e.target.name]: checked,
            };
          } else if (checked === false && e.target.name === "fuelCard") {
            vehicle.simulations[simulationIndex] = {
              ...simulationSelect,
              fuelDescriptiom: "",
              [e.target.name]: checked,
            };
          } else if (checked === false && e.target.name === "viaVerde") {
            vehicle.simulations[simulationIndex] = {
              ...simulationSelect,
              entityViaVerde: "",
              [e.target.name]: checked,
            };
          } else if (e.target.name === "tires" && checked === false) {
            vehicle.simulations[simulationIndex] = {
              ...simulationSelect,
              tiresQuantity: "0",
              [e.target.name]: checked,
            };
          } else {
            vehicle.simulations[simulationIndex] = {
              ...simulationSelect,
              [e.target.name]: checked,
            };
          }
          return { ...row, vehicle };
        })
      );
    }, []
  );

  const handleEditVehicleIndexClick = useCallback((index: number) => () => {
    setStep(1);
    isDetailsStep2VehicleOpen[index] = true;
    setIsDetailsStep2VehicleOpen([...isDetailsStep2VehicleOpen]);
  }, [isDetailsStep2VehicleOpen]);

  const handleEditVehicleClick = useCallback((index: number) => {
    setStep(1);
    isDetailsStep2VehicleOpen[index] = true;
    setIsDetailsStep2VehicleOpen([...isDetailsStep2VehicleOpen]);
  }, [isDetailsStep2VehicleOpen]);


  const handleDeleteAttachedFileClick= useCallback((file: any, noteIndex: number, fileIndex: number) => {
    handleAttachment(fileIndex);
    // remove do disco
    let editCommentsFromGetResponse = commentsFromGetResponse;
    let noteAttachments = editCommentsFromGetResponse[noteIndex].attachments;
    let attachmentToRemove = noteAttachments.find((element:any, index:number) => {return  index === fileIndex});
    if (!attachmentToRemove) {
      openAlert({
        variant: "error",
        text: "Ocorreu um erro remover o anexo! Por favor tente novamente. Se o problema persistir, contate o administrador.",
        title: "Atenção",
      });
    }
    else {
      commentsService.removeAttachments(attachmentToRemove['guid']).then(result => {
        if(result) {
          noteAttachments.splice(fileIndex,1);
        }
        setAttachments(noteAttachments)
        let removeFileFromComments = comments;
        removeFileFromComments[noteIndex] = convertToComment(editCommentsFromGetResponse[noteIndex]);
        setComments(removeFileFromComments);
        setResponseComments(editCommentsFromGetResponse);
      });
    }
  },[
    comments,
    commentsFromGetResponse,
    handleAttachment,
    // testInfo
  ]);

  const handleAttachFileNoteChange = useCallback((e: ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.files) {
      let itemsX: ProgressBarProps[] = [];
      handleAttachFiles(e);

      let editComments = commentsFromGetResponse;
      let addProgressBarToComments = comments;
      commentsService.addAttachments(
        proposal.id,
        editComments[index].id,
        e.target.files as unknown as any[],
        options
      ).then((apiResponse) => {
        if (apiResponse !== null && apiResponse !== undefined) {
          setAttachments([...attachments, ...Object.values(apiResponse)]);
          editComments[index].attachments.push(
            ...Object.values(apiResponse)
          );
          setResponseComments(editComments);

          //instantiate progress bar variable
          let progressBarProps: ProgressBarProps = {
            progress: 0,
            title: "",
            label: "",
            deleteIcon: true,
            deleteTooltipText: "Apagar",
          };
          //update progressBar
          setAttachments((rows)=> {
            rows.map((row,indexR) => {
              let bar=editComments[index].attachments.find((pb: { fileName: any; }) => pb.fileName === row["fileName"]);
              progressBarProps = {
                progress:100,
                title: bar.fileName,
                label: "",
                deleteIcon: true,
                deleteTooltipText: "Apagar",
                onDeleteClick: () => {
                  //remove do disco
                  commentsService.removeAttachments(row["guid"]);
                  // let testInfoIndex:number = -1;
                  // itemsX.forEach(i => {
                  //   testInfoIndex = testInfo.findIndex(pb => pb.title === row["fileName"])
                  // })
                  // testInfo.splice(testInfoIndex,1);
                  //remove do GUI
                  var filteredItems = itemsX.filter(
                    (item) => item !== progressBarProps
                  );
                  itemsX = filteredItems;
                  // setNewProgressBar(itemsX);
                  handleAttachment(indexR);
                  setCommentsAttachProgress(itemsX);
                  editComments[index].attachments.splice(indexR,1);
                  let commentsTmp = comments;
                  commentsTmp[index] = convertToComment(editComments[index]);
                  setComments(commentsTmp);

                },
              };
              itemsX[indexR] = progressBarProps;
              return row;
            })
            setCommentsAttachProgress(itemsX);
            // editComments[index].newNotesAttachmentsFilesProgressBarProps = commentsAttachProgress;
            editComments[index].newNotesAttachmentsFilesProgressBarProps = itemsX;
            setResponseComments(editComments);
            //addProgressBarToComments[index].newNotesAttachmentsFilesProgressBarProps = commentsAttachProgress
            addProgressBarToComments[index].newNotesAttachmentsFilesProgressBarProps = itemsX
            setComments(addProgressBarToComments);
            return rows;
          });
          //display progress bar
          // setNewProgressBar(itemsX);

        } else {
          console.log("no attachments uploaded");
          //Notificar utilizador
        }
      });
    }

  },[
    proposal.id,
    comments,
    commentsFromGetResponse,
    attachments,
    options,
    handleAttachment,
    handleAttachFiles,
    // testInfo
  ]);

  const handleAttachFileChange= useCallback((e: ChangeEvent<HTMLInputElement>) =>  {
    if (e.target.files) {
      let attachmentsList: ProgressBarProps[] = [];
      handleAttachFiles(e);
      commentsService.addAttachments(
        proposal.id,
        "0",
        e.target.files as unknown as any[],
        options
      ).then((apiResponse) => {
        if (apiResponse !== null && apiResponse !== undefined) {
          setAttachments([
            ...attachments,
            ...Object.values(apiResponse),
          ]);


          //instantiate progress bar variable
          let progressBarProps: ProgressBarProps = {
            progress: 0,
            title: "",
            label: "",
            deleteIcon: true,
            deleteTooltipText: "Apagar",
          };

          let attachedFiles = [...testInfo];

          //update progressBar
          setAttachments((rows) =>{
            rows.map((row,index) => {
              let bar = attachedFiles[index];
              progressBarProps = {
                progress: 100, //bar.progress, //100 garante que a barra fica completamente carregada caso o progress não esteja a 100%
                title: bar.title,
                label: bar.label,
                deleteIcon: true,
                deleteTooltipText: "Apagar",

                onDeleteClick: () => {

                  commentsService.removeAttachments(row["guid"]);
                  //remove do GUI
                  let testInfoIndex:number = -1;
                  testInfoIndex = attachedFiles.findIndex(pb => pb.title === row["fileName"])

                  if(testInfoIndex !== -1){
                    attachedFiles.splice(testInfoIndex,1);
                    setTestInfo(attachedFiles)
                  }
                  var filteredItems = attachmentsList.filter(
                    (item,indexF) => indexF !==index
                  );
                  attachmentsList = filteredItems;
                  apiResponse.splice(index,1)

                  handleAttachment(index);
                  setNewProgressBar(attachmentsList);
                  setCommentsAttachProgress(attachmentsList);
                },
              };
              attachmentsList[index] = progressBarProps
              return row;
            });
            setNewProgressBar(attachmentsList);
            setCommentsAttachProgress(attachmentsList);
            return rows;
          });

        } else {
          console.log("no attachments uploaded");
          //Notificar utilizador
          openAlert({
            variant: "error",
            text: "Não foi possivel anexar o documento.",
            title: "Aviso",
          });
        }
      });
    }
  },[
    proposal.id,
    attachments,
    options,
    handleAttachment,
    handleAttachFiles,
    testInfo
  ]);

  const addComment = useCallback(
    (text: string) => {
      commentsService.addComment(proposal.id, text, attachments).then((c) => {
        if (c !== null && c !== undefined) {
          setComments((comments) => [...comments, convertToComment(c)]);
          setResponseComments((responseComments: any) => [...responseComments, c]);

          setTestInfo([]);
          setNewNote("");
          setCommentsAttachProgress([]);
          setNewProgressBar([]);
        } else {
          openAlert({
            variant: "error",
            text: "Ocorreu um erro ao submeter o comentário.",
            title: "Aviso",
          });

          //Notificar utilizador
        }
      });
      setAttachments([]);
    },
    [proposal.id, attachments ]
  );

  const handleNotesDrawerSubmit = useCallback(() => {
    if (!newNote) {
      openAlert({
        variant: "error",
        text: "Por favor preencha o corpo do comentário e reenvie o pedido.",
        title: "Aviso",
      });
    } else {
      addComment(newNote);
    }
  }, [newNote, addComment]);

  const handleNewNoteChange = useCallback(
    (value: string) => {
      setNewNote(value);
    },
    [setNewNote]
  );

  const handleNotesButtonClick = useCallback(() => {
    if(proposal.id > 0) {
      commentsService.clearUnread(proposal.id).then((data) => {
        if (data.succeeded) setTotalCommentsUnread(0);
        if (!data.succeeded &&convertNumber(data.message) === currentUser.userId) {
          setTotalCommentsUnread(0);
        }
      });
    }
    else {
      setTotalCommentsUnread(0);
    }
    //alert('alterar o numero de notas por ler')
  }, [currentUser, proposal.id ]);

  const handleSaveEditNoteChange = useCallback((index: number) => {
    let commentToEdit = commentsFromGetResponse[index];
    let message = editNote === "" ? commentToEdit.message : editNote;
    if (index > -1 && commentToEdit) {
      commentsService.updateComment(
        proposal.id,
        commentToEdit.id,
        message,
        commentToEdit.attachments
      ).then((c) => {
        if (c !== null && c !== undefined) {
          comments[index] = convertToComment(c);
          setComments((comments) => comments);
          setCommentsAttachProgress([]);
          setNewProgressBar([]);
        } else {
          //Notificar utilizador
        }
      });
      setAttachments([]);
    }
  },[editNote, proposal.id, comments, commentsFromGetResponse ]);

  const handleEditNoteChange = useCallback((e: string, index: number) => {
    setEditNote(e);
    let editComments = comments;
    editComments[index].text = e;
    let editCommentsFromGetResponse = commentsFromGetResponse;
    editCommentsFromGetResponse[index].message = e;
    setComments(editComments);
    setResponseComments(editCommentsFromGetResponse);
  },[comments, commentsFromGetResponse]);

  // const handleQuotationChange = useCallback(
  //   (vehicleIndex: number, simulationIndex: number) =>
  //     (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //       setVehicleDataTable((rows) =>
  //         rows.map((row, index) => {
  //           if (index !== vehicleIndex) {
  //             return row;
  //           }
  //           let vehicle = row;
  //           let simulation: Simulation = vehicle.simulations[simulationIndex];
  //           vehicle.simulations[simulationIndex] = {
  //             ...simulation,
  //             [e.target.name]: e.target.value,
  //           };

  //           return { ...row, vehicle };
  //         })
  //       );
  //     },
  //   []
  // );

  // const handleMostFrequentDataChange = useCallback(
  //   (
  //     e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  //     vehicleIndex: number,
  //     simIndex: number
  //   ) => {
  //     let vehicles = vehicleDataTable;
  //     const name = e.target.name
  //     vehicles.forEach((vehicle, index) => {
  //       if (vehicleIndex === index) {
  //         vehicle.simulations.forEach((simulation, simulationIndex) => {
  //           if (simulationIndex === simIndex) {
  //             let value=(simulation as any)[name];
  //             simulation = { ...simulation, [e.target.name]: validaStringFormatDecimal(e.target.value,value) };
  //             if(e.target.name === "increaseVR")
  //             {
  //               simulation = { ...simulation, vrTable: convertNumber(((Number(e.target.value)/100) * simulation.baseVRTable + simulation.baseVRTable).toFixed(2)) };
  //               simulation = { ...simulation, incomeWithoutTax:
  //                 Math.round(
  //                   (
  //                     (simulation.baseIncomeWithoutTax - (simulation.vrTable - simulation.baseVRTable)/simulation.deadline)
  //                     + Number.EPSILON
  //                   ) * 100
  //                 )/100
  //               };
  //               simulation = {...simulation, incomeWithTax :
  //                 Math.round(
  //                   (
  //                     (simulation.incomeWithoutTax * (100 + vehicle.vat)/100 )
  //                       + Number.EPSILON
  //                   ) * 100
  //                 ) /100
  //               }
  //             }
  //             vehicle.simulations[simIndex] = simulation;
  //             // setVehicleDataTable([vehicles[index], vehicle])
  //           } else {
  //           }
  //         });
  //       } else {
  //       }
  //     });
  //     setVehicleDataTable([...vehicles]);
  //   },
  //   [vehicleDataTable]
  // );

  const handleMostFrequentQuotationClick = useCallback(()=> {
    let configurations:any[] = [];
    let frequentQuotatinOptionsTmp:any[] = [];
    vehiclesService.getFrequentQuotations(currentUser.userId).then(data => {
      data.value.forEach((quotationVehicle:IVehicle,index:number) => {
        let vehicle:IVehicle = quotationVehicle;
        vehicle.proposalId = proposal.id;
        vehicle.modelDescription = "";
        vehicle.no = "";
        vehicle.hidden = false;

        //quotation
        configurations.push({
          checkboxProps: {
            onChange: handleFrequentCheckboxPropsOnChange(
              index,
              quotationVehicle.id
            ),
          },
          vehicleID: quotationVehicle.headerSimulation.title,
          vehicleSummary: quotationVehicle.versionDescription,
          seeMoreConditionsLinkText: "Mostrar mais condições ",
          hideConditionsLinkText: "Ocultar condições ",
          rows: handleQuotationSimulations(vehicle), //((quotationData.frequentVehicle as IVehicle)),
          id: quotationVehicle.id,
          hidden: false,
        });
        //quotationOption
        frequentQuotatinOptionsTmp.push({
          label: quotationVehicle.versionDescription + (quotationVehicle.headerSimulation.title?" - " +quotationVehicle.headerSimulation.title:""),
          value: quotationVehicle.id,
        });
      });
      setFrequentQuotations(data.value); //as IFrequentQuotation[]
      setFrequentQuotationsConfigurations(configurations);
      setFrequentQuotationOptions(frequentQuotatinOptionsTmp);
    });
  }, [currentUser.userId, handleFrequentCheckboxPropsOnChange, proposal.id ]);

  const handleMostFrequentSearchResultChange = useCallback((e: React.SyntheticEvent<Element, Event>, value: any) => {
    if (value) {
      //FIXME Alexandre: multicenarios estiverem concluidos, se necessário, alterar isto quando os para refletir as alterações
      let id = (e.target as any).value
      frequentQuotationsConfigurations.forEach((element) => {
        if (typeof id === 'number'){
          if(element.id === id) {
            let frequentQuotationOptionsTmp = frequentQuotationOptions.find(x => {return x.label === value})
            setSelectedFrequentOption(frequentQuotationOptionsTmp);
          }
        }
        else {
          setSelectedFrequentOption(value);
        }
      })
    }
    else {
      handleMostFrequentQuotationClick();
      let frequentQuotationsTmp = frequentQuotationsConfigurations;
      frequentQuotationsTmp.forEach(quotation => {
        quotation.hidden = false;
        setFrequentQuotationsConfigurations([...frequentQuotationsTmp]);
      });
      setSelectedFrequentOption(-1);
    }

  },[frequentQuotationsConfigurations, frequentQuotationOptions, handleMostFrequentQuotationClick]);

  const handleConfirmMostFrequenteModalClick: ButtonProps["onClick"] = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setChangedProposal(true);
    selectedFrequentVehicles.map(async (selected) => {
      if (selected.checked === true) {
        let quotation: any = {}
        if (frequentQuotations.find((x) =>  x.id === selected.mostFrequentId )) {
          quotation = JSON.parse(JSON.stringify(frequentQuotations.find((x) =>  x.id === selected.mostFrequentId )));
        }
        let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)

        let updatedVehicleDataTable = [...vehicleDataTable]
        if (updatedVehicleDataTable.find((row) => row.versionId === quotation.versionId)) {
          updatedVehicleDataTable = await Promise.all(updatedVehicleDataTable.map(async (row) => {
            if (row.versionId === quotation?.versionId) {
              let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.typeofRental === row.typeofRental && x.commercialConditions === row.commercialConditions)

              await Promise.all((quotation.headerSimulation.frequentSimulations as Simulation[]).map( async (frequentSimulation) => {
                frequentSimulation.id = 0;
                frequentSimulation.no = '';

                // frequentSimulation = await initVehicleReplacementOptionsDropDown(
                //   frequentSimulation,
                //   quotation.commercialConditions,
                //   false
                // );

                // frequentSimulation =  await initSelectedInsuranceDropDown(
                //   frequentSimulation,
                //   quotation.use,
                //   false
                // );

                row.simulations.push(frequentSimulation);

                let simulation = row.simulations[row.simulations.length -1]
                let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions.find(x => x.insurance === simulation.insurance)
                if(insurancePackageOption && insuranceFrameworkContractOption){
                  simulation.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
                  simulation.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
                  simulation.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
                  simulation.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

                  simulation.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
                  simulation.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
                  simulation.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
                  simulation.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
                  simulation.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

                  simulation.fuelOptions = insurancePackageOption.fuelOptions;
                  simulation.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

                  simulation.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions;
                  simulation.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions;
                  simulation.occupiersOptions = insurancePackageOption.occupiersOptions;
                  simulation.liabilityOptions = insurancePackageOption.liabilityOptions;
                  simulation.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions;

                  simulation.responsibility = insurancePackageOption.responsibility;
                  simulation.ownDamage = insurancePackageOption.ownDamage;
                  simulation.occupants = insurancePackageOption.occupants;
                  simulation.travelAssistance = insurancePackageOption.travelAssistance;
                  simulation.glassBreakage = insurancePackageOption.glassBreakage;

                  simulation.insurance = frequentSimulation.secureOptions ? insurancePackageOption.insurance : '';
                  simulation.secureOptions = insurancePackageOption.secureOptions;
                  simulation.insurancePackageOptions = insuranceFrameworkContractOption.insurancePackageOptions.map(x => x.insurancePackageOption);
                }

                let totalQuantity=0;
                row.simulations.map((sim) => {
                  totalQuantity+=Number(sim.quantity)
                  return sim;
                });
                row.quantity=totalQuantity;

                return frequentSimulation;
              }))
              return row;
            }
            else {
              return row;
            }
          }))
          setVehicleDataTable(updatedVehicleDataTable)
        }
        else {

          quotation.id = 0;
          (quotation.accessories as IAccessory[]).forEach((element) => {
            element.id = 0;
          });
          (quotation.extraStandard as IExtraStandard[]).forEach((element) => {
            element.id = 0;
          });
          let simulations:Simulation[] = quotation.headerSimulation.frequentSimulations
          simulations.forEach((element) => {
            element.id = 0;
          });

          let vehicle:IVehicle = quotation as IVehicle;
          let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.typeofRental === vehicle.typeofRental && x.commercialConditions === vehicle.commercialConditions)

          vehicle.simulations = simulations;
          vehicle.simulations.map(simulation => {
            let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions.find(x => x.insurance === simulation.insurance)
            if(insurancePackageOption && insuranceFrameworkContractOption){
              simulation.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
              simulation.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
              simulation.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
              simulation.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

              simulation.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
              simulation.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
              simulation.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
              simulation.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
              simulation.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

              simulation.fuelOptions = insurancePackageOption.fuelOptions;
              simulation.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

              simulation.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions;
              simulation.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions;
              simulation.occupiersOptions = insurancePackageOption.occupiersOptions;
              simulation.liabilityOptions = insurancePackageOption.liabilityOptions;
              simulation.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions;

              simulation.responsibility = insurancePackageOption.responsibility;
              simulation.ownDamage = insurancePackageOption.ownDamage;
              simulation.occupants = insurancePackageOption.occupants;
              simulation.travelAssistance = insurancePackageOption.travelAssistance;
              simulation.glassBreakage = insurancePackageOption.glassBreakage;

              // simulation.insurance = simulation.secureOptions ? insurancePackageOption.insurance : '';
              // simulation.secureOptions = insurancePackageOption.secureOptions;
              simulation.insurancePackageOptions = insuranceFrameworkContractOption.insurancePackageOptions.map(x => x.insurancePackageOption);
            }
            return simulation
          })
          let totalQuantity=0;
          vehicle.simulations.map((sim)=>{
            totalQuantity+=Number(sim.quantity)
            return totalQuantity;
          })
          vehicle.quantity=totalQuantity;

          vehicle = updateValuesVehicle(vehicle,"");
          let updatedRows = [...updatedVehicleDataTable,vehicle]
          // updatedRows = await Promise.all( updatedRows.map(async (updatedRow) => {
          //   updatedRow.simulations = await Promise.all( updatedRow.simulations.map(async (updatedSim) => {
          //     // updatedSim = await initVehicleReplacementOptionsDropDown(
          //     //   updatedSim,
          //     //   quotation.commercialConditions,
          //     //   false
          //     // );

          //     // updatedSim =  await initSelectedInsuranceDropDown(
          //     //   updatedSim,
          //     //   quotation.use,
          //     //   false
          //     // );
          //     return updatedSim
          //   }))
          //   return updatedRow;
          // }))
          setVehicleDataTable(updatedRows)
        }
      }
      return selected;
    });
    setSelectedFrequentVehicles([]);
    // setFrequentQuotationsConfigurations((rows) =>
    //   rows.map((row) => {
    //     row.hidden = false;
    //     return row;
    //   })
    // )

  }, [
    selectedFrequentVehicles,
    frequentQuotations,
    vehicleDataTable,
    insuranceFrameworkContractSet
  ]);

  const  handleMostFrequentSearchButtonClick: ButtonProps["onClick"] = useCallback((e: any) => {
    // let frequentQuotationOptionsTmp = frequentQuotationOptions;
    let frequentQuotationsTmp = frequentQuotationsConfigurations;
    if(typeof selectedFrequentOption !== 'string') {
      if (selectedFrequentOption !== -1) {
        frequentQuotationsTmp.forEach((quotation) => {
          if (quotation.id !== selectedFrequentOption.value) {
            quotation.hidden = true;
            setFrequentQuotationsConfigurations([...frequentQuotationsTmp]);
          }
        });
      }
    } else {
      vehiclesService.searchFrequentQuotations(currentUser.userId, selectedFrequentOption).then((data) => {
        if (!data.succeeded){
          openAlert({
            variant: "error",
            text: "Ocorreu um erro pesquisar simulações frequentes! Por favor tente novamente. Se o problema persistir, contate o administrador.",
            title: "Atenção",
          });
        }
        else {
          console.log(data.value)
          frequentQuotationsTmp = []
          let frequentQuotationOptionsTmp: any[] = []
          data.value.forEach((quotationVehicle:IVehicle,index:number) => {
            let vehicle:IVehicle = quotationVehicle;
            vehicle.proposalId = proposal.id;
            // vehicle.modelDescription = "";
            // vehicle.no = "";
            vehicle.hidden = false;

            //quotation
            frequentQuotationsTmp.push({
              checkboxProps: {
                onChange: handleFrequentCheckboxPropsOnChange(
                  index,
                  quotationVehicle.id
                ),
              },
              vehicleID: quotationVehicle.headerSimulation.title,
              vehicleSummary: quotationVehicle.versionDescription,
              seeMoreConditionsLinkText: "Mostrar mais condições ",
              hideConditionsLinkText: "Ocultar condições ",
              rows: handleQuotationSimulations(vehicle), //((quotationData.frequentVehicle as IVehicle)),
              id: quotationVehicle.id,
              hidden: false,
            });
            //quotationOption
            frequentQuotationOptionsTmp.push({
              label: quotationVehicle.versionDescription,
              value: quotationVehicle.id,
            });
          });
          setFrequentQuotations(data.value); //as IFrequentQuotation[]
          setFrequentQuotationsConfigurations(frequentQuotationsTmp);
          setFrequentQuotationOptions(frequentQuotationOptionsTmp);
        }
      })
    }
  },[
    frequentQuotationsConfigurations, selectedFrequentOption,
    currentUser.userId, proposal.id, handleFrequentCheckboxPropsOnChange
  ]);

  const handleGroupEditModalChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let value: any;
    let discount: Number = 0;
    let campaign: Number = 0;
    let rappel: Number = 0;
    let targetIncomeWithTax: Number = 0;
    let targetIncomeWithoutTax: Number = 0;
    setVehicleGroupEditModalValues((current) => {
      if (
        e.target.name === "marginCheck" ||
        e.target.name === "commercialEffortCheck" ||
        e.target.name === "vrCheck"
      ) {
        value = (e.target as any).checked;
      } else if (e.target.name === "lotId") {
        value = e.target.value;
      } else {
        value = convertNumber(e.target.value);
        if (e.target.name === "discountWithoutTax"){
          discount = convertNumber((value * (1 + 23 / 100)).toFixed(2));
        } else if (e.target.name === "campaignWithoutTax"){
          campaign = convertNumber((value * (1 + 23 / 100)).toFixed(2));
        } else if (e.target.name === "rappelWithoutTax"){
          rappel = convertNumber((value * (1 + 23 / 100)).toFixed(2));
        } else if (e.target.name === "incomeWithoutTax" /*&& step  === 4*/ ) {
          targetIncomeWithoutTax = convertNumber((value).toFixed(2));
          targetIncomeWithTax = convertNumber((value * (1 + 23 / 100)).toFixed(2));
        } else if (e.target.name === "incomeWithTax" /*&& step  === 4*/ ) {
          targetIncomeWithTax = convertNumber((value).toFixed(2));
          targetIncomeWithoutTax = convertNumber((value / (1.23 )).toFixed(2));
        }
      }
      // let updatedValues = {...current}

      return {
        ...current,
        [e.target.name]: value,
        discountWithTax:convertNumber(discount ? discount : current.discountWithTax),
        campaignWithTax:convertNumber(campaign ? campaign : current.campaignWithTax),
        rappelWithTax:convertNumber(rappel ? rappel : current.rappelWithTax),
        incomeWithTax:convertNumber(targetIncomeWithTax ? targetIncomeWithTax : current.incomeWithTax),
        incomeWithoutTax:convertNumber(targetIncomeWithoutTax ? targetIncomeWithoutTax : current.incomeWithoutTax),
      };
    });
  }, []);

  const handleCloseGroupEditModal: ButtonProps["onClick"] = useCallback(() => {
    const vehicleGroupEdit = vehicleGroupEditModalValues;
    setContractSelectedRows((rowsSelect) =>
      rowsSelect.map((rowSelect) => {
          setVehicleDataTable((rows) =>
            rows.map((rowVehicle, index) => {
              if (index !== rowSelect.vehicleIndex) {
                return rowVehicle;
              }

              let simulation = rowVehicle.simulations[rowSelect.simulationIndex];

              if (vehicleGroupEdit.marginCheck && vehicleGroupEdit.incomeWithoutTax > 0) {
                if (vehicleGroupEdit.margin > 0 && vehicleGroupEdit.incomeWithoutTax === 0 ) {
                  doNothing();
                  // qual a regra de calculo a aplicar??
                }
              }

              if(vehicleGroupEdit.vrCheck && vehicleGroupEdit.incomeWithoutTax > 0) {
                  // VR Final = VR Atual + (Renda atual- Renda Target)* Nº de Prestações
                  simulation.vrTable = // VR Final
                    //VR Atual              + (Renda atual                - Renda target                      ) * Nº prestações
                    simulation.baseVRTable + (simulation.incomeWithoutTax - vehicleGroupEdit.incomeWithoutTax ) * simulation.deadline;

                  // % Aumento VR
                  simulation.increaseVR = Number((
                    (simulation.vrTable - simulation.baseVRTable) * 100 / simulation.baseVRTable
                  ).toFixed(2))
              }

              rowVehicle.lotId = vehicleGroupEdit.lotId;

              if ( vehicleGroupEdit.incomeWithoutTax > 0 && (vehicleGroupEdit.vrCheck || vehicleGroupEdit.marginCheck)) {
                simulation.incomeWithTax = vehicleGroupEdit.incomeWithTax;
                simulation.incomeWithoutTax = vehicleGroupEdit.incomeWithoutTax;
              }

              simulation.initialEntry = vehicleGroupEdit.initialEntry;
              simulation.collateral = vehicleGroupEdit.collateral;

              if (vehicleGroupEdit.increaseVr > 0) {
                simulation.increaseVR = vehicleGroupEdit.increaseVr;
                simulation.vrTable = Number((simulation.baseVRTable * ((100 + vehicleGroupEdit.increaseVr) / 100)).toFixed(2))  //VR Atual
              }
              if(vehicleGroupEdit.margin > 0) {
                simulation.margin = vehicleGroupEdit.margin;
              }
              if(vehicleGroupEdit.expenses !== simulation.contractExpenses) {
                simulation.contractExpenses = vehicleGroupEdit.expenses;
              }

              updateValuesVehicle(rowVehicle,"");

              return rowVehicle;
            })
          );
        return rowSelect;
      }
    ));
    setVehicleGroupEditModalValues({
      incomeWithoutTax: 0,
      incomeWithTax: 0,
      collateral: 0,
      margin: 0,
      increaseVr:0,
      initialEntry: 0,
      expenses: 0,
      marginCheck: false,
      commercialEffortCheck: false,
      vrCheck: false,
      discountWithoutTax: 0,
      discountWithTax: 0,
      discountWithTaxPercentage: 0,
      campaignWithoutTax: 0,
      campaignWithTax: 0,
      campaignWithTaxPercentage: 0,
      rappelWithoutTax: 0,
      rappelWithTax: 0,
      rappelWithTaxPercentage: 0,
      extraRappel: 0,
      lotId: "",
      extraAccessories: [],
    });
  },[vehicleGroupEditModalValues]);

  const handleContractSettingsGroupEdit_Click = useCallback(() => {
    let simulationProperties = {...simulationScenario};

    // prazo
    const selectedDeadLineOption = deadlineValues.find((x) => x.checked === true);
    const deadLineValue = selectedDeadLineOption?.value;
    if (deadLineValue) {
      simulationProperties = {
        ...simulationProperties,
        deadline: Number(deadLineValue)
      };
    }

    //kilometros
    const selectedKM = annualKmValues.find((x) => x.checked === true);
    const selectedKMValue = selectedKM?.value;
    if (selectedKMValue) {
      simulationProperties = {
        ...simulationProperties,
        miles: deadLineValue ? Number(selectedKMValue) * (Number(deadLineValue)/12) : Number(selectedKMValue)
      };
    }

    //contractSettingsGroupEditTires
    //thirdLineTireValues
    //pneus
    const selectedTyresOption = thirdLineTiresValuesMultiScenario.find((x) => x.checked === true);
    const selectedTyresValue = selectedTyresOption?.value ?? "0";
    simulationProperties = {
      ...simulationProperties,
      tires: contractSettingsGroupEditTiresYes,
      tiresQuantity: selectedTyresValue.toString()
    };

    const insuranceSpecified = insurances[1];
    if (insuranceSpecified === true) {
      simulationProperties = {
        ...simulationProperties,
        // useScenario: useScenario, // só existe na viatura, apenas faz parte da modal para efeito de validação do pacote de seguros.
        secureOptions: simulationScenario.secureOptions,
        insurance: simulationScenario.insurance,
        ownDamage: simulationScenario!.ownDamage,
        occupants: simulationScenario!.occupants,
        travelAssistance: simulationScenario!.travelAssistance,
        responsibility: simulationScenario!.responsibility,
        glassBreakage: simulationScenario!.glassBreakage,
        maintenanceDescription: simulationScenario!.maintenanceDescription,
        iucCost: simulationScenario!.iucCost,
        fuelCard: fuelYes,
        fuelDescriptiom: simulationScenario.fuelDescriptiom,
        viaVerde:	viaVerdeYes,
        entityViaVerde: simulationScenario.entityViaVerde
      };
    }

    const replacementVehicleSpecified = replacementVehicles[1];
    if (replacementVehicleSpecified) {
      simulationProperties = {
        ...simulationProperties,
        vehicleType: simulationScenario!.vehicleType,
        vsOverhaulVehicle: simulationScenario!.vsOverhaulVehicle,
        vsOverhaulVehicleType: simulationScenario!.vsOverhaulVehicleType,
        vsAssistanceVehicle: simulationScenario!.vsAssistanceVehicle,
        vsAssistanceVehicleType: simulationScenario!.vsAssistanceVehicleType,
        vsAccidentVehicle: simulationScenario!.vsAccidentVehicle,
        vsAccidentVehicleType: simulationScenario!.vsAccidentVehicleType,
        vsTheftVehicle: simulationScenario!.vsTheftVehicle,
        vsTheftVehicleType: simulationScenario!.vsTheftVehicleType,
        vehicleReplacementOptions: simulationScenario.vehicleReplacementOptions
      };
    }

    // produtos complementares
    let complementaryProducts: ComplementaryProduct[] = [];
    modalComplementaryProductsList.forEach(row => {
      if(row.cells[0].checked){
        complementaryProducts.push({
          id: 0,
          simulationId: simulationProperties.id,
          no: row.cells[1],
          description: row.cells[2],
          registerCode: String(row.cells[3].value),
          additionalCostValue: String(row.cells[4][0].value),
          additionalCostTimePeriod: String(row.cells[4][1].value),
          insurance: String(row.cells[5].value),
          accessoryType: String(row.details.accessoryDropDownProps.value),
          basePriceWithoutTax: Number(row.details.basePrice.withoutTaxTextFieldProps.value),
          basePriceWithTax: Number(row.details.basePrice.withTaxTextFieldProps.value),
          residualValue: Number(row.details.residualValueTextFieldProps.value),
          supplier: String(row.details.supplierDropDownProps.value),
          lineNo: Number(row.cells[6]),
          headerNo: row.cells[7]

        })
      }
    });
    modalFilteredComplementaryProducts.forEach(row => {
      if(row.cells[0].checked){
        if(!complementaryProducts.some(x => x.no === row.cells[1] && x.lineNo === Number(row.cells[6]) && x.description === row.cells[2])){
          complementaryProducts.push({
            id: 0,
            simulationId: simulationProperties.id,
            no: row.cells[1],
            description: row.cells[2],
            registerCode: String(row.cells[3].value),
            additionalCostValue: String(row.cells[4][0].value),
            additionalCostTimePeriod: String(row.cells[4][1].value),
            insurance: String(row.cells[5].value),
            accessoryType: String(row.details.accessoryDropDownProps.value),
            basePriceWithoutTax: Number(row.details.basePrice.withoutTaxTextFieldProps.value),
            basePriceWithTax: Number(row.details.basePrice.withTaxTextFieldProps.value),
            residualValue: Number(row.details.residualValueTextFieldProps.value),
            supplier: String(row.details.supplierDropDownProps.value),
            lineNo: Number(row.cells[6]),
            headerNo: row.cells[7]

          })
        }
      }
    });

    simulationProperties.complementaryProducts = complementaryProducts;
    setVehicleDataTable(rows => {
      contractSelectedRows.forEach(({ vehicleIndex, simulationIndex }) => {
        let simulation = rows[vehicleIndex].simulations[simulationIndex];
        simulation = {...simulation,
          deadline: simulationProperties.deadline,
          miles:simulationProperties.miles,
          tires:simulationProperties.tires,
          tiresQuantity:simulationProperties.tiresQuantity,

          secureOptions: simulationProperties.secureOptions,
          insurance:simulationProperties.insurance,
          ownDamage:simulationProperties.ownDamage,
          occupants:simulationProperties.occupants,
          travelAssistance:simulationProperties.travelAssistance,
          responsibility:simulationProperties.responsibility,
          glassBreakage:simulationProperties.glassBreakage,

          maintenanceDescription:simulationProperties.maintenanceDescription,
          iucCost:simulationProperties.iucCost,
          fuelCard:simulationProperties.fuelCard,
          fuelDescriptiom:simulationProperties.fuelDescriptiom,
          viaVerde:simulationProperties.viaVerde,
          entityViaVerde:simulationProperties.entityViaVerde,

          vehicleType:simulationProperties.vehicleType,
          vehicleReplacementOptions: simulationProperties.vehicleReplacementOptions,
          vsOverhaulVehicle:simulationProperties.vsOverhaulVehicle,
          vsOverhaulVehicleType:simulationProperties.vsOverhaulVehicleType,
          vsAssistanceVehicle:simulationProperties.vsAssistanceVehicle,
          vsAssistanceVehicleType:simulationProperties.vsAssistanceVehicleType,
          vsAccidentVehicle:simulationProperties.vsAccidentVehicle,
          vsAccidentVehicleType:simulationProperties.vsAccidentVehicleType,
          vsTheftVehicle:simulationProperties.vsTheftVehicle,
          vsTheftVehicleType:simulationProperties.vsTheftVehicleType,

          complementaryProducts:simulationProperties.complementaryProducts,
        }
        rows[vehicleIndex].simulations[simulationIndex] = {...simulation}
      })
      return [...rows];
    })
    setChangedProposal(true);


    // clear checkbox selection
    setModalComplementaryProductsList((rows) => {
      return rows.map((row: any, index: number) => {
        row.cells[0].checked = false;
        return {...row};
      }).sort((a, b) => {
        if (a.cells[0].checked && !b.cells[0].checked) {
          return -1;
        } else if (!a.cells[0].checked && b.cells[0].checked) {
          return 1;
        } else {
          return 0;
        }
      });
    })
    // setModalFilteredComplementaryProducts((rows) => {
    //   return rows.map((row: any, index: number) => {
    //     row.cells[0].checked = false;
    //     return {...row};
    //   }).sort((a, b) => {
    //     if (a.cells[0].checked && !b.cells[0].checked) {
    //       return -1;
    //     } else if (!a.cells[0].checked && b.cells[0].checked) {
    //       return 1;
    //     } else {
    //       return 0;
    //     }
    //   });
    // })
    setModalSelectedSearchOptions({label: '', value: ''});
    setModalFilteredComplementaryProducts([]);

    setCommercialConditionsScenario('');
    setContractSelectedRows([{
      vehicleIndex: -1,
      simulationIndex: -1
    }]);
  }, [
    setModalComplementaryProductsList,
    setModalFilteredComplementaryProducts,
    setModalSelectedSearchOptions,
    modalComplementaryProductsList,
    modalFilteredComplementaryProducts,
    contractSelectedRows,
    simulationScenario,
    deadlineValues,
    annualKmValues,
    contractSettingsGroupEditTiresYes,
    thirdLineTiresValuesMultiScenario,
    insurances,
    fuelYes,
    viaVerdeYes,
    replacementVehicles,
  ]);


  const handleContractTypeDuplicateVehicleClick = useCallback((index:number) => {
    let duplicatedVehicle = JSON.parse(JSON.stringify(vehicleDataTable.at(index)));
    var tempVvehicleDataTable = JSON.parse(JSON.stringify(vehicleDataTable));
    if (duplicatedVehicle) {
      setChangedProposal(true);
      tempVvehicleDataTable.push(duplicatedVehicle);
      duplicatedVehicle = (tempVvehicleDataTable.slice(-1)[0] as IVehicle);
      duplicatedVehicle.id = 0;
      duplicatedVehicle.leaseQuoteNo="";
      duplicatedVehicle.accessories.forEach((x:IAccessory) => {
        x.id=0;
        x.vehicleId=0;
      });
      duplicatedVehicle.extraStandard.forEach((x:IExtraStandard) => {
        x.id=0;
        x.vehicleId=0;
      });
      duplicatedVehicle.simulations.forEach((x:Simulation) => {
        x.id=0;
        x.vehicleId=0;
        x.no = "";
      });
      tempVvehicleDataTable.slice(-1)[0] = duplicatedVehicle;
      tempVvehicleDataTable.forEach((element: any) => {
        if(element.model){
          element.model = null
        }
      });

      let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
      vehiclesService.add(tempVvehicleDataTable).then((data) => {
        (data.value as IVehicle[]).forEach(async (element,index) => {
          let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.typeofRental === element.typeofRental && x.commercialConditions === element.commercialConditions)
          element.hidden = false;
          (element.simulations as Simulation[]).forEach(async (simulation,indexSimulation) => {
            let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions.find(x => x.insurance === simulation.insurance)

            if(insurancePackageOption && insuranceFrameworkContractOption){
              simulation.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
              simulation.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
              simulation.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
              simulation.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

              simulation.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
              simulation.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
              simulation.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
              simulation.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
              simulation.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

              simulation.fuelOptions = insurancePackageOption.fuelOptions;
              simulation.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

              simulation.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions;
              simulation.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions;
              simulation.occupiersOptions = insurancePackageOption.occupiersOptions;
              simulation.liabilityOptions = insurancePackageOption.liabilityOptions;
              simulation.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions;

              simulation.responsibility = insurancePackageOption.responsibility;
              simulation.ownDamage = insurancePackageOption.ownDamage;
              simulation.occupants = insurancePackageOption.occupants;
              simulation.travelAssistance = insurancePackageOption.travelAssistance;
              simulation.glassBreakage = insurancePackageOption.glassBreakage;

              simulation.insurance = insurancePackageOption.insurance;
              simulation.secureOptions = insurancePackageOption.secureOptions;
              simulation.insurancePackageOptions = insuranceFrameworkContractOption.insurancePackageOptions.map(x => x.insurancePackageOption);
            }
            // await initVehicleReplacementOptionsDropDown(simulation,element.commercialConditions,true);
            // await initSelectedInsuranceDropDown(simulation,element.use,true);

            vehiclesService.getCommercialConditions(element.typeofRental).then((data) => {
              if (data.succeeded === true){
                element.commercialOptions = data.value;
              }
            })
          });
        });
        setVehicleDataTable(data.value);
        setVehiclesListingTotal(vehicleDataTable.length + 1);
      });
    }
    else {
      openAlert({
        variant: "info",
        text: "Não foi possível duplicar a viatura. Caso este erro persista contacte um administrador.",
        title: "Atenção",
        autoHideDuration: 3000,
      });
    }

  },[ vehicleDataTable, insuranceFrameworkContractSet ])

  const handleContractTypeRemoveVehicleClick = useCallback((index:number) => {
    let removeVehicle = vehicleDataTable;
    const vehicle = removeVehicle[index];
    const vehicleId = (vehicle as any).id;

    vehiclesService.delete(vehicleId).then(() => {
      setChangedProposal(true);
      removeVehicle.splice(index, 1);
      setVehicleDataTable((vehicleDataTable) => [
        ...removeVehicle,
      ]);
      setVehiclesListingTotal(vehicleDataTable.length);
    });

  },[vehicleDataTable])

  const handleMostFrequentCancel = useCallback(()=> {
    setFrequentHeaderSimulations([]);
    setFrequentSimulationOptions([]);
    setFrequentSimulationSearchOptions([]);
    setSelectedFrequentOption(-1);
    // setSelectedFrequentVehicles([])
  },[]);

  const handleMostFrequentModalCancel = useCallback(() => {
    setFrequentQuotationsConfigurations([]);
    setFrequentQuotationOptions([]);
    setSelectedFrequentOption(-1);
    // setSelectedFrequentVehicles([])
  },[]);

  const handleInitialDropDownInsuranceClick = useCallback(() => {
    if(contractSettingsCheckedVehicles.size === 0){
      openAlert({
        variant: "error",
        text: `Não tem nenhum veículo do selecionado.`,
        title: "Atenção",
      });
    }
    initDropDownToScenario();
  }, [contractSettingsCheckedVehicles,initDropDownToScenario] );

  // const handleMostFrequentConfirmClick = useCallback(async () => {
  const handleMostFrequentConfirmClick = useCallback(() => {
    selectedFrequentVehicles.forEach(async (selected) => {
      if (selected.checked === true) {
        let frequentHeaderSimulation: IFrequentHeaderSimulation = frequentHeaderSimulations.find((x) => {
          return x.id === selected.mostFrequentId;
        });
        frequentHeaderSimulation.mostUsed=frequentHeaderSimulation.mostUsed+1;
        let tempSimulations: Simulation[] = JSON.parse(JSON.stringify(frequentHeaderSimulation.frequentSimulations));
        // await vehiclesService.addFrequentHeaderSimulation(tempSimulations,frequentHeaderSimulation,frequentHeaderSimulation.title, frequentHeaderSimulation.visibility, currentUser.userId, false);
        await Promise.all(
          //SK-388 SK-516
          vehicleDataTable.map(async (vehicle, rowIndex) => {
            //CHECKBOX VEICULOS
            if (contractSettingsCheckedVehicles.size > 0) {
              await Promise.all(
                Array.from(contractSettingsCheckedVehicles).map(async (selectedVehicleId) => {
                  if (selectedVehicleId === vehicle.id.toString()) {

                    let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
                    let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.typeofRental === vehicle.typeofRental && x.commercialConditions === vehicle.commercialConditions)

                    if(vehicle.simulations.some(sim  => sim.deadline === 0 || sim.miles === 0 )) {
                      await Promise.all(
                        tempSimulations.map(async (frequentSimulation) => {
                          let simulationIndex = vehicle.simulations.findIndex(sim  => sim.deadline === 0 || sim.miles === 0);
                          if(simulationIndex === -1) {
                            simulationIndex = vehicle.simulations.length;
                          }
                          frequentSimulation.vehicleId = vehicle.id;
                          frequentSimulation.id =vehicle.simulations[simulationIndex] === undefined ? 0: vehicle.simulations[simulationIndex].id;
                          //Assume os valores da viatura atual em vez dos valores
                          //associados ao modelo guardado no frequente
                          frequentSimulation.priceexclVAT = vehicle.priceexclVAT;
                          frequentSimulation.consumersPrice = vehicle.consumersPrice;

                          frequentSimulation.totalExtraWithoutTax = vehicle.totalExtraWithoutTax;
                          frequentSimulation.totalExtraWithTax = vehicle.totalExtraWithTax;

                          frequentSimulation.totalAccessoriesWithout = vehicle.totalAccessoriesWithout;
                          frequentSimulation.totalAccessoriesWith = vehicle.totalAccessoriesWith;

                          frequentSimulation.discountWithoutTax = vehicle.discountWithoutTax;
                          frequentSimulation.discountWithTax = vehicle.discountWithTax;
                          frequentSimulation.discountWithTaxPercentage = vehicle.discountWithTaxPercentage;
                          frequentSimulation.discountWithTaxTotal = vehicle.discountWithTaxTotal;

                          frequentSimulation.proformaInvoicePriceWithoutTax = vehicle.proformaInvoicePriceWithoutTax;
                          frequentSimulation.proformaInvoicePriceWithTax = vehicle.proformaInvoicePriceWithTax;

                          frequentSimulation.campaignWithoutTax = vehicle.campaignWithoutTax;
                          frequentSimulation.campaignWithTax = vehicle.campaignWithTax;
                          frequentSimulation.campaignWithTaxPercentage = vehicle.campaignWithTaxPercentage;
                          frequentSimulation.campaignWithTaxTotal = vehicle.campaignWithTaxTotal;

                          frequentSimulation.rappelWithoutTax = vehicle.rappelWithoutTax;
                          frequentSimulation.rappelWithTax = vehicle.rappelWithTax;
                          frequentSimulation.rappelWithTaxPercentage = vehicle.rappelWithTaxPercentage;
                          frequentSimulation.rappelWithTaxTotal = vehicle.rappelWithTaxTotal;

                          frequentSimulation.extraRappel = vehicle.extraRappel;

                          frequentSimulation.businessValueWithoutTax = vehicle.businessValueWithoutTax;
                          frequentSimulation.businessValueWithTax = vehicle.businessValueWithTax;
                          frequentSimulation.quantity = vehicle.quantity;
                          //fim validações

                          vehicle.simulations[simulationIndex] = frequentSimulation;

                          let totalQuantity=0;
                          vehicle.simulations.map((sim)=>{
                            totalQuantity+=Number(sim.quantity)
                            return totalQuantity;
                          });
                          vehicle.quantity=totalQuantity;

                          // isto deixa de fazer sentido tendo em conta a atualização
                          // let defaultValue=false;
                          // //Se as condições comerciais não forem iguais ou o tipo do veiculo
                          // if(((vehicle.commercialConditions !== frequentHeaderSimulation.commercialConditions || vehicle.use !== frequentHeaderSimulation.use) && frequentSimulation.secureOptions === true)){
                          //   defaultValue=true;
                          // }

                          //carrega os campos das opções do seguro
                          let simulation = vehicle.simulations[simulationIndex]
                          let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions.find(x => x.insurance === simulation.insurance)
                          if(insurancePackageOption && insuranceFrameworkContractOption){
                            simulation.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
                            simulation.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
                            simulation.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
                            simulation.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

                            simulation.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
                            simulation.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
                            simulation.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
                            simulation.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
                            simulation.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

                            simulation.fuelOptions = insurancePackageOption.fuelOptions;
                            simulation.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

                            simulation.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions;
                            simulation.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions;
                            simulation.occupiersOptions = insurancePackageOption.occupiersOptions;
                            simulation.liabilityOptions = insurancePackageOption.liabilityOptions;
                            simulation.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions;

                            simulation.responsibility = insurancePackageOption.responsibility;
                            simulation.ownDamage = insurancePackageOption.ownDamage;
                            simulation.occupants = insurancePackageOption.occupants;
                            simulation.travelAssistance = insurancePackageOption.travelAssistance;
                            simulation.glassBreakage = insurancePackageOption.glassBreakage;

                            simulation.insurance = frequentSimulation.secureOptions ? insurancePackageOption.insurance : '';
                            // simulation.secureOptions = insurancePackageOption.secureOptions;
                            simulation.insurancePackageOptions = insuranceFrameworkContractOption.insurancePackageOptions.map(x => x.insurancePackageOption);
                          }

                          // await initVehicleReplacementOptionsDropDown(
                          //   vehicle.simulations[simulationIndex],
                          //   vehicle.commercialConditions,defaultValue
                          // );


                          // await initSelectedInsuranceDropDown(
                          //   vehicle.simulations[simulationIndex],
                          //   vehicle.use,defaultValue
                          // );




                          return frequentSimulation;
                        })
                      );
                    }
                    else {
                      await Promise.all(
                        tempSimulations.map(async (frequentSimulation) => {
                          frequentSimulation.vehicleId = vehicle.id;
                          frequentSimulation.id = 0;
                          //Assume os valores da viatura atual em vez dos valores
                          //associados ao modelo guardado no frequente
                          frequentSimulation.priceexclVAT = vehicle.priceexclVAT;
                          frequentSimulation.consumersPrice = vehicle.consumersPrice;

                          frequentSimulation.totalExtraWithoutTax = vehicle.totalExtraWithoutTax;
                          frequentSimulation.totalExtraWithTax = vehicle.totalExtraWithTax;

                          frequentSimulation.totalAccessoriesWithout = vehicle.totalAccessoriesWithout;
                          frequentSimulation.totalAccessoriesWith = vehicle.totalAccessoriesWith;

                          frequentSimulation.discountWithoutTax = vehicle.discountWithoutTax;
                          frequentSimulation.discountWithTax = vehicle.discountWithTax;
                          frequentSimulation.discountWithTaxPercentage = vehicle.discountWithTaxPercentage;
                          frequentSimulation.discountWithTaxTotal = vehicle.discountWithTaxTotal;

                          frequentSimulation.proformaInvoicePriceWithoutTax = vehicle.proformaInvoicePriceWithoutTax;
                          frequentSimulation.proformaInvoicePriceWithTax = vehicle.proformaInvoicePriceWithTax;

                          frequentSimulation.campaignWithoutTax = vehicle.campaignWithoutTax;
                          frequentSimulation.campaignWithTax = vehicle.campaignWithTax;
                          frequentSimulation.campaignWithTaxPercentage = vehicle.campaignWithTaxPercentage;
                          frequentSimulation.campaignWithTaxTotal = vehicle.campaignWithTaxTotal;

                          frequentSimulation.rappelWithoutTax = vehicle.rappelWithoutTax;
                          frequentSimulation.rappelWithTax = vehicle.rappelWithTax;
                          frequentSimulation.rappelWithTaxPercentage = vehicle.rappelWithTaxPercentage;
                          frequentSimulation.rappelWithTaxTotal = vehicle.rappelWithTaxTotal;

                          frequentSimulation.extraRappel = vehicle.extraRappel;

                          frequentSimulation.businessValueWithoutTax = vehicle.businessValueWithoutTax;
                          frequentSimulation.businessValueWithTax = vehicle.businessValueWithTax;
                          frequentSimulation.quantity = vehicle.quantity;
                          //fim validações
                          vehicle.simulations.push(frequentSimulation);
                          let totalQuantity=0;
                          vehicle.simulations.map((sim)=>{
                            totalQuantity+=Number(sim.quantity)
                            return totalQuantity;
                          });
                          vehicle.quantity=totalQuantity;

                          //provavement esta validação do defaultValue deixa de ser necessária
                          // let defaultValue=false;
                          // //Se as condições comerciais não forem iguais ou o tipo do veiculo
                          // if(((vehicle.commercialConditions !== frequentHeaderSimulation.commercialConditions || vehicle.use !== frequentHeaderSimulation.use) && frequentSimulation.secureOptions === true)){
                          //   defaultValue=true;
                          // }

                          //carrega os campos das opções do seguro
                          let simulation = vehicle.simulations[vehicle.simulations.length - 1]
                          let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions.find(x => x.insurance === simulation.insurance)
                          if(insurancePackageOption && insuranceFrameworkContractOption){
                            simulation.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
                            simulation.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
                            simulation.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
                            simulation.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

                            simulation.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
                            simulation.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
                            simulation.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
                            simulation.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
                            simulation.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

                            simulation.fuelOptions = insurancePackageOption.fuelOptions;
                            simulation.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

                            simulation.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions;
                            simulation.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions;
                            simulation.occupiersOptions = insurancePackageOption.occupiersOptions;
                            simulation.liabilityOptions = insurancePackageOption.liabilityOptions;
                            simulation.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions;

                            simulation.responsibility = insurancePackageOption.responsibility;
                            simulation.ownDamage = insurancePackageOption.ownDamage;
                            simulation.occupants = insurancePackageOption.occupants;
                            simulation.travelAssistance = insurancePackageOption.travelAssistance;
                            simulation.glassBreakage = insurancePackageOption.glassBreakage;

                            simulation.insurance = frequentSimulation.secureOptions ? insurancePackageOption.insurance : '';
                            // simulation.secureOptions = insurancePackageOption.secureOptions;
                            simulation.insurancePackageOptions = insuranceFrameworkContractOption.insurancePackageOptions.map(x => x.insurancePackageOption);
                          }


                          // await initVehicleReplacementOptionsDropDown(
                          //   vehicle.simulations[vehicle.simulations.length - 1],
                          //   vehicle.commercialConditions,defaultValue
                          // );


                          // await initSelectedInsuranceDropDown(
                          //   vehicle.simulations[vehicle.simulations.length - 1],
                          //   vehicle.use,defaultValue
                          // );



                          return frequentSimulation;
                        })
                      );
                    }
                  }
                })
              );
            }
            return vehicle;
          }
          )
        ).finally(
          () => {
             getScenariosFrequentSimulations();
           });

        // // FALTA limpar as checkboxes
        // let selectedSimulation: {
        //   vehicleIndex: number;
        //   simulationIndex: number;
        // }[] = []
        // setContractSelectedRows((rows) => {
        //   rows = selectedSimulation
        //   return rows;
        // });
        setContractSettingsCheckedVehicles(new Set()) // reset as viaturas seleccionadas sem limpar checkboxes

        setVehicleDataTable([...vehicleDataTable]);
      }
    });
    setChangedProposal(true);
    setSelectedFrequentVehicles([]);
    frequentSimulationOptions.map((option) => {
      return (option.hidden = false);
    });
  }, [
    frequentHeaderSimulations,
    vehicleDataTable,
    frequentSimulationOptions,
    selectedFrequentVehicles,
    getScenariosFrequentSimulations,
    contractSettingsCheckedVehicles,
    insuranceFrameworkContractSet,
  ]);

  const handleMostFrequentSimulationsClick = useCallback(() => {
    let frequentSimulationsOptionsTmp: any[] = [];
    let frequentSimulationSearchOptionsTmp: Option[] = [];
    vehiclesService.getFrequentHeaderSimulations(currentUser.userId).then((data) => {
      data.value.forEach(
        (frequentHeaderSimulation: IFrequentHeaderSimulation, index: number) => {
          frequentSimulationsOptionsTmp.push({
            checkboxProps: {
              onChange: handleFrequentCheckboxPropsOnChange(
                index,
                frequentHeaderSimulation.id
              ),
            },
            months: `${frequentHeaderSimulation.deadline} meses`,
            kms: `${frequentHeaderSimulation.miles} Km/ano`,
            clientID: frequentHeaderSimulation.title,
            seeMoreConditionsLinkText: "Mostrar mais condições ",
            hideConditionsLinkText: "Ocultar condições ",
            id: frequentHeaderSimulation.id,
            rows:frequentHeaderSimulation.frequentSimulations.map((simFrequent)=>{
             return {
                term: {
                  label: "Prazo (meses)",
                  value: `${simFrequent.deadline} meses`,
                },
                km: {
                  label: "Km",
                  value: `${simFrequent.miles} Km`,
                },
                tyres: {
                  label: "Pneus",
                  value: simFrequent.tires === true ? "Sim" : "Não",
                },
                insurance: {
                  label: "Seguro",
                  value: simFrequent.insurance,
                },
                maintenance: {
                  label: "Manutenção",
                  value:
                    simFrequent.maintenance === true ? "Sim" : "Não",
                },
                vs: {
                  label: "VS",
                  value:
                    simFrequent.vehicleReplacementOptions === true
                      ? "Sim"
                      : "Não",
                  //FIXME Descomentar depois de adicionar o campo ao objecto SIMULATION na API
                },
                complementaryProductsNumber: {
                  label: "Produtos complementares",
                  value: "0",
                },
              }
            }),
            hidden: false,
          });
          frequentSimulationSearchOptionsTmp.push({
            label: `${frequentHeaderSimulation.deadline} meses ${frequentHeaderSimulation.miles} Km`+(frequentHeaderSimulation.title?" - "+ frequentHeaderSimulation.title:""),
            value: frequentHeaderSimulation.id.toString(),
          });
        }
      );
      setFrequentHeaderSimulations(data.value as IFrequentHeaderSimulation[]);
      setFrequentSimulationOptions(frequentSimulationsOptionsTmp);
      setFrequentSimulationSearchOptions(frequentSimulationSearchOptionsTmp)
    })
    vehiclesService.getAllInsurancePackage().then((data) => {
      if (!data.succeeded) {
        openAlert({
          variant: "error",
          text: "Ocorreu um erro obter as opções de seguro! Por favor tente novamente. Se o problema persistir, contate o administrador.",
          title: "Atenção",
        });
      }
      else {
        setAllInsurances(data.value);
      }
    });
  },[
    currentUser,
    // frequentSimulationOptions,
    handleFrequentCheckboxPropsOnChange,
  ]);

  const handleFrequentSilumationChange = useCallback(
    (
      e: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason
    ) => {
      if (value) {
        let id = (e.target as any).value;
        //FIXME Alexandre: multicenarios estiverem concluidos, se necessário, alterar isto quando os para refletir as alterações
        if (typeof id === 'number'){
          frequentHeaderSimulations.forEach((element) => {
            if (element.id === id) {
              let frequentSimulationOptionsTmp = frequentSimulationOptions.find(
                (x) => {
                  return x.id === id;
                }
              );
              setSelectedFrequentOption(frequentSimulationOptionsTmp);
            }
          });
        }
        else {
          setSelectedFrequentOption(value);
        }
      } else {
        handleMostFrequentSimulationsClick();
        let frequentSimulationsOptionsTmp = frequentSimulationOptions;
        frequentSimulationsOptionsTmp.forEach((option) => {
          option.hidden = false;
          setFrequentSimulationOptions([...frequentSimulationsOptionsTmp]);
        });
        setSelectedFrequentOption(-1);
      }
    },

    [frequentSimulationOptions, frequentHeaderSimulations, handleMostFrequentSimulationsClick]
  );

  const handleFrequentSearchClick = useCallback(() => {
    let frequentSimulationsOptionsTmp = frequentSimulationOptions;
    if(typeof selectedFrequentOption !== 'string') {
      if (selectedFrequentOption !== -1) {
        frequentSimulationsOptionsTmp.forEach((option) => {
          if (option.id !== selectedFrequentOption.id) {
            option.hidden = true;
            setFrequentSimulationOptions([...frequentSimulationsOptionsTmp]);
          }
        });
      }
    } else {
      vehiclesService.searchFrequentHeaderSimulations(currentUser.userId, selectedFrequentOption).then((data) => {
        if (!data.succeeded){
          openAlert({
            variant: "error",
            text: "Ocorreu um erro pesquisar simulações frequentes! Por favor tente novamente. Se o problema persistir, contate o administrador.",
            title: "Atenção",
          });
        }
        else {
          frequentSimulationsOptionsTmp = []
          data.value.forEach((frequentHeaderSimulation: IFrequentHeaderSimulation,index: number) => {
            frequentSimulationsOptionsTmp.push({
              checkboxProps: {
                onChange: handleFrequentCheckboxPropsOnChange(
                  index,
                  frequentHeaderSimulation.id
                ),
              },
              months: `${frequentHeaderSimulation.deadline} meses`,
              kms: `${frequentHeaderSimulation.miles} Km/ano`,
              clientID: frequentHeaderSimulation.title,
              seeMoreConditionsLinkText: "Mostrar mais condições ",
              hideConditionsLinkText: "Ocultar condições ",
              id: frequentHeaderSimulation.id,
              rows: frequentHeaderSimulation.frequentSimulations.map((frequSimulation)=>{

                return {
                  term: {
                    label: "Prazo (meses)",
                    value: `${frequSimulation.deadline} meses`,
                  },
                  km: {
                    label: "Km",
                    value: `${frequSimulation.miles} Km`,
                  },
                  tyres: {
                    label: "Pneus",
                    value: frequSimulation.tires === true ? "Sim" : "Não",
                  },
                  insurance: {
                    label: "Seguro",
                    value: frequSimulation.insurance,
                  },
                  maintenance: {
                    label: "Manutenção",
                    value:
                      frequSimulation.maintenance === true ? "Sim" : "Não",
                  },
                  vs: {
                    label: "VS",
                    value:
                      frequSimulation.vehicleReplacementOptions === true
                        ? "Sim"
                        : "Não",
                    //FIXME Descomentar depois de adicionar o campo ao objecto SIMULATION na API
                  },
                  complementaryProductsNumber: {
                    label: "Produtos complementares",
                    value: "0",
                  },
                }
              }),
              hidden: false,
            });
          })
          setFrequentHeaderSimulations(data.value as IFrequentSimulation[]);
          setFrequentSimulationOptions(frequentSimulationsOptionsTmp);
        }
      })
    }
  }, [
    frequentSimulationOptions, selectedFrequentOption,
    currentUser.userId, handleFrequentCheckboxPropsOnChange
  ]);


  // const handleContractSettingsChange = useCallback(
  //   async (
  //     e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  //     vehicleIndex: number,
  //     simIndex: number
  //   ) => {
  //     let vehicles = vehicleDataTable;


  //     let simulationSelect =   JSON.parse(JSON.stringify(vehicles[vehicleIndex].simulations[simIndex]));
  //     if (
  //       e.target.name === "vehicleReplacementOptions" ||
  //       e.target.name === "tires" ||
  //       e.target.name === "secureOptions"
  //     ) {
  //       let checked = e.target.value === "true";
  //       //Remove valores da quantidade
  //       if (e.target.name === "vehicleReplacementOptions" && checked === false)
  //         simulationSelect = {
  //           ...simulationSelect,
  //           vsTheftVehicleType: "",
  //           vsTheftVehicle: "",
  //           vsAccidentVehicleType: "",
  //           vsAccidentVehicle: "",
  //           vsAssistanceVehicleType: "",
  //           vsAssistanceVehicle: "",
  //           vsOverhaulVehicleType: "",
  //           vsOverhaulVehicle: "",
  //           vehicleType: "",
  //           [e.target.name]: checked,
  //         };
  //       else if (e.target.name === "tires" && checked === false)
  //         simulationSelect = {
  //           ...simulationSelect,
  //           tiresQuantity: "0",
  //           [e.target.name]: checked,
  //         };
  //       else
  //         simulationSelect = {
  //           ...simulationSelect,
  //           [e.target.name]: checked,
  //         };
  //     } else if (e.target.name === "miles") {
  //       let round = convertNumber((convertNumber(e.target.value)/settings.Data.Vehicle_TyresValueCalc)*vehicles[vehicleIndex].number_of_wheels);
  //       let tiresQuant=0;
  //       if( Math.ceil(round)%2 === 0 ) {
  //         tiresQuant = Math.ceil(round);
  //       }
  //       else {
  //         tiresQuant = Math.floor(round);
  //       }

  //       simulationSelect = {
  //         ...simulationSelect,
  //         [e.target.name]:convertNumber(e.target.value),
  //         tiresQuantity:tiresQuant.toString()
  //       };
  //     } else if (e.target.name === "deadline") {

  //       simulationSelect = {
  //         ...simulationSelect,
  //         [e.target.name]:convertNumber(e.target.value),
  //       };
  //     } else if (e.target.name === "maintenance") {
  //       simulationSelect = {
  //         ...simulationSelect,
  //         [e.target.name]: e.target.value === "true",
  //       };
  //     } else if (e.target.name === "insurance") {
  //       // simulationSelect = {
  //       //   ...simulationSelect,
  //       //   [e.target.name]: e.target.value,
  //       // };


  //         simulationSelect = await initSelectedInsuranceDropDown(
  //         {
  //           ...simulationSelect,
  //           [e.target.name]: e.target.value,
  //         },
  //         vehicles[vehicleIndex].use
  //       );
  //       setVehicleDataTable((rows) =>
  //         rows.map((cur, index) => {
  //           if(index === vehicleIndex)
  //           {
  //             return {
  //               ...cur,
  //             simulations: cur.simulations.map((sim, sIndex)=> sIndex === simIndex ?
  //              {...simulationSelect, insurance: e.target.value, } :
  //               sim)
  //             }
  //           }
  //           return cur
  //         })
  //       );
  //       return;
  //     } else {
  //       simulationSelect = {
  //         ...simulationSelect,
  //         [e.target.name]: e.target.value,
  //       };
  //     }

  //     setVehicleDataTable((rows) =>
  //       rows.map((cur, index) => {
  //         if(index === vehicleIndex) {
  //           return { ...cur, simulations: cur.simulations.map((simRow, sIndex) => {
  //             if(sIndex === simIndex) {
  //               return simulationSelect;
  //             }
  //             else{
  //               return simRow;
  //             }
  //           }) }
  //         } else {
  //           return cur
  //         }
  //       })
  //     );
  //   },
  //   [
  //     vehicleDataTable,
  //     initSelectedInsuranceDropDown,
  //     settings.Data.Vehicle_TyresValueCalc
  //   ]
  // );


  const initReplacVehicleAssign= useCallback(async(dataRow:any[], segmentTypeVehicle:string): Promise<string> => {
    if (dataRow && dataRow.length > 0) {
      if(dataRow[0].vehicle_Selection !== "") {
        return dataRow[0].vehicle_Selection
      }
      else if (dataRow[0].vehicle_Selection_by_Segment===settings.Data.VehicleSegment[0].result[1].label) {
        return segmentTypeVehicle
      }
      else if(dataRow[0].vehicle_Selection_by_Segment !== "") {
        let segment = await vehiclesService.GetSegmentOrder(segmentTypeVehicle);
        if (segment) {
          if(dataRow[0].vehicle_Selection_by_Segment === settings.Data.VehicleSegment[0].result[0].label) {
            return segment.superior_Segment_Vehicle_Group
          }
          else {
            return segment.lower_Segment_Vehicle_Group
          }
        }
      }
    }
    else {
      return ""
    }
    return ""
  }, [settings]);

  const handleSimulationScenarioChange = useCallback(async (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let simula = simulationScenario;
    let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
    if(e.target.name === "useScenario"){
      // if(simula.insurance !==""){
      //   //  simula=await initSelectedInsuranceDropDown(simula,e.target.value,true);
      // }
      setUseScenario(e.target.value);
    }
    else if(e.target.name === "insurance"){
      simula = {...simula, [e.target.name]: e.target.value,};
      //setInsurance(e.target.value);

      let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.commercialConditions === commercialConditionsScenario)
      let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions.find(x => x.insurance === e.target.value)
      if(insurancePackageOption && insuranceFrameworkContractOption){
        simula.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
        simula.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
        simula.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
        simula.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

        simula.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
        simula.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
        simula.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
        simula.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
        simula.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

        simula.fuelOptions = insurancePackageOption.fuelOptions;
        simula.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

        simula.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions;
        simula.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions;
        simula.occupiersOptions = insurancePackageOption.occupiersOptions;
        simula.liabilityOptions = insurancePackageOption.liabilityOptions;
        simula.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions;

        simula.responsibility = insurancePackageOption.responsibility;
        simula.ownDamage = insurancePackageOption.ownDamage;
        simula.occupants = insurancePackageOption.occupants;
        simula.travelAssistance = insurancePackageOption.travelAssistance;
        simula.glassBreakage = insurancePackageOption.glassBreakage;

        // neste caso já temos as seguintes opções pre seleccionadas ao abrir a modal
        // simula.insurance = frequentSimulation.secureOptions ? insurancePackageOption.insurance : '';
        // simulation.secureOptions = insurancePackageOption.secureOptions;
        // simula.insurancePackageOptions = insuranceFrameworkContractOption.insurancePackageOptions.map(x => x.insurancePackageOption);
      }

      // if(useScenario !== ""){
      //   // simula=await initSelectedInsuranceDropDown(simula,useScenario,true);
      // }
    }
    else if (e.target.name === "vehicleType") {
      simula={...simula,
        vehicleType: e.target.value,
      };

      let segmentTypeVehicle=vehicleDataTable[0].group_Price_Map;
      let data =await vehiclesService.GetReplacVehicleAssignSetup(e.target.value);
      await Promise.all(settings.Data.ReplacementVehicleAssignment_Type[0].result.map(async element => {
        let dataRow=data.filter((x: { type: string; })=>x.type===element.label)
        switch(element.value) {
          case "0":
              await initReplacVehicleAssign(dataRow,segmentTypeVehicle).then((replaceVal)=>{
               simula = {
                  ...simula,
                  vsOverhaulVehicle:replaceVal,
                  vsOverhaulVehicleType:dataRow?.length>0?dataRow[0].vehicle_Type:"",//group_Price_Map:"",
                };
              })
            break;
          case "1":
            await initReplacVehicleAssign(dataRow,segmentTypeVehicle).then((replaceVal)=>{
               simula = {
                  ...simula,
                  vsAssistanceVehicle:replaceVal,
                  vsAssistanceVehicleType:dataRow?.length>0?dataRow[0].vehicle_Type:"",//.group_Price_Map:""
                };
            })
            break;
          case "2":
            await initReplacVehicleAssign(dataRow,segmentTypeVehicle).then((replaceVal)=>{
               simula = {
                  ...simula,
                  vsAccidentVehicle:replaceVal,
                  vsAccidentVehicleType:dataRow?.length>0?dataRow[0].vehicle_Type:"",//.group_Price_Map:""
                };
            })
            break;
          case "3":
            await initReplacVehicleAssign(dataRow,segmentTypeVehicle).then((replaceVal)=>{
               simula = {
                  ...simula,
                  vsTheftVehicle:replaceVal,
                  vsTheftVehicleType:dataRow?.length>0?dataRow[0].vehicle_Type:"",//.group_Price_Map:""
                };
            })
            break;
        }
      }))
    }
    else if (e.target.name === "iucCost") {
      simula = {...simula, [e.target.name]: convertNumber(e.target.value)};
    } else if (e.target.name === "runFlaat") {
      simula = {...simula, [e.target.name]: (e.target as any).checked};
    } else {
      simula = {...simula, [e.target.name]: e.target.value,};
    }
    setSimulationScenario(simula);

  }, [
    simulationScenario,
    commercialConditionsScenario,
    insuranceFrameworkContractSet,
    initReplacVehicleAssign,

    // validar estas duas dependencias
    // settings.Data.ReplacementVehicleAssignment_Type,
    // vehicleDataTable
  ]);

  const handleDriveCheckBoxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      let rowsToUpdate = selectedRows;
      let tmpVehicleDataTable = vehicleDataTable;
      setChangedProposal(true);
      rowsToUpdate.forEach((element) => {
        tmpVehicleDataTable[element].renew = e.target.checked;
      });
      setVehicleDataTable([...tmpVehicleDataTable]);
  }, [
    vehicleDataTable, selectedRows
  ]);

  const handleIncomeTypeDropDownChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      let rowsToUpdate = selectedRows;
      let tmpVehicleDataTable = vehicleDataTable;
      let opt=paymentTerms[1].result?.find((x: { value: string; })=>x.value===e.target.value);
      if(opt)
       setTypeofIncomeGroup(opt.value);

      setChangedProposal(true);
      rowsToUpdate.forEach((element) => {
        tmpVehicleDataTable[element].typeofIncome = e.target.value;
      });
      setVehicleDataTable([...tmpVehicleDataTable]);
  }, [
    vehicleDataTable, selectedRows,paymentTerms
  ]);

  const handleCommercialConditionsDropDownChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      let rowsToUpdate = selectedRows;
      // let tmpVehicleDataTable = vehicleDataTable;
      let tmpVehicleDataTable = [...vehicleDataTable];
      let errorDetails = '<ul>';
      let hasError = false;
      setChangedProposal(true);
      setCommercialConditionGroup(e.target.value);
      rowsToUpdate.forEach((element) => {
        tmpVehicleDataTable[element].commercialOptions.forEach(commercialOption => {
          if (commercialOption.value.includes(e.target.value)) {
            tmpVehicleDataTable[element].commercialConditions = e.target.value;
          }
        })
        if(tmpVehicleDataTable[element].commercialConditions !== e.target.value) {
          errorDetails +=  `<li>A condição comercial ${e.target.value} não consta da lista de condições comerciais para o tipo de aluger ${tmpVehicleDataTable[element].typeofRental} da viatura da linha ${element+1}.
          Por favor selecione o tipo de aluguer pretendido e em seguida seleccione a condição comercial pretendida.</li>`
          hasError = true;
        }
      });

      if(hasError) {
        errorDetails += '</ul>';
        openAlert({
          variant: "info",
          text: errorDetails,
          title: "Atenção",
          autoHideDuration: 5000,
        });
      }

      // tmpVehicleDataTable.map(async (vehicle,rowIndex:number) => {
      //   let rentalSettings = rentalTypeSettings.find((element) => { return element.value === vehicle.typeofRental})

      //   for(let index = 0; index < vehicle.simulations.length; index++) {
      //     const row = vehicle.simulations[index];
      //     row.vehicleReplacementOptions = rentalSettings.vs;
      //     row.maintenance = rentalSettings.maintenance;
      //     row.tires = rentalSettings.tyres;

      //     await initVehicleReplacementOptionsDropDown(
      //        row,
      //        vehicle.commercialConditions,true
      //     );

      //     if( rentalSettings.insurance) {
      //       vehicle.simulations.map(async (sim,simulationIndex) => {
      //         if (simulationIndex === index) {
      //           sim.secureOptions = rentalSettings.insurance;
      //           sim.insurance = sim.insurancePackageOptions?.length > 0 ?  sim.insurancePackageOptions[0].value : '';
      //         }
      //         return sim;
      //       })
      //       await initSelectedInsuranceDropDown(
      //          row,
      //          vehicle.use,true
      //       );
      //     }
      //     else {
      //       vehicle.simulations.map((sim,simulationIndex) => {
      //         if( simulationIndex === index){
      //           sim.secureOptions = rentalSettings.insurance;
      //           sim.insurance = '';
      //         }
      //         return sim;
      //       })
      //     }
      //   }
      //   return vehicle
      // })

      // setVehicleDataTable([...tmpVehicleDataTable]);

      let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
      const updatedVehicleData = tmpVehicleDataTable.map(async (vehicle, rowIndex) => {
        const rentalSettings = rentalTypeSettings.find((element) => element.value === vehicle.typeofRental);
        let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.typeofRental === vehicle.typeofRental && x.commercialConditions === vehicle.commercialConditions)

        if(!insuranceFrameworkContractOption) {
          let newInsuranceFrameworkContractOptions: InsuranceFrameworkContract = {
            typeofRental: vehicle.typeofRental,
            commercialConditions: vehicle.commercialConditions,
            insurancePackageOptions: []
          };
          let insurancePackageOptions: Option[] = [];

          insurancePackageOptions = await vehiclesService.getInsurancePackage(vehicle.commercialConditions);

          for(const insurancePackageOption of insurancePackageOptions ) {
            let insuranceOption: InsuranceOptions = {
              insurancePackageOption: {
                value: '',
                label: ''
              },

              vsOverhaulVehicleOptions: [],
              vsTheftVehicleOptions: [],
              vsAccidentVehicleOptions: [],
              vsAssistanceVehicleOptions: [],
              vehicleTypeOptions: [],

              vsOverhaulVehicleTypeOptions: [],
              vsTheftVehicleTypeOptions: [],
              vsAccidentVehicleTypeOptions: [],
              vsAssistanceVehicleTypeOptions: [],

              fuelOptions: [],
              viaVerdeOptions: [],

              insurance: '',
              vehicleDamageOptions: [],
              roadAssistanceOptions: [],
              occupiersOptions: [],
              liabilityOptions: [],
              isolatedGlassBreakOptions: [],

              responsibility: '',
              ownDamage: '',
              occupants: '',
              travelAssistance: '',
              glassBreakage: '',
              secureOptions: false,
            }

            insuranceOption.insurancePackageOption = insurancePackageOption;
            // insuranceOption.insurance = insurancePackageOption.value;
            insuranceOption = await initSimulationDetailsDropdowns(insuranceOption, vehicle.commercialConditions);


            if (!newInsuranceFrameworkContractOptions.insurancePackageOptions.some(element =>
                  element.insurancePackageOption.value === insurancePackageOption.value)
            ) {
              newInsuranceFrameworkContractOptions.insurancePackageOptions.push(insuranceOption)

              insuranceOption = await initVehicleReplacementOptionsDropDown(newInsuranceFrameworkContractOptions, insuranceOption,true);
              insuranceOption = await initSelectedInsuranceDropDown(insuranceOption, vehicle.use,true);
            }
          }
          insuranceFrameworkContractOption = newInsuranceFrameworkContractOptions
          if(!Array.from(tmpInsuranceSet).find(x => x.commercialConditions === vehicle.commercialConditions && x.typeofRental === vehicle.typeofRental)){
            tmpInsuranceSet.add(insuranceFrameworkContractOption)
          }
          setInsuranceFrameworkContractSet(tmpInsuranceSet)

        }



        const updatedSimulations = await Promise.all(
          vehicle.simulations.map(async (sim, simulationIndex) => {
            sim.vehicleReplacementOptions = rentalSettings.vs;
            sim.maintenance = rentalSettings.maintenance;
            sim.tires = rentalSettings.tyres;

            // await initVehicleReplacementOptionsDropDown(sim, vehicle.commercialConditions, true);
            let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions.find(x => x.insurance === sim.insurance) ?? insuranceFrameworkContractOption?.insurancePackageOptions[0]
            if(insurancePackageOption && insuranceFrameworkContractOption){
              sim.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
              sim.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
              sim.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
              sim.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

              sim.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
              sim.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
              sim.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
              sim.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
              sim.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

              sim.fuelOptions = insurancePackageOption.fuelOptions;
              sim.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

              sim.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions;
              sim.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions;
              sim.occupiersOptions = insurancePackageOption.occupiersOptions;
              sim.liabilityOptions = insurancePackageOption.liabilityOptions;
              sim.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions;

              sim.responsibility = insurancePackageOption.responsibility;
              sim.ownDamage = insurancePackageOption.ownDamage;
              sim.occupants = insurancePackageOption.occupants;
              sim.travelAssistance = insurancePackageOption.travelAssistance;
              sim.glassBreakage = insurancePackageOption.glassBreakage;

              sim.insurance = insurancePackageOption.insurance;
              sim.secureOptions = insurancePackageOption.secureOptions;
              sim.insurancePackageOptions = insuranceFrameworkContractOption.insurancePackageOptions.map(x => x.insurancePackageOption);
            }

            if (rentalSettings.insurance) {
              sim.secureOptions = rentalSettings.insurance;
              sim.insurance = sim.insurance !== '' ? sim.insurance : sim.insurancePackageOptions?.length > 0 ? sim.insurancePackageOptions[0].value : '';

              // Initialize selected insurance dropdown
              // await initSelectedInsuranceDropDown(sim, vehicle.use, true);


            } else {
              sim.secureOptions = rentalSettings.insurance;
              sim.insurance = '';
            }

            return sim;
          })
        );

        vehicle.simulations = updatedSimulations;

        return vehicle;
      });

      Promise.all(updatedVehicleData).then((updatedVehicles) => {
        setVehicleDataTable(updatedVehicles);
      });
  },[
    vehicleDataTable, selectedRows,
    rentalTypeSettings,
    initVehicleReplacementOptionsDropDown, initSelectedInsuranceDropDown,
    initSimulationDetailsDropdowns,
    insuranceFrameworkContractSet
  ]);


  const handleRentalTypeDropDownChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let rowsToUpdate = selectedRows;
    let tmpVehicleDataTable = vehicleDataTable;
    let opt=rentalTypes.find(x=>x.value===e.target.value);
    if(opt){
      setTypeOfRentalGroup(opt.value);
      setCommercialConditionGroup("");
    }
    setChangedProposal(true);
    rowsToUpdate.forEach((element) => {
      tmpVehicleDataTable[element].typeofRental = e.target.value;
      tmpVehicleDataTable[element].commercialConditions = "";
      //atualiza opções de cada linha
      vehiclesService.getCommercialConditions(e.target.value).then((data) => {
        if (data.succeeded === true) {
          tmpVehicleDataTable[element].commercialOptions = data.value;
        }
      });
    });

    setVehicleDataTable([...tmpVehicleDataTable]);
    //atualiza opções do cabeçalho
    vehiclesService.getCommercialConditions(e.target.value).then((data) => {
      if (data.succeeded === true) {
        setCommercialConditions(data.value);
      }
    });
  }, [vehicleDataTable, selectedRows,rentalTypes]);

  const handleDriverNameInputSearch = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason
    ) => {
      if (value.length > 3) {
        let allDriver = drivers.find((x) => {
          return x.label.toLowerCase().includes(value.toLocaleLowerCase());
        });
        if (selectedRows.length > 0) {
          if (!allDriver) {
            openAlert({
              variant: "info",
              text: `O Condutor ${value} não consta da lista de condutores do cliente da proposta.`,
              title: "Atenção",
              autoHideDuration: 3000,
            });
            // vehiclesService.getDriversByNameOrNo(value).then((data) => {
            //   if (data.succeeded) {
            //     setAllDrivers(data.value as Option[]);
            //   }
            // });
          }
        }
      }
      else if(value===""){
        setSearchDriver({value: "",label: ""});
      }
  }, [ drivers, selectedRows ]);

  const handleDriverNameSearchResultChange = useCallback(
    (e: React.SyntheticEvent<Element, Event>, value: any) => {
      let rowsToUpdate = selectedRows;
      let tmpVehicleDataTable = vehicleDataTable;
      if(rowsToUpdate.length===0){
            openAlert({
              variant: "error",
              text: "Não tem nenhum veículo selecionado!",
              title: "Atenção",
            });
      }
      else{
      if (value !== null) {
        rowsToUpdate.forEach((element) => {
          tmpVehicleDataTable[element].driverId = value.value;
          tmpVehicleDataTable[element].driverName = value.label;
          setSearchDriver({
            label: value.label,
            value: value.value,
          });
        });

      } else {
        vehiclesService.getDrivers(proposal.contactId).then((data) => {
          if (data.succeeded) {
            setAllDrivers(data.value);
          }
          setSearchDriver({
            label: "",
            value: "",
          });
        });
      }
      setVehicleDataTable([...tmpVehicleDataTable]);
      // setSearchDriver({
      //   label: "",
      //   value: "",
      // });
    }
  }, [vehicleDataTable, selectedRows ]);

  const handleSearchChange = useCallback(
    (e: React.SyntheticEvent<Element, Event>, value: any, rowIndex: number) => {
      if (value) {
        let driver = drivers.find((x) => {
          return x.value === value.value;
        });
        setVehicleDataTable((rows) => {
          rows.map((row, index) => {
            if (index === rowIndex && driver) {
              row.driverId = driver.value;
              row.driverName = driver.label;
              console.log(row);
            }
            return row;
          });
          return rows;
        });

      } else {
        handleDrivers(proposal.contactId);
        setVehicleDataTable((rows) =>
            rows.map((row, index) => {
              if (index === rowIndex) {
                row.driverId = "";
                row.driverName = "";
                console.log(row)
              }
              return row;
            })
          )
      }
      setVehicleDataTable([...vehicleDataTable]);
  }, [
    vehicleDataTable, proposal.contactId, handleDrivers, drivers
  ]);

  // const handleContractTypeFilterChange: TextFieldProps["onChange"] =
  //   useCallback(
  //     async (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //       let splits = e.target.name.split("-");
  //       const name = splits[1];
  //       const rowIndex =convertNumber(splits[0]);
  //       //Alteração das colunas de cada linha do veiculo
  //       let rowVehicle = vehicleDataTable[rowIndex];
  //       let indexLease=-1;
  //       if (name === "typeofRental")
  //         indexLease= vehicleDataTable.findIndex(x => x.typeofRental === e.target.value && x.leaseQuoteNo !== "");

  //       vehicleDataTable[rowIndex] = { ...rowVehicle, [name]: e.target.value };

  //       if (name === "typeofRental") {

  //         vehiclesService
  //           .getCommercialConditions(e.target.value)
  //           .then((data) => {
  //             if (data.succeeded === true) {
  //               if(indexLease>=0)
  //                 vehicleDataTable[rowIndex].leaseQuoteNo=vehicleDataTable[indexLease].leaseQuoteNo;
  //               else
  //                 vehicleDataTable[rowIndex].leaseQuoteNo="";
  //               vehicleDataTable[rowIndex].commercialOptions = data.value;
  //               vehicleDataTable[rowIndex].commercialConditions = "";
  //               setVehicleDataTable(vehicleDataTable);
  //             }
  //           });
  //       }
  //       else if (name === "quantity") {
  //         vehicleDataTable[rowIndex].simulations[0].quantity =convertNumber(
  //           e.target.value
  //         );
  //       }

  //       else if(name === "commercialConditions") {
  //         rowVehicle = vehicleDataTable[rowIndex];
  //         let rentalSettings = rentalTypeSettings.find((element) => { return element.value === rowVehicle.typeofRental})
  //         for(let index = 0; index < rowVehicle.simulations.length; index++) {
  //           // const row = rowVehicle.simulations[index];
  //           let row = rowVehicle.simulations[index];
  //           row.vehicleReplacementOptions = rentalSettings.vs;
  //           row.maintenance = rentalSettings.maintenance;
  //           row.tires = rentalSettings.tyres;


  //           await initVehicleReplacementOptionsDropDown(
  //             row,
  //             rowVehicle.commercialConditions
  //           );

  //           if( rentalSettings.insurance) {
  //             rowVehicle.simulations.forEach((sim,simulationIndex) => {
  //               if (simulationIndex === index) {
  //                 sim.secureOptions = rentalSettings.insurance;
  //                 sim.insurance = row.insurancePackageOptions?.length > 0 ?  sim.insurancePackageOptions[0].value : '';
  //               }
  //               return sim;
  //             })
  //             await initSelectedInsuranceDropDown(
  //               row,
  //               rowVehicle.use
  //             );
  //           }
  //           else {
  //             rowVehicle.simulations.forEach((sim,simulationIndex) => {
  //               if( simulationIndex === index){
  //                 sim.secureOptions = rentalSettings.insurance;
  //                 sim.insurance = '';
  //               }
  //               return row;
  //             })
  //           }
  //           vehicleDataTable[rowIndex] = rowVehicle;
  //         }
  //       }
  //       setVehicleDataTable([...vehicleDataTable]);
  //   }, [
  //     vehicleDataTable, initVehicleReplacementOptionsDropDown,
  //     initSelectedInsuranceDropDown, rentalTypeSettings
  //   ]);

  const validateRequiredFields = useCallback((): boolean => {
    if (searchClient === null) {
      openAlert({
        variant: "error",
        text: "Necessário criar ou selecinar cliente!",
        title: "Alerta",
      });
      return false;
    }
    if (nifValidate === true) {
      openAlert({
        variant: "error",
        text: "Nif mal definido!",
        title: "Alerta",
      });
      return false;
    }
    if (emailValidate === true) {
      openAlert({
        variant: "error",
        text: "Email mal definido!",
        title: "Alerta",
      });
      return false;
    }
    if (mobileValidate === true) {
      openAlert({
        variant: "error",
        text: "Telefone mal definido!",
        title: "Alerta",
      });
      return false;
    }
    if (phoneValidate === true) {
      openAlert({
        variant: "error",
        text: "Telemóvel mal definido!",
        title: "Alerta",
      });
      return false;
    }
    if (
      requiredClientData.name === "" ||
      requiredClientData.vaT_Registration_No === "" ||
      requiredClientData.customerType === ""
      //||
      //requiredClientData.totalFleet === 0
    ) {
      openAlert({
        variant: "error",
        text: "Necessário preencher os campos obrigatórios!",
        title: "Alerta",
      });
      return false;
    }

    setEnableWizardStep1(true);
    // if (open){
    //   setOpen(false)
    // }
    return true;
  }, [
    requiredClientData,
    searchClient,
    nifValidate,
    emailValidate,
    mobileValidate,
    phoneValidate,
    // open
    // proposalId,
  ]);

  const validateStep2 = useCallback(():boolean => {
   if (vehicleDataTable.some(x=>x.typeofIncome==="" || x.commercialConditions==="" || x.typeofRental==="" || x.quantity===0)) {
     if(step === 2){
        openAlert({
          variant: "error",
          text: "Necessário preencher os campos Tipo de aluguer | Condições comerciais | Tipo de Renda | Quantidade!",
          title: "Alerta",
        });
      }
      setEnableWizardStep3(false);
      return false
    }
    setEnableWizardStep3(true);
    return true;
    // if (open){
    //   setOpen(false)
    // }
  }, [ vehicleDataTable,step ]);

  const onBlurNif: TextFieldProps["onBlur"] = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e.target.value === "") {
        setNifValidate(false);
        setNifMessage("");
        setNifIcon("");
      } else if (validateNIF(e.target.value) === false) {
        setNifValidate(true);
        setNifMessage("Nif mal definido!");
        setNifIcon("");
      } else {
        setNifValidate(false);
        setNifMessage("");
        setNifIcon(SuccessIcon);
      }
    },
    []
  );

  const onBlurEmail: TextFieldProps["onBlur"] = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e.target.value === "") {
        setEmailValidate(false);
        setEmailMessage("");
        setEmailIcon("");
      } else if (validateEmail(e.target.value) === false) {
        setEmailValidate(true);
        setEmailMessage("Email mal definido!");
        setEmailIcon("");
      } else {
        setEmailValidate(false);
        setEmailMessage("");
        setEmailIcon(SuccessIcon);
      }
    },
    []
  );

  const onBlurNumberPhone: TextFieldProps["onBlur"] = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e.target.value === "") {
        setPhoneValidate(false);
        setPhoneMessage("");
        setPhoneIcon("");
      } else if (validatePhoneNumber(e.target.value) === false) {
        setPhoneValidate(true);
        setPhoneMessage("Telefone mal definido!");
        setPhoneIcon("");
      } else {
        setPhoneValidate(false);
        setPhoneMessage("");
        setPhoneIcon(SuccessIcon);
      }
    },
    []
  );

  const onBlurNumberMobile: TextFieldProps["onBlur"] = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e.target.value === "") {
        setMobileValidate(false);
        setMobileMessage("");
        setMobileIcon("");
      } else if (validatePhoneNumber(e.target.value) === false) {
        setMobileValidate(true);
        setMobileMessage("Telemóvel mal definido!");
        setMobileIcon("");
      } else {
        setMobileValidate(false);
        setMobileMessage("");
        setMobileIcon(SuccessIcon);
      }
    },
    []
  );

  const onChangeNameInter: TextFieldProps["onChange"] = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setChangedContact(true);
      setRequiredClientInterlocutorData({
        ...requiredClientInterlocutorData,
        name: e.target.value,
      });
    },
    [requiredClientInterlocutorData]
  );

  const onChangeMobileInter: TextFieldProps["onChange"] = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setChangedContact(true);
      setRequiredClientInterlocutorData({
        ...requiredClientInterlocutorData,
        mobile_Phone_No: e.target.value,
      });
    },
    [requiredClientInterlocutorData]
  );

  const onChangePhoneInter: TextFieldProps["onChange"] = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setChangedContact(true);
      setRequiredClientInterlocutorData({
        ...requiredClientInterlocutorData,
        phoneNo: e.target.value,
      });
    },
    [requiredClientInterlocutorData]
  );

  const onChangeEmaiInter: TextFieldProps["onChange"] = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setChangedContact(true);
      setRequiredClientInterlocutorData({
        ...requiredClientInterlocutorData,
        e_Mail: e.target.value,
      });
    },
    [requiredClientInterlocutorData]
  );

  const onChangeClientTypeRadioGroupProps = useCallback((event: ChangeEvent<HTMLInputElement>, value: string) => {
    let requiredClientDataToUpdate = requiredClientData;
    setRequiredClientData({
        ...requiredClientDataToUpdate,
        type: event.target.value, customerTypePortal:event.target.value
      })
      setChangedContact(true);
  },[requiredClientData])

  const updateSearchOptions = useCallback((item: Option, no: string, vehicles: IVehicle[]) => {
    //ADICIONA
    if(item.value !== ""){
      var searchIndex = vehicleSearchOptions.findIndex((x) => {
        return x.value === item.value;
      });
      var dataIndex = vehicles.findIndex((d) => {
        return d.versionId === item.value;
      });
      if (searchIndex === -1 && dataIndex > -1) vehicleSearchOptions.push(item);
    }

    //REMOVE
    if (no) {
      let indexRemove = vehicleSearchOptions.findIndex((x) => x.value === no);
      var vehicleCont = vehicles.filter((x) => x.versionId === no).length;

      if (indexRemove > -1 && vehicleCont === 0) {
        vehicleSearchOptions.splice(indexRemove, 1);
      }
      if (vehicles.length === 0) {
        setVehicleSearchOptions([]);
        setVehiclesListingTotal(vehicles.length);
      }
    }
  }, [vehicleSearchOptions]);

  const handleSearchOptionsAndVersionCaracteristics = useCallback((vehicleTmp: IVehicle, vehicles: IVehicle[]) => {
    let versionOption: IVersion = {
      makeId: "",
      modelId: "",
      versions: [
        {
          label: "",
          value: "",
          isInactive: false
        },
      ],
    };
    let option: Option | undefined = {
      label: "",
      value: "",
    };

    const searchIndex = versionsSearchOptions.findIndex(
      (a) => a.makeId === vehicleTmp?.makeId
    );

    let versionsTmp = versionsSearchOptions;
    let versionFilter: { type: AdvancedFilterVersionsOptions; value: string }[] = [];
    versionFilter.push({
      type: AdvancedFilterVersionsOptions.Make,
      value: vehicleTmp.makeId,
    });


    let allMakesVersions = versionsSearchOptions;
    const distinctMakes: string[] = [];
    const map = new Map();
    for (const item of allMakesVersions) {
      if (!map.has(item.makeId)) {
        map.set(item.makeId, item.makeId);
        distinctMakes.push(item.makeId);
      }
    }
    const selectedMakes = vehicles.map((item: { makeId: string; }) => item.makeId)
      .filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);

    selectedMakes.forEach((selectedMake: string) => {
      const filters = VehicleTypeStatusHelper.generateAdvancedFilterVersionsOptions(true);
      filters.push({
        type: AdvancedFilterVersionsOptions.Make,
        value: selectedMake,
      });
      vehiclesService.getVersionsToCombo(filters).then((data) => {
        if (data.value.length > 0) {

          if (data.value !== undefined && data.value[0] !== undefined) {

            const makeVersions = data.value.map(
              (item: {
                label: string;
                value: string;
                isInactive: boolean;
              }) => {
                return {
                  label: item.label,
                  value: item.value,
                  isInactive: false
                };
              }
            );

            if (makeVersions && makeVersions.length > 0) {
              const currentMakeIndex = allMakesVersions.findIndex((x) => x.makeId === selectedMake);

              if (currentMakeIndex >= 0) {
                if(allMakesVersions[currentMakeIndex].versions.length <= 1){
                  allMakesVersions[currentMakeIndex].versions = makeVersions; //.filter((x: { status: string; }) => x.status !== "Inactive");
                  setVersionsSearchOptions((rows) => rows = allMakesVersions);
                }
              }
            }

            //BEGIN validação opções de pesquisa
            option = {
              // label: `${vehicleTmp.makeId} ${vehicleTmp.modelDescription}; ${vehicleTmp.engineCc}CC; ${vehicleTmp.powerHP}CV; ${vehicleTmp.quantity_of_Seats} lugares; ${vehicleTmp.numberofDoors} portas`,
              // label: `${vehicleTmp.versionDescription}; ${vehicleTmp.engineCc}CC; ${vehicleTmp.powerHP}CV; ${vehicleTmp.quantity_of_Seats} lugares; ${vehicleTmp.numberofDoors} portas`,
              label: `${vehicleTmp.versionDescription}; ${vehicleTmp.caracteristics}`,
              value: vehicleTmp.versionId ,
            }
            updateSearchOptions(option , '', vehicles);
            //END validação opções de pesquisa
           var inactiveVehicleTypeStatus: string = VehicleTypeStatus[VehicleTypeStatus.Inactive];
            versionOption = {
              makeId: vehicleTmp.makeId,
              modelId:vehicleTmp.modelId,
              versions: [{
                // label: `${vehicleTmp.makeId} ${vehicleTmp.modelDescription}; ${vehicleTmp.engineCc}CC; ${vehicleTmp.powerHP}CV; ${vehicleTmp.quantity_of_Seats} lugares; ${vehicleTmp.numberofDoors} portas`,
                // label: `${vehicleTmp.versionDescription.replace(vehicleTmp.makeId+" - ","").trim()}; ${vehicleTmp.engineCc}CC; ${vehicleTmp.powerHP}CV; ${vehicleTmp.quantity_of_Seats} lugares; ${vehicleTmp.numberofDoors} portas`,
                label: `${vehicleTmp.versionDescription.replace(vehicleTmp.makeId+" - ","").trim()}; ${vehicleTmp.caracteristics}`,
                value: vehicleTmp.versionId,
                isInactive: vehicleTmp.status === inactiveVehicleTypeStatus
              }]
            };
            versionOption.versions.forEach(item => {
              if(versionsTmp[searchIndex]?.versions.find(ver =>  {return  ver.value === item.value}) === (null || undefined))
              {
                versionsTmp[searchIndex]?.versions.push(item);
              }
            });
            setVersionsSearchOptions([...versionsTmp]);
            //END validação da descrição do modelo e carregamento de versões
          }
        }
      });

    });
  },[
    versionsSearchOptions,
    updateSearchOptions,
  ]);

  const handleNewClientCheckboxChange: CheckboxProps["onChange"] = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsNewClient(checked);
    setRequiredClientData(emptyRequiredData);
    setRequiredClientInterlocutorData(emptyRequiredData);
    setSearchClient({ value: "", label: "" });
  },[]);

  const addProposal = useCallback(
    async (publish: boolean) => {
      let response: APIResponse;
      clientsUpdate.length = 0;
      setClientsUpdate(clientsUpdate);
      if (
        isNewClient === true &&
        (requiredClientData.no === undefined || requiredClientData.no === "")
      ) {
        clientsUpdate.push(requiredClientData);
        clientsUpdate.push(requiredClientInterlocutorData);
        response = await contactService.postContacts(clientsUpdate);
        if (response === null) {
          return false;
        } else if (response && !response.succeeded) {
          let errorDetails = "";
          const message = response.message ? response.message : "";
          if (response.errors.length > 0) {
            errorDetails += " Detalhes: <ul>";
            response.errors.forEach(
              (error: string) => (errorDetails += "<li>" + error + "</li>")
            );
            errorDetails += "</ul>";
          }

          openAlert({
            variant: "error",
            text: message + errorDetails,
            title: "Atenção",
          });
          return false;
        } else {
          if (response?.value[0]) {
            proposal.contactId = response?.value[0].no;
            requiredClientData.no = response?.value[0].no;
          }
          if (response?.value[1]) {
            proposal.webPortalContactId = response?.value[1].no;
            requiredClientInterlocutorData.no = response?.value[1].no;
          }
          setRequiredClientData(requiredClientData);
          setRequiredClientInterlocutorData(requiredClientInterlocutorData);
        }
      } else if (changedContact) {
        setChangedProposal(true);
        clientsUpdate.push(requiredClientData);
        clientsUpdate.push(requiredClientInterlocutorData);
        response = await contactService.updateContacts(clientsUpdate);
        if (!response.succeeded) {
          let errorDetails = "";
          const message = response.message ? response.message : "";
          if (response.errors.length > 0) {
            errorDetails += " Detalhes: <ul>";
            response.errors.forEach(
              (error: string) => (errorDetails += "<li>" + error + "</li>")
            );
            errorDetails += "</ul>";
          }

          openAlert({
            variant: "error",
            text: message + errorDetails,
            title: "Atenção",
          });
          return false;
        } else {
          if (response?.value[0]) {
            proposal.contactId = response?.value[0].no;
            requiredClientData.no = response?.value[0].no;
          }
          if (response?.value[1]) {
            proposal.webPortalContactId = response?.value[1].no;
            requiredClientInterlocutorData.no = response?.value[1].no;
          }
          setRequiredClientData(requiredClientData);
          setRequiredClientInterlocutorData(requiredClientInterlocutorData);
        }
      }

      let proposalRequest: UpsertProposalRequest = {
        proposal: proposal,
        rentalTypeId: "",
        commercialConditionsId: "",
        publish: publish,
      };
      if (publish) {
        if (vehicleDataTable && vehicleDataTable.length > 0) {
          proposalRequest.rentalTypeId = vehicleDataTable[0].typeofRental;
          proposalRequest.commercialConditionsId = vehicleDataTable[0].commercialConditions;
        }
      }
      response= await proposalService.upsert(proposalRequest);
      if (response.succeeded) {
        setProposal(response.value);
      }
      else
      {
        let errorDetails = "";
        const message = response.message ? response.message : "";
        if (response.errors.length > 0) {
          errorDetails += " Detalhes: <ul>";
          response.errors.forEach(
            (error: string) => (errorDetails += "<li>" + error + "</li>")
          );
          errorDetails += "</ul>";
        }

        openAlert({
          variant: "error",
          text: message + errorDetails,
          title: "Atenção",
        });
        return false;
      }

      return true;

  },[
    requiredClientData,requiredClientInterlocutorData,isNewClient,proposal,
    // isDetailsStep2VehicleOpen,
    changedContact, clientsUpdate, vehicleDataTable
  ]);

  const addNewSimulation = useCallback(async(rowIndex:number)=> {

    let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
    let insuranceFrameworkContractOptions: InsuranceFrameworkContract = {
      typeofRental: '',
      commercialConditions: '',
      insurancePackageOptions: []
    }

    let insurancePackageOptions: Option[] = []

    vehiclesService.getRentalTypes().then(async(data) => {
      if(data.succeeded) {
        let vehicle = vehicleDataTable[rowIndex];
        let rentalSettings = data.value.find((element:any) => { return element.value === vehicle.typeofRental})
        let simulationsNew:Simulation[] = [];
        if(vehicle.simulations && vehicle.simulations.length > 0) {
          simulationsNew = [...vehicle.simulations];
        }
        simulationsNew.push({
          id:0,
          vehicleId:vehicleDataTable[rowIndex].id,
          no:"",
          deadline:0,
          miles:0,
          tires: rentalSettings.tyres,
          tiresQuantity: "0",
          insurance:"",
          mostUsed:0,
          maintenance: rentalSettings.maintenance,
          secureOptions:false,
          vehicleReplacementOptions: rentalSettings.vs,
          ownDamage:"",
          occupants:"",
          travelAssistance:"",
          responsibility:"",
          glassBreakage:"",
          maintenanceDescription:"",
          iuc:0,
          fuelCard:false,
          petrolPump:false,
          viaVerde:false,
          entityViaVerde:"",
          fuelDescriptiom:"",
          runFlaat:false,
          vehicleType:"",
          vsOverhaulVehicle:"",
          vsOverhaulVehicleType:"",
          vsAssistanceVehicleType:"",
          vsAssistanceVehicle:"",
          vsAccidentVehicleType:"",
          vsAccidentVehicle:"",
          vsTheftVehicle:"",
          vsTheftVehicleType:"",
          insurancePackageOptions:[],
          vehicleDamageOptions:[],
          occupiersOptions:[],
          roadAssistanceOptions:[],
          liabilityOptions:[],
          fuelOptions:[],
          viaVerdeOptions:[],
          isolatedGlassBreakOptions:[],
          vehicleTypeOptions:[],
          vsAssistanceVehicleOptions:[],
          vsAssistanceVehicleTypeOptions:[],
          vsAccidentVehicleOptions:[],
          vsAccidentVehicleTypeOptions:[],
          vsOverhaulVehicleOptions:[],
          vsOverhaulVehicleTypeOptions:[],
          vsTheftVehicleOptions:[],
          vsTheftVehicleTypeOptions:[],
          //campos das cotações
          priceexclVAT: vehicle.priceexclVAT,
          consumersPrice: vehicle.consumersPrice,
          totalExtraWithoutTax: vehicle.totalExtraWithoutTax,
          totalExtraWithTax: vehicle.totalExtraWithTax,
          totalAccessoriesWithout: vehicle.totalAccessoriesWithout,
          totalAccessoriesWith: vehicle.totalAccessoriesWith,
          discountWithoutTax: vehicle.discountWithoutTax,
          discountWithTax: vehicle.discountWithTax,
          discountWithTaxPercentage: vehicle.discountWithTaxPercentage,
          discountWithTaxTotal: vehicle.discountWithTaxTotal,
          proformaInvoicePriceWithoutTax: vehicle.proformaInvoicePriceWithoutTax,
          proformaInvoicePriceWithTax: vehicle.proformaInvoicePriceWithTax,
          campaignWithoutTax: vehicle.campaignWithoutTax,
          campaignWithTax: vehicle.campaignWithTax,
          campaignWithTaxPercentage: vehicle.campaignWithTaxPercentage,
          campaignWithTaxTotal: vehicle.campaignWithTaxTotal,
          rappelWithoutTax: vehicle.rappelWithTaxTotal,
          rappelWithTax: vehicle.rappelWithTax,
          rappelWithTaxPercentage: vehicle.rappelWithTaxPercentage,
          rappelWithTaxTotal: vehicle.rappelWithTaxTotal,
          extraRappel: vehicle.extraRappel,
          businessValueWithoutTax: vehicle.businessValueWithoutTax,
          businessValueWithTax: vehicle.businessValueWithTax,
          //tipo de contrato
          quantity: vehicle.quantity,
          // quotation calculated fields
          baseIncomeWithoutTax: 0,
          incomeWithoutTax: 0,
          incomeWithTax: 0,
          increaseVR: 0,
          margin: 0,
          realVR: 0,
          vrEurotax: 0,
          vrTable: 0,
          baseVRTable:0,

          amortization: 0,
          interests: 0,
          //validar se estes 4 campos estão duplicados
          tyresCost: 0,
          maintenanceCost: 0,
          iucCost: vehicle.iuc,
          insuranceCost: 0,
          vsCost: 0,

          finalTax: 0,
          estimatedMargin: 0,
          monthlyTCOWithTax: 0,
          monthlyTCOWithoutTax: 0,
          additionalKmsWithTax: 0,
          additionalKmsWithoutTax: 0,
          collateral: 0,
          fee: 0,
          initialEntry: 0,
          travelledKmsWithTax: 0,
          travelledKmsWithoutTax: 0,
          proposeToCustomer: false,
          title: "",
          visibility: 0,
          complementaryProducts: [],
          maximum_Kilometers:0,
          contractExpenses: 0,
          commissionValue: 0,
          commissionCode: ""
        });

        vehicle.simulations = simulationsNew;

        //verifica se existem opções pre-carregadas com o tipo de renda e condições comerciais da viatura
        //se não existirem
        if(!Array.from(tmpInsuranceSet).some(item =>
          item.typeofRental === vehicle.typeofRental && item.commercialConditions === vehicle.commercialConditions
          // && item.insurancePackageOptions.some(element => element.insurancePackageOption.value === insurancePackageOption.value)
        )) {
          // carrega as opções de cada pacote de seguros
          insurancePackageOptions = await vehiclesService.getInsurancePackage(vehicle.commercialConditions);

          for(const insurancePackageOption of insurancePackageOptions ) {
            let insuranceOption: InsuranceOptions = {
              insurancePackageOption: {
                value: '',
                label: ''
              },
              // lista de viaturas de substiuição mediante o tipo de seguro
              vsOverhaulVehicleOptions: [],
              vsTheftVehicleOptions: [],
              vsAccidentVehicleOptions: [],
              vsAssistanceVehicleOptions: [],
              // pactoe de opções de viaturas de substituição
              vehicleTypeOptions: [],
              // lista de tipos de substituição mediante o tipo de seguro
              vsOverhaulVehicleTypeOptions: [],
              vsTheftVehicleTypeOptions: [],
              vsAccidentVehicleTypeOptions: [],
              vsAssistanceVehicleTypeOptions: [],

              fuelOptions: [],
              viaVerdeOptions: [],
              // pacote de seguros
              insurance: '',
              // lista de tipos de seguros
              vehicleDamageOptions: [],
              roadAssistanceOptions: [],
              occupiersOptions: [],
              liabilityOptions: [],
              isolatedGlassBreakOptions: [],
              // opções de seguros escolhidas
              responsibility: '',
              ownDamage: '',
              occupants: '',
              travelAssistance: '',
              glassBreakage: '',
              // radio button de inclusão/exclusão de seguros
              secureOptions: false,
            }
            insuranceFrameworkContractOptions.typeofRental = vehicle.typeofRental;
            insuranceFrameworkContractOptions.commercialConditions = vehicle.commercialConditions;

            insuranceOption.insurancePackageOption = insurancePackageOption;
            // insuranceOption.insurance = insurancePackageOption.value;
            insuranceOption = await initSimulationDetailsDropdowns(insuranceOption, vehicle.commercialConditions);


            if (!insuranceFrameworkContractOptions.insurancePackageOptions.some(element => element.insurancePackageOption.value === insurancePackageOption.value) ) {
              insuranceFrameworkContractOptions.insurancePackageOptions.push(insuranceOption)

              insuranceOption = await initVehicleReplacementOptionsDropDown(insuranceFrameworkContractOptions, insuranceOption,true);
              insuranceOption = await initSelectedInsuranceDropDown(insuranceOption, vehicle.use,true);

            }
          }
          tmpInsuranceSet.add(insuranceFrameworkContractOptions)
        }
        // se existir inicializa as opções de seguro para o tipo de renda e condições comerciais da viatura
        else {
          let insurancePackage = Array.from(tmpInsuranceSet).find(item =>
            item.typeofRental === vehicle.typeofRental &&
            item.commercialConditions === vehicle.commercialConditions
          )
          if(insurancePackage){
            insuranceFrameworkContractOptions = insurancePackage
            let options: InsuranceOptions[] = insurancePackage.insurancePackageOptions;
            options.forEach(element => {
              insurancePackageOptions.push(element.insurancePackageOption)
            })
          } else {
            insurancePackageOptions = []
          }
        }
        setInsuranceFrameworkContractSet(tmpInsuranceSet)

        if( rentalSettings.insurance) {
          vehicle.simulations.forEach((row) => {
            //mapeamento de campos base e opções default

            // se o utilizador é administrador
            if( currentUser.isAdministrator) {
              // carrega as opções de pacotes de seguros disponíveis para selecção
              row.insurancePackageOptions = insurancePackageOptions; //insuranceFrameworkContractOptions.insurancePackageOptions;

              // carrega valores de seguros associados ao pacote
              let insurancePackageOption = insuranceFrameworkContractOptions.insurancePackageOptions[0];
              // caso lista de seguros esteja preencida, selecciona primeiro pacote de seguros como default, senão deixa vazio
              row.insurance = insurancePackageOption.insurance ?? row.insurancePackageOptions[0].value ?? '';
              // indica se o pacote de seguros está seleccionado ou não
              row.secureOptions= insurancePackageOption.secureOptions ?? rentalSettings.insurance;

              row.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions;
              row.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions;
              row.occupiersOptions = insurancePackageOption.occupiersOptions;
              row.liabilityOptions = insurancePackageOption.liabilityOptions;
              row.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions;

              row.responsibility = insurancePackageOption.responsibility;
              row.ownDamage = insurancePackageOption.ownDamage;
              row.occupants = insurancePackageOption.occupants;
              row.travelAssistance = insurancePackageOption.travelAssistance;
              row.glassBreakage = insurancePackageOption.glassBreakage;

              // opções não impactadas diretamente pelos perfis, mas impactas pelo pacote de seguro
              row.fuelOptions = insurancePackageOption.fuelOptions;
              row.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

              // carrega viaturas de substituição associadas
              row.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
              row.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
              row.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
              row.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

              row.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
              row.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
              row.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
              row.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
              row.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

            } else {
              // 1. se o utilizador não é administrador
              // valida quais os valores permitidos para preenchimento
              // 2. se nas opções de pacotes de seguros não existirem valores permitidos para o perfil do utilizador
              // mantém os valores default(vazios) pelo que não há necessidade de alterar os mapeamentos

              // carrega as opções de pacotes de seguros disponíveis para selecção
              if(insurancePackageOptions && insurancePackageOptions.length > 0){
                if(insurancePackageOptions.some(ipo => currentUser.profile?.profileInsurances.some(pi => pi.value.split('-')[0] === ipo.value))) {
                  let allowedProfileInsuranceValues = insurancePackageOptions.filter(ipo => currentUser.profile?.profileInsurances.some(pi => pi.value.split('-')[0] === ipo.value));
                  row.insurancePackageOptions = allowedProfileInsuranceValues;
                }
              }

              //se nas opções de pacotes de seguros existirem valores permitidos para o perfil do utilizador
              let insurancePackageOption: InsuranceOptions = emptyInsuranceOption;
              if(insuranceFrameworkContractOptions.insurancePackageOptions.some(ipo => currentUser.profile?.profileInsurances.some(pi => pi.value.split('-')[0] === ipo.insurance))){
                insurancePackageOption = insuranceFrameworkContractOptions.insurancePackageOptions.filter(ipo => currentUser.profile?.profileInsurances.some(pi => pi.value.split('-')[0] === ipo.insurance))[0];

                // carrega valores de seguros associados ao pacote
                // let insurancePackageOption = insuranceFrameworkContractOptions.insurancePackageOptions[0];
                // caso lista de seguros esteja preencida, selecciona primeiro pacote de seguros como default, senão deixa vazio
                row.insurance = insurancePackageOption.insurance ?? row.insurancePackageOptions[0].value ?? '';
                // indica se o pacote de seguros está seleccionado ou não
                row.secureOptions= insurancePackageOption.secureOptions ?? rentalSettings.insurance;

                // se os valores pré carregados, não coincidirem com as valores permitidos pelo perfil
                // mantém os valores default(vazios)
                // senão, filtra os valores pré carregados pelos valores do perfil
                //own damange dropdown options
                if(insurancePackageOption.vehicleDamageOptions.some(vdo => currentUser.profile?.profileVehicleDamages.some(vd => vd.value.split('-')[0] === vdo.value))) {
                  row.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions.filter(vdo => currentUser.profile?.profileVehicleDamages.some(vd => vd.value.split('-')[0] === vdo.value));
                }
                // road assistance dropdown options
                if(insurancePackageOption.roadAssistanceOptions.some(vra => currentUser.profile?.profileRoadAssistances.some(ra => ra.value.split('-')[0] === vra.value))) {
                  row.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions.filter(vra => currentUser.profile?.profileRoadAssistances.some(ra => ra.value.split('-')[0] === vra.value));
                }
                // occupiers dropdown options
                if(insurancePackageOption.occupiersOptions.some(vo => currentUser.profile?.profileOccupiers.some(o => o.value.split('-')[0] === vo.value))) {
                  row.occupiersOptions = insurancePackageOption.occupiersOptions.filter(vo => currentUser.profile?.profileOccupiers.some(o => o.value.split('-')[0] === vo.value));
                }
                // liability dropdown options
                if(insurancePackageOption.liabilityOptions.some(vdo => currentUser.profile?.profileLiabilities.some(vd => vd.value.split('-')[0] === vdo.value))) {
                  row.liabilityOptions = insurancePackageOption.liabilityOptions.filter(vdo => currentUser.profile?.profileLiabilities.some(vd => vd.value.split('-')[0] === vdo.value));
                }
                // glass breakage dropdown options
                if(insurancePackageOption.isolatedGlassBreakOptions.some(vdo => currentUser.profile?.profileIsolatedGlassBreaks.some(vd => vd.value.split('-')[0] === vdo.value))) {
                  row.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions.filter(vdo => currentUser.profile?.profileIsolatedGlassBreaks.some(vd => vd.value.split('-')[0] === vdo.value));
                }
                // liability pre-selected value
                if(currentUser.profile?.profileLiabilities.some(x => x.value.split('-')[0] === insurancePackageOption.responsibility)){
                  row.responsibility = insurancePackageOption.responsibility;
                }
                // own damage pre-selected value
                if(currentUser.profile?.profileVehicleDamages.some(x => x.value.split('-')[0] === insurancePackageOption.ownDamage)){
                  row.ownDamage = insurancePackageOption.ownDamage;
                }
                // occupier pre-selected value
                if(currentUser.profile?.profileOccupiers.some(x => x.value.split('-')[0] === insurancePackageOption.occupants)){
                  row.occupants = insurancePackageOption.occupants;
                }
                // road assistance pre-selected value
                if(currentUser.profile?.profileRoadAssistances.some(x => x.value.split('-')[0] === insurancePackageOption.travelAssistance)){
                  row.travelAssistance = insurancePackageOption.travelAssistance;
                }
                // glass breakage pre-selected value
                if(currentUser.profile?.profileIsolatedGlassBreaks.some(x => x.value.split('-')[0] === insurancePackageOption.glassBreakage)){
                  row.glassBreakage = insurancePackageOption.glassBreakage;
                }

                // opções não impactadas diretamente pelos perfis, mas impactadas pelo pacote de seguro
                row.fuelOptions = insurancePackageOption.fuelOptions;
                row.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

                // carrega viaturas de substituição associadas
                row.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
                row.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
                row.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
                row.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

                row.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
                row.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
                row.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
                row.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
                row.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;
              }
            }

            return row;
          })

        }
        vehicleDataTable[rowIndex] = vehicle;
        setVehicleDataTable([...vehicleDataTable]);
      }
    })

  }, [
    vehicleDataTable,
    initVehicleReplacementOptionsDropDown,
    initSelectedInsuranceDropDown,
    initSimulationDetailsDropdowns,
    insuranceFrameworkContractSet,
    currentUser.isAdministrator,
    currentUser.profile
  ]);

  const addNewSimulationToVehicle = useCallback(async(vehicle:IVehicle): Promise<GenericResponse<IVehicle>> => {
    let response: GenericResponse<IVehicle> = {succeeded: false, message:'', value: null};
    let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
    let insuranceFrameworkContractOptions: InsuranceFrameworkContract = {
      typeofRental: '',
      commercialConditions: '',
      insurancePackageOptions: []
    }
    let insurancePackageOptions: Option[] = [];
    let getRentalTypesResponse = await vehiclesService.getRentalTypes();
    if(getRentalTypesResponse.succeeded) {
      let rentalSettings = getRentalTypesResponse.value.find((element:any) => { return element.value === vehicle.typeofRental})
      let newSimulation:Simulation;
      newSimulation = {
        id:0,
        vehicleId:vehicle.id,
        no:"",
        deadline:0,
        miles:0,
        tires: rentalSettings.tyres,
        tiresQuantity: "0",
        insurance:"",
        mostUsed:0,
        maintenance: rentalSettings.maintenance,
        secureOptions:false,
        vehicleReplacementOptions: rentalSettings.vs,
        ownDamage:"",
        occupants:"",
        travelAssistance:"",
        responsibility:"",
        glassBreakage:"",
        maintenanceDescription:"",
        iuc:0,
        fuelCard:false,
        petrolPump:false,
        viaVerde:false,
        entityViaVerde:"",
        fuelDescriptiom:"",
        runFlaat:false,
        vehicleType:"",
        vsOverhaulVehicle:"",
        vsOverhaulVehicleType:"",
        vsAssistanceVehicleType:"",
        vsAssistanceVehicle:"",
        vsAccidentVehicleType:"",
        vsAccidentVehicle:"",
        vsTheftVehicle:"",
        vsTheftVehicleType:"",
        insurancePackageOptions:[],
        vehicleDamageOptions:[],
        occupiersOptions:[],
        roadAssistanceOptions:[],
        liabilityOptions:[],
        fuelOptions:[],
        viaVerdeOptions:[],
        isolatedGlassBreakOptions:[],
        vehicleTypeOptions:[],
        vsAssistanceVehicleOptions:[],
        vsAssistanceVehicleTypeOptions:[],
        vsAccidentVehicleOptions:[],
        vsAccidentVehicleTypeOptions:[],
        vsOverhaulVehicleOptions:[],
        vsOverhaulVehicleTypeOptions:[],
        vsTheftVehicleOptions:[],
        vsTheftVehicleTypeOptions:[],
        //campos das cotações
        priceexclVAT: vehicle.priceexclVAT,
        consumersPrice: vehicle.consumersPrice,
        totalExtraWithoutTax: vehicle.totalExtraWithoutTax,
        totalExtraWithTax: vehicle.totalExtraWithTax,
        totalAccessoriesWithout: vehicle.totalAccessoriesWithout,
        totalAccessoriesWith: vehicle.totalAccessoriesWith,
        discountWithoutTax: vehicle.discountWithoutTax,
        discountWithTax: vehicle.discountWithTax,
        discountWithTaxPercentage: vehicle.discountWithTaxPercentage,
        discountWithTaxTotal: vehicle.discountWithTaxTotal,
        proformaInvoicePriceWithoutTax: vehicle.proformaInvoicePriceWithoutTax,
        proformaInvoicePriceWithTax: vehicle.proformaInvoicePriceWithTax,
        campaignWithoutTax: vehicle.campaignWithoutTax,
        campaignWithTax: vehicle.campaignWithTax,
        campaignWithTaxPercentage: vehicle.campaignWithTaxPercentage,
        campaignWithTaxTotal: vehicle.campaignWithTaxTotal,
        rappelWithoutTax: vehicle.rappelWithTaxTotal,
        rappelWithTax: vehicle.rappelWithTax,
        rappelWithTaxPercentage: vehicle.rappelWithTaxPercentage,
        rappelWithTaxTotal: vehicle.rappelWithTaxTotal,
        extraRappel: vehicle.extraRappel,
        businessValueWithoutTax: vehicle.businessValueWithoutTax,
        businessValueWithTax: vehicle.businessValueWithTax,
        //tipo de contrato
        quantity: vehicle.quantity,
        // quotation calculated fields
        baseIncomeWithoutTax: 0,
        incomeWithoutTax: 0,
        incomeWithTax: 0,
        increaseVR: 0,
        margin: 0,
        realVR: 0,
        vrEurotax: 0,
        vrTable: 0,
        baseVRTable:0,

        amortization: 0,
        interests: 0,
        //validar se estes 4 campos estão duplicados
        tyresCost: 0,
        maintenanceCost: 0,
        iucCost: vehicle.iuc,
        insuranceCost: 0,
        vsCost: 0,

        finalTax: 0,
        estimatedMargin: 0,
        monthlyTCOWithTax: 0,
        monthlyTCOWithoutTax: 0,
        additionalKmsWithTax: 0,
        additionalKmsWithoutTax: 0,
        collateral: 0,
        fee: 0,
        initialEntry: 0,
        travelledKmsWithTax: 0,
        travelledKmsWithoutTax: 0,
        proposeToCustomer: false,
        title: "",
        visibility: 0,
        complementaryProducts: [],
        maximum_Kilometers:0,
        contractExpenses: 0,
        commissionValue: 0,
        commissionCode: ""
      };
      if (!vehicle.simulations) {
        vehicle.simulations = [];
      }
      vehicle.simulations.push(newSimulation);
      //verifica se existem opções pre-carregadas com o tipo de renda e condições comerciais da viatura
      //se não existirem
      if(!Array.from(tmpInsuranceSet).some(item =>
        item.typeofRental === vehicle.typeofRental && item.commercialConditions === vehicle.commercialConditions
        // && item.insurancePackageOptions.some(element => element.insurancePackageOption.value === insurancePackageOption.value)
      )) {
        // carrega as opções de cada pacote de seguros
        insurancePackageOptions = await vehiclesService.getInsurancePackage(vehicle.commercialConditions);
        for(const insurancePackageOption of insurancePackageOptions ) {
          let insuranceOption: InsuranceOptions = {
            insurancePackageOption: {
              value: '',
              label: ''
            },
            // lista de viaturas de substiuição mediante o tipo de seguro
            vsOverhaulVehicleOptions: [],
            vsTheftVehicleOptions: [],
            vsAccidentVehicleOptions: [],
            vsAssistanceVehicleOptions: [],
            // pactoe de opções de viaturas de substituição
            vehicleTypeOptions: [],
            // lista de tipos de substituição mediante o tipo de seguro
            vsOverhaulVehicleTypeOptions: [],
            vsTheftVehicleTypeOptions: [],
            vsAccidentVehicleTypeOptions: [],
            vsAssistanceVehicleTypeOptions: [],

            fuelOptions: [],
            viaVerdeOptions: [],
            // pacote de seguros
            insurance: '',
            // lista de tipos de seguros
            vehicleDamageOptions: [],
            roadAssistanceOptions: [],
            occupiersOptions: [],
            liabilityOptions: [],
            isolatedGlassBreakOptions: [],
            // opções de seguros escolhidas
            responsibility: '',
            ownDamage: '',
            occupants: '',
            travelAssistance: '',
            glassBreakage: '',
            // radio button de inclusão/exclusão de seguros
            secureOptions: false,
          }
          insuranceFrameworkContractOptions.typeofRental = vehicle.typeofRental;
          insuranceFrameworkContractOptions.commercialConditions = vehicle.commercialConditions;

          insuranceOption.insurancePackageOption = insurancePackageOption;
          // insuranceOption.insurance = insurancePackageOption.value;
          insuranceOption = await initSimulationDetailsDropdowns(insuranceOption, vehicle.commercialConditions);


          if (!insuranceFrameworkContractOptions.insurancePackageOptions.some(element => element.insurancePackageOption.value === insurancePackageOption.value) ) {
            insuranceFrameworkContractOptions.insurancePackageOptions.push(insuranceOption)

            insuranceOption = await initVehicleReplacementOptionsDropDown(insuranceFrameworkContractOptions, insuranceOption,true);
            insuranceOption = await initSelectedInsuranceDropDown(insuranceOption, vehicle.use,true);

          }
        }
        tmpInsuranceSet.add(insuranceFrameworkContractOptions)
      }
      // se existir inicializa as opções de seguro para o tipo de renda e condições comerciais da viatura
      else {
        let insurancePackage = Array.from(tmpInsuranceSet).find(item =>
          item.typeofRental === vehicle.typeofRental &&
          item.commercialConditions === vehicle.commercialConditions
        )
        if(insurancePackage){
          insuranceFrameworkContractOptions = insurancePackage
          let options: InsuranceOptions[] = insurancePackage.insurancePackageOptions;
          options.forEach(element => {
            insurancePackageOptions.push(element.insurancePackageOption)
          })
        } else {
          insurancePackageOptions = []
        }
      }
      setInsuranceFrameworkContractSet(tmpInsuranceSet);
      if( rentalSettings.insurance) {
        vehicle.simulations.forEach((row) => {
          //mapeamento de campos base e opções default

          // se o utilizador é administrador
          if( currentUser.isAdministrator) {
            // carrega as opções de pacotes de seguros disponíveis para selecção
            row.insurancePackageOptions = insurancePackageOptions; //insuranceFrameworkContractOptions.insurancePackageOptions;

            // carrega valores de seguros associados ao pacote
            let insurancePackageOption = insuranceFrameworkContractOptions.insurancePackageOptions[0];
            // caso lista de seguros esteja preencida, selecciona primeiro pacote de seguros como default, senão deixa vazio
            row.insurance = insurancePackageOption.insurance ?? row.insurancePackageOptions[0].value ?? '';
            // indica se o pacote de seguros está seleccionado ou não
            row.secureOptions= insurancePackageOption.secureOptions ?? rentalSettings.insurance;

            row.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions;
            row.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions;
            row.occupiersOptions = insurancePackageOption.occupiersOptions;
            row.liabilityOptions = insurancePackageOption.liabilityOptions;
            row.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions;

            row.responsibility = insurancePackageOption.responsibility;
            row.ownDamage = insurancePackageOption.ownDamage;
            row.occupants = insurancePackageOption.occupants;
            row.travelAssistance = insurancePackageOption.travelAssistance;
            row.glassBreakage = insurancePackageOption.glassBreakage;

            // opções não impactadas diretamente pelos perfis, mas impactas pelo pacote de seguro
            row.fuelOptions = insurancePackageOption.fuelOptions;
            row.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

            // carrega viaturas de substituição associadas
            row.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
            row.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
            row.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
            row.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

            row.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
            row.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
            row.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
            row.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
            row.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

          } else {
            // 1. se o utilizador não é administrador
            // valida quais os valores permitidos para preenchimento
            // 2. se nas opções de pacotes de seguros não existirem valores permitidos para o perfil do utilizador
            // mantém os valores default(vazios) pelo que não há necessidade de alterar os mapeamentos

            // carrega as opções de pacotes de seguros disponíveis para selecção
            if(insurancePackageOptions && insurancePackageOptions.length > 0){
              if(insurancePackageOptions.some(ipo => currentUser.profile?.profileInsurances.some(pi => pi.value.split('-')[0] === ipo.value))) {
                let allowedProfileInsuranceValues = insurancePackageOptions.filter(ipo => currentUser.profile?.profileInsurances.some(pi => pi.value.split('-')[0] === ipo.value));
                row.insurancePackageOptions = allowedProfileInsuranceValues;
              }
            }

            //se nas opções de pacotes de seguros existirem valores permitidos para o perfil do utilizador
            let insurancePackageOption: InsuranceOptions = emptyInsuranceOption;
            if(insuranceFrameworkContractOptions.insurancePackageOptions.some(ipo => currentUser.profile?.profileInsurances.some(pi => pi.value.split('-')[0] === ipo.insurance))){
              insurancePackageOption = insuranceFrameworkContractOptions.insurancePackageOptions.filter(ipo => currentUser.profile?.profileInsurances.some(pi => pi.value.split('-')[0] === ipo.insurance))[0];

              // carrega valores de seguros associados ao pacote
              // let insurancePackageOption = insuranceFrameworkContractOptions.insurancePackageOptions[0];
              // caso lista de seguros esteja preencida, selecciona primeiro pacote de seguros como default, senão deixa vazio
              row.insurance = insurancePackageOption.insurance ?? row.insurancePackageOptions[0].value ?? '';
              // indica se o pacote de seguros está seleccionado ou não
              row.secureOptions= insurancePackageOption.secureOptions ?? rentalSettings.insurance;

              // se os valores pré carregados, não coincidirem com as valores permitidos pelo perfil
              // mantém os valores default(vazios)
              // senão, filtra os valores pré carregados pelos valores do perfil
              //own damange dropdown options
              if(insurancePackageOption.vehicleDamageOptions.some(vdo => currentUser.profile?.profileVehicleDamages.some(vd => vd.value.split('-')[0] === vdo.value))) {
                row.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions.filter(vdo => currentUser.profile?.profileVehicleDamages.some(vd => vd.value.split('-')[0] === vdo.value));
              }
              // road assistance dropdown options
              if(insurancePackageOption.roadAssistanceOptions.some(vra => currentUser.profile?.profileRoadAssistances.some(ra => ra.value.split('-')[0] === vra.value))) {
                row.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions.filter(vra => currentUser.profile?.profileRoadAssistances.some(ra => ra.value.split('-')[0] === vra.value));
              }
              // occupiers dropdown options
              if(insurancePackageOption.occupiersOptions.some(vo => currentUser.profile?.profileOccupiers.some(o => o.value.split('-')[0] === vo.value))) {
                row.occupiersOptions = insurancePackageOption.occupiersOptions.filter(vo => currentUser.profile?.profileOccupiers.some(o => o.value.split('-')[0] === vo.value));
              }
              // liability dropdown options
              if(insurancePackageOption.liabilityOptions.some(vdo => currentUser.profile?.profileLiabilities.some(vd => vd.value.split('-')[0] === vdo.value))) {
                row.liabilityOptions = insurancePackageOption.liabilityOptions.filter(vdo => currentUser.profile?.profileLiabilities.some(vd => vd.value.split('-')[0] === vdo.value));
              }
              // glass breakage dropdown options
              if(insurancePackageOption.isolatedGlassBreakOptions.some(vdo => currentUser.profile?.profileIsolatedGlassBreaks.some(vd => vd.value.split('-')[0] === vdo.value))) {
                row.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions.filter(vdo => currentUser.profile?.profileIsolatedGlassBreaks.some(vd => vd.value.split('-')[0] === vdo.value));
              }
              // liability pre-selected value
              if(currentUser.profile?.profileLiabilities.some(x => x.value === insurancePackageOption.responsibility)){
                row.responsibility = insurancePackageOption.responsibility;
              }
              // own damage pre-selected value
              if(currentUser.profile?.profileVehicleDamages.some(x => x.value === insurancePackageOption.ownDamage)){
                row.ownDamage = insurancePackageOption.ownDamage;
              }
              // occupier pre-selected value
              if(currentUser.profile?.profileOccupiers.some(x => x.value === insurancePackageOption.occupants)){
                row.occupants = insurancePackageOption.occupants;
              }
              // road assistance pre-selected value
              if(currentUser.profile?.profileRoadAssistances.some(x => x.value === insurancePackageOption.travelAssistance)){
                row.travelAssistance = insurancePackageOption.travelAssistance;
              }
              // glass breakage pre-selected value
              if(currentUser.profile?.profileIsolatedGlassBreaks.some(x => x.value === insurancePackageOption.glassBreakage)){
                row.glassBreakage = insurancePackageOption.glassBreakage;
              }

              // opções não impactadas diretamente pelos perfis, mas impactadas pelo pacote de seguro
              row.fuelOptions = insurancePackageOption.fuelOptions;
              row.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

              // carrega viaturas de substituição associadas
              row.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
              row.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
              row.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
              row.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

              row.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
              row.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
              row.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
              row.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
              row.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;
            }
          }

          return row;
        })
      }
      response.value = vehicle;
      response.succeeded = true;
    }
    return response;
  }, [
    //vehicleDataTable,
    initVehicleReplacementOptionsDropDown,
    initSelectedInsuranceDropDown,
    initSimulationDetailsDropdowns,
    insuranceFrameworkContractSet,
    currentUser.isAdministrator,
    currentUser.profile
  ]);

  const addVehicle = useCallback(async () => {
    //cria simulação inicial quando adiciona veiculo na proposta
    vehicleDataTable.forEach((vehicle, index: number) => {
      if (vehicle.simulations.length === 0) {
        addNewSimulation(index);
      } else {
        //FIXME VALIDAR ALTERAÇÕES aos valores da simulação mas manter o id
      }
      //valida condições comerciais iniciais e tipo aluguer com base no tipo de aluguer
      // se a viatura tiver o tipo de aluguer preenchido com o valor pre-definido no utilizador
      if (vehicle.typeofRental) {
        if(currentUser.isAdministrator) {
          vehiclesService.getCommercialConditions(vehicle.typeofRental).then((data) => {
            if (data.succeeded === true) {
              vehicle.commercialOptions = data.value;
              vehicle.typeofIncome = vehicle.typeofIncome ? vehicle.typeofIncome : paymentTerms[1].result[0].value;
            }
          })
        } else {
          // verifica se o valor faz parte dos valores permitidos pelo perfil
          if (currentUser.profile && currentUser.profile.profileRentalTypes && currentUser.profile.profileRentalTypes.length > 0) {
            // se fizer, carrega os valores pre-definidos associados
            if(currentUser.profile.profileRentalTypes.some(rt => rt.value === vehicle.typeofRental)) {
              vehiclesService.getCommercialConditions(vehicle.typeofRental).then((data) => {
                if (data.succeeded === true) {
                  // senão, filtra condições comerciais pelos valores aplicados no perfil de permissões do utilizador
                  if(currentUser.profile && currentUser.profile.profileCommercialConditions && currentUser.profile.profileCommercialConditions.length > 0) {
                    vehicle.commercialOptions = data.value.filter((x: any) => currentUser.profile?.profileCommercialConditions.some(cc => cc.value === x.value));
                  } else {
                    vehicle.commercialOptions = data.value;
                  }
                }
              })
            }
          } // senão deixa o preenchimento ao critério do utilizador.

          // filtra tipos de renda pelos valores aplicados no perfil de permissões do utilizador
          if (currentUser.profile && currentUser.profile.profilePaymentTerms && currentUser.profile.profilePaymentTerms.length > 0) {
            // se vehicle.typeofIncome estiver preenchido
            if (vehicle.typeofIncome) {
              // verifica se ele faz parte dos valores permitidos no perfil
              // se fizer mantem o valor, senão escolhe o primeiro valor permitido
              let permittedTypeofIncome = currentUser.profile?.profilePaymentTerms.find(pt => pt.value === vehicle.typeofIncome);
              if (!permittedTypeofIncome) {
                vehicle.typeofIncome = currentUser.profile?.profilePaymentTerms[0]?.value;
              }
            } else {
              // se vehicle.typeofIncome não estiver preenchido, filtra todos os valores na dropdown dos tipos de renda pelos valores permitidos e escolhe o primeiro
              vehicle.typeofIncome = paymentTerms[1].result[0]?.filter((x: any) => currentUser.profile?.profilePaymentTerms.some(pt => pt.value === x.value))[0]?.value;
            }
            // se não existirem valores definidos(limitados) pelo perfil de permissões do utilizador, escolhe o primeiro valor da dropdown
          } else {
            vehicle.typeofIncome = vehicle.typeofIncome ? vehicle.typeofIncome : paymentTerms[1].result[0]?.value;
          }

        }
      }
    });

    vehiclesService.add(vehicleDataTable).then((data) => {
      if (data.succeeded && data.value.length > 0 && data.value) {
        let vehicles = data.value as IVehicle[];
        for (var i = 0; i < vehicleDataTable.length; i++) {
          vehicleDataTable[i].id = vehicles[i].id;
          for (var j = 0; j < vehicleDataTable[i].simulations.length; j++) {
            if( vehicles[i].simulations.length > 0)
            {
              vehicleDataTable[i].simulations[j].id = vehicles[i].simulations[j].id;
            }
          }
          for (var k = 0; k < vehicleDataTable[i].accessories.length; k++) {
            vehicleDataTable[i].accessories[k].id = vehicles[i].accessories[k].id;
            vehicleDataTable[i].accessories[k].vehicleId = vehicles[i].accessories[k].vehicleId;
          }
          for (var l = 0; l < vehicleDataTable[i].extraStandard.length; l++) {
            vehicleDataTable[i].extraStandard[l].id = vehicles[i].extraStandard[l].id;
            vehicleDataTable[i].extraStandard[l].vehicleId = vehicles[i].extraStandard[l].vehicleId;
          }
        }
        setVehicleDataTable([...vehicleDataTable]);
      }
    });
  }, [addNewSimulation, paymentTerms, vehicleDataTable,currentUser.profile, currentUser.isAdministrator ]);

  const upsertVehicles = useCallback(async (vehiclesToUpsert:IVehicle[]): Promise<IVehicle[]> => {
    //cria simulação inicial quando adiciona veiculo na proposta
    let totalFailed = 0;
    for(let i = 0; i > vehiclesToUpsert.length;i++){
    //vehicles.forEach((vehicle, index: number) => {
      let vehicle = vehiclesToUpsert[i];
      if (vehicle.simulations.length === 0) {
        const addSimulationResponse = await addNewSimulationToVehicle(vehicle);//addNewSimulation(index);
        if(addSimulationResponse.succeeded && addSimulationResponse.value){
          vehicle = addSimulationResponse.value;
        }
        else {
          totalFailed++;
          break;
        }
      } else {
        //FIXME VALIDAR ALTERAÇÕES aos valores da simulação mas manter o id
      }
      //valida condições comerciais iniciais e tipo aluguer com base no tipo de aluguer
      // se a viatura tiver o tipo de aluguer preenchido com o valor pre-definido no utilizador
      if (vehicle.typeofRental) {
        if(currentUser.isAdministrator) {
          vehiclesService.getCommercialConditions(vehicle.typeofRental).then((data) => {
            if (data.succeeded === true) {
              vehicle.commercialOptions = data.value;
              vehicle.typeofIncome = vehicle.typeofIncome ? vehicle.typeofIncome : paymentTerms[1].result[0].value;
            }
          })
        } else {
          // verifica se o valor faz parte dos valores permitidos pelo perfil
          if (currentUser.profile && currentUser.profile.profileRentalTypes && currentUser.profile.profileRentalTypes.length > 0) {
            // se fizer, carrega os valores pre-definidos associados
            if(currentUser.profile.profileRentalTypes.some(rt => rt.value === vehicle.typeofRental)) {
              vehiclesService.getCommercialConditions(vehicle.typeofRental).then((data) => {
                if (data.succeeded === true) {
                  // senão, filtra condições comerciais pelos valores aplicados no perfil de permissões do utilizador
                  if(currentUser.profile && currentUser.profile.profileCommercialConditions && currentUser.profile.profileCommercialConditions.length > 0) {
                    vehicle.commercialOptions = data.value.filter((x: any) => currentUser.profile?.profileCommercialConditions.some(cc => cc.value === x.value));
                  } else {
                    vehicle.commercialOptions = data.value;
                  }
                }
              })
            }
          } // senão deixa o preenchimento ao critério do utilizador.

          // filtra tipos de renda pelos valores aplicados no perfil de permissões do utilizador
          if (currentUser.profile && currentUser.profile.profilePaymentTerms && currentUser.profile.profilePaymentTerms.length > 0) {
            // se vehicle.typeofIncome estiver preenchido
            if (vehicle.typeofIncome) {
              // verifica se ele faz parte dos valores permitidos no perfil
              // se fizer mantem o valor, senão escolhe o primeiro valor permitido
              let permittedTypeofIncome = currentUser.profile?.profilePaymentTerms.find(pt => pt.value === vehicle.typeofIncome);
              if (!permittedTypeofIncome) {
                vehicle.typeofIncome = currentUser.profile?.profilePaymentTerms[0]?.value;
              }
            } else {
              // se vehicle.typeofIncome não estiver preenchido, filtra todos os valores na dropdown dos tipos de renda pelos valores permitidos e escolhe o primeiro
              vehicle.typeofIncome = paymentTerms[1].result[0]?.filter((x: any) => currentUser.profile?.profilePaymentTerms.some(pt => pt.value === x.value))[0]?.value;
            }
            // se não existirem valores definidos(limitados) pelo perfil de permissões do utilizador, escolhe o primeiro valor da dropdown
          } else {
            vehicle.typeofIncome = vehicle.typeofIncome ? vehicle.typeofIncome : paymentTerms[1].result[0]?.value;
          }

        }
      }
    };
    let response:IVehicle[] = [];
    const upsertResponse = await vehiclesService.add(vehiclesToUpsert);
    if (upsertResponse.succeeded && upsertResponse.value.length > 0 && upsertResponse.value) {
      let vehicles = upsertResponse.value as IVehicle[];
      for (var i = 0; i < vehiclesToUpsert.length; i++) {
        vehiclesToUpsert[i].id = vehicles[i].id;
        for (var j = 0; j < vehiclesToUpsert[i].simulations.length; j++) {
          if( vehicles[i].simulations.length > 0)
          {
            vehiclesToUpsert[i].simulations[j].id = vehicles[i].simulations[j].id;
          }
        }
        for (var k = 0; k < vehiclesToUpsert[i].accessories.length; k++) {
          vehiclesToUpsert[i].accessories[k].id = vehicles[i].accessories[k].id;
          vehiclesToUpsert[i].accessories[k].vehicleId = vehicles[i].accessories[k].vehicleId;
        }
        for (var l = 0; l < vehiclesToUpsert[i].extraStandard.length; l++) {
          vehiclesToUpsert[i].extraStandard[l].id = vehicles[i].extraStandard[l].id;
          vehiclesToUpsert[i].extraStandard[l].vehicleId = vehicles[i].extraStandard[l].vehicleId;
        }
      }
      response = vehiclesToUpsert;
    }
    return response;
  }, [
    addNewSimulationToVehicle,
    paymentTerms,
    currentUser.profile,
    currentUser.isAdministrator
  ]);

  const handleRequiredDataTextFieldChange: TextFieldProps["onChange"] = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setChangedContact(true);
    if(e.target.name === "totalFleet") {
      setRequiredClientData({
        ...requiredClientData,
        [e.target.name]:convertNumber(e.target.value),
      });
    } else {
      setRequiredClientData({
        ...requiredClientData,
        [e.target.name]: e.target.value,
      });
    }
    //guarda a salesPerson na proposta
    //falta validar de onde vem as salesPersons
    if(e.target.name === 'salesPersonName') {
      let person = salesPersons.find((x) => {
        return x.value === e.target.value;
      });
      proposal.salespersonCode = person?.value ? person.value : "";
      proposal.salesPersonName = person?.value ? person.label: "";
      // setProposal({ ...proposal, ['salespersonCode']: e.target.value })
      // setProposal({ ...proposal, ['salesPersonName']: person!.label })
    }
    if(e.target.name === 'vaT_Registration_No')
      proposal.nif =e.target.value;
    if(e.target.name === 'name')
      proposal.clientName =e.target.value;
    if(e.target.name === 'type')
      proposal.clientType =e.target.value;
    if(e.target.name === 'customerType')
      proposal.clientTypology =e.target.value;
    if(e.target.name === 'totalFleet')
      proposal.totalFleet =convertNumber(e.target.value);
    setProposal(proposal);
  },[salesPersons,proposal,requiredClientData]);

  const handleProposalDataTextFieldChange: TextFieldProps["onChange"] = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.target.name === "salespersonCode") {
      let salesPersonN = "";
      let person = salesPersons.find((x) => {
        return x.value === e.target.value;
      });

      if (person) {
        setRequiredClientData((requiredClientData) => {
          requiredClientData.salespersonCode = e.target.value;
          return requiredClientData;
        });
        salesPersonN = person?.label;
      }

      setChangedContact(true);
      setProposal({
        ...proposal,
        salesPersonName: salesPersonN,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name ==="inputChannel" && e.target.value !== "1"){
      setProposal({
        ...proposal,
        [e.target.name]: e.target.value,
        requestingChannel:"",
      });
    } else {
      setProposal({
        ...proposal,
        [e.target.name]: e.target.value,
      });
    }
  },[salesPersons, proposal]);

  const setCustomerBC = useCallback( () =>{
      setSearchClient((cli)=>{
        setSalesPersons((salesPer)=>{
          if( cli.value && cli.value !== ''){
            contactService.getContactsByNo(cli.value).then(async (data: any) => {
              if (data) {
                //INTERLOCUTOR
                let webPortalCId="";
                let salesPersonLabel = salesPer.find((x) => x.value === data['salespersonCode'] );
                let customerType = ""
                if (settings.Data.Vehicles_ClientTypology[0].result.find(x => x.label === data['customerType']) ){
                  customerType = settings.Data.Vehicles_ClientTypology[0].result.find(x => x.label === data['customerType'])!.value
                }

                let requiredClient:RequiredDataProps = {
                  no: data['no'],
                  odataEtag: data['odataEtag'],
                  name: data['name'],
                  name2: '',
                  salespersonCode: data['salespersonCode'],
                  salesPersonName: data['salesPersonName'] ? data['salesPersonName'] : salesPersonLabel?.label,
                  vaT_Registration_No: data['vaT_Registration_No'],
                  type: data['type'],
                  customerType: customerType,
                  customerTypePortal:data['customerTypePortal'],
                  totalFleet: data['totalFleet'],
                  interlocutorName: '',

                  phoneNo: data['phoneNo'] ? data['phoneNo']  : '',
                  mobile_Phone_No:  data['mobile_Phone_No'] ? data['mobile_Phone_No']  : '',
                  e_Mail: data['e_Mail'],
                };

                await contactService.getInterlocutor(cli.value).then(interlocutorData => {
                  if (interlocutorData) {

                    setRequiredClientInterlocutorData((interlocutorData as RequiredDataProps));
                    webPortalCId = interlocutorData['no'];
                    //let salesPersonLabel = salesPersons.find((x) => x.value === data['salespersonCode'] );
                    requiredClient = {
                      no: data['no'],
                      odataEtag: data['odataEtag'],
                      name: data['name'],
                      name2: '',
                      salespersonCode: data['salespersonCode'],
                      salesPersonName: data['salesPersonName'] ? data['salesPersonName'] : salesPersonLabel?.label,
                      vaT_Registration_No: data['vaT_Registration_No'],
                      type: data['type'],
                      customerType: customerType,
                      customerTypePortal:data['customerTypePortal'],
                      totalFleet: data['totalFleet'],
                      interlocutorName: interlocutorData['name'],

                      phoneNo: data['phoneNo'] ? data['phoneNo']  : '',
                      mobile_Phone_No:  data['mobile_Phone_No'] ? data['mobile_Phone_No']  : '',
                      e_Mail: data['e_Mail'],
                    };
                    // setRequiredClientData(requiredClient);
                  }
                  setRequiredClientData(requiredClient);
                });

                setProposal((pro)=>{
                    pro.webPortalContactId= (pro.webPortalContactId && pro.webPortalContactId !== '') ? pro.webPortalContactId : webPortalCId;
                    //Cliente
                    pro.contactId = cli.value;
                    //listagem de propostas
                    pro.salespersonCode = data["salespersonCode"];
                    pro.salesPersonName = data['salesPersonName'] ? data['salesPersonName'] : salesPersonLabel?.label;
                    pro.nif = data["vaT_Registration_No"];
                    pro.clientName = data["name"];
                    pro.clientType = data.type;
                    pro.clientTypology = customerType;
                    pro.totalFleet =convertNumber(data.totalFleet)
                    return pro
                })

              }
            });
          }
          return salesPer
        });
        return cli
      });

  },[settings.Data.Vehicles_ClientTypology]);

  const handleSearchOptionsClick = useCallback(() => {
    let searchResults: Option = { value: "", label: "" };
    setBrandInput((brandInputSelect) => {
      searchResults = vehicleSearchOptions.filter(
        (b) => b.value === brandInputSelect?.value
      )[0];
      return brandInputSelect;
    });
    // [0 temporary solution]
    if (searchResults.value !== "") {
      setVehicleDataTable((rows) => {
        setVehiclesListingTotal(
          rows.filter((element) => element.versionId === searchResults.value)
            .length
        );
        return rows.map((cur) =>
          cur.versionId !== searchResults.value ? { ...cur, hidden: true } : cur
        );
      });
      setDisable(false);
    }
  }, [vehicleSearchOptions]);

  const handleEraseLinkVehiclePropsClick = useCallback(() => {
    const option: Option = {
      value: "",
      label: "",
    };
    setBrandInput(option);
    setVehicleDataTable((rows) => {
      setVehiclesListingTotal(rows.length);
      return rows.map((cur) =>
        cur.versionId !== "" ? { ...cur, hidden: false } : cur
      );
    });
    setDisable(true);
  }, []);

  const handleAddFrequentVehicle = useCallback((index:number) => {
     setFrequentTitle('');
    let frequentToAdd = vehicleDataTable
    setNewFrequent(frequentToAdd[index])
    setNewVehicleFrequentIndex(index);
  },[vehicleDataTable])

  const handleSaveVehicleFrequentClick = useCallback(() => {
      let mostFrequentVehicle = newFrequent;
      vehiclesService.addMostFrequent(mostFrequentVehicle,frequentTitle, frequentVisibility,currentUser.userId)
        .then(data => {
          if (data.succeeded)
          setFrequentTitle('');
          setNewFrequent(null);
          setNewVehicleFrequentIndex(0);
        });
  }, [newFrequent, frequentTitle, frequentVisibility, currentUser ]);

  const handleDuplicateVehicleClick = useCallback(
    (index: number) => {
      setChangedProposal(true);
      const tmpDuplicatedVehicle = vehicleDataTable[index] as any;
      var duplicatedVehicle = JSON.parse(
        JSON.stringify(tmpDuplicatedVehicle)
      );
      duplicatedVehicle.hidden=vehicleDataTable[index].hidden;
      duplicatedVehicle.leaseQuoteNo="";
      duplicatedVehicle.id = 0;
      (duplicatedVehicle as IVehicle).accessories.forEach(accessory => {
        accessory.id = 0;
        return accessory;
      });
      (duplicatedVehicle as IVehicle).extraStandard.forEach(extra => {
        extra.id = 0;
        return extra;
      });
      (duplicatedVehicle as IVehicle).simulations.forEach(simulation => {
        simulation.id = 0;
        simulation.no = "";
        return simulation;
      });

      setVehicleDataTable((vehicleDataTable) => [
        ...vehicleDataTable,
        duplicatedVehicle,
      ]);
      setVehiclesListingTotal(vehicleDataTable.length + 1);
    },
    [vehicleDataTable]
  );

  const handleRemoveVehicleClick = useCallback((index: number) => {
    const vehicle = {...vehicleDataTable[index]};
    const vehicleId = vehicle?.id;


    if (vehicleId && vehicleId > 0) {
      setChangedProposal(true);
      vehiclesService.delete(vehicleId).then(() => {
        // removeVehicle.splice(index, 1);
        setVehicleDataTable((rows) => {
          rows = rows.filter( (element,i) => i !== index)
          setVehiclesListingTotal(rows.length);
          let item = vehicleSearchOptions.find( x => { return x.value ===vehicle.versionId });
          if(item) {
            updateSearchOptions(item,vehicle.versionId,rows)
          }

          // correção dos indexes dos extras após alteração do nº de viaturas
          rows.map((row,rowIndex) => {
            return row.extraStandard.map((extra, indexExtr) => {
              extra.valueTextFieldProps.name = "price-price_Excl_VAT-" + rowIndex + "-" + indexExtr;
              return extra;
            })
          })
          return rows
        });
      });
    } else {
      // removeVehicle.splice(index, 1);
      setVehicleDataTable((rows) => {
        rows = rows.filter( (element,i) => i !== index)
        setVehiclesListingTotal(rows.length);
        let item = vehicleSearchOptions.find( x => { return x.value ===vehicle.versionId });
        if(item) {
          updateSearchOptions(item,vehicle.versionId,rows)
        }

        // correção dos indexes dos extras após alteração do nº de viaturas
        rows.map((row,rowIndex) => {
          return row.extraStandard.map((extra, indexExtr) => {
            extra.valueTextFieldProps.name = "price-price_Excl_VAT-" + rowIndex + "-" + indexExtr;
            return extra;
          })
        })
        return rows
      });
    }
  },[vehicleDataTable,vehicleSearchOptions, updateSearchOptions]);


  const handleAddVehicleClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let proposalId = e.currentTarget.name;
    // setCurrentUser((dataCard) => {
    setVehicleDataTable((vehicleDataTable) => {
      setVehiclesListingTotal(vehicleDataTable.length + 1);
      return [
        ...vehicleDataTable,
        {
          id: 0,
          proposalId:convertNumber(proposalId ? proposalId : 0), //proposal.id,
          makeId: "",
          leaseQuoteNo:"",
          versionId: "",
          modelId: "",
          licensePlate: "",
          priceexclVAT: 0,
          consumersPrice: 0,
          pvp: 0,
          recycling_charge: 0,
          luxury_tax: 0,
          transport_x0026_immatriculation_cost: 0,
          status: "New",
          use: "",
          numberofDoors: 0,
          fuelId: "100001",
          fuelType: "",
          euroTaxInternationalSegmt: "",
          quantity_of_Seats: 0,
          lengthmm: 0,
          widthmm: 0,
          heightmm: 0,
          trunkVolumel: 0,
          wheelBasemm: 0,
          trainFrontWheelsmm: 0,
          trainRearWheelsmm: 0,
          compressionratio: 0,
          bore: 0,
          powerHP: 0,
          powerkW: 0,
          cO2DischargeDecF: 0,
          engineCc: 0,
          torqueNm: 0,
          fuelTankCapacityl: 0,

          maxspeedkmh: 0,
          acceleration0100kmh: 0,
          mileage: 0,
          modelYear: "",
          recordDate: "",
          accessories: [],
          extraStandard: [],
          simulations: [],
          no: "",
          lotId: "",
          totalExtraWithoutTax: 0,
          totalAccessoriesWithout: 0,
          proformaInvoicePriceWithoutTax: 0,
          totalExtraWithTax: 0,
          totalAccessoriesWith: 0,
          proformaInvoicePriceWithTax: 0,
          discountWithTaxTotal: 0,
          rappelWithTaxTotal: 0,
          campaignWithTaxTotal: 0,
          businessValueWithoutTax: 0,
          businessValueWithTax: 0,
          discountWithTax: 0,
          discountWithoutTax: 0,
          discountWithTaxPercentage: 0,
          campaignWithTax: 0,
          campaignWithoutTax: 0,
          campaignWithTaxPercentage: 0,
          rappelWithTax: 0,
          rappelWithTaxPercentage: 0,
          rappelWithoutTax: 0,
          extraRappel: 0,
          extraOptionsSearch: [],
          extraOptionSelect: 0,
          versionDescription: "",
          caracteristics: "",
          quantity: 1,
          oilChange: 0,
          bPM: 0,
          luxury_tax_vat: 0,
          vat: 0,
          technMaxQuantityKm: 0,
          hidden: false,

          modelDescription: "",
          iuc:0,
          number_of_wheels:0,
          group_Price_Map:"",
          vehicleStatus: {
            color: "warning",
            label: "evaluating",
          },
          // campos mapeados no step 3
          commercialOptions: [],
          //tipo aluguer
          typeofRental: currentUser && currentUser.typeofRental ? currentUser.typeofRental : "",
          // typeofRental: dataCard && dataCard.typeofRental ? dataCard.typeofRental : "",
          // condições comerciais
          commercialConditions: currentUser && currentUser.commercialConditions ? currentUser.commercialConditions : "",
          // commercialConditions: dataCard && dataCard.commercialConditions ? dataCard.commercialConditions : "",
          //tipo de renda
          typeofIncome: currentUser && currentUser.typeofIncome ? currentUser.typeofIncome : "",
          // typeofIncome: dataCard && dataCard.typeofIncome ? dataCard.typeofIncome : "",
            // paymentTerms[1].result[0],
          driverId: "", //FIXME susbtituir por nome do contato do user quando este estiver definido no user
          renew: false,
          driverName: "",
        },
      ];
    });
      // return dataCard;
    // });
  },[currentUser]);

  const handleOnChange: TextFieldProps["onChange"] = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      let splits = e.target.name.split("-");
      const name = splits[1];
      const rowIndex =convertNumber(splits[0]);
      setChangedProposal(true);
      setVehicleDataTable((rows) =>
        rows.map((row, index) => {
          if (index !== rowIndex) {
            return row;
          }
          try {
            if (
              name === "lotId" ||
              name === "licensePlate" ||
              name === "use"
            ) {
              // let value = 0;
              //if(e.target.value!="" && !isNaN(e.target.value))
              //value =e.target.value;
              row[name] = e.target.value;
            }
            //FIXME Alexandre: VALIDAR ALTERAÇÕES quando estiverem definidas as regras das cotações
            else if (name === "quantity") {
              row.quantity =convertNumber(e.target.value);
              if (row.simulations.length > 0) {
                //TODO Alexandre: validar comportamento. altera so a quantidade na primeira ou em todas as simulações?
                row.simulations[0].quantity =convertNumber(e.target.value);
              }
            } else {
              row[name] = validaStringFormatDecimal(e.target.value,row[name]);
            }
            if (name === "businessValueWithoutTax") {
              let valueP =validaStringFormatDecimal(e.target.value,row.businessValueWithoutTax);
              let busissValue=convertNumber((
                convertNumber(row.priceexclVAT) +

                convertNumber(row.totalAccessoriesWithout) +
                convertNumber(row.totalExtraWithoutTax) +

                convertNumber(row.luxury_tax) +
                convertNumber(row.transport_x0026_immatriculation_cost) +
                convertNumber(row.recycling_charge) -

                convertNumber(row.rappelWithTaxTotal) -
                convertNumber(row.extraRappel) -

                convertNumber(row.campaignWithTaxTotal)
              ).toFixed(2));
              //TODO Alexandre: validar comportamento. altera so a quantidade na primeira ou em todas as simulações?
              let discount = convertNumber((convertNumber(busissValue) - convertNumber(valueP)).toFixed(2));
              if(discount >= 0) {
                row.discountWithoutTax = discount;
              } else {
                row.discountWithoutTax = 0;
              }
              updateValuesVehicle(row,"");

            }

            if(name === "consumersPrice") {
              row.priceexclVAT = convertNumber(
                (convertNumber(row[name]) / (1 + row.vat / 100)).toFixed(2)
              );
              updateValuesVehicle(row,"consumersPrice");
            } else {
              updateValuesVehicle(row,"");
            }
            return row;
          } catch (ex) {
            console.log(ex);
            return row;
          }
        })
      );
    },
    []
  );

  const handleSelectRows = useCallback((selected: number[]) => {
    setSelectedRows([...selected]);
  }, []);

  const handleVehicleSearchPropsChange: SearchResultProps["onChange"] =
    useCallback((_: any, option: any) => {
      if (option === null) {
        setVehicleDataTable((rows) => {
          setVehiclesListingTotal(rows.length);
          return rows.map((cur) =>
            cur.versionId !== "" ? { ...cur, hidden: false } : cur
          );
        });
        setDisable(true);
      }
      setBrandInput(option);
    }, []);

  const handleTableInputsChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      rowIndex: number
    ) => {
      setChangedProposal(true);
      setVehicleDataTable((rows) =>
        rows.map((cur, index) =>
          index === rowIndex ? { ...cur, [e.target.name]: e.target.value } : cur
        )
      );
    },
    []
  );

  const handleTableInputMakeChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let splits = e.target.name.split("-");
    const name = splits[0];
    const rowIndex =convertNumber(splits[1]);

    // setVehicleDataTable((rows) =>
    //   rows.map((cur, index) =>
    //     index === rowIndex ?
    //     { ...cur,
    //       [name]: e.target.value,
    //       versionId:"",
    //       modelId:""
    //     } : cur
    //   )
    // );

    let option: Option = {
      label:"",
      value: "",
    };

    setVehicleDataTable((rows) =>
      rows.map((row, indexR) => {
        if (indexR === rowIndex) {
          // faz uma copia do objecto para não alterar o original
          let updatedRow = {...row}
          // atualiza os valores pretendidos em conformidade com a lógica de negócio
          updatedRow.id = 0;
          updatedRow.makeId =  e.target.value;
          updatedRow.leaseQuoteNo = "";
          updatedRow.versionId =  "";
          updatedRow.modelId =  "";
          updatedRow.licensePlate =  "";
          updatedRow.priceexclVAT =  0;
          updatedRow.consumersPrice =  0;
          updatedRow.pvp = 0;
          updatedRow.recycling_charge =  0;
          updatedRow.luxury_tax =  0;
          updatedRow.transport_x0026_immatriculation_cost =  0;
          updatedRow.status =  "";
          updatedRow.use =  "";
          updatedRow.numberofDoors =  0;
          updatedRow.fuelId =  "";
          updatedRow.fuelType =  "";
          updatedRow.euroTaxInternationalSegmt =  "";
          updatedRow.quantity_of_Seats =  0;
          updatedRow.lengthmm =  0;
          updatedRow.widthmm =  0;
          updatedRow.heightmm =  0;
          updatedRow.trunkVolumel =  0;
          updatedRow.wheelBasemm =  0;
          updatedRow.trainFrontWheelsmm =  0;
          updatedRow.trainRearWheelsmm =  0;
          updatedRow.compressionratio =  0;
          updatedRow.bore =  0;
          updatedRow.powerHP =  0;
          updatedRow.powerkW =  0;
          updatedRow.cO2DischargeDecF =  0;
          updatedRow.engineCc =  0;
          updatedRow.torqueNm =  0;
          updatedRow.fuelTankCapacityl =  0;

          updatedRow.maxspeedkmh =  0;
          updatedRow.acceleration0100kmh =  0;
          updatedRow.mileage =  0;
          updatedRow.modelYear =  "";
          updatedRow.recordDate =  "";
          updatedRow.accessories =  [];
          updatedRow.extraStandard =  [];
          updatedRow.simulations =  [];
          updatedRow.no =  "";
          updatedRow.lotId =  "";
          updatedRow.totalExtraWithoutTax =  0;
          updatedRow.totalAccessoriesWithout =  0;
          updatedRow.proformaInvoicePriceWithoutTax =  0;
          updatedRow.totalExtraWithTax =  0;
          updatedRow.totalAccessoriesWith =  0;
          updatedRow.proformaInvoicePriceWithTax =  0;
          updatedRow.discountWithTaxTotal =  0;
          updatedRow.rappelWithTaxTotal =  0;
          updatedRow.campaignWithTaxTotal =  0;
          updatedRow.businessValueWithoutTax =  0;
          updatedRow.businessValueWithTax =  0;
          updatedRow.discountWithTax =  0;
          updatedRow.discountWithoutTax =  0;
          updatedRow.discountWithTaxPercentage =  0;
          updatedRow.campaignWithTax =  0;
          updatedRow.campaignWithoutTax =  0;
          updatedRow.campaignWithTaxPercentage =  0;
          updatedRow.rappelWithTax =  0;
          updatedRow.rappelWithTaxPercentage =  0;
          updatedRow.rappelWithoutTax =  0;
          updatedRow.extraRappel =  0;
          updatedRow.extraOptionsSearch =  [];
          updatedRow.extraOptionSelect =  0;
          updatedRow.versionDescription =  "";
          updatedRow.caracteristics =  "";
          updatedRow.quantity =  1;
          updatedRow.oilChange =  0;
          updatedRow.bPM =  0;
          updatedRow.luxury_tax_vat =  0;
          updatedRow.vat =  0;
          updatedRow.technMaxQuantityKm =  0;
          updatedRow.hidden =  false;
          updatedRow.commercialOptions =  [];
          updatedRow.modelDescription =  "";
          updatedRow.iuc = 0;
          updatedRow.number_of_wheels = 0;
          updatedRow.vehicleStatus =  {
            color:  "warning",
            label:  "evaluating"
          };
          updatedRow.group_Price_Map = "";

          updatedRow.driverId =  ""; //FIXME susbtituir por nome do contato do user quando este estiver definido no user
          updatedRow.renew =  false;
          updatedRow.driverName =  "";
          updatedRow.modelId = "";
          updatedRow.versionId = "";
          updatedRow.makeId = e.target.value;
          // se a marca for alterada, retira a viatura das opções de pesquisa de viaturas da proposta
          let oldVersion = row.versionId;
          updateSearchOptions(option,oldVersion,rows);
          //atualiza a linha com novo objecto
          return updatedRow;
        }
        return row
      })
    );
  },[
    updateSearchOptions
  ]);

  const handleAddAdvSearchSelectedVehicles_Click = useCallback( async (itemsToAdd:any) => {
    if (!itemsToAdd) return;

    //Carregamento de versões inativas de viaturas
    let mustUpdateVersions = false;
    for (let i = 0; i < itemsToAdd.length; i++) {
      let itemToAdd:any = itemsToAdd[i];
      var inactiveVehicleTypeStatus: string = VehicleTypeStatus[VehicleTypeStatus.Inactive];
      if(itemToAdd.isInactive === inactiveVehicleTypeStatus) {
        const currentMakeIndex = versionsSearchOptions.findIndex(
          (x) => x.makeId === itemToAdd.makeId && x.modelId === itemToAdd.modelId
        );

        const hasInactiveVersion = versionsSearchOptions[currentMakeIndex].versions.some(v => v.value === itemToAdd.versionId);
        if(!hasInactiveVersion){
          if( versionsSearchOptions[currentMakeIndex].versions.filter(x => x.value === "").length===1){
             versionsSearchOptions[currentMakeIndex].versions = [{
              label: itemToAdd.versionDetails.description + '; ' + itemToAdd.versionDetails.caracteristics,
              value: itemToAdd.versionId,
              isInactive: true
            }];
          }
          else{
            versionsSearchOptions[currentMakeIndex].versions.push({
              label: itemToAdd.versionDetails.description + '; ' + itemToAdd.versionDetails.caracteristics,
              value: itemToAdd.versionId,
              isInactive: true
            });
          }
        }
        mustUpdateVersions = true;
      }
    }
    if(mustUpdateVersions){
      setVersionsSearchOptions([...versionsSearchOptions]);
    }

    let vehiclesToUpsert:IVehicle[] = [];
    let searchOptions:Option[] = [];

    for (let i = 0; i < itemsToAdd.length; i++) {
      const versionDetails = (itemsToAdd[i] as any)?.versionDetails;
      const options = await vehiclesService.getOptionsType(
        versionDetails.no,
        null
      );
      let extras = [];
      let accessories = [];
      if (options.succeeded && options.value.length > 0) {
        extras = options.value.filter(
          (x: { general_with_this_Type: boolean }) =>
            x.general_with_this_Type === false
        );
        accessories = options.value.filter(
          (x: { general_with_this_Type: boolean }) =>
            x.general_with_this_Type === true
        );
      }
      let totalAccessories = 0;
      let totalAccessoriesVat = 0;
      if (accessories.length > 0) {
        accessories.forEach((item: { price: number }) => {
          totalAccessories += item.price;
        });
      }
      if (totalAccessories > 0) {
        totalAccessoriesVat =
          totalAccessories + totalAccessories * (1 + versionDetails.vat / 100);
      }
      let vehicle: IVehicle = {
        id: 0,
        proposalId: proposal.id,
        makeId: versionDetails.make,
        leaseQuoteNo:"",
        versionId: versionDetails.no,
        modelId: versionDetails.modelNo,
        priceexclVAT: versionDetails.priceexclVAT,
        consumersPrice: versionDetails.consumersPrice,
        recycling_charge: versionDetails.recycling_charge,
        luxury_tax: versionDetails.luxury_tax,
        transport_x0026_immatriculation_cost:
          versionDetails.transport_x0026_immatriculation_cost,
        status: versionDetails.status,
        use: versionDetails.use,
        pvp: 0,
        numberofDoors: versionDetails.numberofDoors,
        fuelId: versionDetails.fuelId,
        fuelType: versionDetails.fuelType,
        euroTaxInternationalSegmt: versionDetails.euroTaxInternationalSegmt,
        quantity_of_Seats: versionDetails.quantity_of_Seats,
        lengthmm: versionDetails.lengthmm,
        widthmm: versionDetails.widthmm,
        heightmm: versionDetails.hightmm,
        trunkVolumel: versionDetails.trunkVolumel,
        wheelBasemm: versionDetails.wheelBasemm,
        trainFrontWheelsmm: versionDetails.trainFrontWheelsmm,
        trainRearWheelsmm: versionDetails.trainRearWheelsmm,
        compressionratio: versionDetails.compressionratio,
        bore: versionDetails.bore,
        powerHP: versionDetails.powerHP,
        powerkW: versionDetails.powerkW,
        cO2DischargeDecF: versionDetails.cO2DischargeDecF,
        engineCc: versionDetails.engineCc,
        torqueNm: versionDetails.torqueNm,
        fuelTankCapacityl: versionDetails.fuelTankCapacityl,
        maxspeedkmh: versionDetails.maxspeedkmh,
        acceleration0100kmh: versionDetails.acceleration0100kmh,
        number_of_wheels: versionDetails.number_of_wheels,
        licensePlate: "",
        mileage: 0,
        modelYear: "",
        recordDate: "0001-01-01T00:00:00",
        lotId: (itemsToAdd[i].associateLotTextFieldProps.value as string) || "",
        totalExtraWithoutTax: 0,
        totalAccessoriesWithout: totalAccessories,
        proformaInvoicePriceWithoutTax: 0,
        totalExtraWithTax: 0,
        totalAccessoriesWith: totalAccessoriesVat,
        proformaInvoicePriceWithTax: 0,
        discountWithTaxTotal: 0,
        rappelWithTaxTotal: 0,
        campaignWithTaxTotal: 0,
        businessValueWithoutTax: 0,
        businessValueWithTax: 0,
        discountWithTax: 0,
        discountWithoutTax: 0,
        discountWithTaxPercentage: 0,
        campaignWithTax: 0,
        campaignWithoutTax: 0,
        campaignWithTaxPercentage: 0,
        rappelWithTax: 0,
        rappelWithTaxPercentage: 0,
        rappelWithoutTax: 0,
        extraRappel: 0,
        vat: versionDetails.vat,
        luxury_tax_vat:
          versionDetails.luxury_tax +
          versionDetails.luxury_tax * versionDetails.vat,
        no: versionDetails.no,
        accessories: accessories,
        extraStandard: extras,
        extraOptionSelect: 0,
        extraOptionsSearch: [],
        simulations: [],
        versionDescription: `${versionDetails.make} - ${versionDetails.description}`,
        caracteristics: "",
        quantity: 1,
        typeofRental: currentUser!.typeofRental,
        commercialConditions: currentUser!.commercialConditions,
        typeofIncome: currentUser?.typeofIncome ? currentUser.typeofIncome : "",
        oilChange: versionDetails.oilChange,
        bPM: versionDetails.bPM,
        technMaxQuantityKm: versionDetails.technMaxQuantityKm,
        driverId: "",
        renew: false,
        hidden: false,
        commercialOptions: [],
        group_Price_Map:versionDetails.group_Price_Map,
        modelDescription: versionDetails.modelDescription,
        iuc:versionDetails.iuc,
        //quotationProps
        vehicleStatus: {
          label: "Criado",
          color: "info",
        },
        //listagem de propostas
        driverName: "",
      };
      vehicle = await initExtraStandardDetails(vehicle);
      let option: Option = {
        label: `${versionDetails.make}; ${versionDetails.description}; ${versionDetails.caracteristics}`,
        value: versionDetails.no,
      };
      vehicle = updateValuesVehicle(vehicle,"");
      if(vehiclesToUpsert){
        vehiclesToUpsert.push(vehicle);
        searchOptions.push(option);
      }
    }
    const upsertVehiclesResponse = await upsertVehicles(vehiclesToUpsert);
    setVehicleDataTable((rows) => {
      upsertVehiclesResponse.forEach((upsertedVehicle) => {rows.push(upsertedVehicle);});
      searchOptions.forEach((option) => {updateSearchOptions(option, "", rows);});
      setVehiclesListingTotal(rows.length);
      return rows;
    });
    setChangedProposal(true);
  }, [
    upsertVehicles,
    //addVehicle,
    updateSearchOptions,
    currentUser,
    proposal.id,
    // isDetailsStep2VehicleOpen,
    initExtraStandardDetails,
    // validar dependencias 10-04
    versionsSearchOptions,
    //unnecessary dependencies
    // vehicleDataTable,
  ]);

  const {
    filterDrawerProps,
    brandsSectionProps,
    setBrandsSectionProps
  } = useAdvVehicleFilterHook(settings, vehiclesService, handleAddAdvSearchSelectedVehicles_Click, openAlert);

  const getModel = useCallback((make: string,model:string) => {
    const index = modelSearchOptions.findIndex((e) =>
      e.makeId === make ? make : ""
    );

    let makeModel = modelSearchOptions.find( x => x.makeId === make);
    if( makeModel !== undefined && makeModel?.models.find( x => x.value === model) === undefined && (model !== undefined && model !== "")) {
      let brand = brandsSectionProps.find((x: { label: string; models:{label:string,id:string}[]}) => x.label === make);
      let modelOption = brand.models.find((x: { id: any;label:string }) => x.id === model);
      const index = modelSearchOptions.findIndex((e) =>
        e.makeId === make ? make : ""
      );

     if(makeModel.models === undefined )
       makeModel.models=[{label:modelOption.label,value:modelOption.id}];
     else
       makeModel?.models.push({label:modelOption.label,value:modelOption.id});

       return makeModel?.models ? makeModel?.models : [];

    }

    return modelSearchOptions[index]?.models ? modelSearchOptions[index]?.models : [];

  },[modelSearchOptions,brandsSectionProps]);

  const handleTableInputModelChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let splits = e.target.name.split("-");
    const rowIndex =convertNumber(splits[1]);
    let filters: { type: AdvancedFilterVersionsOptions; value: string }[] = [];
    filters.push({
      type: AdvancedFilterVersionsOptions.Make,
      value: vehicleDataTable[rowIndex].makeId,
    });
    filters.push({
      type: AdvancedFilterVersionsOptions.Model,
      value: e.target.value,
    });

    const filtersBystate = VehicleTypeStatusHelper.generateAdvancedFilterVersionsOptions(true);
    if(filtersBystate.length > 0){
      filters.push(...filtersBystate);
    }

    await vehiclesService.getVersionsToCombo(filters).then((data) => {
      if (!data.succeeded) {
        openAlert({
          variant: "error",
          text: "Ocorreu um erro pesquisar viaturas! Por favor tente novamente. Se o problema persistir, contate o administrador.",
          title: "Atenção",
        });
      } else {
        if (data.value && data.value.length > 0) {
          // atualiza as versões disponíveis para seleção
          setVersionsSearchOptions((rows) =>
            rows.map((cur) => {
              if (cur.makeId !== vehicleDataTable[rowIndex].makeId || cur.modelId !== e.target.value)  {
                return cur;
              }

              if (cur.versions.length === 1 && cur.versions[0].label==='') {
                cur.versions = data.value;
              } else {
                data.value.forEach((item: { label: string; value: string; isInactive: boolean }) => {
                  if (cur.versions.find((ver) =>{ return ver.value === item.value; }) === (null || undefined)) {
                    cur.versions.push(item);
                  }
                });
              }
              return cur;
            })
          );

          let option: Option = {
            label:"",
            value: "",
          };
          let oldVersion=vehicleDataTable[rowIndex].versionId;
          vehicleDataTable[rowIndex].versionId="";
          updateSearchOptions(option,oldVersion,vehicleDataTable);
          // atualiza modelo da viatura
          setVehicleDataTable((rows) =>
            rows.map((row, indexR) => {
              if (indexR === rowIndex) {
                row.modelId = e.target.value;
              }
              return {...row};
            })
          );
        } else {
          setVersionsSearchOptions((rows) =>
            rows.map((cur, index) => {
              if (cur.makeId === e.target.value) {
                cur.versions = [{ label: "", value: "", isInactive: false }];
              }
              return {...cur};
            })
          );

          openAlert({
            variant: "error",
            text:
              "O modelo " +
              modelSearchOptions.find(x => x.makeId === vehicleDataTable[rowIndex].makeId)?.models.find(x => x.value === e.target.value)?.label +
              " não contem versões disponiveis!",
            title: "Atenção",
          });
        }
      }
    });
  },[
    vehicleDataTable,
    // currentUser?.commercialConditions,
    // currentUser.typeofIncome,
    // currentUser?.typeofRental,
    // proposal.id,
    modelSearchOptions,
    updateSearchOptions
  ]);

  const handleTableInputVersionChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChangedProposal(true);
    let splits=e.target.name.split('-');
    const name=splits[0];
    const rowIndex =convertNumber(splits[1]);

    let filters: { type: AdvancedFilterVersionsOptions; value: string }[] =
      [];
    filters.push({
      type: AdvancedFilterVersionsOptions.No,
      value: e.target.value,
    });
    vehiclesService.getVersions(filters).then(async (data) => {
      if (!data.succeeded) {
        openAlert({
          variant: "error",
          text: data.message,
          title: "Atenção",
        });
      } else {
        if (data.value.length > 0) {
          let vehicleTemp:IVehicle = {
            id:0,
            proposalId: proposal.id,
            makeId: "",
            leaseQuoteNo:"",
            versionId: "",
            modelId: "",
            licensePlate: "",
            priceexclVAT: 0,
            consumersPrice: 0,
            pvp:0,
            recycling_charge: 0,
            luxury_tax: 0,
            transport_x0026_immatriculation_cost: 0,
            status: "",
            use: "",
            numberofDoors: 0,
            fuelId: "",
            fuelType: "",
            euroTaxInternationalSegmt: "",
            quantity_of_Seats: 0,
            lengthmm: 0,
            widthmm: 0,
            heightmm: 0,
            trunkVolumel: 0,
            wheelBasemm: 0,
            trainFrontWheelsmm: 0,
            trainRearWheelsmm: 0,
            compressionratio: 0,
            bore: 0,
            powerHP: 0,
            powerkW: 0,
            cO2DischargeDecF: 0,
            engineCc: 0,
            torqueNm: 0,
            fuelTankCapacityl: 0,

            maxspeedkmh: 0,
            acceleration0100kmh: 0,
            mileage: 0,
            modelYear: "",
            recordDate: "",
            accessories: [],
            extraStandard: [],
            simulations: [],
            no: "",
            lotId: "",
            totalExtraWithoutTax: 0,
            totalAccessoriesWithout: 0,
            proformaInvoicePriceWithoutTax: 0,
            totalExtraWithTax: 0,
            totalAccessoriesWith: 0,
            proformaInvoicePriceWithTax: 0,
            discountWithTaxTotal: 0,
            rappelWithTaxTotal: 0,
            campaignWithTaxTotal: 0,
            businessValueWithoutTax: 0,
            businessValueWithTax: 0,
            discountWithTax: 0,
            discountWithoutTax: 0,
            discountWithTaxPercentage: 0,
            campaignWithTax: 0,
            campaignWithoutTax: 0,
            campaignWithTaxPercentage: 0,
            rappelWithTax: 0,
            rappelWithTaxPercentage: 0,
            rappelWithoutTax: 0,
            extraRappel: 0,
            extraOptionsSearch: [],
            extraOptionSelect: 0,
            versionDescription: "",
            caracteristics: "",
            quantity: 1,
            typeofRental: currentUser?.typeofRental
              ? currentUser?.typeofRental
              : "",
            commercialConditions: currentUser?.commercialConditions
              ? currentUser?.commercialConditions
              : "",
            typeofIncome: currentUser?.typeofIncome
              ? currentUser.typeofIncome
              : "",
            oilChange: 0,
            bPM: 0,
            luxury_tax_vat: 0,
            vat: 0,
            technMaxQuantityKm: 0,
            driverId: "", //FIXME susbtituir por nome do contato do user quando este estiver definido no user
            renew: false,
            hidden: false,
            commercialOptions: [],
            modelDescription: "",
            iuc:0,
            number_of_wheels:0,
            group_Price_Map:"",
            vehicleStatus: {
              color: "warning",
              label: "evaluating",
            },
            driverName: "",
          };

          const option: Option = {
            label: `${data.value[0].make}; ${data.value[0].description}; ${data.value[0].caracteristics}`,
            value: data.value[0].no,
          };
          vehicleTemp.versionId = e.target.value;

          await initAccessoriesDetails(vehicleTemp);
          await initExtraStandardDetails(vehicleTemp); //,rowIndex);
          setVehicleDataTable((rows) =>
            rows.map((row, indexR) => {
              if (indexR !== rowIndex) {
                return row;
              }
              let oldVersion = rows[rowIndex].versionId;
              rows[rowIndex].versionId = e.target.value;
              updateSearchOptions(option, oldVersion, rows);

              row[name] = e.target.value;
              row.priceexclVAT = data.value[0].priceexclVAT;
              row.recycling_charge = data.value[0].recycling_charge;
              row.luxury_tax = data.value[0].luxury_tax;
              row.vat = data.value[0].vat;
              row.luxury_tax_vat = data.value[0].luxury_tax + data.value[0].luxury_tax * data.value[0].vat;
              row.transport_x0026_immatriculation_cost = data.value[0].transport_x0026_immatriculation_cost;
              row.status = data.value[0].status;
              row.use = data.value[0].use;
              row.numberofDoors = data.value[0].numberofDoors;
              row.fuelId = data.value[0].fuelId;
              row.fuelType = data.value[0].fuelType;
              row.euroTaxInternationalSegmt = data.value[0].euroTaxInternationalSegmt;
              row.quantity_of_Seats = data.value[0].quantity_of_Seats;
              row.lengthmm = data.value[0].lengthmm;
              row.widthmm = data.value[0].widthmm;
              row.hightmm = data.value[0].hightmm;
              row.trunkVolumel = data.value[0].trunkVolumel;
              row.wheelBasemm = data.value[0].wheelBasemm;
              row.trainFrontWheelsmm = data.value[0].trainFrontWheelsmm;
              row.trainRearWheelsmm = data.value[0].trainRearWheelsmm;
              row.compressionratio = data.value[0].compressionratio;
              row.bore = data.value[0].bore;
              row.powerHP = data.value[0].powerHP;
              row.powerkW = data.value[0].powerkW;
              row.cO2DischargeDecF = data.value[0].cO2DischargeDecF;
              row.engineCc = data.value[0].engineCc;
              row.torqueNm = data.value[0].torqueNm;
              row.fuelTankCapacityl = data.value[0].fuelTankCapacityl;
              row.fuelDescription = data.value[0].fuelDescription;
              row.maxspeedkmh = data.value[0].maxspeedkmh;
              row.acceleration0100kmh = data.value[0].acceleration0100kmh;
              row.recordDate = data.value[0].recordDate;
              row.modelYear = data.value[0].modelYear;
              row.modelId = data.value[0].modelNo;
              row.versionDescription = data.value[0].make + " - " + data.value[0].description;
              row.caracteristics = data.value[0].caracteristics;
              row.accessories = vehicleTemp?.accessories;
              row.extraStandard = vehicleTemp?.extraStandard;
              row.extraOptionsSearch = vehicleTemp?.extraOptionsSearch;
              row.iuc = data.value[0].iuc;
              row.number_of_wheels = data.value[0].number_of_wheels;
              row.discountWithoutTax = 0;
              row.rappelWithTax = 0;
              row.extraRappel = 0;
              row.campaignWithoutTax = 0;
              row.discountWithTaxPercentage = 0;
              row.rappelWithTaxPercentage = 0;
              row.campaignWithTaxPercentage = 0;
              row.group_Price_Map = data.value[0].group_Price_Map;
              row.vehicleStatus = {
                label: "Criado",
                color: "info",
              };

              // initAccessoriesDetails(row);
              // initExtraStandardDetails(row);

              updateValuesVehicle(row,"");
              return {...row};
            })
          );
        }
      }
    });
  },
  [
    currentUser,
    proposal.id,
    initAccessoriesDetails,
    initExtraStandardDetails,
    updateSearchOptions,
  ]);

  const handleAccessoryDeleteButtonPropsClick : ButtonProps["onClick"] =  useCallback((e: any) => {
    let splits = e.currentTarget.name.split("-");
    const rowIndex =convertNumber(splits[0]);
    const indexAcc =convertNumber(splits[1])

    setVehicleDataTable((rows) =>
      rows.map((row, index) => {
        if(index !== rowIndex){
          return row;
        }
        let vehicle = row;
        let accessoryToDelete:IAccessory = vehicle.accessories.splice(indexAcc, 1)[0];
        if (vehicle.accessories.length > 0) {
          vehicle.totalAccessoriesWithout = 0;
          vehicle.accessories.forEach((item: { price: number; })=>{
            vehicle.totalAccessoriesWithout +=convertNumber(item.price);
          });
          vehicle.totalAccessoriesWith = vehicle.totalAccessoriesWithout  * (100 + vehicle.vat) / 100;
        } else {
          vehicle.totalAccessoriesWith=0;
          vehicle.totalAccessoriesWithout=0;
        }
        console.log(accessoryToDelete)
        if(accessoryToDelete?.id > 0){
          vehiclesService.deleteAccessory(accessoryToDelete?.id)
        }

        vehicle = updateValuesVehicle(vehicle,"");
        return {...row,vehicle};
      }
    ));
  },[])

  const handleAccessoryTextFieldChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      let splits = e.target.name.split("-");
      const name = splits[0];
      const rowIndex =convertNumber(splits[1]);
      const indexAcc =convertNumber(splits[2]);
      setVehicleDataTable((rows) =>
        rows.map((row, index) => {
          if (index !== rowIndex) {
            return row;
          }
          let totalAccessoriesWithoutVat = 0;
          let updatedAccessories = row.accessories.map((accessory, accessoryIndex) => {
            if(accessoryIndex !== indexAcc) {
              return accessory
            }
            if (name === "description" || name === "responsable") {
              accessory = { ...accessory, [name]: e.target.value };
            } else {
              accessory = { ...accessory, [name]: validaStringFormatDecimal(e.target.value,accessory['price_Excl_VAT'])};
              accessory.price = accessory.price_Excl_VAT * (1 + row.vat / 100);
            }
            return accessory
          })
          //atualiza acessórios da viatura
          row = {...row,accessories: updatedAccessories}
          // atualiza valores totais de acessórios com iva na viatura
          row.accessories.forEach(accessory => {
            totalAccessoriesWithoutVat += convertNumber(accessory.price_Excl_VAT);
          })
          row.totalAccessoriesWithout = totalAccessoriesWithoutVat
          row.totalAccessoriesWith = convertNumber(
            (row.totalAccessoriesWithout * (1 + row.vat / 100)).toFixed(2)
          );
          updateValuesVehicle(row,"");
          return row;
        })
      );
    },[]);


  const handleExtraSearchPropsClick: ButtonProps["onClick"] = useCallback(
    (e: any) => {
      let rowIndex =convertNumber(e.currentTarget.name);
      setVehicleDataTable((rows) =>
        rows.map((row, index) => {
          if (index !== rowIndex) {
            return row;
          }
          let searchResults = row.extraOptionsSearch.filter(
            (b) => b.label === row.extraOptionSelect?.label
          )[0]; // [0 temporary solution]

          if (searchResults !== null) {
            row.extraStandard.forEach((element) => {
              if (element.optionTypeId === searchResults?.value) {
                element.visible = true;
              } else {
                element.visible = false;
              }
            });
            updateValuesVehicle(row,"");
          }
          return row;
        })
      );
    },
    []);

  const handleExtraSearchPropsChange = useCallback(
    (rowIndex: Number) => (e: any, option: any) => {
      setVehicleDataTable((rows) =>
        rows.map((row, index) => {
          if (index !== rowIndex) {
            return row;
          }
          if (option === null) {
            row.extraStandard.forEach((element) => {
              element.visible = true;
            });
          }
          row.extraOptionSelect = option;

          updateValuesVehicle(row,"");
          return row;
        })
      );
  }, []);

 const getExtraSearchForVehicleshydra = useCallback((index:number) => {
    let searchResult:SearchResultProps={
      label: 'Pesquisar por extra',
      placeholder: 'Pesquisar',
      onChange: handleExtraSearchPropsChange(index),
      value:vehicleDataTable[index].extraOptionSelect,
      options:vehicleDataTable[index].extraOptionsSearch,
    };
    return searchResult;
  }, [vehicleDataTable,handleExtraSearchPropsChange]);


  const getExtraStandardhydra = useCallback((index:number) => {
    const aux: VehicleDetailsProps['extraStandard'] = [];
    let vehicle = vehicleDataTable[index];
    vehicle.extraStandard.filter(x => x.visible === true).forEach((item,indexExtr) =>{
      let disable = vehicle.licensePlate ? true :false;
      aux.push({
        group: item.main_Group_Allocation ? item.main_Group_Allocation : "",
        groupLabel: item.descGroup ? item.descGroup :"",
        code: item.optionTypeId,
        label: item.description,
        valueTextFieldProps: {
          name: "price-price_Excl_VAT-" + index + "-" + indexExtr, //name: `price-${index}-${indexExtr}`,
          value: item.price_Excl_VAT,
          // onChange: handleExtraStandardTextFieldChange,
          onInput: handleExtraStandardTextFieldChange,
          disabled: propertyDisabled('Extras','Detalhes Viatura') || disable,
          hidden: propertyHidden('Extras','Detalhes Viatura') || disable,
          warning: propertyValidation(item.price_Excl_VAT, 'Extras','Detalhes Viatura'),
          // onChange: ()=> alert('4'),
          // onInput: ()=>{alert('3')}

        },
        checkboxProps: {
          checked: item.checkedExtra,
          name:item.optionTypeId,
          disabled: propertyDisabled('Extras','Detalhes Viatura') || disable,
          hidden: propertyHidden('Extras','Detalhes Viatura') || disable,
        },
        lazyload: true,
        requiredCodes: [], // => obrigatórios
        incompatibilityCodes: [], // incompatibilidade de extras
        includes: [], // => packs de extras
      });
      // Inclusion Type
      switch (item.inclusion_Type) {
        case 'Obligatory':
          aux[aux.length-1].requiredCodes = item.compatibleExtrasList
          break;
        case 'Inclusion':
          aux[aux.length-1].requiredCodes = item.compatibleExtrasList
          break;
        case 'Exclusion':
          // temp fix
          let incompatibleExtrasTmp:string[] = []
          item.incompatibleExtrasList.forEach(element => {
            element.forEach(value => {
              incompatibleExtrasTmp.push(value)
            });
          });
          aux[aux.length-1].incompatibilityCodes = incompatibleExtrasTmp
          // end temp fix
          // aux[aux.length-1].incompatibilityCodes = item.incompatibleExtrasList
          break;

        default:
          break;
      }
      // Package
      if(item.package === 'Yes') {
        let packageExtras:{
          label:string,
          code:string
        }[] = [];

        //não consigo resolver isto. retorna sempre uma promise
        // let data = await vehiclesService.getIncompatibilityExtraPackageDescription(vehicleDataTable[index].versionId,item.compatibleExtrasList)//.then(data => {
        // if(data.succeeded) {
        //   data.value.forEach((element:{label:string, code:string}) => {
        //     packageExtras.push(element)
        //   });
        //   aux[aux.length-1].includes = packageExtras;
        //   aux[aux.length-1].requiredCodes = []
        // }
        // // });

        item.compatibleExtrasList.forEach(value => {

          packageExtras.push({
            label:value,
            code:value
          })
        })
        aux[aux.length-1].includes = packageExtras;
        aux[aux.length-1].requiredCodes = []

      } else {
        aux[aux.length-1].includes = []
      }
    })
    return aux;
  }, [vehicleDataTable, handleExtraStandardTextFieldChange, propertyDisabled, propertyHidden, propertyValidation]);



  const [vendors, setVendors] = useState<Option[]>([]);

  const handleVendorsCallback = useCallback(() => {
    let results: Option[] = vendors;
    vehiclesService.getVendors().then((data) => {
      if (data && data.succeeded) {
        data.value.forEach((x: any) => {
          results.push({
            label: x.name,
            value: x.no,
          });
        });
        setVendors((rows) => {
          rows = results;
          return rows;
        });
      }
    });
    return results;
  }, [vendors ]);

  const vendorsMemo = useMemo(
    () => handleVendorsCallback(),
    [handleVendorsCallback]
  );

  const getAccessorieshydra = useCallback((index: number) => {
    const aux: VehicleDetailsProps["extraAccessories"] = [];
    let vehicle = vehicleDataTable[index];
    vehicle.accessories.map((item, indexAcc) => {
      let disable = vehicle.licensePlate ? true : false;
      let name = "Nome";
      let responsable = "Responsável de instalação";
      let price = "Preço sem IVA";
      aux.push({
        deleteExtraAcessoryIconButtonProps: {
          name:index + "-" + indexAcc,
          disabled: propertyDisabled('Acessórios','Detalhes Viatura') || item.optionTypeId !== '',
          hidden: propertyHidden('Acessórios','Detalhes Viatura'),
          onClick:handleAccessoryDeleteButtonPropsClick,
        },
        ExtraAcessoryNameTextFieldProps: {
          label: name,
          placeholder: "Inserir nome do acessório",
          name: "description-" + index + "-" + indexAcc,
          value: item.description,
          onChange: handleAccessoryTextFieldChange,
          disabled: propertyDisabled('Acessórios','Detalhes Viatura') || disable,
          hidden: propertyHidden('Acessórios','Detalhes Viatura'),
          warning: propertyValidation(item.description, 'Acessórios','Detalhes Viatura')
        },
        ExtraAcessoryInstallerTextFieldProps: {
          label: responsable,
          placeholder: "Inserir nome do responsável",
          value: item.responsable,
          name: "responsable-" + index + "-" + indexAcc,
          options: vendorsMemo,
          onChange: handleAccessoryTextFieldChange,
          disabled: propertyDisabled('Acessórios','Detalhes Viatura') || disable,
          hidden: propertyHidden('Acessórios','Detalhes Viatura'),
          warning: propertyValidation(item.responsable, 'Acessórios','Detalhes Viatura')
        },
        ExtraAcessoryPriceTextFieldProps: {
          label: price,
          placeholder: "Inserir valor ",
          name: "price_Excl_VAT-" + index + "-" + indexAcc,
          endAdornment: "€",
          value: item.price_Excl_VAT,
          onChange: handleAccessoryTextFieldChange,
          disabled: propertyDisabled('Acessórios','Detalhes Viatura') ||disable,
          hidden: propertyHidden('Acessórios','Detalhes Viatura'),
          warning: propertyValidation(item.price_Excl_VAT, 'Acessórios','Detalhes Viatura' )
        },
        lazyload: true,
      });
      return item;
    });
    return aux;
  }, [vehicleDataTable, propertyHidden, handleAccessoryDeleteButtonPropsClick, handleAccessoryTextFieldChange, propertyDisabled, propertyValidation, vendorsMemo]);

  const handleAddAccessoryButtonPropsClick: ButtonProps["onClick"] = useCallback((e: any) => {
    let splits = e.currentTarget.name.split("-");
    const rowIndex =convertNumber(splits[0]);
    const id =convertNumber(splits[1]);
    setChangedProposal(true);
    setVehicleDataTable((rows) =>
      rows.map((row, index) => {
        if (index !== rowIndex) {
          return row;
        }
        let vehicle = row;
        vehicle.accessories.unshift({
          id: 0,
          optionTypeId: "",
          description: "",
          vehicleId: id,
          price: 0,
          price_Excl_VAT:0,
          responsable: "",
        });

        return { ...row, vehicle };
      })
    );
  }, []);

  const handleExtraStandarCheckChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      checked: boolean,
      index: number,
      tableIndex: number
    ) => {
      setChangedProposal(true);
      setVehicleDataTable((rows) =>
        rows.map((row, indexR) => {
          if (indexR !== tableIndex) {
            return row;
          }
          let extra = row.extraStandard.find(
            (x) => x.optionTypeId === e.target.name
          );
          let indexExtra = row.extraStandard.findIndex(
            (ext) => ext.optionTypeId === e.target.name
          );
          if (extra){
            extra.checkedExtra = e.target.checked;
            extra.checkboxProps.checked = e.target.checked;
            row.extraStandard[indexExtra]= extra
            // row.extraStandard[indexExtra] = {
            //   ...extra,
            //   checkedExtra: e.target.checked, checkboxProps:{checked: e.target.checked}
            // };
          }

          let totalextraStandard = 0;
          let totalextraStandardVat = 0;

          if (row.extraStandard.length > 0) {
            row.extraStandard.forEach((item) => {
              if (item.checkedExtra === true) totalextraStandard +=convertNumber(item.price_Excl_VAT.toString());
            });

            if (totalextraStandard !== 0)
              totalextraStandardVat =convertNumber(
                (totalextraStandard * (1 + row.vat / 100)).toFixed(2)
              );
            } else {
              totalextraStandard = row.totalExtraWithoutTax;
              totalextraStandardVat = row.totalExtraWithTax;
            }

          row.totalExtraWithoutTax = Number(totalextraStandard.toFixed(2));
          row.totalExtraWithTax = Number(totalextraStandardVat.toFixed(2));
          updateValuesVehicle(row,"");
          return row;
        })
      );

      // const el = e.target.closest('#extra-check');

      // if (el) {
      //   const index: number = parseInt(el.getAttribute('data-curindex') || '-1');
      //   onExtraCheckboxChange(e, value, index, tableIndex);
      //   checkExtrasCompatibility();
      // }
    },
    []
  );

  const [previousRentalTypeId, setPreviousRentalTypeID] = useState<string>("");

  const syncQuote = useCallback(async () => {

    setSimulationLoading(true);
    setSimulationLoadingText("A sincronizar simulações...");

    let vehicleList:IVehicle[] = JSON.parse(JSON.stringify(vehicleDataTable));
    proposal.vehicles = vehicleDataTable;
    let proposalRequest:UpsertProposalRequest = {
      proposal: proposal,
      rentalTypeId: "",
      commercialConditionsId: "",
      publish: true,
    };
    if (vehicleList && vehicleList.length > 0) {
      proposalRequest.rentalTypeId = vehicleList[0].typeofRental;
      proposalRequest.commercialConditionsId = vehicleList[0].commercialConditions;
    }

    let upsertResponse = await proposalService.upsert(proposalRequest);
    let leaseQuoteNo = proposal.leaseQuoteNo;
    if (upsertResponse.succeeded && !upsertResponse.hasErrors) {
      leaseQuoteNo = upsertResponse.value.leaseQuoteNo;
      setProposal(upsertResponse.value);

      const allUpserts:APIResponse[] = [];
      // for (let i1 = 0; i1 < vehicleList.length; i1++) {
      //   const i = i1; //Garantir o valor correto do indice
      //   for (let i2 = 0; i2 <  vehicleList[i].simulations.length; i2++) {
      //     vehicleList[i].simulations[i2].no = upsertResponse.value.vehicles[i].simulations[i2].no;
      //     vehicleList[i].simulations[i2].id = upsertResponse.value.vehicles[i].simulations[i2].id;
      //   }
      //   vehicleList[i].leaseQuoteNo = upsertResponse.value.vehicles[i].leaseQuoteNo;
      //   vehicleList[i].id = upsertResponse.value.vehicles[i].id;
      //   let vehicleInsert:IVehicle = JSON.parse(JSON.stringify(vehicleList[i]));
      //   var resp = await vehiclesService.upsertVehicle1(
      //     vehicleInsert,
      //     upsertResponse.value.vehicles[i].leaseQuoteNo
      //   ); //.then((resp) => {
      //   allUpserts.push(resp);
      // }
      let outPutMessage = "";
      let asyncResponseObject: {
        result: string,
        errors: string
      } = {
        result: '',
        errors: outPutMessage
      }

      await  Promise.all(vehicleDataTable.map(async (vehicle, vehicleIndex) => {
        vehicle.leaseQuoteNo = upsertResponse.value.vehicles[vehicleIndex].leaseQuoteNo;
        vehicle.id = upsertResponse.value.vehicles[vehicleIndex].id;
        vehicle.accessories.map((accessory,accessoryIndex) => {
          accessory.id = upsertResponse.value.vehicles[vehicleIndex].accessories[accessoryIndex].id;
          return accessory
        })

        vehicle.simulations.map((simulation,simulationIndex) => {
          simulation.no = upsertResponse.value.vehicles[vehicleIndex].simulations[simulationIndex].no;
          simulation.id = upsertResponse.value.vehicles[vehicleIndex].simulations[simulationIndex].id;
          return simulation;
        })

        await vehiclesService.upsertVehicle1(
          vehicle,
          vehicle.leaseQuoteNo
        ).then((resp) => {
            let currentVehicleIndex = -1;
            let setVehicleDataTableState = false;
            if (
              resp.value !== null &&
              resp.value !== undefined &&
              Object.keys(resp.value).length > 0
            ) {
              currentVehicleIndex = vehicleIndex;


              if (
                //resp.succeeded &&
                resp.value !== null &&
                resp.value !== undefined &&
                Object.keys(resp.value).length > 0 &&
                currentVehicleIndex > -1
              ) {


                vehicleDataTable[currentVehicleIndex].simulations.forEach((simulate,simulationIndex) => {
                  resp.value.simulations[simulationIndex].insurancePackageOptions=simulate.insurancePackageOptions;
                  resp.value.simulations[simulationIndex].vehicleDamageOptions=simulate.vehicleDamageOptions;
                  resp.value.simulations[simulationIndex].occupiersOptions=simulate.occupiersOptions;
                  resp.value.simulations[simulationIndex].roadAssistanceOptions=simulate.roadAssistanceOptions;
                  resp.value.simulations[simulationIndex].liabilityOptions=simulate.liabilityOptions;
                  resp.value.simulations[simulationIndex].isolatedGlassBreakOptions=simulate.isolatedGlassBreakOptions;
                  resp.value.simulations[simulationIndex].fuelOptions=simulate.fuelOptions;
                  resp.value.simulations[simulationIndex].viaVerdeOptions=simulate.viaVerdeOptions;
                  resp.value.simulations[simulationIndex].vehicleTypeOptions=simulate.vehicleTypeOptions;
                  resp.value.simulations[simulationIndex].vsAssistanceVehicleOptions=simulate.vsAssistanceVehicleOptions;
                  resp.value.simulations[simulationIndex].vsAssistanceVehicleTypeOptions=simulate.vsAssistanceVehicleTypeOptions;
                  resp.value.simulations[simulationIndex].vsAccidentVehicleOptions=simulate.vsAccidentVehicleOptions;
                  resp.value.simulations[simulationIndex].vsAccidentVehicleTypeOptions=simulate.vsAccidentVehicleTypeOptions;
                  resp.value.simulations[simulationIndex].vsTheftVehicleOptions=simulate.vsTheftVehicleOptions;
                  resp.value.simulations[simulationIndex].vsTheftVehicleTypeOptions=simulate.vsTheftVehicleTypeOptions;
                  resp.value.simulations[simulationIndex].vsOverhaulVehicleOptions=simulate.vsOverhaulVehicleOptions;
                  resp.value.simulations[simulationIndex].vsOverhaulVehicleTypeOptions=simulate.vsOverhaulVehicleTypeOptions;
                });


                vehicleDataTable[currentVehicleIndex].simulations = resp.value.simulations;

                setVehicleDataTableState = true;
              }

              if (resp.value && currentVehicleIndex > -1) {
                const simulations = resp.value.simulations;
                for (
                  let pos = 0;
                  pos < vehicleDataTable[currentVehicleIndex].simulations.length;
                  pos++
                ) {
                  for (let j = 0; j < simulations.length; j++) {
                    vehicleDataTable[currentVehicleIndex].simulations[j].no = simulations[j].no;
                    vehicleDataTable[currentVehicleIndex].simulations[j].id = simulations[j].id;
                    setVehicleDataTableState = true;
                  }
                }
              }
              if (setVehicleDataTableState) {
                setVehicleDataTable([...vehicleDataTable]);
              }
            }
            if(!resp.succeeded) {

              let errorDetails = "";
              const message = resp.message ? resp.message : "";
              if (resp.errors.length > 0) {
                errorDetails += "<ul>";
                if (currentVehicleIndex > -1)
                  errorDetails +=
                    "<b>" +
                    vehicleDataTable[currentVehicleIndex].versionDescription +
                    "</b>: " +
                    message;
                resp.errors.forEach(
                  (error) => (errorDetails += "<li>" + error + "</li>")
                );
                errorDetails += "</ul>";
              }
              if(errorDetails.length > 0 )
                outPutMessage += errorDetails;
              else if(message.length > 0)
                outPutMessage += message;
              else
                outPutMessage += "Ocorreu um erro.";
            }
            return resp;
        });
        return vehicle;
      })).finally(()=>{
          setSimulationLoadingText("Sincronização concluída");
          setTimeout(() => {
            setSimulationLoading(false);
            setSimulationLoadingText("");
          }, 5000);
          //setSimulationLoadingText(outPutMessage);

          asyncResponseObject = {
            errors: outPutMessage,
            result:`proposal/edit/${proposal.id}`
          }
          setAsyncResponse(asyncResponseObject)

          if (outPutMessage.length > 0) {
            openAlert({
              variant: "error",
              text: outPutMessage,
              title: "Atenção",
            });
          } else {
            // setAsyncResponse(`/proposal/edit/${proposal.id}`);
            openAlert({
              variant: "success",
              text: "Calculo efetuado com sucesso",
              title: "Atenção",
              autoHideDuration: 3000,
            });
            setLeaseQuoteCreatedInBC(true);
          }
          // confirmar se é aqui que se valida a atualização do bc ou no else da mensagem de sucesso
          setChangedProposal(false);
          setEnableWizardStep4(true)
      });


    }
    else {
      setSimulationLoadingText("Sincronização concluída");
      setTimeout(() => {
        setSimulationLoading(false);
        setSimulationLoadingText("");
      }, 5000);

      let errorDetails = "";
      const message = upsertResponse.message ? upsertResponse.message : "";
      if (upsertResponse.errors.length > 0) {
        errorDetails += "<ul>";
        upsertResponse.errors.forEach(
          (error) => (errorDetails += "<li>" + error + "</li>")
        );
        errorDetails += "</ul>";
      }
      openAlert({
        variant: "error",
        text: message + errorDetails,
        title: "Atenção",
      });
    }

  },[proposal, setAsyncResponse, vehicleDataTable])

  const [enableWizardStep0, setEnableWizardStep0] = useState<boolean>(true)
  const [enableWizardStep1, setEnableWizardStep1] = useState<boolean>(false)
  const [enableWizardStep2, setEnableWizardStep2] = useState<boolean>(false)
  const [enableWizardStep3, setEnableWizardStep3] = useState<boolean>(false)
  const [enableWizardStep4, setEnableWizardStep4] = useState<boolean>(false)

  const validateWizardNavigationStep0 = useCallback(() => {
    if(
      searchClient === null || nifValidate === true ||
      // (searchClient && searchClient.value === "") ||  // validar se esta condição é precisa
      emailValidate === true || mobileValidate === true ||
      phoneValidate === true ||
      requiredClientData.name === "" ||
      requiredClientData.vaT_Registration_No === "" ||
      requiredClientData.customerType === "" ||
      requiredClientData.totalFleet === 0
    ){
      setEnableWizardStep1(false)
    }
    else {
      setEnableWizardStep1(true)
    }
  },[
    emailValidate, mobileValidate, nifValidate, phoneValidate,
    requiredClientData.customerType, requiredClientData.name,
    requiredClientData.totalFleet, requiredClientData.vaT_Registration_No, searchClient
  ])

  const validateWizardNavigationStep1 = useCallback((vehicles: IVehicle[]) => {
    if(vehicles && vehicles.length > 0){

      const invalidItems = vehicles.some(x => x.makeId === '' || x.modelId === '' || x.versionId === '');
      if(invalidItems){
        setEnableWizardStep2(false);
        return false;
      }
      else {
        setEnableWizardStep2(true);
        return true
      }
    } else {
      setEnableWizardStep2(false);
      return false;
    }
  },[])

  const validateWizardNavigationStep2 = useCallback((vehicles: IVehicle[]) => {
    if(vehicles && vehicles.length > 0){
      const invalidItems = vehicles.some(x => x.typeofIncome==="" || x.commercialConditions==="" || x.typeofRental==="" || x.quantity === 0);
      if (invalidItems) {
        setEnableWizardStep3(false);
        return false
      } else {
        setEnableWizardStep3(true);
        return true;
      }
    } else {
      setEnableWizardStep3(false);
      return false;
    }
  },[])

  // as validações referentes a esta useCallback são feitas mediante uma variavel de estado pelo que a implementação do método
  // não é necessária. Se no futuro houver alterações ao método de validação re-avaliar a necessidade de implementar este método
  // const validateWizardNavigationStep3 = useCallback(() => {},[])



  const validateStep1 = useCallback(() => {
    let vdt = vehicleDataTable
    if(vdt && vdt.length > 0){

      const invalidItems = vdt.some(x => x.makeId === '' || x.modelId === '' || x.versionId === '');
      if(invalidItems){
        openAlert({
          variant: "error",
          text: "O preenchimento dos campos Marca e Descrição do modelo é obrigatório.",
          title: "Atenção",
        });
        setEnableWizardStep2(false);
        return false;
      }
      else {
        setEnableWizardStep2(true);
        // if (open){
        //   setOpen(false)
        // }
        return true
      }
    }
    else {
      openAlert({
        variant: "error",
        text: "Tem que adicionar pelo menos uma viatura.",
        title: "Atenção",
      });
      setEnableWizardStep2(false);
      return false;
    }
  }, [ vehicleDataTable ]);


  const handleForwardStep0 = useCallback(async () => {
    if (validateRequiredFields() === true) {
      if ((await addProposal(false)) === true) {
        if (proposal.inputChannel === "0") {
          vehiclesService.getDrivers(proposal.contactId).then((data) => {
            if (data.succeeded) {
              setAllDrivers(data.value);
              setDrivers(data.value);
            }
          });
        }
        setStep(step + 1);
      }
    }
  },[addProposal, proposal.contactId, proposal.inputChannel, step, validateRequiredFields])

  const handleForwardStep1 = useCallback(() => {
    addVehicle();
    setStep(step + 1);
  },[addVehicle, step])

  const handleForwardStep2 = useCallback(() => {
    let validate = validateStep2();
    if(validate)
    {
        if(simulationScenario.occupiersOptions.length===0)
        {
            getScenariosFrequentSimulations();
        }
        addVehicle();
        setStep(step + 1);
    }
  },[addVehicle, getScenariosFrequentSimulations, simulationScenario.occupiersOptions.length, step, validateStep2])

  const handleForwardStep3 = useCallback(() => {
    let noSimulations =  vehicleDataTable.filter(x => x.simulations.length === 0);
    if (noSimulations.length > 0 ) {
      openAlert({
        variant: "error",
        text: "Existem viaturas sem simulações. Deverá adicionar as simulações em falta ou remover as viaturas em questão navegando a um dos passos anteriores e eliminado a viatura.",
        title: "Atenção",
      });
    }
    else {
      setStep(step + 1);
      if(!leaseQuoteCreatedInBC){
        syncQuote();
      }

      else {
        if (!simulationLoading){
          addVehicle()
        }
      }
    }
  },[addVehicle, leaseQuoteCreatedInBC, simulationLoading, step, syncQuote, vehicleDataTable])

  const handleForward: ButtonProps['onClick'] = useCallback(async() => {
    setTypeOfRentalGroup("");
    setTypeofIncomeGroup("");
    setCommercialConditionGroup("");
    setSearchDriver({value: "",label: ""});
    if(step > 0){
      if ( !validateStep1()) {
        return ;
      }
    }

    if (step === 0) {
      handleForwardStep0();
    }
    else if (step === 2) {
      handleForwardStep2()
    }
    else if (step === 3) {
      handleForwardStep3();
    }
    else if (step === 4) {
      if(proposal?.printProposalDestinationEmailAddress?.length <=0) {
        setProposal({
          ...proposal,
          printProposalDestinationEmailAddress: requiredClientInterlocutorData.e_Mail,
        });
      }

      //setSimulationLoading(true);
      addVehicle();

      const currentRentalTypeId = vehicleDataTable[0]?.typeofRental;
      if (currentRentalTypeId !== previousRentalTypeId) {
        // previousRentalTypeId = currentRentalTypeId;
        setPreviousRentalTypeID(currentRentalTypeId);
        proposalService.getPrintTemplates(currentRentalTypeId)
          .then((response) => {
            if (response.succeeded) {
              setProposalPrintTemplates(response.value);
            } else {
              openAlert({
                variant: "error",
                text:
                  "Ocorreu um erro obter os templates de propostas do NAV: " +
                  response.message,
                title: "Atenção",
              });
            }
          });
      }
    }

    else {
      handleForwardStep1()
    }
    let details: boolean[] = [];
    vehicleDataTable.forEach((element) => {
      details.push(false);
    });
    setIsDetailsStep2VehicleOpen(details);
  }, [
    step,
    vehicleDataTable,
    previousRentalTypeId,
    setProposalPrintTemplates,
    addVehicle,
    requiredClientInterlocutorData,
    validateStep1,
    handleForwardStep0,
    handleForwardStep1,
    handleForwardStep2,
    handleForwardStep3,
    // proposal
  ]);

  const handleStepClick = useCallback(async (index: number) => {
    let currentStep = step;
    while (currentStep < index) {
      switch (currentStep) {
        case 0:
          await handleForwardStep0();
          break;
        case 1:
          handleForwardStep1();
          break;
        case 2:
          handleForwardStep2();
          break;
        case 3:
          handleForwardStep3();
          break;
        default:
          doNothing();
          break;
      }
      currentStep++;
    }
    setStep(index);
    let details: boolean[] = [];
    vehicleDataTable.forEach((element) => {
      details.push(false);
    });
    setIsDetailsStep2VehicleOpen(details);
  },[handleForwardStep0, handleForwardStep1, handleForwardStep2,handleForwardStep3, step, vehicleDataTable])


  const onCalculateClick: ButtonProps['onClick'] = useCallback( async () => {
    let noSimulations = vehicleDataTable.filter(x => x.simulations.length === 0)
    if (noSimulations.length > 0 ) {
      openAlert({
        variant: "error",
        text: "Existem viaturas sem simulações. Deverá adicionar as simulações em falta ou remover as viaturas em questão navegando a um dos passos anteriores e eliminado a viatura.",
        title: "Atenção",
      });
    }
    else {
      syncQuote();
    }
  },[syncQuote, vehicleDataTable]);

  const handleBackward: ButtonProps["onClick"] = useCallback(() => {
    setTypeOfRentalGroup("");
    setTypeofIncomeGroup("");
    setCommercialConditionGroup("");
    setSearchDriver({value: "",label: ""});
    if (step > 0) setStep(step - 1);
    let details: boolean[] = [];
    vehicleDataTable.forEach((element) => {
      details.push(false);
    });
    setIsDetailsStep2VehicleOpen(details);
    if(changedProposal) {
      addVehicle();
    }
  }, [addVehicle, changedProposal, step, vehicleDataTable]);

  //ESTE USE STATE É UMA EXCEPÇÃO POR CAUSA DE TER UM EVENTO ONCHANGE COM CALLBACK
  // const [extraSearchForVehicles, setExtraSearchForVehicles] = useState<VehicleDetailsProps['extraStandardSearchResultProps'][]>(
  //   vehicleDataTable.map((_) => {
  //    let searchResult:SearchResultProps={
  //       label: 'Pesquisar por extra',
  //       placeholder: 'Pesquisar',
  //       //onChange: handleSearchExtraStandardChange,
  //       options: [
  //         {
  //           label: 'opção1',
  //           value: 'op1',
  //         },
  //         {
  //           label: 'opção2',
  //           value: 'op2',
  //         },
  //       ],
  //     };

  //    return searchResult;
  //   })
  // );

  const loadMostFrequent = useCallback(() => {
    let searchOptions = frequentSearchOptions;
    let frequentVehicles: IMostFrequent[] = [];
    vehiclesService.getMostFrequent(currentUser.userId).then((data) => {
      if (data !== null) {
        (data.value as IMostFrequent[]).forEach((frequentVehicle) => {
          //cria opções de pesquisa
          let frequentSearchOption: Option = {
            label: `${frequentVehicle.versionDescription} - ${frequentVehicle.title}`,
            value: frequentVehicle.id.toString(),
            // value: frequentVehicle.versionId,
          };
          //filtro para duplicados de opções
          if (
            !searchOptions.some((frequentVehicle) => {
              return (
                frequentVehicle.label === frequentSearchOption.label &&
                frequentVehicle.value === frequentSearchOption.value
              );
            })
          )
          searchOptions.push(frequentSearchOption);
          frequentVehicles.push(frequentVehicle);
        });
        setMostFrequent((rows) => (rows = frequentVehicles));

        //carrega configurações
        handleMostFrequent(frequentVehicles);
      }
    });
    vehiclesService.getMostFrequentTotal(currentUser.userId).then(data => {
      if (!data.succeeded) {
        openAlert({
          variant: "error",
          text: "Ocorreu um erro pesquisar viaturas! Por favor tente novamente. Se o problema persistir, contate o administrador.",
          title: "Atenção",
        });
      }
      else {
        setMostFrequentTotal(data.value);
      }
    });
  }, [currentUser, frequentSearchOptions, handleMostFrequent ]);

  const handleCloseMostFrequentModalProps = useCallback(() => {
    setSelectedFrequentVehicles([]);
    setFrequentSearchOptions([]);
    setMostFrequentSearched([]);
    setMostFrequentConfigurations([]);
    setMostFrequent([]);
    setMostFrequentSearchValue('');
    setMostFrequentTotal(0);
    setMostFrequentSearched([]);
    setAllInsurances([]);
  }, []);

  const handleDeleteButtonQuotationSimulationModalProps = useCallback(async () => {
    let searchOptions = frequentQuotationOptions;
    await selectedFrequentVehicles.forEach(async (cur,index) => {
       let vehicle: IVehicle;
       if (cur.checked) {
        vehicle = searchOptions.find((element) => {
          return element.id === cur.mostFrequentId;
        }) as unknown as IVehicle;
        let data=await vehiclesService.deleteMostFrequent(cur.mostFrequentId,currentUser.userId);
        if (!data.succeeded) {
          openAlert({
            variant: "error",
            text: "Ocorreu um erro ao eliminar frequente simulação! Por favor tente novamente. Se o problema persistir, contate o administrador.",
            title: "Atenção",
          });
        }
        else {
          getScenariosFrequentSimulations();
          setFrequentQuotations([]);
          setFrequentQuotationOptions([]);
          setFrequentQuotationsConfigurations([]);
          setSelectedFrequentVehicles([]);
          setSelectedFrequentOption(-1);
        }
      }
    });
  }, [
    mostFrequent,
    currentUser,
    // vehicleDataTable,
    frequentQuotationOptions,
  ]);

  const handleDeleteButtonFrequentSimulationModalProps = useCallback(async () => {
    let searchOptions = frequentSimulationOptions;
    await selectedFrequentVehicles.forEach(async (cur,index) => {
      if (cur.checked) {
        let frequentHeaderSimulation: IFrequentHeaderSimulation = frequentHeaderSimulations.find((x) => {
          return x.id === cur.mostFrequentId;
        });
        let data=await vehiclesService.deleteFrequentHeaderSimulation(frequentHeaderSimulation.userId,frequentHeaderSimulation.id);
        if (!data.succeeded) {
          openAlert({
            variant: "error",
            text: "Ocorreu um erro ao eliminar frequente simulação! Por favor tente novamente. Se o problema persistir, contate o administrador.",
            title: "Atenção",
          });
        }
        else {
          getScenariosFrequentSimulations();
          setFrequentHeaderSimulations([]);
          setFrequentSimulationOptions([]);
          setFrequentSimulationSearchOptions([]);
          setSelectedFrequentVehicles([]);
          setSelectedFrequentOption(-1);
          handleMostFrequentSimulationsClick();
        }
      }
    });
  }, [
    frequentHeaderSimulations,
    selectedFrequentVehicles,
    vehicleDataTable,
    frequentSimulationOptions,
    handleMostFrequentSimulationsClick
  ]);

  const handleDeleteButtonMostFrequentModalProps = useCallback(async () => {
    let searchOptions = frequentSearchOptions;
    let frequentVehicles=mostFrequent;
    let configuration=mostFrequentConfigurations;
    let mostFrequentSrch=mostFrequentSearched;
    await selectedFrequentVehicles.forEach(async (cur,index) => {
      let vehicle: IVehicle;
      if (cur.checked) {
        vehicle = mostFrequent.find((element) => {
          return element.id === cur.mostFrequentId;
        }) as unknown as IVehicle;
        let data=await vehiclesService.deleteMostFrequent(cur.mostFrequentId,currentUser.userId);
        if (!data.succeeded) {
          openAlert({
            variant: "error",
            text: "Ocorreu um erro ao eliminar frequente viatura! Por favor tente novamente. Se o problema persistir, contate o administrador.",
            title: "Atenção",
          });
        }
        else {
          searchOptions.splice(index,1);
          frequentVehicles.splice(index,1);
          configuration.splice(index,1);
          mostFrequentSrch.splice(index,1);
          setFrequentSearchOptions(searchOptions);
          setMostFrequent(frequentVehicles);
          setMostFrequentTotal(frequentVehicles.length);
          setMostFrequentSearched(mostFrequentSrch);
          setMostFrequentConfigurations(configuration);
          setMostFrequentSearchValue('');
          setSelectedFrequentVehicles([]);
        }

      }
    });


  }, [
    currentUser,
    selectedFrequentVehicles,
    mostFrequent,
    mostFrequentConfigurations,
    frequentSearchOptions,
    mostFrequentSearched,
  ]);

  const handleConfirmButtonMostFrequentModalProps = useCallback(() => {
    let vehicles = vehicleDataTable;

    selectedFrequentVehicles.forEach(async (cur) => {
      let vehicle: IVehicle;
      if (cur.checked) {
        vehicle = mostFrequent.find((element) => {
          return element.id === cur.mostFrequentId;
        }) as unknown as IVehicle;
        vehiclesService.getMostFrequentAccessories(cur.mostFrequentId).then(async data => {
          if (!data.succeeded) {
            openAlert({
              variant: "error",
              text: "Ocorreu um erro pesquisar viaturas! Por favor tente novamente. Se o problema persistir, contate o administrador.",
              title: "Atenção",
            });
          }
          else {
            vehicle = data.value;
            await initExtraStandardDetails(vehicle); //,vehicles.length); ou ,vehicleDataTable.length);
            vehicle.proposalId = proposal.id;
            vehicle.commercialOptions = [];
            vehicle.hidden = false;
            if (!vehicle.simulations) {
              vehicle.simulations = [];
            }

            //BEGIN
            //validação da descrição do modelo e carregamento de versões
            const searchIndex = versionsSearchOptions.findIndex(
              (a) => a.makeId === vehicle.makeId && a.modelId === vehicle.modelId
            );
            let versionFilter: {
              type: AdvancedFilterVersionsOptions;
              value: string;
            }[] = [];
            versionFilter.push({
              type: AdvancedFilterVersionsOptions.Make,
              value: vehicle.makeId,
            });
              versionFilter.push({
              type: AdvancedFilterVersionsOptions.Model,
              value: vehicle.modelId,
            });
            const filtersBystate = VehicleTypeStatusHelper.generateAdvancedFilterVersionsOptions(true);
            if(filtersBystate.length > 0){
              versionFilter.push(...filtersBystate);
            }

            vehiclesService.getVersionsToCombo(versionFilter).then((data) => {
              if (data.succeeded) {
                let versions = versionsSearchOptions;
                if (versions[searchIndex].versions.length === 1 && versions[searchIndex].versions[0].label==='') {
                  versions[searchIndex].versions = data.value;
                } else {
                  data.value.forEach((item: { label: string; value: string; isInactive: boolean }) => {
                    if (
                      versions[searchIndex].versions.find((ver) => {
                        return ver.value === item.value;
                      }) === (null || undefined)
                    ) {
                      versions[searchIndex].versions.push(item);
                    }
                  });
                }
                setVersionsSearchOptions([...versions]);
                //END validação
                //BEGIN validação opções de pesquisa
                let modelVersions = versionsSearchOptions.find((x) => {
                  return x.makeId === vehicle.makeId && x.modelId === vehicle.modelId;
                });
                let version = modelVersions?.versions.find((y) => {
                  return y.value === vehicle.versionId;
                });
                if (!version) {
                  versionFilter = []
                  versionFilter.push({
                    type: AdvancedFilterVersionsOptions.Make,
                    value: vehicle.makeId,
                  });
                    versionFilter.push({
                    type: AdvancedFilterVersionsOptions.Model,
                    value: vehicle.modelId,
                  });

                  vehiclesService.getVersionsToCombo(versionFilter).then((data) => {
                    if(data.succeeded){
                      data.value.forEach((item: { label: string; value: string; isInactive: boolean }) => {
                        if(item.value === vehicle.versionId){
                          if (
                            versions[searchIndex].versions.find((ver) => {
                              return ver.value === item.value;
                            }) === (null || undefined)
                          ) {
                            versions[searchIndex].versions.push(item);
                          }
                        }
                      });
                      version = modelVersions?.versions.find((y) => {
                        return y.value === vehicle.versionId;
                      });
                      let option: Option = {
                        label: `${vehicle.makeId}; ${version?.label}`,
                        value: vehicle.versionId,
                      };
                      //END
                      vehicle.id = 0;
                      vehicle.accessories.forEach((accessory) => (accessory.id = 0));
                      vehicle.extraStandard.forEach((extra) => (extra.id = 0));
                      vehicle.simulations.forEach((simulation) => simulation = { ...simulation, id: 0, miles: simulation.miles });
                      // vehicle.simulations.forEach((simulation) => (simulation.id = 0,simulation.miles=simulation.miles));
                      vehicles.push(vehicle); // atualiza combo box
                      updateSearchOptions(option, "", vehicles);
                      setChangedProposal(true);
                      setVehicleDataTable((rows) => (rows = vehicles));
                      setVehiclesListingTotal(vehicleDataTable.length);
                    }
                  })
                }
                else{
                  let option: Option = {
                    label: `${vehicle.makeId}; ${version?.label}`,
                    value: vehicle.versionId,
                  };
                  //END
                  vehicle.id = 0;
                  vehicle.accessories.forEach((accessory) => (accessory.id = 0));
                  vehicle.extraStandard.forEach((extra) => (extra.id = 0));
                  vehicle.simulations.forEach((simulation) => simulation = { ...simulation, id: 0, miles: simulation.miles });
                  // vehicle.simulations.forEach((simulation) => (simulation.id = 0,simulation.miles=simulation.miles));
                  vehicles.push(vehicle); // atualiza combo box
                  updateSearchOptions(option, "", vehicles);
                  setChangedProposal(true);
                  setVehicleDataTable((rows) => (rows = vehicles));
                  setVehiclesListingTotal(vehicleDataTable.length);
                }
              }
            });
          }
        })
      }
    });
    setSelectedFrequentVehicles([]);
    setFrequentSearchOptions([]);
    setMostFrequentSearched([]);
    setMostFrequentConfigurations([]);
    setMostFrequent([]);
    setMostFrequentSearchValue('');
    setMostFrequentTotal(0);
    setMostFrequentSearched([]);
  }, [
    updateSearchOptions,
    vehicleDataTable,
    versionsSearchOptions,
    selectedFrequentVehicles,
    mostFrequent,
    proposal.id,
    initExtraStandardDetails,
  ]);

  const handleFrequentSearchModalProps = useCallback((e: any) => {
    let value = e.target.value;
    if (typeof value === 'string') {
      if (value && value.length > 3) {
        setMostFrequentSearchValue(value)
      }
    }
    else if(typeof value === 'number') {
      setMostFrequentSearchValue('')
      mostFrequent.forEach((element) => {
        // if (element.versionId === value.toString()){
        if (element.id === value){
          setMostFrequentSearched(rows => {
            rows = [];
            rows.push(element);
            return rows;
          })
          // mostFrequentSearched.push(element);
        }
      });

    }
    else {
      handleMostFrequent(mostFrequent);
      setMostFrequentSearched([]);
      setMostFrequentSearchValue('');
    }
  }, [handleMostFrequent, mostFrequent]);

  const handleSearchButtonModalProps = useCallback((e: any) => {

    // let options: {
    //   title?: string | null,
    //   marca?: string | null,
    //   modelo?: string | null,
    // } = { };
    // if (
    //   mostFrequentSearchValue.toLocaleLowerCase().includes('marca') || mostFrequentSearchValue.toLocaleLowerCase().includes('modelo')
    // ) {
    //   let searchInput = mostFrequentSearchValue.split(':')
    //   searchInput.forEach((element, index:number) => {
    //     if (element.toLowerCase().includes('marca')){
    //       options.marca = searchInput[index + 1]
    //     }
    //     if (element.toLowerCase().includes('modelo')){
    //       options.modelo = searchInput[index + 1]
    //     }
    //   })
    // } else {
    //   options.title = mostFrequentSearchValue
    // }

    let searchParam = mostFrequentSearchValue
    if (searchParam) {
      // vehiclesService.searchMostFrequentVehicle(currentUser.userId, options).then(data => {
      vehiclesService.searchMostFrequentVehicle(currentUser.userId, searchParam).then(data => {
        if (!data.succeeded){
          openAlert({
            variant: "error",
            text: "Ocorreu um erro pesquisar viaturas! Por favor tente novamente. Se o problema persistir, contate o administrador.",
            title: "Atenção",
          });
        } else {
          setMostFrequentSearched(data.value);
          let clearConfigurations: [] = [];
          setMostFrequentConfigurations([...clearConfigurations]);
          handleMostFrequent(data.value);
        }
      })
    }

    let clearConfigurations: [] = [];
    setMostFrequentConfigurations([...clearConfigurations]);
    handleMostFrequent(mostFrequentSearched);
  }, [
    mostFrequentSearched, handleMostFrequent,
    mostFrequentSearchValue,
    currentUser.userId
  ]);

  const handleTypeOfRentalOptions = useCallback(
    (leaseCode: string) => {
      let options: Option[] = [];
      vehiclesService.getCommercialConditions(leaseCode).then((data) => {
        if (data.succeeded === true) {
          options = data.value;
        }
      });
      return options;
    }, []);

  const handleCheckboxPropsOnChange = useCallback(
    (index: number, stateVariableArray?: any[], stateVariable?: any) =>
      (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (stateVariableArray !== undefined && stateVariableArray.length > 0) {
          stateVariableArray[index].checkboxProps.checked =
            !stateVariableArray[index].checkboxProps.checked;
          setFilterResults((fr) => fr?.map((result) => result));
        }
        if (stateVariable) {
          stateVariable.checkboxProps.checked =
            !stateVariable.checkboxProps.checked;
          setFilterResults((fr) => fr?.map((result) => result));
        }
      },
    []
  );

  const handleCampaignVehicles = useCallback((vehicles: IVehicle[]) => {
    let campaingVehicles: CampaignVehicleDetails[] = [];

    if (vehicles.length > 0) {
      vehicles.forEach((vehicle, index: number) => {
        let campaingVehicle: CampaignVehicleDetails = {
          id: vehicle.no,
          icons: VehicleUtilities.getFuelTypeIconFor(vehicle.fuel_Type),
          checkboxProps: {
            onChange: handleCheckboxPropsOnChange(
              index,
              selectedCampaignVehicles
            ),
          },
          name: vehicle.versionDescription,
          characteristics: vehicle.caracteristics, //vehicle.versionDescription
          businessPrice: {
            label: `Preço de negócio`,
            value: `${vehicle.businessValueWithoutTax} €`,
            // endAdornment: "€",
          },
          extras: {
            label: `Extras`,
            value: `${vehicle.totalExtraWithoutTax} €`,
          },
          proformaPrice: {
            label: `Preço FT proforma`,
            value: `${vehicle.proformaInvoicePriceWithoutTax} €`,
          },
          details: {
            ...vehicleDetails,
            onExtraCheckboxChange: () => alert("vehicle details 1"),
          },
        };

        campaingVehicles.push(campaingVehicle);
        selectedCampaignVehicles.push(campaingVehicle);
      });
    }
    setSelectedCampaignVehicles((rows) => (rows = campaingVehicles));
    return campaingVehicles;
  }, [selectedCampaignVehicles, handleCheckboxPropsOnChange]);

  const handlePreConfCheckboxPropsOnChange = useCallback(
    (index: number) =>
      (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setPreConfiguredVehicles(items => {
          return items.map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                checkboxProps: {
                  ...item.checkboxProps,
                  checked: !item.checkboxProps.checked
                }
              };
            }
            return item;
          });
        });
      },
      []
    );

  function convertPreConfiguredVehicleToVehicle(preConfiguredVehicle: IPreConfiguredVehicle, proposalId: number, rentalType: string, commercialConditions: string, typeofIncome: string): IVehicle {
    const targetVehicle: IVehicle = newVehicle;
    // Copy common properties from IPreConfiguredVehicle to IVehicle
    const filteredProperties = Object.keys(preConfiguredVehicle)
      .filter(key => key in targetVehicle)
      .reduce((obj, key) => {
        obj[key] = preConfiguredVehicle[key];
        return obj;
      }, {} as Partial<IVehicle>);

    Object.assign(targetVehicle, filteredProperties);

    targetVehicle.id = 0;
    targetVehicle.proposalId = proposalId;
    targetVehicle.typeofRental = rentalType;
    targetVehicle.commercialConditions = commercialConditions;
    targetVehicle.typeofIncome = typeofIncome;

    return {...targetVehicle};
  }

  const handlePreConfiguredVehiclesConfirmClick = useCallback(async () => {
    //extract property id from preConfiguredVehicles where  checkboxProps.chacked is true
    const selectedPreConfiguredVehiclesIds: number[] = preConfiguredVehicles
      .filter((vehicle) => vehicle.checkboxProps.checked === true)
      .map((vehicle) => Number(vehicle.id));

    let searchOptionsToAdd: Option[] = [];

    const getPreConfiguredVehiclesResponse = await vehiclesService.getPreConfiguredVehicles(selectedPreConfiguredVehiclesIds);
    //.then((response) => {
      if (getPreConfiguredVehiclesResponse.succeeded) {
        let preConfVehicles:IPreConfiguredVehicle[] = getPreConfiguredVehiclesResponse.value;

        let newVehicles: IVehicle[] = [];

        for(let i = 0; i < preConfVehicles.length; i++) {
          let vehicle = convertPreConfiguredVehicleToVehicle(preConfVehicles[i], proposal.id, currentUser.typeofRental, currentUser.commercialConditions, currentUser.typeofIncome);

          const currentMakeIndex = versionsSearchOptions.findIndex(
            (x) => x.makeId === vehicle?.makeId && x.modelId===vehicle?.modelId
          );

          const hasInactiveVersion = versionsSearchOptions[currentMakeIndex].versions.some(v => v.value === vehicle?.versionId);
          if(!hasInactiveVersion){
            if( versionsSearchOptions[currentMakeIndex].versions.filter(x => x.value === "").length === 1){
              versionsSearchOptions[currentMakeIndex].versions=[{
                label: `${vehicle?.versionDescription}; ${vehicle?.engineCc}CC; ${vehicle?.powerHP}CV; ${vehicle?.quantity_of_Seats} lugares; ${vehicle?.numberofDoors} portas; ${vehicle?.priceexclVAT} €`,
                value: vehicle.versionId,
                isInactive: true
              }];
            }
            else {
              versionsSearchOptions[currentMakeIndex].versions.push({
                label: `${vehicle?.versionDescription}; ${vehicle?.engineCc}CC; ${vehicle?.powerHP}CV; ${vehicle?.quantity_of_Seats} lugares; ${vehicle?.numberofDoors} portas;  ${vehicle?.priceexclVAT} €`,
                value: vehicle.versionId,
                isInactive: true
              });
            }
          }

          setVersionsSearchOptions([...versionsSearchOptions]);

          await initAccessoriesDetails(vehicle);
          await initExtraStandardDetails(vehicle);
          let option: Option = {
            label: `${vehicle?.versionDescription}; ${vehicle?.engineCc}CC; ${vehicle?.powerHP}CV; ${vehicle?.quantity_of_Seats} lugares; ${vehicle?.numberofDoors} portas; ${vehicle?.priceexclVAT} €`,
            value: vehicle.versionId, //vehicleTmp.no,
          };
          searchOptionsToAdd.push(option);

          vehicle = updateValuesVehicle(vehicle,"");
          newVehicles.push(vehicle);
        }
        setChangedProposal(true);
        setVehicleDataTable((rows) => {
          rows = [...rows, ...newVehicles]
          setVehiclesListingTotal(rows.length)
          searchOptionsToAdd.forEach(option => {
            updateSearchOptions(option, "", rows);
          });
          return rows;
        });
        //setVehicleDataTable((vehicles) => [...vehicles, ...newVehicles]);
        //setVehiclesListingTotal(item => item + newVehicles.length);
      }
      else {
        openAlert({
          variant: "error",
          text: "Não foi possivel obter veículos pré-configurados! Por favor tente novamente. Se o problema persistir, contate o administrador.",
          title: "Atenção",
        });
      }
    //});
    // let accessoryFilter: {
    //   type: AdvancedFilterAccessoryOptions;
    //   value: string;
    // }[] = [];
    // // tentativa adcionar label na combo box
    // // let tmpUsedVehicleOptions: UsedVehicleDetails[] = [
    // //   {
    // //     icons: [],
    // //     checkboxProps: {},
    // //     name: "",
    // //     characteristics: "",
    // //     licensePlate: { label: "", value: "" },
    // //   },
    // // ];
    // let selectedVehicle = selectedVehicles.find((x) => {
    //   return x.checkboxProps.checked === true;
    // });
    // let versionDetails =  vehicleBC.find((x) => {
    //   return x.licensePlate === selectedVehicle?.licensePlate.value;
    // });
    // if (versionDetails?.quantity === 0) {
    //   versionDetails.quantity = 1;
    //   versionDetails.modelYear = "0";
    // }
    // if (versionDetails) {
    //   //let vehicle= versionDetails;
    //   let vehicle: IVehicle = {
    //     id: 0,

    //     proposalId: proposal.id,
    //     makeId: versionDetails.makeId,
    //     leaseQuoteNo:"",
    //     versionId: versionDetails.versionId,
    //     modelId: versionDetails.modelId,
    //     priceexclVAT: versionDetails.priceexclVAT,
    //     consumersPrice: versionDetails.consumersPrice,
    //     recycling_charge: versionDetails.recycling_charge,
    //     luxury_tax: versionDetails.luxury_tax,
    //     transport_x0026_immatriculation_cost:
    //       versionDetails.transport_x0026_immatriculation_cost,
    //     status: versionDetails.status,
    //     use: versionDetails.use,
    //     pvp: 0,
    //     numberofDoors: versionDetails.numberofDoors,
    //     fuelId: versionDetails.fuelId,
    //     fuelType: versionDetails.fuelType,
    //     euroTaxInternationalSegmt: versionDetails.euroTaxInternationalSegmt,
    //     quantity_of_Seats: versionDetails.quantity_of_Seats,
    //     lengthmm: versionDetails.lengthmm,
    //     widthmm: versionDetails.widthmm,
    //     heightmm: versionDetails.hightmm,
    //     trunkVolumel: versionDetails.trunkVolumel,
    //     wheelBasemm: versionDetails.wheelBasemm,
    //     trainFrontWheelsmm: versionDetails.trainFrontWheelsmm,
    //     trainRearWheelsmm: versionDetails.trainRearWheelsmm,
    //     compressionratio: versionDetails.compressionratio,
    //     bore: versionDetails.bore,
    //     powerHP: versionDetails.powerHP,
    //     powerkW: versionDetails.powerkW,
    //     cO2DischargeDecF: versionDetails.cO2DischargeDecF,
    //     engineCc: versionDetails.engineCc,
    //     torqueNm: versionDetails.torqueNm,
    //     fuelTankCapacityl: versionDetails.fuelTankCapacityl,
    //     maxspeedkmh: versionDetails.maxspeedkmh,
    //     acceleration0100kmh: versionDetails.acceleration0100kmh,
    //     number_of_wheels: versionDetails.number_of_wheels,
    //     licensePlate: versionDetails.licensePlate,
    //     mileage: versionDetails.mileage,
    //     modelYear: "",
    //     recordDate: "0001-01-01T00:00:00",
    //     lotId: versionDetails.lotId,
    //     totalExtraWithoutTax: 0,
    //     totalAccessoriesWithout: 0,
    //     proformaInvoicePriceWithoutTax: 0,
    //     totalExtraWithTax: 0,
    //     totalAccessoriesWith: 0,
    //     proformaInvoicePriceWithTax: 0,
    //     discountWithTaxTotal: 0,
    //     rappelWithTaxTotal: 0,
    //     campaignWithTaxTotal: 0,
    //     businessValueWithoutTax: 0,
    //     businessValueWithTax: 0,
    //     discountWithTax: 0,
    //     discountWithoutTax: 0,
    //     discountWithTaxPercentage: 0,
    //     campaignWithTax: 0,
    //     campaignWithoutTax: 0,
    //     campaignWithTaxPercentage: 0,
    //     rappelWithTax: 0,
    //     rappelWithTaxPercentage: 0,
    //     rappelWithoutTax: 0,
    //     extraRappel: 0,
    //     vat: versionDetails.vat,
    //     luxury_tax_vat:
    //       versionDetails.luxury_tax +
    //       versionDetails.luxury_tax * versionDetails.vat,
    //     no: versionDetails.no,
    //     accessories: [],
    //     extraStandard: [],
    //     extraOptionSelect: 0,
    //     extraOptionsSearch: [],
    //     simulations: [],
    //     versionDescription: versionDetails.versionDescription,
    //     caracteristics: "",
    //     quantity: 1,
    //     typeofRental: currentUser!.typeofRental,
    //     commercialConditions: currentUser!.commercialConditions,
    //     typeofIncome: currentUser?.typeofIncome ? currentUser.typeofIncome : "",
    //     oilChange: versionDetails.oilChange,
    //     bPM: versionDetails.bPM,
    //     technMaxQuantityKm: versionDetails.technMaxQuantityKm,
    //     driverId: "",
    //     renew: false,
    //     hidden: false,
    //     commercialOptions: [],
    //     group_Price_Map:versionDetails.group_Price_Map,
    //     modelDescription: versionDetails.modelDescription,
    //     iuc:versionDetails.iuc,
    //     //quotationProps
    //     vehicleStatus: {
    //       label: "Criado",
    //       color: "info",
    //     },
    //     //listagem de propostas
    //     driverName: "",
    //   };
    //   //Carregamento de versões inativas de viaturas

    //   // var inactiveVehicleTypeStatus: string = VehicleTypeStatus[VehicleTypeStatus.Inactive]; // se não for preciso remover

    //   const currentMakeIndex = versionsSearchOptions.findIndex(
    //     (x) => x.makeId === vehicle?.makeId && x.modelId===vehicle?.modelId
    //   );

    //   const hasInactiveVersion = versionsSearchOptions[currentMakeIndex].versions.some(v => v.value === vehicle?.versionId);
    //   if(!hasInactiveVersion){
    //     if( versionsSearchOptions[currentMakeIndex].versions.filter(x => x.value === "").length === 1){
    //       versionsSearchOptions[currentMakeIndex].versions=[{
    //         label: `${selectedVehicle?.name}; ${vehicle?.engineCc}CC; ${vehicle?.powerHP}CV; ${vehicle?.quantity_of_Seats} lugares; ${vehicle?.numberofDoors} portas; ${vehicle?.priceexclVAT} €`,
    //         value: vehicle.versionId,
    //         isInactive: true
    //       }];
    //     }
    //     else {
    //       versionsSearchOptions[currentMakeIndex].versions.push({
    //         label: `${selectedVehicle?.name}; ${vehicle?.engineCc}CC; ${vehicle?.powerHP}CV; ${vehicle?.quantity_of_Seats} lugares; ${vehicle?.numberofDoors} portas;  ${vehicle?.priceexclVAT} €`,
    //         value: vehicle.versionId,
    //         isInactive: true
    //       });
    //     }
    //   }

    //   setVersionsSearchOptions([...versionsSearchOptions]);
    //   accessoryFilter.push({
    //     type: AdvancedFilterAccessoryOptions.All,
    //     value: `${vehicle?.no};${vehicle?.versionId};Vehicle`,
    //   });
    //   vehiclesService.getAccessories(accessoryFilter).then(async (ac) => {
    //     if (ac.succeeded === true) {
    //       ac.value.forEach((element: any) => {
    //       //ACESSORIOS BASE
    //       if (element.general) {
    //         vehicle?.accessories.push(element as IAccessory);
    //       }
    //       //ACESSORIOS EXTRA
    //       else {
    //         let extra = element as IExtraStandard;
    //         extra.checkedExtra = true;
    //         extra.visible = true;
    //         vehicle?.extraStandard.push(extra);
    //       }
    //       });
    //     }
    //     let option: Option = {
    //       label: `${selectedVehicle?.name}; ${vehicle?.engineCc}CC; ${vehicle?.powerHP}CV; ${vehicle?.quantity_of_Seats} lugares; ${vehicle?.numberofDoors} portas; ${vehicle?.priceexclVAT} €`,
    //       value: vehicle.versionId, //vehicleTmp.no,
    //     };

    //     vehicle = updateValuesVehicle(vehicle,"");
    //     //vehicle.modelId="";
    //     setChangedProposal(true);
    //     setVehicleDataTable((rows) => {
    //       rows.push(vehicle);
    //       setVehiclesListingTotal(rows.length)
    //       updateSearchOptions(option, "", rows);

    //       return rows;
    //     });
    //     //addVehicle();
    //   });
    //}
  }, [currentUser, initAccessoriesDetails, initExtraStandardDetails, preConfiguredVehicles, proposal.id, updateSearchOptions, versionsSearchOptions]);

  const handleVehiclesCampaignChange = useCallback(
    (variant: VehicleTypeModalVariant) => {
      const options: Option[] = [];

      let vehicles: IVehicle[] = [];
      let usedVehicleSearchOptionsTmp: UsedVehicleDetails[] = [
        {
          icons: [],
          checkboxProps: {},
          name: "",
          characteristics: "",
          licensePlate: { label: "", value: "" },
        },
      ];
      let campaignVehicleSearchOptionsTmp: CampaignVehicleDetails[] = [];
      let filters1: { type: AdvancedFilterVehiclesOptions; value: string }[] = [];

      if (variant === "used") {
        filters1.push({
          type: AdvancedFilterVehiclesOptions.Remarketing_Status,
          value: "New Contract",
        });
      } else if (variant === "newCampaign") {
        filters1.push({
          type: AdvancedFilterVehiclesOptions.Campaign_Type,
          value: "New Vehicle Campaign",
        });
        filters1.push({
          type: AdvancedFilterVehiclesOptions.Campaign_Type,
          value: "Campanha Veículos Novos",
        });
      } else if (variant === "usedCampaign") {
        filters1.push({
          type: AdvancedFilterVehiclesOptions.Campaign_Type,
          value: "Used Vehicle Campaing",
        });
        filters1.push({
          type: AdvancedFilterVehiclesOptions.Campaign_Type,
          value: "Campanha Veículos Usados",
        });
      }
      else if("preConfigured") {

      }
      else {
        openAlert({
          variant: "info",
          text: `A opção ${variant} não foi encontrada.`,
          title: "Atenção",
          autoHideDuration: 3000,
        });
        return;
      }

      if (variant === "used") {
        vehiclesService.getVehiclesBc(filters1).then((data) => {
          if (!data.succeeded) {
            openAlert({
              variant: "error",
              text: "Ocorreu um erro pesquisar viaturas! Por favor tente novamente. Se o problema persistir, contate o administrador.",
              title: "Atenção",
            });
          } else {
            if (data.value.length > 0) {
              data.value.forEach(async (element: any, index: number) => {
                let vehicleDTO = JSON.parse(JSON.stringify(element));
                let commercialConditionsOptions: Option[] = [];

                if (vehicleDTO["typeofRental"]) {
                  commercialConditionsOptions = handleTypeOfRentalOptions(
                    vehicleDTO["typeofRental"]
                  );
                } else {
                  if (currentUser?.typeofRental) {
                    commercialConditionsOptions = handleTypeOfRentalOptions(
                      currentUser.typeofRental// vehicleDTO["typeofRental"]
                    );
                  } else {
                    commercialConditionsOptions = commercialConditions;
                  }
                }

                let vehicle: IVehicle = {
                  id: 0,
                  proposalId: proposal.id,
                  lotId: "",
                  leaseQuoteNo:"",
                  makeId: vehicleDTO["make"],
                  modelId: vehicleDTO["model"],
                  versionId: vehicleDTO["type"],
                  licensePlate: vehicleDTO["license_Plate"],
                  priceexclVAT: vehicleDTO["type_Price_Excl_VAT"],
                  pvp: 0,
                  consumersPrice: vehicleDTO["consumers_Price"],
                  recycling_charge: vehicleDTO["recycling_charge"],
                  luxury_tax: vehicleDTO["luxury_tax"],
                  transport_x0026_immatriculation_cost:
                    vehicleDTO["transport_x0026_immatriculation_cost"],
                  status: vehicleDTO["status"],
                  use: vehicleDTO["use"],
                  numberofDoors: vehicleDTO["number_of_Doors"],
                  fuelId: vehicleDTO["fuel"],
                  fuelType: vehicleDTO["fuel_Type"],
                  euroTaxInternationalSegmt:
                    vehicleDTO["euroTaxInternationalSegmt"],
                  quantity_of_Seats: vehicleDTO["quantity_of_Seats"],
                  lengthmm: vehicleDTO["lengthmm"],
                  widthmm: vehicleDTO["widthmm"],
                  heightmm: vehicleDTO["heightmm"],
                  trunkVolumel: vehicleDTO["trunkVolumel"],
                  wheelBasemm: vehicleDTO["wheelBasemm"],
                  trainFrontWheelsmm: vehicleDTO["trainFrontWheelsmm"],
                  trainRearWheelsmm: vehicleDTO["trainRearWheelsmm"],
                  compressionratio: vehicleDTO["compressionratio"],
                  bore: vehicleDTO["bore"],
                  powerHP: vehicleDTO["powerHP"],
                  powerkW: vehicleDTO["powerkW"],
                  cO2DischargeDecF: vehicleDTO["cO2_Discharge_ounces_kg"],
                  engineCc: vehicleDTO["engine_Cc"],
                  torqueNm: vehicleDTO["torqueNm"],
                  fuelTankCapacityl: vehicleDTO["fuelTankCapacityl"],
                  maxspeedkmh: vehicleDTO["maxspeedkmh"],
                  acceleration0100kmh: vehicleDTO["acceleration0100kmh"],
                  mileage: vehicleDTO["mileage"],
                  no: vehicleDTO["no"],
                  recordDate: "0001-01-01T00:00:00", //FIXME FALTA MAPEAR
                  totalExtraWithoutTax: vehicleDTO["totalExtraWithoutTax"],
                  totalAccessoriesWithout: vehicleDTO["totalAccessoriesWithout"],
                  //SK-506
                  // proformaInvoicePriceWithoutTax: vehicleDTO["proformaPrice"],
                  proformaInvoicePriceWithoutTax: vehicleDTO["proformaInvoicePriceWithoutTax"],
                  totalExtraWithTax: vehicleDTO["totalExtraWithTax"],
                  totalAccessoriesWith: vehicleDTO["totalAccessoriesWith"],
                  // proformaInvoicePriceWithTax: vehicleDTO["proformaPrice"] * vehicleDTO["vat"],
                  proformaInvoicePriceWithTax: vehicleDTO["proformaInvoicePriceWithTax"],
                  discountWithTaxTotal: vehicleDTO["discountWithTaxTotal"],
                  rappelWithTaxTotal: vehicleDTO["rappelWithoutTax"]+vehicleDTO["rappelWithTaxPercentage"],
                  campaignWithTaxTotal: vehicleDTO["campaignWithoutTax"]+vehicleDTO["campaignWithTaxPercentage"],
                  businessValueWithoutTax: vehicleDTO["businessValueWithoutTax"],
                  businessValueWithTax: vehicleDTO["businessValueWithTax"],
                  discountWithTax: vehicleDTO["discountWithTax"],
                  discountWithoutTax: vehicleDTO["discountWithoutTax"],
                  discountWithTaxPercentage:
                    vehicleDTO["discountWithTaxPercentage"],
                  campaignWithTax: vehicleDTO["campaignWithTax"],
                  campaignWithoutTax: vehicleDTO["campaignWithoutTax"],
                  campaignWithTaxPercentage:
                    vehicleDTO["campaignWithTaxPercentage"],
                  rappelWithTax: vehicleDTO["rappelWithTax"],
                  rappelWithTaxPercentage: vehicleDTO["rappelWithTaxPercentage"],
                  rappelWithoutTax: vehicleDTO["rappelWithoutTax"],
                  extraRappel: vehicleDTO["extraRappel"],
                  modelYear: vehicleDTO["modelYear"]
                    ? vehicleDTO["modelYear"]
                    : 0,
                  oilChange: vehicleDTO["oilChange"],
                  bPM: vehicleDTO["bPM"],
                  technMaxQuantityKm: vehicleDTO["technMaxQuantityKm"],
                  accessories: vehicleDTO["accessories"]
                    ? vehicleDTO["accessories"]
                    : [],
                  extraStandard: vehicleDTO["extraStandard"]
                    ? vehicleDTO["extraStandard"]
                    : [],
                  simulations: vehicleDTO["simulations"]
                    ? vehicleDTO["simulations"]
                    : [],
                  fuelDescription: null, //FIXME FALTA MAPEAR
                  extraOptionsSearch: [],
                  extraOptionSelect: 0,
                  group_Price_Map: vehicleDTO["group_Price_Map"],//'CIT',//XXX
                  quantity: vehicleDTO["quantity"],
                  //se o dto não trouxer o campo
                  typeofRental: vehicleDTO["typeofRental"]
                    ? vehicleDTO["typeofRental"]
                    : //tenta validar a partir do user
                    currentUser?.typeofRental
                    ? currentUser?.typeofRental
                    : "",
                  //se o dto não trouxer o campo
                  commercialConditions: vehicleDTO["commercialConditions"]
                    ? vehicleDTO["commercialConditions"]
                    : //tenta validar a partir do user
                    currentUser?.commercialConditions
                    ? currentUser.commercialConditions
                    : "",
                  //se o dto não trouxer o campo
                  typeofIncome: vehicleDTO["typeofIncome"]
                    ? vehicleDTO["typeofIncome"]
                    : //tenta validar a partir do user
                    currentUser?.typeofIncome
                    ? currentUser.typeofIncome
                    : "",
                  vat: vehicleDTO["vat"],
                  luxury_tax_vat: vehicleDTO["luxury_tax_vat"],
                  versionDescription: vehicleDTO["type_description"]
                    ? vehicleDTO["type_description"]
                    : vehicleDTO["make"] +
                      " " +
                      vehicleDTO["model_Description"] +
                      " - " +
                      vehicleDTO["description"],
                  caracteristics: `( ${vehicleDTO["engine_Cc"]}CC | ${vehicleDTO["powerHP"]}CV | ${vehicleDTO["quantity_of_Seats"]} lugares | ${vehicleDTO["number_of_Doors"]} portas)`, //TODO remover valores estáticos

                  driverId: "", //FIXME FALTA MAPEAR
                  renew: false,
                  hidden: false,
                  commercialOptions: commercialConditionsOptions,
                  modelDescription: vehicleDTO["model_Description"],
                  iuc:vehicleDTO["iUC"],
                  number_of_wheels:vehicleDTO["number_of_wheels"],
                  //QUOTATIONPROPS
                  vehicleStatus: {
                    label: "Criado",
                    color: "info",
                  },
                  //listagem de propostas
                  driverName: "", //FIXME FALTA MAPEAR
                };
                if(vehicle.versionId !== ""){
                  initExtraStandardDetails(vehicle); //vehicles.length);
                }
                // se filtro for do tipo veiculos usados
                if (
                  filters1[0].type ===
                  AdvancedFilterVehiclesOptions.Remarketing_Status
                ) {
                  const fuelIcon = VehicleUtilities.getFuelTypeIconFor(
                    vehicleDTO.fuel_Type
                  );
                  let usedVehicle = {
                    icons: fuelIcon,
                    checkboxProps: {},
                    name:
                      vehicle.makeId +
                      " " +
                      vehicleDTO["model_Description"] +
                      " - " +
                      vehicleDTO["description"],
                    characteristics: `( ${vehicleDTO.gearBoxDescription} | ${vehicle.engineCc}CC | ${vehicle.powerHP}CV | ${vehicle.quantity_of_Seats} lugares | ${vehicle.numberofDoors} portas)`,
                      // characteristics: `( ${vehicle.engineCc}CC | ${vehicle.powerHP}CV | ${vehicle.quantity_of_Seats} lugares | ${vehicle.numberofDoors})`,
                    licensePlate: {
                      label: "Matricula",
                      value: vehicle.licensePlate,
                    },
                  };

                  let searchOption: Option = {
                    label: `${vehicle.licensePlate} ${vehicle.makeId} - ${vehicleDTO["description"]}`,
                    value: vehicleDTO["no"], // vehicle.versionId.toString(),
                  };
                  // if(vehicle.versionId !== "0")
                  if (vehicle.no !== "" || vehicle.no !== undefined) {
                    options.push(searchOption);
                    let insertVehicle: IVehicle = JSON.parse(JSON.stringify(vehicle));
                    vehicles.push(insertVehicle);
                    let insertusedVehicle = JSON.parse(JSON.stringify(usedVehicle));
                    usedVehicleSearchOptionsTmp.push(insertusedVehicle);
                  }
                }
                //senao
                else {
                  let campaignVehicle = {
                    icons: VehicleUtilities.getFuelTypeIconFor(
                      vehicleDTO.fuel_Type
                    ),
                    checkboxProps: {
                      onChange: handleCheckboxPropsOnChange(
                        index,
                        selectedCampaignVehicles
                      ),
                    },
                    id: vehicle.no,
                    name: vehicle.versionDescription,
                    characteristics: vehicle.caracteristics, //vehicle.versionDescription
                    businessPrice: {
                      label: `Preço de negócio`,
                      value: `${vehicle.businessValueWithoutTax} €`,
                    },
                    extras: {
                      label: `Extras`,
                      value: `${vehicle.totalExtraWithoutTax} €`,
                    },
                    proformaPrice: {
                      label: `Preço FT proforma`,
                      value: `${vehicle.proformaInvoicePriceWithoutTax} €`,
                    },
                    details: {
                      ...vehicleDetails,
                      onExtraCheckboxChange: () => alert("vehicle details 1"),
                    },
                  };
                  let searchOption: Option = {
                    label: `${vehicleDTO["make"]} ${vehicleDTO["model_Description"]} - ${vehicleDTO["description"]}`,
                    value: vehicleDTO["no"],
                  };
                  if (vehicle.no !== "" || vehicle.no !== undefined) {
                    options.push(searchOption);
                    let insertVehicle: IVehicle = JSON.parse(JSON.stringify(vehicle));
                    vehicles.push(insertVehicle);
                    let insertcampaignVehicle = JSON.parse(JSON.stringify(campaignVehicle));
                    campaignVehicleSearchOptionsTmp.push(insertcampaignVehicle);
                  }
                }
              });
            }
            handleCampaignVehicles(vehicles);
            setVehicleBC((rows) => (rows = vehicles));
            setUsedVehicleOptions((rows) => (rows = usedVehicleSearchOptionsTmp));
            setCampaignVehicleOptions(
              (rows) => (rows = campaignVehicleSearchOptionsTmp)
            );
            setUsedVehicleSearchOptions((rows) => (rows = options));
          }
        });
      }
      else if("preConfigured") {
        vehiclesService.getPreConfiguredVehicles(null)
        .then((response) => {
          if (!response.succeeded) {
            openAlert({
              variant: "error",
              text: "Não foi possivel obter veículos pré-configurados! Por favor tente novamente. Se o problema persistir, contate o administrador.",
              title: "Atenção",
            });
          }
          else {
            let preConfVehicles:IPreConfiguredVehicle[] = response.value;
            setPreConfiguredVehicles(preConfVehicles.map((v, index) =>{
              return {
                hidden: false,
                id: v.id.toString(),
                versionId: v.versionId,
                icons: VehicleUtilities.getFuelTypeIconFor(v.fuelType),
                checkboxProps: {
                  onChange: handlePreConfCheckboxPropsOnChange(index),
                  checked: false,
                },
                name: v.versionDescription,
                characteristics: v.caracteristics,
                licensePlate: { label: `Matricula`, value: v.licensePlate },
                vehicleState: { label: `Estado Veículo`, value: VehicleUtilities.getVehicleTypeStringFor(v.state) },
              }
            }));
            const uniqueVehiclesByVersionId = (vehicles:IPreConfiguredVehicle[]) => {
              const seen = new Set();
              return vehicles.filter((v) => {
                const duplicate = seen.has(v.versionId);
                seen.add(v.versionId);
                return !duplicate;
              });
            };

            setPreConfiguredVehiclesSearchOptions(
              uniqueVehiclesByVersionId(preConfVehicles).map((v) => {
                return {
                  label: `${v.versionDescription}`,
                  value: v.id.toString(),
                };
              })
            );
          }
        });
      }
      else {
        vehiclesService.getCampaignSummariesList(filters1).then((data) => {
          if (!data.succeeded) {
            openAlert({
              variant: "error",
              text: "Ocorreu um erro pesquisar viaturas! Por favor tente novamente. Se o problema persistir, contate o administrador.",
              title: "Atenção",
            });
          } else {
            //TODO quando houver tempo avaliar a conversão da estrutura do vehicle em baixo na estrutura do sumario dum veiculo
            if (data.value.campaignVehicleSummaries.length > 0) {
              data.value.campaignVehicleSummaries.forEach((element: any, index: number) => {
                let vehicleDTO = JSON.parse(JSON.stringify(element));

                let vehicle: IVehicle = {
                  lotId: "",
                  leaseQuoteNo:"",
                  makeId: vehicleDTO["makeId"],
                  licensePlate: vehicleDTO["licensePlate"],
                  numberofDoors: vehicleDTO["numberofDoors"],
                  fuelId: vehicleDTO["fuelId"],
                  fuelType: vehicleDTO["fuelType"],
                  quantity_of_Seats: vehicleDTO["quantity_of_Seats"],
                  powerHP: vehicleDTO["powerHP"],

                  //SK-506
                  // proformaInvoicePriceWithTax:vehicleDTO["proformaInvoicePriceWithTax"],
                  proformaInvoicePriceWithTax: Number(vehicleDTO["proformaPrice"]) * Number(vehicleDTO["vat"]),
                  totalExtraWithoutTax: vehicleDTO["totalExtraWithoutTax"],
                  engineCc: vehicleDTO["engineCC"],
                  no: vehicleDTO["no"],
                  businessValueWithoutTax: vehicleDTO["businessValueWithoutTax"],
                  // proformaInvoicePriceWithoutTax:vehicleDTO["proformaInvoicePriceWithoutTax"],
                  proformaInvoicePriceWithoutTax:vehicleDTO["proformaPrice"],
                  modelDescription: vehicleDTO["modelDescription"],
                  versionDescription: vehicleDTO["typeDescription"]
                    ? vehicleDTO["typeDescription"]
                    : vehicleDTO["makeId"] +
                      " " +
                      vehicleDTO["modelDescription"] +
                      " - " +
                      vehicleDTO["description"],
                  caracteristics: `( ${vehicleDTO["gearBoxDescription"]} | ${vehicleDTO["engineCC"]}CC | ${vehicleDTO["powerHP"]}CV | ${vehicleDTO["quantity_of_Seats"]} lugares | ${vehicleDTO["numberofDoors"]} portas)`, //TODO remover valores estáticos

                  id: 0,
                  proposalId: proposal.id,
                  versionId: vehicleDTO["type"],
                  modelId: vehicleDTO["model"],
                  priceexclVAT: vehicleDTO["type_Price_Excl_VAT"],
                  pvp: 0,
                  consumersPrice: vehicleDTO["consumers_Price"],
                  recycling_charge: vehicleDTO["recycling_charge"],
                  luxury_tax: vehicleDTO["luxury_tax"],
                  transport_x0026_immatriculation_cost:
                    vehicleDTO["transport_x0026_immatriculation_cost"],
                  status: vehicleDTO["status"],
                  use: vehicleDTO["use"],
                  euroTaxInternationalSegmt:vehicleDTO["euroTaxInternationalSegmt"],
                  lengthmm: vehicleDTO["lengthmm"],
                  widthmm: vehicleDTO["widthmm"],
                  heightmm: vehicleDTO["heightmm"],
                  trunkVolumel: vehicleDTO["trunkVolumel"],
                  wheelBasemm: vehicleDTO["wheelBasemm"],
                  trainFrontWheelsmm: vehicleDTO["trainFrontWheelsmm"],
                  trainRearWheelsmm: vehicleDTO["trainRearWheelsmm"],
                  compressionratio: vehicleDTO["compressionratio"],
                  bore: vehicleDTO["bore"],
                  powerkW: vehicleDTO["powerkW"],
                  cO2DischargeDecF: vehicleDTO["cO2_Discharge_ounces_kg"],
                  torqueNm: vehicleDTO["torqueNm"],
                  fuelTankCapacityl: vehicleDTO["fuelTankCapacityl"],
                  maxspeedkmh: vehicleDTO["maxspeedkmh"],
                  acceleration0100kmh: vehicleDTO["acceleration0100kmh"],
                  mileage: vehicleDTO["mileage"],
                  recordDate: "0001-01-01T00:00:00", //FIXME FALTA MAPEAR
                  totalAccessoriesWithout: vehicleDTO["totalAccessoriesWithout"],
                  totalExtraWithTax: vehicleDTO["totalExtraWithTax"],
                  totalAccessoriesWith: vehicleDTO["totalAccessoriesWith"],
                  discountWithTaxTotal: vehicleDTO["discountWithTaxTotal"],
                  rappelWithTaxTotal: vehicleDTO["rappelWithTaxTotal"],
                  campaignWithTaxTotal: vehicleDTO["campaignWithTaxTotal"],
                  businessValueWithTax: vehicleDTO["businessValueWithTax"],
                  discountWithTax: vehicleDTO["discountWithTax"],
                  discountWithoutTax: vehicleDTO["discountWithoutTax"],
                  discountWithTaxPercentage:
                    vehicleDTO["discountWithTaxPercentage"],
                  campaignWithTax: vehicleDTO["campaignWithTax"],
                  campaignWithoutTax: vehicleDTO["campaignWithoutTax"],
                  campaignWithTaxPercentage:
                    vehicleDTO["campaignWithTaxPercentage"],
                  rappelWithTax: vehicleDTO["rappelWithTax"],
                  rappelWithTaxPercentage: vehicleDTO["rappelWithTaxPercentage"],
                  rappelWithoutTax: vehicleDTO["rappelWithoutTax"],
                  extraRappel: vehicleDTO["extraRappel"],
                  modelYear: vehicleDTO["modelYear"]
                    ? vehicleDTO["modelYear"]
                    : 0,
                  oilChange: vehicleDTO["oilChange"],
                  bPM: vehicleDTO["bPM"],
                  technMaxQuantityKm: vehicleDTO["technMaxQuantityKm"],
                  accessories: vehicleDTO["accessories"]
                    ? vehicleDTO["accessories"]
                    : [],
                  extraStandard: vehicleDTO["extraStandard"]
                    ? vehicleDTO["extraStandard"]
                    : [],
                  simulations: vehicleDTO["simulations"]
                    ? vehicleDTO["simulations"]
                    : [],
                  fuelDescription: null, //FIXME FALTA MAPEAR
                  extraOptionsSearch: [],
                  extraOptionSelect: 0,
                  iuc:vehicleDTO["iUC"],
                  number_of_wheels:vehicleDTO["number_of_wheels"],
                  quantity: vehicleDTO["quantity"],
                  //se o dto não trouxer o campo
                  typeofRental: vehicleDTO["typeofRental"]
                    ? vehicleDTO["typeofRental"]
                    : //tenta validar a partir do user
                    currentUser?.typeofRental
                    ? currentUser?.typeofRental
                    : "",
                  //se o dto não trouxer o campo
                  commercialConditions: vehicleDTO["commercialConditions"]
                    ? vehicleDTO["commercialConditions"]
                    : //tenta validar a partir do user
                    currentUser?.commercialConditions
                    ? currentUser.commercialConditions
                    : "",
                  //se o dto não trouxer o campo
                  typeofIncome: vehicleDTO["typeofIncome"]
                    ? vehicleDTO["typeofIncome"]
                    : //tenta validar a partir do user
                    currentUser?.typeofIncome
                    ? currentUser.typeofIncome
                    : "",
                  vat: vehicleDTO["vat"],
                  luxury_tax_vat: vehicleDTO["luxury_tax_vat"],
                  group_Price_Map:vehicleDTO["group_Price_Map"],

                  driverId: "", //FIXME FALTA MAPEAR
                  renew: false,
                  hidden: false,
                  commercialOptions: [{label:"",value:"",}],
                  //QUOTATIONPROPS
                  vehicleStatus: {
                    label: "Criado",
                    color: "info",
                  },
                  //listagem de propostas
                  driverName: "", //FIXME FALTA MAPEAR
                };
                // se filtro for do tipo veiculos usados
                if (filters1[0].type === AdvancedFilterVehiclesOptions.Remarketing_Status) {
                  const fuelIcon = VehicleUtilities.getFuelTypeIconFor(vehicleDTO.fuel_Type);
                  let usedVehicle = {
                    icons: fuelIcon,
                    checkboxProps: {},
                    name:
                      vehicle.makeId +
                      " " +
                      vehicleDTO["modelDescription"] +
                      " - " +
                      vehicleDTO["description"],
                    characteristics: `( ${vehicle.engineCC}CC | ${vehicle.powerHP}CV | ${vehicle.quantity_of_Seats} lugares | ${vehicle.numberofDoors} portas)`,
                    licensePlate: {
                      label: "Matricula",
                      value: vehicle.licensePlate,
                    },
                  };

                  let searchOption: Option = {
                    label: `${vehicle.licensePlate} ${vehicle.makeId} - ${vehicleDTO["description"]}`,
                    value: vehicleDTO["no"], // vehicle.versionId.toString(),
                  };
                  // if(vehicle.versionId !== "0")
                  if (vehicle.no !== "" || vehicle.no !== undefined) {
                    options.push(searchOption);
                    let insertVehicle: IVehicle = JSON.parse(JSON.stringify(vehicle));
                    vehicles.push(insertVehicle);
                    let insertusedVehicle = JSON.parse(JSON.stringify(usedVehicle));
                    usedVehicleSearchOptionsTmp.push(insertusedVehicle);
                  }
                }
                //senao
                else {
                  let campaignVehicle = {
                    icons: VehicleUtilities.getFuelTypeIconFor(vehicleDTO.fuelType),
                    checkboxProps: {
                      onChange: handleCheckboxPropsOnChange(index,selectedCampaignVehicles),
                    },
                    id: vehicle.no,
                    name: `${vehicle.versionDescription}`,
                    // name: vehicle.versionDescription,
                    characteristics: vehicle.caracteristics, //vehicle.versionDescription
                    businessPrice: {
                      label: `Preço de negócio`,
                      value: `${vehicle.businessValueWithoutTax} €`,
                    },
                    extras: {
                      label: `Extras`,
                      value: `${vehicle.totalExtraWithoutTax} €`,
                    },
                    proformaPrice: {
                      label: `Preço FT proforma`,
                      value: `${vehicle.proformaInvoicePriceWithoutTax} €`,
                    },
                    details: {
                      ...vehicleDetails,
                      onExtraCheckboxChange: () => alert("vehicle details 1"),
                    },
                  };
                  let searchOption: Option = {
                    // label: `${vehicleDTO["makeId"]} ${vehicleDTO["modelDescription"]} - ${vehicleDTO["description"]}`,
                    // label: `${vehicleDTO["typeDescription"]}`,
                    label: `${vehicle.versionDescription}`,
                    value: vehicleDTO["no"],
                  };
                  if (vehicle.no !== "" || vehicle.no !== undefined) {
                    options.push(searchOption);
                    let insertVehicle: IVehicle = JSON.parse(JSON.stringify(vehicle));
                    vehicles.push(insertVehicle);
                    let insertcampaignVehicle = JSON.parse(JSON.stringify(campaignVehicle));
                    campaignVehicleSearchOptionsTmp.push(insertcampaignVehicle);
                  }
                }
              });
            }
            setCampaignFilters(filters1);
            setTotalCampaignVehiclesAmount(data.value.totalVehiclesAmount);
            handleCampaignVehicles(vehicles);
            setVehicleBC((rows) => (rows = vehicles));
            setUsedVehicleOptions((rows) => (rows = usedVehicleSearchOptionsTmp));
            setCampaignVehicleOptions(
              (rows) => (rows = campaignVehicleSearchOptionsTmp)
            );
            setUsedVehicleSearchOptions((rows) => (rows = options));
          }
        })
      }
    },
    [
      selectedCampaignVehicles,
      commercialConditions,
      currentUser,
      handleCampaignVehicles,
      handleCheckboxPropsOnChange,
      handleTypeOfRentalOptions,
      proposal.id,
      initExtraStandardDetails,
      handlePreConfCheckboxPropsOnChange
    ]
  );

  // método antigo - para remover
  // const handleCampaignVehicleSearch = useCallback((e: any, version: Option) => {
  //   console.log(e.target)
  //     if (version) {
  //       let value = version.value;
  //       setSelectedOptionId((row) => (row = value));
  //     } else {
  //       let tmpSelectedCampaignVehicles = handleCampaignVehicles(vehicleBC);
  //       setSelectedCampaignVehicles(
  //         (rows) => (rows = tmpSelectedCampaignVehicles)
  //       );
  //       setUsedVehicleOptions((rows) => (rows = []));
  //     }
  // },[handleCampaignVehicles, vehicleBC]);

  const handleCampaignVehicleSearchOnInputChange = useCallback((e: any, value: string) => {
    if(value){
      setSelectedOptionId((row) => (row = value));
    }
    else {
      let tmpSelectedCampaignVehicles = handleCampaignVehicles(vehicleBC);
      setSelectedCampaignVehicles((rows) => (rows = tmpSelectedCampaignVehicles));
      setUsedVehicleOptions((rows) => (rows = []));
    }
  },[handleCampaignVehicles, vehicleBC]);

  const handleUsedVehiclesVehicleSearch = useCallback(
    (e: any, version: Option) => {
      if (version) {
        let value = version.value;
        setSelectedOptionId((row) => (row = value));
      } else {
        setSelectedVehicles((rows) => (rows = []));
        // setUsedVehicleOptions((rows) => (rows = []));
      }
    },
    []
  );

  const handlePreConfiguredVehiclesSearchChange = useCallback(
    (e: any, selectedOption: Option) => {
      if (selectedOption) {
        let value = selectedOption.value;
        setPreConfiguredVehiclesSearchValue(value);
      }
      else {
        setPreConfiguredVehiclesSearchValue('');
      }
    },
    []
  );

  const handleCampaignCloseModal = useCallback(() => {
    setVehicleBC((rows) => (rows = []));
    setUsedVehicleOptions((rows) => (rows = []));
    setUsedVehicleSearchOptions((rows) => (rows = []));
    setSelectedCampaignVehicles((rows) => (rows = []));
    setCampaignFilters((rows) => (rows = []));
  }, []);

  const handleUsedVehiclesCloseModal = useCallback(() => {
    setVehicleBC([]);
    setUsedVehicleOptions([]);
    setUsedVehicleSearchOptions([]);
    setSelectedVehicles([]);

    setSelectedCampaignVehicles([]);
    setCampaignVehicleOptions([]);
  }, []);

  const handleCampaignAddVehicle = useCallback((e: any) => {
    if (selectedOptionId) {
      // se encontrar descrição na lista pre carregada
      let filteredVehicle = vehicleBC.filter((x) => {
        return x.versionDescription === selectedOptionId;
      });
      let filteredVehicleOptions: CampaignVehicleDetails[] = [];
      let elementFound: CampaignVehicleDetails | undefined = {
        id: "",
        icons: [],
        checkboxProps: {},
        name: "",
        characteristics: "",
        businessPrice: {
          label: "",
          value: "",
        },
        extras: { label: "", value: "" },
        proformaPrice: { label: "", value: "" },
        details: {
          ...vehicleDetails,
          onExtraCheckboxChange: () => alert("vehicle details 1"),
        },
      };
      let usedVehicleOptionsTmp = campaignVehicleOptions;
      if(filteredVehicle.length > 0) {
         filteredVehicle.forEach((element) => {
          elementFound = usedVehicleOptionsTmp.find((x) => {
            return x.id === element.no;
          });
          if (elementFound) {
            filteredVehicleOptions.push(elementFound);
          }
        });
        if (filteredVehicleOptions.length > 0) {
          setSelectedCampaignVehicles(filteredVehicleOptions);
        }
      }
        // senão vai pesquisar por valor
      else {
        vehiclesService.searchCampaignSummariesList(campaignFilters,selectedOptionId).then(async (data) => {
          if(data.succeeded && data.value.campaignVehicleSummaries.length > 0) {
            let campaingVehicles: CampaignVehicleDetails[] = [];
            data.value.campaignVehicleSummaries.forEach((vehicle:any,index:number) => {
              let campaignVehicle = {
                icons: VehicleUtilities.getFuelTypeIconFor(vehicle.fuelType),
                checkboxProps: {
                  onChange: handleCheckboxPropsOnChange(index,selectedCampaignVehicles),
                },
                id: vehicle.no,
                name: vehicle["typeDescription"] ?
                  vehicle["typeDescription"] :
                  vehicle["makeId"] + " " + vehicle["modelDescription"] + " - " + vehicle["description"],
                characteristics: `( ${vehicle["gearBoxDescription"]} | ${vehicle["engineCC"]}CC | ${vehicle["powerHP"]}CV | ${vehicle["quantity_of_Seats"]} lugares | ${vehicle["numberofDoors"]} portas)`,
                businessPrice: {
                  label: `Preço de negócio`,
                  value: `${vehicle.businessValueWithoutTax} €`,
                },
                extras: {
                  label: `Extras`,
                  value: `${vehicle.totalExtraWithoutTax} €`,
                },
                proformaPrice: {
                  label: `Preço FT proforma`,
                  value: `${vehicle.proformaInvoicePriceWithoutTax} €`,
                },
                details: {
                  ...vehicleDetails,
                  onExtraCheckboxChange: () => alert("vehicle details 1"),
                },
              };
              campaingVehicles.push(campaignVehicle);
              selectedCampaignVehicles.push(campaignVehicle);
            });
            setSelectedCampaignVehicles((rows) => (rows = campaingVehicles));
          }
        });
      }
    } else {
      setSelectedCampaignVehicles((rows) => (rows = []));
      setUsedVehicleOptions((rows) => (rows = []));
    }
  },[selectedOptionId, vehicleBC, campaignVehicleOptions, campaignFilters, handleCheckboxPropsOnChange, selectedCampaignVehicles]);

  const handleUsedVehiclesAddVehicle = useCallback(
    (e: any) => {
      if (selectedOptionId) {
        let value = selectedOptionId;
        let vehicleBCTmp = vehicleBC;
        let filteredVehicle = vehicleBCTmp.filter((x) => {
          return x.no === value;
        });
        let filteredVehicleOptions: UsedVehicleDetails[] = [];
        let elementFound: UsedVehicleDetails | undefined = {
          icons: [],
          checkboxProps: {},
          name: "",
          characteristics: "",
          licensePlate: { label: "", value: "" },
        };
        let usedVehicleOptionsTmp = usedVehicleOption;
        filteredVehicle.forEach((element) => {
          elementFound = usedVehicleOptionsTmp.find((x) => {
            return x.licensePlate.value === element.licensePlate;
          });
          if (elementFound) {
            elementFound.checkboxProps.checked = false;
            filteredVehicleOptions.push(elementFound);
          }
        });
        if (filteredVehicleOptions.length > 0) {
          setSelectedVehicles(filteredVehicleOptions);
        }
      } else {
        setSelectedVehicles((rows) => (rows = []));
        setUsedVehicleOptions((rows) => (rows = []));
      }
    },
    [usedVehicleOption, selectedOptionId, vehicleBC]
  );

  const handlePreConfiguredVehiclesSearchClick = useCallback(
    (e: any) => {
      let versionId: string;
      if (preConfiguredVehiclesSearchValue) {
        const vehicle = preConfiguredVehicles.find(item => item.id === preConfiguredVehiclesSearchValue);
        if(vehicle) {
          versionId = vehicle.versionId;
        }
      }
      setPreConfiguredVehicles(items => {
        return items.map(item => {
          if(versionId === undefined || item.versionId === versionId) {
            item.hidden = false;
          }
          else {
            item.hidden = true;
          }
          return item;
        });
      });
    },
    [preConfiguredVehicles, preConfiguredVehiclesSearchValue]
  );

  const handleCampaignConfirmClick = useCallback(async () => {
    let selectedCampaignVehiclesTmp = selectedCampaignVehicles.filter(
      (x) => x.checkboxProps.checked === true
    );
    //possivelmente duplicado?
    let filters: { type: AdvancedFilterVehiclesOptions; value: string }[] = [];
    selectedCampaignVehiclesTmp.forEach((vehicle) => {
      filters.push({
        type: AdvancedFilterVehiclesOptions.No,
        value: vehicle.id,
      });
    })
    //fim possivelmente duplicado
    let vehiclesToAdd: IVehicle[] = [];

    if (selectedCampaignVehiclesTmp.length > 0) {
      let filters: { type: AdvancedFilterVehiclesOptions; value: string }[] = [];
      selectedCampaignVehiclesTmp.forEach((vehicle) => {
        filters.push({
          type: AdvancedFilterVehiclesOptions.No,
          value: vehicle.id,
        });
      })
      vehiclesService.getVehiclesBc(filters).then(async (data) => {
        if(!data.succeeded){
          console.log(data.value)
        } else {
          let vehicle: IVehicle;
          let vehicles: IVehicle[] = [];
          await Promise.all(
            data.value.map(async (element: any, index: number) => {
              let vehicleDTO  = JSON.parse(JSON.stringify(element));
              let commercialConditionsOptions: Option[] = [];

              if (vehicleDTO["typeofRental"] !== (null || undefined)) {
                commercialConditionsOptions = handleTypeOfRentalOptions(
                  vehicleDTO["typeofRental"]
                );
              } else {
                if (currentUser?.typeofRental) {
                  commercialConditionsOptions = handleTypeOfRentalOptions(
                    vehicleDTO["typeofRental"]
                  );
                } else {
                  commercialConditionsOptions = commercialConditions;
                }
              }

              let typeOfIncomme= paymentTerms[0].result.findIndex((x: { label: any; })=>x.label===vehicleDTO["typeofIncome"]).toString();
              vehicle = {
                id: 0,
                proposalId: proposal.id,
                lotId: "",
                leaseQuoteNo:"",
                makeId: vehicleDTO["make"],
                modelId: vehicleDTO["model"],
                versionId: vehicleDTO["type"],
                licensePlate: vehicleDTO["license_Plate"],
                priceexclVAT: vehicleDTO["type_Price_Excl_VAT"],
                pvp: 0,
                consumersPrice: vehicleDTO["consumers_Price"],
                recycling_charge: vehicleDTO["recycling_charge"],
                luxury_tax: vehicleDTO["luxury_tax"],
                transport_x0026_immatriculation_cost:
                  vehicleDTO["transport_x0026_immatriculation_cost"],
                status: vehicleDTO["status"],
                use: vehicleDTO["use"],
                numberofDoors: vehicleDTO["number_of_Doors"],
                fuelId: vehicleDTO["fuel"],
                fuelType: vehicleDTO["fuel_Type"],
                euroTaxInternationalSegmt:
                  vehicleDTO["euroTaxInternationalSegmt"],
                quantity_of_Seats: vehicleDTO["quantity_of_Seats"],
                lengthmm: vehicleDTO["lengthmm"],
                widthmm: vehicleDTO["widthmm"],
                heightmm: vehicleDTO["heightmm"],
                trunkVolumel: vehicleDTO["trunkVolumel"],
                wheelBasemm: vehicleDTO["wheelBasemm"],
                trainFrontWheelsmm: vehicleDTO["trainFrontWheelsmm"],
                trainRearWheelsmm: vehicleDTO["trainRearWheelsmm"],
                compressionratio: vehicleDTO["compressionratio"],
                bore: vehicleDTO["bore"],
                powerHP: vehicleDTO["powerHP"],
                powerkW: vehicleDTO["powerkW"],
                cO2DischargeDecF: vehicleDTO["cO2_Discharge_ounces_kg"],
                engineCc: vehicleDTO["engine_Cc"],
                torqueNm: vehicleDTO["torqueNm"],
                fuelTankCapacityl: vehicleDTO["fuelTankCapacityl"],
                maxspeedkmh: vehicleDTO["maxspeedkmh"],
                acceleration0100kmh: vehicleDTO["acceleration0100kmh"],
                mileage: vehicleDTO["mileage"],
                no: vehicleDTO["no"],
                recordDate: "0001-01-01T00:00:00", //FIXME FALTA MAPEAR
                totalExtraWithoutTax: vehicleDTO["totalExtraWithoutTax"],
                totalAccessoriesWithout: vehicleDTO["totalAccessoriesWithout"],
                proformaInvoicePriceWithoutTax:
                  vehicleDTO["proformaInvoicePriceWithoutTax"],
                totalExtraWithTax: vehicleDTO["total_Access_incl_VAT"],
                totalAccessoriesWith: vehicleDTO["totalAccessoriesWith"],
                proformaInvoicePriceWithTax:
                  vehicleDTO["proformaInvoicePriceWithTax"],
                discountWithTaxTotal: vehicleDTO["discountWithoutTax"],
                rappelWithTaxTotal: vehicleDTO["rappelWithoutTax"]+vehicleDTO["rappelWithTaxPercentage"],
                campaignWithTaxTotal: vehicleDTO["campaignWithoutTax"]+vehicleDTO["campaignWithTaxPercentage"],
                businessValueWithoutTax: vehicleDTO["businessValueWithoutTax"],
                businessValueWithTax: vehicleDTO["businessValueWithTax"],
                discountWithTax: vehicleDTO["discountWithTax"],
                discountWithoutTax: vehicleDTO["discountWithoutTax"],
                discountWithTaxPercentage:
                  vehicleDTO["discountWithTaxPercentage"],
                campaignWithTax: vehicleDTO["campaignWithTax"],
                campaignWithoutTax: vehicleDTO["campaignWithoutTax"],
                campaignWithTaxPercentage:
                  vehicleDTO["campaignWithTaxPercentage"],
                rappelWithTax: vehicleDTO["rappelWithTax"],
                rappelWithTaxPercentage: vehicleDTO["rappelWithTaxPercentage"],
                rappelWithoutTax: vehicleDTO["rappelWithoutTax"],
                extraRappel: vehicleDTO["extraRappel"],
                modelYear: vehicleDTO["modelYear"]
                  ? vehicleDTO["modelYear"]
                  : 0,
                oilChange: vehicleDTO["oilChange"],
                bPM: vehicleDTO["bpm"],
                technMaxQuantityKm: vehicleDTO["technMaxQuantityKm"],
                accessories: vehicleDTO["accessories"]
                  ? vehicleDTO["accessories"]
                  : [],
                extraStandard: vehicleDTO["extraStandard"]
                  ? vehicleDTO["extraStandard"]
                  : [],
                simulations: vehicleDTO["simulations"]
                  ? vehicleDTO["simulations"]
                  : [],
                fuelDescription: null, //FIXME FALTA MAPEAR
                extraOptionsSearch: [],
                extraOptionSelect: 0,
                group_Price_Map:vehicleDTO["group_Price_Map"],
                quantity: vehicleDTO["quantity"],
                //se o dto não trouxer o campo
                typeofRental: vehicleDTO["typeofRental"]
                  ? vehicleDTO["typeofRental"]
                  : //tenta validar a partir do user
                  currentUser?.typeofRental
                  ? currentUser?.typeofRental
                  : "",
                //se o dto não trouxer o campo
                commercialConditions: vehicleDTO["commercialConditions"]
                  ? vehicleDTO["commercialConditions"]
                  : //tenta validar a partir do user
                  currentUser?.commercialConditions
                  ? currentUser.commercialConditions
                  : "",
                //se o dto não trouxer o campo
                typeofIncome: vehicleDTO["typeofIncome"]
                  ? typeOfIncomme
                  : //tenta validar a partir do user
                  currentUser?.typeofIncome
                  ? currentUser.typeofIncome
                  : "",
                vat: vehicleDTO["vat"],
                luxury_tax_vat: vehicleDTO["luxury_tax_vat"],
                versionDescription: vehicleDTO["type_description"]
                  ? vehicleDTO["type_description"]
                  : vehicleDTO["make"] +
                    " " +
                    vehicleDTO["model_Description"] +
                    " - " +
                    vehicleDTO["description"],
                caracteristics: `${vehicleDTO["caracteristics"]}`, //TODO remover valores estáticos

                driverId: "", //FIXME FALTA MAPEAR
                renew: false,
                hidden: false,
                commercialOptions: commercialConditionsOptions,
                modelDescription: vehicleDTO["model_Description"],
                iuc:vehicleDTO["iUC"],
                number_of_wheels:vehicleDTO["number_of_wheels"],
                //QUOTATIONPROPS
                vehicleStatus: {
                  label: "Criado",
                  color: "info",
                },
                //listagem de propostas
                driverName: "", //FIXME FALTA MAPEAR
              };


              if (vehicle.no !== "" || vehicle.no !== undefined) {
                // options.push(searchOption);
                let vehicleInsert=await initCampaignExtraStandardDetails(vehicle)

                vehicles.push(vehicleInsert);
                // campaignVehicleSearchOptionsTmp.push(campaignVehicle);
              }
            })
          ).finally(() => {
            setChangedProposal(true);
            setVehicleBC((rows) => (rows = vehicles));
            vehicles.map((vehicle) => {
              vehicle.hidden = false;

              // initVehicleReplacementOptionsDropDown(
              //   vehicle.simulations[vehicle.simulations.length - 1],
              //   vehicle.commercialConditions,true
              // ); //TODO:Rever mapeamento se pack seguro
              // initSelectedInsuranceDropDown(
              //   vehicle.simulations[vehicle.simulations.length - 1],
              //   vehicle.use,true
              // );


              updateValuesVehicle(vehicle,"");
              vehiclesToAdd.push(vehicle as IVehicle);
              return vehicle;
            });

            let option: Option | undefined = {
              label: "",
              value: "",
            };
            // adciona label na combo box

            setVehicleDataTable((rows) => {
              vehiclesToAdd.forEach(async (vehicleTmp) => {
                const filters = VehicleTypeStatusHelper.generateAdvancedFilterVersionsOptions(true);
                filters.push({
                  type: AdvancedFilterVersionsOptions.Make,
                  value: vehicleTmp.makeId,
                });
                let data= await vehiclesService.getVersionsToCombo(filters);
                if (data.value.length > 0) {
                  if (data.value !== undefined && data.value[0] !== undefined) {
                    const makeVersions = data.value.map(
                      (item: {
                        label: string;
                        value: string;
                        isInactive: boolean;
                      }) => {
                        return {
                          label: item.label,
                          value: item.value,
                          isInactive: false
                        };
                      }
                    );

                    setVersionsSearchOptions((rows) => {
                      if (makeVersions && makeVersions.length > 0) {
                        const currentMakeIndex = rows.findIndex((x) => x.makeId === vehicleTmp.makeId);
                        if (currentMakeIndex >= 0) {
                          if(rows[currentMakeIndex].versions.length <= 1){
                            rows[currentMakeIndex].versions = makeVersions; //.filter((x: { status: string; }) => x.status !== "Inactive");
                          }
                          var inactiveVehicleTypeStatus: string = VehicleTypeStatus[VehicleTypeStatus.Inactive];
                          if(vehicleTmp !== undefined){
                            const currentMakeIndex1 = rows.findIndex(
                              (x) => x.makeId === vehicleTmp?.makeId && x.modelId===vehicleTmp?.modelId
                            );

                            const hasInactiveVersion = rows[currentMakeIndex1].versions.some(v => v.value === vehicleTmp?.versionId);
                            if(!hasInactiveVersion) {
                              if( rows[currentMakeIndex1].versions.filter(x => x.value === "").length===1) {
                                rows[currentMakeIndex1].versions = [{
                                  label:`${vehicleTmp?.modelDescription}; ${vehicleTmp?.caracteristics}`,
                                  value: vehicleTmp.versionId,
                                  isInactive: true
                                }];
                              } else {
                                rows[currentMakeIndex1].versions.push({
                                  label: `${vehicleTmp?.modelDescription}; ${vehicleTmp?.caracteristics}`,
                                  value: vehicleTmp.versionId,
                                  isInactive: true
                                });
                              }
                            }
                          }
                        }
                      }

                      return rows
                    });

                    rows.push(vehicleTmp);

                    //BEGIN validação opções de pesquisa
                    option = {
                      label: `${vehicleTmp.makeId} ${vehicleTmp.modelDescription}; ${vehicleTmp.caracteristics}`,
                      value: vehicleTmp.versionId, //vehicleTmp.no
                    };
                    updateSearchOptions(option, "", rows);

                    setVehiclesListingTotal(rows.length);
                  }
                }
              });
              //END validação opções de pesquisa

              rows.map((row,index) => {
                return row.extraStandard.map((extra, indexExtr) => {
                  extra.valueTextFieldProps.name = "price-price_Excl_VAT-" + index + "-" + indexExtr;
                  return extra;
                })
              })
              return rows;
            });
          });
        }
      });
    }
  }, [
    setVersionsSearchOptions,
    updateSearchOptions,
    handleTypeOfRentalOptions,
    selectedCampaignVehicles,
    commercialConditions,
    currentUser?.commercialConditions, currentUser.typeofIncome, currentUser?.typeofRental,
    proposal.id,

    //dependencias a mais
    // initExtraStandardDetails, getVersion,
    //dependencias em falta
    initCampaignExtraStandardDetails, paymentTerms,
  ]);

  const handleUsedVehiclesConfirmClick = useCallback(async () => {

    let accessoryFilter: {
      type: AdvancedFilterAccessoryOptions;
      value: string;
    }[] = [];
    // tentativa adcionar label na combo box
    // let tmpUsedVehicleOptions: UsedVehicleDetails[] = [
    //   {
    //     icons: [],
    //     checkboxProps: {},
    //     name: "",
    //     characteristics: "",
    //     licensePlate: { label: "", value: "" },
    //   },
    // ];
    let selectedVehicle = selectedVehicles.find((x) => {
      return x.checkboxProps.checked === true;
    });
    let versionDetails =  vehicleBC.find((x) => {
      return x.licensePlate === selectedVehicle?.licensePlate.value;
    });
    if (versionDetails?.quantity === 0) {
      versionDetails.quantity = 1;
      versionDetails.modelYear = "0";
    }
    if (versionDetails) {
      //let vehicle= versionDetails;
      let vehicle: IVehicle = {
        id: 0,

        proposalId: proposal.id,
        makeId: versionDetails.makeId,
        leaseQuoteNo:"",
        versionId: versionDetails.versionId,
        modelId: versionDetails.modelId,
        priceexclVAT: versionDetails.priceexclVAT,
        consumersPrice: versionDetails.consumersPrice,
        recycling_charge: versionDetails.recycling_charge,
        luxury_tax: versionDetails.luxury_tax,
        transport_x0026_immatriculation_cost:
          versionDetails.transport_x0026_immatriculation_cost,
        status: versionDetails.status,
        use: versionDetails.use,
        pvp: 0,
        numberofDoors: versionDetails.numberofDoors,
        fuelId: versionDetails.fuelId,
        fuelType: versionDetails.fuelType,
        euroTaxInternationalSegmt: versionDetails.euroTaxInternationalSegmt,
        quantity_of_Seats: versionDetails.quantity_of_Seats,
        lengthmm: versionDetails.lengthmm,
        widthmm: versionDetails.widthmm,
        heightmm: versionDetails.hightmm,
        trunkVolumel: versionDetails.trunkVolumel,
        wheelBasemm: versionDetails.wheelBasemm,
        trainFrontWheelsmm: versionDetails.trainFrontWheelsmm,
        trainRearWheelsmm: versionDetails.trainRearWheelsmm,
        compressionratio: versionDetails.compressionratio,
        bore: versionDetails.bore,
        powerHP: versionDetails.powerHP,
        powerkW: versionDetails.powerkW,
        cO2DischargeDecF: versionDetails.cO2DischargeDecF,
        engineCc: versionDetails.engineCc,
        torqueNm: versionDetails.torqueNm,
        fuelTankCapacityl: versionDetails.fuelTankCapacityl,
        maxspeedkmh: versionDetails.maxspeedkmh,
        acceleration0100kmh: versionDetails.acceleration0100kmh,
        number_of_wheels: versionDetails.number_of_wheels,
        licensePlate: versionDetails.licensePlate,
        mileage: versionDetails.mileage,
        modelYear: "",
        recordDate: "0001-01-01T00:00:00",
        lotId: versionDetails.lotId,
        totalExtraWithoutTax: 0,
        totalAccessoriesWithout: 0,
        proformaInvoicePriceWithoutTax: 0,
        totalExtraWithTax: 0,
        totalAccessoriesWith: 0,
        proformaInvoicePriceWithTax: 0,
        discountWithTaxTotal: 0,
        rappelWithTaxTotal: 0,
        campaignWithTaxTotal: 0,
        businessValueWithoutTax: 0,
        businessValueWithTax: 0,
        discountWithTax: 0,
        discountWithoutTax: 0,
        discountWithTaxPercentage: 0,
        campaignWithTax: 0,
        campaignWithoutTax: 0,
        campaignWithTaxPercentage: 0,
        rappelWithTax: 0,
        rappelWithTaxPercentage: 0,
        rappelWithoutTax: 0,
        extraRappel: 0,
        vat: versionDetails.vat,
        luxury_tax_vat:
          versionDetails.luxury_tax +
          versionDetails.luxury_tax * versionDetails.vat,
        no: versionDetails.no,
        accessories: [],
        extraStandard: [],
        extraOptionSelect: 0,
        extraOptionsSearch: [],
        simulations: [],
        versionDescription: versionDetails.versionDescription,
        caracteristics: "",
        quantity: 1,
        typeofRental: currentUser!.typeofRental,
        commercialConditions: currentUser!.commercialConditions,
        typeofIncome: currentUser?.typeofIncome ? currentUser.typeofIncome : "",
        oilChange: versionDetails.oilChange,
        bPM: versionDetails.bPM,
        technMaxQuantityKm: versionDetails.technMaxQuantityKm,
        driverId: "",
        renew: false,
        hidden: false,
        commercialOptions: [],
        group_Price_Map:versionDetails.group_Price_Map,
        modelDescription: versionDetails.modelDescription,
        iuc:versionDetails.iuc,
        //quotationProps
        vehicleStatus: {
          label: "Criado",
          color: "info",
        },
        //listagem de propostas
        driverName: "",
      };
      //Carregamento de versões inativas de viaturas

		  // var inactiveVehicleTypeStatus: string = VehicleTypeStatus[VehicleTypeStatus.Inactive]; // se não for preciso remover

      const currentMakeIndex = versionsSearchOptions.findIndex(
        (x) => x.makeId === vehicle?.makeId && x.modelId===vehicle?.modelId
      );

      const hasInactiveVersion = versionsSearchOptions[currentMakeIndex].versions.some(v => v.value === vehicle?.versionId);
      if(!hasInactiveVersion){
        if( versionsSearchOptions[currentMakeIndex].versions.filter(x => x.value === "").length === 1){
          versionsSearchOptions[currentMakeIndex].versions=[{
            label: `${selectedVehicle?.name}; ${vehicle?.engineCc}CC; ${vehicle?.powerHP}CV; ${vehicle?.quantity_of_Seats} lugares; ${vehicle?.numberofDoors} portas; ${vehicle?.priceexclVAT} €`,
            value: vehicle.versionId,
            isInactive: true
          }];
        }
        else {
          versionsSearchOptions[currentMakeIndex].versions.push({
            label: `${selectedVehicle?.name}; ${vehicle?.engineCc}CC; ${vehicle?.powerHP}CV; ${vehicle?.quantity_of_Seats} lugares; ${vehicle?.numberofDoors} portas;  ${vehicle?.priceexclVAT} €`,
            value: vehicle.versionId,
            isInactive: true
          });
        }
	    }

      setVersionsSearchOptions([...versionsSearchOptions]);
      accessoryFilter.push({
        type: AdvancedFilterAccessoryOptions.All,
        value: `${vehicle?.no};${vehicle?.versionId};Vehicle`,
      });
      vehiclesService.getAccessories(accessoryFilter).then(async (ac) => {
        if (ac.succeeded === true) {
          ac.value.forEach((element: any) => {
          //ACESSORIOS BASE
          if (element.general) {
            vehicle?.accessories.push(element as IAccessory);
          }
          //ACESSORIOS EXTRA
          else {
            let extra = element as IExtraStandard;
            extra.checkedExtra = true;
            extra.visible = true;
            vehicle?.extraStandard.push(extra);
          }
          });
        }
        let option: Option = {
          label: `${selectedVehicle?.name}; ${vehicle?.engineCc}CC; ${vehicle?.powerHP}CV; ${vehicle?.quantity_of_Seats} lugares; ${vehicle?.numberofDoors} portas; ${vehicle?.priceexclVAT} €`,
          value: vehicle.versionId, //vehicleTmp.no,
        };

        vehicle = updateValuesVehicle(vehicle,"");
       //vehicle.modelId="";
        setChangedProposal(true);
        setVehicleDataTable((rows) => {
          rows.push(vehicle);
          setVehiclesListingTotal(rows.length)
          updateSearchOptions(option, "", rows);

          return rows;
        });
        //addVehicle();
      });
	  }
  }, [
  	vehicleBC,
    selectedVehicles,
    proposal,
    updateSearchOptions,
    versionsSearchOptions,
    setVersionsSearchOptions,
    currentUser,
    //unnecessary dependencies
    // vehicleDataTable,

  ]);
  // const handleUsedVehiclesConfirmClick = useCallback(() => {
  //   let accessoryFilter: {
  //     type: AdvancedFilterAccessoryOptions;
  //     value: string;
  //   }[] = [];
  //   let vehicles: any[] = [];
  //   // tentativa adcionar label na combo box
  //   let tmpUsedVehicleOptions: UsedVehicleDetails[] = [
  //     {
  //       icons: [],
  //       checkboxProps: {},
  //       name: "",
  //       characteristics: "",
  //       licensePlate: { label: "", value: "" },
  //     },
  //   ];
  //   let selectedVehicle = selectedVehicles.find((x) => {
  //     return x.checkboxProps.checked === true;
  //   });
  //   let vehicle = vehicleBC.find((x) => {
  //     return x.licensePlate === selectedVehicle?.licensePlate.value;
  //   });
  //   if (vehicle?.quantity === 0) {
  //     vehicle.quantity = 1;
  //     vehicle.modelYear = "0";
  //   }
  //   if (vehicle) {
  //     accessoryFilter.push({
  //       type: AdvancedFilterAccessoryOptions.All,
  //       value: `${vehicle?.no};${vehicle?.versionId};Vehicle`,
  //     });
  //     vehiclesService.getAccessories(accessoryFilter).then(async (ac) => {
  //       if (ac.succeeded === true) {
  //         ac.value.forEach((element: any) => {
  //           //ACESSORIOS BASE
  //           if (element.general) {
  //             vehicle?.accessories.push(element as IAccessory);
  //           }
  //           //ACESSORIOS EXTRA
  //           else {
  //             let extra = element as IExtraStandard;
  //             extra.checkedExtra = true;
  //             extra.visible = true;
  //             vehicle?.extraStandard.push(extra);
  //           }
  //         });
  //       }
  //       // if (vehicle != null) {
  //       if (vehicle) {
  //         await updateValuesVehicle(vehicle);
  //       }
  //       vehicles.push(vehicle);
  //       // setVehicleDataTable([...vehicleDataTable, ...vehicles]);
  //       setVehicleDataTable((rows) => {
  //         vehicles.forEach((row) => rows.push(row));
  //         return rows;
  //       });
  //       setVehiclesListingTotal(vehicleDataTable.length);
  //       // tentativa adcionar label na combo box
  //       let tmpUsedVehicleOption = usedVehicleOption.find((x) => {
  //         return x.licensePlate.value === selectedVehicle?.licensePlate.value;
  //       });
  //       if (tmpUsedVehicleOption) {
  //         tmpUsedVehicleOptions.push(tmpUsedVehicleOption);
  //       }
  //       let versionOption: IVersion = {
  //         makeId: "",
  //         modelId:"",
  //         versions: [
  //           {
  //             label: "",
  //             value: "",
  //             isInactive: false
  //           },
  //         ],
  //       };
  //       let option: Option | undefined = {
  //         label: "",
  //         value: "",
  //       };

  //       tmpUsedVehicleOptions.forEach((x) => {
  //         let vehicleTmp: IVehicle = vehicles.find((v) => {
  //           return v["licensePlate"] === x.licensePlate.value;
  //         });
  //         //BEGIN validação da descrição do modelo e carregamento de versões
  //         if (vehicleTmp) {
  //           const searchIndex = versionsSearchOptions.findIndex(
  //             (a) => a.makeId === vehicleTmp.makeId
  //           );
  //           let versionsTmp = versionsSearchOptions;
  //           let versionFilter: {
  //             type: AdvancedFilterVersionsOptions;
  //             value: string;
  //           }[] = [];
  //           versionFilter.push({
  //             type: AdvancedFilterVersionsOptions.Make,
  //             value: vehicleTmp.makeId,
  //           });
  //           vehiclesService.getVersionsToCombo(versionFilter).then((data) => {
  //             if (data.value.length > 0) {
  //               if (data.value !== undefined && data.value[0] !== undefined) {
  //                 data.value.forEach(
  //                   (item: { label: string; value: string; isInactive: boolean }) => {
  //                     if (
  //                       versionsTmp[searchIndex].versions.find((ver) => {
  //                         return ver.value === item.value;
  //                       }) === (null || undefined)
  //                     ) {
  //                       versionsTmp[searchIndex].versions.push(item);
  //                     }
  //                   }
  //                 );
  //               }
  //             }
  //           });
  //           let mustUpdateVersions = false;

  //               const currentMakeIndex = versionsSearchOptions.findIndex(
  //               (x) => x.makeId === vehicleTmp.makeId && x.modelId===vehicleTmp.modelId
  //             );

  //             const hasInactiveVersion = versionsSearchOptions[currentMakeIndex].versions.some(v => v.value === vehicleTmp.versionId);
  //             if(!hasInactiveVersion){
  //               if( versionsSearchOptions[currentMakeIndex].versions.filter(x=>x.value=="").length===1){
  //                 versionsSearchOptions[currentMakeIndex].versions=[{
  //                   label: `${x.name}; ${vehicleTmp.engineCc}CC; ${vehicleTmp.powerHP}CV; ${vehicleTmp.quantity_of_Seats} lugares; ${vehicleTmp.numberofDoors} portas`,
  //                   value: vehicleTmp.versionId,
  //                   isInactive: vehicleTmp.status == VehicleTypeStatus.Inactive.toString()
  //                 }];
  //               }
  //               else{
  //                 versionsSearchOptions[currentMakeIndex].versions.push({
  //                   label: `${x.name}; ${vehicleTmp.engineCc}CC; ${vehicleTmp.powerHP}CV; ${vehicleTmp.quantity_of_Seats} lugares; ${vehicleTmp.numberofDoors} portas`,
  //                   value: vehicleTmp.versionId,
  //                   isInactive: vehicleTmp.status == VehicleTypeStatus.Inactive.toString()
  //                 });
  //               }
  //             }
  //             mustUpdateVersions = true;

  //        // }

  //         if(mustUpdateVersions){
  //           setVersionsSearchOptions([...versionsSearchOptions]);
  //         }

  //               // versionOption = { Comentado para já
  //               //   makeId: vehicleTmp.makeId,
  //               //   modelId: vehicleTmp.modelId,
  //               //   versions: [
  //               //     {
  //               //       label: `${x.name}; ${vehicleTmp.engineCc}CC; ${vehicleTmp.powerHP}CV; ${vehicleTmp.quantity_of_Seats} lugares; ${vehicleTmp.numberofDoors} portas`,
  //               //       value: vehicleTmp.versionId,
  //               //       isInactive: vehicleTmp.status == VehicleTypeStatus.Inactive.toString()
  //               //     },
  //               //   ],
  //               // };
  //               // //BEGIN validação opções de pesquisa
  //           option = {
  //             label: `${x.name}; ${vehicleTmp.engineCc}CC; ${vehicleTmp.powerHP}CV; ${vehicleTmp.quantity_of_Seats} lugares; ${vehicleTmp.numberofDoors} portas`,
  //             value: vehicleTmp.versionId, //vehicleTmp.no,
  //           };
  //           updateSearchOptions(option, "", vehicleDataTable);
  //           //END validação opções de pesquisa
  //               // versionOption.versions.forEach((item) => { omentado para já
  //               //   if (
  //               //     versionsTmp[searchIndex].versions.find((ver) => {
  //               //       return ver.value === item.value;
  //               //     }) === (null || undefined)
  //               //   ) {
  //               //     versionsTmp[searchIndex].versions.push(item);
  //               //   }
  //               // });
  //               // setVersionsSearchOptions(versionsTmp);
  //           //END validação da descrição do modelo e carregamento de versões
  //         }
  //       });
  //       setSelectedVehicles((rows) => (rows = []));
  //       setUsedVehicleSearchOptions((rows) => (rows = []));
  //       setUsedVehicleOptions((rows) => (rows = []));
  //       setVehicleBC((rows) => (rows = []));
  //     });
  //   } else {
  //     setSelectedVehicles((rows) => (rows = []));
  //     setUsedVehicleSearchOptions((rows) => (rows = []));
  //     setUsedVehicleOptions((rows) => (rows = []));
  //     setVehicleBC((rows) => (rows = []));
  //   }
  // }, [
  //   updateSearchOptions,
  //   selectedVehicles,
  //   usedVehicleOption,
  //   vehicleBC,
  //   vehicleDataTable,
  //   vehiclesService,
  //   versionsSearchOptions,
  //   // isDetailsStep2VehicleOpen
  // ]);


  // const handleAdvFilterTypeStatusClick = useCallback((index: number) => () => {
  //   setAdvFilterTypeStatusOptions((typeStatusOptions) => {
  //     return typeStatusOptions.map((row:any, rowIndex:number) => {
  //       if(index === rowIndex){
  //         row.checked = !row.checked
  //       }
  //       return row
  //     })
  //   });
  // },[]);

  // const handleAdvFilterFuelTypeClick = useCallback((index: number) => () => {
  //   setAdvFilterFuelTypeOptions((fuelTypeOptions) => {
  //     return fuelTypeOptions.map((row:any, rowIndex:number) => {
  //       if(index === rowIndex){
  //         row.checked = !row.checked
  //       }
  //       return row
  //     })
  //   })
  // },[]);

  // const handleAdvFilterSeatsClick = useCallback(
  //   (index: number) => () => {
  //     setAdvFilterSeats((seatsOptions) => {
  //       return seatsOptions.map((row, seatIndex:number) => {
  //         if(seatIndex === index){
  //           row.checked = !row.checked;
  //         }
  //         return row;
  //       });
  //     });
  //   },[]
  // );

  // const handleSegmentsSearch = useCallback(
  //   (e: any, option: any) => {
  //     for (let i = 0; i < advFilterSegmentsOptions.length; i++) {
  //       if (option === null) {
  //         advFilterSegmentsOptions[i].hidden = false;
  //       } else if (advFilterSegmentsOptions[i].label !== option.label) {
  //         advFilterSegmentsOptions[i].hidden = true;
  //       }
  //       else if(advFilterSegmentsOptions[i].label === option.label){
  //         advFilterSegmentsOptions[i].checkBoxProps.checked = true;
  //       }
  //     }
  //     setAdvFilterSegmentsOptions((options: any[]) =>
  //       options.map((item) => item)
  //     );
  //   },
  //   [advFilterSegmentsOptions]
  // );

  // const handleAdvFilterSegmentClick = useCallback(
  //   (segmentId: string) => () => {

  //     const index = advFilterSegmentsOptions.findIndex((x:{ value: string; }) => x.value === segmentId);
  //     if(index > -1){
  //       advFilterSegmentsOptions[index].checkBoxProps.checked =
  //         !advFilterSegmentsOptions[index].checkBoxProps.checked;
  //       setAdvFilterSegmentsOptions((items: any[]) => items.map((item) => item));
  //     }
  //   },
  //   [advFilterSegmentsOptions]
  // );

  // //Vehicles.bg
  // const handleAdvFilterSetSelectedChange = useCallback(
  //   (index: number) => () => {
  //     if (filterResults !== undefined && filterResults.length > 0) {
  //       filterResults[index].checkboxProps.checked =
  //         !filterResults[index].checkboxProps.checked;
  //       setFilterResults((fr) => fr?.map((result) => result));
  //     }
  //   },
  //   [filterResults]
  // );
  // //Vehicles.nd

  // const handleAdvFilterSetBatchChange = useCallback(
  //   (index: number) =>
  //     (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //       //const filterResults1 = filterResults;
  //       if (filterResults !== undefined && filterResults.length > 0) {
  //         filterResults[index].associateLotTextFieldProps.value =
  //           e.target.value;
  //         setFilterResults((fr) => fr?.map((result) => result));
  //       }
  //     },
  //   [filterResults]
  // );

  // let cancelToken = axios.CancelToken.source();

  // const advSearchDefault = useCallback((): any[] => {
  //     let displayableFilters: any = [];
  //     let dataFilters: {
  //       type: AdvancedFilterVersionsOptions;
  //       value: string;
  //     }[] = [];

  //     advFilterTypeStatusOptions.filter((x) => x.checked === true).forEach((x) => {
  //       displayableFilters.push({
  //         label: x.label,
  //         id:
  //           "type:" + AdvancedFilterVersionsOptions.VehicleType +
  //           ";" + x.value,
  //       });
  //       dataFilters.push({
  //         type: AdvancedFilterVersionsOptions.VehicleType,
  //         value: typeof x.value === 'string' ? x.value : "",
  //       });
  //     });

  //     advFilterSeats.filter((x) => x.checked === true).forEach((x) => {
  //       const values = x.label ? x.label.split(" "): "";
  //       displayableFilters.push({
  //         label: x.label,
  //         id: "type:" + AdvancedFilterVersionsOptions.Seats + ";" + values[0],
  //       });
  //       dataFilters.push({
  //         type: AdvancedFilterVersionsOptions.Seats,
  //         value: values[0],
  //       });
  //     });

  //     advFilterFuelTypeOptions.filter((x) => x.checked === true).forEach((x) => {
  //       displayableFilters.push({
  //         label: x.label,
  //         id: "type:" + AdvancedFilterVersionsOptions.FuelType + ";" + x.id,
  //       });
  //       dataFilters.push({
  //         type: AdvancedFilterVersionsOptions.FuelType,
  //         value: x.id ? x.id : "",
  //       });
  //     });

  //     advFilterSegmentsOptions
  //       .filter((x: { checkBoxProps:{checked: boolean} }) => x.checkBoxProps.checked === true)
  //       .forEach((x: { label: string; value: string }) => {
  //         displayableFilters.push({
  //           label: x.label,
  //           id: "type:" + AdvancedFilterVersionsOptions.Segment + ";" + x.value,
  //         });
  //         dataFilters.push({
  //           type: AdvancedFilterVersionsOptions.Segment,
  //           value: x.value,
  //         });
  //       });

  //     if (
  //       vehiclesMinLengthFilter >= vehicleMinLengthDefaultValue ||
  //       vehiclesMaxLengthFilter <= vehicleMaxLengthDefaultValue
  //     ) {
  //       displayableFilters.push({
  //         label: `Comprimento entre ${vehiclesMinLengthFilter} e ${vehiclesMaxLengthFilter} (mm)`,
  //         id: `type:${AdvancedFilterVersionsOptions.Length}; ${vehiclesMinLengthFilter}-${vehiclesMaxLengthFilter}`,
  //       });
  //     }
  //     dataFilters.push({
  //       type: AdvancedFilterVersionsOptions.Length,
  //       value: vehiclesMinLengthFilter + "-" + vehiclesMaxLengthFilter,
  //     });

  //     if (
  //       vehiclesMinWidthFilter >= vehicleMinWidthDefaultValue ||
  //       vehiclesMaxWidthFilter <= vehicleMaxWidthDefaultValue
  //     ) {
  //       displayableFilters.push({
  //         label: `Largura entre ${vehiclesMinWidthFilter} e ${vehiclesMaxWidthFilter} (mm)`,
  //         id: `type:${AdvancedFilterVersionsOptions.Width};${vehiclesMinWidthFilter}-${vehiclesMaxWidthFilter}`,
  //       });
  //     }
  //     dataFilters.push({
  //       type: AdvancedFilterVersionsOptions.Width,
  //       value: vehiclesMinWidthFilter + "-" + vehiclesMaxWidthFilter,
  //     });

  //     if (
  //       vehiclesMinHeightFilter >= vehicleMinHeightDefaultValue ||
  //       vehiclesMaxHeightFilter <= vehicleMaxHeightDefaultValue
  //     ) {
  //       displayableFilters.push({
  //         label: `Altura entre ${vehiclesMinHeightFilter} e ${vehiclesMaxHeightFilter} (mm)`,
  //         id: `type:${AdvancedFilterVersionsOptions.Height};${vehiclesMinHeightFilter}-${vehiclesMaxHeightFilter}`,
  //       });
  //     }
  //     dataFilters.push({
  //       type: AdvancedFilterVersionsOptions.Height,
  //       value: vehiclesMinHeightFilter + "-" + vehiclesMaxHeightFilter,
  //     });

  //     if (
  //       vehiclesMinGrossWeightFilter >= vehicleMinGrossWeightDefaultValue ||
  //       vehiclesMaxGrossWeightFilter <= vehicleMaxGrossWeightDefaultValue
  //     ) {
  //       displayableFilters.push({
  //         label: `Peso bruto entre ${vehiclesMinGrossWeightFilter} e ${vehiclesMaxGrossWeightFilter} (KG)`,
  //         id: `type: ${AdvancedFilterVersionsOptions.GrossWeight};{$vehiclesMinGrossWeightFilter}-${vehiclesMaxGrossWeightFilter}`,
  //       });
  //     }
  //     dataFilters.push({
  //       type: AdvancedFilterVersionsOptions.GrossWeight,
  //       value:
  //         vehiclesMinGrossWeightFilter + "-" + vehiclesMaxGrossWeightFilter,
  //     });

  //     if (
  //       vehiclesMinMaxSuitcaseVolumeFilter >= vehicleMinMaxSuitcaseVolumeDefaultValue ||
  //       vehiclesMaxMaxSuitcaseVolumeFilter <= vehicleMaxMaxSuitcaseVolumeDefaultValue
  //     ) {
  //       displayableFilters.push({
  //         label:
  //           "Volume máx. mala entre " +
  //           vehiclesMinMaxSuitcaseVolumeFilter +
  //           " e " +
  //           vehiclesMaxMaxSuitcaseVolumeFilter +
  //           " (L)",
  //         id:
  //           "type:" +
  //           AdvancedFilterVersionsOptions.MaxSuitcaseVolume +
  //           ";" +
  //           vehiclesMinMaxSuitcaseVolumeFilter +
  //           "-" +
  //           vehiclesMaxMaxSuitcaseVolumeFilter,
  //       });
  //     }
  //     dataFilters.push({
  //       type: AdvancedFilterVersionsOptions.MaxSuitcaseVolume,
  //       value:
  //         vehiclesMinMaxSuitcaseVolumeFilter +
  //         "-" +
  //         vehiclesMaxMaxSuitcaseVolumeFilter,
  //     });

  //     if (
  //       vehiclesMinDistanceBetweenFrontAxlesFilter >= vehicleMinDistanceBetweenFrontAxlesDefaultValue ||
  //       vehiclesMaxDistanceBetweenFrontAxlesFilter <= vehicleMaxDistanceBetweenFrontAxlesDefaultValue
  //     ) {
  //       displayableFilters.push({
  //         label:
  //           "Distancia eixos dianteiros entre " +
  //           vehiclesMinDistanceBetweenFrontAxlesFilter +
  //           " e " +
  //           vehiclesMaxDistanceBetweenFrontAxlesFilter +
  //           " (mm)",
  //         id:
  //           "type:" +
  //           AdvancedFilterVersionsOptions.DistanceBetweenFrontAxles +
  //           ";" +
  //           vehiclesMinDistanceBetweenFrontAxlesFilter +
  //           "-" +
  //           vehiclesMaxDistanceBetweenFrontAxlesFilter,
  //       });
  //     }
  //     dataFilters.push({
  //       type: AdvancedFilterVersionsOptions.DistanceBetweenFrontAxles,
  //       value:
  //         vehiclesMinDistanceBetweenFrontAxlesFilter +
  //         "-" +
  //         vehiclesMaxDistanceBetweenFrontAxlesFilter,
  //     });

  //     if (
  //       vehiclesMinDistanceBetweenRearAxlesFilter >= vehicleMinDistanceBetweenRearAxlesDefaultValue ||
  //       vehiclesMaxDistanceBetweenRearAxlesFilter <= vehicleMaxDistanceBetweenRearAxlesDefaultValue
  //     ) {
  //       displayableFilters.push({
  //         label:
  //           "Distancia eixos traseiros entre " +
  //           vehiclesMinDistanceBetweenRearAxlesFilter +
  //           " e " +
  //           vehiclesMaxDistanceBetweenRearAxlesFilter +
  //           " (mm)",
  //         id:
  //           "type:" +
  //           AdvancedFilterVersionsOptions.DistanceBetweenRearAxles +
  //           ";" +
  //           vehiclesMinDistanceBetweenRearAxlesFilter +
  //           "-" +
  //           vehiclesMaxDistanceBetweenRearAxlesFilter,
  //       });
  //     }
  //     dataFilters.push({
  //       type: AdvancedFilterVersionsOptions.DistanceBetweenRearAxles,
  //       value: `${vehiclesMinDistanceBetweenRearAxlesFilter}-${vehiclesMaxDistanceBetweenRearAxlesFilter}`,
  //     });

  //     if (
  //       vehiclesMinPowerFilter >= vehicleMinPowerDefaultValue ||
  //       vehiclesMaxPowerFilter <= vehicleMaxPowerDefaultValue
  //     ) {
  //       displayableFilters.push({
  //         label:
  //           "Potência do motor entre " +
  //           vehiclesMinPowerFilter +
  //           " e " +
  //           vehiclesMaxPowerFilter +
  //           " (CV)",
  //         id:
  //           "type:" +
  //           AdvancedFilterVersionsOptions.Power +
  //           ";" +
  //           vehiclesMinPowerFilter +
  //           "-" +
  //           vehiclesMaxPowerFilter,
  //       });
  //     }
  //     dataFilters.push({
  //       type: AdvancedFilterVersionsOptions.Power,
  //       value: vehiclesMinPowerFilter + "-" + vehiclesMaxPowerFilter,
  //     });

  //     if (
  //       vehiclesMinEngineCapacityFilter >= vehicleMinEngineCapacityDefaultValue ||
  //       vehiclesMaxEngineCapacityFilter <= vehicleMaxEngineCapacityDefaultValue
  //     ) {
  //       displayableFilters.push({
  //         label:
  //           "Cilindrada motor entre " +
  //           vehiclesMinEngineCapacityFilter +
  //           " e " +
  //           vehiclesMaxEngineCapacityFilter +
  //           " (CC)",
  //         id:
  //           "type:" +
  //           AdvancedFilterVersionsOptions.EngineCapacity +
  //           ";" +
  //           vehiclesMinEngineCapacityFilter +
  //           "-" +
  //           vehiclesMaxEngineCapacityFilter,
  //       });
  //     }
  //     dataFilters.push({
  //       type: AdvancedFilterVersionsOptions.EngineCapacity,
  //       value: vehiclesMinEngineCapacityFilter + "-" + vehiclesMaxEngineCapacityFilter,
  //     });

  //     if (
  //       vehiclesMinMaxTorqueFilter >= vehicleMinMaxTorqueDefaultValue ||
  //       vehiclesMaxMaxTorqueFilter <= vehicleMaxMaxTorqueDefaultValue
  //     ) {
  //       displayableFilters.push({
  //         label:
  //           "Binário máximo entre " +
  //           vehiclesMinMaxTorqueFilter +
  //           " e " +
  //           vehiclesMaxMaxTorqueFilter,
  //         id:
  //           "type:" +
  //           AdvancedFilterVersionsOptions.MaxTorque +
  //           ";" +
  //           vehiclesMinMaxTorqueFilter +
  //           "-" +
  //           vehiclesMaxMaxTorqueFilter,
  //       });
  //     }
  //     dataFilters.push({
  //       type: AdvancedFilterVersionsOptions.MaxTorque,
  //       value: vehiclesMinMaxTorqueFilter + "-" + vehiclesMaxMaxTorqueFilter,
  //     });

  //     if (
  //       vehiclesMinCO2EmissionFilter >= vehicleMinCO2EmissionDefaultValue ||
  //       vehiclesMaxCO2EmissionFilter <= vehicleMaxCO2EmissionDefaultValue
  //     ) {
  //       displayableFilters.push({
  //         label:
  //           "CO2 Emissão entre " +
  //           vehiclesMinCO2EmissionFilter +
  //           " e " +
  //           vehiclesMaxCO2EmissionFilter +
  //           " Grama/Kg",
  //         id:
  //           "type:" +
  //           AdvancedFilterVersionsOptions.CO2Emission +
  //           ";" +
  //           vehiclesMinCO2EmissionFilter +
  //           "-" +
  //           vehiclesMaxCO2EmissionFilter,
  //       });
  //     }
  //     dataFilters.push({
  //       type: AdvancedFilterVersionsOptions.CO2Emission,
  //       value:
  //         vehiclesMinCO2EmissionFilter + "-" + vehiclesMaxCO2EmissionFilter,
  //     });

  //     brandsSectionProps.forEach(
  //       (brand: {
  //         models: {
  //           checkBoxProps: { checked: boolean };
  //           label: string;
  //           id: string;
  //         }[];
  //         label: string;
  //       }) => {
  //         brand.models.forEach(
  //           (model: {
  //             id: string;
  //             label: string;
  //             checkBoxProps: { checked: boolean };
  //           }) => {
  //             if (model.checkBoxProps.checked) {
  //               displayableFilters.push({
  //                 label: brand.label + ": " + model.label,
  //                 id: `type:${AdvancedFilterVersionsOptions.Model};${model.id}`,
  //               });
  //               dataFilters.push({
  //                 type: AdvancedFilterVersionsOptions.Model,
  //                 value: model.id,
  //               });
  //             }
  //           }
  //         );
  //       }
  //     );


  //    return displayableFilters;


  //   },[
  //   advFilterFuelTypeOptions, advFilterSeats, advFilterSegmentsOptions, advFilterTypeStatusOptions,
  //   brandsSectionProps,
  //   vehicleMaxCO2EmissionDefaultValue, vehicleMaxDistanceBetweenFrontAxlesDefaultValue,
  //   vehicleMaxDistanceBetweenRearAxlesDefaultValue, vehicleMaxEngineCapacityDefaultValue,
  //   vehicleMaxGrossWeightDefaultValue, vehicleMaxHeightDefaultValue,
  //   vehicleMaxLengthDefaultValue, vehicleMaxMaxSuitcaseVolumeDefaultValue,
  //   vehicleMaxMaxTorqueDefaultValue, vehicleMaxWidthDefaultValue,
  //   vehicleMinCO2EmissionDefaultValue, vehicleMaxPowerDefaultValue,
  //   vehicleMinPowerDefaultValue, vehicleMinDistanceBetweenFrontAxlesDefaultValue,
  //   vehicleMinDistanceBetweenRearAxlesDefaultValue, vehicleMinEngineCapacityDefaultValue,
  //   vehicleMinGrossWeightDefaultValue, vehicleMinHeightDefaultValue,
  //   vehicleMinLengthDefaultValue, vehicleMinMaxSuitcaseVolumeDefaultValue,
  //   vehicleMinMaxTorqueDefaultValue, vehicleMinWidthDefaultValue,

  //   vehiclesMaxCO2EmissionFilter, vehiclesMaxDistanceBetweenFrontAxlesFilter,
  //   vehiclesMaxDistanceBetweenRearAxlesFilter, vehiclesMaxEngineCapacityFilter,
  //   vehiclesMaxGrossWeightFilter, vehiclesMaxHeightFilter,
  //   vehiclesMaxLengthFilter, vehiclesMaxMaxSuitcaseVolumeFilter,
  //   vehiclesMaxMaxTorqueFilter, vehiclesMaxWidthFilter,
  //   vehiclesMinCO2EmissionFilter, vehiclesMinDistanceBetweenFrontAxlesFilter,
  //   vehiclesMinDistanceBetweenRearAxlesFilter, vehiclesMinEngineCapacityFilter,
  //   vehiclesMinGrossWeightFilter, vehiclesMinHeightFilter,
  //   vehiclesMinLengthFilter, vehiclesMinMaxSuitcaseVolumeFilter,
  //   vehiclesMinMaxTorqueFilter, vehiclesMaxPowerFilter,
  //   vehiclesMinPowerFilter, vehiclesMinWidthFilter,
  // ]);

  // const handleBrandsClick = useCallback((item: {
  //   [x: string]: any;
  //   label: any;
  //   open: boolean;
  //   models: {
  //     [x: string]: any;
  //     id: string;
  //     label: string;
  //   }[];
  // }) => () => {
  //   for (let i = 0; i < brandsSectionProps.length; i++) {
  //     if (brandsSectionProps[i].label === item.label) {
  //       brandsSectionProps[i].open = !brandsSectionProps[i].open;
  //       break;
  //     }
  //   }
  //   setBrandsSectionProps([...brandsSectionProps]);
  // }, [brandsSectionProps]);

  // const handleBrandsCheckboxPropsClick = useCallback((item: {
  //   [x: string]: any;
  //   label: any;
  //   open: boolean;
  //   models: {
  //     [x: string]: any;
  //     id: string;
  //     label: string;
  //   }[];
  // }) => () => {
  //   for (let i = 0; i < brandsSectionProps.length; i++) {
  //     if (brandsSectionProps[i].label === item.label) {
  //       brandsSectionProps[i].checkBoxProps.checked =
  //         !brandsSectionProps[i].checkBoxProps.checked;
  //       brandsSectionProps[i].models.forEach(
  //         (m: { checkBoxProps: { checked: boolean } }) =>
  //           (m.checkBoxProps.checked = !m.checkBoxProps.checked)
  //       );
  //       break;
  //     }
  //   }
  //   setBrandsSectionProps([...brandsSectionProps]);
  // }, [brandsSectionProps]);

  // const handleBrandsModelCheckboxPropsClick = useCallback((item: {
  //     [x: string]: any;
  //     label: any;
  //     open: boolean;
  //     models: {
  //       [x: string]: any;
  //       id: string;
  //       label: string;
  //     }[];
  //   },
  //   modelIndex: number
  // ) => () => {
  //   item.models[modelIndex].checkBoxProps.checked =
  //     !item.models[modelIndex].checkBoxProps.checked;
  //   setBrandsSectionProps([...brandsSectionProps]);
  // }, [brandsSectionProps]);

  // const handleAllBrandsCheckboxProps = useCallback((e: any) => {
  //   const element = e.target as HTMLInputElement; //onSelectAllBrandsAndModelsClick(e.target as HTMLInputElement),
  //   for (var i = 0; i < brandsSectionProps.length; i++) {
  //     brandsSectionProps[i].checkBoxProps.checked = element.checked;
  //     for (var j = 0; j < brandsSectionProps[i].models.length; j++) {
  //       brandsSectionProps[i].models[j].checkBoxProps.checked =
  //         element.checked;
  //     }
  //   }
  //   setBrandsSectionProps([...brandsSectionProps]);
  // }, [brandsSectionProps]);

  // const handleBrandsSearchChange = useCallback((e: any, option: any) => {
  //   for (let i = 0; i < brandsSectionProps.length; i++) {
  //     if (option === null) {
  //       brandsSectionProps[i].hidden = false;
  //     } else if (brandsSectionProps[i].label !== option.label) {
  //       brandsSectionProps[i].hidden = true;
  //     }
  //   }
  //   setBrandsSectionProps([...brandsSectionProps]);
  // }, [brandsSectionProps]);

  // const handleVehiclesMinGrossWeightFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMinGrossWeightFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMinGrossWeightFilter(value);
  // }, [vehiclesMinGrossWeightFilter]);

  // const handleVehiclesMaxGrossWeightFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMaxGrossWeightFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMaxGrossWeightFilter(value);
  // }, [vehiclesMaxGrossWeightFilter]);

  // const handleVehiclesGrossWeightBarFilterChange = useCallback((_: any, value: any) => {
  //   if (Array.isArray(value)) {
  //     setVehiclesMinGrossWeightFilter(value[0]);
  //     setVehiclesMaxGrossWeightFilter(value[1]);
  //   }
  // }, []);

  // const handleVehiclesMinMaxSuitcaseVolumeFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMinMaxSuitcaseVolumeFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMinMaxSuitcaseVolumeFilter(value);
  // }, [vehiclesMinMaxSuitcaseVolumeFilter]);

  // const handleVehiclesMaxMaxSuitcaseVolumeFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMaxMaxSuitcaseVolumeFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMaxMaxSuitcaseVolumeFilter(value);
  // }, [vehiclesMaxMaxSuitcaseVolumeFilter]);

  // const handleVehiclesSuitcaseVolumeBarFilterChange = useCallback((_: any, value: any) => {
  //   if (Array.isArray(value)) {
  //     setVehiclesMinMaxSuitcaseVolumeFilter(value[0]);
  //     setVehiclesMaxMaxSuitcaseVolumeFilter(value[1]);
  //   }
  // }, []);

  // const handleVehiclesMinLengthFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMinLengthFilter;
  //   // const minLengthDefaultValue =
  //   //   vehicleMinLengthDefaultValue;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   //FIXME a alteração da caixa de texto não replica para o slider
  //   /*
  //   if(value < minLengthDefaultValue)
  //     value = minLengthDefaultValue;
  //   if(value > vehicleMaxLengthDefaultValue)
  //     value = vehicleMaxLengthDefaultValue;
  //   */
  //   //if(value >= minLengthDefaultValue && value <= vehicleMaxLengthDefaultValue)
  //   setVehiclesMinLengthFilter(value);
  // }, [vehiclesMinLengthFilter]);

  // const handleVehiclesMaxLengthFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMaxLengthFilter;
  //   // const maxLengthDefaultValue =
  //   //   vehicleMaxLengthDefaultValue;

  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   //FIXME a alteração da caixa de texto não replica para o slider
  //   /*
  //   if(value > maxLengthDefaultValue)
  //     value = maxLengthDefaultValue;
  //   if(value < vehicleMinLengthDefaultValue)
  //     value = vehicleMaxLengthDefaultValue;
  //   */
  //   setVehiclesMaxLengthFilter(value);
  // }, [vehiclesMaxLengthFilter]);

  // const handleVehiclesLengthBarFilterChange = useCallback((_: any, value: any) => {
  //   if (Array.isArray(value)) {
  //     setVehiclesMinLengthFilter(value[0]);
  //     setVehiclesMaxLengthFilter(value[1]);
  //   }
  // }, []);

  // const handleVehiclesMinWidthFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMinWidthFilter;
  //   const minWidthDefaultValue = vehicleMinWidthDefaultValue;

  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }

  //   if (value < minWidthDefaultValue) value = minWidthDefaultValue;
  //   if (value > vehicleMaxWidthDefaultValue)
  //     value = vehicleMaxWidthDefaultValue;

  //   setVehiclesMinWidthFilter(value);
  // }, [
  //   vehiclesMinWidthFilter,
  //   vehicleMaxWidthDefaultValue,
  //   vehicleMinWidthDefaultValue,
  // ]);

  // const handleVehiclesMaxWidthFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMaxWidthFilter;
  //   const maxWidthDefaultValue = vehicleMaxWidthDefaultValue;

  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   if (value > maxWidthDefaultValue) value = maxWidthDefaultValue;
  //   if (value < vehicleMinWidthDefaultValue)
  //     value = vehicleMaxWidthDefaultValue;

  //   setVehiclesMaxWidthFilter(value);
  // }, [
  //   vehiclesMaxWidthFilter,
  //   vehicleMaxWidthDefaultValue,
  //   vehicleMinWidthDefaultValue,
  // ]);

  // const handleVehiclesWidthBarFilterChange = useCallback((_: any, value: any) => {
  //   if (Array.isArray(value)) {
  //     setVehiclesMinWidthFilter(value[0]);
  //     setVehiclesMaxWidthFilter(value[1]);
  //   }
  // }, []);

  // const handleVehiclesMinHeightFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMinHeightFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMinHeightFilter(value);
  // }, [vehiclesMinHeightFilter]);

  // const handleVehiclesMaxHeightFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMaxHeightFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMaxHeightFilter(value);
  // }, [vehiclesMaxHeightFilter]);

  // const handleVehiclesHeightBarFilterChange = useCallback((_: any, value: any) => {
  //   if (Array.isArray(value)) {
  //     setVehiclesMinHeightFilter(value[0]);
  //     setVehiclesMaxHeightFilter(value[1]);
  //   }
  // }, []);

  // const handleVehiclesMinDistanceBetweenFrontAxlesFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMinDistanceBetweenFrontAxlesFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMinDistanceBetweenFrontAxlesFilter(value);
  // }, [vehiclesMinDistanceBetweenFrontAxlesFilter]);

  // const handleVehiclesMaxDistanceBetweenFrontAxlesFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMaxDistanceBetweenFrontAxlesFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMaxDistanceBetweenFrontAxlesFilter(value);
  // }, [vehiclesMaxDistanceBetweenFrontAxlesFilter]);

  // const handleVehiclesDistanceBetweenFrontAxlesBarFilterChange = useCallback((_: any, value: any) => {
  //   if (Array.isArray(value)) {
  //     setVehiclesMinDistanceBetweenFrontAxlesFilter(value[0]);
  //     setVehiclesMaxDistanceBetweenFrontAxlesFilter(value[1]);
  //   }
  // }, []);

  // const handleVehiclesMinDistanceBetweenRearAxlesFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMinDistanceBetweenRearAxlesFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMinDistanceBetweenRearAxlesFilter(value);
  // }, [vehiclesMinDistanceBetweenRearAxlesFilter]);

  // const handleVehiclesMaxDistanceBetweenRearAxlesFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMaxDistanceBetweenRearAxlesFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMaxDistanceBetweenRearAxlesFilter(value);
  // }, [vehiclesMaxDistanceBetweenRearAxlesFilter]);

  // const handleVehiclesDistanceVetweenAxlesBarFilterChange = useCallback((_: any, value: any) => {
  //   if (Array.isArray(value)) {
  //     setVehiclesMinDistanceBetweenRearAxlesFilter(value[0]);
  //     setVehiclesMaxDistanceBetweenRearAxlesFilter(value[1]);
  //   }
  // }, []);

  // const handleVehiclesMinPowerFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMinPowerFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMinPowerFilter(value);
  // }, [vehiclesMinPowerFilter]);

  // const handleVehiclesMaxPowerFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMaxPowerFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMaxPowerFilter(value);
  // }, [vehiclesMaxPowerFilter]);

  // const handleVehiclesPowerBarFilterChange = useCallback((_: any, value: any) => {
  //   if (Array.isArray(value)) {
  //     setVehiclesMinPowerFilter(value[0]);
  //     setVehiclesMaxPowerFilter(value[1]);
  //   }
  // }, []);

  // const handleVehiclesMinEngineCapacityFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMinEngineCapacityFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMinEngineCapacityFilter(value);
  // }, [vehiclesMinEngineCapacityFilter]);

  // const handleVehiclesMaxEngineCapacityFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMaxEngineCapacityFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMaxEngineCapacityFilter(value);
  // }, [vehiclesMaxEngineCapacityFilter]);

  // const handleVehiclesEngineCapacityBarFilterChange = useCallback((_: any, value: any) => {
  //   if (Array.isArray(value)) {
  //     setVehiclesMinEngineCapacityFilter(value[0]);
  //     setVehiclesMaxEngineCapacityFilter(value[1]);
  //   }
  // }, []);

  // const handleVehiclesMinMaxTorqueFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMinMaxTorqueFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMinMaxTorqueFilter(value);
  // }, [vehiclesMinMaxTorqueFilter]);

  // const handleVehiclesMaxMaxTorqueFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMaxMaxTorqueFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMaxMaxTorqueFilter(value);
  // }, [vehiclesMaxMaxTorqueFilter]);

  // const handleVehiclesTorqueBarFilterChange = useCallback((_: any, value: any) => {
  //   if (Array.isArray(value)) {
  //     setVehiclesMinMaxTorqueFilter(value[0]);
  //     setVehiclesMaxMaxTorqueFilter(value[1]);
  //   }
  // }, []);

  // const handleVehiclesMinCO2EmissionFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMinCO2EmissionFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMinCO2EmissionFilter(value);
  // }, [vehiclesMinCO2EmissionFilter]);

  // const handleVehiclesMaxCO2EmissionFilter = useCallback((e: any) => {
  //   let value: number = vehiclesMaxCO2EmissionFilter;
  //   try {
  //     value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setVehiclesMaxCO2EmissionFilter(value);
  // }, [vehiclesMaxCO2EmissionFilter]);

  // const handleVehiclesCO2EmissionbarFitlerChange = useCallback((_: any, value: any) => {
  //   if (Array.isArray(value)) {
  //     setVehiclesMinCO2EmissionFilter(value[0]);
  //     setVehiclesMaxCO2EmissionFilter(value[1]);
  //   }
  // }, []);

  // const advFilterSeatsOptions = useMemo(() =>
  //   advFilterSeats.map((item, index: number) => {
  //     return {
  //       label: item.label,
  //       checked: item.checked,
  //       onClick: handleAdvFilterSeatsClick(index),
  //     };
  //   }
  // ), [advFilterSeats, handleAdvFilterSeatsClick]);

  // const filterDrawerPropsBrands = useMemo(() => {
  //   let brands = brandsSectionProps.filter((x: { hidden: boolean }) => x.hidden === false).map((item: {
  //     [x: string]: any; label: any; open:boolean; models: {
  //       [x: string]: any; id: string; label: string;
  //     }[];
  //   }, index:number) => {
  //     return {
  //       label: item.label,
  //       hidden: false,
  //       onClick: handleBrandsClick(item),
  //       open: item.open,
  //       checkBoxProps: {
  //         checked: item.checkBoxProps.checked,
  //         onClick: handleBrandsCheckboxPropsClick(item),
  //       },
  //       models: item.models.map((model: { id: string; label: string },modelIndex:number) => {
  //         return {
  //           id: model.id,
  //           label: model.label,
  //           checkBoxProps: {
  //             checked: item.models[modelIndex].checkBoxProps.checked,
  //             onClick: handleBrandsModelCheckboxPropsClick(item,modelIndex),
  //           },
  //           onClick: handleBrandsModelCheckboxPropsClick(item,modelIndex)
  //         };
  //       }),
  //     }
  //   });
  //   return brands;
  // }, [
  //   brandsSectionProps,
  //   handleBrandsClick,
  //   handleBrandsCheckboxPropsClick,
  //   handleBrandsModelCheckboxPropsClick,
  // ]);

  const onProposalPrintFieldChange: TextFieldProps["onChange"] = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setProposal({
        ...proposal,
        [e.target.name]: e.target.value,
      });
    }, [proposal]
  );

  const onProposalPrintAttachClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        let itemsX: ProgressBarProps[] = [];
        handleAttachProposalFiles(e);
        proposalService
          .addAttachments(proposal.id, e.target.files as unknown as any[],optionsProposal)
          .then((response) => {
            if (response.succeeded) {
              setProposal({
                ...proposal,
                ["printProposalAttachments"]: response.value,
              });
              setAttachmentsProposal([
                ...attachmentsProposal,
                ...Object.values(response.value),
              ]);
              //instantiate progress bar variable
              let progressBarProps: ProgressBarProps = {
                progress: 0,
                title: "",
                label: "",
                deleteIcon: true,
                deleteTooltipText: "Apagar",
              };
              //update progressBar
              setAttachmentsProposal((rows) =>
                rows.map((row,index) => {
                  let bar=infoProgressBarProposal[index];
                  progressBarProps = {
                    progress: bar.progress,
                    title: bar.title,
                    label: bar.label,
                    deleteIcon: true,
                    deleteTooltipText: "Apagar",

                    onDeleteClick: () => {

                      //remove do disco

                      proposalService.removeAttachments(row["guid"]);
                      //remove do GUI
                      let testInfoIndex:number = -1;
                      itemsX.forEach(i => {
                        testInfoIndex = infoProgressBarProposal.findIndex(pb => pb === i )
                      })
                      infoProgressBarProposal.splice(testInfoIndex,1);
                      var filteredItems = itemsX.filter(
                        (item) => item !== progressBarProps
                      );
                      itemsX = filteredItems;

                      response.value.splice(index,1);
                      handleAttachmentProposal(index);
                      setNewProgressBarProposal(itemsX);
                    },
                  };
                  itemsX[index] = progressBarProps;
                  return row;
                })
              );

              //display progress bar
              setNewProgressBarProposal(itemsX);
              //setCommentsAttachProgress(itemsX);
            } else {
              const message =
                "Não foi possivel adicionar os anexos à proposta: " +
                response.message;
              let errorDetails = "";
              if (response.errors.length > 0) {
                errorDetails += "<ul>";
                response.errors.forEach(
                  (error: string) => (errorDetails += "<li>" + error + "</li>")
                );
                errorDetails += "</ul>";
              }
              openAlert({
                variant: "error",
                text: message + errorDetails,
                title: "Atenção",
              });
            }
          });
      }
    },
    [ proposal,optionsProposal,infoProgressBarProposal]
  );

  const onProposalPrintPreviewClick = useCallback(() => {
    proposal.vehicles = vehicleDataTable
      .filter((v) => v.simulations.some((s) => s.proposeToCustomer === true))
      .map((v) => ({
        ...v,
        simulations: v.simulations.filter((s) => s.proposeToCustomer === true),
      }));
    proposalService.sendToCustomerPreview(proposal).then((response) => {
      if (response.succeeded) {
        openAlert({
          variant: "info",
          text: "Proposta enviada com sucesso.",
          title: "",
          autoHideDuration: 3000,
        });
      } else {
        const message =
          "Não foi possivel enviar a proposta: " + response.message;
        let errorDetails = "";
        if (response.errors.length > 0) {
          errorDetails += "<ul>";
          response.errors.forEach(
            (error: string) => (errorDetails += "<li>" + error + "</li>")
          );
          errorDetails += "</ul>";
        }
        openAlert({
          variant: "error",
          text: message + errorDetails,
          title: "Atenção",
        });
      }
    });
  }, [ proposal, vehicleDataTable]);

  const onSaveAndSendLaterClick = useCallback(() => {
    let proposalRequest: UpsertProposalRequest = {
      proposal: proposal,
      rentalTypeId: "",
      commercialConditionsId: "",
      publish: false,
    };
    proposalService.upsert(proposalRequest).then((response) => {
      if (response.succeeded) {
        setProposal(response.value);
      } else {
        openAlert({
          variant: "error",
          text: "Ocorreu um erro guardar! Por favor tente novamente. Se o problema persistir, contate o administrador.",
          title: "Atenção",
        });
      }
    });
  }, [proposal]);

  const handleCancelSimulationClick: ButtonProps['onClick'] = useCallback(() => {
    proposalService.delete(proposal.id).then((response) => {
      if (response.succeeded){
        // uma vez que handleCardClick também é uma useCallback
        // é preciso declarar um argumento que invoque a função navigate declarada dentro da callback handleCardClick
        // para que a navegação seja executada devidamente
        const redirectToPage = handleCardClick("proposal");
        redirectToPage();
      }
      else {
        openAlert({
          variant: "error",
          text: "Ocorreu um erro ao Eliminar! Por favor tente novamente. Se o problema persistir, contate o administrador.",
          title: "Atenção",
        });
      }
    });
  }, [
    proposal,
    handleCardClick,
  ]);

  const onSendProposalToCustomerClick = useCallback(() => {
    proposal.vehicles = vehicleDataTable;
    if(proposal.printProposalDestinationEmailAddress===""){
      setProposal({
        ...proposal,
        printProposalDestinationEmailAddress: requiredClientData.e_Mail,
      });
    }

    proposalService.sendToCustomer(proposal).then((response) => {
      if (response.succeeded) {
        openAlert({
          variant: "info",
          text: "Proposta enviada com sucesso.",
          title: "",
          autoHideDuration: 3000,
        });
        const updatedProposal = {
          ...proposal,
          sendToApprovedDate: response.value?.sendToApprovedDate,
          sendToApprovedValidateDate: response.value?.sendToApprovedValidateDate,
          state: 'On Customer'
        }
        setProposal(updatedProposal);
        let proposalRequest:UpsertProposalRequest = {
          proposal: updatedProposal,
          rentalTypeId: "",
          commercialConditionsId: "",
          publish: true,
        };
        // atualizar proposta com o estado de enviado ao cliente
        // e se necessário efetuar mapeamentos adicionais dentro do setProposal
        proposalService.upsert(proposalRequest).then((response) => {
          if(response.succeeded){
            setProposal({
              ...proposal,
              state: response.value.state
            })
          }
        })

      } else {
        const message = "Não foi possivel enviar a proposta";
        let errorDetails = "";
        if (response.errors.length > 0) {
          errorDetails += "<ul>";
          response.errors.forEach(
            (error: string) => (errorDetails += "<li>" + error + "</li>")
          );
          errorDetails += "</ul>";
        }
        openAlert({
          variant: "error",
          text: message + errorDetails,
          title: "Atenção",
        });
      }
    });
  }, [proposal, vehicleDataTable, requiredClientData.e_Mail]);

  const onSimulationSelected = useCallback((selected: { vehicleIndex: number; simulationIndex: number }[]) => {
    vehicleDataTable.forEach((v) =>
      v.simulations.forEach((s) => (s.proposeToCustomer = false))
    );
    selected.forEach((item: { vehicleIndex: number; simulationIndex: number }) => {
      vehicleDataTable[item.vehicleIndex].simulations[
        item.simulationIndex
      ].proposeToCustomer = true;
    });
    setVehicleDataTable([...vehicleDataTable]);
    setContractSelectedRows(selected);
  }, [vehicleDataTable]);

  const handleGroupEditModalApplyButtonClick: ButtonProps["onClick"] = useCallback((e: any) => {
    setChangedProposal(true);
    setVehicleDataTable((rows) => {

      rows.map((vehicle, index) => {
        selectedRows.map((selectedRow) => {

          if (index !== selectedRow) {
            return vehicle;
          }
          // let vehicle = rowVehicle;

          vehicle.discountWithoutTax = vehicleGroupEditModalValues.discountWithoutTax;
          vehicle.discountWithTaxPercentage = vehicleGroupEditModalValues.discountWithTaxPercentage;
          vehicle.campaignWithoutTax = vehicleGroupEditModalValues.campaignWithoutTax;
          vehicle.campaignWithTaxPercentage = vehicleGroupEditModalValues.campaignWithTaxPercentage;

          vehicle.rappelWithoutTax = vehicleGroupEditModalValues.rappelWithoutTax;
          vehicle.rappelWithTaxPercentage = vehicleGroupEditModalValues.rappelWithTaxPercentage;
          vehicle.extraRappel = vehicleGroupEditModalValues.extraRappel;
          vehicle.lotId = vehicleGroupEditModalValues.lotId;
          vehicleGroupEditModalValues.extraAccessories.map((x) => {
            if (vehicle.licensePlate === "") {
              vehicle.accessories.push({
                id: 0,
                vehicleId: vehicle.id,
                optionTypeId: "",
                description: x.extraAcessoryName,
                responsable: x.extraAcessoryInstaller,
                price: x.extraAcessoryPrice * ((100 + vehicle.vat)/100),
                price_Excl_VAT:x.extraAcessoryPrice,
              });
              vehicle.totalAccessoriesWithout += x.extraAcessoryPrice;
              vehicle.totalAccessoriesWith = vehicle.totalAccessoriesWithout * ((100 + vehicle.vat)/100)
            }
            return x;
          });
          updateValuesVehicle(vehicle,"");
          return selectedRow;
        })
        return vehicle;
      });
      setVehicleGroupEditModalValues({
        incomeWithoutTax: 0,
        incomeWithTax: 0,
        collateral:0,
        margin: 0,
        increaseVr:0,
        initialEntry: 0,
        expenses: 0,
        marginCheck: false,
        commercialEffortCheck: false,
        vrCheck: false,
        discountWithoutTax: 0,
        discountWithTax: 0,
        discountWithTaxPercentage: 0,
        campaignWithoutTax: 0,
        campaignWithTax: 0,
        campaignWithTaxPercentage: 0,
        rappelWithoutTax: 0,
        rappelWithTax: 0,
        rappelWithTaxPercentage: 0,
        extraRappel: 0,
        lotId: "",
        extraAccessories: []
      });
      setEditGroupExtraAccessories([]);
      return rows
    })
    // setSelectedRows((rowsSelect) =>
    //   rowsSelect.map((rowSelect) => {
    //     setVehicleGroupEditModalValues((vehicleGroupEdit) => {
    //       setVehicleDataTable((rows) =>
    //         rows.map((rowVehicle, index) => {
    //           if (index !== rowSelect) {
    //             return rowVehicle;
    //           }
    //           let vehicle = rowVehicle;

    //           vehicle.discountWithoutTax =
    //             vehicleGroupEdit.discountWithoutTax;
    //           vehicle.discountWithTaxPercentage =
    //             vehicleGroupEdit.discountWithTaxPercentage;
    //           vehicle.campaignWithoutTax =
    //             vehicleGroupEdit.campaignWithoutTax;
    //           vehicle.campaignWithTaxPercentage =
    //             vehicleGroupEdit.campaignWithTaxPercentage;

    //           vehicle.rappelWithoutTax = vehicleGroupEdit.rappelWithoutTax;
    //           vehicle.rappelWithTaxPercentage =
    //             vehicleGroupEdit.rappelWithTaxPercentage;
    //           vehicle.extraRappel = vehicleGroupEdit.extraRappel;
    //           vehicle.lotId = vehicleGroupEdit.lotId;
    //           vehicleGroupEdit.extraAccessories.forEach((x) => {
    //             if (vehicle.licensePlate === "") {
    //               vehicle.accessories.push({
    //                 description: x.extraAcessoryName,
    //                 price: x.extraAcessoryPrice,
    //                 responsable: x.extraAcessoryInstaller,
    //                 id: 0,
    //                 vehicleId: vehicle.id,
    //                 optionTypeId: "",
    //               });
    //             }
    //           });
    //           updateValuesVehicle(vehicle);

    //           return { ...rowVehicle, vehicle };
    //         })
    //       );
    //       return vehicleGroupEdit;
    //     });
    //     return rowSelect;
    //   }
    // ));


  },[
    selectedRows,
    vehicleGroupEditModalValues
  ]);

  const onEditGroupExtraAccessoryPriceChange = useCallback((itemIndex:number) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>  {
    setVehicleGroupEditModalValues(current => {
      let extraAccessories = current.extraAccessories;
      extraAccessories[itemIndex].extraAcessoryPrice =convertNumber(e.target.value);
      return {
        ...current,
        extraAccessories: extraAccessories,
      };
    });
    setEditGroupExtraAccessories((rows) => {
      rows.map((row, index:Number) => {
        if (index === itemIndex) {
          row.ExtraAcessoryPriceTextFieldProps.value =convertNumber(validaStringFormatDecimal(e.target.value,row.ExtraAcessoryPriceTextFieldProps.value));
        }
        return row;
      })
      return rows;
    })
  }, []);

  const onEditGroupExtraAccessoryInstallerChange = useCallback((itemIndex:number) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>  {
    setVehicleGroupEditModalValues(current => {
      let extraAccessories = current.extraAccessories;
      extraAccessories[itemIndex].extraAcessoryInstaller = e.target.value;
      return {
        ...current,
        extraAccessories: extraAccessories,
      };
    });
    setEditGroupExtraAccessories((rows) => {
      rows.map((row, index:Number) => {
        if (index === itemIndex) {
          row.ExtraAcessoryInstallerDropDownProps.value = e.target.value;
        }
        return row;
      })
      return rows;
    });
  }, []);

  const onEditGroupExtraAccessoryDeleteClick = useCallback((itemIndex:number) => () => {
    let groupEditModalValues = vehicleGroupEditModalValues;
    groupEditModalValues.extraAccessories.splice(itemIndex, 1);
    setVehicleGroupEditModalValues(groupEditModalValues);
    // setVehicleGroupEditModalValues(current => {
    //   current.extraAccessories.splice(itemIndex, 1);
    //   return current
    //   // return {
    //   //   ...current,
    //   //   extraAccessories: extraAccessories,
    //   // };
    // });

    let groupExtras = editGroupExtraAccessories;
    groupExtras.slice(itemIndex,1);
    setEditGroupExtraAccessories(groupExtras)
    // setEditGroupExtraAccessories((rows) => {
    //   rows.splice(itemIndex,1)
    //   return rows;
    // })
  }, [vehicleGroupEditModalValues,editGroupExtraAccessories]);

  const onEditGroupExtraAccessoryNameChange = useCallback((itemIndex:number) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>  {
    setVehicleGroupEditModalValues(current => {
      let extraAccessories = current.extraAccessories;
      extraAccessories[itemIndex].extraAcessoryName = e.target.value;
      return {
        ...current,
        extraAccessories: extraAccessories,
      };
    });

    setEditGroupExtraAccessories((rows) => {
      rows.map((row, index:Number) => {
        if (index === itemIndex)
        {
          row.ExtraAcessoryNameTextFieldProps.value = e.target.value;
        }
        return row;
      })
      return rows;
    })
  }, []);

  const onEditGroupAddAccessoryClick = useCallback(() =>  {
    let values = vehicleGroupEditModalValues
    values.extraAccessories.push({
      extraAcessoryName: "",
      extraAcessoryInstaller: "",
      extraAcessoryPrice: 0,
      // onChange:
    });
    setVehicleGroupEditModalValues(values);
    // setVehicleGroupEditModalValues(current => {
    //   let extraAccessoriesValues = current.extraAccessories;
    //   extraAccessoriesValues.push({
    //     extraAcessoryName: "",
    //     extraAcessoryInstaller: "",
    //     extraAcessoryPrice: 0,
    //     // onChange:
    //   });
    //   // current.extraAccessories = extraAccessoriesValues;
    //   // return current
    //   return {
    //     ...current,
    //     extraAccessories: extraAccessoriesValues,
    //   };
    // });

    const aux: GroupEditModalProps['extraAccessories'] = [];

    vehicleGroupEditModalValues.extraAccessories.forEach((item,index) => {
      return aux.push({
        ExtraAcessoryNameTextFieldProps : {
          label: "Nome",
          value: item.extraAcessoryName,
          onChange: onEditGroupExtraAccessoryNameChange(index),
        },
        ExtraAcessoryPriceTextFieldProps: {
          label: "Preço sem IVA",
          value: item.extraAcessoryPrice,
          onChange: onEditGroupExtraAccessoryPriceChange(index),
        },
        ExtraAcessoryInstallerDropDownProps: {
          label: "Responsável de instalação",
          placeholder: "Inserir nome do responsável",
          value: vendorsMemo.find((x) => {return x.value === item.extraAcessoryInstaller}) ?? '',
          options: vendorsMemo,
          onChange: onEditGroupExtraAccessoryInstallerChange(index),
        },
        deleteExtraAcessoryIconButtonProps:{
          onClick: onEditGroupExtraAccessoryDeleteClick(index),
        }
      });
    })

    setEditGroupExtraAccessories(aux);
  }, [
    onEditGroupExtraAccessoryDeleteClick, onEditGroupExtraAccessoryInstallerChange,
    onEditGroupExtraAccessoryNameChange, onEditGroupExtraAccessoryPriceChange,
    vehicleGroupEditModalValues, vendorsMemo,
    // validar dependencias 10-04 editGroupExtraAccessories
  ]
      // ,  vehicleGroupEditModalValues, getEditGroupAccessories]
  );

  const handleContractSettingsAddFrequentSimulation = useCallback((vehicleIndex:number, simulationIndex:number) => {
    let simulation = vehicleDataTable[vehicleIndex].simulations[simulationIndex];
    setFrequentTitle('');
    let tempFrequentSimulation: any = JSON.parse(JSON.stringify(simulation))
    tempFrequentSimulation.no = '';
    tempFrequentSimulation.miles = tempFrequentSimulation.miles;
    setNewFrequent(tempFrequentSimulation);
    setNewVehicleFrequentIndex(vehicleIndex);
  },[vehicleDataTable]);

  const handleAddFrequentQuotationSimulation = useCallback((vehicleIndex: number, simulationIndex: number) => {
  // const handleAddFrequentQuotationSimulation = useCallback((currentVehicle:IVehicle,simulation: Simulation) => (vehicleIndex: number, simulationIndex: number) => {
    // openAlert({
    //   variant: "success",
    //   text: "teste",
    //   title: "Sucesso",
    //   autoHideDuration: 3000,
    // });

    // BEGIN cria so com a simulação pretendida

    //alterações sk-392
    // let vehicle = JSON.parse(JSON.stringify(currentVehicle));
    let vehicle = JSON.parse(JSON.stringify(vehicleDataTable[vehicleIndex]));
    let simulation = JSON.parse(JSON.stringify(vehicleDataTable[vehicleIndex].simulations[simulationIndex]));

    vehicle.simulations = [];
    vehicle.simulations.push(simulation);
    let mostFrequent = vehicle as IMostFrequent;
    mostFrequent.userId = currentUser.userId;
    let tempSimulationsScenario: IFrequentSimulation[] = [];
    let frequentHeaderSimulation:IFrequentHeaderSimulation = {
      id:0,
      userId:0,
      deadline:simulation.deadline.toString(),
      miles:simulation.miles.toString(),
      tire:(simulation.tires as boolean) ? "yes":"no",
      vehicleReplacementOptions:"",
      maintenance:"",
      insurance:simulation.secureOptions ? 'yes' : 'no',
      deductible:"",
      title:frequentTitle,
      mostUsed:0,
      visibility:0,
      typeofRental:vehicle.typeofRental,
      commercialConditions:vehicle.commercialConditions,
      typeofIncome:vehicle.typeofIncome,
      use: vehicle.use,
      frequentSimulations:[]
    }
    let tempFrequentSimulation: IFrequentSimulation = simulation
    tempFrequentSimulation.no="";
    tempFrequentSimulation.id=0;
    tempSimulationsScenario.push(tempFrequentSimulation);

    frequentHeaderSimulation.frequentSimulations = tempSimulationsScenario;
    mostFrequent.headerSimulation = frequentHeaderSimulation;
    // END //INFO caso seja para enviar todas as simmulações eliminar as duas linhas dentro dos comentários
    setNewMostFrequent(mostFrequent);
    setFrequentTitle('');

  },[currentUser.userId, frequentTitle, vehicleDataTable]);

  const handleFrequentTitleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFrequentTitle(e.target.value);
  },[]);

  const handleFrequentVisibilityClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setFrequentVisibility(convertNumber((e.target as any).value));
  }, [] );



  const handleSaveFrequentSimulation = useCallback(() => {
    let tempSimulationsScenario: Simulation[]=[];

    let frequentHeaderSimulation:IFrequentHeaderSimulation = {
        id:0,
        userId:0,
        //frequentVehicleId:0,
        deadline:newFrequent.deadline.toString(),
        miles:(newFrequent.miles / (newFrequent.deadline/12)).toString(),
        tire:(newFrequent.tires as boolean) ? "yes":"no",
        vehicleReplacementOptions:"",
        maintenance:"",
        insurance: newFrequent.secureOptions ? 'yes': 'no',
        deductible:"",
        title:frequentTitle,
        mostUsed:0,
        visibility:0,
        typeofRental:vehicleDataTable[newVehicleFrequentIndex?newVehicleFrequentIndex:0].typeofRental,
        commercialConditions:vehicleDataTable[newVehicleFrequentIndex?newVehicleFrequentIndex:0].commercialConditions,
        typeofIncome:vehicleDataTable[newVehicleFrequentIndex?newVehicleFrequentIndex:0].typeofIncome,
        use: vehicleDataTable[newVehicleFrequentIndex?newVehicleFrequentIndex:0].use,
        frequentSimulations:[]
    }
    tempSimulationsScenario.push(newFrequent);
    vehiclesService.addFrequentHeaderSimulation(tempSimulationsScenario,frequentHeaderSimulation,frequentTitle, frequentVisibility, currentUser.userId,true).then(data => {
      if ((data.succeeded))
      {
        openAlert({
          variant: "success",
          text: "Frequente guardado com sucesso!",
          title: "Sucesso",
          autoHideDuration: 3000,
        });
        setChangedProposal(true);
        getScenariosFrequentSimulations();
      }
      else {
        openAlert({
          variant: "error",
          text: "Não foi guardado o frequente. Caso este erro persista contacte um administrador.",
          title: "Atenção",
        });

      }
    });
    setFrequentTitle('');
  },[currentUser, newFrequent, frequentTitle, frequentVisibility,vehicleDataTable,newVehicleFrequentIndex]);


  const handleAddClient = useCallback(() => {
    setCustomerBC();
    setShowClientData(true);
  },[setCustomerBC])

  const handleSaveFrequentQuotationSimulation = useCallback(() => {

      let mostFrequent = newMostFrequent;
      mostFrequent.headerSimulation.title=frequentTitle;
      mostFrequent.headerSimulation.visibility=frequentVisibility;
      mostFrequent.headerSimulation.mostUsed=1;
      mostFrequent.headerSimulation.userId=currentUser.userId;

      vehiclesService.addFrequentQuotation((mostFrequent),currentUser.userId).then(data => {
      if ((data.succeeded))
      {
        openAlert({
          variant: "success",
          text: "Frequente guardado com sucesso!",
          title: "Sucesso",
          autoHideDuration: 3000,
        });
        getScenariosFrequentSimulations();
      }
      else {
        openAlert({
          variant: "error",
          text: "Não foi guardar o frequente. Caso este erro persista contacte um administrador.",
          title: "Atenção",
        });

      }
    });
  },[newMostFrequent, frequentTitle, frequentVisibility, currentUser.userId, getScenariosFrequentSimulations]);
  //#endregion

  //#region USE EFFECTS
  //FIXME VALIDAR CARREGAMENTO CORRECTO DAS OPÇÕES

  //GET INSTANCES - carregamento/atualização do ficheiro appsettings
  useEffect(() => {
    SettingsService.getInstance()
      .then(x => setSettings(x.settings));
  }, []);

  // //APPLIED FILTERS - filtros avançados de pesquisa
  // useEffect(() => {
  //   if (appliedFilters.length === 0) {
  //     setFilterResults([]);
  //   }
  // }, [appliedFilters]);

  //GET SETTINGS -- carregamento dos campos do ficheiro appsettings
  useEffect(() => {

    // setVehiclesMinGrossWeightFilter(settings.Data.Vehicles_MinGrossWeight);
    // setVehiclesMaxGrossWeightFilter(settings.Data.Vehicles_MaxGrossWeight);
    // setVehiclesMinMaxSuitcaseVolumeFilter(settings.Data.Vehicles_MinMaxSuitcaseVolume);
    // setVehiclesMaxMaxSuitcaseVolumeFilter(settings.Data.Vehicles_MaxMaxSuitcaseVolume);

    // setVehiclesMinLengthFilter(settings.Data.Vehicle_MinLength)
    // setVehiclesMaxLengthFilter(settings.Data.Vehicles_MaxLength)
    // setVehiclesMinWidthFilter(settings.Data.Vehicles_MinWidth);
    // setVehiclesMaxWidthFilter(settings.Data.Vehicles_MaxWidth);
    // setVehiclesMinHeightFilter(settings.Data.Vehicles_MinHeight);
    // setVehiclesMaxHeightFilter(settings.Data.Vehicles_MaxHeight);
    // setVehiclesMinDistanceBetweenFrontAxlesFilter(settings.Data.Vehicles_MinDistanceBetweenFrontAxles);
    // setVehiclesMaxDistanceBetweenFrontAxlesFilter(settings.Data.Vehicles_MaxDistanceBetweenFrontAxles);
    // setVehiclesMinDistanceBetweenRearAxlesFilter(settings.Data.Vehicles_MinDistanceBetweenRearAxles);
    // setVehiclesMaxDistanceBetweenRearAxlesFilter(settings.Data.Vehicles_MaxDistanceBetweenRearAxles);

    // setVehiclesMinPowerFilter(settings.Data.Vehicles_MinPower);
    // setVehiclesMaxPowerFilter(settings.Data.Vehicles_MaxPower);
    // setVehiclesMinEngineCapacityFilter(settings.Data.Vehicles_MinEngineCapacity);
    // setVehiclesMaxEngineCapacityFilter(settings.Data.Vehicles_MaxEngineCapacity);
    // setVehiclesMinMaxTorqueFilter(settings.Data.Vehicles_MinMaxTorque);
    // setVehiclesMaxMaxTorqueFilter(settings.Data.Vehicles_MaxMaxTorque);
    // setVehiclesMinCO2EmissionFilter(settings.Data.Vehicles_MinCO2Emission);
    // setVehiclesMaxCO2EmissionFilter(settings.Data.Vehicles_MaxCO2Emission);


    setDeadlineValues(x => {
      return [...settings.Data.DeadlineValues.map((element,index) => {
        element = {
          value: (element.value as string),
          onChange: handleDeadLineCheckedChange(index, true),
          checked: element.checked
        };
        return element;
      })];
    });


    setAnnualKmValues(x => {
      return [...settings.Data.AnnualKmValues.map((element,index) => {
        element = {
          value: element.value,
          onChange: handleAnnualKmCheckedChange(index, true),
          checked:false
        };
        return element;
      })];
    })

    const updatedValues = settings.Data.Vehicles_TyresNumber_MultiScenario.map((element) => {
      element.onChange = handleThirdLineTiresCheckedChange
      element.checked = false

      return element;
    })
    setThirdLineTiresValuesMultiScenario(updatedValues)
    setThirdLineTiresValues(settings.Data.Vehicles_TyresNumber);

    // const seats = settings.Data.Vehicle_NumberOfPlacesOptions.map((item: any, index: number) => {
    //   let vehicleSeats: CheckboxProps = {
    //     label: item + " lugares",
    //     checked: false,
    //     onClick: handleAdvFilterSeatsClick(index),
    //   };
    //   return vehicleSeats;
    // });
    //setAdvFilterSeats(seats)

  },[settings.Data,
    handleThirdLineTiresCheckedChange,
    //handleAdvFilterSeatsClick
  ])

  //GET COMMENTS
  useEffect(() => {
    if (!proposal || proposal.id === 0) return;
    commentsService.getComments(proposal.id).then((items) => {
      if (items === null || items === undefined) {
        // setResponseComments([]);
        // setTotalCommentsUnread(0);
        // setComments([]);
        return;
      }
      let totalUnread = 0;
      const comments = items.map((item) => {
        if (item.read === false) {
          totalUnread++;
        }
        return convertToComment(item);
      });
      setResponseComments(items);
      setTotalCommentsUnread(totalUnread);
      setComments(comments);
    });
  }, [newNote, proposal ]);

  const [applicantContactsList, setApplicantContactsList] = useState<RequiredDataProps[]>();
  const [applicantContactsOptionsList, setApplicantContactsOptionsList] = useState<Option[]>();
  const [commissions, setCommissions] = useState<ICommision[]>([]);
  const [commissionOptions, setCommissionsOptions] = useState<Option[]>([]);
  //INIT PROPOSAL -- carregamento inicial da proposta e de dados nescessários ao seu preenchimento
  useEffect(()=>{
    vehiclesService.getRentalTypes().then((data) => {
      if(data.succeeded) {
        let options:Option[] = []
        data.value.forEach((element:any) => {
          options.push({
            label: element.label,
            value: element.value
          })
        })
        setRentalTypes(options);
        setRentalTypeSettings(data.value)
      }
    });
    //Lease_Code vazio = todos os rentals
    vehiclesService.getCommercialConditions("").then((data) => {
      if (data.succeeded === true) {
        setCommercialConditions(data.value);
      }
    });
    // carrega contactos para
    contactService.getContacts().then((data) => {
      if(data && data.succeeded){
        let applicantsList: RequiredDataProps[] = [];
        let applicantsOptionsList: Option[] =[{
          label:'',
          value:''
        }];

        data.value.forEach((element: any) => {
          applicantsList.push(element as RequiredDataProps);
          applicantsOptionsList.push({
            label: `${element.no} - ${element.name}`,
            value: element.no
          });
        });
        setApplicantContactsList(applicantsList);
        setApplicantContactsOptionsList(applicantsOptionsList);
      }

    });

    vehiclesService.getCommissions().then((data) => {
      if(data && data.succeeded) {
        let commissionsList: ICommision[] = []
        let commissionsOptionsList: Option[] = []
        if(data.value && data.value.length > 0){
          data.value.forEach((element: ICommision) => {
            commissionsList.push(element);
            commissionsOptionsList.push({
              label: element.description,
              value: element.code
            });
          });
        }
        setCommissions(commissionsList);
        setCommissionsOptions(commissionsOptionsList);
      }
    });
  },[])

  //CREATE PROPOSAL
  useEffect(() => {
    const proposalRequest: UpsertProposalRequest = {
      proposal: proposal,
      rentalTypeId: "",
      commercialConditionsId: "",
      publish: false,
    };
    custService.getCenterDealer().then((data) => {
      if (data) {
        let channelOptions:Option[] = [];
        data.map((item: { id: string;description:string }) => {
          return channelOptions.push({
            label: item.id+' - '+item.description,
            value: item.id,
          });
        })
        setRequestingChannelOptions(channelOptions)
      }
    });

    if (!proposalId) {
      proposalService.upsert(proposalRequest).then((response) => {
        if (response.succeeded)
          setProposal(response.value);
      });

    }
  }, [proposalId]);


  const populateVehicleBrands = useCallback(() => {
    vehiclesService.getBrands().then((data) => {
      const items = convertToExpandableMakesOptions(data);
      data.map((item: { id: string,models:[{ id: string, description: string, active: boolean }] }) => {
        let modelsMake:[{label:string, value:string}]=[{ label: "", value: "" }];

        item.models.forEach((element) => {
          if (element.active) {
            modelsMake.push({
              label: element.description,
              value: element.id
            });
          }
          setVersionsSearchOptions((rows) => [
              ...rows,
              {
                  makeId: item.id,
                  modelId:element.id,
                  versions: [{ label: "", value: "", isInactive: false }],
              }
          ]);
        });
        modelsMake.splice(0,1);


        // maneira correta de fazer push diretamente dentro de um state
        setModelsSearchOptions((rows) => [
          ...rows,
          {
            makeId: item.id,
            models: modelsMake.length > 0 ? modelsMake : [{ label: "", value: "" }]
          }
        ]);
        return item;
      });

      setBrandsSectionProps(items);
      setMakesSearchOptions(items);

      if(proposalId) {
        proposalService.get(Number(proposalId)).then(response => {
          if(response.succeeded){
            if (response.value.leaseQuoteNo !== '') {
              setLeaseQuoteCreatedInBC(true);
              setEnableWizardStep4(true)
            }
            setProposal(response.value);

            if(response.value?.webPortalContactId){
              const searchValue = response.value?.webPortalContactId;
              let applicantsOptionsList: Option[] = [{label: '', value: ''}]
              contactService.getApplicants(searchValue).then((data) => {
                if( data && data.succeeded){
                  data.value.forEach((element: any) => {
                    applicantsOptionsList.push({
                      label: `${element.no} - ${element.name}`,
                      value: element.no
                    });
                  });
                  setApplicantContactsOptionsList(applicantsOptionsList);
                  let applicantOption = applicantsOptionsList.find(x => x.value === response.value?.webPortalContactId);
                  if(applicantOption) {
                    setApplicant(applicantOption);
                  } 
                }
              });
            }
            if(response.value?.contactId.length > 0 ) {
              setSearchClient({
                value: response.value.contactId,
                label: '',
              })
              setShowClientData(true);
              setCustomerBC();
            }
            setStep(1);
            validateWizardNavigationStep0() //VALIDAR ESTE STEP
            let step1 = validateWizardNavigationStep1(response.value?.vehicles)
            let step2 = validateWizardNavigationStep2(response.value?.vehicles)

            if (response.value.inputChannel === "0") {
              vehiclesService.getDrivers(response.value.contactId).then((data) => {
                if (data.succeeded) {
                  setAllDrivers(data.value);
                  setDrivers(data.value);
                }
              });
            }
            if(response.value.contactId === ''){
              setStep(0);
            }
            else {
              if(response.value.vehicles.length > 0){
                let vehicles:IVehicle[] = [];

                let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
                let insurancePackageOptions: Option[] = []
                //inicializa o Set

                Promise.all(response.value.vehicles.map(async (vehicle:IVehicle) => {

                  vehicle.hidden = false;
                  let extras:IExtraStandard[] = JSON.parse(JSON.stringify(vehicle.extraStandard));

                  await initExtraStandardDetails(vehicle) // ,response.value.vehicles.length);
                  // por alguma razão não carrega a lista de compativeis em modo de edição
                  let extrasSearch:Option[] = []
                  let updatetotalExtraWithoutTax = 0
                  let updatetotalExtraWithTax = 0
                  vehicle.extraStandard.map(x => {
                    x.visible = true
                    extras.forEach(extra => {
                      if (extra.optionTypeId === x.optionTypeId){
                        x.id = extra.id;
                        x.vehicleId = extra.vehicleId
                        if(x.price_Excl_VAT !== extra.price_Excl_VAT){
                          x.price_Excl_VAT = extra.price_Excl_VAT;
                          x.valueTextFieldProps.value =extra.price_Excl_VAT;
                        }
                        if(x.price !== extra.price){
                          x.price = extra.price;
                        }
                      }
                    });
                    if (x.checkedExtra || (x.checkboxProps && x.checkboxProps.checked) ) {
                      updatetotalExtraWithoutTax += x.price_Excl_VAT;
                      updatetotalExtraWithTax += x.price;
                    }
                    return x;
                  })
                  if(vehicle.totalExtraWithTax !== updatetotalExtraWithTax && updatetotalExtraWithTax !== 0) {
                    vehicle.totalExtraWithTax = updatetotalExtraWithTax;
                  }
                  if(vehicle.totalExtraWithoutTax !== updatetotalExtraWithoutTax && updatetotalExtraWithoutTax !== 0) {
                    vehicle.totalExtraWithoutTax = updatetotalExtraWithoutTax;
                  }

                  vehicle.extraStandard.map((element: { description: any; optionTypeId: any ;manufactor_Code:any}) => {
                    extrasSearch.push({
                      label:`${element.description} - ${element.manufactor_Code} - ${element.optionTypeId}`,
                      value: element.optionTypeId,
                    });
                    return element
                  });
                  vehicle.extraOptionsSearch = extrasSearch;

                  let insuranceFrameworkContractOptions: InsuranceFrameworkContract = {
                    typeofRental: '',
                    commercialConditions: '',
                    insurancePackageOptions: []
                  }

                  insuranceFrameworkContractOptions.typeofRental = vehicle.typeofRental
                  insuranceFrameworkContractOptions.commercialConditions = vehicle.commercialConditions

                  //verifica se existem opções pre-carregadas com o tipo de renda e condições comerciais da viatura
                  //se não existir
                  if(!Array.from(tmpInsuranceSet).some(item =>
                    item.typeofRental === vehicle.typeofRental &&
                    item.commercialConditions === vehicle.commercialConditions
                  )){
                    insurancePackageOptions = await vehiclesService.getInsurancePackage(vehicle.commercialConditions);

                    for(const insurancePackageOption of insurancePackageOptions) {
                      let insuranceOption: InsuranceOptions = {
                        insurancePackageOption: {
                          value: '',
                          label: ''
                        },
                        vsOverhaulVehicleOptions: [],
                        vsTheftVehicleOptions: [],
                        vsAccidentVehicleOptions: [],
                        vsAssistanceVehicleOptions: [],
                        vehicleTypeOptions: [],

                        vsOverhaulVehicleTypeOptions: [],
                        vsTheftVehicleTypeOptions: [],
                        vsAccidentVehicleTypeOptions: [],
                        vsAssistanceVehicleTypeOptions: [],

                        fuelOptions: [],
                        viaVerdeOptions: [],

                        insurance: '',
                        vehicleDamageOptions: [],
                        roadAssistanceOptions: [],
                        occupiersOptions: [],
                        liabilityOptions: [],
                        isolatedGlassBreakOptions: [],

                        responsibility: '',
                        ownDamage: '',
                        occupants: '',
                        travelAssistance: '',
                        glassBreakage: '',
                        secureOptions: false, // validar como ultrapassar esta questão
                      };

                      insuranceOption.insurancePackageOption = insurancePackageOption;
                      insuranceOption = await initSimulationDetailsDropdowns(insuranceOption, vehicle.commercialConditions);

                      if(!insuranceFrameworkContractOptions.insurancePackageOptions.some(element =>
                        element.insurancePackageOption.value === insurancePackageOption.value
                      )) {
                        insuranceFrameworkContractOptions.insurancePackageOptions.push(insuranceOption);

                        insuranceOption = await initVehicleReplacementOptionsDropDown(insuranceFrameworkContractOptions, insuranceOption,true);
                        insuranceOption = await initSelectedInsuranceDropDown(insuranceOption, vehicle.use,true);
                        // if (rentalSettings.insurance) {
                        //   insuranceOption.secureOptions = rentalSettings.insurance;
                        //   insuranceOption.insurance = insuranceFrameworkContractOptions.insurancePackageOptions?.length > 0 ?
                        //     insuranceFrameworkContractOptions.insurancePackageOptions[0].insurance
                        //     : '';
                        //   insuranceOption = await initSelectedInsuranceDropDown(insuranceOption, vehicle.use,true);
                        // } else {
                        //   insuranceOption.secureOptions = rentalSettings.insurance;
                        //   insuranceOption.insurance = '';
                        // }
                      }
                    }

                    if(!Array.from(tmpInsuranceSet).some(item => item.typeofRental === vehicle.typeofRental && item.commercialConditions === vehicle.commercialConditions)){
                      tmpInsuranceSet.add(insuranceFrameworkContractOptions);
                    }
                    insurancePackageOptions = insuranceFrameworkContractOptions.insurancePackageOptions.map(x => x.insurancePackageOption);
                  }
                  // se existir inicializa as opções de seguro para o tipo de renda e condições comerciais da viatura
                  else {
                    let teste = Array.from(tmpInsuranceSet).find(item =>
                      item.typeofRental === vehicle.typeofRental &&
                      item.commercialConditions === vehicle.commercialConditions
                    )
                    if(teste){
                      insuranceFrameworkContractOptions = teste;
                      // let options: InsuranceOptions[] = teste.insurancePackageOptions;
                      // options.forEach(element => {
                      //   insurancePackageOptions.push(element.insurancePackageOption);
                      // })
                      insurancePackageOptions = teste.insurancePackageOptions.map(x => x.insurancePackageOption)
                    } else {
                      insurancePackageOptions = [];
                    }
                  }
                  setInsuranceFrameworkContractSet(tmpInsuranceSet);

                  if (vehicle.simulations.length > 0) {

                    // await initSimulationDetailsDropdowns(vehicle.commercialConditions).then(async (simulationDetailsDropdownOptions: Simulation) => {
                    vehicle.simulations = await Promise.all(
                      vehicle.simulations.map(async (simulation, index) => {
                        // let teste = Array.from(insuranceFrameworkContractSet).find(element => element.typeofRental === vehicle.typeofIncome && element.commercialConditions === vehicle.commercialConditions)?.insurancePackageOptions.map(x => x.insurancePackageOption)

                        //se a proposta tiver linhas com seguro carrega com base no seguro caso contrario carrega o primeiro seguro como default
                        let insurancePackageOption =  insuranceFrameworkContractOptions.insurancePackageOptions.find(x => x.insurance === simulation.insurance) ?? insuranceFrameworkContractOptions.insurancePackageOptions[0];
                        simulation.insurancePackageOptions = insurancePackageOptions;//insuranceFrameworkContractOptions.insurancePackageOptions;

                        simulation.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
                        simulation.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
                        simulation.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
                        simulation.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

                        simulation.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
                        simulation.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
                        simulation.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
                        simulation.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
                        simulation.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

                        simulation.fuelOptions = insurancePackageOption.fuelOptions;
                        simulation.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

                        simulation.insurance = insurancePackageOption.insurance;
                        simulation.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions;
                        simulation.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions;
                        simulation.occupiersOptions = insurancePackageOption.occupiersOptions;
                        simulation.liabilityOptions = insurancePackageOption.liabilityOptions;
                        simulation.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions;

                        simulation.responsibility = insurancePackageOption.responsibility;
                        simulation.ownDamage = insurancePackageOption.ownDamage;
                        simulation.occupants = insurancePackageOption.occupants;
                        simulation.travelAssistance = insurancePackageOption.travelAssistance;
                        simulation.glassBreakage = insurancePackageOption.glassBreakage;

                        // simulation.secureOptions= insurancePackageOption.secureOptions;

                        // simulation = await initVehicleReplacementOptionsDropDown(simulation,vehicle.commercialConditions,true)
                        // simulation = await initSelectedInsuranceDropDown(simulation,vehicle.use,true)

                        return simulation
                      })
                    )
                    // });
                  }

                  // este filtro tem de ser colocado como falso nesta secção para fazer o carregamento inicial
                  // de viaturas com estado inativas afetas à proposta
                  const filters = VehicleTypeStatusHelper.generateAdvancedFilterVersionsOptions(false);
                  filters.push({
                    type: AdvancedFilterVersionsOptions.Make,
                    value: vehicle.makeId,
                  });
                  filters.push({
                    type: AdvancedFilterVersionsOptions.Model,
                    value: vehicle.modelId,
                  });
                  vehicles.push(vehicle);
                  await  vehiclesService.getVersionsToCombo(filters).then(versionsCombo => {
                    if (versionsCombo.value.length > 0) {
                      if (versionsCombo.value !== undefined && versionsCombo.value[0] !== undefined) {
                        let option: Option | undefined = {
                          label: "",
                          value: "",
                        };
                        const makeVersions = versionsCombo.value.map(
                          (item: {
                            label: string;
                            value: string;
                            isInactive: boolean;
                          }) => {
                            return {
                              label: item.label,
                              value: item.value,
                              isInactive: false
                            };
                          }
                        );
                        if (makeVersions && makeVersions.length > 0) {
                          setVersionsSearchOptions((rows) =>
                            rows.map((cur, index) => {
                              if (cur.makeId === vehicle?.makeId && cur.modelId===vehicle?.modelId) {
                                var inactiveVehicleTypeStatus: string = VehicleTypeStatus[VehicleTypeStatus.Inactive];
                                if(vehicle !== undefined) {
                                  cur.versions=makeVersions;
                                  const hasInactiveVersion = cur.versions.some((v: { value: string; }) => v.value === vehicle?.versionId);
                                  if(!hasInactiveVersion) {
                                    if( cur.versions.filter((x: { value: string; }) => x.value === "").length===1){
                                      cur.versions = [{
                                        label:`${vehicle?.modelDescription}; ${vehicle?.caracteristics}`,
                                        value: vehicle.versionId,
                                        isInactive: true
                                      }];
                                    }
                                    else{
                                      cur.versions.push({
                                        label: `${vehicle?.modelDescription}; ${vehicle?.caracteristics}`,
                                        value: vehicle.versionId,
                                        isInactive: true
                                      });
                                    }
                                  }
                                  option = {
                                    label: `${vehicle.versionDescription}; ${vehicle.caracteristics}`,
                                    value: vehicle.versionId ,
                                  }
                                  updateSearchOptions(option , '', vehicles);
                                }
                              }
                              return cur;
                            })
                          );
                        }
                      }
                    }
                  });
                  return vehicle
                })).finally(()=>{
                  setVehiclesListingTotal(vehicles.length)
                  // vehicles.map(vehicleTmp =>{
                  //     handleSearchOptionsAndVersionCaracteristics(vehicleTmp,vehicles)
                  // });
                  setVehicleDataTable(vehicles);
                })
              }
            }
          }
        });
      }
    });
  },[
    proposalId, 
    setBrandsSectionProps, 
    initExtraStandardDetails, 
    initSimulationDetailsDropdowns, 
    convertToExpandableMakesOptions, 
    // insuranceFrameworkContractSet, // causa dependencia ciclica
    // initSelectedInsuranceDropDown, 
    // initVehicleReplacementOptionsDropDown, 
    // setCustomerBC, 
    // updateSearchOptions, 
    // validateWizardNavigationStep0, 
    // validateWizardNavigationStep1, 
    // validateWizardNavigationStep2
  ])

  const populateFunctionalGroupVehicleBrands = useCallback((
    functionalGroupsMakes: string[],
    functionalGroupsModels:string[],
    functionalGroupsVersionTypes: FunctionalGroupsVersionType[]
  ) => {
    console.log(functionalGroupsModels, functionalGroupsMakes, functionalGroupsVersionTypes);
    vehiclesService.getFunctionalGroupBrands(functionalGroupsMakes, functionalGroupsModels).then((data) => {
      if(data && data.succeeded){
        const items = convertToExpandableMakesOptions(data);
        setBrandsSectionProps(items);
        setMakesSearchOptions(items);

        if(proposalId){

        }
      }
    });
  },[
    convertToExpandableMakesOptions,
    proposalId,
    setBrandsSectionProps,
  ])
  //PROPOSAL EDITED AND GET BRANDS
  useEffect(() => {
    custService.getSalesPersons().then((data) => {
      let persons: Option[] = [];
      if (data) {
        setSalesPersons((rows)=>{
          if (rows.length === 0) {
            (data.value as SalesPerson[]).forEach((person) => {
              persons.push({
                value: person.code,
                label: person.name,
              });
            });
            rows=persons;
          }
          return rows;
        })
      }

      let functionGroups: string[] = [];
      functionGroups = allowedValues(functionGroups, 'functional groups');
      if(currentUser.profile) {
        profileService.getFunctionalGroupVehicles(functionGroups).then((response) => {
          if(response){
            if(response.succeeded){
              let functionalGroupsMakesData: string[] = [];
              let functionalGroupsModelsData: string[] = [];
              let functionalGroupsVersionTypesData: FunctionalGroupsVersionType[] = [];
              response.value.forEach((element: FunctionalGroupVehicle) => {
                if(!functionalGroupsMakesData.some(x => x === element.make)){
                  functionalGroupsMakesData.push(element.make);
                }
                if(!functionalGroupsModelsData.some(x => x === element.model)){
                  functionalGroupsModelsData.push(element.model);
                }
                if(!functionalGroupsVersionTypesData.some(x => x.versionType === element.model_Type)){
                  functionalGroupsVersionTypesData.push({
                    versionType: element.model_Type,
                    campaign: element.campaign,
                    discountPercentage: element.discount_Percent,
                    rappel: element.rappel,
                    transport_x0026_immatriculation_cost: element.transport_x0026_immatriculation_cost,
                    contractExpenses: element.contract_Expenses
                  });
                }

              });
              setFunctionalGroupsMakes(functionalGroupsMakesData);
              setFunctionalGroupsModels(functionalGroupsModelsData);
              setFunctionalGroupsVersionTypes(functionalGroupsVersionTypesData);

              populateFunctionalGroupVehicleBrands(functionalGroupsMakesData,functionalGroupsModelsData,functionalGroupsVersionTypesData);

            } else {
              let errorDetails = "";
              const message = response.message ? response.message : "";
              if (response.errors.length > 0) {
                errorDetails += " Detalhes: <ul>";
                response.errors.forEach(
                  (error: string) => (errorDetails += "<li>" + error + "</li>")
                );
                errorDetails += "</ul>";
              }
              openAlert({
                variant: "error",
                text: "Ocorreu um erro ao obter as viaturas!\nPor favor contacte um administrador." + message + errorDetails,
                title: "Alerta",
              });
            }
          } else {
            populateVehicleBrands();
          }
        });

      } else {
        populateVehicleBrands();
      }
    });
  },[
    allowedValues, 
    populateVehicleBrands,
    populateFunctionalGroupVehicleBrands,
    currentUser
  ])

  //MESSAGEM PERSISTENTE DE CRIAÇÃO/ATUALIZAÇÃO CALCULO
  useEffect(() => {
    // persiste mensagens de calculo para outras páginas ( quer de sucesso ou nao)
    if (asyncResponse) {
      let id = asyncResponse.result.toString().split('/').pop() ;
      // Display the openAlert message with the response data.
      if(asyncResponse.errors !== "") {
        openAlert({
          variant: "error",
          text: asyncResponse.errors,
          title: "Atenção",
          // autoHideDuration: 3000,
          button:{
            props:{
              onClick: handleCardClick(asyncResponse.result)
            },
            text: `Voltar à proposta ${id}.`
          }
        })
      } else {
        openAlert({
          variant: "success",
          text: "Calculo efetuado com sucesso",
          title: "Atenção",
          autoHideDuration: 3000,
          button:{
            props:{
              onClick: handleCardClick(asyncResponse.result)
            },
            text: `Voltar à proposta ${id}.`
          }
        })
      }
      // Reset the asyncResponse in the context to avoid repeated alerts.
    }
  }, [ asyncResponse, handleCardClick ]);

  //#endregion

  // FILTROS AVANÇÃDOS STEP 2
  // const filterDrawerPropsFilterResults = useMemo(() => {
  //   let results = filterResults?.map((b, index) => {
  //     return {
  //       name: b.name,
  //       characteristics: b.characteristics, //TODO: avaliar criar do lado do interface devido ao multilingue
  //       icons: b.icons,
  //       businessPrice: b.businessPrice,
  //       checkboxProps: {
  //         onChange: handleAdvFilterSetSelectedChange(index),
  //       },
  //       //associateLotTextFieldProps: b.associateLotTextFieldProps,
  //       associateLotTextFieldProps: {
  //         label: b.associateLotTextFieldProps.label,
  //         value: b.associateLotTextFieldProps.value,
  //         onChange: handleAdvFilterSetBatchChange(index),
  //       },
  //     };
  //   });
  //   return results;
  // }, [
  //   filterResults,
  //   handleAdvFilterSetBatchChange,
  //   handleAdvFilterSetSelectedChange,
  // ]);

  // const filterDrawerProps = useMemo(() => ({
  //   loading: filterLoading,
  //   headerTitle: !filterLoading && filterResults?.length === 0
  //     ? "Filtros Avançados"
  //     : "Resultados da pesquisa",
  //   brandSectionProps: {
  //     vehicleTypesText: "Tipos de viaturas",
  //     vehicleTypesCheckboxProps: advFilterTypeStatusOptions,
  //     brandSearchResultProps: {
  //       label: "Pesquisar marca",
  //       options: brandsSectionProps.map(
  //         (item: { value: string; label: string }) => {
  //           return { value: item.value, label: item.label };
  //         }
  //       ),
  //       placeholder: "Pesquisar",
  //       onChange: handleBrandsSearchChange,
  //     },
  //     allBrandsCheckBoxProps: {
  //       onClick: handleAllBrandsCheckboxProps,
  //     },
  //     allBrandsText: "Todas",
  //     brands: filterDrawerPropsBrands,
  //   },
  //   vehicleSpecificationsSectionProps: {
  //     vehicleFuelTypesText: "Tipo de Combustível",
  //     vehicleFuelTypesCheckboxProps: advFilterFuelTypeOptions,
  //     sliders: [
  //       {
  //         label: "Peso Bruto da viatura (Kg)",
  //         measurementType: "kg",
  //         maxValue: vehicleMaxGrossWeightDefaultValue,
  //         minValue: vehicleMinGrossWeightDefaultValue,
  //         step: 1,
  //         values: [
  //           vehiclesMinGrossWeightFilter,
  //           vehiclesMaxGrossWeightFilter,
  //         ],
  //         valuesTextFieldProps: [
  //           {
  //             value: vehiclesMinGrossWeightFilter,
  //             onChange: handleVehiclesMinGrossWeightFilter,
  //           },
  //           {
  //             value: vehiclesMaxGrossWeightFilter,
  //             onChange: handleVehiclesMaxGrossWeightFilter,
  //           },
  //         ],
  //         onChange: handleVehiclesGrossWeightBarFilterChange,
  //       },
  //       {
  //         label: "Volume Máximo da mala (L)",
  //         measurementType: "L",
  //         maxValue: vehicleMaxMaxSuitcaseVolumeDefaultValue,
  //         minValue: vehicleMinMaxSuitcaseVolumeDefaultValue,
  //         step: 1,
  //         values: [
  //           vehiclesMinMaxSuitcaseVolumeFilter,
  //           vehiclesMaxMaxSuitcaseVolumeFilter,
  //         ],
  //         valuesTextFieldProps: [
  //           {
  //             value: vehiclesMinMaxSuitcaseVolumeFilter,
  //             onChange: handleVehiclesMinMaxSuitcaseVolumeFilter,
  //           },
  //           {
  //             value: vehiclesMaxMaxSuitcaseVolumeFilter,
  //             onChange: handleVehiclesMaxMaxSuitcaseVolumeFilter,
  //           },
  //         ],
  //         onChange: handleVehiclesSuitcaseVolumeBarFilterChange,
  //       },
  //     ],
  //     segmentSearchResultProps: {
  //       label: "Pesquisar segmento",
  //       options: advFilterSegmentsSearchOptions,
  //       onChange: handleSegmentsSearch,
  //     },
  //     segments: advFilterSegmentsOptions.filter((x: { hidden: boolean }) => x.hidden === false).map((
  //       x: { label: string, value: string, hidden: boolean, checkBoxProps:{checked:boolean} },
  //       index: number
  //     ) => {
  //       return {
  //         label: x.label,
  //         value: x.value,
  //         hidden: x.hidden,
  //         checkBoxProps: {
  //           checked: x.checkBoxProps.checked,
  //         },
  //         onClick: handleAdvFilterSegmentClick(x.value),
  //       };
  //     }),
  //   },
  //   spaceAndDimensionsSectionProps: {
  //     seatsNumberText: "Número de lugares",
  //     seatsNumberCheckboxProps: advFilterSeatsOptions,
  //     sliders: [
  //       {
  //         label: "Comprimento (mm)",
  //         measurementType: "mm",
  //         maxValue: vehicleMaxLengthDefaultValue,
  //         minValue: vehicleMinLengthDefaultValue,
  //         step: 100,
  //         values: [
  //           vehiclesMinLengthFilter,
  //           vehiclesMaxLengthFilter,
  //         ],
  //         valuesTextFieldProps: [
  //           {
  //             value: vehiclesMinLengthFilter,
  //             onChange: handleVehiclesMinLengthFilter,
  //           },
  //           {
  //             value: vehiclesMaxLengthFilter,
  //             onChange: handleVehiclesMaxLengthFilter,
  //           },
  //         ],
  //         onChange: handleVehiclesLengthBarFilterChange,
  //       },
  //       {
  //         label: "Largura (mm)",
  //         measurementType: "mm",
  //         maxValue: vehicleMaxWidthDefaultValue,
  //         minValue: vehicleMinWidthDefaultValue,
  //         values: [
  //           vehiclesMinWidthFilter,
  //           vehiclesMaxWidthFilter,
  //         ],
  //         valuesTextFieldProps: [
  //           {
  //             value: vehiclesMinWidthFilter,
  //             onChange: handleVehiclesMinWidthFilter,
  //           },
  //           {
  //             value: vehiclesMaxWidthFilter,
  //             onChange: handleVehiclesMaxWidthFilter,
  //           },
  //         ],
  //         onChange:handleVehiclesWidthBarFilterChange,
  //       },
  //       {
  //         label: "Altura (mm)",
  //         measurementType: "mm",
  //         maxValue: vehicleMaxHeightDefaultValue,
  //         minValue: vehicleMinHeightDefaultValue,
  //         values: [
  //           vehiclesMinHeightFilter,
  //           vehiclesMaxHeightFilter,
  //         ],
  //         valuesTextFieldProps: [
  //           {
  //             value: vehiclesMinHeightFilter,
  //             onChange: handleVehiclesMinHeightFilter,
  //           },
  //           {
  //             value: vehiclesMaxHeightFilter,
  //             onChange: handleVehiclesMaxHeightFilter,
  //           },
  //         ],
  //         onChange: handleVehiclesHeightBarFilterChange,
  //       },
  //       {
  //         label: "Distancia entre eixos dianteiros (mm)",
  //         measurementType: "mm",
  //         maxValue: vehicleMaxDistanceBetweenFrontAxlesDefaultValue,
  //         minValue: vehicleMinDistanceBetweenFrontAxlesDefaultValue,
  //         values: [
  //           vehiclesMinDistanceBetweenFrontAxlesFilter,
  //           vehiclesMaxDistanceBetweenFrontAxlesFilter,
  //         ],
  //         valuesTextFieldProps: [
  //           {
  //             value: vehiclesMinDistanceBetweenFrontAxlesFilter,
  //             onChange: handleVehiclesMinDistanceBetweenFrontAxlesFilter,
  //           },
  //           {
  //             value: vehiclesMaxDistanceBetweenFrontAxlesFilter,
  //             onChange: handleVehiclesMaxDistanceBetweenFrontAxlesFilter,
  //           },
  //         ],
  //         onChange:  handleVehiclesDistanceBetweenFrontAxlesBarFilterChange,
  //       },
  //       {
  //         label: "Distancia entre eixos traseiros (mm)",
  //         measurementType: "mm",
  //         maxValue: vehicleMaxDistanceBetweenRearAxlesDefaultValue,
  //         minValue: vehicleMinDistanceBetweenRearAxlesDefaultValue,
  //         values: [
  //           vehiclesMinDistanceBetweenRearAxlesFilter,
  //           vehiclesMaxDistanceBetweenRearAxlesFilter,
  //         ],
  //         valuesTextFieldProps: [
  //           {
  //             value: vehiclesMinDistanceBetweenRearAxlesFilter,
  //             onChange: handleVehiclesMinDistanceBetweenRearAxlesFilter,
  //           },
  //           {
  //             value: vehiclesMaxDistanceBetweenRearAxlesFilter,
  //             onChange: handleVehiclesMaxDistanceBetweenRearAxlesFilter,
  //           },
  //         ],
  //         onChange: handleVehiclesDistanceVetweenAxlesBarFilterChange,
  //       },
  //     ],
  //   },
  //   engineSpecsSectionProps: {
  //     sliders: [
  //       {
  //         label: "Potência (CV)",
  //         measurementType: "CV",
  //         maxValue: vehicleMaxPowerDefaultValue,
  //         minValue: vehicleMinPowerDefaultValue,
  //         step: 50,
  //         values: [
  //           vehiclesMinPowerFilter,
  //           vehiclesMaxPowerFilter,
  //         ],
  //         valuesTextFieldProps: [
  //           {
  //             value: vehiclesMinPowerFilter,
  //             onChange: handleVehiclesMinPowerFilter,
  //           },
  //           {
  //             value: vehiclesMaxPowerFilter,
  //             onChange: handleVehiclesMaxPowerFilter,
  //           },
  //         ],
  //         onChange: handleVehiclesPowerBarFilterChange,
  //       },
  //       {
  //         label: "Cilindrada Motor",
  //         measurementType: "CC",
  //         maxValue: vehicleMaxEngineCapacityDefaultValue,
  //         minValue: vehicleMinEngineCapacityDefaultValue,
  //         step: 50,
  //         values: [
  //           vehiclesMinEngineCapacityFilter,
  //           vehiclesMaxEngineCapacityFilter,
  //         ],
  //         valuesTextFieldProps: [
  //           {
  //             value: vehiclesMinEngineCapacityFilter,
  //             onChange: handleVehiclesMinEngineCapacityFilter,
  //           },
  //           {
  //             value: vehiclesMaxEngineCapacityFilter,
  //             onChange: handleVehiclesMaxEngineCapacityFilter,
  //           },
  //         ],
  //         onChange: handleVehiclesEngineCapacityBarFilterChange,
  //       },
  //       {
  //         label: "Binário Máximo",
  //         measurementType: "",
  //         maxValue: vehicleMaxMaxTorqueDefaultValue,
  //         minValue: vehicleMinMaxTorqueDefaultValue,
  //         step: 5,
  //         values: [
  //           vehiclesMinMaxTorqueFilter,
  //           vehiclesMaxMaxTorqueFilter,
  //         ],
  //         valuesTextFieldProps: [
  //           {
  //             value: vehiclesMinMaxTorqueFilter,
  //             onChange: handleVehiclesMinMaxTorqueFilter,
  //           },
  //           {
  //             value: vehiclesMaxMaxTorqueFilter,
  //             onChange: handleVehiclesMaxMaxTorqueFilter,
  //           },
  //         ],
  //         onChange: handleVehiclesTorqueBarFilterChange,
  //       },
  //       {
  //         label: "CO2 Emissão (Grama/Kg)",
  //         measurementType: "Grama/Kg",
  //         maxValue: vehicleMaxCO2EmissionDefaultValue,
  //         minValue: vehicleMinCO2EmissionDefaultValue,
  //         step: 1,
  //         values: [
  //           vehiclesMinCO2EmissionFilter,
  //           vehiclesMaxCO2EmissionFilter,
  //         ],
  //         valuesTextFieldProps: [
  //           {
  //             value: vehiclesMinCO2EmissionFilter,
  //             onChange: handleVehiclesMinCO2EmissionFilter,
  //           },
  //           {
  //             value: vehiclesMaxCO2EmissionFilter,
  //             onChange: handleVehiclesMaxCO2EmissionFilter,
  //           },
  //         ],
  //         onChange: handleVehiclesCO2EmissionbarFitlerChange,
  //       },
  //     ],
  //   },
  //   applyButtonText:!filterLoading && filterResults?.length === 0 && !actionFilter
  //     ? "Pesquisar"
  //     // : "Adicionar seleção",
  //     : filterResults?.length === 0 && actionFilter ? "Limpar filtros" :"Adicionar seleção" ,
  //   applyButtonProps: {
  //     onClick: filterDrawerPropsApplyButtonPropsClick,
  //     disabled: (!(!filterLoading && filterResults?.length === 0) && filterResults?.filter((x) => x.checkboxProps.checked === true)?.length===0),
  //   },
  //   clearAllButtonText: "Limpar todos os filtros", //TODO: implementar
  //   clearAllButtonProps: {
  //     onClick: resetAdvancedFilters,
  //   },
  //   expandAllButtonText: "Expandir tudo",
  //   collapseAllButtonText: "Diminuir tudo",
  //   appliedFilters: appliedFiltersProps,

  //   loadingText: "A procurar resultados para a sua pesquisa",
  //   resultsFoundNumber: advFilterResultsNumber.toString(),
  //   resultsFoundText: "Resultados",
  //   result: filterDrawerPropsFilterResults,

  //   selectVehiclesText:
  //     advFilterResultsNumber > 0
  //       ? "Selecione as viaturas que pretende adicionar às suas simulações"
  //       : "",
  // }),
  // [
  //   actionFilter,
  //   advFilterFuelTypeOptions,
  //   advFilterResultsNumber,
  //   advFilterSegmentsOptions,
  //   advFilterSegmentsSearchOptions,
  //   advFilterTypeStatusOptions,
  //   // advFilterSeats,
  //   brandsSectionProps,
  //   filterLoading,
  //   filterResults,
  //   // handleAdvFilterFuelTypeClick,
  //   handleAdvFilterSegmentClick,
  //   // handleAdvFilterTypeStatusClick,
  //   resetAdvancedFilters,
  //   vehicleMaxCO2EmissionDefaultValue,
  //   vehicleMaxDistanceBetweenFrontAxlesDefaultValue,
  //   vehicleMaxDistanceBetweenRearAxlesDefaultValue,
  //   vehicleMaxEngineCapacityDefaultValue,
  //   vehicleMaxGrossWeightDefaultValue,
  //   vehicleMaxHeightDefaultValue,
  //   vehicleMaxLengthDefaultValue,
  //   vehicleMaxMaxSuitcaseVolumeDefaultValue,
  //   vehicleMaxMaxTorqueDefaultValue,
  //   vehicleMaxPowerDefaultValue,
  //   vehicleMaxWidthDefaultValue,
  //   vehicleMinCO2EmissionDefaultValue,
  //   vehicleMinDistanceBetweenFrontAxlesDefaultValue,
  //   vehicleMinDistanceBetweenRearAxlesDefaultValue,
  //   vehicleMinEngineCapacityDefaultValue,
  //   vehicleMinGrossWeightDefaultValue,
  //   vehicleMinHeightDefaultValue,
  //   vehicleMinLengthDefaultValue,
  //   vehicleMinMaxSuitcaseVolumeDefaultValue,
  //   vehicleMinMaxTorqueDefaultValue,
  //   vehicleMinPowerDefaultValue,
  //   vehicleMinWidthDefaultValue,
  //   vehiclesMaxCO2EmissionFilter,
  //   vehiclesMaxDistanceBetweenFrontAxlesFilter,
  //   vehiclesMaxDistanceBetweenRearAxlesFilter,
  //   vehiclesMaxEngineCapacityFilter,
  //   vehiclesMaxGrossWeightFilter,
  //   vehiclesMaxHeightFilter,
  //   vehiclesMaxLengthFilter,
  //   vehiclesMaxMaxSuitcaseVolumeFilter,
  //   vehiclesMaxMaxTorqueFilter,
  //   vehiclesMaxPowerFilter,
  //   vehiclesMaxWidthFilter,
  //   vehiclesMinCO2EmissionFilter,
  //   vehiclesMinDistanceBetweenFrontAxlesFilter,
  //   vehiclesMinDistanceBetweenRearAxlesFilter,
  //   vehiclesMinEngineCapacityFilter,
  //   vehiclesMinGrossWeightFilter,
  //   vehiclesMinHeightFilter,
  //   vehiclesMinLengthFilter,
  //   vehiclesMinMaxSuitcaseVolumeFilter,
  //   vehiclesMinMaxTorqueFilter,
  //   vehiclesMinPowerFilter,
  //   vehiclesMinWidthFilter,

  //   filterDrawerPropsBrands,
  //   filterDrawerPropsApplyButtonPropsClick,
  //   filterDrawerPropsFilterResults,
  //   // handleRemoveAppliedFilter  appliedFilters, advFilterSeats,
  //   appliedFiltersProps,
  //   advFilterSeatsOptions,
  //   handleAllBrandsCheckboxProps,
  //   handleBrandsSearchChange,
  //   handleSegmentsSearch,
  //   handleVehiclesGrossWeightBarFilterChange,
  //   handleVehiclesMaxGrossWeightFilter,
  //   handleVehiclesMinGrossWeightFilter,
  //   handleVehiclesMinMaxSuitcaseVolumeFilter,
  //   handleVehiclesMaxMaxSuitcaseVolumeFilter,
  //   handleVehiclesSuitcaseVolumeBarFilterChange,
  //   handleVehiclesMinLengthFilter,
  //   handleVehiclesMaxLengthFilter,
  //   handleVehiclesLengthBarFilterChange,
  //   handleVehiclesMinWidthFilter,
  //   handleVehiclesMaxWidthFilter,
  //   handleVehiclesWidthBarFilterChange,
  //   handleVehiclesMinHeightFilter,
  //   handleVehiclesMaxHeightFilter,
  //   handleVehiclesHeightBarFilterChange,
  //   handleVehiclesMinDistanceBetweenFrontAxlesFilter,
  //   handleVehiclesMaxDistanceBetweenFrontAxlesFilter,
  //   handleVehiclesDistanceBetweenFrontAxlesBarFilterChange,
  //   handleVehiclesMinDistanceBetweenRearAxlesFilter,
  //   handleVehiclesMaxDistanceBetweenRearAxlesFilter,
  //   handleVehiclesDistanceVetweenAxlesBarFilterChange,

  //   handleVehiclesMinPowerFilter,
  //   handleVehiclesMaxPowerFilter,
  //   handleVehiclesPowerBarFilterChange,
  //   handleVehiclesMinEngineCapacityFilter,
  //   handleVehiclesMaxEngineCapacityFilter,
  //   handleVehiclesEngineCapacityBarFilterChange,
  //   handleVehiclesMinMaxTorqueFilter,
  //   handleVehiclesMaxMaxTorqueFilter,
  //   handleVehiclesTorqueBarFilterChange,
  //   handleVehiclesMinCO2EmissionFilter,
  //   handleVehiclesMaxCO2EmissionFilter,
  //   handleVehiclesCO2EmissionbarFitlerChange,
  // ]);
  // FIM FILTROS AVANÇADOS STEP 2

  const notesButtonProps = useMemo(() => ({
    onClick: handleNotesButtonClick,
  }), [handleNotesButtonClick]);

  //VEICULOS PRECONFIGURADOS
  const preConfiguredVehiclesModalProps = useMemo(() => ({
    title: 'Selecionar veículos pré configurados',
    handleClose: () => console.log(''),
    vehicleSearchResultProps: {
      label: 'Pesquise por matrícula ou veículo',
      placeholder: 'Pesquisar',
      options: preConfiguredVehiclesSearchOptions,
      onChange: handlePreConfiguredVehiclesSearchChange,
      value: preConfiguredVehiclesSearchValue,
    },
    addVehicleButtonText: 'Pesquisar',
    addVehicleButtonProps: { onClick: handlePreConfiguredVehiclesSearchClick },
    resultsFoundText: 'resultados encontrados',
    resultsFoundNumber: preConfiguredVehicles.filter(item => !item.hidden).length,
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Aplicar',
    confirmButtonProps: {
      onClick: handlePreConfiguredVehiclesConfirmClick
    },
    vehicles: preConfiguredVehicles.filter(item => !item.hidden)
  }),[
    preConfiguredVehiclesSearchOptions,
    preConfiguredVehiclesSearchValue,
    preConfiguredVehicles,
    handlePreConfiguredVehiclesSearchClick,
    handlePreConfiguredVehiclesSearchChange,
    handlePreConfiguredVehiclesConfirmClick
  ])

  //VEICULOS USADOS
  const usedVehiclesModalProps = useMemo(() => ({
    title: "Selecionar veículos usados",
    vehicleSearchResultProps: {
      label: "Pesquise por matrícula ou veículo",
      placeholder: "Pesquisar",
      options: usedVehicleSearchOptions,
      onChange: handleUsedVehiclesVehicleSearch,
    },
    addVehicleButtonText: "Pesquisar",
    addVehicleButtonProps: {
      onClick: handleUsedVehiclesAddVehicle,
    },
    resultsFoundText: "resultado encontrado",
    resultsFoundNumber: selectedVehicles.length,
    cancelButtonText: "Cancelar",
    handleClose: handleUsedVehiclesCloseModal,
    confirmButtonText: "Aplicar",
    confirmButtonProps: {
      onClick: handleUsedVehiclesConfirmClick,
    },
    vehicles: selectedVehicles.map((x: UsedVehicleDetails, index: number) => {
      return {
        icons: selectedVehicles[index].icons,
        checkboxProps: {
          onChange: handleCheckboxPropsOnChange(index, selectedVehicles),
        },
        name: x.name,
        characteristics: x.characteristics,
        licensePlate: x.licensePlate,
      };
    }),
  }), [
    handleCheckboxPropsOnChange,
    handleUsedVehiclesAddVehicle,
    handleUsedVehiclesCloseModal,
    handleUsedVehiclesConfirmClick,
    handleUsedVehiclesVehicleSearch,
    selectedVehicles,
    usedVehicleSearchOptions,
  ]);

  //CAMPANHA USADOS (Dialog)
  const usedCampaignVehiclesModalProps = useMemo(() => ({
    title: "Selecionar veículos de campanha usados",
    vehicleSearchResultProps: {
      label: "Pesquise por matrícula ou veículo",
      placeholder: "Pesquisar",
      options: usedVehicleSearchOptions,
      // onChange: handleCampaignVehicleSearch,
      onInputChange: handleCampaignVehicleSearchOnInputChange,
      freeSolo:true,
    },
    addVehicleButtonText: "Pesquisar",
    addVehicleButtonProps: {
      onClick: handleCampaignAddVehicle,
    },
    // resultsFoundText: "resultado encontrado",
    resultsFoundText: `de ${totalCampaignVehiclesAmount} resultados encontrados.`,
    resultsFoundNumber: selectedCampaignVehicles.length,
    cancelButtonText: "Cancelar",
    handleClose: handleCampaignCloseModal,
    confirmButtonText: "Aplicar",
    confirmButtonProps: {
      onClick: handleCampaignConfirmClick,
    },
    vehicles: selectedCampaignVehicles.map(
      (x: CampaignVehicleDetails, index: number) => {
        return {
          id: x.id,
          icons: VehicleUtilities.getFuelTypeIconFor(
            x?.details?.fuelType?.value
          ),
          checkboxProps: {
            onChange: handleCheckboxPropsOnChange(
              index,
              selectedCampaignVehicles
            ),
          },
          name: x.name,
          characteristics: x.characteristics,
          businessPrice: x.businessPrice,
          extras: x.extras,
          proformaPrice: x.proformaPrice,
          details: x.details,
        };
      }
    ),
    expandableText: "Abrir todos os detalhes",
    closeExpandableText: "Fechar todos os detalhes",
  }), [
    usedVehicleSearchOptions,
    selectedCampaignVehicles,
    totalCampaignVehiclesAmount,
    handleCampaignAddVehicle,
    handleCampaignCloseModal,
    // handleCampaignVehicleSearch,
    handleCampaignVehicleSearchOnInputChange,
    handleCampaignConfirmClick,
    handleCheckboxPropsOnChange,
  ]);

  //CAMPANHA NOVOS (Dialog)
  const newCampaignVehiclesModalProps = useMemo(() => ({
    title: "Selecionar veículos de campanha novos",
    vehicleSearchResultProps: {
      label: "Pesquise por matrícula ou veículo",
      placeholder: "Pesquisar",
      options: usedVehicleSearchOptions,
      // onChange: handleCampaignVehicleSearch,
      onInputChange: handleCampaignVehicleSearchOnInputChange,
      freeSolo:true,
    },

    addVehicleButtonText: "Pesquisar",
    addVehicleButtonProps: {
      onClick: handleCampaignAddVehicle,
    },
    resultsFoundText: `de ${totalCampaignVehiclesAmount} resultados encontrados.`,
    resultsFoundNumber: selectedCampaignVehicles.length,
    cancelButtonText: "Cancelar",
    handleClose: handleCampaignCloseModal,
    confirmButtonText: "Aplicar",
    confirmButtonProps: {
      onClick: handleCampaignConfirmClick,
    },
    vehicles: selectedCampaignVehicles.map(
      (x: CampaignVehicleDetails, index: number) => {
        return {
          id: x.id,
          icons: VehicleUtilities.getFuelTypeIconFor(
            x?.details?.fuelType?.value
          ),
          checkboxProps: {
            onChange: handleCheckboxPropsOnChange(
              index,
              selectedCampaignVehicles
            ),
          },
          name: x.name,
          characteristics: x.characteristics,
          businessPrice: x.businessPrice,
          extras: x.extras,
          proformaPrice: x.proformaPrice,
          details: x.details,
        };
      }
    ),
    expandableText: "Abrir todos os detalhes",
    closeExpandableText: "Fechar todos os detalhes",
  }), [
    usedVehicleSearchOptions,
    selectedCampaignVehicles,
    totalCampaignVehiclesAmount,
    handleCampaignAddVehicle,
    handleCampaignCloseModal,
    // handleCampaignVehicleSearch,
    handleCampaignVehicleSearchOnInputChange,
    handleCampaignConfirmClick,
    handleCheckboxPropsOnChange,
  ]);

  const bottomBarProps = useMemo(() => ({
    cancelButtonProps: {
      onClick: handleCancelSimulationClick,
      disabled: proposal.isClientApproved || simulationLoading===true,
    },
    cancelText: "Cancelar simulação",
    extraButtonText: "Calcular",
    extraButtonProps: {
      hidden: proposal?.leaseQuoteNo === '' && step !== 4,// || proposal?.leaseQuoteNo === undefined,// step !== 4,
      disabled: simulationLoading=== true,
      onClick: onCalculateClick,
    },
    backwardText: "Voltar",
    backwardButtonProps: {
      disabled: step === 0 || simulationLoading=== true,
      onClick: handleBackward,
    },
    forwardText:
      step === 4
        ? "Enviar para o cliente"
        : step === 3 && !leaseQuoteCreatedInBC
        ? "Simular"
        : "Avançar",
    forwardButtonProps: {
      disabled: 
        simulationLoading=== true || 
        (step === 0 && proposal.contactId === "" && isNewClient === false)  || 
        (proposal?.leaseQuoteNo !== '' && step === 4 && (changedProposal === true || contractSelectedRows?.length<=0)) ||
        (proposal?.leaseQuoteNo === '' && step === 4)
          ? true
          : false,
      onClick: handleForward,
    },
  }), [
    handleBackward, handleForward, step, isNewClient, proposal,
    leaseQuoteCreatedInBC, handleCancelSimulationClick, onCalculateClick,
    changedProposal,
    simulationLoading,
    contractSelectedRows.length
  ]);

  const otherChaDropdownOptions = useMemo(() => settings.Data.Vehicles_UseProps, [settings.Data.Vehicles_UseProps]);
  // ISTO É O MESMO QUE O QUE ESTÁ EM BAIXO COMENTADO
  // const otherChaDropdownOptions = useMemo(() => {
  //   return settings.Data.Vehicles_UseProps
  // }, [settings.Data.Vehicles_UseProps]);

  const groupEditButtonDisabled = useMemo(() =>
    selectedRows.length === 0 ,
  [selectedRows]);

  const contractSettingGroupEditButtonDisabled = useMemo(() =>
    contractSelectedRows.length === 0 || (contractSelectedRows.length === 1 && contractSelectedRows[0].vehicleIndex === -1 && contractSelectedRows[0].simulationIndex === -1),
  [contractSelectedRows]);

  const groupSimulationEditButtonDisabled = useMemo(
    () => contractSelectedRows.length === 0,
  [contractSelectedRows.length]);


  const handleContractSettingsDuplicateVehicleClick= useCallback((vehicleIndex:number, simulationIndex:number) => {
    const simulationDuplicate = vehicleDataTable[vehicleIndex].simulations[simulationIndex] as any;
    var tempSimulationDuplicate = JSON.parse(
      JSON.stringify(simulationDuplicate)
    );

    let vehicle = vehicleDataTable;
    tempSimulationDuplicate.id = 0;
    tempSimulationDuplicate.no = "";
    vehicle[vehicleIndex].simulations.push(
      tempSimulationDuplicate
    );
    let totalQuantity=0;
    vehicle[vehicleIndex].simulations.map((sim) => {
      totalQuantity+=Number(sim.quantity)
      return totalQuantity;
    })

    setChangedProposal(true);
    setVehicleDataTable((rows) =>
      rows.map((cur, index) =>
        index === vehicleIndex
          ? {
              ...cur,
              simulations: vehicle[index].simulations,
              quantity:totalQuantity
            }
          : cur
      )
    );
  },[vehicleDataTable])

  const handleContractSettingsApplyToAllVehicleClick = useCallback(async (vehicleIndex:number, simulationIndex:number) => {
    let tmpInsuranceSet = new Set(insuranceFrameworkContractSet);

    let simulation = vehicleDataTable[vehicleIndex].simulations[simulationIndex]
    for (let i = 0; i < vehicleDataTable.length; i++) {
      if (i !== vehicleIndex) {
        let tmpSimulation = JSON.parse(JSON.stringify(simulation));
        tmpSimulation.id = 0;
        tmpSimulation.no = "";

        if(
          vehicleDataTable[i].versionId !== vehicleDataTable[vehicleIndex].versionId ||
          vehicleDataTable[i].typeofIncome !== vehicleDataTable[vehicleIndex].typeofIncome ||
          vehicleDataTable[i].typeofRental !== vehicleDataTable[vehicleIndex].typeofRental ||
          (vehicleDataTable[i].typeofRental === vehicleDataTable[vehicleIndex].typeofRental &&
          vehicleDataTable[i].commercialConditions !== vehicleDataTable[vehicleIndex].commercialConditions)
        ) {
          var tmpSimulationDefault:Simulation = JSON.parse(
            JSON.stringify(vehicleDataTable[i].simulations[0])
          );
          tmpSimulationDefault.id = 0;
          tmpSimulationDefault.no = "";
          tmpSimulationDefault.baseIncomeWithoutTax = 0;
          tmpSimulationDefault.incomeWithoutTax = 0;
          tmpSimulationDefault.incomeWithTax = 0;
          tmpSimulationDefault.tires = tmpSimulation.tires;
          tmpSimulationDefault.tiresQuantity = tmpSimulation.tiresQuantity;
          tmpSimulationDefault.maintenance = tmpSimulation.maintenance;
          tmpSimulationDefault.baseVRTable = tmpSimulation.baseVRTable;
          tmpSimulationDefault.realVR = tmpSimulation.realVR;
          tmpSimulationDefault.vrTable = tmpSimulation.vrTable;
          tmpSimulationDefault.vrEurotax = tmpSimulation.vrEurotax;
          tmpSimulationDefault.quantity = tmpSimulation.quantity;
          tmpSimulationDefault.deadline = tmpSimulation.deadline;
          tmpSimulationDefault.miles = tmpSimulation.miles;

          tmpSimulationDefault.vehicleReplacementOptions = tmpSimulation.vehicleReplacementOptions;
          tmpSimulationDefault.vsAccidentVehicle = tmpSimulation.vsAccidentVehicle;
          tmpSimulationDefault.vsAccidentVehicleType = tmpSimulation.vsAccidentVehicleType;
          tmpSimulationDefault.vsAssistanceVehicle = tmpSimulation.vsAssistanceVehicle;
          tmpSimulationDefault.vsAssistanceVehicleType = tmpSimulation.vsAssistanceVehicleType;
          tmpSimulationDefault.vsTheftVehicle = tmpSimulation.vsTheftVehicle;
          tmpSimulationDefault.vsTheftVehicleType = tmpSimulation.vsTheftVehicleType;
          tmpSimulationDefault.vsOverhaulVehicle = tmpSimulation.vsOverhaulVehicle;
          tmpSimulationDefault.vsOverhaulVehicleType = tmpSimulation.vsOverhaulVehicleType;
          tmpSimulationDefault.vehicleType = tmpSimulation.vehicleType;

          let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x =>
            x.commercialConditions === vehicleDataTable[i].commercialConditions &&
            x.typeofRental === vehicleDataTable[i].typeofRental
          )
          const insurance = tmpSimulationDefault.insurance;

          if(insuranceFrameworkContractOption && insurance !== ''){
            //se o seguro da simulação a copiar existir no contrato de quadro da viatura para a qual vamos copiar a simulação usamos esse seguro senão usa o seguro pré-carregado
            let insurancePackageOption = insuranceFrameworkContractOption.insurancePackageOptions.find(x => x.insurance === tmpSimulation.insurance) ?? insuranceFrameworkContractOption.insurancePackageOptions.find(x => x.insurance === insurance)
            if(insurancePackageOption){
              tmpSimulationDefault.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
              tmpSimulationDefault.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
              tmpSimulationDefault.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
              tmpSimulationDefault.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

              tmpSimulationDefault.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
              tmpSimulationDefault.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
              tmpSimulationDefault.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
              tmpSimulationDefault.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
              tmpSimulationDefault.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

              tmpSimulationDefault.fuelOptions = insurancePackageOption.fuelOptions;
              tmpSimulationDefault.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

              tmpSimulationDefault.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions;
              tmpSimulationDefault.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions;
              tmpSimulationDefault.occupiersOptions = insurancePackageOption.occupiersOptions;
              tmpSimulationDefault.liabilityOptions = insurancePackageOption.liabilityOptions;
              tmpSimulationDefault.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions;

              tmpSimulationDefault.responsibility = insurancePackageOption.responsibility;
              tmpSimulationDefault.ownDamage = insurancePackageOption.ownDamage;
              tmpSimulationDefault.occupants = insurancePackageOption.occupants;
              tmpSimulationDefault.travelAssistance = insurancePackageOption.travelAssistance;
              tmpSimulationDefault.glassBreakage = insurancePackageOption.glassBreakage;

              tmpSimulationDefault.insurance = insurancePackageOption.insurance;
              // tmpSimulationDefault.secureOptions = insurancePackageOption.secureOptions;
              // tmpSimulationDefault.insurancePackageOptions = insuranceFrameworkContractOption.insurancePackageOptions.map(x => x.insurancePackageOption);
            }
          }
          vehicleDataTable[i].simulations.push(tmpSimulationDefault);
          let totalQuantity=0;
          vehicleDataTable[i].simulations.map((sim) => {
            totalQuantity+=Number(sim.quantity)
            return totalQuantity;
          })
          vehicleDataTable[i].quantity = totalQuantity;
        }
        else {
          vehicleDataTable[i].simulations.push(
            tmpSimulation
          );
          let totalQuantity = 0;
          vehicleDataTable[i].simulations.map((sim) => {
            totalQuantity += Number(sim.quantity)
            return totalQuantity;
          })
          vehicleDataTable[i].quantity = totalQuantity;
        }

        // await initVehicleReplacementOptionsDropDown(
        //     vehicleDataTable[i].simulations[vehicleDataTable[i].simulations.length - 1],
        //     vehicleDataTable[i].commercialConditions,true
        // ); //TODO:Rever mapeamento se pack seguro
        // await initSelectedInsuranceDropDown(
        //   vehicleDataTable[i].simulations[vehicleDataTable[i].simulations.length - 1],
        //     vehicleDataTable[i].use,true
        // );


      }
    }
    setChangedProposal(true);
    setVehicleDataTable([...vehicleDataTable]);
  },[
      insuranceFrameworkContractSet,
      vehicleDataTable,
  ])

  const handleContractSettingsRemoveVehicleClick = useCallback((vehicleIndex:number, simulationIndex:number) => {
    // let vehicle = vehicleDataTable;
    // const simulation = vehicle[vehicleIndex].simulations[
    //   simulationIndex
    // ] as any;
    // const simulationId = (simulation as any).id;
    // if(simulationId > 0){
    //   vehiclesService
    //     .deleteSimulation(simulationId)
    //     .then(() => {});
    // }
    // vehicle[vehicleIndex].simulations.splice(
    //   simulationIndex,
    //   1
    // );
    // setVehicleDataTable((rows) =>
    //   rows.map((cur, index) =>
    //     index === vehicleIndex
    //       ? {
    //           ...cur,
    //           simulations: vehicle[index].simulations,
    //         }
    //       : cur
    //   )
    // );

    setChangedProposal(true);
    setVehicleDataTable((rows) =>
      rows.map((cur, index) => {
        if (index === vehicleIndex){
          let updatedSimulations = cur.simulations.filter((sim, simIndex) => {
            if (simIndex === simulationIndex && sim.id > 0) {
              vehiclesService.deleteSimulation(sim.id)
              return false;
            }
            else if(simIndex === simulationIndex && sim.id === 0) {
              return false;
            }
            else {
              return true
            }
          })
          let totalQuantity=0;
          updatedSimulations.map((sim)=>{
            totalQuantity+=Number(sim.quantity)
            return totalQuantity;
          })
          cur.quantity=totalQuantity;
          cur.simulations=updatedSimulations;
          return cur
        } else {
          return cur
        }
      })
    );
  },[])


  const handleSimulationsCheckboxChangeCallback = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let indexes = e.target.name.split('-');
    let vehicleIndex = Number(indexes[0]);
    let simulationIndex = Number(indexes[1]);
    let targetName = indexes[2];

    setChangedProposal(true);
    setVehicleDataTable((rows) =>
      rows.map((row, index) => {
        if (index !== vehicleIndex) {
          return row;
        }
        let vehicle = row;
        let simulationSelect = vehicle.simulations[simulationIndex];
        let checked = false;

        if ( e.target.value === 'no'  /* isCheckedTrue === false */ && Boolean(e.target.value) === true)
          checked = false;
        else if (
          e.target.value === 'yes'
          /* isCheckedTrue === true */ &&
          Boolean(e.target.value) === true
        ) {
          checked = true;
        } else checked = Boolean(e.target.value);

        if (
          checked === true &&
          targetName === "secureOptions" &&
          vehicle.simulations[simulationIndex].insurance === ""
        ) {
          openAlert({
            variant: "error",
            text: "É necessário selecionar um tipo de seguro!",
            title: "Alerta",
          });
          vehicle.simulations[simulationIndex] = {
            ...simulationSelect,
            [targetName]: false,
          };
        }

        else if (checked === false && targetName === "secureOptions") {
          vehicle.simulations[simulationIndex] = {
            ...simulationSelect,
            ownDamage: "",
            occupants: "",
            travelAssistance: "",
            responsibility: "",
            glassBreakage: "",
            [targetName]: checked,
            insurance: checked? row.insurance:"",
          };
        } else if (
          checked === false &&
          targetName === "vehicleReplacementOptions"
        ) {
          vehicle.simulations[simulationIndex] = {
            ...simulationSelect,
            vsTheftVehicleType: "",
            vsTheftVehicle: "",
            vsAccidentVehicleType: "",
            vsAccidentVehicle: "",
            vsAssistanceVehicleType: "",
            vsAssistanceVehicle: "",
            vsOverhaulVehicleType: "",
            vsOverhaulVehicle: "",
            vehicleType: "",
            [targetName]: checked,
          };
        } else if (checked === false && targetName === "fuelCard") {
          vehicle.simulations[simulationIndex] = {
            ...simulationSelect,
            fuelDescriptiom: "",
            [targetName]: checked,
          };
        } else if (checked === false && targetName === "viaVerde") {
          vehicle.simulations[simulationIndex] = {
            ...simulationSelect,
            entityViaVerde: "",
            [targetName]: checked,
          };
        } else if (targetName === "tires" && checked === false) {
          vehicle.simulations[simulationIndex] = {
            ...simulationSelect,
            tiresQuantity: "0",
            [targetName]: checked,
          };
        } else {
          vehicle.simulations[simulationIndex] = {
            ...simulationSelect,
            [targetName]: checked,
          };
        }
        return { ...row, vehicle };
      })
    );
    },[]
  );

  // callbacks de mapeamento de tooltips para os resumos das opções de seguro, manutenção e viaturas de substituição
  const handleInsuranceSummary = useCallback((simulation: Simulation) => {
    let summary = 'Resumo das opções de Seguro:';
    if(simulation.secureOptions){
      // dano proprio
      if(simulation.ownDamage && simulation.ownDamage !== '' && simulation.vehicleDamageOptions && simulation.vehicleDamageOptions.length > 0
        && (currentUser.isAdministrator || currentUser.profile?.profileVehicleDamages.some(pi => pi.value === simulation.ownDamage))
      ){
        summary += `\n ${simulation.ownDamage} - ${simulation.vehicleDamageOptions.find(x => x.value === simulation.ownDamage)?.label}`
      }
      //ocupantes
      if(simulation.occupants && simulation.occupants !== '' && simulation.occupiersOptions && simulation.occupiersOptions.length > 0){
        summary += `\n ${simulation.occupants} - ${simulation.occupiersOptions.find(x => x.value === simulation.occupants)?.label}`
      }
      //assistencia em viagem
      if(simulation.travelAssistance && simulation.travelAssistance !== '' && simulation.roadAssistanceOptions && simulation.roadAssistanceOptions.length > 0){
        summary += `\n ${simulation.travelAssistance} - ${simulation.roadAssistanceOptions.find(x => x.value === simulation.travelAssistance)?.label}`
      }
      //responsabilidade civil
      if(simulation.responsibility && simulation.responsibility !== '' && simulation.liabilityOptions && simulation.liabilityOptions.length > 0){
        summary += `\n ${simulation.responsibility} - ${simulation.liabilityOptions.find(x => x.value === simulation.responsibility)?.label}`
      }
      //quebra isolada de vidros
      if(simulation.glassBreakage && simulation.glassBreakage !== '' && simulation.isolatedGlassBreakOptions && simulation.isolatedGlassBreakOptions.length > 0){
        summary += `\n ${simulation.glassBreakage} - ${simulation.isolatedGlassBreakOptions.find(x => x.value === simulation.glassBreakage)?.label}`
      }
      //cartão de combustível
      if(simulation.fuelDescriptiom && simulation.fuelDescriptiom !== '' && simulation.fuelOptions && simulation.fuelOptions.length > 0){
        summary += `\n ${simulation.fuelDescriptiom} - ${simulation.fuelOptions.find(x => x.value === simulation.fuelDescriptiom)?.label}`
      }
      //via verde
      if(simulation.entityViaVerde && simulation.entityViaVerde !== '' && simulation.viaVerdeOptions && simulation.viaVerdeOptions.length > 0){
        summary += `\n ${simulation.entityViaVerde} - ${simulation.viaVerdeOptions.find(x => x.value === simulation.entityViaVerde)?.label}`
      }
    }
    // const summaryList = summary.split('\n').map((item, index) => <li key={index}>{item}</li>);
    // return (<ul>{summaryList}</ul>)
    // pedir à hi-interactive para usar a tag <pre></pre> nestes tooltips
    return summary;
  },[currentUser.isAdministrator, currentUser.profile?.profileVehicleDamages]);

  const handleMaintenanceSummary = useCallback((simulation: Simulation) => {
    let summary = 'Resumo das opções de Manutenção:';
    if(simulation.maintenance){
      // Manutenção - simulation.maintenanceDescription
      if(simulation.maintenanceDescription && simulation.maintenanceDescription !== '' ){
        summary += `\n ${simulation.maintenanceDescription}`
      }
    }
    // const summaryList = summary.split('\n').map((item, index) => <li key={index}>{item}</li>);
    // return (<ul>{summaryList}</ul>)
    // pedir à hi-interactive para usar a tag <pre></pre> nestes tooltips
    return summary;
  },[]);

  const handleVsSummary = useCallback((simulation: Simulation) => {
    let summary = 'Resumo das opções de Viaturas de substituição:';
    // if(simulation && simulation.vehicleReplacementOptions){

      // configuração - simulation.vehicleType
      if( simulation.vehicleType && simulation.vehicleType !== '' && simulation.vehicleTypeOptions && simulation.vehicleTypeOptions.length > 0){
        summary += `\n ${simulation.vehicleType} - ${simulation.vehicleTypeOptions.find(x => x.value === simulation.vehicleType)?.label}`;
      }

      // Revisão
      // tipo - simulation.vsOverhaulVehicle
      if(simulation.vsOverhaulVehicle && simulation.vsOverhaulVehicle !== '' && simulation.vsOverhaulVehicleOptions && simulation.vsOverhaulVehicleOptions.length > 0){
        summary += `\n ${simulation.vsOverhaulVehicle} - ${simulation.vsOverhaulVehicleOptions.find(x => x.value === simulation.vsOverhaulVehicle)?.label}`;
      }
      // viatura - simulation.vsOverhaulVehicleType
      if(simulation.vsOverhaulVehicleType && simulation.vsOverhaulVehicleType !== '' && simulation.vsOverhaulVehicleTypeOptions && simulation.vsOverhaulVehicleTypeOptions.length > 0){
        summary += `\n ${simulation.vsOverhaulVehicleType} - ${simulation.vsOverhaulVehicleTypeOptions.find(x => x.value === simulation.vsOverhaulVehicleType)?.label}`;
      }

      // Avaria
      // tipo - simulation.vsAssistanceVehicle
      if(simulation.vsAssistanceVehicle && simulation.vsAssistanceVehicle !== '' && simulation.vsAssistanceVehicleOptions && simulation.vsAssistanceVehicleOptions.length > 0){
        summary += `\n ${simulation.vsAssistanceVehicle} - ${simulation.vsAssistanceVehicleOptions.find(x => x.value === simulation.vsAssistanceVehicle)?.label}`;
      }
      // viatura - simulation.vsAssistanceVehicleType
      if(simulation.vsAssistanceVehicleType && simulation.vsAssistanceVehicleType !== '' && simulation.vsAssistanceVehicleTypeOptions && simulation.vsAssistanceVehicleTypeOptions.length > 0){
        summary += `\n ${simulation.vsAssistanceVehicleType} - ${simulation.vsAssistanceVehicleTypeOptions.find(x => x.value === simulation.vsAssistanceVehicleType)?.label}`;
      }

      // Sinistro
      // tipo - simulation.vsAccidentVehicle
      if(simulation.vsAccidentVehicle && simulation.vsAccidentVehicle !== '' && simulation.vsAccidentVehicleOptions && simulation.vsAccidentVehicleOptions.length > 0){
        summary += `\n ${simulation.vsAccidentVehicle} - ${simulation.vsAccidentVehicleOptions.find(x => x.value === simulation.vsAccidentVehicle)?.label}`;
      }
      // viatura - simulation.vsAccidentVehicleType
      if(simulation.vsAccidentVehicleType && simulation.vsAccidentVehicleType !== '' && simulation.vsAccidentVehicleTypeOptions && simulation.vsAccidentVehicleTypeOptions.length > 0){
        summary += `\n ${simulation.vsAccidentVehicleType} - ${simulation.vsAccidentVehicleTypeOptions.find(x => x.value === simulation.vsAccidentVehicleType)?.label}`;
      }

      // Roubo
      // tipo - simulation.vsTheftVehicle
      if(simulation.vsTheftVehicle && simulation.vsTheftVehicle !== '' && simulation.vsTheftVehicleOptions && simulation.vsTheftVehicleOptions.length > 0){
        summary += `\n ${simulation.vsTheftVehicle} - ${simulation.vsTheftVehicleOptions.find(x => x.value === simulation.vsTheftVehicle)?.label}`;
      }
      // viatura - simulation.vsTheftVehicleType
      if(simulation.vsTheftVehicleType && simulation.vsTheftVehicleType !== '' && simulation.vsTheftVehicleTypeOptions && simulation.vsTheftVehicleTypeOptions.length > 0){
        summary += `\n ${simulation.vsTheftVehicleType} - ${simulation.vsTheftVehicleTypeOptions.find(x => x.value === simulation.vsTheftVehicleType)?.label}`;
      }
    // }
    // const summaryList = summary.split('\n').map((item, index) => <li key={index}>{item}</li>);
    // return (<ul>{summaryList}</ul>)
    // pedir à hi-interactive para usar a tag <pre></pre> nestes tooltips
    return summary;
  },[]);

  const summariesMemo = useMemo(() => {
    if(vehicleDataTable.length > 0){
      return vehicleDataTable.map(row =>
        row.simulations.map(simulation => {
          return handleVsSummary(simulation)
        })
      )
    }
    return undefined
  },[vehicleDataTable,handleVsSummary])

  const handleSimulationsChangeCallback = useCallback(async (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let indexes = e.target.name.split('-');
    let vehicleIndex = Number(indexes[0]);
    let simulationIndex = Number(indexes[1]);
    let targetName = indexes[2];

    let updatedSimulation = {...vehicleDataTable[vehicleIndex].simulations[simulationIndex]};

    if(targetName === "vehicleType") {
      let segmentTypeVehicle = vehicleDataTable[vehicleIndex].group_Price_Map;
      let data = await vehiclesService.GetReplacVehicleAssignSetup(e.target.value);
      await Promise.all(settings.Data.ReplacementVehicleAssignment_Type[0].result.map(async element => {
        let dataRow = data.filter((x: { type: string; }) => x.type === element.label)
        switch(element.value) {
          case "0":
              await initReplacVehicleAssign(dataRow, segmentTypeVehicle).then((replaceVal)=>{
                updatedSimulation = {
                  ...updatedSimulation,
                  vsOverhaulVehicle: replaceVal,
                  vsOverhaulVehicleType: dataRow?.length > 0 ? dataRow[0].vehicle_Type : "",//group_Price_Map:"",
                };
              })
            break;
          case "1":
            await initReplacVehicleAssign(dataRow, segmentTypeVehicle).then((replaceVal)=>{
              updatedSimulation = {
                  ...updatedSimulation,
                  vsAssistanceVehicle: replaceVal,
                  vsAssistanceVehicleType: dataRow?.length > 0 ? dataRow[0].vehicle_Type : "",//.group_Price_Map:""
                };
            })
            break;
          case "2":
            await initReplacVehicleAssign(dataRow, segmentTypeVehicle).then((replaceVal)=>{
              updatedSimulation = {
                  ...updatedSimulation,
                  vsAccidentVehicle: replaceVal,
                  vsAccidentVehicleType: dataRow?.length > 0 ? dataRow[0].vehicle_Type : "",//.group_Price_Map:""
                };
            })
            break;
          case "3":
            await initReplacVehicleAssign(dataRow, segmentTypeVehicle).then((replaceVal)=>{
              updatedSimulation = {
                  ...updatedSimulation,
                  vsTheftVehicle: replaceVal,
                  vsTheftVehicleType: dataRow?.length > 0 ? dataRow[0].vehicle_Type : "",//.group_Price_Map:""
                };
            })
            break;
        }
      })).then(() => {
        updatedSimulation =  { ...updatedSimulation, [targetName]: e.target.value}
      })
    } else if (targetName === "runFlaat") {
      updatedSimulation =  { ...updatedSimulation, [targetName]: (e.target as any).checked }
    } else {
      updatedSimulation =  { ...updatedSimulation, [targetName]: e.target.value}
    }

    setVehicleDataTable(rows => {
      let updatedRows = [...rows];
      let updatedVehicleRow = updatedRows.find((row, index) => index === vehicleIndex);
      if( updatedVehicleRow ){
        updatedVehicleRow.simulations = updatedVehicleRow.simulations.map((sim, simIndex) => {
          if(simIndex === simulationIndex) {
            sim = updatedSimulation;
          }
          return sim;
        })
        updatedRows[vehicleIndex] = updatedVehicleRow;
      }
      return updatedRows;
    })

    setChangedProposal(true);
  },[
    vehicleDataTable,
    initReplacVehicleAssign,
    settings.Data.ReplacementVehicleAssignment_Type
  ]);

  const handleDeadlineCallback = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let indexes = e.target.id.split(',')
    setChangedProposal(true);
    setVehicleDataTable(rows => {
      return rows.map((row, index) => {
        if (index === Number(indexes[0])) {
          return {
            ...row,
            simulations: row.simulations.map((sim, simIndex) => {
              if (simIndex === Number(indexes[1])) {
                sim.deadline = convertNumber(e.target.value)
                return sim
              } else {
                return sim;
              }
            })
          };
        }
        return row;
      });
    });
  },[])

  const handleMileageCallback = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let indexes = e.target.id.split(',')
    setChangedProposal(true);
    setVehicleDataTable(rows => {
      return rows.map((row, index) => {
        if (index === Number(indexes[0])) {
          return {
            ...row,
            simulations: row.simulations.map((sim, simIndex) => {
              if (simIndex === Number(indexes[1])) {
                sim.miles = convertNumber(e.target.value)
                let round = convertNumber((convertNumber(e.target.value)/settings.Data.Vehicle_TyresValueCalc) * row.number_of_wheels);
                let tiresQuant = 0;
                if( Math.ceil(round)%2 === 0 ) {
                  tiresQuant = Math.ceil(round);
                }
                else {
                  tiresQuant = Math.floor(round);
                }

                sim.tiresQuantity = tiresQuant.toString()
                return sim
              } else {
                return sim;
              }
            })
          };
        }
        return row;
      });
    });
  },[settings.Data.Vehicle_TyresValueCalc])

  //VALIDAR COMO EVITAR ESTA RERENDIREZAÇÃO
  const handleInsuranceCallback = useCallback(async(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let name = (e.target as any).name.split('+')[0];
    let indexes = (e.target as any).name.split('+')[1].split(',');
    let vehicleIndex = Number(indexes[0]);
    let simulationIndex = Number(indexes[1]);

    let vehicle = vehicleDataTable[vehicleIndex]
    let simulation = vehicle.simulations[simulationIndex];
    simulation.insurance = e.target.value;
    simulation.secureOptions=true;

    // simulation = await initSelectedInsuranceDropDown(simulation, vehicle.use,true);
    let tmpInsuranceSet = new Set(insuranceFrameworkContractSet)
    let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.typeofRental === vehicle.typeofRental && x.commercialConditions === vehicle.commercialConditions)
    let insurancePackageOption = insuranceFrameworkContractOption?.insurancePackageOptions.find(x => x.insurance === simulation.insurance)

    setChangedProposal(true);
    // setVehicleDataTable(rows => rows.map((row, index) => ({
    //     ...row,
    //     simulations: index === vehicleIndex
    //       ? row.simulations.map((sim, simIndex) => simIndex === simulationIndex ? simulation : sim )
    //       : row.simulations
    //   })
    // ));
    setVehicleDataTable(rows => rows.map((row, index) => {
      if(index !== vehicleIndex) {
        return row;
      } else {
        row.simulations.map((sim,simIndex) => {
          if(simIndex !== simulationIndex){
            return sim;
          } else {
            if(insurancePackageOption){
              sim.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
              sim.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
              sim.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
              sim.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

              sim.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
              sim.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
              sim.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
              sim.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
              sim.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

              sim.fuelOptions = insurancePackageOption.fuelOptions;
              sim.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;

              sim.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions;
              sim.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions;
              sim.occupiersOptions = insurancePackageOption.occupiersOptions;
              sim.liabilityOptions = insurancePackageOption.liabilityOptions;
              sim.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions;

              sim.responsibility = insurancePackageOption.responsibility;
              sim.ownDamage = insurancePackageOption.ownDamage;
              sim.occupants = insurancePackageOption.occupants;
              sim.travelAssistance = insurancePackageOption.travelAssistance;
              sim.glassBreakage = insurancePackageOption.glassBreakage;

              // sim.insurance = insurancePackageOption.insurance;
              // sim.secureOptions= insurancePackageOption.secureOptions;
            }
            return sim
          }
        })
        return row
      }

    }))
  },[vehicleDataTable,insuranceFrameworkContractSet]);

  const handleTyresQuantityCallback = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let indexes = (e.target as any).name.split('+')[1].split(',');

    setChangedProposal(true);
    setVehicleDataTable(rows => rows.map((row, index) => ({
      ...row,
      simulations: index === Number(indexes[0])
        ? row.simulations.map((sim, simIndex) => ({
            ...sim,
            tiresQuantity: (simIndex === Number(indexes[1]) && sim.tires===true) ? e.target.value : sim.tiresQuantity
          }))
        : row.simulations
    })));

  },[]);

  //handle para penus manutenção e vs
  const handleBooleanDropdownCallback = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //exemplo completo - este caso não necessita de ter a dependencia incluida
    let name = (e.target as any).name.split('+')[0];
    let indexes = (e.target as any).name.split('+')[1].split(',');

    setChangedProposal(true);

    setVehicleDataTable(rows => {
      return rows.map((row, index) => {
        if (index === Number(indexes[0])) {
          return {
            ...row,
            simulations: row.simulations.map((sim:Simulation, simIndex) => {
              if(name === "vehicleReplacementOptions" && simIndex === Number(indexes[1])){
                sim.vehicleReplacementOptions = e.target.value === "true"; // se e.target ==='true' devolve booleano true senão devolve boolean false
                if( !(e.target.value === "true")) {
                  sim.vsTheftVehicleType = "";
                  sim.vsTheftVehicle = "";
                  sim.vsAccidentVehicleType = "";
                  sim.vsAccidentVehicle = "";
                  sim.vsAssistanceVehicleType = "";
                  sim.vsAssistanceVehicle = "";
                  sim.vsOverhaulVehicleType = "";
                  sim.vsOverhaulVehicle = "";
                  sim.vehicleType = "";
                }
              }
              else if(name === "tires"  && simIndex === Number(indexes[1])){
                sim.tires = e.target.value === "true"; // se e.target ==='true' devolve booleano true senão devolve boolean false
                if(!(e.target.value === "true")){
                  sim.tiresQuantity = "0";
                }
              }
              else if (simIndex === Number(indexes[1])) {
                return {...sim, [name]: e.target.value === "true"}; // se e.target ==='true' devolve booleano true senão devolve boolean false
              }
              else {
                return sim;
              }
              return sim;
            })
          };
        }
        return row;
      });
    });
  },[])

  const contractSettingsGroupEditMemo: CreateProposalPageProps["contractSettingsProps"]['groupEditionDrawerProps'] = useMemo(() => ({
    title: '',
    expandText: "Expandir Todos",
    collapseAllText: "Ocultar todos",

    //Prazo
    deadlineTitle: "Prazo (meses)",
    deadlineValues: deadlineValues,
    deadlineUnit: "meses",
    deadlineTextFieldProps: {
      label: "Adicionar opção de prazo",
      placeholder: "Inserir",
      value: contractSettingsGroupEdit_DeadlineValue,
      onChange: handleContractSettingsGroupEditDeadLineChange,
    },
    addDeadlineButtonText: "Adicionar",
    addDeadlineButtonProps: {
      onClick: () => {
        const deadlineValue = Number(contractSettingsGroupEdit_DeadlineValue)

        if(!isNaN(deadlineValue)){

          setDeadlineValues(rows => {
            let updatedRows = [...rows];
            const deadlineOption = {
              value: Number(contractSettingsGroupEdit_DeadlineValue),
              onChange: handleDeadLineCheckedChange(rows.length, false),
              checked: true
            };
            updatedRows = [...updatedRows, deadlineOption];

            return updatedRows.sort((a,b) => Number(a.value) - Number(b.value))
              .map((x, index: number) => ({...x, onChange: handleDeadLineCheckedChange(index, false)}))
          })

          setContractSettingsGroupEdit_DeadlineValue('');
        } else {
          openAlert({
            variant: "error",
            text: "O prazo inserido é inválido.",
            title: "Atenção",
            // autoHideDuration: 3000,
          });
        }
      }
    },

    //kilometragem
    annualKmTitle: "Kilómetros",
    annualKmTextFieldProps: {
      label: "Adicionar opção de KM",
      placeholder: "Inserir",
      value: contractSettingsGroupEdit_AnnualKmValue,
      onChange: handleContractSettingsGroupEditAnnualKmChange,
    },
    addAnnualKmButtonText: "Adicionar",
    annualKmValues: annualKmValues,
    annualKmUnit: "KM",
    addAnnualKmButtonProps: {
      onClick: () => {
        const annualKmValue = Number(contractSettingsGroupEdit_AnnualKmValue);
        if(!isNaN(annualKmValue)){
          setAnnualKmValues(rows => {
            let updatedRows = [...rows];
            const annualKmOption = {
              value: Number(contractSettingsGroupEdit_AnnualKmValue),
              onChange: handleAnnualKmCheckedChange(rows.length, false),
              checked: true
            };
            updatedRows = [...updatedRows, annualKmOption];

            return updatedRows.sort((a,b) => Number(a.value) - Number(b.value))
              .map((x, index: number) => ({...x, onChange: handleAnnualKmCheckedChange(index, false)}));
          })
          setContractSettingsGroupEdit_AnnualKmValue('');
        } else {
          openAlert({
            variant: "error",
            text: "A kilometragem inserida é inválida.",
            title: "Atenção",
            // autoHideDuration: 3000,
          });
        }
      }
    },

    //pneus
    tiresTitle: "Pneus",
    tiresSubTitle: "",
    firstLineTireValues: [
      { value: "false", label: "Não", checked: contractSettingsGroupEditTiresNo , onChange: handleContractSettingsGroupEditTiresChangeNo  },
      { value: "true", label: "Sim", checked: contractSettingsGroupEditTiresYes , onChange: handleContractSettingsGroupEditTiresChangeYes },
    ],
    secondLineTireValues: [
      { value: "non-limited", label: "Ilimitados", checked: !contractSettingsGroupEditTiresLimited, onChange: handleContractSettingsGroupEditTiresLimitedChange  },
      { value: "limited", label: "Limitados", checked: contractSettingsGroupEditTiresLimited, onChange: handleContractSettingsGroupEditTiresLimitedChange  },
    ],
    thirdLineTireValues: thirdLineTiresValuesMultiScenario.sort((a,b) => a.value - b.value), //checkbox array

    tiresTextFieldProps: {
      label: "Adicionar opção de pneus",
      placeholder: "Inserir",
      value: contractSettingsGroupEdit_TiresValue,
      onChange: handleContractSettingsGroupEditTiresQuantityChange,
    },
    addTiresQuantButtonText: "Adicionar",
    addTiresQuantButtonProps: {
      onClick: () => {
        const tiresQuantityValue = Number(contractSettingsGroupEdit_TiresValue)
        if(!isNaN(tiresQuantityValue)){
          setThirdLineTiresValuesMultiScenario(rows => {
            let updatedRows = [...rows];
            const tiresQuantityOption = {
              value: Number(contractSettingsGroupEdit_TiresValue),
              label: contractSettingsGroupEdit_TiresValue,
              onChange: handleThirdLineTiresCheckedChange(rows.length, false),
              checked: true
            };
            updatedRows = [...updatedRows, tiresQuantityOption];

            return updatedRows.sort((a,b) => Number(a.value) - Number(b.value))
              .map((x, index: number) => ({...x, onChange: handleThirdLineTiresCheckedChange(index, false)}))
          })
          setContractSettingsGroupEdit_TiresValue('');
        } else {
          openAlert({
            variant: "error",
            text: "A quantidade de pneus inserida é inválida.",
            title: "Atenção",
            // autoHideDuration: 3000,
          });
        }
      }
    },

    // pacote de seguro - reutiliza estrutura dos multicenários
    showInsuranceConditionsButtonProps: handleShowInsuranceConditions,
    isInsuranceConditionsOpen: isInsuranceConditionsOpen,
    insuranceTitle: "Selecionar seguro",
    insuranceValues: [
      { label: "Não", value: insurances[0], checked: insurances[0], onChange: handleInsuranceVehicleNoChange(false) },
      { label: "Sim", value: insurances[1], checked: insurances[1], onChange: handleInsuranceVehicleYesChange(false) },
    ],
    insuranceShowConditions: '',//isInsuranceConditionsOpen
    // ? "Ocultar condições pré-definidas"
    // : "Mostrar condições pré-definidas",
    insurance:  {
      thresholdText: 'Carece validação',
      title: "Opções do seguro",
      noCheckboxProps: {
        // value: insurances[0],
        // checked: insurances[0],
        // label: "Não",
        // onChange: handleInsuranceVehicleNoChange
      },
      yesCheckboxProps: {
        // value: insurances[1],
        // checked: insurances[1],
        // label: "Sim",
        // onChange: handleInsuranceVehicleYesChange
      },
      insuranceTypeDropDownProps: {
        label: 'Tipo de Seguro',
        options: allowedValues(simulationScenario.insurancePackageOptions,'insurance'),
        value: simulationScenario.insurance,
        name: "insurance",
        disabled: false, // !insurances[1],
        onChange: handleSimulationScenarioChange,
      },
      vehicleTypeDropDownProps: {
        label: 'Tipo de viatura',
        options: settings.Data.Vehicles_UseProps,
        value: useScenario,
        name: "useScenario",
        disabled: false, //!insurances[1],
        onChange: handleSimulationScenarioChange,
      },
      vehicleDamageDropDownProps: {
        warning: false,
        label: "Danos próprios",
        options: allowedValues( simulationScenario!.vehicleDamageOptions, 'own damage'),
        value: simulationScenario!.ownDamage,
        name: "ownDamage",
        disabled:  false, //!insurances[1],

        onChange: handleSimulationScenarioChange,
      },
      occupiersDropDownProps: {
        warning: false,
        label: "Ocupantes",
        options: allowedValues(simulationScenario!.occupiersOptions, 'occupiers'),
        value: simulationScenario!.occupants,
        name: "occupants",
        disabled: false, //!insurances[1],
        onChange: handleSimulationScenarioChange,
      },
      roadAssistanceDropDownProps: {
        warning: false,
        label: "Assistência em viagem",
        options: allowedValues(simulationScenario!.roadAssistanceOptions,'road assistance'),
        value: simulationScenario!.travelAssistance,
        disabled:  false, //!insurances[1],
        name: "travelAssistance",

        onChange: handleSimulationScenarioChange,
      },
      liabilityDropDownProps: {
        warning: false,
        label: "Responsabilidade civil ",
        options: allowedValues(simulationScenario!.liabilityOptions,'liabilities'),
        value: simulationScenario!.responsibility,
        disabled: !insurances[1],
        name: "responsibility",

        onChange: handleSimulationScenarioChange,
      },
      isolatedGlassBreakDropDownProps: {
        warning: false,
        label: "Quebra isolada de vidros",
        options: allowedValues(simulationScenario!.isolatedGlassBreakOptions,'isolated glass breakage'),
        value: simulationScenario!.glassBreakage,
        disabled: !insurances[1],
        name: "glassBreakage",

        onChange: handleSimulationScenarioChange,
      },
      maintenanceDropDownProps: {
        warning: false,
        label: "Manutenção",
        options: [
          {
            label: "M10 00001 - 10.000€ MIP",
            value: "op1",
          },
        ],
        defaultValue: "op1",
        value: simulationScenario!.maintenanceDescription,
        name: "maintenanceDescription",
        // isto não existe no BC pelo que não faz sentido ser manipulado,
        // logo as permissões do perfil não se aplicam
        disabled: true,
        hidden: true,
        // onChange: handleSimulationScenarioChange,
      },
      singleRoadTaxTextFieldProps: {
        label: "IUC",
        value: simulationScenario!.iucCost,
        name: "iucCost",
        // o IUC é relativo a cada viatura pelo que não faz sentido alterar em massa
        // logo este campo fica oculto
        hidden: true,
        disabled: true,
        // onChange: handleSimulationScenarioChange,

      },
    },
    fuelCard: {
      title: "Cartão de combústivel",
      noCheckboxProps: {
        label: "Não",
        checked: fuelNo,
        name: "fuelCard",
        value: "no",
        onChange: handleFuelNoChange(false),
      },
      yesCheckboxProps: {
        label: "Sim",
        checked: fuelYes,
        name: "fuelCard",
        value: "yes",
        onChange: handleFuelYesChange(false),
      },
      dropdownProps: {
        options: simulationScenario.fuelOptions,
        value: simulationScenario.fuelDescriptiom,
        name: "fuelDescriptiom",
        disabled: !fuelYes,
        onChange: handleSimulationScenarioChange,
      },
    },
    viaVerde: {
      title: "Via Verde",
      yesCheckboxProps: {
        label: "Sim",
        checked: viaVerdeYes,
        name:"viaVerde",
        value: 'yes',
        onChange:handleViaVerdeYesChange(false),
        },
      noCheckboxProps: {
        label: "Não",
        checked: viaVerdeNo,
        value: 'no',
        name:"viaVerde",
        onChange:handleViaVerdeNoChange(false),
      },
      dropdownProps: {
        options: simulationScenario.viaVerdeOptions,
        value: simulationScenario.entityViaVerde,
        name: "entityViaVerde",
        disabled: !viaVerdeYes,
        onChange: handleSimulationScenarioChange,
      },
    },


    //viaturas de substituição
    showReplacementVehicleConditionsButtonProps: handleShowReplacementBehicleConditions,
    isReplacementVehicleConditionsOpen: isReplacementVehicleConditionsOpen,
    replacementVehicleTitle: "Viatura de Substituição",
    replacementVehicleValues: [
      { value: replacementVehicles[0], checked: replacementVehicles[0], label: "Não", onChange:handleReplacementVehicleNoChange(false) },
      { value: replacementVehicles[1], checked: replacementVehicles[1], label: "Sim", onChange:handleReplacementVehicleYesChange(false) },
    ],
    replacementVehicleShowConditions: "",
    replacementVehicleOptions: {
      title: "Opções de viatura de substituição",
      noCheckboxProps: { },
      yesCheckboxProps:  { },
      vehicleTypeDropDownProps: {
        label: "Tipo de viatura",
        options: simulationScenario!.vehicleTypeOptions,
        value: simulationScenario!.vehicleType,
        name: "vehicleType",
        disabled: false,
        onChange: handleSimulationScenarioChange,
      },
      overhaul: {
        title: "VS Revisão",
        vehicleDropDownProps: {
          label: "Escolha viatura",
          options: simulationScenario!.vsOverhaulVehicleOptions,
          defaultValue: "op1",
          value: simulationScenario!.vsOverhaulVehicle,
          name: "vsOverhaulVehicle",
          disabled: false, // !replacementVehicles[1],
          onChange: handleSimulationScenarioChange,
        },
        vehicleTypeDropDownProps: {
          label: "Tipo viatura",
          options: simulationScenario!.vsOverhaulVehicleTypeOptions,
          defaultValue: "op1",
          value: simulationScenario!.vsOverhaulVehicleType,
          name: "vsOverhaulVehicleType",
          disabled: false, // !replacementVehicles[1],
          onChange: handleSimulationScenarioChange,
        },
      },
      malfunction: {
        title: "VS Avaria",
        vehicleDropDownProps: {
          label: "Escolha viatura",
          options: simulationScenario!.vsAssistanceVehicleOptions,
          value: simulationScenario!.vsAssistanceVehicle,
          name: "vsAssistanceVehicle",
          disabled: false, // !replacementVehicles[1],
          onChange: handleSimulationScenarioChange,
        },
        vehicleTypeDropDownProps: {
          label: "Tipo viatura",
          options: simulationScenario!.vsAssistanceVehicleTypeOptions,
          value: simulationScenario!.vsAssistanceVehicleType,
          name: "vsAssistanceVehicleType",
          disabled: false, // !replacementVehicles[1],
          onChange: handleSimulationScenarioChange,
        },
      },
      accident: {
        title: "VS Sinistro",
        vehicleDropDownProps: {
          label: "Escolha viatura",
          options: simulationScenario!.vsAccidentVehicleOptions,
          value: simulationScenario!.vsAccidentVehicle,
          name: "vsAccidentVehicle",
          disabled: false, // !replacementVehicles[1],
          onChange: handleSimulationScenarioChange,
        },
        vehicleTypeDropDownProps: {
          label: "Tipo viatura",
          options: simulationScenario!.vsAccidentVehicleTypeOptions,
          value: simulationScenario!.vsAccidentVehicleType,
          name: "vsAccidentVehicleType",
          disabled: false, // !replacementVehicles[1],
          onChange: handleSimulationScenarioChange,
        },
      },
      theft: {
        title: "VS Roubo",
        vehicleDropDownProps: {
          label: "Escolha viatura",
          options: simulationScenario!.vsTheftVehicleOptions,
          value: simulationScenario!.vsTheftVehicle,
          name: "vsTheftVehicle",
          disabled: false, // !replacementVehicles[1],
          onChange: handleSimulationScenarioChange,
        },
        vehicleTypeDropDownProps: {
          label: "Tipo viatura",
          options: simulationScenario!.vsTheftVehicleTypeOptions,
          value: simulationScenario!.vsTheftVehicleType,
          name: "vsTheftVehicleType",
          disabled: false, // !replacementVehicles[1],
          onChange: handleSimulationScenarioChange,
        },
      },
    },

    cancelButtonText: "Cancelar",
    cancelButtonProps: {
      onClick: handleContractSettingsGroupEditCancelClick
    },
    // botão ocultado
    createSaveButtonText: "Guardar",
    createSaveButtonProps: {
      onClick: ()=>{}
    },
    createScenarioButtonText: "Aplicar",
    createScenatioButtonProps: {
      onClick: handleContractSettingsGroupEdit_Click
    },

    complementaryProducts: modalComplementaryProductsMemo,
    warningText: "warningText",
    haveWarning: false,

    // ocultados. não interessam para este caso
    addClientNameTitle: "",
    clientNameTextFieldProps: {
      hidde: true,
      label: "Adicionar titulo ou nome de cliente",
      placeholder: "Inserir",
      value: "",
      onChange: ()=>{},
    },
    visibleTitle: "visibleTitle",
    visibleSubTitle: "visibleSubTitle",
    visibleValues: [],//checkbox array
  }),[
    contractSettingsGroupEdit_DeadlineValue, handleContractSettingsGroupEditDeadLineChange, handleContractSettingsGroupEdit_Click,
    deadlineValues, contractSettingsGroupEdit_AnnualKmValue, handleContractSettingsGroupEditAnnualKmChange,
    annualKmValues, contractSettingsGroupEdit_TiresValue, handleContractSettingsGroupEditTiresQuantityChange,
    thirdLineTiresValuesMultiScenario,
    contractSettingsGroupEditTiresLimited, handleContractSettingsGroupEditTiresLimitedChange,
    contractSettingsGroupEditTiresYes, handleContractSettingsGroupEditTiresChangeYes,
    contractSettingsGroupEditTiresNo, handleContractSettingsGroupEditTiresChangeNo,
    insurances, handleShowInsuranceConditions,
    handleInsuranceVehicleNoChange, handleInsuranceVehicleYesChange, allowedValues, simulationScenario,
    handleSimulationScenarioChange, settings.Data.Vehicles_UseProps, useScenario, fuelNo, handleFuelNoChange, fuelYes,
    handleFuelYesChange, viaVerdeYes, handleViaVerdeYesChange, viaVerdeNo, handleViaVerdeNoChange, isReplacementVehicleConditionsOpen,
    handleContractSettingsGroupEditCancelClick, handleThirdLineTiresCheckedChange, replacementVehicles,isInsuranceConditionsOpen,
    handleReplacementVehicleNoChange, handleReplacementVehicleYesChange, handleShowReplacementBehicleConditions,
    modalComplementaryProductsMemo
  ])

  const getSimulation: (simulation: Simulation, simulationIndex: number, vehicleIndex: number, vehicle: IVehicle ) => CreateProposalPageProps['contractSettingsProps']['contractSettingsTableData'][0]['simulations'][0] =
    useCallback((simulation: Simulation, simulationIndex: number, vehicleIndex: number, vehicle: IVehicle  )=> {


    //CORRIGIR
    // const insuranceSummary = (() => {
    //   let summary = 'Resumo das opções de Seguro:';
    //   if(simulation.secureOptions){
    //     // dano proprio
    //     if(simulation.ownDamage && simulation.ownDamage !== '' && simulation.vehicleDamageOptions && simulation.vehicleDamageOptions.length > 0
    //       && (currentUser.isAdministrator || currentUser.profile?.profileVehicleDamages.some(pi => pi.value === simulation.ownDamage))
    //     ){
    //       summary += `\n ${simulation.ownDamage} - ${simulation.vehicleDamageOptions.find(x => x.value === simulation.ownDamage).label}`
    //     }
    //     //ocupantes
    //     if(simulation.occupants && simulation.occupants !== '' && simulation.occupiersOptions && simulation.occupiersOptions.length > 0){
    //       summary += `\n ${simulation.occupants} - ${simulation.occupiersOptions.find(x => x.value === simulation.occupants).label}`
    //     }
    //     //assistencia em viagem
    //     if(simulation.travelAssistance && simulation.travelAssistance !== '' && simulation.roadAssistanceOptions && simulation.roadAssistanceOptions.length > 0){
    //       summary += `\n ${simulation.travelAssistance} - ${simulation.roadAssistanceOptions.find(x => x.value === simulation.travelAssistance).label}`
    //     }
    //     //responsabilidade civil
    //     if(simulation.responsibility && simulation.responsibility !== '' && simulation.liabilityOptions && simulation.liabilityOptions.length > 0){
    //       summary += `\n ${simulation.responsibility} - ${simulation.liabilityOptions.find(x => x.value === simulation.responsibility).label}`
    //     }
    //     //quebra isolada de vidros
    //     if(simulation.glassBreakage && simulation.glassBreakage !== '' && simulation.isolatedGlassBreakOptions && simulation.isolatedGlassBreakOptions.length > 0){
    //       summary += `\n ${simulation.glassBreakage} - ${simulation.isolatedGlassBreakOptions.find(x => x.value === simulation.glassBreakage).label}`
    //     }
    //     //cartão de combustível
    //     if(simulation.fuelDescriptiom && simulation.fuelDescriptiom !== '' && simulation.fuelOptions && simulation.fuelOptions.length > 0){
    //       summary += `\n ${simulation.fuelDescriptiom} - ${simulation.fuelOptions.find(x => x.value === simulation.fuelDescriptiom).label}`
    //     }
    //     //via verde
    //     if(simulation.entityViaVerde && simulation.entityViaVerde !== '' && simulation.viaVerdeOptions && simulation.viaVerdeOptions.length > 0){
    //       summary += `\n ${simulation.entityViaVerde} - ${simulation.viaVerdeOptions.find(x => x.value === simulation.entityViaVerde).label}`
    //     }
    //   }
    //   // const summaryList = summary.split('\n').map((item, index) => <li key={index}>{item}</li>);
    //   // return (<ul>{summaryList}</ul>)
    //   // pedir à hi-interactive para usar a tag <pre></pre> nestes tooltips
    //   return summary;
    // });

    // const maintenanceSummary = (() => {
    //   let summary = 'Resumo das opções de Manutenção:';
    //   if(simulation.maintenance){
    //     // Manutenção - simulation.maintenanceDescription
    //     if(simulation.maintenanceDescription && simulation.maintenanceDescription !== '' ){
    //       summary += `\n ${simulation.maintenanceDescription}`
    //     }
    //   }
    //   // const summaryList = summary.split('\n').map((item, index) => <li key={index}>{item}</li>);
    //   // return (<ul>{summaryList}</ul>)
    //   // pedir à hi-interactive para usar a tag <pre></pre> nestes tooltips
    //   return summary;
    // });

    // const vsSummary = (() => {
    //   let summary = 'Resumo das opções de Viaturas de substituição:';
    //   if(simulation.vehicleReplacementOptions){

    //     // configuração - simulation.vehicleType
    //     if( simulation.vehicleType && simulation.vehicleType !== '' && simulation.vehicleTypeOptions && simulation.vehicleTypeOptions.length > 0){
    //       summary += `\n ${simulation.vehicleType} - ${simulation.vehicleTypeOptions.find(x => x.value === simulation.vehicleType).label}`;
    //     }

    //     // Revisão
    //     // tipo - simulation.vsOverhaulVehicle
    //     if(simulation.vsOverhaulVehicle && simulation.vsOverhaulVehicle !== '' && simulation.vsOverhaulVehicleOptions && simulation.vsOverhaulVehicleOptions.length > 0){
    //       summary += `\n ${simulation.vsOverhaulVehicle} - ${simulation.vsOverhaulVehicleOptions.find(x => x.value === simulation.vsOverhaulVehicle).label}`;
    //     }
    //     // viatura - simulation.vsOverhaulVehicleType
    //     if(simulation.vsOverhaulVehicleType && simulation.vsOverhaulVehicleType !== '' && simulation.vsOverhaulVehicleTypeOptions && simulation.vsOverhaulVehicleTypeOptions.length > 0){
    //       summary += `\n ${simulation.vsOverhaulVehicleType} - ${simulation.vsOverhaulVehicleTypeOptions.find(x => x.value === simulation.vsOverhaulVehicleType).label}`;
    //     }

    //     // Avaria
    //     // tipo - simulation.vsAssistanceVehicle
    //     if(simulation.vsAssistanceVehicle && simulation.vsAssistanceVehicle !== '' && simulation.vsAssistanceVehicleOptions && simulation.vsAssistanceVehicleOptions.length > 0){
    //       summary += `\n ${simulation.vsAssistanceVehicle} - ${simulation.vsAssistanceVehicleOptions.find(x => x.value === simulation.vsAssistanceVehicle).label}`;
    //     }
    //     // viatura - simulation.vsAssistanceVehicleType
    //     if(simulation.vsAssistanceVehicleType && simulation.vsAssistanceVehicleType !== '' && simulation.vsAssistanceVehicleTypeOptions && simulation.vsAssistanceVehicleTypeOptions.length > 0){
    //       summary += `\n ${simulation.vsAssistanceVehicleType} - ${simulation.vsAssistanceVehicleTypeOptions.find(x => x.value === simulation.vsAssistanceVehicleType).label}`;
    //     }

    //     // Sinistro
    //     // tipo - simulation.vsAccidentVehicle
    //     if(simulation.vsAccidentVehicle && simulation.vsAccidentVehicle !== '' && simulation.vsAccidentVehicleOptions && simulation.vsAccidentVehicleOptions.length > 0){
    //       summary += `\n ${simulation.vsAccidentVehicle} - ${simulation.vsAccidentVehicleOptions.find(x => x.value === simulation.vsAccidentVehicle).label}`;
    //     }
    //     // viatura - simulation.vsAccidentVehicleType
    //     if(simulation.vsAccidentVehicleType && simulation.vsAccidentVehicleType !== '' && simulation.vsAccidentVehicleTypeOptions && simulation.vsAccidentVehicleTypeOptions.length > 0){
    //       summary += `\n ${simulation.vsAccidentVehicleType} - ${simulation.vsAccidentVehicleTypeOptions.find(x => x.value === simulation.vsAccidentVehicleType).label}`;
    //     }

    //     // Roubo
    //     // tipo - simulation.vsTheftVehicle
    //     if(simulation.vsTheftVehicle && simulation.vsTheftVehicle !== '' && simulation.vsTheftVehicleOptions && simulation.vsTheftVehicleOptions.length > 0){
    //       summary += `\n ${simulation.vsTheftVehicle} - ${simulation.vsTheftVehicleOptions.find(x => x.value === simulation.vsTheftVehicle).label}`;
    //     }
    //     // viatura - simulation.vsTheftVehicleType
    //     if(simulation.vsTheftVehicleType && simulation.vsTheftVehicleType !== '' && simulation.vsTheftVehicleTypeOptions && simulation.vsTheftVehicleTypeOptions.length > 0){
    //       summary += `\n ${simulation.vsTheftVehicleType} - ${simulation.vsTheftVehicleTypeOptions.find(x => x.value === simulation.vsTheftVehicleType).label}`;
    //     }
    //   }
    //   // const summaryList = summary.split('\n').map((item, index) => <li key={index}>{item}</li>);
    //   // return (<ul>{summaryList}</ul>)
    //   // pedir à hi-interactive para usar a tag <pre></pre> nestes tooltips
    //   return summary;
    // });

    // inicialização dos produtos complementares por viatura
    let cp: ContractSettingsDetailsProps['complementaryProducts'] = {
      title: "Produtos completares/extras de contrato",
      //PESQUISA
      productSearchResultProps: {
        placeholder:
          "Pesquisar por  descrição ou número do extra",
        options: [
          {
            label: "Opção1",
            value: "op1",
          },
          {
            label: "Opção2",
            value: "op2",
          },
        ],
      },
      searchButtonText: "Pesquisar",
      searchButtonProps: {
        // onClick: () => alert("button click"),
      },
      // LISTA DE PRODUTOS COMPLEMENTARES
      totalProductsText: "0 produtos adicionados",
      tableProps: {
        displayHead: true,
        headCells: [
          {
            label: "Incluir",
          },
          {
            label: "Nº",
          },
          {
            label: "Descrição",
          },
          {
            label: "Código Registo ",
          },
          {
            label: "Custos adicionais ",
          },
          {
            label: "Seguro",
          },
        ],
        rows: [],
        // onChange: (e, rowIndex) => alert(e.target.name + ' - ' + rowIndex),
      }
      // hidden: propertyHidden('Produtos complementares'),
      // disabled: propertyDisabled('Produtos complementares')
    };

    if (complementaryProductsByVehicleMemo && complementaryProductsByVehicleMemo.length > 0) {
      // metodo para obter produtos complementares por simulação separado em duas declarações
      let vehicleHasSimulationWithComplementaryProducts = complementaryProductsByVehicleMemo.find((v, vIndex) => vIndex === vehicleIndex);

      let complementaryProducts: ContractSettingsDetailsProps['complementaryProducts'] | undefined =
        vehicleHasSimulationWithComplementaryProducts?.simulations.find((s,sIndex) => sIndex === simulationIndex)?.complementaryProducts;

      // metodo para obter produtos complementares por simulação numa declaração
      // let complementaryProducts: ContractSettingsDetailsProps['complementaryProducts'] | undefined = complementaryProductsByVehicleMemo
      //   .find((v, vIndex) => vIndex === vehicleIndex)
      //   ?.simulations.find((s, sIndex) => sIndex === simulationIndex)
      //   ?.complementaryProducts;

      if (complementaryProducts) {
        cp = complementaryProducts;
      }
    }

    return {
      details: {
        insurance: {
          radioGroupProps: {},

          title: "Opções do seguro",
          yesCheckboxProps: {
            label: "Sim",
            checked: simulation.secureOptions,
            name:  vehicleIndex+"-"+simulationIndex+"-secureOptions",
            value: "yes",
            onChange: handleSimulationsCheckboxChangeCallback,
            disabled: propertyDisabled('Seguro','Definições de Contrato'),
            hidden: propertyHidden('Seguro','Definições de Contrato'),
          },
          noCheckboxProps: {
            label: "Não",
            checked: !simulation.secureOptions,
            value: "no",
            name:  vehicleIndex+"-"+simulationIndex+"-secureOptions",
            onChange: handleSimulationsCheckboxChangeCallback,
            disabled: propertyDisabled('Seguro','Definições de Contrato'),
            hidden: propertyHidden('Seguro','Definições de Contrato'),
          },
          thresholdText: "Carece validação",
          vehicleDamageDropDownProps: {
            label: "Danos próprios",
            // options: simulation.vehicleDamageOptions,
            options: allowedValues(simulation.vehicleDamageOptions, "own damage"),

            value: simulation.ownDamage,
            name:  vehicleIndex+"-"+simulationIndex+"-ownDamage",
            onChange: handleSimulationsChangeCallback,
            // disabled: !simulation.secureOptions || simulation.insurance === "",
            disabled: propertyDisabled('Danos proprios','Definições de Contrato') || (!simulation.secureOptions || simulation.insurance === ""),
            hidden: propertyHidden('Danos proprios','Definições de Contrato'),
            warning: propertyValidation(simulation.ownDamage, 'Danos proprios','Definições de Contrato')
          },
          occupiersDropDownProps: {
            label: "Ocupantes",
            // options: simulation.occupiersOptions,
            options: allowedValues(simulation.occupiersOptions, "occupiers"),
            value: simulation.occupants,
            name:  vehicleIndex+"-"+simulationIndex+"-occupants",
            onChange: handleSimulationsChangeCallback,
            // disabled: !simulation.secureOptions || simulation.insurance === "",
            disabled: propertyDisabled('Ocupantes','Definições de Contrato') || (!simulation.secureOptions || simulation.insurance === ""),
            hidden: propertyHidden('Ocupantes','Definições de Contrato'),
            warning: propertyValidation( simulation.occupants, 'Ocupantes','Definições de Contrato')
          },
          roadAssistanceDropDownProps: {
            label: "Assistência em viagem",
            // options: simulation.roadAssistanceOptions,
            options: allowedValues(simulation.roadAssistanceOptions, 'road assistance'),

            value: simulation.travelAssistance,
            name:  vehicleIndex+"-"+simulationIndex+"-travelAssistance",
            onChange: handleSimulationsChangeCallback,
            // disabled: !simulation.secureOptions || simulation.insurance === "",
            disabled: propertyDisabled('Assistência em viagem','Definições de Contrato') || (!simulation.secureOptions || simulation.insurance === ""),
            hidden: propertyHidden('Assistência em viagem','Definições de Contrato'),
            warning: propertyValidation(simulation.travelAssistance, 'Assistência em viagem','Definições de Contrato')
          },
          liabilityDropDownProps: {
            label: "Responsabilidade civil ",
            options: allowedValues(simulation.liabilityOptions, 'liabilities'),
            value: simulation.responsibility,
            name:  vehicleIndex+"-"+simulationIndex+"-responsibility",
            onChange: handleSimulationsChangeCallback,
            // disabled: !simulation.secureOptions || simulation.insurance === "",
            disabled: propertyDisabled('Responsabilidade civil','Definições de Contrato') || (!simulation.secureOptions || simulation.insurance === ""),
            hidden: propertyHidden('Responsabilidade civil','Definições de Contrato'),
            warning: propertyValidation(simulation.responsibility, 'Responsabilidade civil','Definições de Contrato')
          },
          isolatedGlassBreakDropDownProps: {
            label: "Quebra isolada de vidros",
            // options: simulation.isolatedGlassBreakOptions,
            options: allowedValues(simulation.isolatedGlassBreakOptions, 'isolated glass breakage') ,
            value: simulation.glassBreakage,
            name:  vehicleIndex+"-"+simulationIndex+"-glassBreakage",

            onChange: handleSimulationsChangeCallback,
            // disabled: !simulation.secureOptions || simulation.insurance === "",
            disabled: propertyDisabled('Quebra isolada de vidros','Definições de Contrato') || (!simulation.secureOptions || simulation.insurance === ""),
            hidden: propertyHidden('Quebra isolada de vidros','Definições de Contrato'),
            warning: propertyValidation(simulation.glassBreakage, 'Quebra isolada de vidros','Definições de Contrato')
          },

          maintenanceDropDownProps: {
            warning: false,
            label: "Manutenção",
            options: [
              {
                label: "M10 00001 - 10.000€ MIP",
                value: "op1",
              },
            ],
            defaultValue: "op1",
            value: simulation.maintenanceDescription,
            name:  vehicleIndex+"-"+simulationIndex+"-maintenanceDescription",
            // onChange: handleSimulationsChangeCallback,
            // disabled: propertyDisabled('Manutenção'),
            // hidden: propertyHidden('Manutenção')
            // isto não existe no BC pelo que não faz sentido ser manipulado,
            // logo as permissões do perfil não se aplicam
            disabled: true,
            hidden: true
          },
          singleRoadTaxTextFieldProps: {
            label: "IUC",
            value: simulation.iucCost,
            name:  vehicleIndex+"-"+simulationIndex+"-iucCost",
            onChange: handleSimulationsChangeCallback,
            // disabled: true,
            disabled: propertyDisabled('IUC','Definições de Contrato'),
            hidden: propertyHidden('IUC','Definições de Contrato'),
            warning: propertyValidation( simulation.iucCost, 'IUC','Definições de Contrato')
          },
        },
        replacementVehicleOptions: {
          title: "Opções de viatura de substituição",
          yesCheckboxProps: {
            label: "Sim",
            checked: simulation.vehicleReplacementOptions,
            name:  vehicleIndex+"-"+simulationIndex+"-vehicleReplacementOptions",
            value: "yes",
            onChange: handleSimulationsCheckboxChangeCallback,
            disabled: propertyDisabled('VS'),
            hidden: propertyHidden('VS')
          },
          noCheckboxProps: {
            label: "Não",
            checked: !simulation.vehicleReplacementOptions,
            name:  vehicleIndex+"-"+simulationIndex+"-vehicleReplacementOptions",
            value: "no",
            onChange: handleSimulationsCheckboxChangeCallback,
            disabled: propertyDisabled('VS'),
            hidden: propertyHidden('VS')
          },
          descriptionDropDownProps: {
            label: "Descrição",
            options: [
              {
                label: "R02N - Até 2 dias",
                value: "op1",
              },
            ],
            defaultValue: "op1",
            value: simulation.vehicleType,
            name:  vehicleIndex+"-"+simulationIndex+"-vehicleType",

            onChange: handleSimulationsChangeCallback,
            // disabled: propertyDisabled(''),
            // hidden: propertyHidden('')
          },
          vehicleTypeDropDownProps: {
            label: "Tipo de viatura",
            options: simulation.vehicleTypeOptions,
            value: simulation.vehicleType,
            name:  vehicleIndex+"-"+simulationIndex+"-vehicleType",

            onChange: handleSimulationsChangeCallback,
            // disabled: !simulation.vehicleReplacementOptions,
            disabled: propertyDisabled('Tipo de viatura','Definições de Contrato') || !simulation.vehicleReplacementOptions,
            hidden: propertyHidden('Tipo de viatura','Definições de Contrato'),
            warning: propertyValidation(simulation.vehicleType, 'Tipo de viatura','Definições de Contrato')
          },
          overhaul: {
            title: "VS Revisão",
            vehicleDropDownProps: {
              label: "Escolha viatura",
              options: simulation.vsOverhaulVehicleOptions,
              value: simulation.vsOverhaulVehicle,
              name:  vehicleIndex+"-"+simulationIndex+"-vsOverhaulVehicle",

              onChange: handleSimulationsChangeCallback,
              // disabled: !simulation.vehicleReplacementOptions,
              disabled: propertyDisabled('Viatura VS Revisão','Definições de Contrato') || !simulation.vehicleReplacementOptions,
              hidden: propertyHidden('Viatura VS Revisão','Definições de Contrato'),
              warning: propertyValidation(simulation.vsOverhaulVehicle, 'Viatura VS Revisão','Definições de Contrato')
            },
            vehicleTypeDropDownProps: {
              label: "Tipo viatura",
              options: simulation.vsOverhaulVehicleTypeOptions,
              value: simulation.vsOverhaulVehicleType,
              name:  vehicleIndex+"-"+simulationIndex+"-vsOverhaulVehicleType",

              onChange: handleSimulationsChangeCallback,
              // disabled: !simulation.vehicleReplacementOptions,
              disabled: propertyDisabled('Tipo VS Revisão','Definições de Contrato') || !simulation.vehicleReplacementOptions,
              hidden: propertyHidden('Tipo VS Revisão','Definições de Contrato'),
              warning: propertyValidation(simulation.vsOverhaulVehicleType, 'Tipo VS Revisão','Definições de Contrato')
            },
          },
          malfunction: {
            title: "VS Avaria",
            vehicleDropDownProps: {
              label: "Escolha viatura",
              options: simulation.vsAssistanceVehicleOptions,
              value: simulation.vsAssistanceVehicle,
              name:  vehicleIndex+"-"+simulationIndex+"-vsAssistanceVehicle",

              onChange: handleSimulationsChangeCallback,
              // disabled: !simulation.vehicleReplacementOptions,
              disabled: propertyDisabled('Viatura VS Avaria','Definições de Contrato') || !simulation.vehicleReplacementOptions,
              hidden: propertyHidden('Viatura VS Avaria','Definições de Contrato'),
              warning: propertyValidation(simulation.vsAssistanceVehicle, 'Viatura VS Avaria','Definições de Contrato')
            },
            vehicleTypeDropDownProps: {
              label: "Tipo viatura",
              options: simulation.vsAssistanceVehicleTypeOptions,
              value: simulation.vsAssistanceVehicleType,
              name:  vehicleIndex+"-"+simulationIndex+"-vsAssistanceVehicleType",

              onChange: handleSimulationsChangeCallback,
              // disabled: !simulation.vehicleReplacementOptions,
              disabled: propertyDisabled('Tipo VS Avaria','Definições de Contrato') || !simulation.vehicleReplacementOptions,
              hidden: propertyHidden('Tipo VS Avaria','Definições de Contrato'),
              warning: propertyValidation(simulation.vsAssistanceVehicleType, 'Tipo VS Avaria','Definições de Contrato')
            },
          },
          accident: {
            title: "VS Sinistro",
            vehicleDropDownProps: {
              label: "Escolha viatura",
              options: simulation.vsAccidentVehicleOptions,
              value: simulation.vsAccidentVehicle,
              name:  vehicleIndex+"-"+simulationIndex+"-vsAccidentVehicle",

              onChange: handleSimulationsChangeCallback,
              // disabled: !simulation.vehicleReplacementOptions,
              disabled: propertyDisabled('Viatura VS Sinistro','Definições de Contrato') || !simulation.vehicleReplacementOptions,
              hidden: propertyHidden('Viatura VS Sinistro','Definições de Contrato'),
              warning: propertyValidation( simulation.vsAccidentVehicle, 'Viatura VS Sinistro','Definições de Contrato')
            },
            vehicleTypeDropDownProps: {
              label: "Tipo viatura",
              options: simulation.vsAccidentVehicleTypeOptions,
              value: simulation.vsAccidentVehicleType,
              name:  vehicleIndex+"-"+simulationIndex+"-vsAccidentVehicleType",

              onChange: handleSimulationsChangeCallback,
              // disabled: !simulation.vehicleReplacementOptions,
              disabled: propertyDisabled('Tipo VS Sinistro','Definições de Contrato') || !simulation.vehicleReplacementOptions,
              hidden: propertyHidden('Tipo VS Sinistro','Definições de Contrato'),
              warning: propertyValidation(simulation.vsAccidentVehicleType, 'Tipo VS Sinistro','Definições de Contrato')
            },
          },
          theft: {
            title: "VS Roubo",
            vehicleDropDownProps: {
              label: "Escolha viatura",
              options: simulation.vsTheftVehicleOptions,
              value: simulation.vsTheftVehicle,
              name:  vehicleIndex+"-"+simulationIndex+"-vsTheftVehicle",

              onChange: handleSimulationsChangeCallback,
              // disabled: !simulation.vehicleReplacementOptions,
              disabled: propertyDisabled('Viatura VS Roubo','Definições de Contrato') || !simulation.vehicleReplacementOptions,
              hidden: propertyHidden('Viatura VS Roubo','Definições de Contrato'),
              warning: propertyValidation(simulation.vsTheftVehicle, 'Viatura VS Roubo','Definições de Contrato')
            },
            vehicleTypeDropDownProps: {
              label: "Tipo viatura",
              options: simulation.vsTheftVehicleTypeOptions,
              value: simulation.vsTheftVehicleType,
              name:  vehicleIndex+"-"+simulationIndex+"-vsTheftVehicleType",

              onChange: handleSimulationsChangeCallback,
              // disabled: !simulation.vehicleReplacementOptions,
              disabled: propertyDisabled('Tipo VS Roubo','Definições de Contrato') || !simulation.vehicleReplacementOptions,
              hidden: propertyHidden('Tipo VS Roubo','Definições de Contrato'),
              warning: propertyValidation(simulation.vsTheftVehicleType, 'Tipo VS Roubo','Definições de Contrato')
            },
          },
        },
        tyres: {
          title: "Opções de pneus",
          yesCheckboxProps: {
            label: "Sim",
            checked: simulation.tires,
            name:  vehicleIndex+"-"+simulationIndex+"-tires",
            value: "yes",
            onChange: handleSimulationsCheckboxChangeCallback,
            disabled: propertyDisabled('Pneus','Definições de Contrato'),
            hidden: propertyHidden('Pneus','Definições de Contrato'),
          },
          noCheckboxProps: {
            label: "Não",
            checked: !simulation.tires,
            name:  vehicleIndex+"-"+simulationIndex+"-tires",
            value: "no",
            onChange: handleSimulationsCheckboxChangeCallback,
            disabled: propertyDisabled('Pneus','Definições de Contrato'),
            hidden: propertyHidden('Pneus','Definições de Contrato'),
          },
          amountDropDownProps: {
            label: "Quantidade",
            options: thirdLineTiresValues,
            value: simulation.tiresQuantity,
            name:  vehicleIndex+"-"+simulationIndex+"-tiresQuantity",
            onChange: handleSimulationsChangeCallback,
            // disabled: !simulation.tires,
            disabled: propertyDisabled('Quantidade','Definições de Contrato') || !simulation.tires,
            hidden: propertyHidden('Quantidade','Definições de Contrato'),
            warning: propertyValidation(simulation.tiresQuantity, 'Quantidade','Definições de Contrato'),
          },
          description: {
            label: propertyHidden('Run-flaat','Definições de Contrato') ? '': "Descrição",
            runFlaatCheckboxProps: {
              defaultChecked: false,
              label: "Run-flaat",
              value: simulation.runFlaat,
              name:  vehicleIndex+"-"+simulationIndex+"-runFlaat",
              checked: simulation.runFlaat,

              onChange: handleSimulationsChangeCallback,
              disabled: propertyDisabled('Run-flaat','Definições de Contrato'),
              hidden: propertyHidden('Run-flaat','Definições de Contrato'),
            },
          },
        },
        fuelCard: {
          title: propertyHidden('Cartão Combustível','Definições de Contrato') ? '' : "Cartão de combústivel",
          yesCheckboxProps: {
            label: "Sim",
            checked: simulation.fuelCard,
            name:  vehicleIndex+"-"+simulationIndex+"-fuelCard",
            value: "yes",
            onChange: handleSimulationsCheckboxChangeCallback,
            disabled: propertyDisabled('Cartão Combustível','Definições de Contrato'),
            hidden: propertyHidden('Cartão Combustível','Definições de Contrato')
          },
          noCheckboxProps: {
            label: "Não",
            checked: !simulation.fuelCard,
            name:  vehicleIndex+"-"+simulationIndex+"-fuelCard",
            value: "no",
            onChange: handleSimulationsCheckboxChangeCallback,
            disabled: propertyDisabled('Cartão Combustível','Definições de Contrato'),
            hidden: propertyHidden('Cartão Combustível','Definições de Contrato')
          },
          dropdownProps: {
            options: simulation.fuelOptions,
            value: simulation.fuelDescriptiom,
            name:  vehicleIndex+"-"+simulationIndex+"-fuelDescriptiom",

            onChange: handleSimulationsChangeCallback,
            // disabled: !simulation.fuelCard,
            disabled: propertyDisabled('Cartão Combustível','Definições de Contrato') || !simulation.fuelCard,
            hidden: propertyHidden('Cartão Combustível','Definições de Contrato'),
            warning: propertyValidation(simulation.fuelDescriptiom, 'Cartão Combustível','Definições de Contrato')
          },
        },
        viaVerde: {
          title: propertyHidden('Via Verde','Definições de Contrato') ? '' : "Via Verde",
          yesCheckboxProps: {
            label: "Sim",
            checked: simulation.viaVerde,
            name:  vehicleIndex+"-"+simulationIndex+"-viaVerde",
            value: "yes",
            onChange: handleSimulationsCheckboxChangeCallback,
            disabled: propertyDisabled('Via Verde','Definições de Contrato'),
            hidden: propertyHidden('Via Verde','Definições de Contrato')
          },
          noCheckboxProps: {
            label: "Não",
            checked: !simulation.viaVerde,
            name:  vehicleIndex+"-"+simulationIndex+"-viaVerde",
            value: "no",
            onChange: handleSimulationsCheckboxChangeCallback,
            disabled: propertyDisabled('Via Verde','Definições de Contrato'),
            hidden: propertyHidden('Via Verde','Definições de Contrato')
          },
          dropdownProps: {
            options: simulation.viaVerdeOptions,
            value: simulation.entityViaVerde,
            name: vehicleIndex+"-"+simulationIndex+"-entityViaVerde",

            onChange: handleSimulationsChangeCallback,
            // disabled: !simulation.viaVerde,
            disabled: propertyDisabled('Via Verde','Definições de Contrato') || !simulation.viaVerde,
            hidden: propertyHidden('Via Verde','Definições de Contrato'),
            warning: propertyValidation(simulation.entityViaVerde, 'Via Verde','Definições de Contrato')
          },
        },
        complementaryProducts: cp,
      },
      id: simulation.no, //"CA10089805",
      termTextFieldProps: {
        value: simulation.deadline,
        name: "deadline",
        id: `${vehicleIndex},${simulationIndex}`,
        onChange: handleDeadlineCallback,
        disabled: propertyDisabled('Prazo','Definições de Contrato'),
        hidden: propertyHidden('Prazo','Definições de Contrato'),
        warning: propertyValidation(simulation.deadline, 'Prazo','Definições de Contrato')
      },
      kmTextFieldProps: {
        value: simulation.miles,
        name: "miles",
        id: `${vehicleIndex},${simulationIndex}`,
        onChange: handleMileageCallback,
        disabled: propertyDisabled('Kilometros','Definições de Contrato'),
        hidden: propertyHidden('Kilometros','Definições de Contrato'),
        warning: propertyValidation(simulation.miles, 'Kilometros','Definições de Contrato')
      },
      termkmHeaderText: `Condições para ${simulation.deadline} meses e ${simulation.miles} km`,
      vehicleSummary: vehicle.versionDescription ? vehicle.versionDescription : "",
      tyres: {
        withTyresDropDownProps: {
          value: String(simulation.tires),
          name: `tires+${vehicleIndex},${simulationIndex}`,
          id: `${vehicleIndex},${simulationIndex}`,
          options: settings.Data.Vehicles_YesNo,
          onChange: handleBooleanDropdownCallback,
          disabled: propertyDisabled('Pneus','Definições de Contrato'),
          hidden: propertyHidden('Pneus','Definições de Contrato'),

        },
        tyresNumberDropDownProps: {
          value: simulation.tiresQuantity,
          name: `tiresQuantity+${vehicleIndex},${simulationIndex}`,
          id: `${vehicleIndex},${simulationIndex}`,
          options: thirdLineTiresValues,
          // disabled: !simulation.tires,
          onChange: handleTyresQuantityCallback,
          disabled: propertyDisabled('Quantidade','Definições de Contrato') || !simulation.tires,

          hidden: propertyHidden('Quantidade','Definições de Contrato') && !simulation.tires,
          warning: propertyValidation(simulation.tiresQuantity, 'Quantidade','Definições de Contrato'),
        },
      },
      insurance: {
        dropDownProps: {
          value: simulation.insurance,
          name: `insurance+${vehicleIndex},${simulationIndex}`,
          id: `${vehicleIndex},${simulationIndex}`,
          options: allowedValues(simulation.insurancePackageOptions,'insurance'),
          //  simulation.insurancePackageOptions.some(i => !currentUser.isAdministrator && currentUser.profile?.profileInsurances.some(pi => pi.value === i.value.split('-')[0]))
          //   ? simulation.insurancePackageOptions.filter(i => currentUser.profile?.profileInsurances.some(pi => pi.value === i.value.split('-')[0]))
          //   : simulation.insurancePackageOptions,
          onChange: handleInsuranceCallback,
          disabled: propertyDisabled('Seguro','Definições de Contrato'),
          hidden: propertyHidden('Seguro','Definições de Contrato'),
          warning: propertyValidation( simulation.insurance, 'Seguro','Definições de Contrato')
        },
        summaryToolTipLabel: handleInsuranceSummary(simulation),
      },
      warning: false,
      maintenance: {
        dropDownProps: {
          defaultValue: "false",
          value: String(simulation.maintenance),
          name: `maintenance+${vehicleIndex},${simulationIndex}`,
          id: `${vehicleIndex},${simulationIndex}`,
          options: settings.Data.Vehicles_YesNo,
          onChange: handleBooleanDropdownCallback,
          disabled: propertyDisabled('Manutenção','Definições de Contrato'),
          hidden: propertyHidden('Manutenção','Definições de Contrato'),
        },
        summaryToolTipLabel: handleMaintenanceSummary(simulation),
      },
      vs: {
        dropDownProps: {

          value: String(simulation.vehicleReplacementOptions),
          name: `vehicleReplacementOptions+${vehicleIndex},${simulationIndex}`,
          id: `${vehicleIndex},${simulationIndex}`,
          options: settings.Data.Vehicles_YesNo,
          onChange: handleBooleanDropdownCallback,
          disabled: propertyDisabled('VS','Definições de Contrato'),
          hidden: propertyHidden('VS','Definições de Contrato'),
        },
        summaryToolTipLabel:  summariesMemo && summariesMemo?.length>0 ? summariesMemo[vehicleIndex][simulationIndex] : 'Resumo das opções de Viaturas de substituição:',
        // summaryToolTipLabel: handleVsSummary(simulation),
      },
      complementaryProducts: {
        value: Number(cp.totalProductsText.split(' ')[0]),
        summaryToolTipLabel: "resumo dos produtos incluidos",
        addButtonProps: {
          onMouseUp: () => alert('Funcionalide em desenvolvimento'),
          // ESTE BOTÃO FOI REMOVIDO(OCULTADO) A PEDIDO DO CLIENTE
          disabled: true,//propertyDisabled('Produtos complementares','Definições de Contrato'),
          hidden: true,//propertyHidden('Produtos complementares','Definições de Contrato')
        },
        addButtonText: "Adicionar",
      },
      rowActions: [
        {
          label: "Ver detalhe",
        },
        {
          label: "Duplicar",
          onClick: handleContractSettingsDuplicateVehicleClick,
        },
        {
          label: "Guardar frequente",
          onClick: handleContractSettingsAddFrequentSimulation,
        },
        {
          label: "Aplicar a todos",
          onClick: handleContractSettingsApplyToAllVehicleClick,
        },
        {
          label: "Eliminar",
          onClick: handleContractSettingsRemoveVehicleClick,
        },
      ],
    };
  },[
    handleContractSettingsAddFrequentSimulation,
    handleContractSettingsDuplicateVehicleClick, handleContractSettingsApplyToAllVehicleClick, handleContractSettingsRemoveVehicleClick,
    settings.Data.Vehicles_YesNo, thirdLineTiresValues,

    handleDeadlineCallback, handleMileageCallback, handleBooleanDropdownCallback, handleTyresQuantityCallback, handleInsuranceCallback,
    handleSimulationsCheckboxChangeCallback, handleSimulationsChangeCallback,

    propertyHidden, propertyDisabled, allowedValues, propertyValidation,
    // currentUser.profile, currentUser.isAdministrator,
    // handleVsSummary
    handleInsuranceSummary, handleMaintenanceSummary,
    summariesMemo,
    complementaryProductsByVehicleMemo,
  ])

  const getRow: (vehicle:IVehicle,vehicleIndex: number) => CreateProposalPageProps['contractSettingsProps']['contractSettingsTableData'][0] =
  useCallback(( vehicle:IVehicle,vehicleIndex: number ) => {
    const addSimulation = (async()=> {
      //FIXME Alexandre: VALIDAR ALTERAÇÕES quando forem definidas as regras das cotações
      vehiclesService.getRentalTypes().then(async(data) => {
        if(data.succeeded) {
          let rentalSettings = data.value.find((element:any) => { return element.value === vehicle.typeofRental})
          vehicle.simulations.push({
            id:0,
            vehicleId: vehicle.id,
            // vehicleId:vehicleDataTable[vehicleIndex].id,
            no:"",
            deadline:0,
            miles:0,
            tires: rentalSettings.tyres,
            tiresQuantity: "0" ,
            insurance:"",
            mostUsed:0,
            maintenance: rentalSettings.maintenance,
            secureOptions:false,
            vehicleReplacementOptions: rentalSettings.vs,
            ownDamage:"",
            occupants:"",
            travelAssistance:"",
            responsibility:"",
            glassBreakage:"",
            maintenanceDescription:"",
            iuc:0,
            fuelCard:false,
            petrolPump:false,
            viaVerde:false,
            entityViaVerde:"",
            fuelDescriptiom:"",
            runFlaat:false,
            vehicleType:"",
            vsOverhaulVehicle:"",
            vsOverhaulVehicleType:"",
            vsAssistanceVehicleType:"",
            vsAssistanceVehicle:"",
            vsAccidentVehicleType:"",
            vsAccidentVehicle:"",
            vsTheftVehicle:"",
            vsTheftVehicleType:"",
            insurancePackageOptions:[],
            vehicleDamageOptions:[],
            occupiersOptions:[],
            roadAssistanceOptions:[],
            liabilityOptions:[],
            fuelOptions:[],
            viaVerdeOptions:[],
            isolatedGlassBreakOptions:[],
            vehicleTypeOptions:[],
            vsAssistanceVehicleOptions:[],
            vsAssistanceVehicleTypeOptions:[],
            vsAccidentVehicleOptions:[],
            vsAccidentVehicleTypeOptions:[],
            vsOverhaulVehicleOptions:[],
            vsOverhaulVehicleTypeOptions:[],
            vsTheftVehicleOptions:[],
            vsTheftVehicleTypeOptions:[],
            //campos das cotações
            priceexclVAT: vehicle.priceexclVAT,
            consumersPrice: vehicle.consumersPrice,
            totalExtraWithoutTax: vehicle.totalExtraWithoutTax,
            totalExtraWithTax: vehicle.totalExtraWithTax,
            totalAccessoriesWithout: vehicle.totalAccessoriesWithout,
            totalAccessoriesWith: vehicle.totalAccessoriesWith,
            discountWithoutTax: vehicle.discountWithoutTax,
            discountWithTax: vehicle.discountWithTax,
            discountWithTaxPercentage: vehicle.discountWithTaxPercentage,
            discountWithTaxTotal: vehicle.discountWithTaxTotal,
            proformaInvoicePriceWithoutTax: vehicle.proformaInvoicePriceWithoutTax,
            proformaInvoicePriceWithTax: vehicle.proformaInvoicePriceWithTax,
            campaignWithoutTax: vehicle.campaignWithoutTax,
            campaignWithTax: vehicle.campaignWithTax,
            campaignWithTaxPercentage: vehicle.campaignWithTaxPercentage,
            campaignWithTaxTotal: vehicle.campaignWithTaxTotal,
            rappelWithoutTax: vehicle.rappelWithTaxTotal,
            rappelWithTax: vehicle.rappelWithTax,
            rappelWithTaxPercentage: vehicle.rappelWithTaxPercentage,
            rappelWithTaxTotal: vehicle.rappelWithTaxTotal,
            extraRappel: vehicle.extraRappel,
            businessValueWithoutTax: vehicle.businessValueWithoutTax,
            businessValueWithTax: vehicle.businessValueWithTax,
            //tipo de contrato
            quantity: 1,
            // quotation calculated fields
            baseIncomeWithoutTax: 0,
            incomeWithoutTax: 0,
            incomeWithTax: 0,
            increaseVR: 0,
            margin: 0,
            realVR: 0,
            vrEurotax: 0,
            vrTable: 0,
            baseVRTable:0,

            amortization: 0,
            interests: 0,
            //validar se estes 4 campos estão duplicados
            tyresCost: 0,
            maintenanceCost: 0,
            iucCost: vehicle.iuc,
            insuranceCost: 0,
            vsCost: 0,

            finalTax: 0,
            estimatedMargin: 0,
            monthlyTCOWithTax: 0,
            monthlyTCOWithoutTax: 0,
            additionalKmsWithTax: 0,
            additionalKmsWithoutTax: 0,
            collateral: 0,
            fee: 0,
            initialEntry: 0,
            travelledKmsWithTax: 0,
            travelledKmsWithoutTax: 0,
            proposeToCustomer: false,
            title: "",
            visibility: 0,
            complementaryProducts: [],
            maximum_Kilometers:0,
            contractExpenses: 0,
            commissionValue: 0,
            commissionCode: ""
          });

          let tmpInsuranceSet = new Set(insuranceFrameworkContractSet);
          let insuranceFrameworkContractOption = Array.from(tmpInsuranceSet).find(x => x.commercialConditions === vehicle.commercialConditions && x.typeofRental === vehicle.typeofRental)

          // preenche a lista da dropdown com os seguros associados ao contrato de quadro, caso contrario preenche a lista como vazia
          let insurancePackageOptions = insuranceFrameworkContractOption?.insurancePackageOptions.map(x => x.insurancePackageOption) ?? []
          if( rentalSettings.insurance) {
            vehicle.simulations.forEach((row) => {
              // se o utilizador é administrador não precisa filtrar valores
              if(currentUser.isAdministrator){
                row.secureOptions = rentalSettings.insurance;
                //se o contrato de quadro tiver preenchimento default do seguro selecciona o seguro default e selecciona a opção sim (secureOptions) nos detalhes
                if(rentalSettings.insurance){
                  if(row.insurancePackageOptions.length === 0){
                    row.insurancePackageOptions = insurancePackageOptions
                  }
                  // se a linha em questão já tiver as opções de seguros pré-carregadas
                  row.insurance = row.insurancePackageOptions.length > 0
                    // selecciona a primeira opção da lista de seguros como default
                    ? row.insurancePackageOptions[0].value
                    : '';

                    // // senao carrega a primeira opção da lista correspondente ao contrato de quadro associado à viatura
                    // insurancePackageOptions && insurancePackageOptions.length>0
                    //   ? insurancePackageOptions[0].value
                    //   : '';

                }
                // senão a dropdown fica vazia no resumo e selecciona a opção não (secureOptions) nos detalhes
                else {
                  row.insurance = ''
                }
                // se tiver seguro seleccionado por default, alimenta as opções das dropdowns mediante o contrato de quadro associado
                if(row.insurance !== '' && insuranceFrameworkContractOption) {
                  let insurancePackageOption = insuranceFrameworkContractOption.insurancePackageOptions.find(x => x.insurance === row.insurance)
                  if (insurancePackageOption) {
                    row.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions;
                    row.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions;
                    row.occupiersOptions = insurancePackageOption.occupiersOptions;
                    row.liabilityOptions = insurancePackageOption.liabilityOptions;
                    row.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions;

                    row.responsibility = insurancePackageOption.responsibility;
                    row.ownDamage = insurancePackageOption.ownDamage;
                    row.occupants = insurancePackageOption.occupants;
                    row.travelAssistance = insurancePackageOption.travelAssistance;
                    row.glassBreakage = insurancePackageOption.glassBreakage;

                    //campos que não são diretamente dependentes das opções de perfis
                    //mapeamento de campos base e opções default
                    row.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
                    row.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
                    row.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
                    row.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

                    row.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
                    row.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
                    row.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
                    row.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
                    row.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

                    row.fuelOptions = insurancePackageOption.fuelOptions;
                    row.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;


                    row.insurance = insurancePackageOption.insurance;
                    row.secureOptions= insurancePackageOption.secureOptions;
                  }
                }
              } else {
                // se não é administrador valida valores permitidos pelo perfil
                row.secureOptions = rentalSettings.insurance;
                //se o contrato de quadro tiver preenchimento default do seguro selecciona o seguro default e selecciona a opção sim (secureOptions) nos detalhes
                if(rentalSettings.insurance ){
                  if(row.insurancePackageOptions.length === 0){
                    // filtra os valores de seguro pré-carregados com os valores permitidos pelo perfil. se o perfil não tiver valores preenche como vazio
                    row.insurancePackageOptions = insurancePackageOptions.filter(i => currentUser.profile?.profileInsurances.some(pi => pi.value.split('-')[0] === i.value));
                  }
                  // se a linha em questão já tiver as opções de seguros pré-carregadas
                  row.insurance = row.insurancePackageOptions.length > 0
                    // selecciona a primeira opção da lista de seguros como default
                    ? row.insurancePackageOptions[0].value
                    : '';

                } else { // senão a dropdown fica vazia no resumo e selecciona a opção não (secureOptions) nos detalhes
                  row.insurance = ''
                }
                // se tiver seguro seleccionado por default, alimenta as opções das dropdowns mediante o contrato de quadro associado
                if(row.insurance !== '' && insuranceFrameworkContractOption) {

                  let insurancePackageOption: InsuranceOptions = emptyInsuranceOption;
                  if(insuranceFrameworkContractOption.insurancePackageOptions.some(ipo => currentUser.profile?.profileInsurances.some(pi => pi.value.split('-')[0] === ipo.insurance))){
                    insurancePackageOption = insuranceFrameworkContractOption.insurancePackageOptions.filter(ipo => currentUser.profile?.profileInsurances.some(pi => pi.value.split('-')[0] === ipo.insurance))[0];
                    if (insurancePackageOption !== emptyInsuranceOption) {


                      // se os valores pré carregados, não coincidirem com as valores permitidos pelo perfil
                      // mantém os valores default(vazios)
                      // senão, filtra os valores pré carregados pelos valores do perfil
                      //own damange dropdown options
                      if(insurancePackageOption.vehicleDamageOptions.some(vdo => currentUser.profile?.profileVehicleDamages.some(vd => vd.value === vdo.value))) {
                        row.vehicleDamageOptions = insurancePackageOption.vehicleDamageOptions.filter(vdo => currentUser.profile?.profileVehicleDamages.some(vd => vd.value.split('-')[0] === vdo.value));
                      }
                      // road assistance dropdown options
                      if(insurancePackageOption.roadAssistanceOptions.some(vra => currentUser.profile?.profileRoadAssistances.some(ra => ra.value === vra.value))) {
                        row.roadAssistanceOptions = insurancePackageOption.roadAssistanceOptions.filter(vra => currentUser.profile?.profileRoadAssistances.some(ra => ra.value.split('-')[0] === vra.value));
                      }
                      // occupiers dropdown options
                      if(insurancePackageOption.occupiersOptions.some(vo => currentUser.profile?.profileOccupiers.some(o => o.value === vo.value))) {
                        row.occupiersOptions = insurancePackageOption.occupiersOptions.filter(vo => currentUser.profile?.profileOccupiers.some(o => o.value.split('-')[0] === vo.value));
                      }
                      // liability dropdown options
                      if(insurancePackageOption.liabilityOptions.some(vdo => currentUser.profile?.profileLiabilities.some(vd => vd.value === vdo.value))) {
                        row.liabilityOptions = insurancePackageOption.liabilityOptions.filter(vdo => currentUser.profile?.profileLiabilities.some(vd => vd.value.split('-')[0] === vdo.value));
                      }
                      // glass breakage dropdown options
                      if(insurancePackageOption.isolatedGlassBreakOptions.some(vdo => currentUser.profile?.profileIsolatedGlassBreaks.some(vd => vd.value === vdo.value))) {
                        row.isolatedGlassBreakOptions = insurancePackageOption.isolatedGlassBreakOptions.filter(vdo => currentUser.profile?.profileIsolatedGlassBreaks.some(vd => vd.value.split('-')[0] === vdo.value));
                      }
                      // liability pre-selected value
                      if(currentUser.profile?.profileLiabilities.some(x => x.value.split('-')[0] === insurancePackageOption.responsibility)){
                        row.responsibility = insurancePackageOption.responsibility;
                      }
                      // own damage pre-selected value
                      if(currentUser.profile?.profileVehicleDamages.some(x => x.value.split('-')[0] === insurancePackageOption.ownDamage)){
                        row.ownDamage = insurancePackageOption.ownDamage;
                      }
                      // occupier pre-selected value
                      if(currentUser.profile?.profileOccupiers.some(x => x.value.split('-')[0] === insurancePackageOption.occupants)){
                        row.occupants = insurancePackageOption.occupants;
                      }
                      // road assistance pre-selected value
                      if(currentUser.profile?.profileRoadAssistances.some(x => x.value.split('-')[0] === insurancePackageOption.travelAssistance)){
                        row.travelAssistance = insurancePackageOption.travelAssistance;
                      }
                      // glass breakage pre-selected value
                      if(currentUser.profile?.profileIsolatedGlassBreaks.some(x => x.value.split('-')[0] === insurancePackageOption.glassBreakage)){
                        row.glassBreakage = insurancePackageOption.glassBreakage;
                      }
                      // opções não impactadas diretamente pelos perfis, mas impactadas pelo pacote de seguro
                      //mapeamento de campos base e opções default
                      row.vsAccidentVehicleOptions = insurancePackageOption.vsAccidentVehicleOptions;
                      row.vsAssistanceVehicleOptions = insurancePackageOption.vsAssistanceVehicleOptions;
                      row.vsOverhaulVehicleOptions = insurancePackageOption.vsOverhaulVehicleOptions;
                      row.vsTheftVehicleOptions = insurancePackageOption.vsTheftVehicleOptions;

                      row.vsAccidentVehicleTypeOptions = insurancePackageOption.vsAccidentVehicleTypeOptions;
                      row.vsAssistanceVehicleTypeOptions = insurancePackageOption.vsAssistanceVehicleTypeOptions;
                      row.vsOverhaulVehicleTypeOptions = insurancePackageOption.vsOverhaulVehicleTypeOptions;
                      row.vsTheftVehicleTypeOptions = insurancePackageOption.vsTheftVehicleTypeOptions;
                      row.vehicleTypeOptions = insurancePackageOption.vehicleTypeOptions;

                      row.fuelOptions = insurancePackageOption.fuelOptions;
                      row.viaVerdeOptions = insurancePackageOption.viaVerdeOptions;


                      row.insurance = insurancePackageOption.insurance;
                      row.secureOptions= insurancePackageOption.secureOptions;
                    }
                  }
                  // let insurancePackageOption = insuranceFrameworkContractOption.insurancePackageOptions.find(x => x.insurance === row.insurance)

                }
              }

              return row;
            })

          }
          let totalQuantity = 0;
          vehicle.simulations.map((sim) => {
            totalQuantity += Number(sim.quantity)
            return totalQuantity
          });
          vehicle.quantity = totalQuantity;

          // vehicleDataTable[vehicleIndex] = vehicle;
          // setVehicleDataTable([...vehicleDataTable]);
          setVehicleDataTable(rows => {
            return rows.map((cur, index) => {
              let updatedVehicle = {...cur}
              if(index === vehicleIndex) {
                updatedVehicle = vehicle
              }
              return updatedVehicle;
            })
          })
          setChangedProposal(true);
        }
      })
    });

    const handleEditVehicleIndexClick = (() => {
      setStep(1);
      setIsDetailsStep2VehicleOpen(prev => prev.map((cur, index) => index === vehicleIndex ? true : cur));
    });

    const handleVehicleCheckbox = ((e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedCheckedList = new Set(contractSettingsCheckedVehicles);
      if (!updatedCheckedList.has(e.currentTarget.id) && e.currentTarget.checked) {
        updatedCheckedList.add(e.currentTarget.id);
      } else if (updatedCheckedList.has(e.currentTarget.id) && !e.currentTarget.checked) {
        updatedCheckedList.delete(e.currentTarget.id);
      }
      setContractSettingsCheckedVehicles(updatedCheckedList);
    })

    return {
      addSimulationLinkText: "Nova Simulação",
      addSimulationLinkOnClick: addSimulation,
      editVehicleLinkText: "Editar Viatura",
      editVehicleLinkOnClick: handleEditVehicleIndexClick,
      header: {
        characteristics: vehicle.caracteristics,
        icons: [
          VehicleUtilities.getFuelTypeIconFor(vehicle.fuelType)[0],
          VehicleFrontIcon,
        ],
        lot: vehicle.lotId,
        model: `${vehicle.versionDescription}: ${vehicle.typeofRental} - ${vehicle.commercialConditions}`,
        checkboxProps: {
          id: vehicle.id.toString(),
          onChange: handleVehicleCheckbox,
          checked:contractSettingsCheckedVehicles.has(vehicle.id.toString())?true:false
        },
        disabled: propertyDisabled('Descrição','Tipo de Contrato'),
        hidden: propertyHidden('Descrição','Tipo de Contrato'),
      },
      // simulations: vehicleDataTable[vehicleIndex].simulations.map((simulation, simulationIndex) => {
      simulations: vehicle.simulations.map((simulation, simulationIndex) => {
        return getSimulation(simulation, simulationIndex, vehicleIndex, vehicle)
      }),

      // o que é que esta estrutura reflete?
      vehicle: {
        icons: VehicleUtilities.getFuelTypeIconFor(vehicle.fuelType),
        lot: vehicle.lotId,
        model: vehicle.versionDescription,
        brand: vehicle.makeId,
        condition: vehicle.status,
        segment: "MAPEAR",
        version: vehicle.versionDescription,
        id: vehicle.id.toString(),
        characteristics: vehicle.caracteristics,
      },
    }
  },[
    getSimulation,
    // vehicleDataTable,
    contractSettingsCheckedVehicles,
    insuranceFrameworkContractSet,
    propertyDisabled,
    propertyHidden,
    currentUser.profile,
    currentUser.isAdministrator
  ])

  const handleGetRows = useCallback(() => {
    return vehicleDataTable.map((vehicle, vehicleIndex) => {
      return getRow(vehicle, vehicleIndex);
    })
  },[getRow, vehicleDataTable])

  const contractSettingsTableData: CreateProposalPageProps['contractSettingsProps']['contractSettingsTableData'] = useMemo(() => {
    return handleGetRows();
  },[handleGetRows])


  // const replacemenetVehiclesMultiscenarioCheckboxOptions: CheckboxProps[] = useMemo(() => {
  //   let replacementVehiclesCheckboxes: CheckboxProps[] = [];
  //   replacementVehicles.map((element,index) => {
  //     if(index === 0) {
  //       replacementVehiclesCheckboxes[0] = {
  //         value: Boolean(replacementVehicles[0]),
  //         label: "Não",
  //         onChange: handleReplacementVehicleNoChange(true)
  //       }
  //     }
  //     if (index === 1 ) {
  //       replacementVehiclesCheckboxes[1] = {
  //         value: Boolean(replacementVehicles[1]),
  //         label: "Sim",
  //         onChange: handleReplacementVehicleYesChange(true)
  //       }
  //     }
  //     return element;
  //   })
  //   return replacementVehiclesCheckboxes
  // },[replacementVehicles, handleReplacementVehicleNoChange, handleReplacementVehicleYesChange])

  const multiScenariosDrawerPropsMemo: CreateProposalPageProps['contractSettingsProps']['multiScenariosDrawerProps'] = useMemo(() => {

    return {
      title: "Configurar outros cenários",
      expandText: "Expandir todos",
      collapseAllText: "Diminuir todos",

      //prazo
      deadlineTitle: "Selecionar Prazo",
      deadlineUnit: "meses",
      deadlineTextFieldProps: {
        label: 'Adicionar outros valores',
        value: deadline,
        disabled: propertyDisabled('Prazo','Definições de Contrato'),
        hidden: (currentUser.profile && currentUser.profile.profileDeadlines?.length > 0 ? true: false) || propertyHidden('Prazo','Definições de Contrato'),
        warning: propertyValidation(deadline, 'Prazo','Definições de Contrato'),
        onChange: handleDeadLineChange,
      },
      addDeadlineButtonText: "Adicionar",
      addDeadlineButtonProps: {
        disabled: propertyDisabled('Prazo','Definições de Contrato'),
        hidden: (currentUser.profile && currentUser.profile.profileDeadlines?.length > 0 ? true: false) || propertyHidden('Prazo','Definições de Contrato'),
        onClick: handleAddDeadlineKmClick,
      },
      deadlineValues:  allowedValues(deadlineValues, 'deadline')
      ,
      //kilometragem
      annualKmTitle: "Selecionar kilometros anuais",
      annualKmUnit: "km",
      annualKmTextFieldProps: {
        label: 'Adicionar outros valores',
        value: annualKm,
        disabled: propertyDisabled('Kilometros','Definições de Contrato'),
        hidden: (currentUser.profile && currentUser.profile.profileAnnualKms?.length > 0 ? true : false ) || propertyHidden('Kilometros','Definições de Contrato'),
        warning: propertyValidation(annualKm, 'Kilometros','Definições de Contrato'),
        onChange: handleAnnualKmChange,
      },
      addAnnualKmButtonText: "Adicionar",
      addAnnualKmButtonProps: {
        disabled: propertyDisabled('Kilometros','Definições de Contrato'),
        hidden: (currentUser.profile && currentUser.profile.profileAnnualKms?.length > 0 ? true : false ) || propertyHidden('Kilometros','Definições de Contrato'),
        onClick: handleAddAnnualKmClick,
      },
      annualKmValues: allowedValues(annualKmValues,'mileage'),
      //pneus
      tiresTitle: "Selecionar pneus",
      tiresSubTitle: "Tipo de pneu",
      tiresTextFieldProps: {
        label: 'Adicionar outros valores',
        value:thirdLineTire,
        onChange: handleThirdLineTireChange,
      },
      firstLineTireValues: [
        { value: "false", label: "Não", checked: tiresNo, onChange: handleTiresNoChange  },
        { value: "true", label: "Sim", checked: tiresYes, onChange: handleTiresYesChange },
      ],
      secondLineTireValues: [
        { value: "non-limited", label: "Ilimitados", checked: tiresLimitedNo, onChange: handleTiresLimitedNoChange  },
        { value: "limited", label: "Limitados", checked: tiresLimitedYes, onChange: handleTiresLimitedYesChange  },
      ],
      thirdLineTireValues: thirdLineTiresValuesMultiScenario,
      addTiresQuantButtonText: "Adicionar",
      addTiresQuantButtonProps: {
        onClick: handleAddThirdLineTireClick,
      },
      //Seleccionar seguro
      insuranceTitle: "Selecionar seguro",
      insuranceValues: [
        { label: "Não", value: insurances[0], checked: insurances[0], onChange: handleInsuranceVehicleNoChange(true) },
        { label: "Sim", value: insurances[1], checked: insurances[1], onChange: handleInsuranceVehicleYesChange(true) },
      ],
      insuranceConditionsEnabled: insurances[1],
      insuranceShowConditions: isInsuranceConditionsOpen
        ? "Ocultar condições pré-definidas"
        : "Mostrar condições pré-definidas",

      isInsuranceConditionsOpen: isInsuranceConditionsOpen,
      showInsuranceConditionsButtonProps: handleShowInsuranceConditions,

      insurance: {
        thresholdText: 'Carece validação',
        title: "Opções do seguro",
        noCheckboxProps: {},
        yesCheckboxProps: {},
        insuranceTypeDropDownProps: {
          label: 'Tipo de Seguro',
          options: allowedValues(simulationScenario.insurancePackageOptions,'insurance'),
          value: simulationScenario.insurance,
          name: "insurance",
          disabled: false, // !insurances[1],
          onChange: handleSimulationScenarioChange,
        },
        vehicleTypeDropDownProps: {
          label: 'Tipo de viatura',
          options:settings.Data.Vehicles_UseProps,
          value: useScenario,
          name: "useScenario",
          disabled: false, //!insurances[1],
          onChange: handleSimulationScenarioChange,
        },
        vehicleDamageDropDownProps: {
          warning: false,
          label: "Danos próprios",
          options: allowedValues( simulationScenario!.vehicleDamageOptions, 'own damage'),
          value: simulationScenario!.ownDamage,
          name: "ownDamage",
          disabled:  false, //!insurances[1],

          onChange: handleSimulationScenarioChange,
        },
        occupiersDropDownProps: {
          warning: false,
          label: "Ocupantes",
          options: allowedValues(simulationScenario!.occupiersOptions, 'occupiers'),
          value: simulationScenario!.occupants,
          name: "occupants",
          disabled: false, //!insurances[1],
          onChange: handleSimulationScenarioChange,
        },
        roadAssistanceDropDownProps: {
          warning: false,
          label: "Assistência em viagem",
          options: allowedValues(simulationScenario!.roadAssistanceOptions,'road assistance'),
          value: simulationScenario!.travelAssistance,
          disabled:  false, //!insurances[1],
          name: "travelAssistance",

          onChange: handleSimulationScenarioChange,
        },
        liabilityDropDownProps: {
          warning: false,
          label: "Responsabilidade civil ",
          options: allowedValues(simulationScenario!.liabilityOptions,'liabilities'),
          value: simulationScenario!.responsibility,
          disabled: !insurances[1],
          name: "responsibility",

          onChange: handleSimulationScenarioChange,
        },
        isolatedGlassBreakDropDownProps: {
          warning: false,
          label: "Quebra isolada de vidros",
          options: allowedValues(simulationScenario!.isolatedGlassBreakOptions,'isolated glass breakage'),
          value: simulationScenario!.glassBreakage,
          disabled: !insurances[1],
          name: "glassBreakage",

          onChange: handleSimulationScenarioChange,
        },
        maintenanceDropDownProps: {
          warning: false,
          label: "Manutenção",
          options: [
            {
              label: "M10 00001 - 10.000€ MIP",
              value: "op1",
            },
          ],
          defaultValue: "op1",
          value: simulationScenario!.maintenanceDescription,
          name: "maintenanceDescription",
          // isto não existe no BC pelo que não faz sentido ser manipulado,
          // logo as permissões do perfil não se aplicam
          disabled: true,
          hidden: true,
          // onChange: handleSimulationScenarioChange,
        },
        singleRoadTaxTextFieldProps: {
          label: "IUC",
          value: simulationScenario!.iucCost,
          name: "iucCost",
          // o IUC é relativo a cada viatura pelo que não faz sentido alterar em massa
          // logo este campo fica oculto
          hidden: true,
          disabled: true,
          // onChange: handleSimulationScenarioChange,

        },
      },
      fuelCard: {
        title: "Cartão de combústivel",
        noCheckboxProps: {
          label: "Não",
          checked: fuelNo,
          name: "fuelCard",
          value: "no",
          onChange: handleFuelNoChange(true),
        },
        yesCheckboxProps: {
          label: "Sim",
          checked: fuelYes,
          name: "fuelCard",
          value: "yes",
          onChange: handleFuelYesChange(true),
        },
        dropdownProps: {
          options: simulationScenario.fuelOptions,
          value: simulationScenario.fuelDescriptiom,
          name: "fuelDescriptiom",
          disabled: !fuelYes,
          onChange: handleSimulationScenarioChange,
        },
      },
      viaVerde: {
        title: "Via Verde",
        yesCheckboxProps: {
          label: "Sim",
          checked: viaVerdeYes,
          name:"viaVerde",
          value: 'yes',
          onChange:handleViaVerdeYesChange(true),
          },
        noCheckboxProps: {
          label: "Não",
          checked: viaVerdeNo,
          value: 'no',
          name:"viaVerde",
          onChange:handleViaVerdeNoChange(true),
        },
        dropdownProps: {
          options: simulationScenario.viaVerdeOptions,
          value: simulationScenario.entityViaVerde,
          name: "entityViaVerde",
          disabled: !viaVerdeYes,
          onChange: handleSimulationScenarioChange,
        },
      },


      //viatura de substituição
      replacementVehicleTitle: "Viatura de substituição",
      replacementVehicleValues: [
        { value: replacementVehicles[0], label: "Não", onChange: handleReplacementVehicleNoChange(true) },
        { value: replacementVehicles[1], label: "Sim", onChange: handleReplacementVehicleYesChange(true) },
      ],
      // replacementVehicleValues: replacemenetVehiclesMultiscenarioCheckboxOptions,

      replacementVehicleConditionsEnabled: replacementVehicles[1],
      replacementVehicleShowConditions: isReplacementVehicleConditionsOpen
        ? "Ocultar condições pré-definidas"
        : "Mostrar condições pré-definidas",
      isReplacementVehicleConditionsOpen: isReplacementVehicleConditionsOpen,
      showReplacementVehicleConditionsButtonProps: handleShowReplacementBehicleConditions,
      replacementVehicleOptions: {
        title: "Opções de viatura de substituição",
        noCheckboxProps: {},
        yesCheckboxProps:  {},
        vehicleTypeDropDownProps: {
          label: "Tipo de viatura",
          options: simulationScenario!.vehicleTypeOptions,
          value: simulationScenario!.vehicleType,
          name: "vehicleType",
          disabled: false, // !replacementVehicles[1],
          onChange: handleSimulationScenarioChange,
        },
        overhaul: {
          title: "VS Revisão",
          vehicleDropDownProps: {
            label: "Escolha viatura",
            options: simulationScenario!.vsOverhaulVehicleOptions,
            defaultValue: "op1",
            value: simulationScenario!.vsOverhaulVehicle,
            name: "vsOverhaulVehicle",
            disabled: false, // !replacementVehicles[1],
            onChange: handleSimulationScenarioChange,
          },
          vehicleTypeDropDownProps: {
            label: "Tipo viatura",
            options: simulationScenario!.vsOverhaulVehicleTypeOptions,
            defaultValue: "op1",
            value: simulationScenario!.vsOverhaulVehicleType,
            name: "vsOverhaulVehicleType",
            disabled: false, // !replacementVehicles[1],
            onChange: handleSimulationScenarioChange,
          },
        },
        malfunction: {
          title: "VS Avaria",
          vehicleDropDownProps: {
            label: "Escolha viatura",
            options: simulationScenario!.vsAssistanceVehicleOptions,
            value: simulationScenario!.vsAssistanceVehicle,
            name: "vsAssistanceVehicle",
            disabled: false, // !replacementVehicles[1],
            onChange: handleSimulationScenarioChange,
          },
          vehicleTypeDropDownProps: {
            label: "Tipo viatura",
            options: simulationScenario!.vsAssistanceVehicleTypeOptions,
            value: simulationScenario!.vsAssistanceVehicleType,
            name: "vsAssistanceVehicleType",
            disabled: false, // !replacementVehicles[1],
            onChange: handleSimulationScenarioChange,
          },
        },
        accident: {
          title: "VS Sinistro",
          vehicleDropDownProps: {
            label: "Escolha viatura",
            options: simulationScenario!.vsAccidentVehicleOptions,
            value: simulationScenario!.vsAccidentVehicle,
            name: "vsAccidentVehicle",
            disabled: false, // !replacementVehicles[1],
            onChange: handleSimulationScenarioChange,
          },
          vehicleTypeDropDownProps: {
            label: "Tipo viatura",
            options: simulationScenario!.vsAccidentVehicleTypeOptions,
            value: simulationScenario!.vsAccidentVehicleType,
            name: "vsAccidentVehicleType",
            disabled: false, // !replacementVehicles[1],
            onChange: handleSimulationScenarioChange,
          },
        },
        theft: {
          title: "VS Roubo",
          vehicleDropDownProps: {
            label: "Escolha viatura",
            options: simulationScenario!.vsTheftVehicleOptions,
            value: simulationScenario!.vsTheftVehicle,
            name: "vsTheftVehicle",
            disabled: false, // !replacementVehicles[1],
            onChange: handleSimulationScenarioChange,
          },
          vehicleTypeDropDownProps: {
            label: "Tipo viatura",
            options: simulationScenario!.vsTheftVehicleTypeOptions,
            value: simulationScenario!.vsTheftVehicleType,
            name: "vsTheftVehicleType",
            disabled: false, // !replacementVehicles[1],
            onChange: handleSimulationScenarioChange,
          },
        },
      },

      //produtos complementares/extras de contrato
      complementaryProducts: modalComplementaryProductsMemo,

      warningText: "Atenção alguns extras que incluir podem não ser válidos para as vituras configuradas",
      haveWarning: true,
      // Adicionar titulo ou nome de cliente
      addClientNameTitle: 'Adicionar titulo ou nome de cliente',
      clientNameTextFieldProps: {
        placeholder: 'Insira o nome de cliente',
        value: frequentTitle,
        onChange: handleFrequentTitleChange,
      },
      // Vísivel para
      visibleTitle: "Vísivel para",
      visibleSubTitle: "Selecione quem predentde que tenha acesso a este cenário se o guardar como frequente",
      visibleValues: [
      {
        value: "0",
        label: "Só para mim",
        checked:frequentVisibility !== 0 ? false:true,
        onClick: handleFrequentVisibilityClick,
      },
      {
        value: "1",
        label: "Kinto (comerciais Kinto tem acesso)",
        checked:frequentVisibility !== 1 ? false:true,
        onClick: handleFrequentVisibilityClick,
      },
      {
        value: "2",
        label: "Todos",
        checked:frequentVisibility !== 2 ? false:true,
        onClick: handleFrequentVisibilityClick,
      }],
      // botões
      cancelButtonProps: {
      },
      cancelButtonText: "Cancelar",
      createSaveButtonText: "Criar e guardar como cenário frequente",
      createSaveButtonProps: {
        onClick: handleCreateSaveScenarioClick,
        // disabled:contractSettingsCheckedVehicles.size === 0 ? true : false
      },
      createScenarioButtonText: "Criar cenários",
      createScenatioButtonProps: {
        onClick: handleCreateScenarioClick,
        // disabled:contractSettingsCheckedVehicles.size === 0 ? true : false
      },
    }
  },[
    insurances, handleInsuranceVehicleYesChange, handleInsuranceVehicleNoChange,
    isInsuranceConditionsOpen,

    // replacemenetVehiclesMultiscenarioCheckboxOptions,
    replacementVehicles, handleReplacementVehicleYesChange,  handleReplacementVehicleNoChange,
    isReplacementVehicleConditionsOpen,

    annualKm, annualKmValues,
    deadline, deadlineValues, frequentTitle, frequentVisibility, fuelNo, fuelYes,
    handleAddAnnualKmClick, handleAddDeadlineKmClick, handleAddThirdLineTireClick,
    handleAnnualKmChange, handleCreateSaveScenarioClick, handleCreateScenarioClick,
    handleDeadLineChange, handleFrequentTitleChange, handleFrequentVisibilityClick,
    handleFuelNoChange, handleFuelYesChange,
    handleShowInsuranceConditions,
    handleShowReplacementBehicleConditions, handleSimulationScenarioChange,
    handleThirdLineTireChange, handleTiresLimitedNoChange, handleTiresLimitedYesChange,
    handleTiresNoChange, handleTiresYesChange, handleViaVerdeNoChange,
    handleViaVerdeYesChange,
    settings.Data.Vehicles_UseProps, simulationScenario,
    thirdLineTire, thirdLineTiresValuesMultiScenario, tiresLimitedNo, tiresLimitedYes,
    tiresNo, tiresYes, useScenario, viaVerdeNo, viaVerdeYes,
    allowedValues, currentUser.profile, propertyDisabled, propertyHidden, propertyValidation,
    modalComplementaryProductsMemo
  ]);

  const contractSettingsProps: CreateProposalPageProps['contractSettingsProps'] = useMemo(() => ({
    configureMultiScenariosModalProps: {
      title: "Definir configuração como mais frequente",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Guardar e aplicar",
      confirmButtonProps: {
        onClick: handleSaveFrequentSimulation,
      },
      configurationTitleTextFieldProps: {
        label: "Adicionar titulo ou nome de cliente",
        placeholder: "Inserir",
        value: frequentTitle,
        onChange: handleFrequentTitleChange,
      },
      visibleRadioGroupProps: {},
      visibleTo: {
        title: "Vísivel para",
        selfCheckboxProps: {
          value: "0",
          label: "Só para mim",
          onClick: handleFrequentVisibilityClick,
        },
        kintoCheckboxProps: {
          value: "1",
          label: "Kinto (comerciais Kinto tem acesso)",
          onClick: handleFrequentVisibilityClick,
        },
        allCheckboxProps: {
          value: "2",
          label: "Todos",
          onClick: handleFrequentVisibilityClick,
        },
      },
      months: `${newFrequent?.deadline} Meses`, //'36 | 48 Meses',
      kms: `${convertNumber((newFrequent?.miles/newFrequent?.deadline)*12).toFixed(0)} Km/ano`, //'10 | 20 Km/ano',
      seeMoreConditionsLinkText: "Mostrar mais condições ",
      hideConditionsLinkText: "Ocultar condições ",
      rows: [
        {
          term: {
            label: "Prazo (meses)",
            value: `${newFrequent?.deadline} meses`,
          },
          km: {
            label: "Km",
            value: `${newFrequent?.miles} Km`,
          },
          tyres: {
            label: "Pneus",
            value: newFrequent?.tires ? "Sim" : "Não",
          },
          insurance: {
            label: "Seguro",
            value: newFrequent?.insurance,
          },
          maintenance: {
            label: "Manutenção",
            value: newFrequent?.maintenance ? "Sim" : "Não",
          },
          vs: {
            //FIXME FALTA VALIDAR ESTE MAPEAMENTO
            label: "VS",
            value: newFrequent?.vehicleReplacementOptions
              ? "Sim"
              : "Não",
          },
          complementaryProductsNumber: {
            label: "Produtos complementares",
            value: "3", // falta definir
          },
        },
      ],
    },
    mostFrequentModalProps: {
      deleteButtonText: 'Apagar mais frequente',
      deleteButtonProps: {
          disabled: (selectedFrequentVehicles.filter(x => x.checked === true).length !== 0 && selectedFrequentVehicles.length !== 0) ? false : true,
          onClick:handleDeleteButtonFrequentSimulationModalProps
      },
      title: "Definições de contrato mais frequentes",
      handleClose: () => {},
      cancelButtonText: "Cancelar",
      onCancel: handleMostFrequentCancel,
      confirmButtonText: "Aplicar",
      confirmButtonProps: {
        onClick: handleMostFrequentConfirmClick,
      },
      frequentSearchResultProps: {
        options: frequentSimulationSearchOptions,
        onInputChange: handleFrequentSilumationChange,
        placeholder: "pesquisar por configurações mais frequentes",
        freeSolo: true, //input de frequentes
      },
      searchButtonText: "Pesquisar",
      searchButtonProps: {
        onClick: handleFrequentSearchClick,
      },
      groupTotalNumber: frequentSimulationOptions.filter((x) => x.hidden === false).length,
      groupTotalText: "configurações gravadas",
      configurations: frequentSimulationOptions.filter((x) => x.hidden === false),
    },

    multiScenariosDrawerProps: multiScenariosDrawerPropsMemo,
    onMultiScenariosConfigurationClick: handleInitialDropDownInsuranceClick,

    quickSettingsTitle: "Configurações rápidas",
    warningText: "Carece validação",

    cards: scenariosFrequent,
    configureMultiScenariosText: "Configurar multicenários",
    customSettingsText: "Configurações personalizadas / Definir cenários",
    groupEditButtonText: "Editar em grupo",
    groupEditButtonProps: {
      disabled: contractSettingGroupEditButtonDisabled, // || propertyDisabled('Definições de Contrato', 'Propostas'),
      hidden: false,
      // onMouseUp: () => {
      //   alert('funcionalidade em desenvolvimento')
      // },
    },
    groupEditionDrawerProps: contractSettingsGroupEditMemo,
    mostFrequentButtonText: "Mais frequentes",
    mostFrequentButtonProps: {
      // disabled: propertyDisabled('Definições de Contrato','Propostas'),
      onClick: handleMostFrequentSimulationsClick,
    },
    orderByDropDownText: "Ordenar por: ",
    orderByDropDownOptions: {
      brandLabel: "Marca",
      modelLabel: "Modelo",
      versionLabel: "Versão",
      MKmLabel: "Meses/Km",
      segmentLabel: "Segmento",
      conditionLabel: "Estado",
      lotLabel: "Lote",
    },

    headCells: [
      {
        label: "Simulação",
        id: "sim",
      },
      {
        label: "Viatura",
        id: "vehicle",
      },
      {
        label: "Prazo (meses)",
        id: "term",
      },
      {
        label: "Kilometros",
        id: "km",
      },
      {
        label: "Pneus",
        id: "tyres",
      },
      {
        label: "Seguro",
        id: "insurance",
      },
      {
        label: "Manutenção",
        id: "maintenance",
      },
      {
        label: "VS",
        id: "vs",
      },
      {
        label: "Produtos complementares",
        id: "cp",
      },
    ],
    selectAllCheckBoxProps: {},
    selectAllText: "Veículo proposto",
    contractSettingsTableData: contractSettingsTableData,
    closeExpandableText: "Fechar Detalhes",
    onGroupEditionClick: initGroupEditionDropDowns,
    // PROPRIEDADES NAO UTILIZADAS
    // initialSelection?: string[];
    // notesButtonProps?: ButtonProps;
    // notesButtonText: string;
    // unreadNotesNumber: number,
    // handleNotesClick: ButtonProps['onClick'];
    // historyButtonProps?: ButtonProps;
    // historyButtonText: string;


    handleSelected: setContractSelectedRows,

  } as CreateProposalPageProps["contractSettingsProps"]), [
    handleSaveFrequentSimulation,
    frequentTitle,
    handleFrequentTitleChange, handleFrequentVisibilityClick,
    newFrequent?.deadline, newFrequent?.miles, newFrequent?.tires, newFrequent?.insurance,
    newFrequent?.maintenance, newFrequent?.vehicleReplacementOptions, selectedFrequentVehicles,
    handleDeleteButtonFrequentSimulationModalProps, handleMostFrequentCancel, handleMostFrequentConfirmClick,
    frequentSimulationSearchOptions, handleFrequentSilumationChange, handleFrequentSearchClick, frequentSimulationOptions,
    multiScenariosDrawerPropsMemo, handleInitialDropDownInsuranceClick, scenariosFrequent, contractSettingGroupEditButtonDisabled,
    contractSettingsGroupEditMemo, handleMostFrequentSimulationsClick, contractSettingsTableData, initGroupEditionDropDowns
  ]);

  // FIM ESTRUTURA DA PÁGINA STEP 4

  // ESTRUTURA DA PÁGINA STEP 5
  const { handleState } = useProposalStatesHook('','');

  //actions
  const handleQuotationRowDuplicate = useCallback((vehicleIndex: number, simulationIndex: number) => {
    let cur = vehicleDataTable[vehicleIndex]
    let simulation = vehicleDataTable[vehicleIndex].simulations[simulationIndex];
    var tmpSimulation = JSON.parse(
      JSON.stringify(simulation)
    );
    tmpSimulation.id = 0;
    tmpSimulation.no = "";
    cur.simulations.push(tmpSimulation);

    let totalQuantity=0;
    cur.simulations.map((sim)=>{
      totalQuantity+=Number(sim.quantity)
      return totalQuantity;
    })

    setChangedProposal(true);
    setVehicleDataTable((rows) =>
      rows.map((row, rowindex) =>
        vehicleIndex === rowindex
          ? {...row,simulations: cur.simulations, quantity:totalQuantity}
          : row
      )
    );
  },[vehicleDataTable]);

  const handleQuotationRowApplyToAll = useCallback((vehicleIndex: number, simulationIndex: number) => {
    let currentVehicle = vehicleDataTable[vehicleIndex];
    let simulation = vehicleDataTable[vehicleIndex].simulations[simulationIndex];
    for(let i = 0; i < vehicleDataTable.length; i++){
      if(i !== vehicleIndex){
        let tmpSimulation = JSON.parse(JSON.stringify(simulation));
        tmpSimulation.id = 0;
        tmpSimulation.no = "";

        if(vehicleDataTable[i].versionId !== currentVehicle.versionId || vehicleDataTable[i].typeofIncome !== currentVehicle.typeofIncome) {
          var tmpSimulationDefault:Simulation = JSON.parse(
            JSON.stringify(vehicleDataTable[i].simulations[0])
          );
          tmpSimulationDefault.id = 0;
          tmpSimulationDefault.no = "";
          tmpSimulationDefault.baseIncomeWithoutTax=0;
          tmpSimulationDefault.incomeWithoutTax=0;
          tmpSimulationDefault.incomeWithTax=0;
          tmpSimulationDefault.tires=tmpSimulation.tires;
          tmpSimulationDefault.tiresQuantity=tmpSimulation.tiresQuantity;
          tmpSimulationDefault.maintenance=tmpSimulation.maintenance;
          tmpSimulationDefault.baseVRTable=tmpSimulation.baseVRTable;
          tmpSimulationDefault.realVR=tmpSimulation.realVR;
          tmpSimulationDefault.vrTable=tmpSimulation.vrTable;
          tmpSimulationDefault.vrEurotax=tmpSimulation.vrEurotax;
          tmpSimulationDefault.quantity=tmpSimulation.quantity;
          tmpSimulationDefault.deadline=tmpSimulation.deadline;
          tmpSimulationDefault.miles=tmpSimulation.miles;
          tmpSimulationDefault.vsAccidentVehicle =tmpSimulation.vsAccidentVehicle;
          tmpSimulationDefault.vsAccidentVehicleType=tmpSimulation.vsAccidentVehicleType;
          tmpSimulationDefault.vsAssistanceVehicle=tmpSimulation.vsAssistanceVehicle;
          tmpSimulationDefault.vsAssistanceVehicleType=tmpSimulation.vsAssistanceVehicleType;
          tmpSimulationDefault.vsTheftVehicle=tmpSimulation.vsTheftVehicle;
          tmpSimulationDefault.vsTheftVehicleType=tmpSimulation.vsTheftVehicleType;
          tmpSimulationDefault.vsOverhaulVehicle=tmpSimulation.vsOverhaulVehicle;
          tmpSimulationDefault.vsOverhaulVehicleType=tmpSimulation.vsOverhaulVehicleType;
          vehicleDataTable[i].simulations.push(tmpSimulationDefault);
          let totalQuantity=0;
          vehicleDataTable[i].simulations.map((sim)=>{
            totalQuantity+=Number(sim.quantity)
            return totalQuantity;
          })
          vehicleDataTable[i].quantity=totalQuantity;
        }
        else
        {
          vehicleDataTable[i].simulations.push(tmpSimulation);
          let totalQuantity=0;
          vehicleDataTable[i].simulations.map((sim)=>{
            totalQuantity+=Number(sim.quantity)
            return totalQuantity;
          })
          vehicleDataTable[i].quantity=totalQuantity;
        }
      }
    }
    setChangedProposal(true);
    setVehicleDataTable([...vehicleDataTable]);
  },[vehicleDataTable])

  const handleQuotationRowDelete = useCallback((vehicleIndex: number, simulationIndex: number) => {
    // let simulation = vehicleDataTable[vehicleIndex].simulations[simulationIndex] as any;
    // let simulationId = (simulation as any).id;
    let cur = vehicleDataTable[vehicleIndex]
    let simulation = vehicleDataTable[vehicleIndex].simulations[simulationIndex];
    let simulationId = simulation.id;

    if(simulationId > 0){
      if (cur.simulations.length > 1) {
        vehiclesService.deleteSimulation(simulationId).then(() => {
          cur.simulations.splice(simulationIndex, 1);
          let totalQuantity=0;
          cur.simulations.map((sim)=>{
            totalQuantity+=Number(sim.quantity)
            return totalQuantity;
          });
          setVehicleDataTable((rows) =>
            rows.map((row, rowIndex) =>
              vehicleIndex === rowIndex
                ? {...row, simulations: cur.simulations,quantity:totalQuantity }
                : row
            )
          );
        });
      }
      else {
        vehiclesService.delete(cur.id).then(data => {
          if (data.succeeded) {
            //isto funciona e renderiza
            // let vehiclesList = [...vehicleDataTable]   // cria copia do vehicleDataTable numa nova posição de memória (substituto para o JSON.Stringify)
            // vehiclesList.splice(vehicleIndex,1)        // remove elemento da lista
            // setVehicleDataTable(vehiclesList)          // atualiza lista

            // isto funciona mas nao renderiza
            // aparentemente o react assume que está a trabalhar a mesma variavel e não uma atualização de estado da variável,
            // pelo que não deteta as alterações da variavel de estado e não dá trigger da renderização
            // setVehicleDataTable((rows) => {
            //   rows.splice(vehicleIndex, 1)
            //   return rows;
            // })

            setVehicleDataTable((rows) => {
              const updatedRows = [...rows]
              updatedRows.splice(vehicleIndex, 1)
              return updatedRows;
            })
          }
        })
      }
    }
    else {

      if (cur.simulations.length > 1) {
        cur.simulations.splice(simulationIndex, 1);
        let totalQuantity=0;
        cur.simulations.map((sim)=>{
          totalQuantity+=Number(sim.quantity)
          return totalQuantity;
        });
        setVehicleDataTable((rows) =>
          rows.map((row, rowIndex) =>
            vehicleIndex === rowIndex
              ? {...row, simulations: cur.simulations,quantity:totalQuantity }
              : row
          )
        );
      }
      else {
        setVehicleDataTable((rows) =>
          rows.splice(vehicleIndex, 1)
        )
      }
    }
    setChangedProposal(true);
  },[vehicleDataTable])

  const [commissionType, setCommissionType] = useState<string>('€');
  const handleQuotationRowInputChange = useCallback((index:number, simulationIndex: number) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setChangedProposal(true);
    setVehicleDataTable((rows) =>
      rows.map((row, rowIndex) => {
        if (rowIndex !== index) {
          return row;
        }
        let vehicle = {...row};
        let simulation: Simulation = {...vehicle.simulations[simulationIndex]};
        let value = (simulation as any)[e.target.name];
        simulation = {
          ...simulation,
          [e.target.name]: validaStringFormatDecimal(e.target.value,value)
        }
        if(e.target.name === 'commissionCode'){
          const commission = commissions.find(x => x.code === e.target.value);
          let commissionValue = 0;
          setCommissionType('€');
          if(commission) {
            // se for valor percentual
            if (commission.business_Price_Percent > 0 && commission.amount === 0) {
              commissionValue = commission.business_Price_Percent;
              setCommissionType('%');
            } else if (commission.business_Price_Percent === 0 && commission.amount > 0 ){
              commissionValue = commission.amount;
            } else {
              commissionValue = 0;
            }
          }
          simulation = {
            ...simulation,
            [e.target.name]: e.target.value,
            commissionValue: commissionValue
          }
        }
        if(e.target.name === "increaseVR") {
          simulation = { ...simulation, realVR: convertNumber(((Number(e.target.value)/100) * simulation.baseVRTable + simulation.baseVRTable).toFixed(2)) };
          simulation = { ...simulation, incomeWithoutTax:
            Math.round(
              (
                (simulation.baseIncomeWithoutTax - (simulation.realVR - simulation.baseVRTable)/simulation.deadline)
                + Number.EPSILON
              ) * 100
            )/100
          };
          simulation = {...simulation, incomeWithTax :
            Math.round(
              (
                (simulation.incomeWithoutTax * (100 + vehicle.vat)/100 )
                  + Number.EPSILON
              ) * 100
            ) /100
          }
        }

        if(e.target.name === "rappelWithoutTax") {
          simulation.businessValueWithoutTax = simulation.proformaInvoicePriceWithoutTax - convertNumber(simulation.rappelWithoutTax)
        }

        //reconversão da string para numero
        if (simulation.increaseVR.toString().slice(-1) !== '.') {
          simulation = {...simulation, increaseVR: Number(simulation.increaseVR)}
        }

        vehicle.simulations[simulationIndex] = simulation;
        // optimização do código comentado
        // vehicle = {
        //   ...vehicle,
        //   simulations: vehicle.simulations.map((sim, index) =>
        //     index === simulationIndex ? simulation : sim
        //   )
        // };

        if(e.target.name === "quantity") {
          let totalQuantity=0;
          vehicle.simulations.map((sim)=>{
            totalQuantity+=Number(sim.quantity)
            return totalQuantity;
          })
          vehicle = {...vehicle, quantity: totalQuantity};
        }
        return { ...row, vehicle };
      })
    );
  },[commissions]);

  const getQuotationTableDataRow: (cur:IVehicle,index: number) => CreateProposalPageProps['quotationProps']['quotationTableData'][0] =
  useCallback((cur:IVehicle,index: number) => {





    return {
      // o que é que esta estrutura reflete?
      vehicle: {
        characteristics: cur.caracteristics, //falta a cor
        icons: VehicleUtilities.getFuelTypeIconFor(cur.fuelType),
        lot: cur.lotId ,
        model: cur.versionDescription,
        brand: cur.makeId,
        segment: cur.euroTaxInternationalSegmt, //ex: "Diesel", o que mapear aqui??? tipo combustivel on segmento
        version: cur.modelDescription, //criar campo para a descrição da versão //"Mégane ST 1.5 Blue DCI Limited",
      },
      header: {
        characteristics: cur.caracteristics, //falta a cor
        icons: VehicleUtilities.getFuelTypeIconFor(cur.fuelType),
        lot: cur.lotId,
        model: cur.versionDescription,
        checkboxProps: {}, // falta mapear depois
      },
      simulations: cur.simulations.map((simulation, simulationIndex) => {
        return {
          warning: false,
          vehicleSummary: cur.versionDescription,
          businessValueTextFieldProps: {
            value: simulation.businessValueWithoutTax,
            name: "businessValueWithoutTax",
            //onChange: handleQuotationRowInputChange(simulationIndex) ,
            disabled: true,
            // disabled: propertyDisabled('Valor de negócio','Cotação'),
            hidden: propertyHidden('Valor de negócio','Cotação'),
            warning: propertyValidation(simulation.businessValueWithoutTax, 'Valor de negócio','Cotação')
          },
          incomeWithoutTaxTextFieldProps: {
            value: simulation.incomeWithoutTax,
            name: "incomeWithoutTax",
            //onChange: handleQuotationRowInputChange(simulationIndex) ,
            disabled: true,
            // disabled: propertyDisabled('Renda sem IVA','Cotação'),
            hidden: propertyHidden('Renda sem IVA','Cotação'),
            warning: propertyValidation( simulation.incomeWithoutTax, 'Renda sem IVA','Cotação')
          },
          incomeWithTaxTextFieldProps: {
            value: simulation.incomeWithTax,
            name: "incomeWithTax",
            //onChange: handleQuotationRowInputChange(simulationIndex) ,
            disabled: true,
            // disabled: propertyDisabled('Renda com IVA','Cotação'),
            hidden: propertyHidden('Renda com IVA','Cotação'),
            warning: propertyValidation(simulation.incomeWithTax,'Renda com IVA','Cotação')
          },
          increaseVRTextFieldProps: {
            value: simulation.increaseVR,
            name: "increaseVR",
            onChange: handleQuotationRowInputChange(index, simulationIndex) ,
            disabled: propertyDisabled('Aumento de VR','Cotação'),
            hidden: propertyHidden('Aumento de VR','Cotação'),
            warning: propertyValidation(simulation.increaseVR,'Aumento de VR','Cotação')
          },
          marginTextFieldProps: {
            value: simulation.margin,
            name: "margin",
            onChange: handleQuotationRowInputChange(index, simulationIndex) ,
            disabled: propertyDisabled('Margem','Cotação'),
            hidden: propertyHidden('Margem','Cotação'),
            warning: propertyValidation(simulation.margin, 'Margem','Cotação' )
          },
          proformaPriceTextFieldProps: {
            value: simulation.proformaInvoicePriceWithTax,
            name: "proformaInvoicePriceWithTax",
            //onChange: handleQuotationRowInputChange(index, simulationIndex) ,
            disabled: true,
            // disabled: propertyDisabled('Preço Proforma','Cotação'),
            hidden: propertyHidden('Preço Proforma','Cotação'),
            warning: propertyValidation( simulation.proformaInvoicePriceWithTax, 'Preço Proforma','Cotação')
          },
          quantityTextFieldProps: {
            value: simulation.quantity,
            name: "quantity",
            onChange: handleQuotationRowInputChange(index, simulationIndex),
            disabled: propertyDisabled('Quantidade','Cotação'),
            hidden: propertyHidden('Quantidade','Cotação'),
            warning: propertyValidation(simulation.quantity, 'Quantidade','Cotação' )
          },
          rappelTextFieldProps: {
            value: simulation.rappelWithoutTax,
            name: "rappelWithoutTax",
            //onChange: handleQuotationRowInputChange(index, simulationIndex) ,
            disabled: true,
            // disabled: propertyDisabled('Rappel','Cotação'),
            hidden: propertyHidden('Rappel','Cotação'),
            warning: propertyValidation(simulation.rappelWithoutTax, 'Rappel','Cotação' )
          },
          realVRTextFieldProps: {
            value: simulation.realVR,
            name: "realVR",
            onChange: handleQuotationRowInputChange(index, simulationIndex) ,
            disabled: propertyDisabled('VR Negócio C/IVA','Cotação'),
            hidden: propertyHidden('VR Negócio C/IVA','Cotação'),
            warning: propertyValidation(simulation.realVR, 'VR Negócio C/IVA','Cotação')
          },
          termTextFieldProps: {
            value:convertNumber(simulation.deadline),
            name: "deadline",
            onChange: handleQuotationRowInputChange(index, simulationIndex) ,
            disabled: propertyDisabled('Prazo','Cotação'),
            hidden: propertyHidden('Prazo','Cotação'),
            warning: propertyValidation(simulation.deadline, 'Prazo','Cotação')
          },
          kmTextFieldProps: {
            value: simulation.miles,
            name: "miles",
            onChange: handleQuotationRowInputChange(index, simulationIndex) ,
            disabled: propertyDisabled('Kilometros','Cotação'),
            hidden: propertyHidden('Kilometros','Cotação'),
            warning: propertyValidation( simulation.miles, 'Kilometros','Cotação')
          },
          vrEurotaxTextFieldProps: {
            value: simulation.vrEurotax,
            name: "vrEurotax",
            //onChange: handleQuotationRowInputChange(index, simulationIndex) ,
            disabled: true,
            // disabled: propertyDisabled('VR Eurotax Original C/IVA','Cotação'),
            hidden: propertyHidden('VR Eurotax Original C/IVA','Cotação'),
            warning: propertyValidation( simulation.vrEurotax, 'VR Eurotax Original C/IVA','Cotação')
          },
          vrTableTextFieldProps: {
            value: simulation.vrTable,
            name: "vrTable",
            //onChange: handleQuotationRowInputChange(index, simulationIndex) ,
            disabled: true,
            // disabled: propertyDisabled('VR Tabela C/IVA','Cotação'),
            hidden: propertyHidden('VR Tabela C/IVA','Cotação'),
            warning: propertyValidation(simulation.vrTable, 'VR Tabela C/IVA','Cotação')
          },
          status: {
            color: cur.vehiclestatus?.color as
              | "error"
              | "warning"
              | "success"
              | "info",
            label: cur.vehiclestatus?.label,
            disabled: propertyDisabled('Estado','Cotação'),
            hidden: propertyHidden('Estado','Cotação'),
          },
          termkmHeaderText: `Condições para ${simulation.deadline} meses e ${simulation.miles} km`,
          details: {
            title: simulation.title,

            amortization: {
              label: "Amort.",
              value: `${simulation.amortization}`,
              disabled: propertyDisabled('Amortização','Cotação'),
              hidden: propertyHidden('Amortização','Cotação'),
              warning: propertyValidation( simulation.amortization, 'Amortização','Cotação')
            }, // { label: "Amort.", value: "229.95 €" },
            interests: {
              label: "Juros",
              value: `${simulation.interests} €`,
              disabled: propertyDisabled('Juros','Cotação'),
              hidden: propertyHidden('Juros','Cotação'),
              warning: propertyValidation(simulation.interests, 'Juros','Cotação')
            }, // { label: "Juros", value: "65.95 €" },
            maintenance: {
              label: "Manut",
              value: `${simulation.maintenanceCost} €`,
              disabled: propertyDisabled('Manutenção','Cotação'),
              hidden: propertyHidden('Manutenção','Cotação'),
              warning: propertyValidation(simulation.maintenanceCost, 'Manutenção','Cotação')
            }, // { label: "Manut", value: "65.95 €" },
            tyres: {
              label: "Pneus",
              value: `${simulation.tyresCost} €`,
              disabled: propertyDisabled('Pneus','Cotação'),
              hidden: propertyHidden('Pneus','Cotação'),
              warning: propertyValidation(simulation.tyresCost, 'Pneus','Cotação')
            }, // { label: "Pneus", value: "65.95 €" },
            iuc: {
              label: "IUC",
              value: String(simulation.iuc) + ' €',
              disabled: propertyDisabled('IUC','Cotação'),
              hidden: propertyHidden('IUC','Cotação'),
              warning: propertyValidation(simulation.iuc, 'IUC','Cotação')
            }, // { label: "IUC", value: "65.95 €" },
            // iuc: { label: "IUC", value: `${simulation.iUC} €` }, // isto é a converte para string à semelhança da linha de cima
            insurance: {
              label: "Seguro",
              value: `${simulation.insuranceCost} €`,
              disabled: propertyDisabled('Seguro','Cotação'),
              hidden: propertyHidden('Seguro','Cotação'),
              warning: propertyValidation( simulation.insuranceCost, 'Seguro','Cotação')
            }, // { label: "Seguro", value: "65.95 €" },
            finalTax: {
              label: "Taxa final",
              value: `${simulation.finalTax} %`,
              disabled: propertyDisabled('Taxa final','Cotação'),
              hidden: propertyHidden('Taxa final','Cotação'),
              warning: propertyValidation(simulation.finalTax, 'Taxa final','Cotação')
            }, // { label: "Taxa final", value: "34.596 €" },
            vs: {
              label: "VS",
              value: `${simulation.vsCost} €`,
              disabled: propertyDisabled('VS','Cotação'),
              hidden: propertyHidden('VS','Cotação'),
              warning: propertyValidation(simulation.vsCost, 'VS','Cotação')
            }, // { label: "VS", value: "65.95 €" },
            estimatedMargin: {
              label: "Margem estimada",
              // value: `${simulation.estimatedMargin.toFixed(2)}%`,
              value: `${simulation.estimatedMargin}%`,
              disabled: propertyDisabled('Margem estimada','Cotação'),
              hidden: propertyHidden('Margem estimada','Cotação'),
              warning: propertyValidation( simulation.estimatedMargin, 'Margem estimada','Cotação')
            }, // { label: "Margem estimada", value: "30%" },
            monthlyTCO: {
              label: "TCO Mensal com e sem IVA",
              withTax: propertyHidden('TCO Mensal c/IVA','Cotação') ? '' : `${simulation.monthlyTCOWithTax} €`, // "754 €",
              withoutTax: propertyHidden('TCO Mensal s/IVA','Cotação') ? '' : `${simulation.monthlyTCOWithoutTax} €`, // "843 €",
              disabled: propertyDisabled('TCO Mensal s/IVA','Cotação') && propertyDisabled('TCO Mensal c/IVA','Cotação'),
              hidden: propertyHidden('TCO Mensal s/IVA','Cotação') && propertyHidden('TCO Mensal c/IVA','Cotação'),
              warning: propertyValidation( simulation.monthlyTCOWithTax, 'TCO Mensal s/IVA','Cotação') && propertyValidation( simulation.monthlyTCOWithoutTax, 'TCO Mensal c/IVA','Cotação')
            },
            contractExpenses: {
              label: propertyHidden('Despesas de contracto','Cotação') ? '' : 'Despesas de contracto',
              value: simulation.contractExpenses,
              name: 'contractExpenses',
              endAdornment: '€',
              onChange: handleQuotationRowInputChange(index, simulationIndex),
              disabled: propertyDisabled('Despesas de contracto','Cotação'),
              hidden: propertyHidden('Despesas de contracto','Cotação'),
              warning: propertyValidation(simulation.vsCost, 'Despesas de contracto','Cotação')

            },
            comissionValue: {
              label: 'Valor de comissão',
              value: simulation.commissionValue && commissionType ? `${simulation.commissionValue} ${commissionType}` : '0 €',
              disabled: propertyDisabled('Valor de comissão','Cotação'),
              hidden: propertyHidden('Valor de comissão','Cotação')
            },
            comissionCode: {
              label: 'Código de comissão',
              value : simulation.commissionCode,
              options: commissionOptions,
              name: 'commissionCode',
              onChange: handleQuotationRowInputChange(index, simulationIndex),
              disabled: propertyDisabled('Código de comissão','Cotação'),
              hidden: propertyHidden('Código de comissão','Cotação'),

              warning: propertyValidation(simulation.vsCost, 'Código de comissão','Cotação')
            },

            //CAMPOS EDITÁVEIS
            feeTextFieldProps: {
              label: "Fee",
              value: simulation.fee,
              endAdornment: '€',
              name: 'fee',
              onChange: handleQuotationRowInputChange(index, simulationIndex),
              disabled: propertyDisabled('Fee','Cotação'),
              hidden: propertyHidden('Fee','Cotação'),
              warning: propertyValidation(simulation.fee, 'Fee','Cotação')
            }, // { label: "Fee", value: "4" },
            collateralTextFieldProps: {
              label: "Caução",
              value: simulation.collateral, // "2.500",
              endAdornment: "€",
              name: 'collateral',
              onChange:handleQuotationRowInputChange(index, simulationIndex),
              disabled: propertyDisabled('Caução','Cotação'),
              hidden: propertyHidden('Caução','Cotação'),
              warning: propertyValidation( simulation.collateral, 'Caução','Cotação')
            },
            initialEntryTextFieldProps: {
              label: "Entrada Inicial (s/IVA)",
              value: simulation.initialEntry,// "2.500",
              endAdornment: "€",
              name: 'initialEntry',
              onChange:handleQuotationRowInputChange(index, simulationIndex),
              disabled: propertyDisabled('Entrada Inicial s/IVA','Cotação'),
              hidden: propertyHidden('Entrada Inicial s/IVA','Cotação'),
              warning: propertyValidation( simulation.initialEntry, 'Entrada Inicial s/IVA','Cotação')
            },
            extraRappelTextFieldProps: {
              label: "Rappel extra",
              value: simulation.extraRappel,//"2.500",
              endAdornment: "€",
              name: 'extraRappel',
              //onChange:handleQuotationRowInputChange(index, simulationIndex),
              disabled: true,
              // disabled: propertyDisabled('Rappel extra','Cotação'),
              hidden: propertyHidden('Rappel extra','Cotação'),
              warning: propertyValidation(simulation.extraRappel, 'Rappel extra','Cotação')
            },
            businessPriceTextFieldProps: {
              label: "Preço de negócio",
              value: simulation.businessValueWithoutTax, //"34.596",
              endAdornment: "€",
              //onChange:handleQuotationRowInputChange(index, simulationIndex)
              disabled: true,
              // disabled: propertyDisabled('Preço do negócio','Cotação'),
              hidden: propertyHidden('Preço do negócio','Cotação'),
              warning: propertyValidation( simulation.businessValueWithoutTax, 'Preço do negócio','Cotação')
            },
            additionalKms: {
              label: "Km adicionais com /sem IVA",
              withTaxTextFieldProps: {
                value: simulation.additionalKmsWithTax, // "553,48 ",
                name: 'additionalKmsWithTax',
                endAdornment: "cênt.",
                //onChange:handleQuotationRowInputChange(index, simulationIndex)
                disabled: true,
                // disabled: propertyDisabled('Km adicionais c/Iva','Cotação'),
                hidden: propertyHidden('Km adicionais c/Iva','Cotação'),
                warning: propertyValidation(simulation.additionalKmsWithTax, 'Km adicionais c/Iva','Cotação')
              },
              withoutTaxTextFieldProps: {
                value: simulation.additionalKmsWithoutTax, // "553,48 ",
                name: 'additionalKmsWithoutTax',
                endAdornment: "cênt.",
                //onChange:handleQuotationRowInputChange(index, simulationIndex)
                disabled: true,
                // disabled: propertyDisabled('Km adicionais s/Iva','Cotação'),
                hidden: propertyHidden('Km adicionais s/Iva','Cotação'),
                warning: propertyValidation(simulation.additionalKmsWithoutTax, 'Km adicionais s/Iva','Cotação')
              },
            },
            travelledKms: {
              label: "Km não percorridos com /sem IVA",
              withTaxTextFieldProps: {
                value: simulation.travelledKmsWithTax,
                name: 'travelledKmsWithTax',
                endAdornment: "cênt.",
                //onChange:handleQuotationRowInputChange(index, simulationIndex)
                disabled: true,
                // disabled: propertyDisabled('Km percorridos c/Iva','Cotação'),
                hidden: propertyHidden('Km percorridos c/Iva','Cotação'),
                warning: propertyValidation(simulation.travelledKmsWithTax, 'Km percorridos c/Iva','Cotação')
              },
              withoutTaxTextFieldProps: {
                value: simulation.travelledKmsWithoutTax,
                name: 'travelledKmsWithoutTax',
                endAdornment: "cênt.",
                //onChange:handleQuotationRowInputChange(index, simulationIndex)
                disabled: true,
                // disabled: propertyDisabled('Km percorridos s/Iva','Cotação'),
                hidden: propertyHidden('Km percorridos s/Iva','Cotação'),
                warning: propertyValidation( simulation.travelledKmsWithoutTax, 'Km percorridos s/Iva','Cotação')
              },
            },
          },
          id: simulation.no, // valor de exemplo"CA10089803",
          rowActions: [
            { label: "Ver detalhe" },
            {
              label: "Duplicar",
              onClick: handleQuotationRowDuplicate,
            },
            {
              label:  changedProposal ? "Efetuar calculo para poder guardar frequente" : "Guardar frequente",
              onClick: handleAddFrequentQuotationSimulation,
              disabled: changedProposal,
            },
            {
              label: "Aplicar a todos",
              onClick: handleQuotationRowApplyToAll,
            },
            {
              label: "Eliminar",
              onClick: handleQuotationRowDelete,
            },
          ],
        }
      }),
      editVehicleLinkText: "Editar Viatura",
      editVehicleLinkOnClick: handleEditVehicleIndexClick(index),
    }
  },[
    commissionOptions, commissionType, handleQuotationRowInputChange,
    handleEditVehicleIndexClick,
    changedProposal,
    handleAddFrequentQuotationSimulation,
    handleQuotationRowDuplicate,
    handleQuotationRowApplyToAll,
    handleQuotationRowDelete,
    propertyDisabled, propertyHidden, propertyValidation
  ])

  const handleQuotationTableDataRows = useCallback(() => {
    return vehicleDataTable.map((cur, index) => {
      return getQuotationTableDataRow(cur, index);

    })
  },[getQuotationTableDataRow, vehicleDataTable])

  const quotationTableData: CreateProposalPageProps['quotationProps']['quotationTableData'] = useMemo(() => {
    return handleQuotationTableDataRows()
  },[handleQuotationTableDataRows])

  const quotationProps:CreateProposalPageProps['quotationProps'] = useMemo(() => ({
    //TODO  e preciso validar esta parte
    configureMostFrequentModalProps: {
      title: 'Definir configuração como mais frequente',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Guardar e aplicar',
      confirmButtonProps: {
        onClick: handleSaveFrequentQuotationSimulation,
      },
      configurationTitleTextFieldProps: {
        label: 'Adicionar titulo ou nome de cliente',
        placeholder: 'Inserir',
        value: frequentTitle,
        onChange: handleFrequentTitleChange,
      },
      visibleRadioGroupProps: {},
      visibleTo: {
        title: "Vísivel para",
        selfCheckboxProps: {
          value: "0",
          label: "Só para mim",
          onClick: handleFrequentVisibilityClick,
        },
        kintoCheckboxProps: {
          value: "1",
          label: "Kinto (comerciais Kinto tem acesso)",
          onClick: handleFrequentVisibilityClick,
        },
        allCheckboxProps: {
          value: "2",
          label: "Todos",
          onClick: handleFrequentVisibilityClick,
        },
      },

      vehicleDescription:`${newMostFrequent?.versionDescription}`,
      seeMoreConditionsLinkText: 'Mostrar mais condições ',
      hideConditionsLinkText: 'Ocultar condições ',
      rows: newMostFrequent?.headerSimulation?.frequentSimulations ? newMostFrequent.headerSimulation.frequentSimulations.map((frequenteSimulation:any) => {
        return {
          qtd: {
            label: 'Qtd',
            value: `${frequenteSimulation.quantity}`,
          },
          term: {
            label: 'Prazo (meses)',
            value: `${frequenteSimulation.deadline} meses`,
          },
          km: {
            label: 'Km',
            value: `${frequenteSimulation.miles} Km`,
          },
          proformaPrice: {
            label: 'P. Proforma',
            value: `${frequenteSimulation.proformaInvoicePriceWithoutTax} €`,
          },
          businessValue: {
            label: 'V. Negócio',
            value: `${frequenteSimulation.businessValueWithTax} €`,
          },
          realVR: {
            label: 'VR Negócio C/IVA',
            value: `${frequenteSimulation.realVR} €`,
          },
          increaseVR: {
            label: 'Aumento VR',
            value: `+${frequenteSimulation.increaseVR} %`,
          },
          tableVR: {
            label: 'VR Tabela C/IVA',
            value: `${frequenteSimulation.vrTable.toFixed(2)} €`,
          },
          eurotaxVR: {
            label: 'VR Eurotax Original C/IVA',
            value: `${frequenteSimulation.vrEurotax.toFixed(2)} €`,
          },
          rappel: {
            label: 'Rappel',
            value: `${frequenteSimulation.rappelWithoutTax} €`,
          },
          percentageMargin: {
            label: '% Margem',
            value: `${frequenteSimulation.margin} %`,
          },
          incomeWithoutTax: {
            label: 'Renda sem IVA',
            value:  `${frequenteSimulation.incomeWithoutTax.toFixed(2)} €`,
          },
          incomeWithTax: {
            label: 'Renda com IVA',
            value: `${frequenteSimulation.incomeWithTax.toFixed(2)} €`,
          },
        }
      }) : []
    },
    handleSelected: onSimulationSelected,
    groupEditModalProps: {
      title: "Alterar em grupo para os items selecionados",
      cancelButtonText: "Cancelar",
      handleClose: () => {
        setVehicleGroupEditModalValues({
          incomeWithoutTax: 0,
          incomeWithTax: 0,
          collateral: 0,
          margin: 0,
          increaseVr:0,
          initialEntry: 0,
          expenses: 0,
          marginCheck: false,
          commercialEffortCheck: false,
          vrCheck: false,
          discountWithTax: 0,
          discountWithoutTax: 0,
          discountWithTaxPercentage: 0,
          campaignWithTax: 0,
          campaignWithTaxPercentage: 0,
          campaignWithoutTax: 0,
          rappelWithTax: 0,
          rappelWithTaxPercentage: 0,
          rappelWithoutTax: 0,
          extraRappel: 0,
          lotId: "",
          extraAccessories: [],
        });
      },
      confirmButtonText: "Aplicar",
      confirmButtonProps: {
        onClick: handleCloseGroupEditModal,
      },
      targetIncome: {
        title: "Definir renda target",
        intendedIncome: {
          label: "Renda pretendida sem e com iva",
          withoutTaxTextFieldProps: {
            value: vehicleGroupEditModalValues?.incomeWithoutTax,
            name: "incomeWithoutTax",
            onChange: handleGroupEditModalChange,
            endAdornment: "€",
            disabled: propertyDisabled('Renda sem IVA','Cotação'),
            hidden: propertyHidden('Renda sem IVA','Cotação'),
          },
          withTaxTextFieldProps: {
            value: vehicleGroupEditModalValues?.incomeWithTax,
            name: "incomeWithTax",
            onChange: handleGroupEditModalChange,
            endAdornment: "€",
            disabled: propertyDisabled('Renda com IVA','Cotação'),
            hidden: propertyHidden('Renda com IVA','Cotação'),
          },
        },
      },
      influencedFields: {
        title: "Definir os campos que a renda target deve influenciar",
        marginCheckboxProps: {
          label: "Margem",
          checked: vehicleGroupEditModalValues?.marginCheck,
          name: "marginCheck",
          onChange: handleGroupEditModalChange,
          disabled: propertyDisabled('Margem','Cotação'),
          hidden: propertyHidden('Margem','Cotação'),
        },
        vrCheckboxProps: {
          label: "VR%",
          checked: vehicleGroupEditModalValues?.vrCheck,
          name: "vrCheck",
          onChange: handleGroupEditModalChange,
          disabled: propertyDisabled('Aumento de VR','Cotação'),
          hidden: propertyHidden('Aumento de VR','Cotação'),
        },
        //ocultado da modal
        // neste campo não são aplicadas permissões
        commercialEffortCheckboxProps: {
          label: "Esforço Cocmercial",
          checked: vehicleGroupEditModalValues?.commercialEffortCheck,
          name: "commercialEffortCheck",
          onChange: handleGroupEditModalChange,
          disabled: true,
          hidden: true
          // disabled: propertyDisabled(''),
          // hidden: propertyHidden('')
        },
      },
      changeConfigurations: {
        title: "Alterar a configuração de:",
        vrTextFieldProps: {
          label: 'VR',
          value: vehicleGroupEditModalValues?.increaseVr,
          name: "increaseVr",
          onChange: handleGroupEditModalChange,
          endAdornment: "%",
          disabled: propertyDisabled('Aumento de VR','Cotação'),
          hidden: propertyHidden('Aumento de VR','Cotação'),
        },
        marginTextFieldProps: {
          label: "%Margem",
          value: vehicleGroupEditModalValues?.margin,
          name: "margin",
          onChange: handleGroupEditModalChange,
          endAdornment: "%",
          disabled: propertyDisabled('Margem','Cotação'),
          hidden: propertyHidden('Margem','Cotação'),
        },
        initialEntryTextFieldProps: {
          label: "Entrada inicial",
          value: vehicleGroupEditModalValues?.initialEntry,
          name: "initialEntry",
          onChange: handleGroupEditModalChange,
          endAdornment: "€",
          disabled: propertyDisabled('Entrada Inicial s/IVA','Cotação'),
          hidden: propertyHidden('Entrada Inicial s/IVA','Cotação'),
        },
        collateralTextFieldProps: {
          label: "Caução",
          value: vehicleGroupEditModalValues?.collateral,
          name: "collateral",
          onChange: handleGroupEditModalChange,
          endAdornment: "€",
          disabled: propertyDisabled('Caução','Cotação'),
          hidden: propertyHidden('Caução','Cotação'),
        },
        contractExpensesTextFieldProps: {
          label: "Despesas de contrato",
          value: vehicleGroupEditModalValues?.expenses,
          name: "expenses",
          onChange: handleGroupEditModalChange,
          endAdornment: "€",
          // disabled: propertyDisabled('Caução','Cotação'),
          // hidden: propertyHidden('Caução','Cotação'),
        },
      },
      lot: {
        title: "Associar lote",
        lotTextFieldProps: {
          label: "Nome Identificativo do lote",
          placeholder: "Inserir nome do lote",
          value: vehicleGroupEditModalValues?.lotId,
          name: "lotId",
          onChange: handleGroupEditModalChange,
        },
      },
    },
    mostFrequentModalProps: {
      deleteButtonText: 'Apagar mais frequente',
      deleteButtonProps: {
        disabled: (selectedFrequentVehicles.filter(x => x.checked === true).length === 0 || selectedFrequentVehicles.length === 0) ? true:false,
        onClick:handleDeleteButtonQuotationSimulationModalProps
      },
      title: "Definições de dados de cálculos mais frequentes",
      cancelButtonText: "Cancelar",
      handleClose: () => {
        setSelectedFrequentVehicles([]);
      },
      onCancel: handleMostFrequentModalCancel,
      confirmButtonText: "Aplicar",
      confirmButtonProps: {
        onClick: handleConfirmMostFrequenteModalClick,
      },
      frequentSearchResultProps: {
        options: frequentQuotationOptions,
        onInputChange: handleMostFrequentSearchResultChange,
        placeholder: "Pesquisar por configurações mais frequentes",
        freeSolo: true, //input de frequentes
      },
      searchButtonText: "Pesquisar",
      searchButtonProps: {
        onClick: handleMostFrequentSearchButtonClick,
      },
      groupTotalNumber: frequentQuotationsConfigurations.filter(
        (x) => x.hidden === false
      ).length,
      groupTotalText: "configurações gravadas",
      configurations: frequentQuotationsConfigurations.filter(
        (x) => x.hidden === false
      ),
    },
    simulationLoading: simulationLoading,
    simulationLoadingText: simulationLoadingText, //"A criar simulações...",
    seeOthersProposalsButtonProps: {
      onClick: handleCardClick("proposal"),
    },
    seeOthersProposalsButtonText: "Ver outras propostas",
    // handleDataChange: handleMostFrequentDataChange,
    proposalInfo: {
      state: {
        tagColor: (handleState(proposal.state, proposal.description).color as 'error' | 'success' | 'warning') ,
        tagLabel: handleState(proposal.state, proposal.description).tagLabel,
        value: handleState(proposal.state, proposal.description).description
      },
      // se necessário substituir a declaração do useProposalStatesHook por esta e mudar
      // a declaração do state acima e do proposalState na finishModalProps para o código comentado aqui

      // const { proposalStateMemo, handleState } = useProposalStatesHook(proposal.state, proposal.description);

      // state: {
      //   tagColor: (proposalStateMemo.color as 'error' | 'success' | 'warning') ,
      //   tagLabel: proposalStateMemo.tagLabel,
      //   value: proposalStateMemo.description
      // },

      rentalType: {
        label: "Tipo de aluguer",
        value: getTypeOfRentalInProposal(vehicleDataTable),
      },
      commercialConditions: {
        label: "Condições comerciais",
        value: "",
      },
      clientType:"",
        // settings.Data.Vehicles_ClientTypology.find((x) => {
        //   return x.value.toLowerCase() === proposal.clientType;
        // })?.label !== (null || undefined)
        //   ? settings.Data.Vehicles_ClientTypology.find((x) => {
        //       return x.value.toLowerCase() === proposal.clientType;
        //     })!.label
        //   : proposal.clientType,
      inputChannel: {
        label: "Canal de entrada",
        value:
          settings.Data.Vehicles_InputChannel.find((x) => {
            return x.value === proposal.inputChannel;
          })?.label !== (null || undefined)
            ? settings.Data.Vehicles_InputChannel.find((x) => {
                return x.value === proposal.inputChannel;
              })!.label
            : proposal.inputChannel,
      },
      underNegotiation: {
        label: "Em negociação",
        value:
          vehicleDataTable?.length > 0
            ? `${getTotalVehiclesProposal(vehicleDataTable)} veículos`
            : "0 veículos.",
      },
      expiryDate: {
        label: "Validade",
        from: proposal?.sendToApprovedDate ? "Enviada "+ proposal.sendToApprovedDate: "   ",
        to: proposal?.sendToApprovedValidateDate ? "Válida até "+ proposal.sendToApprovedValidateDate: "   ",
      },
      Responsible: {
        label: "Responsável",
        value:
          salesPersons.find((x) => {
            return x.value === proposal.salespersonCode;
          })?.label ?? "",
      },
    },
    groupEditButtonText: "Editar em grupo",
    groupEditButtonProps: {
      disabled: groupSimulationEditButtonDisabled //&& propertyDisabled('Cotação', 'Propostas')
    },
    mostFrequentButtonText: "Mais frequentes",
    mostFrequentButtonProps: {
      //disabled: propertyDisabled('Cotação', 'Propostas'),
      onClick: handleMostFrequentQuotationClick,
    },
    orderByDropDownText: "Ordenar por: ",
    orderByDropDownOptions: {
      brandLabel: "Marca",
      modelLabel: "Modelo",
      versionLabel: "Versão",
      MKmLabel: "Meses/Km",
      segmentLabel: "Segmento",
      statusLabel: "Estado",
      lotLabel: "Lote",
    },
    scenarioListText: "Lista de cenários",

    selectAllCheckBoxProps: {},
    selectAllText: "Veículo proposto",
    headCells: [
      {
        label: "Simulação",
      },
      {
        label: "Qtd.",
      },
      {
        label: "Viatura",
      },
      {
        label: "Prazo (meses)",
      },
      {
        label: "Km",
      },
      {
        label: "P. Proforma / V. Negócio",
      },
      {
        label: "Aumento VR",
      },
      {
        label: "VR Tabela C/IVA / VR Eurotax Original C/IVA / VR Negócio C/IVA ",
      },
      {
        label: "Rappel",
      },
      {
        label: "%Margem",
      },
      {
        label: "Estado",
      },
      {
        label: "Renda sem e com IVA",
      },
    ],
    warningText: "Carece validação",
    closeExpandableText: "Fechar detalhes",
    quotationTableData: quotationTableData,
  } as CreateProposalPageProps['quotationProps']), [
    vehicleGroupEditModalValues,
    frequentQuotationOptions,
    frequentQuotationsConfigurations,
    proposal.inputChannel,
    proposal.salespersonCode,
    salesPersons,
    simulationLoading,
    simulationLoadingText,
    vehicleDataTable,
    groupSimulationEditButtonDisabled,
    newMostFrequent,
    handleSaveFrequentQuotationSimulation,
    handleFrequentVisibilityClick,
    handleFrequentTitleChange,
    handleCloseGroupEditModal,
    handleGroupEditModalChange,
    // handleMostFrequentDataChange,
    handleMostFrequentSearchResultChange,
    handleMostFrequentModalCancel,
    handleConfirmMostFrequenteModalClick,
    handleMostFrequentSearchButtonClick,
    handleMostFrequentQuotationClick,
    frequentTitle, handleCardClick, handleDeleteButtonQuotationSimulationModalProps, selectedFrequentVehicles,
    onSimulationSelected, quotationTableData,  settings.Data.Vehicles_InputChannel,
    getTotalVehiclesProposal, getTypeOfRentalInProposal, proposal.sendToApprovedDate, proposal.sendToApprovedValidateDate,
    propertyDisabled,
    propertyHidden,
    handleState,
    proposal.state, proposal.description
  ]);
  // FIM ESTRUTURA DA PÁGINA STEP 5

  // ESTRUTURA DA PÁGINA STEP 1
  const handleClientChange = useCallback( (event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
    if(value !== null){
        setSearchClient(value);
        setDataCustomerByCombo([]);
    }
    else {
        setIsNewClient(false);
        setShowClientData(false);
        setRequiredClientData(emptyRequiredData);
        setRequiredClientInterlocutorData(emptyRequiredData);
        setSearchClient({ value: "", label: "" });
        proposal.contactId="";
        setProposal(proposal);
    }

  },[proposal])

  const handleNifOrClientNameSearch = useCallback((event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    if(value.length > 3 && value.indexOf(' - ') !== 9){
      contactService.getContactsByCombo(value).then((data) => {
        let results = data.value ?? []
        setDataCustomerByCombo(results);
      });
    }
    if(value === '' && (reason === 'clear' || reason === 'input')){
      setDataCustomerByCombo([]);
    }
  },[])

  // const contactSearch = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  const searchApplicant = useCallback((e: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    let applicantsOptionsList: Option[] = [{label: '', value: ''}]
    const regex = /^CN\d{6} - /;
    if(value && !regex.test(value) && value !== 'undefined' && value.length > 3 ){
      contactService.getApplicants(value).then((data) => {
        if( data && data.succeeded){
          data.value.forEach((element: any) => {
            applicantsOptionsList.push({
              label: `${element.no} - ${element.name}`,
              value: element.no
            });
          });
          setApplicantContactsOptionsList(applicantsOptionsList);
        }
      });
    }
    if(value && value !== 'undefined' && regex.test(value)){
      let searchValue = value.split(' - ')[0];
      contactService.getApplicants(searchValue).then((data) => {
        if( data && data.succeeded){
          data.value.forEach((element: any) => {
            applicantsOptionsList.push({
              label: `${element.no} - ${element.name}`,
              value: element.no
            });
          });
          setApplicantContactsOptionsList(applicantsOptionsList);
        }
      });
    }
    if((value === '' && (reason === 'clear' || reason === 'input'))){
      contactService.getContacts().then((data) => {
        if(data && data.succeeded){  
          data.value.forEach((element: any) => {
            applicantsOptionsList.push({
              label: `${element.no} - ${element.name}`,
              value: element.no
            });
          });
          setApplicantContactsOptionsList(applicantsOptionsList);
        }
      });
    }
  },[])
  
  const selectApplicant = useCallback( (event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
    let emptyApplicantsOption: Option = {label: '', value: ''};
    if(value !== null){
      setProposal(p => ({...p, webPortalContactId: value.value}));
      setApplicant(value)
    } else {
      setProposal(p => ({...p, webPortalContactId: ''}));
      setApplicant(emptyApplicantsOption)
    }
  },[])

  const [applicant, setApplicant] = useState<Option>({ value: "", label: "" });

  const clientFileProps= useMemo(() => ({
    socialDenominationSearchProps: {
      placeholder: "Pesquisar",
      label: "Grupo empresarial",
      options: [
        { value: "EGEO", label: "EGEO Pressão, S.A." },
        { value: "other", label: "Outro Exemplo" },
      ],
    },
    bankTextFieldProps: { label: "Banco", placeholder: "Inserir banco" },
    swiftCodeTextFieldProps: {
      label: "Swift Code",
      placeholder: "Inserir swift code",
    },
    shareCapitalTextFieldProps: {
      label: "Capital social",
      placeholder: "Inserir capital social",
    },
    commercialRegistryTextFieldProps: {
      label: "Registo comercial",
      placeholder: "Inserir registo comercial",
    },
    IBANTextFieldProps: { label: "IBAN", placeholder: "Inserir IBAN" },
    optionalClientDataSectionTitle: "Dados Cliente (opcional)",
    documentationAddressSectionTitle: "Morada de envio documentação",
    billingAddressSectionTitle: "Morada de envio para faturação",
    servicesToJoinLabel: "A que serviços pretende aderir?",
    sameAddressRadioProps: {
      label: "Mesma morada de envio de documentação",
      value: "sameAddress",
    },
    addAddressRadioProps: {
      label: "Adicionar outra morada",
      value: "newAddress",
    },
    addAddressButtonText: "Adicionar Segunda morada",
    addressSectionProps: {
      careOfTextFieldProps: { label: "A/C", placeholder: "Inserir nome" },
      documentationAddressCountryInputProps: {
        label: "País",
        defaultValue: "PT",
      },
      documentationAddressPostalCodeSecondPartTextFieldProps: {},
      documentationAddressPostalCodeTextFieldProps: {
        label: "Código Postal",
      },
      documentationAddressTextFieldProps: {},
      headquartersAddressTextFieldProps: {
        label: "Morada Sede",
        placeholder: "Inserir morada sede",
      },
    },
    secondAddressSectionProps: {
      careOfTextFieldProps: { label: "A/C", placeholder: "Inserir nome" },
      documentationAddressCountryInputProps: {
        label: "País",
        defaultValue: "PT",
      },
      documentationAddressPostalCodeSecondPartTextFieldProps: {},
      documentationAddressPostalCodeTextFieldProps: {
        label: "Código Postal",
      },
      documentationAddressTextFieldProps: {},
      headquartersAddressTextFieldProps: {
        label: "Morada Sede",
        placeholder: "Inserir morada sede",
      },
      onDelete: () => alert("apagar linha"),
    },
    billingAddressSectionProps: {
      careOfTextFieldProps: { label: "A/C", placeholder: "Inserir nome" },
      documentationAddressCountryInputProps: {
        label: "País",
        defaultValue: "PT",
      },
      documentationAddressPostalCodeSecondPartTextFieldProps: {},
      documentationAddressPostalCodeTextFieldProps: {
        label: "Código Postal",
      },
      documentationAddressTextFieldProps: { value: "Prior Velho" },
      headquartersAddressTextFieldProps: {
        label: "Morada Sede",
        placeholder: "Inserir morada sede",
      },
    },
    addAddressButtonProps: {},
    secondAddress: false,
    billingRadioGroupProps: {},

    // ficha cliente
    fileClientSectionTitle: "Ficha Cliente",
    clientSearchProps: {
      placeholder: "Pesquisar",
      value: searchClient,
      onChange:  handleClientChange,
      label: "Pesquise por NIF ou nome do Cliente",
      options: dataCustomerCombo ? dataCustomerCombo : [],
      onInputChange: handleNifOrClientNameSearch,
    },
    addClientButtonText: 'Adicionar',
    addClientButtonProps: {
      disabled: searchClient && searchClient.value === "",
      onClick: handleAddClient,
    },
    newClientCheckboxProps: {
      checked: isNewClient,
      onChange: handleNewClientCheckboxChange,
      label: "Criar novo Cliente",
    },
    unCheckNewClientConfirmationModalProps: {
      text: "Atenção que se desmarcar esta opção vai perder os dados preenchidos!",
      title: "Perda de dados!",
      onConfirm: () => {
        setIsNewClient(false);
        setShowClientData(false);
        setRequiredClientData(emptyRequiredData);
        setRequiredClientInterlocutorData(emptyRequiredData);
      },
      cancelText: "Cancelar",
      confirmText: "Confirmar",
    },
    // fim ficha cliente
    // Tipo de cliente
    clientTypeSectionTitle: "Tipo de Cliente",
    enterpriseButtonToolbarProps: {
      label: "Empresa",
      checkProps: {
        value: "Company",
        checked: requiredClientData.customerTypePortal === "Company" ? true : false
      },
    },
    privateButtonToolbarProps: {
      label: "Particular",
      checkProps: {
        value: "Particular",
        checked: requiredClientData.customerTypePortal === "Particular" ? true : false
      },
    },
    eniButtonToolbarProps: {
      label: "ENI",
      checkProps: {
        value: "ENI",
        checked: requiredClientData.customerTypePortal === "ENI" ? true : false

      }
    },
    clientTypeRadioGroupProps: {
      value: requiredClientData?.type ?? '',
      onChange: onChangeClientTypeRadioGroupProps,
    },
    // fim tipo de cliente
    // Origem da proposta
    proposalOriginSectionTitle: "Origem da Proposta",
    responsibleDropDownProps: {
      label: "Responsável",
      placeholder: "Inserir responsável",
      value: requiredClientData.salespersonCode ? requiredClientData.salespersonCode : '',
      name: "salespersonCode",
      options: salesPersons, //salesPersons,
      onChange: handleProposalDataTextFieldChange,
    },
    inputChannelDropDownProps: {
      label: "Canal de entrada",
      placeholder: "Selecionar",
      options: settings.Data.Vehicles_InputChannel,
      value: proposal?.inputChannel,
      name: "inputChannel",
      onChange: handleProposalDataTextFieldChange,
    },
    requestingChannelDropDownProps: {
      label: "Concessão",
      placeholder: "Inserir",
      options: requestingChannelOptions,
      value: proposal?.requestingChannel,
      name: "requestingChannel",
      disabled: proposal?.inputChannel === '1'? false : true,
      hidden: proposal?.inputChannel === '1'? false : true,
      onChange: handleProposalDataTextFieldChange,
    },
    aplicantDropdownProps: { // SK-726
      placeholder: "Selecionar",
      value: applicant ?? { label:'', value:''},
      onChange: selectApplicant,
      onInputChange: searchApplicant,
      label: "Contacto Requerente",
      options: applicantContactsOptionsList ?? [],
      name: "webPortalContactId",
      disabled: proposal?.inputChannel === '1'? false : true,
      hidden: proposal?.inputChannel === '1'? false : true,
    },
    // fim origem da proposta
    // dados do cliente
    clientDataSectionTitle: "Dados Cliente",
    requiredFieldsTextWarning: "(*) Campos Obrigatórios",
    socialDenominationTextFieldProps: {
      label: "Denominação social",
      placeholder: "Inserir Denominação social",
      value: requiredClientData.name,
      name: "name",
      onChange: handleRequiredDataTextFieldChange,
    },
    taxNumberTextFieldProps: {
      label: "NIF",
      placeholder: "Inserir NIF",
      value: requiredClientData.vaT_Registration_No,
      name: "vaT_Registration_No",
      helperText: nifMessage,
      error: nifValidate,
      externalIcon: nifIcon,
      onChange: handleRequiredDataTextFieldChange,
      onBlur: onBlurNif, //HYDRA
    },
    clientTypologyDropDownProps: {
      label: "Tipologia de Cliente",
      placeholder: "Inserir Tipologia de Cliente",
      options: settings.Data.Vehicles_ClientTypology[1].result,
      value: requiredClientData?.customerType,
      name: "customerType",
      onChange: handleRequiredDataTextFieldChange,
    },
    totalFleetTextFieldProps: {
      label: "Frota total",
      placeholder: "Inserir",
      name: "totalFleet",
      value:convertNumber(requiredClientData?.totalFleet),
      onChange: handleRequiredDataTextFieldChange,
    },
    // fim dados do cliente
    // dados do interlocutor
    interlocutorDataSectionTitle: "Dados do Interlocutor",
    interlocutorNameTextFieldProps: {
      label: "Nome",
      placeholder: "Inserir nome",
      value: requiredClientInterlocutorData
        ? requiredClientInterlocutorData.name
        : requiredClientData.interlocutorName,
      onChange: onChangeNameInter,
    },
    interlocutorPhoneInputProps: {
      label: "País",
      defaultValue: "PT",
    },
    interlocutorPhoneNumberTextFieldProps: {
      label: "Telefone",
      placeholder: "Inserir número",
      value: requiredClientInterlocutorData
        ? requiredClientInterlocutorData.phoneNo
        : requiredClientData.phoneNo,
      onChange: onChangePhoneInter,
      onBlur: onBlurNumberPhone,
      error: phoneValidate,
      helperText: phoneMessage,
      externalIcon: phoneIcon,
    },
    interlocutorEmailTextFieldProps: {
      label: "E-mail",
      placeholder: "Inserir e-mail",
      value: requiredClientInterlocutorData
        ? requiredClientInterlocutorData.e_Mail
        : requiredClientData.e_Mail,
      onChange: onChangeEmaiInter,
      onBlur: onBlurEmail,
      error: emailValidate,
      helperText: emailMessage,
      externalIcon: emailIcon,
    },
    interlocutorMobilePhoneInputProps: {
      label: "País",
      defaultValue: "PT",
    },
    interlocutorMobilePhoneNumberTextFieldProps: {
      label: "Telemóvel",
      placeholder: "Inserir número",
      value: requiredClientInterlocutorData
        ? requiredClientInterlocutorData.mobile_Phone_No
        : requiredClientData.mobile_Phone_No,
      onChange: onChangeMobileInter,
      onBlur: onBlurNumberMobile,
      error: mobileValidate,
      helperText: mobileMessage,
      externalIcon: mobileIcon,
    },
    // fim dados do interlocutor
    showClientFile: showClientData,

  } as CreateProposalPageProps['clientFileProps']), [
    searchApplicant, selectApplicant, applicantContactsOptionsList,
    proposal,
    salesPersons, //salesPersons,
    requiredClientData,
    requiredClientInterlocutorData,
    showClientData,
    dataCustomerCombo,
    isNewClient,
    emailIcon,
    emailMessage,
    emailValidate,
    mobileIcon,
    mobileMessage,
    mobileValidate,
    nifIcon,
    nifMessage,
    nifValidate,
    phoneIcon,
    phoneMessage,
    phoneValidate,
    requestingChannelOptions,
    searchClient,
    onChangeEmaiInter,
    onChangeMobileInter,
    onChangeNameInter,
    onChangePhoneInter,
    onChangeClientTypeRadioGroupProps,
    onBlurNif,
    onBlurEmail,
    onBlurNumberPhone,
    onBlurNumberMobile,
    handleAddClient,
    // setCustomerBC,
    // setShowClientData,
    handleProposalDataTextFieldChange,
    handleRequiredDataTextFieldChange,
    handleNewClientCheckboxChange,
    handleClientChange,
    settings.Data.Vehicles_ClientTypology,
    settings.Data.Vehicles_InputChannel,
    handleNifOrClientNameSearch
    ]
  );
  // ESTRUTURA DA PÁGINA STEP 1

  // ESTRUTURA DA PÁGINA STEP 2
  const mostFrequentModalProps = useMemo(() => ({
      deleteButtonText: 'Apagar mais frequente',
      deleteButtonProps: {
        disabled: (selectedFrequentVehicles.filter(x => x.checked === true).length !== 0 && selectedFrequentVehicles.length !== 0) ? false :true,
        onClick:handleDeleteButtonMostFrequentModalProps
      },
      title: "Definições de dados de viaturas mais frequentes",
      cancelButtonText: "Cancelar",
      handleClose: handleCloseMostFrequentModalProps,
      confirmButtonText: "Aplicar",
      confirmButtonProps: {
        onClick: handleConfirmButtonMostFrequentModalProps,
      },
      frequentSearchResultProps: {
        options: frequentSearchOptions,
        freeSolo:true,

        // helperText: 'teste',
        // error:true,
        placeholder: "Pesquisar por viaturas mais frequentes",
        onInputChange: handleFrequentSearchModalProps,
      },
      searchButtonText: "Pesquisar",
      searchButtonProps: {
        onClick: handleSearchButtonModalProps,
      },
      groupTotalNumber: mostFrequentSearched.length !== 0 ? mostFrequentSearched.length : mostFrequent.length,
      groupTotalText: `de ${mostFrequentTotal} configurações gravadas`,
      configurations: mostFrequentConfigurations,
    }),
    [
      handleCloseMostFrequentModalProps,
      handleConfirmButtonMostFrequentModalProps,
      handleDeleteButtonMostFrequentModalProps,
      handleFrequentSearchModalProps,
      handleSearchButtonModalProps,
      mostFrequent.length,
      mostFrequentConfigurations,
      frequentSearchOptions,
      mostFrequentTotal,
      mostFrequentSearched,
      selectedFrequentVehicles
    ]
  );

  const groupEditModalPropsMemo = useMemo(() => ({
    title: "Alterar em grupo",
    manuallyAddedAccessoriesTitle: propertyHidden('Acessórios', 'Detalhes Viatura') ? '' : "Acessórios adicionados manualmente",
    addAccessoryButtonText: propertyHidden('Acessórios', 'Detalhes Viatura') ? '' : "Adicionar Acessório",
    addAccessoryButtonProps: {
      // TODO disabled: If matricula
      disabled: propertyDisabled('Acessórios', 'Detalhes Viatura'),
      hidden: propertyHidden('Acessórios', 'Detalhes Viatura'),
      onClick: onEditGroupAddAccessoryClick,
    },
    extraAccessories: editGroupExtraAccessories, //getEditGroupAccessories,
    // disabled by default
    businessSettingsTitle: propertyHidden('Desconto s/IVA', 'Detalhes Viatura') && propertyHidden('Campanha s/Iva', 'Detalhes Viatura') && propertyHidden('Rappel s/Iva', 'Detalhes Viatura') ? '' : "Configurações de negócio",
    discountWithoutTaxTextFieldProps: {
      label:  propertyHidden('Desconto s/IVA', 'Detalhes Viatura') ? '' : "Desconto sem e com IVA",
      value: vehicleGroupEditModalValues?.discountWithoutTax,
      name:"discountWithoutTax",
      onChange: handleGroupEditModalChange,
      disabled: propertyDisabled('Desconto s/IVA', 'Detalhes Viatura'),
      hidden: propertyHidden('Desconto s/IVA', 'Detalhes Viatura'),
    },
    discountWithTaxTextFieldProps: {
      value: vehicleGroupEditModalValues?.discountWithTax,
      name:"discountWithTax",
      onChange: handleGroupEditModalChange,
      disabled: true,
      // disabled: propertyDisabled('Desconto c/IVA', 'Detalhes Viatura'),
      hidden: propertyHidden('Desconto c/IVA', 'Detalhes Viatura'),
    },
    discountWithTaxPercentageTextFieldProps: {
      value: vehicleGroupEditModalValues?.discountWithTaxPercentage,
      name:"discountWithTaxPercentage",
      onChange: handleGroupEditModalChange,
      disabled: propertyDisabled('Desconto em percentagem', 'Detalhes Viatura'),
      hidden: propertyHidden('Desconto em percentagem', 'Detalhes Viatura'),
    },

    CampaignWithoutTaxTextFieldProps: {
      label: propertyHidden('Campanha s/Iva', 'Detalhes Viatura') ? '' : "Campanha sem e com IVA",
      value: vehicleGroupEditModalValues?.campaignWithoutTax,
      name:"campaignWithoutTax",
      onChange: handleGroupEditModalChange,
      disabled: propertyDisabled('Campanha s/Iva', 'Detalhes Viatura'),
      hidden: propertyHidden('Campanha s/Iva', 'Detalhes Viatura'),
    },
    // disabled by default
    CampaignWithTaxTextFieldProps: {
      value: vehicleGroupEditModalValues?.campaignWithTax,
      name:"campaignWithTax",
      onChange: handleGroupEditModalChange,
      disabled: true,
      // disabled: propertyDisabled('Campanha c/Iva', 'Detalhes Viatura'),
      hidden: propertyHidden('Campanha c/Iva', 'Detalhes Viatura'),
    },
    CampaignWithTaxPercentageTextFieldProps: {
      value: vehicleGroupEditModalValues?.campaignWithTaxPercentage,
      name:"campaignWithTaxPercentage",
      onChange: handleGroupEditModalChange,
      disabled: propertyDisabled('Campanha em percentagem', 'Detalhes Viatura'),
      hidden: propertyHidden('Campanha em percentagem', 'Detalhes Viatura'),
    },

    rappelWithoutTaxTextFieldProps: {
      label: propertyHidden('Rappel s/Iva') ? '' : "Rappel sem e com IVA",
      value: vehicleGroupEditModalValues?.rappelWithoutTax,
      name:"rappelWithoutTax",
      onChange: handleGroupEditModalChange,
      disabled: propertyDisabled('Rappel s/Iva', 'Detalhes Viatura'),
      hidden: propertyHidden('Rappel s/Iva', 'Detalhes Viatura'),
    },
    // disabled by default
    rappelWithTaxTextFieldProps: {
      value: vehicleGroupEditModalValues?.rappelWithTax,
      name:"rappelWithTax",
      onChange: handleGroupEditModalChange,
      disabled: true,
      // disabled: propertyDisabled('Rappel c/Iva', 'Detalhes Viatura'),
      hidden: propertyHidden('Rappel c/Iva', 'Detalhes Viatura'),
    },
    rappelWithTaxPercentageTextFieldProps: {
      value: vehicleGroupEditModalValues?.rappelWithTaxPercentage,
       name:"rappelWithTaxPercentage",
       onChange: handleGroupEditModalChange,
       disabled: propertyDisabled('Rappel em percentagem', 'Detalhes Viatura'),
       hidden: propertyHidden('Rappel em percentagem', 'Detalhes Viatura'),
    },

    extraRappelTextFieldProps: {
      label: propertyHidden('Rappel extra') ? '' : "Rappel extra",
      value: vehicleGroupEditModalValues?.extraRappel,
      name:"extraRappel",
      disabled: propertyDisabled('Rappel extra', 'Detalhes Viatura'),
      hidden: propertyHidden('Rappel extra', 'Detalhes Viatura'),
    },
    associateLotTitle: propertyHidden('Nome Identificativo do Lote') ? '' : "Associar lote",
    lotIDTextFieldProps: {
      label: propertyHidden('Nome Identificativo do Lote', 'Detalhes Viatura') ? '' :"Nome Identificativo do lote",
      value: vehicleGroupEditModalValues?.lotId,
      name:"lotId",
      onChange: handleGroupEditModalChange,
      disabled: propertyDisabled('Nome Identificativo do Lote', 'Detalhes Viatura'),
      hidden: propertyHidden('Nome Identificativo do Lote', 'Detalhes Viatura'),
    },
    cancelButtonText: "Cancelar",
    handleClose: () => {},
    confirmButtonText: "Aplicar",
    confirmButtonProps: {
      onClick: handleGroupEditModalApplyButtonClick,
    },
  }),[
    handleGroupEditModalChange,
    handleGroupEditModalApplyButtonClick, onEditGroupAddAccessoryClick,
    vehicleGroupEditModalValues, editGroupExtraAccessories,
    propertyDisabled, propertyHidden
  ])


  const [toggleCollapseTechnicalCharacteristics, setToggleCollapseTechnicalCharacteristics] = useState<boolean>(true);
  const [toggleCollapseSpaceAndDimensions, setToggleCollapseSpaceAndDimensions] = useState<boolean>(true);
  const [toggleCollapseEngineSpecifications, setToggleCollapseEngineSpecifications] = useState<boolean>(true);

  const handleVehicleDataPropsDeleteButton_Click = useCallback(async () => {
    const vehiclesToDelete = selectedRows.map((item, index) => {
      let vehicle = vehicleDataTable[item];
      if(vehicle.id === 0){
        //a viatura não foi criada na bd. Criar id temporário para eliminação no datatable
        vehicle.id = -index-1;
      }
      return vehicleDataTable[item];
    });

    let failedToDeleteVehicles: IVehicle[] = [];
    //Eliminar viaturas no servidor. Em paralelo gera deadlocks na bd. Usar sequencial
    for (const vehicle of vehiclesToDelete) {
      if(vehicle.id > 0){
        let response = await vehiclesService.delete(vehicle.id);
        if (!response.succeeded) {
          failedToDeleteVehicles.push(vehicle);
        }
      }
    }
    let failedToDeleteIdSet = new Set(failedToDeleteVehicles.map(item => item.id));
    let deletedVehicles = vehiclesToDelete.filter(item => !failedToDeleteIdSet.has(item.id));
    let deletedIdSet = new Set(deletedVehicles.map(item => item.id));
    let vehicles = vehicleDataTable.filter(item => !deletedIdSet.has(item.id));
    setVehicleDataTable(vehicles);
    setChangedProposal(true);
    setVehiclesListingTotal(vehicles.length);
    if(failedToDeleteVehicles.length > 0){
      openAlert({
        variant: "error",
        text: `Um ou mais veículos não foram eliminados. Por favor tente novamente. Se o problema persistir, contate o administrador.`,
        title: "Atenção",
      });
    }
    else{
      setSelectedRows([]);
      openAlert({
        variant: "success",
        text: "Eliminado com sucesso!",
        title: "Sucesso",
        autoHideDuration: 5000
      });
    }
  }
  ,[selectedRows, vehicleDataTable])

  const vehicleDataProps = useMemo(() => {
    return ({
      deleteButtonText:"Eliminar selecionados",
      deleteButtonProps: {
        disabled: groupEditButtonDisabled, //Reutilização de var de estado
        onClick:handleVehicleDataPropsDeleteButton_Click,
      },
      configureMostFrequentModalProps: {
        title: 'Definir viatura como mais frequente',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Guardar e aplicar',
        confirmButtonProps: {
          onClick: handleSaveVehicleFrequentClick,
        },
        configurationTitleTextFieldProps: {
          label: 'Adicionar titulo ou nome de cliente',
          placeholder: 'Inserir',
          value: frequentTitle,
          onChange: handleFrequentTitleChange,
        },
        visibleRadioGroupProps: {},
        visibleTo: {
          title: 'Vísivel para',
          selfCheckboxProps: {
            value: "0",
            label: "Só para mim",
            onClick: handleFrequentVisibilityClick,
          },
          kintoCheckboxProps: {
            value: "1",
            label: "Kinto (comerciais Kinto tem acesso)",
            onClick: handleFrequentVisibilityClick,
          },
          allCheckboxProps: {
            value: "2",
            label: "Todos",
            onClick: handleFrequentVisibilityClick,
          },
        },
        icons: VehicleUtilities.getFuelTypeIconFor((newFrequent as IVehicle)?.fuel_Type),
        vehicleDescription: (newFrequent as IVehicle)?.versionDescription,
        vehicleCharacteristics: (newFrequent as IVehicle)?.caracteristics,
        seeMoreConditionsLinkText: 'Mostrar mais condições ',
        hideConditionsLinkText: 'Ocultar condições ',
        rows: [
          {
            pvp: {
              label: 'PVP',
              value: `${(newFrequent as IVehicle)?.pvp} €`,
            },
            extras: {
              label: 'Extras',
              value: `${(newFrequent as IVehicle)?.totalExtraWithTax} €`,
            },
            proformaPrice: {
              label: 'Preço FT proforma',
              value: `${(newFrequent as IVehicle)?.proformaInvoicePriceWithTax} €`,
            },
            businessValue: {
              label: 'Preço de negócio',
              value: `${(newFrequent as IVehicle)?.businessValueWithTax} €`,
            },
          },
        ],
      },
      mostFrequentModalProps: mostFrequentModalProps,
      tableProps: {
        openDetails: isDetailsStep2VehicleOpen,
        headCells: vehicleDataTableHeadCells,
        rows: vehicleDataTable.filter(x => x.hidden === false).map((cur,index) => {

          return {
            cells: [
              {
                value: cur.makeId,
                placeholder: 'Selecionar',
                options: brandOptions,
                name: 'makeId-'+index,
                onChange:handleTableInputMakeChange
              },
              {
                value: cur.modelId,
                placeholder: 'Selecionar modelo',
                options: cur.makeId ? getModel(cur.makeId,cur?.modelId) : [],
                name: 'modelId-'+index,
                onChange:handleTableInputModelChange,
              },
              {
                value: cur.versionId,
                placeholder: 'Selecionar descrição',
                options: cur.modelId ? getVersion(cur.makeId,cur.modelId,index) : [],
                name: 'versionId-'+index,
                // disableFilterOptions:true,
                onChange:handleTableInputVersionChange,
              },
              {
                value: cur.pvp,
                name: "pvp",
                warning: false,
                disabled: true,
              },
              {
                value: cur.totalExtraWithoutTax,
                name: "totalExtraWithoutTax",
                disabled: true,
              },
              {
                value: cur.discountWithTaxTotal,
                name: "discountWithTaxTotal",
                disabled: true,
              },
              {
                value: cur.proformaInvoicePriceWithoutTax,
                name: "proformaInvoicePriceWithoutTax",
                disabled: true,
              },
              {
                value: cur.businessValueWithoutTax,
                name: "businessValueWithoutTax",
                disabled: true,
              },
            ],
            details: {
              tableIndex: 0,
              expandExtraAccessoriesText: 'Mostrar todos os acessórios',
              collapseExtraAccessoriesText: 'Ocultar acessórios',
              expandExtraStandardsText: 'Mostrar todos os extras',
              collapseExtraStandardsText: 'Ocultar extras',
              noSelectedExtraStandardsText: [
                'Não existem extras selecionados',
                'Selecione os extras da lista apresentada em Todos os extras',
              ],
              otherVehicleDataTitle: 'Outros dados da viatura',
              registrationTextFieldProps: {
                label: 'Matrícula',
                value: cur.licensePlate,
                placeholder: '00-00-00',
                name:index +'-licensePlate',
                onChange: handleOnChange,
                disabled: propertyDisabled('Matricula', 'Detalhes Viatura'),
                hidden : propertyHidden('Matricula', 'Detalhes Viatura'),
                warning: propertyValidation( cur.licensePlate, 'Matricula', 'Detalhes Viatura'),
              },
              fuelType: {
                label: 'Combustível',
                value: cur.fuelType,
                disabled: propertyDisabled('Combustível', 'Detalhes Viatura'),
                hidden : propertyHidden('Combustível', 'Detalhes Viatura'),
                warning: propertyValidation(cur.fuelType, 'Combustível', 'Detalhes Viatura'),
              },
              cylinderDisplacement: {
                label: 'Cilindrada',
                value: cur.engineCc.toString(),
                disabled: propertyDisabled('Cilindrada', 'Detalhes Viatura'),
                hidden : propertyHidden('Cilindrada', 'Detalhes Viatura'),
                warning: propertyValidation(cur.engineCc, 'Cilindrada', 'Detalhes Viatura'),
              },
              potency: {
                label: 'Potência',
                value: cur.powerHP.toString(),
                disabled: propertyDisabled('Potência', 'Detalhes Viatura'),
                hidden : propertyHidden('Potência', 'Detalhes Viatura'),
                warning: propertyValidation(cur.powerHP, 'Potência', 'Detalhes Viatura'),
              },
              associateLotText: 'Associar lote',
              lotIDTextFieldProps: {
                label: 'Nome Identificativo do Lote',
                placeholder: 'Inserir nome do lote',
                value: cur.lotId,
                name:index +'-lotId',
                onChange: handleOnChange,
                disabled: propertyDisabled('Nome Identificativo do Lote', 'Detalhes Viatura'),
                hidden: propertyHidden('Nome Identificativo do Lote', 'Detalhes Viatura'),
                warning: propertyValidation( cur.lotId, 'Nome Identificativo do Lote', 'Detalhes Viatura'),
              },
              businessSettingsTitle: 'Configurações do negócio',
              vehicleValuesText: 'Valores da viatura',
              valuesWithoutTaxText: 'Valores sem IVA',
              basePriceWithoutTaxTextFieldProps: {
                label: 'Preço base',
                placeholder: '0',
                endAdornment: '€',
                value: cur.priceexclVAT,
                name:index +'-priceexclVAT',
                onChange: handleOnChange,
                disabled: propertyDisabled('Preço base s/IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Preço base s/IVA', 'Detalhes Viatura'),
                warning: propertyValidation(cur.priceexclVAT, 'Preço base s/IVA', 'Detalhes Viatura'),
              },
              // disabled by default
              totalExtraWithoutTaxTextFieldProps: {
                label: 'Total extras',
                placeholder: '0',
                defaultValue: '0',
                endAdornment: '€',
                value: cur.totalExtraWithoutTax,
                name:index +'-totalExtraWithoutTax',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('Total extras s/IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Total extras s/IVA', 'Detalhes Viatura'),
                warning: propertyValidation( cur.totalExtraWithoutTax, 'Total extras s/IVA', 'Detalhes Viatura'),
              },
              // disabled by default
              totalAccessoriesWithoutTaxTextFieldProps: {
                label: 'Total acessórios',
                placeholder: '0',
                defaultValue: '0',
                endAdornment: '€',
                value: cur.totalAccessoriesWithout,
                name:index +'-totalAccessoriesWithout',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('Total acessórios s/IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Total acessórios s/IVA', 'Detalhes Viatura'),
                warning: propertyValidation(cur.totalAccessoriesWithout, 'Total acessórios s/IVA', 'Detalhes Viatura'),
              },
              discountWithoutTaxTextFieldProps: {
                label: 'Desconto',
                placeholder: '0',
                defaultValue: '0',
                endAdornment: '€',
                value: cur.discountWithoutTax,
                name:index +'-discountWithoutTax',
                onChange: handleOnChange,
                disabled: propertyDisabled('Desconto s/IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Desconto s/IVA', 'Detalhes Viatura'),
                warning: propertyValidation(cur.discountWithoutTax, 'Desconto s/IVA', 'Detalhes Viatura'),
              },
              // disabled by default
              proformaInvoicePriceWithoutTaxTextFieldProps: {
                label: 'Preço fatura proforma',
                placeholder: '0',
                endAdornment: '€',
                value: cur.proformaInvoicePriceWithoutTax,
                name:index +'-proformaInvoicePriceWithoutTax',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('Preço fatura proforma s/IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Preço fatura proforma s/IVA', 'Detalhes Viatura'),
                warning: propertyValidation(cur.proformaInvoicePriceWithoutTax, 'Preço fatura proforma s/IVA', 'Detalhes Viatura'),
              },
              campaignWithoutTaxTextFieldProps: {
                label: 'Campanha',
                placeholder: '0',
                defaultValue: '0',
                endAdornment: '€',
                value: cur.campaignWithoutTax,
                name:index +'-campaignWithoutTax',
                onChange: handleOnChange,
                disabled: propertyDisabled('Campanha s/Iva', 'Detalhes Viatura'),
                hidden : propertyHidden('Campanha s/Iva', 'Detalhes Viatura'),
                warning: propertyValidation( cur.campaignWithoutTax, 'Campanha s/Iva', 'Detalhes Viatura'),
              },
              rappelWithoutTaxTextFieldProps: {
                label: 'Rappel',
                placeholder: '0',
                defaultValue: '400',
                endAdornment: '€',
                value: cur.rappelWithoutTax,
                name:index +'-rappelWithoutTax',
                onChange: handleOnChange,
                disabled: propertyDisabled('Rappel s/Iva', 'Detalhes Viatura'),
                hidden : propertyHidden('Rappel s/Iva', 'Detalhes Viatura'),
                warning: propertyValidation( cur.rappelWithoutTax, 'Rappel s/Iva', 'Detalhes Viatura'),
              },
              onDiscountFileClick: () => alert('discount file'),
              onRappelFileClick: () => alert('rappel file'),
              rappelWithoutTaxExtraTextFieldProps: {
                label: 'Rappel extra',
                placeholder: '0',
                defaultValue: '400',
                endAdornment: '€',
                value: cur.extraRappel,
                name:index +'-extraRappel',
                onChange: handleOnChange,
                disabled: propertyDisabled('Rappel extra', 'Detalhes Viatura'),
                hidden : propertyHidden('Rappel extra', 'Detalhes Viatura'),
                // se não tiver regras de validação verifica se o extraRappel tem valor superior a 0 para indicar que o rappel precisa ser validado.
                warning: propertyValidation( cur.extraRappel, 'Rappel extra', 'Detalhes Viatura')
                  ? propertyValidation( cur.extraRappel, 'Rappel extra', 'Detalhes Viatura')
                  : cur.extraRappel > 0
                    ? true
                    : false,
              },
              hasRappelWithoutTaxExtraThreshold: cur.extraRappel > 0 ? true : false,
              rappelWithoutTaxExtraThresholdLabel: 'Carece validação',
              valuesWithTaxText: 'Valores com IVA',
              basePriceWithTaxTextFieldProps: {
                placeholder: '0',
                endAdornment: '€',
                value: cur.consumersPrice,
                name:index +'-consumersPrice',
                onChange: handleOnChange,
                disabled: propertyDisabled('Preço base c/IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Preço base c/IVA', 'Detalhes Viatura'),
                warning: propertyValidation(cur.consumersPrice, 'Preço base c/IVA', 'Detalhes Viatura'),
              },
              // disabled by default
              totalExtraWithTaxTextFieldProps: {
                placeholder: '0',
                endAdornment: '€',
                value: cur.totalExtraWithTax,
                name:index +'-totalExtraWithTax',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('Total extras c/IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Total extras c/IVA', 'Detalhes Viatura'),
                warning: propertyValidation( cur.totalExtraWithTax, 'Total extras c/IVA', 'Detalhes Viatura'),
              },
              // disabled by default
              totalAccessoriesWithTaxTextFieldProps: {
                placeholder: '0',
                endAdornment: '€',
                value: cur.totalAccessoriesWith,
                name:index +'-totalAccessoriesWith',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('Total acessórios c/IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Total acessórios c/IVA', 'Detalhes Viatura'),
                warning: propertyValidation(cur.totalAccessoriesWith, 'Total acessórios c/IVA', 'Detalhes Viatura'),
              },
              // disabled by default
              discountWithTaxTextFieldProps: {
                placeholder: '0',
                endAdornment: '€',
                value: cur.discountWithTax,
                name:index +'-discountWithTax',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('Desconto c/IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Desconto c/IVA', 'Detalhes Viatura'),
                warning: propertyValidation(cur.discountWithTax, 'Desconto c/IVA', 'Detalhes Viatura'),
              },
              discountWithTaxExtraTextFieldProps: {
                placeholder: '0',
                endAdornment: '%',
                value: cur.discountWithTaxPercentage,
                name:index +'-discountWithTaxPercentage',
                onChange: handleOnChange,
                disabled: propertyDisabled('Desconto em percentagem', 'Detalhes Viatura'),
                hidden : propertyHidden('Desconto em percentagem', 'Detalhes Viatura'),
                warning: propertyValidation(cur.discountWithTaxPercentage, 'Desconto em percentagem', 'Detalhes Viatura'),
              },
              // disabled by default
              discountWithTaxTotalTextFieldProps: {
                placeholder: '0',
                endAdornment: '%',
                // regra para o valor total
                value: convertNumber((cur.discountWithTaxTotal/ (Number(cur.priceexclVAT) + cur.totalExtraWithoutTax + cur.totalAccessoriesWithout))*100).toFixed(2),
                //regra para a percentagem
                // value: cur.discountWithTaxTotal,
                name:index +'-discountWithTaxTotal',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('Desconto Total c/IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Desconto Total c/IVA', 'Detalhes Viatura'),
                warning: propertyValidation(convertNumber((cur.discountWithTaxTotal/ (Number(cur.priceexclVAT) + cur.totalExtraWithoutTax + cur.totalAccessoriesWithout))*100).toFixed(2),'Desconto Total c/IVA', 'Detalhes Viatura'),
              },
              // disabled by default
              proformaInvoicePriceWithTaxTextFieldProps: {
                placeholder: '0',
                endAdornment: '€',
                value: cur.proformaInvoicePriceWithTax,
                name:index +'-proformaInvoicePriceWithTax',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('Preço fatura proforma c/IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Preço fatura proforma c/IVA', 'Detalhes Viatura'),
                warning: propertyValidation(cur.proformaInvoicePriceWithTax, 'Preço fatura proforma c/IVA', 'Detalhes Viatura'),
              },
              // disabled by default
              campaignWithTaxTextFieldProps: {
                placeholder: '0',
                endAdornment: '€',
                value: cur.campaignWithTax,
                name:index +'-campaignWithTax',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('Campanha c/Iva', 'Detalhes Viatura'),
                hidden : propertyHidden('Campanha c/Iva', 'Detalhes Viatura'),
                warning: propertyValidation(cur.campaignWithTax, 'Campanha c/Iva', 'Detalhes Viatura'),
              },
              campaignWithTaxExtraTextFieldProps: {
                placeholder: '0',
                endAdornment: '%',
                value: cur.campaignWithTaxPercentage,
                name:index +'-campaignWithTaxPercentage',
                onChange: handleOnChange,
                disabled: propertyDisabled('Campanha em percentagem', 'Detalhes Viatura'),
                hidden : propertyHidden('Campanha em percentagem', 'Detalhes Viatura'),
                warning: propertyValidation(cur.campaignWithTaxPercentage, 'Campanha em percentagem', 'Detalhes Viatura'),
              },
              // disabled by default
              campaignWithTaxTotalTextFieldProps: {
                placeholder: '0',
                endAdornment: '€',
                value: cur.campaignWithTaxTotal,
                name:index +'-campaignWithTaxTotal',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('Total campanha', 'Detalhes Viatura'),
                hidden : propertyHidden('Total campanha', 'Detalhes Viatura'),
                warning: propertyValidation(cur.campaignWithTaxTotal, 'Total campanha', 'Detalhes Viatura'),
              },
              // disabled by default
              rappelWithTaxTextFieldProps: {
                placeholder: '0',
                endAdornment: '€',
                value: cur.rappelWithTax,
                name:index +'-rappelWithTax',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('Rappel c/Iva', 'Detalhes Viatura'),
                hidden : propertyHidden('Rappel c/Iva', 'Detalhes Viatura'),
                warning: propertyValidation(cur.rappelWithTax, 'Rappel c/Iva', 'Detalhes Viatura'),
              },
              rappelWithTaxExtraTextFieldProps: {
                placeholder: '0',
                endAdornment: '%',
                value: cur.rappelWithTaxPercentage,
                name:index +'-rappelWithTaxPercentage',
                onChange: handleOnChange,
                disabled: propertyDisabled('Rappel em percentagem', 'Detalhes Viatura'),
                hidden : propertyHidden('Rappel em percentagem', 'Detalhes Viatura'),
                warning: propertyValidation(cur.rappelWithTaxPercentage, 'Rappel em percentagem', 'Detalhes Viatura'),
              },
              // disabled by default
              rappelWithTaxTotalTextFieldProps: {
                placeholder: '0',
                endAdornment: '€',
                value: cur.rappelWithTaxTotal,
                name:index +'-rappelWithTaxTotal',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('Rappel Total c/IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Rappel Total c/IVA', 'Detalhes Viatura'),
                warning: propertyValidation(cur.rappelWithTaxTotal, 'Rappel Total c/IVA', 'Detalhes Viatura'),
              },
              expensesText:  propertyHidden('Ecovalor', 'Detalhes Viatura') && propertyHidden('Isv sem IVA', 'Detalhes Viatura') && propertyHidden('PVP sem IVA', 'Detalhes Viatura') && propertyHidden('Despesas de legalização', 'Detalhes Viatura')
                ? ''
                : 'Despesas',
              ecoValueTextFieldProps: {
                label: 'Ecovalor',
                placeholder: '0',
                endAdornment: '€',
                value: cur?.recycling_charge,
                name:index +'-recycling_charge',
                onChange: handleOnChange,
                disabled: propertyDisabled('Ecovalor', 'Detalhes Viatura'),
                hidden : propertyHidden('Ecovalor', 'Detalhes Viatura'),
                warning: propertyValidation(cur?.recycling_charge, 'Ecovalor', 'Detalhes Viatura'),
              },
              ISV_WithoutTaxTextFieldProps: {
                label: 'ISV sem IVA',
                placeholder: '0',
                endAdornment: '€',
                value: cur?.luxury_tax,
                name:index +'-luxury_tax',
                onChange: handleOnChange,
                disabled: propertyDisabled('Isv sem IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Isv sem IVA', 'Detalhes Viatura'),
                warning: propertyValidation(cur?.luxury_tax, 'Isv sem IVA', 'Detalhes Viatura'),
              },
              // disabled by default
              PVP_WithoutTaxTextFieldProps: {
                label: 'PVP sem IVA',
                placeholder: '0',
                endAdornment: '€',
                value: cur?.pvp,
                name:index +'-pvp',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('PVP sem IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('PVP sem IVA', 'Detalhes Viatura'),
                warning: propertyValidation(cur?.pvp, 'PVP sem IVA', 'Detalhes Viatura'),
              },
              legalizationAndTransportCostsTextFieldProps: {
                label: 'Despesas de legalização e transporte',
                placeholder: '0',
                endAdornment: '€',
                value: cur.transport_x0026_immatriculation_cost,
                name:index +'-transport_x0026_immatriculation_cost',
                onChange: handleOnChange,
                disabled: propertyDisabled('Despesas de legalização', 'Detalhes Viatura'),
                hidden : propertyHidden('Despesas de legalização', 'Detalhes Viatura'),
                warning: propertyValidation(cur.transport_x0026_immatriculation_cost, 'Despesas de legalização', 'Detalhes Viatura'),
              },
              addAccessoryTitle: 'Selecionar Acessórios/extras',
              addAccessoryText: 'Adicionar acessórios',
              selectExtraStandardTitle: 'Escolher extras standard s/IVA',
              rentalDataText: propertyDisabled('Tipo de aluguer', 'Detalhes Viatura') && propertyHidden('Condições comerciais', 'Detalhes Viatura') && propertyHidden('Quantidade', 'Detalhes Viatura') ? "" :' Dados do aluguer',
              rentalType: {
                label: 'Tipo de aluguer',
                value: cur.typeofRental,
                disabled: propertyDisabled('Tipo de aluguer', 'Detalhes Viatura'),
                hidden : propertyHidden('Tipo de aluguer', 'Detalhes Viatura'),
                warning: propertyValidation(cur.typeofRental, 'Tipo de aluguer', 'Detalhes Viatura'),
              },
              businessConditions: {
                label: 'Condiçoes comerciais',
                value: cur.commercialConditions,
                disabled: propertyDisabled('Condições comerciais', 'Detalhes Viatura'),
                hidden : propertyHidden('Condições comerciais', 'Detalhes Viatura'),
                warning: propertyValidation(cur.commercialConditions, 'Condições comerciais', 'Detalhes Viatura'),
              },
              amountTextFieldProps: {
                label: 'Quantidade',
                placeholder: '0',
                value: cur.quantity,
                name:index +'-quantity',
                onChange: handleOnChange,
                disabled: propertyDisabled('Quantidade','Detalhes Viatura'),
                hidden : propertyHidden('Quantidade', 'Detalhes Viatura'),
                warning: propertyValidation(cur.quantity, 'Quantidade', 'Detalhes Viatura'),
              },
              businessPriceText: 'Preço do negócio',
              businessValueWithoutTaxTextFieldProps: {
                label: 'Valor de negócio sem IVA',
                defaultValue: '0 ',
                endAdornment: '€',
                value: cur.businessValueWithoutTax,
                name:index +'-businessValueWithoutTax',
                onChange: handleOnChange,
                disabled: propertyDisabled('Valor de negócio sem IVA', 'Detalhes Viatura'),
                hidden : propertyHidden('Valor de negócio sem IVA', 'Detalhes Viatura'),
                warning: propertyValidation(cur.businessValueWithoutTax, 'Valor de negócio sem IVA', 'Detalhes Viatura'),
              },
              //disabled: true
              businessValueWithTaxTextFieldProps: {
                label: 'Valor de negócio com IVA',
                endAdornment: '€',
                defaultValue: '0 ',
                value: cur.businessValueWithTax,
                name:index +'-businessValueWithTax',
                onChange: handleOnChange,
                disabled: true,
                // disabled: propertyDisabled('Valor de negócio com IVA', 'Detalhes Viatura'),
                hidden: propertyHidden('Valor de negócio com IVA', 'Detalhes Viatura'),
                warning: propertyValidation(cur.businessValueWithTax, 'Valor de negócio com IVA', 'Detalhes Viatura'),
              },
              addAccessoryButtonText: 'Adicionar Acessório',
              addAccessoryButtonProps: {
                name:index+"-"+cur?.id,
                onClick: handleAddAccessoryButtonPropsClick,
                disabled: propertyDisabled('Acessórios', 'Detalhes Viatura'),
                hidden: propertyHidden('Acessórios', 'Detalhes Viatura'),
              },
              searchStandardExtraButtonText: 'Pesquisar',
              searchStandardExtraButtonProps: {
                name:String(index),
                onClick:handleExtraSearchPropsClick
              },

              allExtrasButtonText: "Todos os extras",
              allExtrasButtonProps: {},
              selectedExtrasButtonText: "Extras selecionados",
              selectedExtrasButtonProps: {},
              extraAccessories: getAccessorieshydra(index),
              onExtraCheckboxChange: handleExtraStandarCheckChange,
              // extras:[],
              extraIncompatibilityModalProps: {
                title: "Incompatibilidade de extras",
                cancelButtonText: "Manter opções",
                confirmButtonText: "Atualizar",
                confirmButtonProps: () => {
                  console.log('extraIncompatibilityModalProps')
                  alert('extraIncompatibilityModalProps')
                },
                incompatabilityText: [
                  "Estes dois acessórios que selecionou são incompativeis.",
                  "Por favor escolha, qual opção pretende manter?",
                ],
                requiredExtrasText: [
                  "A opção selecionada requer que inclua também os extras abaixo:",
                ],
                alreadyIncludedText: [
                  "O Pack selecionado inclui os extras abaixo, pretende manter todas as opçoes mesmo assim?",
                ],
                titleRequiredExtras: "Extras de seleccção obrigatória",
                titlePacksIncompatibility: "Packs de Extras incompatíveis"
                // extras: [],
              },
              // extraStandard: cur.extraStandard.filter(x => x.visible === true), //getExtraStandardhydra(index),
              extraStandard: getExtraStandardhydra(index),
              extraStandardSearchResultProps: getExtraSearchForVehicleshydra(index),
              technicalCharacteristics: [
                {
                  title: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura') ? "" : "Caracteristicas técnicas",
                  characteristic: [
                    {
                      group: "vehicleSpec",
                      groupLabel: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura') ? "" : "Especificações da viatura",
                      label: "Ano do modelo",
                      value: cur?.modelYear,
                      disabled: propertyDisabled('Caracteristicas técnicas', 'Detalhes Viatura'),
                      hidden: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura'),
                    },
                    {
                      group: "vehicleSpec",
                      groupLabel: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura') ? "" : "Especificações da viatura",
                      label: "Data de registo",
                      value: cur?.recordDate,
                      disabled: propertyDisabled('Caracteristicas técnicas', 'Detalhes Viatura'),
                      hidden: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura'),
                    },
                    {
                      group: "vehicleSpec",
                      groupLabel: propertyHidden('Caracteristicas técnicas') ? "" : "Especificações da viatura",
                      label: "Quilometragem",
                      value: cur?.mileage.toString(),
                      disabled: propertyDisabled('Caracteristicas técnicas', 'Detalhes Viatura'),
                      hidden: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura'),
                    },
                    {
                      group: "vehicleSpec",
                      groupLabel: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura') ? "" : "Especificações da viatura",
                      label: "Número de portas",
                      value: cur?.numberofDoors.toString(),
                      disabled: propertyDisabled('Caracteristicas técnicas', 'Detalhes Viatura'),
                      hidden: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura'),
                    },
                    {
                      group: "vehicleSpec",
                      groupLabel: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura') ? "" : "Especificações da viatura",
                      label: "Estado",
                      value: cur?.status?.toString(),
                      disabled: propertyDisabled('Caracteristicas técnicas', 'Detalhes Viatura'),
                      hidden: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura'),
                    },
                    {
                      group: "vehicleSpec",
                      groupLabel: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura') ? "" : "Especificações da viatura",
                      label: "Uso",
                      value: otherChaDropdownOptions.find(x => x.value === cur?.use)?.label ?? cur?.use,
                      disabled: propertyDisabled('Caracteristicas técnicas', 'Detalhes Viatura'),
                      hidden: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura'),
                    },
                  ],
                  isCollapsible: true,
                  collapsed: toggleCollapseTechnicalCharacteristics,
                  //IGNORA O TIPO DO EVENTO
                  onCollapse: (isCollapsed: boolean) => {
                    setToggleCollapseTechnicalCharacteristics( x => {
                      let value = !x
                      return value
                    });
                  }
                },
                {
                  title: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : "Espaço e Dimensões",
                  characteristic: [
                    {
                      group: "iDim",
                      groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : 'Dimensões',
                      label: "Número de lugares",
                      value: cur && cur.quantity_of_Seats
                        ? vehicleDataTable[index].quantity_of_Seats.toString()
                        : "",
                      disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                      hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                    },
                    {
                      group: "iDim",
                      groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : 'Dimensões',
                      label: "Comprimento (mm)",
                      value: cur && cur.lengthmm
                        ? cur.lengthmm.toString()
                        : "",
                      disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                      hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                    },
                    {
                      group: "iDim",
                      groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : 'Dimensões',
                      label: "Largura (mm)",
                      value: cur && cur.widthmm
                        ? cur.widthmm.toString()
                        : "",
                      disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                      hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                    },
                    {
                      group: "iDim",
                      groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : 'Dimensões',
                      label: "Altura",
                      value: cur && cur.heightmm
                        ? cur.heightmm.toString()
                        : "",
                      disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                      hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                    },
                    {
                      group: "iDim",
                      groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : 'Dimensões',
                      label: "Volume Mala (L)	",
                      value: cur && cur.trunkVolumel
                        ? cur.trunkVolumel.toString()
                        : "",
                      disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                      hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                    },
                    {
                      group: "Dim&W",
                      groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : "Dimensões e pesos",
                      label: "Distância entre Eixos (mm)",
                      value: cur && cur.wheelBasemm
                        ? cur.wheelBasemm.toString()
                        : "",
                      disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                      hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                    },
                    {
                      group: "Dim&W",
                      groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : "Dimensões e pesos",
                      label: "Distância entre Eixos Dianteiros (mm)",
                      value: cur && cur.trainRearWheelsmm
                        ? vehicleDataTable[index].trainRearWheelsmm.toString()
                        : "",
                      disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                      hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                    },
                    {
                      group: "Dim&W",
                      groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : "Dimensões e pesos",
                      label: "Distância entre Eixos traseiros (mm)",
                      value: cur && cur.trainFrontWheelsmm
                        ? vehicleDataTable[index].trainFrontWheelsmm.toString()
                        : "",
                      disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                      hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                    },
                  ],
                  isCollapsible: true,
                  collapsed: toggleCollapseSpaceAndDimensions,
                  //IGNORA O TIPO DO EVENTO
                  onCollapse: (isCollapsed: boolean) => {
                    setToggleCollapseSpaceAndDimensions( x => {
                      let value = !x
                      return value
                    });
                  }
                },
                {
                  title: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Especificações do motor",
                  characteristic: [
                    {
                      group: "motor",
                      groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Motor",
                      label: "Taxa de compressão",
                      value: cur && cur.compressionratio
                        ? vehicleDataTable[index].compressionratio.toString()
                        : "",
                      disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                      hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                    },
                    {
                      group: "motor",
                      groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Motor",
                      label: "Diâmetro",
                      value: cur && cur.bore
                        ? cur.bore.toString()
                        : "",
                      disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                      hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                    },
                    {
                      group: "motor",
                      groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Motor",
                      label: "Binário Máximo (Nm)",
                      value: cur && cur.torqueNm
                        ? cur.torqueNm.toString()
                        : "",
                      disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                      hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                    },
                    {
                      group: "motor",
                      groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Motor",
                      label: "Potência máxima (kw)",
                      value: cur && cur.powerkW
                        ? cur.powerkW.toString()
                        : "",
                      disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                      hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                    },
                    {
                      group: "envP",
                      groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Performance Ambiental",
                      label: "Capacidade do Tanque de Combustível (L)",
                      value: cur && cur.fuelTankCapacityl
                        ? vehicleDataTable[index].fuelTankCapacityl.toString()
                        : "",
                      disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                      hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                    },
                    {
                      group: "envP",
                      groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Performance Ambiental",
                      label: "Tipo de Combustível ",
                      value: cur && cur.fuelType
                        ? cur.fuelType.toString()
                        : "",
                      disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                      hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                    },
                    {
                      group: "envP",
                      groupLabel: propertyDisabled('Especificações do motor') ? "" : "Performance Ambiental",
                      label: "CO2-Emissão Grama / Kg",
                      value: cur && cur.cO2DischargeDecF
                        ? vehicleDataTable[index].cO2DischargeDecF.toString()
                        : "",
                      disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                      hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                    },
                    {
                      group: "perf",
                      groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Performance",
                      label: "Velocidade Máxima (Km/H)",
                      value: cur && cur.maxspeedkmh
                        ? cur.maxspeedkmh.toString()
                        : "",
                      disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                      hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                    },
                    {
                      group: "perf",
                      groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Performance",
                      label: "Aceleração 0-100 Km / H",
                      value: cur && cur.acceleration0100kmh
                        ? vehicleDataTable[index].acceleration0100kmh.toString()
                        : "",
                      disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                      hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                    },
                    {
                      group: "perf",
                      groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Performance",
                      label: "Autonomia",
                      value: cur && cur.technMaxQuantityKm
                        ? vehicleDataTable[index].technMaxQuantityKm.toString()
                        : "",
                      disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                      hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                    },
                  ],
                  isCollapsible: true,
                  collapsed: toggleCollapseEngineSpecifications,
                  //IGNORA O TIPO DO EVENTO
                  onCollapse: (isCollapsed: boolean) => {
                    setToggleCollapseEngineSpecifications( x => {
                      let value = !x
                      return value
                    });
                  }

                },
              ],
              otherCharacteristics: [
                {
                  title: propertyHidden('BPM', 'Detalhes Viatura') && propertyHidden('Mudança de óleo', 'Detalhes Viatura') && propertyHidden('Uso(Categoria da viatura)', 'Detalhes Viatura')? "" : "Outra informaçãos/serviços",
                  characteristic: [
                    {
                      group: "vehicleSpec",
                      groupLabel: propertyHidden('BPM', 'Detalhes Viatura') ? "" : "BPM",
                      label: propertyHidden('BPM', 'Detalhes Viatura') ? "" : "BPM",
                      value: cur.bPM
                        ? cur.bPM.toString()
                        : "0",
                      disabled: propertyDisabled('BPM', 'Detalhes Viatura'),
                      hidden: propertyHidden('BPM', 'Detalhes Viatura'),
                    },
                    {
                      group: "vehicleSpec",
                      groupLabel: propertyHidden('Mudança de óleo', 'Detalhes Viatura') ? '' : 'Mudança de óleo', // aparenta só estar ativo na primeira propriedade
                      label: propertyHidden('Mudança de óleo', 'Detalhes Viatura') ? '' : 'Mudança de óleo',
                      value: cur.oilChange.toString(),
                      disabled: propertyDisabled('Mudança de óleo', 'Detalhes Viatura'),
                      hidden: propertyHidden('Mudança de óleo', 'Detalhes Viatura'),
                    },
                  ],
                  characteristicsDropdownProps: [
                    {
                      label: "Uso",
                      options: otherChaDropdownOptions,
                      value: cur.use,
                      name: index + "-use",
                      onChange: handleOnChange,
                      disabled: propertyDisabled('Uso(Categoria da viatura)', 'Detalhes Viatura'),
                      hidden: propertyHidden('Uso(Categoria da viatura)', 'Detalhes Viatura'),
                      warning: propertyValidation(cur.use, 'Uso(Categoria da viatura)', 'Detalhes Viatura'),
                    },
                  ],
                },
              ],
              // SK-850
              tiresTitle: '',
              tiresMeasurements: {
                options: [],
                label: 'Pesquise por CA ou veículo',
                placeholder: 'Pesquisar',
                value: '',
                // onInputChange: handleOnInputSearchOptions,
                freeSolo: true,
                disableClearable: false,
                hidden: true
              },
              tiresDivergentMeasurements: {
                options: [],
                label: 'Pesquise por CA ou veículo',
                placeholder: 'Pesquisar',
                value: '',
                // onInputChange: handleOnInputSearchOptions,
                freeSolo: true,
                disableClearable: false,
                hidden: true
              },
            },
          };
        }),
        expandableText: "Abrir Detalhes",
        closeExpandableText: "Fechar detalhes",
        handleSelected: (selected) => {
          console.log(selected);
          setSelectedRows([...selected])
        },

        onChange: handleTableInputsChange,
        warningText: "Dados Actualizados",
        actions: [
          {
            label: "Guardar frequente",
            onClick: handleAddFrequentVehicle,
          },
          {
            label: "Duplicar",
            onClick: handleDuplicateVehicleClick,
          },

          {
            label: "Eliminar",
            onClick: handleRemoveVehicleClick,
          },
        ],
      },
      vehicleSearchProps: {
        placeholder: "Pesquisar",
        label: "Pesquise por Marca ou outras caracteristicas",
        options: vehicleSearchOptions,
        value: brandInput,
        onChange: handleVehicleSearchPropsChange,
      },
      // botão veículos pré-configurados ( seleção do tipo de campanha)
      vehicleTypeDropdownProps: {
        placeholder: "Veículos pré configurados",
        preConfiguredVehiclesLabel: 'Veículos pré configurados',
        newCampaingnVehiclesLabel: "Veículos de campanha novos",
        usedCampaingnVehiclesLabel: "Veículos de campanha usados",
        usedVehiclesLabel: "Veículos usados",
        handleSelected: handleVehiclesCampaignChange,
      },
      loadProformaButtonText: "Carregar Proforma",
      loadProformaButtonProps: {
        hidden:true
      },
      onProformaFileChange: doNothing,
      uploadProformaModalProps: {
        title: "Carregar proforma(s)",
        handleClose: doNothing,
        proformaFilesProgressBarProps,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Aplicar",
        confirmButtonProps: { onClick: doNothing },
      },
      mostFrequentButtonText: "Mais Frequentes",
      mostFrequentButtonProps: {
        onClick: loadMostFrequent,
      },
      searchButtonText: 'Pesquisar',
      searchButtonProps: { onClick: handleSearchOptionsClick },
      filterIconProps: {},
      eraseLinkText: "LIMPAR",
      eraseLinkProps: {
        disabled: disable,
        onClick: handleEraseLinkVehiclePropsClick,
      },
      groupEditButtonText: "Editar em grupo",
      groupEditButtonProps: { disabled: groupEditButtonDisabled },

      groupEditModalProps: groupEditModalPropsMemo,
      addVehicleButtonText: 'Adicionar Veículo',
      addVehicleButtonProps: {
        name: String(proposal.id),
        onClick: handleAddVehicleClick,
      },
      totalText: `Total de viaturas: ${vehiclesListingTotal}`,
      preConfiguredVehiclesModalProps: preConfiguredVehiclesModalProps,
      usedVehiclesModalProps: usedVehiclesModalProps,
      usedCampaignVehiclesModalProps: usedCampaignVehiclesModalProps,
      newCampaignVehiclesModalProps: newCampaignVehiclesModalProps,
      filterDrawerProps: filterDrawerProps,
    } as CreateProposalPageProps["vehicleDataProps"])
  }, [
    handleVehicleDataPropsDeleteButton_Click,
    // selectedVehicles,
    toggleCollapseEngineSpecifications,
    toggleCollapseTechnicalCharacteristics,
    toggleCollapseSpaceAndDimensions,

    brandOptions,
    brandInput,
    disable,
    proposal.id,
    groupEditButtonDisabled,
    otherChaDropdownOptions,
    isDetailsStep2VehicleOpen,
    vehicleDataTable,
    vehiclesListingTotal,
    vehicleSearchOptions,
    mostFrequentModalProps,
    usedVehiclesModalProps,
    newCampaignVehiclesModalProps,
    usedCampaignVehiclesModalProps,
    preConfiguredVehiclesModalProps,
    filterDrawerProps,
    getAccessorieshydra,
    getExtraStandardhydra,
    getModel,
    getVersion,
    handleOnChange,
    handleTableInputsChange,
    handleTableInputVersionChange,
    handleTableInputMakeChange,
    handleTableInputModelChange,
    handleVehicleSearchPropsChange,
    handleAddVehicleClick,
    handleDuplicateVehicleClick,
    handleEraseLinkVehiclePropsClick,
    handleExtraStandarCheckChange,
    handleRemoveVehicleClick,
    handleSaveVehicleFrequentClick,
    handleSearchOptionsClick,
    handleVehiclesCampaignChange,
    handleExtraSearchPropsClick,
    handleAddAccessoryButtonPropsClick,
    loadMostFrequent,
    getExtraSearchForVehicleshydra,
    groupEditModalPropsMemo,
    frequentTitle, handleAddFrequentVehicle,
    handleFrequentTitleChange, handleFrequentVisibilityClick,
    newFrequent,
    propertyHidden, propertyDisabled, propertyValidation
  ]);
  // FIM ESTRUTURA DA PÁGINA STEP 2

  // ESTRUTURA DA PÁGINA STEP 3
  const rentalTypesFilteredByProfileMemo = useMemo(() => {
    // LÓGICA COMPLETA
    // if( currentUser.isAdministrator){
    //   //mostra tudo
    //   return rentalTypes;
    // } else {
    //   // senão, se o perfil do utilizador tiver algum tipo de aluguer
    //   if(rentalTypes.some(x => currentUser.profile.profileRentalTypes.some(c => c.value === x.value))){
    //     return rentalTypes.filter(x => currentUser.profile?.profileRentalTypes.some(y => y.value === x.value));
    //   } else {
    //     // senão mostra todos
    //     return rentalTypes;
    //   }
    // }

    // LÓGICA SIMPLIFICADA
    if(rentalTypes.some(x => currentUser.profile?.profileRentalTypes.some(c => c.value === x.value))){
      return rentalTypes.filter(x => currentUser.profile?.profileRentalTypes.some(y => y.value === x.value));
    }
    return rentalTypes;
  },[
    // currentUser.isAdministrator,
    currentUser.profile, rentalTypes
  ])

  const commercialConditionsFilteredByProfileMemo = useMemo(() => {
    // LÓGICA SIMPLIFICADA
    if(commercialConditions.some(x => currentUser.profile?.profileCommercialConditions.some(c => c.value === x.value))){
      return commercialConditions.filter(x => currentUser.profile?.profileCommercialConditions.some(y => y.value === x.value));
    }
    return commercialConditions;
  },[
    // currentUser.isAdministrator,
    currentUser.profile, commercialConditions
  ])

  const paymentTermsFilteredByProfileMemo = useMemo(() => {
    // LÓGICA SIMPLIFICADA
    if(paymentTerms[1].result.some((x: Option) => currentUser.profile?.profilePaymentTerms.some(c => c.value === x.value))){
      return paymentTerms[1].result.filter((x: Option) => currentUser.profile?.profilePaymentTerms.some(y => y.value === x.value));
    }
    return paymentTerms[1].result;
  },[
    // currentUser.isAdministrator,
    currentUser.profile, paymentTerms
  ])

  const handleContractTypeSelected: CreateProposalPageProps['contractTypeProps']['contractTypeTableProps']['handleSelected'] = useCallback((selected: number[]) => {
    setSelectedRows(selected)
  },[])

  // const handleRentalTypeOnChangeProps = useCallback((index:number) => async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   let indexLease = -1
  //   const updatedRows = await Promise.all(
  //     vehicleDataTable.map(async (row, rowIndex) => {
  //       if (index === rowIndex) {
  //         row.typeofRental = e.target.value;
  //       }
  //       if (row.typeofRental === e.target.value && row.leaseQuoteNo !== "") {
  //         indexLease = rowIndex;
  //       }
  //       const data = await vehiclesService.getCommercialConditions(e.target.value);
  //       if (data.succeeded === true) {
  //         if (indexLease >= 0) {
  //           row.leaseQuoteNo = vehicleDataTable[indexLease].leaseQuoteNo;
  //         } else {
  //           row.leaseQuoteNo = "";
  //         }
  //         row.commercialOptions = data.value;
  //         row.commercialConditions = "";
  //       }
  //       return row;
  //     })
  //   );
  //   setChangedProposal(true);
  //   setVehicleDataTable(updatedRows);
  // }, [vehicleDataTable]);

  // const handleCommercialConditionsOnChangeProps = useCallback((index: number) => async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   const updatedRows = await Promise.all(
  //     vehicleDataTable.map(async (row, rowIndex) => {
  //       if (index === rowIndex) {
  //         let rentalSettings = rentalTypeSettings.find((element) => element.value === row.typeofRental);
  //         row.commercialConditions = commercialConditions.find((x) => x.value === e.target.value)?.value || '';
  //         const updatedSimulations = await Promise.all(
  //           row.simulations.map(async (sim, simIndex) => {
  //             row.vehicleReplacementOptions = rentalSettings.vs;
  //             row.maintenance = rentalSettings.maintenance;
  //             row.tires = rentalSettings.tyres;

  //             sim = await initVehicleReplacementOptionsDropDown(sim, row.commercialConditions);

  //             if (rentalSettings.insurance) {
  //               sim.secureOptions = rentalSettings.insurance;
  //               sim.insurance = sim.insurancePackageOptions?.length > 0 ? sim.insurancePackageOptions[0].value : '';
  //               sim = await initSelectedInsuranceDropDown(sim, row.use);
  //             } else {
  //               sim.secureOptions = rentalSettings.insurance;
  //               sim.insurance = '';
  //             }
  //             return sim;
  //           })
  //         );
  //         row.simulations = updatedSimulations;
  //       }
  //       return row;
  //     })
  //   );
  //   setChangedProposal(true);
  //   setVehicleDataTable(updatedRows);
  // },[
  //   initSelectedInsuranceDropDown, initVehicleReplacementOptionsDropDown,
  //   commercialConditions, rentalTypeSettings, vehicleDataTable
  // ]);

  // const handlePaymentTermsOnChangeProps = useCallback((index:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   setChangedProposal(true);
  //   setVehicleDataTable((rows) => {
  //     return rows.map((row, rowIndex) => {
  //       if(index === rowIndex){
  //         row.typeofIncome = e.target.value;
  //         // row.typeofIncome = paymentTerms.find(x => x.value === e.target.value)?.value || '';
  //       }
  //       return row;
  //     })
  //   })
  // },[])

  // const handleQuantityOnChangeProps = useCallback((index:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   setChangedProposal(true);
  //   setVehicleDataTable((rows) => {
  //     return rows.map((row, rowIndex) => {
  //       if(index === rowIndex){
  //         row.quantity = convertNumber(e.target.value);        }
  //       return row;
  //     })
  //   })
  // },[])

  // const handleRenew = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   //falta colocar o indice correspondente a viatura em vez de Index = 0
  //   // const index = 0
  //   let index = Number((e.target as any).name.split('-',1))

  //   setChangedProposal(true);
  //   setVehicleDataTable(rows => {
  //     return rows.map((row, rowIndex) => {
  //       if(index ===rowIndex) {
  //         row.renew = (e.target as any).checked
  //       }
  //       return row;
  //     })
  //   })
  // },[])

  const contractTypeHeadCells = useMemo(() => {
    let headCells: {label:string}[]=[];
    if(!propertyHidden('Veículo proposto','Tipo de Contrato')) {
      headCells.push({label: 'Veículo proposto'});
    } else {
      headCells.push({ label: ""});
    }
    if(!propertyHidden('Tipo de aluguer','Tipo de Contrato')) {
      headCells.push({label: 'Tipo de aluguer'});
    } else {
      headCells.push({ label: ""});
    }
    if(!propertyHidden('Condições Comerciais','Tipo de Contrato')) {
      headCells.push({label: 'Condições comerciais'});
    } else {
      headCells.push({ label: ""});
    }
    if(!propertyHidden('Tipo de renda','Tipo de Contrato')) {
      headCells.push({label: 'Tipo de renda'});
    } else {
      headCells.push({ label: ""});
    }
    if(!propertyHidden('Quantidade','Tipo de Contrato')) {
      headCells.push({label: 'Qtd.'});
    } else {
      headCells.push({ label: ""});
    }
    if(!propertyHidden('Renovação/Nome condutor','Tipo de Contrato')) {
      headCells.push({label: 'Renovação/Nome condutor'});
    }
    return headCells;
  },[propertyHidden])

  const getContractTypePropsRow: (item:IVehicle,index: number) => CreateProposalPageProps['contractTypeProps']['contractTypeTableProps']['rows'][0] = useCallback((item:IVehicle,index: number) => {

    const handleRentalTypeOnChangeProps = ((index:number) => async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let indexLease = -1
      const updatedRows = await Promise.all(
        vehicleDataTable.map(async (row, rowIndex) => {
          if (index !== rowIndex) {
            return row;
          }
          if (index === rowIndex) {
            row.typeofRental = e.target.value;
          }
          if (row.typeofRental === e.target.value && row.leaseQuoteNo !== "") {
            indexLease = rowIndex;
          }
          const data = await vehiclesService.getCommercialConditions(e.target.value);
          if (data.succeeded === true) {
            if (indexLease >= 0) {
              row.leaseQuoteNo = vehicleDataTable[indexLease].leaseQuoteNo;
            } else {
              row.leaseQuoteNo = "";
            }

            if(currentUser.profile) {
              row.commercialOptions = data.value.filter((x: any) => currentUser.profile?.profileCommercialConditions.some(cc => cc.value === x.value));
            } else {
              row.commercialOptions = data.value;
            }

            row.commercialConditions = "";
            row.simulations.map((simulationRow) => simulationRow.insurance = '')
          }
          return row;
        })
      );
      setChangedProposal(true);
      setVehicleDataTable(updatedRows);
    });

    const handleCommercialConditionsOnChangeProps = ((index: number) => async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let tmpInsuranceSet = new Set(insuranceFrameworkContractSet);
      let insuranceFrameworkContractOptions: InsuranceFrameworkContract = {
        typeofRental: '',
        commercialConditions: '',
        insurancePackageOptions: []
      };

      let insurancePackageOptions: Option[] = [];
      //inicializa o Set

      const updatedRows = await Promise.all(
        vehicleDataTable.map(async (row, rowIndex) => {
          if (index === rowIndex) {
            let rentalSettings = rentalTypeSettings.find((element) => element.value === row.typeofRental);
            row.commercialConditions = row.commercialOptions.find((x) => x.value === e.target.value)?.value || '';

            //verifica se existem opções pre-carregadas com o tipo de renda e condições comerciais da viatura
            //se não existir
            if(!Array.from(tmpInsuranceSet).some(item =>
              item.typeofRental === row.typeofRental &&
              item.commercialConditions === row.commercialConditions
              // && item.insurancePackageOptions.some(element => element.insurancePackageOption.value === insurancePackageOption.value)
            )) {
              // carrega as opções de cada pacote de seguros
              insurancePackageOptions = await vehiclesService.getInsurancePackage(row.commercialConditions);

              for(const insurancePackageOption of insurancePackageOptions ) {
                let insuranceOption: InsuranceOptions = {
                  insurancePackageOption: {
                    value: '',
                    label: ''
                  },

                  vsOverhaulVehicleOptions: [],
                  vsTheftVehicleOptions: [],
                  vsAccidentVehicleOptions: [],
                  vsAssistanceVehicleOptions: [],
                  vehicleTypeOptions: [],

                  vsOverhaulVehicleTypeOptions: [],
                  vsTheftVehicleTypeOptions: [],
                  vsAccidentVehicleTypeOptions: [],
                  vsAssistanceVehicleTypeOptions: [],

                  fuelOptions: [],
                  viaVerdeOptions: [],

                  insurance: '',
                  vehicleDamageOptions: [],
                  roadAssistanceOptions: [],
                  occupiersOptions: [],
                  liabilityOptions: [],
                  isolatedGlassBreakOptions: [],

                  responsibility: '',
                  ownDamage: '',
                  occupants: '',
                  travelAssistance: '',
                  glassBreakage: '',
                  secureOptions: false,
                }
                insuranceFrameworkContractOptions.typeofRental = row.typeofRental;
                insuranceFrameworkContractOptions.commercialConditions = row.commercialConditions;

                insuranceOption.insurancePackageOption = insurancePackageOption;
                // insuranceOption.insurance = insurancePackageOption.value;
                insuranceOption = await initSimulationDetailsDropdowns(insuranceOption, row.commercialConditions);


                if (!insuranceFrameworkContractOptions.insurancePackageOptions.some(element =>
                  element.insurancePackageOption.value === insurancePackageOption.value)
                ) {
                  insuranceFrameworkContractOptions.insurancePackageOptions.push(insuranceOption)

                  insuranceOption = await initVehicleReplacementOptionsDropDown(insuranceFrameworkContractOptions, insuranceOption,true);
                  insuranceOption = await initSelectedInsuranceDropDown(insuranceOption, row.use,true);
                }
              }
              tmpInsuranceSet.add(insuranceFrameworkContractOptions)
            }
            // se existir inicializa as opções de seguro para o tipo de renda e condições comerciais da viatura
            else {
              let insurancePackage = Array.from(tmpInsuranceSet).find(item =>
                item.typeofRental === row.typeofRental &&
                item.commercialConditions === row.commercialConditions
              )
              if(insurancePackage){
                insuranceFrameworkContractOptions = insurancePackage
                let options: InsuranceOptions[] = insurancePackage.insurancePackageOptions;
                options.forEach(element => {
                  insurancePackageOptions.push(element.insurancePackageOption)
                })
              } else {
                insurancePackageOptions = []
              }

            }
            setInsuranceFrameworkContractSet(tmpInsuranceSet)

            const updatedSimulations = await Promise.all(
              row.simulations.map(async (sim, simIndex) => {
                row.vehicleReplacementOptions = rentalSettings.vs;
                row.maintenance = rentalSettings.maintenance;
                row.tires = rentalSettings.tyres;

                sim.insurancePackageOptions = insurancePackageOptions; //insuranceFrameworkContractOptions.insurancePackageOptions;

                if (rentalSettings.insurance) {
                  sim.secureOptions = rentalSettings.insurance;
                  sim.insurance = insuranceFrameworkContractOptions.insurancePackageOptions?.length > 0 ?
                    insuranceFrameworkContractOptions.insurancePackageOptions[0].insurance
                    : '';
                } else {
                  sim.secureOptions = rentalSettings.insurance;
                  sim.insurance = '';
                }

                sim.vsAccidentVehicleOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsAccidentVehicleOptions;
                sim.vsAssistanceVehicleOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsAssistanceVehicleOptions;
                sim.vsOverhaulVehicleOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsOverhaulVehicleOptions;
                sim.vsTheftVehicleOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsTheftVehicleOptions;

                sim.vsAccidentVehicleTypeOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsAccidentVehicleTypeOptions;
                sim.vsAssistanceVehicleTypeOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsAssistanceVehicleTypeOptions;
                sim.vsOverhaulVehicleTypeOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsOverhaulVehicleTypeOptions;
                sim.vsTheftVehicleTypeOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vsTheftVehicleTypeOptions;
                sim.vehicleTypeOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vehicleTypeOptions;

                sim.fuelOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].fuelOptions;
                sim.viaVerdeOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].viaVerdeOptions;

                sim.vehicleDamageOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].vehicleDamageOptions;
                sim.roadAssistanceOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].roadAssistanceOptions;
                sim.occupiersOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].occupiersOptions;
                sim.liabilityOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].liabilityOptions;
                sim.isolatedGlassBreakOptions = insuranceFrameworkContractOptions.insurancePackageOptions[0].isolatedGlassBreakOptions;

                sim.responsibility = insuranceFrameworkContractOptions.insurancePackageOptions[0].responsibility;
                sim.ownDamage = insuranceFrameworkContractOptions.insurancePackageOptions[0].ownDamage;
                sim.occupants = insuranceFrameworkContractOptions.insurancePackageOptions[0].occupants;
                sim.travelAssistance = insuranceFrameworkContractOptions.insurancePackageOptions[0].travelAssistance;
                sim.glassBreakage = insuranceFrameworkContractOptions.insurancePackageOptions[0].glassBreakage;

                sim.insurance = insuranceFrameworkContractOptions.insurancePackageOptions[0].insurance;
                sim.secureOptions= insuranceFrameworkContractOptions.insurancePackageOptions[0].secureOptions;

                return sim;
              })
            );
            row.simulations = updatedSimulations;
          }
          return row;
        })
      );
      setChangedProposal(true);
      setVehicleDataTable(updatedRows);
    });

    const handlePaymentTermsOnChangeProps = ((index:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setChangedProposal(true);
      setVehicleDataTable((rows) => {
        return rows.map((row, rowIndex) => {
          if(index === rowIndex){
            row.typeofIncome = e.target.value;
            // row.typeofIncome = paymentTerms.find(x => x.value === e.target.value)?.value || '';
          }
          return row;
        })
      });
    })

    const handleQuantityOnChangeProps = ((index:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setChangedProposal(true);
      setVehicleDataTable((rows) => {
        return rows.map((row, rowIndex) => {
          if(index === rowIndex){
            row.quantity = convertNumber(e.target.value);        }
          return row;
        })
      })
    })

    const handleDriverChangeProps = ((index:number) =>  (e: React.SyntheticEvent<Element, Event>, value: any) => {
      setChangedProposal(true);
      if (value) {
        let driver = drivers.find((x) => {
          return x.value === value.value;
        });
        setVehicleDataTable((rows) => {
          rows.map((row, rowIndex) => {
            if (index === rowIndex && driver) {
              row.driverId = driver.value;
              row.driverName = driver.label;
              console.log(row);
            }
            return row;
          });
          return rows;
        });

      } else {
        handleDrivers(proposal.contactId);
        setVehicleDataTable((rows) =>
            rows.map((row, rowIndex) => {
              if (index === rowIndex) {
                row.driverId = "";
                row.driverName = "";
                console.log(row)
              }
              return row;
            })
          )
      }
      setVehicleDataTable([...vehicleDataTable]);
    });

    const handleRenew = ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      //falta colocar o indice correspondente a viatura em vez de Index = 0
      // const index = 0
      let index = Number((e.target as any).name.split('-',1))

      setChangedProposal(true);
      setVehicleDataTable(rows => {
        return rows.map((row, rowIndex) => {
          if(index ===rowIndex) {
            row.renew = (e.target as any).checked
          }
          return row;
        })
      })
    })

    return {
      cells: [
        // cabeçalho da viatura
        {
          name: item.versionDescription,// + `Renault Mégane IV Sport Tourer Diesel - Mégane ST 1.5 Blue DCI Limited`,
          characteristics: item.caracteristics,//`(1461CC | 90CV | 5 lugares | 5 portas | Azul)`,
          fuelIcon: VehicleUtilities.getFuelTypeIconFor(item.fuelType)[0],// ElectricCarIcon,
          fuelLabel: item.fuelType,
          lot: item.lotId,
        },
        // tipo de aluguer
        {
          options: rentalTypesFilteredByProfileMemo,
          value: item.typeofRental ? item.typeofRental : { label: "", value: "" },
          name: index+"-typeofRental",
          onChange: handleRentalTypeOnChangeProps(index),
          disabled: propertyDisabled('Tipo de aluguer','Tipo de Contrato'),
          hidden: propertyHidden('Tipo de aluguer','Tipo de Contrato'),
          warning: propertyValidation(item?.typeofRental, 'Tipo de aluguer','Tipo de Contrato')
        },
        // condições comerciais
        {
          options: item.commercialOptions?.length > 0 ? item.commercialOptions : commercialConditionsFilteredByProfileMemo,
          value: item.commercialConditions ? item.commercialConditions  : { label: "", value: "" },
          name:index+"-commercialConditions",
          onChange: handleCommercialConditionsOnChangeProps(index),
          disabled: propertyDisabled('Condições Comerciais','Tipo de Contrato'),
          hidden: propertyHidden('Condições Comerciais','Tipo de Contrato'),
          warning: propertyValidation(item?.commercialConditions, 'Condições Comerciais','Tipo de Contrato')
        },
        // tipo de renda
        {
          options: paymentTermsFilteredByProfileMemo,//[{ label: "90 Dias", value: "90D" }],
          value: item.typeofIncome && item.typeofIncome !== '' ? item.typeofIncome : { label: "", value: "" },
          // value: item.typeofIncome ? item.typeofIncome : ,
          name:index+"-typeofIncome",
          onChange: handlePaymentTermsOnChangeProps(index),
          disabled: propertyDisabled('Tipo de renda','Tipo de Contrato'),
          hidden: propertyHidden('Tipo de renda','Tipo de Contrato'),
          warning: propertyValidation(item?.typeofIncome, 'Tipo de renda','Tipo de Contrato')
        },
        // quantidade
        {
          value: item.quantity,
          name: index+"-quantity",
          disabled: true,
          // onChange: handleQuantityOnChangeProps(index),
          // disabled: propertyDisabled('Quantidade','Tipo de Contrato'),
          hidden: propertyHidden('Quantidade','Tipo de Contrato'),
          warning: propertyValidation(item.quantity, 'Quantidade','Tipo de Contrato')
        },
        // checkbox renovação / condutor
        {
          checkboxProps: {
            name:index+"-renew",
            value: item.renew,
            checked: item.renew,
            onClick: handleRenew,
            disabled: propertyDisabled('Renovação/Nome condutor','Tipo de Contrato'),
            hidden: propertyHidden('Renovação/Nome condutor','Tipo de Contrato'),
          },
          nameSearchResultProps: {
            name:index+"-driverId",
            value: item.driverId ? drivers.find(d => {return d.value === item.driverId}) : { label: "", value: "" },
            options: drivers,
            filterOptions: filterOptions,
            onChange:handleDriverChangeProps(index),
            disabled: propertyDisabled('Renovação/Nome condutor','Tipo de Contrato'),
            hidden: propertyHidden('Renovação/Nome condutor','Tipo de Contrato'),
          },
        },
      ],
    }
  },[
    drivers,
    filterOptions,
    initSelectedInsuranceDropDown,
    initVehicleReplacementOptionsDropDown,
    initSimulationDetailsDropdowns,
    insuranceFrameworkContractSet,
    rentalTypeSettings,
    // rentalTypes,
    // paymentTerms,
    // commercialConditions,
    vehicleDataTable,
    handleDrivers,
    proposal.contactId,
    rentalTypesFilteredByProfileMemo,
    paymentTermsFilteredByProfileMemo,
    commercialConditionsFilteredByProfileMemo,
    currentUser.profile,
    propertyHidden, propertyDisabled, propertyValidation
  ])

  const handleContractTypePropsRows = useCallback(() => {
    return vehicleDataTable.map((cur, index) => {
      return getContractTypePropsRow(cur, index);
    })
  },[getContractTypePropsRow, vehicleDataTable])

  const handleContractTypeTablePropsData: CreateProposalPageProps['contractTypeProps']['contractTypeTableProps']['rows'] = useMemo(() => {
    return handleContractTypePropsRows()
  },[handleContractTypePropsRows])

  // const contractTypePropsData = useMemo(() => {
  // const contractTypeTableProps: CreateProposalPageProps['contractTypeProps']['contractTypeTableProps'] = useMemo(() => {
  //   return {
  //     loading: false,
  //     handleSelected: handleContractTypeSelected,
  //     // onChange:handleContractTypeFilterChange,
  //     disabled: selectedRows.length === 0,
  //     //Este metodo so funciona para validar os drivers os drivers
  //     onSearchChange:handleSearchChange,
  //     rows: handleContractTypeTablePropsData,
  //     headCells: [
  //       {
  //         label: 'Veículo proposto',
  //       },
  //       {
  //         label: 'Tipo de aluguer',
  //       },
  //       {
  //         label: 'Condições comerciais',
  //       },
  //       {
  //         label: 'Tipo de renda',
  //       },
  //       {
  //         label: 'Qtd.',
  //       },
  //       {
  //         label: 'Renovação/Nome condutor',
  //       },
  //     ],
  //     actions: [
  //       {
  //         label: 'Editar',
  //         onClick: handleEditVehicleClick,
  //       },
  //       {
  //         label: "Duplicar",
  //         onClick: handleContractTypeDuplicateVehicleClick,
  //       },
  //       {
  //         label: "Opções de renovação",
  //       },
  //       {
  //         label: "Eliminar",
  //         onClick: handleContractTypeRemoveVehicleClick,
  //       },
  //     ],
  //     renovationOptionsModalProps: {
  //       title: 'Selecionar Opções de renovação',
  //       addVehicleButtonText: 'Pesquisar',
  //       addVehicleButtonProps: {
  //         onClick: () => alert('adicionar veículo'),
  //       },
  //       dateDropDownProps: {
  //         defaultValue: '02.02.2022',
  //         placeholder: '02.02.2022',
  //           options: [
  //             {
  //               label: "01.02.2022",
  //               value: "01.02.2022",
  //             },
  //             {
  //               label: "02.02.2022",
  //               value: "02.02.2022",
  //             },
  //             {
  //               label: "03.02.2022",
  //               value: "03.02.2022",
  //             },
  //           ],
  //         },
  //         eraseLinkText: "LIMPAR",
  //         eraseLinkProps: { disabled: false, onClick: () => alert("Limpar") },
  //         vehicleSearchResultProps: {
  //           label: "Pesquise por matrícula",
  //           placeholder: "Pesquisar",
  //           options: [{ label: "opt1", value: "1" }],
  //         },
  //         resultsFoundText: "resultado encontrado",
  //         resultsFoundNumber: 1,
  //         cancelButtonText: "Cancelar",
  //         confirmButtonText: "Aplicar",
  //         confirmButtonProps: { onClick: () => alert("aplicar") },
  //         tableProps: {
  //           loading: false,
  //           rows: [
  //             {
  //               cells: [
  //                 `00.NH.00`,
  //                 "C010089803",
  //                 {
  //                   title:
  //                     "Renault Mégane IV Sport Tourer Diesel - Mégane ST 1.5 Blue DCI Limited ",
  //                   description:
  //                     "(1461CC | 90CV | 5 lugares | 5 portas | Azul)",
  //                 },
  //                 "António Silva",
  //               ],
  //               details: {
  //                 ...vehicleDetails,
  //                 onExtraCheckboxChange: () => alert(),
  //               },
  //             },
  //             {
  //               cells: [
  //                 `00.NH.00`,
  //                 "C010089803",
  //                 {
  //                   title:
  //                     "Renault Mégane IV Sport Tourer Diesel - Mégane ST 1.5 Blue DCI Limited ",
  //                   description:
  //                     "(1461CC | 90CV | 5 lugares | 5 portas | Azul)",
  //                 },
  //               'António Silva',
  //             ],
  //             details: {
  //               ...vehicleDetails,
  //               onExtraCheckboxChange: () => alert(),
  //             },
  //           },
  //         ],
  //         headCells: [
  //           {
  //             label: 'Matricula',
  //           },
  //           {
  //             label: 'Nº Contrato',
  //           },
  //           {
  //             label: 'Descrição da viatura',
  //           },
  //           {
  //             label: 'Condutor',
  //           },
  //         ],
  //         expandableText: 'Abrir Detalhes',
  //         closeExpandableText: 'Fechar detalhes',
  //         openDetails: isDetailsStep2VehicleOpen,
  //         warningText: 'Carece validação',
  //       },
  //     },
  //   }
  //   // return handleContractTypePropsRows()
  // },[
  //   handleContractTypeDuplicateVehicleClick, handleContractTypeRemoveVehicleClick,
  //   handleContractTypeSelected, handleContractTypeTablePropsData,
  //   handleEditVehicleClick, handleSearchChange,
  //   isDetailsStep2VehicleOpen, selectedRows.length
  // ])


  //Contract type
  const contractTypeProps: CreateProposalPageProps["contractTypeProps"] = useMemo(() => ({
    title: "Selecionar tipo de contrato",
    totalText: "Total de viaturas: " + vehiclesListingTotal,

    // Opções para edição em gru0po
    rentalTypeDropDownProps: {
      options: rentalTypesFilteredByProfileMemo,
      onChange: handleRentalTypeDropDownChange,
      value: typeOfRentalGroup,
      disabled: propertyDisabled('Tipo de aluguer','Tipo de Contrato'),
      hidden: propertyHidden('Tipo de aluguer','Tipo de Contrato'),
    },
    commercialConditionsDropDownProps: {
      options: commercialConditionsFilteredByProfileMemo,
      onChange: handleCommercialConditionsDropDownChange,
      value: commercialConditionGroup,
      disabled: propertyDisabled('Condições Comerciais','Tipo de Contrato'),
      hidden: propertyHidden('Condições Comerciais','Tipo de Contrato'),
    },
    incomeTypeDropDownProps: {
      // options: paymentTerms[1].result,
      options: paymentTermsFilteredByProfileMemo,
      onChange: handleIncomeTypeDropDownChange,
      value: typeofIncomeGroup,
      disabled: propertyDisabled('Tipo de renda','Tipo de Contrato'),
      hidden: propertyHidden('Tipo de renda','Tipo de Contrato'),
    },
    driver: {
      checkboxProps: {
        onChange: handleDriveCheckBoxChange,
        disabled: propertyDisabled('Renovação/Nome condutor', 'Tipo de Contrato'),
        hidden: propertyHidden('Quantidade','Tipo de Contrato'),
      },
      nameSearchResultProps: {
        value: searchDriver,
        //filterOptions: filterOptions,
        onChange: handleDriverNameSearchResultChange,
        options: drivers ?? [],
        onInputChange: handleDriverNameInputSearch,
        disabled: propertyDisabled('Renovação/Nome condutor', 'Tipo de Contrato'),
        hidden: propertyHidden('Renovação/Nome condutor','Tipo de Contrato'),
      },
    },
    //Table
    contractTypeTableProps: {
      loading: false,
      handleSelected: handleContractTypeSelected,
      // onChange:handleContractTypeFilterChange,
      disabled: selectedRows.length === 0,
      //Este metodo so funciona para validar os drivers
      onSearchChange:handleSearchChange,
      rows: handleContractTypeTablePropsData,
      headCells: contractTypeHeadCells,
      actions: [
        {
          label: 'Editar',
          onClick: handleEditVehicleClick,
        },
        {
          label: "Duplicar",
          onClick: handleContractTypeDuplicateVehicleClick,
        },
        {
          label: "Opções de renovação",
        },
        {
          label: "Eliminar",
          onClick: handleContractTypeRemoveVehicleClick,
        },
      ],
      renovationOptionsModalProps: {
        title: 'Selecionar Opções de renovação',
        addVehicleButtonText: 'Pesquisar',
        addVehicleButtonProps: {
          onClick: () => alert('adicionar veículo'),
        },
        dateDropDownProps: {
          defaultValue: '02.02.2022',
          placeholder: '02.02.2022',

          options: [
            {
              label: "01.02.2022",
              value: "01.02.2022",
            },
            {
              label: "02.02.2022",
              value: "02.02.2022",
            },
            {
              label: "03.02.2022",
              value: "03.02.2022",
            },
          ],
        },
        eraseLinkText: "LIMPAR",
        eraseLinkProps: { disabled: false, onClick: () => alert("Limpar") },
        vehicleSearchResultProps: {
          label: "Pesquise por matrícula",
          placeholder: "Pesquisar",
          options: [{ label: "opt1", value: "1" }],
        },

        resultsFoundText: "resultado encontrado",
        resultsFoundNumber: 1,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Aplicar",
        confirmButtonProps: { onClick: () => alert("aplicar") },
        tableProps: {
          loading: false,
          rows: [
            {
              cells: [
                `00.NH.00`,
                "C010089803",
                {
                  title:
                    "Renault Mégane IV Sport Tourer Diesel - Mégane ST 1.5 Blue DCI Limited ",
                  description:
                    "(1461CC | 90CV | 5 lugares | 5 portas | Azul)",
                },
                "António Silva",
              ],
              details: {
                ...vehicleDetails,
                onExtraCheckboxChange: () => alert(),
              },
            },
            {
              cells: [
                `00.NH.00`,
                "C010089803",
                {
                  title:
                    "Renault Mégane IV Sport Tourer Diesel - Mégane ST 1.5 Blue DCI Limited ",
                  description:
                    "(1461CC | 90CV | 5 lugares | 5 portas | Azul)",
                },
                'António Silva',
              ],
              details: {
                ...vehicleDetails,
                onExtraCheckboxChange: () => alert(),
              },
            },
          ],
          headCells: [
            {
              label: 'Matricula',
            },
            {
              label: 'Nº Contrato',
            },
            {
              label: 'Descrição da viatura',
            },
            {
              label: 'Condutor',
            },
          ],
          expandableText: 'Abrir Detalhes',
          closeExpandableText: 'Fechar detalhes',
          openDetails: isDetailsStep2VehicleOpen,
          warningText: 'Carece validação',
        },
      },
    }
  }), [
    drivers,
    // allDrivers,
    searchDriver,
    typeOfRentalGroup,
    typeofIncomeGroup,
    commercialConditionGroup,
    vehiclesListingTotal,
    // handleContractTypeFilterChange,
    handleDriverNameSearchResultChange,
    handleDriverNameInputSearch,
    handleIncomeTypeDropDownChange,
    handleCommercialConditionsDropDownChange,
    handleRentalTypeDropDownChange,
    handleDriveCheckBoxChange,

    handleSearchChange,
    handleEditVehicleClick,
    handleContractTypeSelected,
    handleContractTypeDuplicateVehicleClick, handleContractTypeRemoveVehicleClick,
    isDetailsStep2VehicleOpen,
    selectedRows.length,

    handleContractTypeTablePropsData,
    rentalTypesFilteredByProfileMemo,
    paymentTermsFilteredByProfileMemo,
    commercialConditionsFilteredByProfileMemo,
    propertyDisabled, propertyHidden,
    contractTypeHeadCells
  ]);
  // FIM ESTRUTURA DA PÁGINA STEP 3


  const notesDrawerProps = useMemo(() => ({
    onSubmit: handleNotesDrawerSubmit,
    notes: comments,
    fileAllowedTypes: ".jpg, .jpeg, .png, .doc, .pdf, .gif, .zip, .rar, .txt, .xls, .docx, .xlsx",
    onNewNoteChange: handleNewNoteChange,
    onEditNoteChange: handleEditNoteChange,
    newNote: newNote,
    newNotePlaceholder: "Insira aqui a mensagem.",
    addNoteText: "Adicionar Comentário",
    attachFileText: "Anexar ficheiro",
    filesTypes: "Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx. O tamanho máximo dos ficheiros é 2 MB.",
    submitNoteText: "ENVIAR COMENTÁRIO",
    onAttachFileChange: handleAttachFileChange,

    onAttachFileToNoteChange: handleAttachFileNoteChange,

    onDeleteAttachedFileClick: handleDeleteAttachedFileClick,

    onSaveEditButtonClick: handleSaveEditNoteChange,

    notesAttachmentsFilesProgressBarProps: progressBar,
  }),
  [
    newNote,
    comments,
    progressBar,
    handleNotesDrawerSubmit,
    handleNewNoteChange,
    handleEditNoteChange,
    handleAttachFileChange,
    handleAttachFileNoteChange,
    handleDeleteAttachedFileClick,
    handleSaveEditNoteChange,
  ]);

  const finishModalProps: Omit<FinishModalProps,"children" | "width" | "open"> = useMemo(() => ({
    filesProgressBarProps:progressBarProposal,
    handleClose: () => {},
    companyIcon: AccountIcon,
    title: 'Enviar proposta ao cliente',
    clientName: requiredClientData?.name,
    nif: 'NIF ' + requiredClientData?.vaT_Registration_No,
    proposalNumber: "Proposta Nº " + proposal?.id,
    proposalState: {
      tagColor: (handleState(proposal.state, proposal.description).color as 'error' | 'success' | 'warning') ,
      tagLabel: handleState(proposal.state, proposal.description).tagLabel,
    },
    proposalType: 'Em comercial',
    numberOfProposal: vehicleDataTable.reduce((counter, vehicle) => {
      counter += vehicle.simulations.filter(x => x.proposeToCustomer).length;
      return counter;
    }, 0)?.toString(),
    descOfProposal: 'Propostas a enviar ao cliente',
    numberOfCalc: vehicleDataTable.reduce((counter, vehicle) => {
      counter += vehicle.simulations.length;
      return counter;
    }, 0)?.toString(),
    descOfCalc: 'Calculos criados',
    emailInfoDescription:
      'Ao enviar ao cliente, irá receber no email a seguinte informação:',
    emailInfoTitle: 'Resumo das propostas financeiras',
    templateDropDownProps: {
      name: "printProposalRentalTypeId",
      label: '',
      placeholder: 'Selecionar opções de template',
      options: proposalPrintTemplates,// [{ label: 'opção1', value: 'op1' }],
      value: proposal?.printProposalRentalTypeId,
      onChange: onProposalPrintFieldChange,
    },
    email: {
      name: "printProposalDestinationEmailAddress",
      label: '',
      placeholder: 'Inserir email',
      value: proposal?.printProposalDestinationEmailAddress,
      onChange: onProposalPrintFieldChange,
    },
    proposalTitle: {
      name: "printProposalTitle",
      label: 'Titulo',
      placeholder: 'Inserir titulo',
      value: proposal?.printProposalTitle,
      onChange: onProposalPrintFieldChange,
    },
    comments: {
      name: "printProposalComments",
      label: 'Escreva um enquadramento da proposta a enviar',
      placeholder: 'Incluir aqui os comentários',
      value: proposal?.printProposalComments,
      onChange: onProposalPrintFieldChange,
    },
    attachOnClick: onProposalPrintAttachClick,
    previewOnClick: onProposalPrintPreviewClick,
    attachFileText: 'Anexar ficheiro',
    filesTypes:
      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx. O tamanho máximo dos ficheiros é 2 MB',
    previewLinkText: 'Pré-visualizar',
    cancelButtonText: 'Cancelar',
    cancelButtonProps: {
      onClick: () => {},
    },
    saveSendLaterButtonText: 'Guardar e Enviar mais tarde',
    saveSendLaterButtonProps: {
      onClick: onSaveAndSendLaterClick,
    },
    sendButtonText: 'Enviar',
    sendButtonProps: {
      onClick: onSendProposalToCustomerClick,
    },
  }), [
    vehicleDataTable, requiredClientData, proposal, handleState,
    onProposalPrintAttachClick, onProposalPrintFieldChange, onProposalPrintPreviewClick,
    onSaveAndSendLaterClick, onSendProposalToCustomerClick, proposalPrintTemplates,progressBarProposal,
  ]);

  const unreadComments = useMemo(() => {
    if(proposal.id > 0){
      commentsService.clearUnread(proposal.id).then((data) => {
        if (data.succeeded) {
          setTotalCommentsUnread(0);
        }
        if (!data.succeeded && convertNumber(data.message) === currentUser.userId) {
          setTotalCommentsUnread(0);
        }
        return totalCommentsUnread;
      });
    }
    else {
      setTotalCommentsUnread(0);
    }
    return totalCommentsUnread
  }, [
    totalCommentsUnread, currentUser,
    // esta dependencia causa ciclo infinito
    //proposal
  ]);

  const wizardSteps = useCallback(() => {
    return [
      {
        label: 'Dados do Cliente',
        onClick: handleStepClick,
        canClick: enableWizardStep0,
      },
      {
        label: 'Dados da viatura',
        onClick: handleStepClick,
        canClick: enableWizardStep1,
      },
      {
        label: 'Tipo de contrato',
        onClick: handleStepClick,
        canClick: enableWizardStep2,
      },
      {
        label: 'Definições de contrato',
        onClick: handleStepClick,
        canClick: enableWizardStep3,
      },
      {
        label: 'Cotação',
        onClick: handleStepClick,
        canClick: enableWizardStep4
      },
    ];
  },[enableWizardStep0, enableWizardStep1, enableWizardStep2, enableWizardStep3, enableWizardStep4,handleStepClick])

  const steps: WizardProps["steps"] = useMemo(() => wizardSteps(), [wizardSteps])

  return (
    <>
      <CreateProposalPage
        stepFivePageTitle="Cabeçalho da nova proposta"
        proposalNumber={proposal?.id.toString() ?? ''}
        proposalText="Proposta Nº: "
        steps={steps}
        activeStep={step}
        historyButtonText="ver histórico de ações"
        historyButtonProps={{ onClick: doNothing, hidden:true }}
        notesButtonText="notas"
        notesButtonProps={notesButtonProps}
        unreadNotesNumber={unreadComments}
        finishModalProps={finishModalProps}
        bottomBarProps={bottomBarProps}
        // barra de navegação
        topBarProps={topBarPropsMemo}
        breadCrumbsLinks={breadcrumbList}
        dashboardMenuButtonProps={dashboardMenuButtonPropsMemo}
        proposalMenuButtonProps={proposalMenuButtonPropsMemo}
        clientsMenuButtonProps={clientsMenuButtonPropsMemo}
        contractsMenuButtonProps={contractsMenuButtonPropsMemo}
        performanceMenuButtonProps={performanceMenuButtonPropsMemo}
        backofficeMenuButtonProps={backofficeMenuButtonPropsMemo}
        // notificações
        notesDrawerProps={notesDrawerProps}
        historyDrawerProps={historyDrawerProps}
        // steps
        clientFileProps={clientFileProps}
        vehicleDataProps={vehicleDataProps}
        contractTypeProps={contractTypeProps}
        contractSettingsProps={contractSettingsProps}
        quotationProps={quotationProps}
      />
      <Alert open={open} onClose={handleClose} {...alertProps} />
    </>
  );
};

export default memo(CreateProposalNew);
