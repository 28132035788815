import { useCallback, useEffect, useState } from "react";
import { ComponentPermision, Profile, ProfilePermissions, ProfileWebFieldConditionProp, ProfileWebPageProp } from "../../model/Profile";
import { UserModel } from "../../model/User";
import { UserServices } from "../../services/UserServices";
import { Option } from "../../model/interfaces";
const userService = new UserServices();

const usePermissionsHook = ()=> {
  const [pagePermissions, setPagePermissions] = useState<ProfilePermissions>();
  const [currentUser, setCurrentUser] = useState<UserModel>({
    userId: 0,
    username: "",
    friendlyName: "",
    avatarSrc: "",
    refreshToken: "",
    typeofIncome: "",
    typeofRental: "",
    commercialConditions: "",
    webPortalContactId: "",
    email: "",
    isAdministrator: false,
    profileId: undefined,
    profile: undefined,
    concessions: [],
    created: new Date(Date.now()),
    createdByUserId: 0,
    active: false,
  });

  useEffect(() => {
    const idUser = localStorage.getItem("userId");
    if (idUser) {
      const id = parseInt(idUser);
      userService.getUserById(id,true).then((user) => {
        if(user.succeeded){
          setCurrentUser(user.value);
          if(!user.value.isAdministrator) {
            // isto pode ser feito assim ou diretamente dentro do setPagePermissions
            let currentProfile: Profile = user.value.profile;
            let pagePermissionValues: ProfilePermissions = {
              allowedFunctionalGroupsValues: [],
              allowedAnnualKmsValues: [],
              allowedDeadlinesValues: [],
              allowedPaymentTermsValues: [],
              allowedRentalTypesValues: [],
            
              allowedCommercialConditionsValues: [],
              allowedInsurancesValues: [],
              
              allowedIsolatedGlassBreaksValues: [],
              allowedLiabilitiesValues: [],
              allowedOccupiersValues: [],
              allowedRoadAssistancesValues: [],
              allowedVehicleDamagesValues: [],
              
              allowedWebPagesAndFields: []
            };
            // carrega quais os valores que o utilizador está autorizado a utilizar para:
            // grupos funcionais
            currentProfile.profileFunctionalGroups.forEach((element: any) => {
              let allowedValue: Option = {
                label: element.value,
                value: element.value
              }
              if( !pagePermissionValues.allowedFunctionalGroupsValues.some(x => x.value === allowedValue.value) ){
                pagePermissionValues.allowedFunctionalGroupsValues.push(allowedValue);
              }
            });
            // kilometragem
            currentProfile.profileAnnualKms.forEach((element: any) => {
              let allowedValue: Option = {
                label: element.value,
                value: element.value
              }
              if( !pagePermissionValues.allowedAnnualKmsValues.some(x => x.value === allowedValue.value) ){
                pagePermissionValues.allowedAnnualKmsValues.push(allowedValue);
              }
            });
            // Prazo
            currentProfile.profileDeadlines.forEach((element: any) => {
              let allowedValue: Option = {
                label: element.value,
                value: element.value
              }
              if( !pagePermissionValues.allowedDeadlinesValues.some(x => x.value === allowedValue.value) ){
                pagePermissionValues.allowedDeadlinesValues.push(allowedValue);
              }
            });
            // Tipo de renda
            currentProfile.profilePaymentTerms.forEach((element: any) => {
              let allowedValue: Option = {
                label: element.value,
                value: element.value
              }
              if( !pagePermissionValues.allowedPaymentTermsValues.some(x => x.value === allowedValue.value) ){
                pagePermissionValues.allowedPaymentTermsValues.push(allowedValue);
              }
            });
            // Tipo de aluguer
            currentProfile.profileRentalTypes.forEach((element: any) => {
              let allowedValue: Option = {
                label: element.value,
                value: element.value
              }
              if( !pagePermissionValues.allowedRentalTypesValues.some(x => x.value === allowedValue.value) ){
                pagePermissionValues.allowedRentalTypesValues.push(allowedValue);
              }
            });
            // Condições comerciais
            currentProfile.profileCommercialConditions.forEach((element: any) => {
              let allowedValue: Option = {
                label: element.value,
                value: element.value
              }
              if( !pagePermissionValues.allowedCommercialConditionsValues.some(x => x.value === allowedValue.value) ){
                pagePermissionValues.allowedCommercialConditionsValues.push(allowedValue);
              }
            });
            // Pacotes de seguros
            currentProfile.profileInsurances.forEach((element: any) => {
              let allowedValue: Option = {
                label: element.value,
                value: element.value
              }
              if( !pagePermissionValues.allowedInsurancesValues.some(x => x.value === allowedValue.value) ){
                pagePermissionValues.allowedInsurancesValues.push(allowedValue);
              }
            });
            // Opções de seguros
            // isolatedglass breakage
            currentProfile.profileIsolatedGlassBreaks.forEach((element: any) => {
              let allowedValue: Option = {
                label: element.value,
                value: element.value
              }
              if( !pagePermissionValues.allowedIsolatedGlassBreaksValues.some(x => x.value === allowedValue.value) ){
                pagePermissionValues.allowedIsolatedGlassBreaksValues.push(allowedValue);
              }
            });
            // liabilities
            currentProfile.profileLiabilities.forEach((element: any) => {
              let allowedValue: Option = {
                label: element.value,
                value: element.value
              }
              if( !pagePermissionValues.allowedLiabilitiesValues.some(x => x.value === allowedValue.value) ){
                pagePermissionValues.allowedLiabilitiesValues.push(allowedValue);
              }
            });
            // occupiers
            currentProfile.profileOccupiers.forEach((element: any) => {
              let allowedValue: Option = {
                label: element.value,
                value: element.value
              }
              if( !pagePermissionValues.allowedOccupiersValues.some(x => x.value === allowedValue.value) ){
                pagePermissionValues.allowedOccupiersValues.push(allowedValue);
              }
            });
            // road assistance
            currentProfile.profileRoadAssistances.forEach((element: any) => {
              let allowedValue: Option = {
                label: element.value,
                value: element.value
              }
              if( !pagePermissionValues.allowedRoadAssistancesValues.some(x => x.value === allowedValue.value) ){
                pagePermissionValues.allowedRoadAssistancesValues.push(allowedValue);
              }
            });
            // own damage
            currentProfile.profileVehicleDamages.forEach((element: any) => {
              let allowedValue: Option = {
                label: element.value,
                value: element.value
              }
              if( !pagePermissionValues.allowedVehicleDamagesValues.some(x => x.value === allowedValue.value) ){
                pagePermissionValues.allowedVehicleDamagesValues.push(allowedValue);
              }
            });

            // carrega quais campos o utilizador está autorizado a ver/editar
            // pagínas
            currentProfile.profileWebPages.forEach((element: ProfileWebPageProp) => { 
              // se não existir na lista de permissões, adiciona
              if(!pagePermissionValues.allowedWebPagesAndFields.some(x => x.name === element.name)){
                let permission: ComponentPermision = {
                  name: element.name,
                  isSwitchToggled: element.isSwitchToggled,
                  isVisible: element.isVisible,
                  isEditable: element.isEditable,
                  hasValidationRules: element.hasValidationRules,
                  validationRules:[],

                  id: element.id,
                }
                pagePermissionValues.allowedWebPagesAndFields.push(permission);
              }
              // secções das páginas
              if(element.profileWebSubPages){
                element.profileWebSubPages.forEach((subElement) => {
                  if ( !pagePermissionValues.allowedWebPagesAndFields.some(x => x.id === subElement.id && x.parentId === subElement.profileWebPageId)){
                    let subpermission: ComponentPermision = {
                      name: subElement.name,
                      isSwitchToggled: subElement.isSwitchToggled,
                      isVisible: subElement.isVisible,
                      isEditable: subElement.isEditable,
                      hasValidationRules: subElement.hasValidationRules,
                      validationRules:[],

                      id: subElement.id,
                      parentId: subElement.profileWebPageId,
                    }
                    pagePermissionValues.allowedWebPagesAndFields.push(subpermission);
                  }
                  //inputs das secções
                  if(subElement.profileWebFields){
                    subElement.profileWebFields.forEach((field) => {
                      if ( !pagePermissionValues.allowedWebPagesAndFields.some(x => x.id === field.id && x.parentId === field.profileWebSubPageId)){
                        let validationRules: ProfileWebFieldConditionProp[] = [];
                        if(field.profileWebFieldConditions && field.profileWebFieldConditions.length > 0){
                          validationRules = field.profileWebFieldConditions.map((condition) => { 
                            return {
                              id: condition.id,
                              profileWebFieldId: condition.profileWebFieldId,
                              value: condition.value,
                              conditionSymbol: condition.conditionSymbol
                            }
                          });
                        }
                        let fieldpermission: ComponentPermision = {
                          name: field.name,
                          isSwitchToggled: field.isSwitchToggled,
                          isVisible: field.isVisible,
                          isEditable: field.isEditable,
                          hasValidationRules: field.hasValidationRules,
                          validationRules: validationRules,

                          id: field.id,
                          parentId: field.profileWebSubPageId,
                        }
                        pagePermissionValues.allowedWebPagesAndFields.push(fieldpermission);
                      }
                    });
                  }
                });
              }
            });
            setPagePermissions(pagePermissionValues);
          }
        }
      });
    }
  },[]);

  const propertyDisabled = useCallback((label: string, parentObjectName?: string) => {
    if(!currentUser.isAdministrator) {
      if(pagePermissions && pagePermissions.allowedWebPagesAndFields){
        const parent = pagePermissions.allowedWebPagesAndFields.filter(x => x.name === parentObjectName);
        if(parent  && parent.length > 0){
          let prop = pagePermissions.allowedWebPagesAndFields.find((x) => x.name === label && parent.some(y => y.id === x.parentId));
          if(prop) {
            return !prop.isEditable
          } else {
            return false
          }
        } else {
          let prop = pagePermissions.allowedWebPagesAndFields.find((x) => x.name === label);
          if(prop) {
            return !prop.isVisible
          } else {
            return false
          }
        }
          // pagePermissions.allowedWebPagesAndFields.forEach((page) => {
          //   let prop = Object.keys(vehicleDataTable[0]).find((key) => { 
          //     return key === page.name
          //   })
          //   if(prop){ 
          //     console.log(page.name + " has values")
          //   }
          //   console.log(page.name + "  doesn't have values")
          // })
      } else {
        return false;
      }
    }
    return !currentUser.isAdministrator;
  }, [
    // vehicleDataTable,
    pagePermissions, currentUser.isAdministrator
  ])
    
  const propertyHidden = useCallback((label: string, parentObjectName?: string) => {
    if(!currentUser.isAdministrator) {
      if(pagePermissions && pagePermissions.allowedWebPagesAndFields){
        const parent = pagePermissions.allowedWebPagesAndFields.filter(x => x.name === parentObjectName);
        if(parent && parent.length > 0){
          let prop = pagePermissions.allowedWebPagesAndFields.find((x) => x.name === label && parent.some(y => y.id === x.parentId));
          if(prop) {
            return !prop.isVisible
          } else {
            return false
          }
        } else {
          let prop = pagePermissions.allowedWebPagesAndFields.find((x) => x.name === label);
          if(prop) {
            return !prop.isVisible
          } else {
            return false
          }
        }
      } else {
        return false;
      }

    // if(pagePermissions){
    //   if(pagePermissions.allowedWebPagesAndFields){
    //     if(currentUser.isAdministrator) {
    //       return !currentUser.isAdministrator
    //     } else {
    //       let prop = pagePermissions.allowedWebPagesAndFields.find((x) => x.name === label);
    //       if(prop) {
    //         return !prop.isVisible
    //       } else {
    //         return false
    //       }
    //     }
    //   }
    } else {
      return false
    }
  }, [
    pagePermissions, currentUser.isAdministrator
  ])

  const propertyValidation = useCallback((propertyValue: string | number, label: string, parentObjectName?: string) => {
    if(!currentUser.isAdministrator) {
      if(pagePermissions && pagePermissions.allowedWebPagesAndFields){
        const parent = pagePermissions.allowedWebPagesAndFields.filter(x => x.name === parentObjectName);
        if(parent && parent.length > 0){
          let prop = pagePermissions.allowedWebPagesAndFields.find((x) => x.name === label && parent.some(y => y.id === x.parentId));
          let validPropertyValue:boolean = false
          if(prop) {
            if(prop.hasValidationRules && prop.validationRules && prop.validationRules.length > 0) {
              // verifica se existe alguma condição em que o valor da propriedade
              // esteja fora dos limites de valores definidos nas regras de validação
              validPropertyValue = prop.validationRules.some( x => {
                let parsedValue = Number(propertyValue)
                if(isNaN(parsedValue)){
                  validPropertyValue = (propertyValue as String).toLocaleLowerCase() === x.value.toLocaleLowerCase();
                  return validPropertyValue;
                } else {
                  let value = Number(x.value)
                  // if(typeof propertyValue === 'number') {
                  switch (x.conditionSymbol) {
                    case -1:
  
                      if(parsedValue < value){
                        validPropertyValue = validPropertyValue && false
                      } else {
                        validPropertyValue = validPropertyValue && true
                      }
                      break;
                    case 0:
                      if(parsedValue === value){
                        validPropertyValue = validPropertyValue || false
                      } else {
                        validPropertyValue = validPropertyValue || true  
                      }
                    break;
                  
                    case 1:
                      if(parsedValue > value){
                        validPropertyValue = validPropertyValue || false
                      } else {
                        validPropertyValue = validPropertyValue || true
                      }
                      break;
                    default:
                      break;
                  }
                }
                return validPropertyValue;
              })
              // validar que está dentro dos paramentros das regras de validação
            }
            return validPropertyValue;
          } else {
            return false
          }
        } else {
          let prop = pagePermissions.allowedWebPagesAndFields.find((x) => x.name === label);
          if(prop) {
            return prop.hasValidationRules
          } else {
            return false
          }
        }
      } else {
        return false;
      }
    } else {
      return false
    }
  },[pagePermissions, currentUser.isAdministrator])
    
  // este metodo permite aceitar uma variavel de qualquer tipo e devolver uma variavel do mesmo tipo
  const allowedValues = useCallback(<T extends unknown>(arg: T, valuesType: string): T => {
    let values: typeof arg = arg;

    switch (valuesType) {
      // grupos funcionais
      case 'functional groups':
        if(currentUser.isAdministrator || (!currentUser.isAdministrator && currentUser.profile?.profileFunctionalGroups.length === 0)) {
          return values;
        } else {
          if (Array.isArray(values)) {
            let nonExistingValues: string[] = [];
            const functionalGroupsToAdd = currentUser.profile?.profileFunctionalGroups;
            if (functionalGroupsToAdd) {
              functionalGroupsToAdd.forEach((element) => { nonExistingValues.push(element.value); });
            }
            // Combine existing values with non-existing values
            const combinedValues = [...nonExistingValues];
  
            return combinedValues as any as T;
          }
          return values;
        }
        // break;
      // prazo
      case 'deadline':
        if(currentUser.isAdministrator || (!currentUser.isAdministrator && currentUser.profile?.profileDeadlines.length === 0)) {
          return values
        } else {
          if(Array.isArray(values) && values.some(i => currentUser.profile?.profileDeadlines.some(pi => pi.value === i.value.toString()))) {
            return values.filter(i => currentUser.profile?.profileDeadlines.some(pi => pi.value === i.value.toString())) as any as T
          }
          return values;
        }
        // break;

      //kilometragem
      case 'mileage':
        if(currentUser.isAdministrator || (!currentUser.isAdministrator && currentUser.profile?.profileAnnualKms.length === 0)) {
          return values;
        } else {
          if(Array.isArray(values) && values.some(i => currentUser.profile?.profileAnnualKms.some(pi => pi.value === i.value.toString()))) {
            return values.filter(i => currentUser.profile?.profileAnnualKms.some(pi => pi.value === i.value.toString())) as any as T
          }
          return values;
        }
        // break;
      // tipo de renda
      case 'payment terms':
        if(currentUser.isAdministrator || (!currentUser.isAdministrator && currentUser.profile?.profilePaymentTerms.length === 0)) {
          return values;
        } else {
          if(Array.isArray(values) && values.some(i => currentUser.profile?.profilePaymentTerms.some(pi => pi.value === i.value))) {
            return values.filter(i => currentUser.profile?.profilePaymentTerms.some(pi => pi.value === i.value)) as any as T
          }
          return values;
        }
        // break;

      // pacote de seguro
      case 'insurance':
        if(currentUser.isAdministrator || (!currentUser.isAdministrator && currentUser.profile?.profileInsurances.length === 0)) {
          return values;
        } else {
          if(Array.isArray(values) && values.some(i => currentUser.profile?.profileInsurances.some(pi => pi.value.split('-')[0] === i.value))) {
            return values.filter(i => currentUser.profile?.profileInsurances.some(pi => pi.value.split('-')[0] === i.value)) as any as T
          }
          return values;
        }
        // break;
      // SEGUROS
      case 'own damage':
        // own damage sd
        if(currentUser.isAdministrator || (!currentUser.isAdministrator && currentUser.profile?.profileVehicleDamages.length === 0)) {
          return values
        } else {
          if(Array.isArray(values) &&values.some(i => currentUser.profile?.profileVehicleDamages.some(pi => pi.value.split('-')[0] === i.value))) {
          return values.filter(i => currentUser.profile?.profileVehicleDamages.some(pi => pi.value.split('-')[0] === i.value)) as any as T
          } 
          return values;
        }
        // break;
      case 'occupiers':
        // occupiers
        if(currentUser.isAdministrator || (!currentUser.isAdministrator && currentUser.profile?.profileOccupiers.length === 0)) {
          return values
        } else {
          // lista de valores contem valores identicos ao perfil
          if( Array.isArray(values) && values.some(i => !currentUser.isAdministrator && currentUser.profile?.profileOccupiers.some(pi => pi.value.split('-')[0] === i.value))){
          return values.filter(i => currentUser.profile?.profileOccupiers.some(pi => pi.value.split('-')[0] === i.value)) as any as T
          }
          return values
        }
        // break;
      case 'road assistance':
        // road assistance
        if(currentUser.isAdministrator || (!currentUser.isAdministrator && currentUser.profile?.profileRoadAssistances.length === 0)) {
          return values;
        } else {
          if(Array.isArray(values) && values.some(i => !currentUser.isAdministrator && currentUser.profile?.profileRoadAssistances.some(pi => pi.value.split('-')[0] === i.value))) {
            return values.filter(i => currentUser.profile?.profileRoadAssistances.some(pi => pi.value.split('-')[0] === i.value)) as any as T
          }
          return values;
        }
      // break;
      case 'isolated glass breakage':
        // isolated glass breakage
        if(currentUser.isAdministrator || (!currentUser.isAdministrator && currentUser.profile?.profileIsolatedGlassBreaks.length === 0)) {
          return values;
        } else {
          if(Array.isArray(values) && values.some(i => !currentUser.isAdministrator && currentUser.profile?.profileIsolatedGlassBreaks.some(pi => pi.value.split('-')[0] === i.value))) {
            return values.filter(i => currentUser.profile?.profileIsolatedGlassBreaks.some(pi => pi.value.split('-')[0] === i.value)) as any as T
          }
          return values;
        }
        // break;
      case 'liabilities':
        // liabilities
        if(currentUser.isAdministrator || (!currentUser.isAdministrator && currentUser.profile?.profileLiabilities.length === 0)) {
          return values;
        } else {
          if(Array.isArray(values) && values.some(i => !currentUser.isAdministrator && currentUser.profile?.profileLiabilities.some(pi => pi.value.split('-')[0] === i.value))) {
            return values.filter(i => currentUser.profile?.profileLiabilities.some(pi => pi.value.split('-')[0] === i.value)) as any as T
          }
          return values;
        }
        // break;
      default:
      return values;
    }
  }, [currentUser.isAdministrator, currentUser.profile]);

  // SE FOREM NOTADOS PROBLEMAS DE PERFORMANCE, OS METODOS ACIMA DEVERÃO SER ALTERADOS 
  // TENDO EM CONSIDERAÇÃO OS METODOS ABAIXO
  // CRIAÇÃO DO MAPA PARA AGILIZAR A PESQUISA E RESPETIVA SUBSTITUIÇÃO DE

  // pagePermissions.allowedWebPagesAndFields.find((x) => x.name === label)
  // POR   
  // permissions.get('Preço base')

  // const permissions = useMemo(() => {
  //   const map = new Map();
  //   if(pagePermissions){
  //     pagePermissions.allowedWebPagesAndFields.forEach((perm) => map.set(perm.name, perm));
  //   }
  //   return map;
  // }, [pagePermissions]);

  // const checkPermissions = useCallback(() => {
  //   if(currentUser.isAdministrator) {
  //     return !currentUser.isAdministrator
  //   } else {
  //     if(permissions) {
  //       permissions.get('Preço base') !== undefined ? permissions.get('Preço base').isEditable : false,
  //     }
  //   }
  // },[permissions, currentUser])

  return { currentUser, setCurrentUser, propertyDisabled, propertyHidden, propertyValidation, allowedValues};

}
export default usePermissionsHook;
